/*!
  *  Copyright (C) 2022 Robert Bosch GmbH Copyright (C) 2022 Robert Bosch Manufacturing Solutions GmbH, Germany. All rights reserved.
 */

// new colors and shades (Feb 2022)

$boschGray95: #eff1f2;
$boschGray90: #e0e2e5;
$boschGray85: #d0d4d8;
$boschGray80: #c1c7cc;
$boschGray75: #b2b9c0;
$boschGray70: #a4abb3;
$boschGray65: #979ea4;
$boschGray60: #8a9097;
$boschGray55: #7d8389;
$boschGray50: #71767c;
$boschGray45: #656a6f;
$boschGray40: #595e62;
$boschGray35: #4e5256;
$boschGray30: #43464a;
$boschGray25: #383b3e;
$boschGray20: #2e3033;
$boschGray15: #232628;
$boschGray10: #1a1c1d;
$boschGray05: #101112;
$boschPurple95: #f7eef6;
$boschPurple90: #f0dcee;
$boschPurple85: #ebcae8;
$boschPurple80: #e8b6e3;
$boschPurple75: #e5a2df;
$boschPurple70: #e48cdd;
$boschPurple65: #e472db;
$boschPurple60: #e552da;
$boschPurple55: #d543cb;
$boschPurple50: #c535bc;
$boschPurple45: #b12ea9;
$boschPurple40: #9e2896;
$boschPurple35: #8b2284;
$boschPurple30: #791d73;
$boschPurple25: #671761;
$boschPurple20: #551151;
$boschPurple15: #440c41;
$boschPurple10: #340731;
$boschPurple05: #230421;
$boschBlue95: #e8f1ff;
$boschBlue90: #d1e4ff;
$boschBlue85: #b8d6ff;
$boschBlue80: #9dc9ff;
$boschBlue75: #7ebdff;
$boschBlue70: #56b0ff;
$boschBlue65: #00a4fd;
$boschBlue60: #0096e8;
$boschBlue55: #0088d4;
$boschBlue50: #007bc0;
$boschBlue45: #006ead;
$boschBlue40: #00629a;
$boschBlue35: #005587;
$boschBlue30: #004975;
$boschBlue25: #003e64;
$boschBlue20: #003253;
$boschBlue15: #002742;
$boschBlue10: #001d33;
$boschBlue05: #001222;
$boschTurquoise95: #def5f3;
$boschTurquoise90: #b6ede8;
$boschTurquoise85: #a1dfdb;
$boschTurquoise80: #8dd2cd;
$boschTurquoise75: #79c5c0;
$boschTurquoise70: #66b8b2;
$boschTurquoise65: #54aba5;
$boschTurquoise60: #419e98;
$boschTurquoise55: #2e908b;
$boschTurquoise50: #18837e;
$boschTurquoise45: #147671;
$boschTurquoise40: #116864;
$boschTurquoise35: #0e5b57;
$boschTurquoise30: #0a4f4b;
$boschTurquoise25: #07423f;
$boschTurquoise20: #053634;
$boschTurquoise15: #032b28;
$boschTurquoise10: #02201e;
$boschTurquoise05: #011413;
$boschGreen95: #e2f5e7;
$boschGreen90: #b8efc9;
$boschGreen85: #9be4b3;
$boschGreen80: #86d7a2;
$boschGreen75: #72ca92;
$boschGreen70: #5ebd82;
$boschGreen65: #4ab073;
$boschGreen60: #37a264;
$boschGreen55: #219557;
$boschGreen50: #00884a;
$boschGreen45: #007a42;
$boschGreen40: #006c3a;
$boschGreen35: #005f32;
$boschGreen30: #00512a;
$boschGreen25: #004523;
$boschGreen20: #00381b;
$boschGreen15: #002c14;
$boschGreen10: #00210e;
$boschGreen05: #001507;
$boschRed95: #ffecec;
$boschRed90: #ffd9d9;
$boschRed85: #ffc6c6;
$boschRed80: #ffb2b2;
$boschRed75: #ff9d9d;
$boschRed70: #ff8787;
$boschRed65: #ff6e6f;
$boschRed60: #ff5152;
$boschRed55: #ff2124;
$boschRed50: #ed0007;
$boschRed45: #d50005;
$boschRed40: #be0004;
$boschRed35: #a80003;
$boschRed30: #920002;
$boschRed25: #7d0002;
$boschRed20: #680001;
$boschRed15: #540001;
$boschRed10: #410000;
$boschRed05: #2d0000;
$boschYellow95: #ffefd1;
$boschYellow90: #ffdf95;
$boschYellow85: #ffcf00;
$boschYellow80: #eec100;
$boschYellow75: #deb300;
$boschYellow70: #cda600;
$boschYellow65: #bd9900;
$boschYellow60: #ad8c00;
$boschYellow55: #9e7f00;
$boschYellow50: #8f7300;
$boschYellow45: #806700;
$boschYellow40: #725b00;
$boschYellow35: #644f00;
$boschYellow30: #564400;
$boschYellow25: #493900;
$boschYellow20: #3c2e00;
$boschYellow15: #2f2400;
$boschYellow10: #231a00;
$boschYellow05: #171000;

$boschWhite: #ffffff;
$boschBlack: #000000;

// Functional colors
$boschSignalYellow: $boschYellow85;
$boschSignalRed: $boschRed50;
$boschSignalGreen: $boschGreen50;
