/*!
  *  Copyright (C) 2017 Robert Bosch GmbH Copyright (C) 2017 Robert Bosch Manufacturing Solutions GmbH, Germany. All rights reserved.
 */

input {
  @include make-form-input;
}

.search-input {
  @include make-form-input-search;
}

textarea {
  @include make-textarea;
}

input, textarea, select {
  &.valid {
    border-color: $boschSignalGreen;
  }

  &.warning {
    border-color: $boschSignalYellow;
  }

  &.invalid,
  &.error {
    border-color: $boschSignalRed;
  }
}

.form-group {
  @include make-form-group;

  &.form-field-numeric {
    @include make-form-field-numeric;
  }

  div, span, p {
    &.hint,
    &.valid,
    &.error,
    &.warning {
      font-size: $font-size-small;
      padding: $grid-size $grid-size*2;
      font-weight: $font-weight-medium;
    }

    &.valid {
      color: $boschSignalGreen;
    }

    &.warning {
      color: $boschSignalYellow;
    }

    &.error {
      color: $boschSignalRed;
    }
  }

}

@-moz-document url-prefix() {
  .form-group {
    @include make-form-group;

    select:first-child {
      padding-left: 13px;
    }

    &.form-field-numeric {
      @include make-form-field-numeric;
    }
  }
}

.dark-background {
  input {
    @include make-form-input-dark;
  }

  textarea {
    @include make-textarea-dark;
  }
}
