@import "../bci-variables";

@mixin make-responsive-content-padding() {
  @each $width, $padding in $content-padding {
    @media (min-width: $width) {
      padding: $padding;
    }
  }
}

@mixin make-responsive-banner-padding() {
  @each $width, $padding in $content-padding {
    @media (min-width: $width) {
      padding: $grid-size $padding;
    }
  }
}
