/*!
  *  Copyright (C) 2019 Robert Bosch GmbH Copyright (C) 2019 Robert Bosch Manufacturing Solutions GmbH, Germany. All rights reserved.
 */

$checkbox-size: $input-form-size;

$checkbox-background-color: $boschGray60;
$checkbox-background-color-checked: $boschBlue50;
$checkbox-background-color-active: $boschBlue30;
$checkbox-background-color-hover: $boschBlue40;
$checkbox-stroke-color: $boschWhite;

$checkbox-background-color-disabled: $boschGray80;
$checkbox-stroke-color-disabled: $boschGray60;
$checkbox-label-color-disabled: $boschGray60;

@mixin make-radiobox-checkbox-label {
  font-weight: $font-weight-regular;
  font-size: $font-size-base;
  height: $input-form-size;
  line-height: $input-form-size;
}
