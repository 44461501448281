/*!
  *  Copyright (C) 2019 Robert Bosch GmbH Copyright (C) 2019 Robert Bosch Manufacturing Solutions GmbH, Germany. All rights reserved.
 */

@keyframes backgroundLeftRight {
  from {
    background-position-x: 0%;
  }
  to {
    background-position-x: 100%;
  }
}

.loadingTextSkeleton {
  @include make-text-skeleton;
}

div, td, th {
  &.loadingTextSkeleton {
    @include make-text-skeleton-block;
  }
}
