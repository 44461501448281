.bci-tile-view {
  @include make-bci-tile-view;
}

.bci-tile {
  @include make-bci-tile;

  &.tile-border-green {
    @include make-border-color-green;
  }
  &.tile-border-yellow {
    @include make-border-color-yellow;
  }
  &.tile-border-orange {
    @include make-border-color-orange;
  }
  &.tile-border-red {
    @include make-border-color-red;
  }

  &.selected {
    @include make-border-color-blue;
  }

  .tile-header {
    @include make-bci-tile-header;
  }

  .title {
    @include make-bci-tile-title
  }

  .tile-content, .tile-content-double {
    .textual-elements {
      @include make-bci-tile-textual-elements;
    }

    .action-elements {
      @include make-bci-tile-action-elements;
    }

    .large-text {
      @include make-bci-tile-large-text;
    }
  }

  .tile-content {
    @include make-bci-tile-content;
  }

  .tile-content-double {
    @include make-bci-tile-content-double;
  }
}
