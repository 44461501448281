.flex-column {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.flex-row {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
}

.flex-none {
  flex: 0 0 auto;
}

.flex-grow {
  flex: 1 1 100%;
  height: 100%;
}

.flex-layout-start-strech {
  place-content: stretch flex-start;
  align-items: stretch;
}

.flex-layout-center-center {
  place-content: center;
  align-items: center;
}

.flex-fill {
  width: 100%;
  box-sizing: border-box;
  max-width: 100%;
}

.flex-row-wrap {
  flex-flow: row wrap;
  box-sizing: border-box;
  display: flex;

  &.grow {
    flex: 1 1 100%;
    max-width: 100%;
  }
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
