/*!
  *  Copyright (C) 2017 Robert Bosch GmbH Copyright (C) 2017 Robert Bosch Manufacturing Solutions GmbH, Germany. All rights reserved.
 */

// Deprecated styles

.alert {
  @include make-callout-base;

  .alert-content {
    @include make-callout-content;
  }

  .alert-icon {
    @include make-callout-icon;
  }

  .alert-close {
    @include make-callout-close;
  }
}

.alert-success {
  @include make-callout-success;
}
.alert-info {
  @include make-callout-info;
}
.alert-warn {
  @include make-callout-warn;
}
.alert-danger {
  @include make-callout-danger;
}
