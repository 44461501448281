/*!
  *  Copyright (C) 2017 Robert Bosch GmbH Copyright (C) 2017 Robert Bosch Manufacturing Solutions GmbH, Germany. All rights reserved.
 */

@mixin make-form-input-padding-without-label {
  padding: $grid-size * 1.5 $grid-size * 2 $grid-size * 1.5 - 1 $grid-size * 2;
}

@mixin make-form-input-padding-with-label {
  padding: $grid-size * 2.5 $grid-size * 2 $grid-size * 0.5 - 1 $grid-size * 2;
}

@mixin make-form-group {
  position: relative;

  input,
  textarea,
  select,
  .dropdown {
    &:first-child {
      @include make-form-input-padding-with-label;
    }
    &:first-child:last-child {
      @include make-form-input-padding-without-label;
    }
  }
}

@mixin make-form-input-search {
  input {
    padding-right: (28px + $grid-size * 2);
  }
  &::after {
    font-family: "Bosch-Ic";
    content: $Bosch-Ic-search;
    font-size: $icon-font-size-medium;
    color: $boschBlack;
    display: inline-block;

    position: relative;
    right: 12px;
    bottom: 14px;
    margin-left: -24px;

    z-index: 1;
    pointer-events: none;
  }
}

@mixin make-form-input {
  color: $font-color;
  background-color: $form-field-default-theme-background-color;
  box-shadow: none;
  width: 100%;
  min-width: 160px;
  box-sizing: border-box;
  caret-color: $form-field-caret-color;
  cursor: auto;

  @include make-font-regular($font-size-base);
  @include make-border(0px 0px 1px 0px, $boschBlack);
  @include make-form-control-states;
  @include placeholder($form-field-default-theme-focused-placeholder-color);
  @include make-form-input-padding-without-label;
  @include remove-form-input-spin-buttons;
}

@mixin make-form-field-numeric {
  .bosch-ic-clickable {
    position: absolute;
    top: $grid-size * 1.5;
    cursor: pointer;
  }
  .bosch-ic-clickable:first-of-type + .bosch-ic-clickable {
    right: $grid-size * 1.5;
  }
  .bosch-ic-clickable:first-of-type {
    right: $grid-size * 7.5;
  }
  .label-top {
    // make room to the spin buttons
    width: calc(100% - #{$grid-size * 14});
  }
  input {
    // make room to the spin buttons
    padding-right: $grid-size * 13.25;

    // handle disabled spin buttons
    &:disabled ~ .bosch-ic-clickable {
      cursor: default;
      &,
      &:active,
      &:hover {
        color: $form-field-disabled-color;
      }
    }

    // fixing the padding when label is missing
    &:nth-last-child(3) {
      @include make-form-input-padding-without-label;
      padding-right: $grid-size * 13.25;
    }
  }
}

@mixin remove-form-input-spin-buttons {
  &[type="number"] {
    -moz-appearance: textfield;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

@mixin make-form-input-dark {
  background-color: $form-field-dark-theme-background-color;

  @include make-form-control-states-dark;
}

@mixin make-form-control-label-state($color) {
  label {
    background-color: $color;
  }
}

@mixin make-form-control-hover-state {
  background-color: $form-field-default-theme-hovered-background-color;
  box-shadow: none;

  @include make-form-control-states-hovered-label-top;
  @include placeholder($form-field-default-theme-focused-placeholder-color);
  @include make-form-control-label-state(
    $form-field-default-theme-hovered-background-color
  );
}

@mixin make-form-control-press-state {
  background-color: $form-field-default-theme-pressed-background-color;
  box-shadow: none;

  @include make-form-control-states-pressed-label-top;
  @include placeholder($form-field-default-theme-focused-placeholder-color);
  @include make-form-control-label-state(
    $form-field-default-theme-pressed-background-color
  );
}

@mixin make-form-control-focus-state {
  background-color: $form-field-default-theme-focused-background-color;
  box-shadow: none;

  @include make-form-control-states-focused-label-top;
  @include placeholder($form-field-default-theme-focused-placeholder-color);
  @include make-form-control-label-state(
    $form-field-default-theme-focused-background-color
  );
}

@mixin make-form-control-disabled-state {
  color: $form-field-disabled-color;
  background-color: $form-field-default-theme-disabled-background-color;
  cursor: default;
  @include make-border(0px 0px 1px 0px, $form-field-disabled-color);
  @include placeholder($form-field-placeholder-color);
  @include make-form-control-states-disabled-label-top;
  @include make-form-control-label-state(
    $form-field-default-theme-disabled-background-color
  );
}

@mixin make-form-control-readonly-state {
  color: $form-field-disabled-color;
  background-color: $form-field-default-theme-disabled-background-color;
  cursor: default;
  @include make-border(0px 0px 1px 0px, $boschBlack);
  @include placeholder($form-field-placeholder-color);
  @include make-form-control-states-disabled-label-top;
  @include make-form-control-label-state(
    $form-field-default-theme-disabled-background-color
  );
}

@mixin make-form-control-states {
  &:hover:enabled,
  &:hover {
    @include make-form-control-hover-state;
  }

  &:active:enabled,
  &.active,
  &:active:enabled:hover,
  &.active:hover {
    @include make-form-control-press-state;
  }

  &:focus,
  &:focus:enabled,
  &:focus:hover,
  &:focus:enabled:hover {
    @include make-form-control-focus-state;
  }

  &:disabled,
  &:hover:disabled,
  &.disabled {
    @include make-form-control-disabled-state;
  }

  &[readonly] {
    @include make-form-control-readonly-state;
  }

  &:focus {
    box-shadow: none;
    outline: 0;
  }

  &::-ms-expand {
    border: 0;
    background-color: transparent;
  }
}

@mixin make-form-control-states-hovered-label-top {
  ~ .label-top {
    @include make-label-top-hovered;
  }
}

@mixin make-form-control-states-pressed-label-top {
  ~ .label-top {
    @include make-label-top-pressed;
  }
}

@mixin make-form-control-states-focused-label-top {
  ~ .label-top {
    @include make-label-top-focused;
  }
}

@mixin make-form-control-states-disabled-label-top {
  ~ .label-top {
    @include make-label-top-disabled;
  }
}

@mixin make-form-control-states-dark {
  &:active:enabled,
  &.active,
  &:focus:enabled {
    background-color: $form-field-dark-theme-focused-background-color;

    @include make-form-control-states-focused-label-top-dark;
  }
}

@mixin make-form-control-states-focused-label-top-dark {
  ~ .label-top {
    @include make-label-top-focused-dark;
  }
}

@mixin make-textarea {
  @include make-form-input;
  @include make-border(0px 0px 1px 0px, $boschBlack);
  min-height: $grid-size * 12;
  resize: vertical;
}

@mixin make-textarea-dark {
  @include make-form-input-dark;
}

// Form validation states
//
// Used in forms.less to generate the form validation CSS for warnings, errors,
// and successes.

@mixin form-control-validation(
  $text-color: #555,
  $border-color: #ccc,
  $background-color: #f5f5f5
) {
  // Color the label and help text
  .help-block,
  .control-label,
  .radio,
  .checkbox,
  .radio-inline,
  .checkbox-inline,
  &.radio label,
  &.checkbox label,
  &.radio-inline label,
  &.checkbox-inline label {
    color: $text-color;
  }
  // Set the border and box shadow on specific inputs to match
  .form-control {
    border-color: $border-color;
    box-shadow: none;
    &:focus {
      border-color: darken($border-color, 10%);
      box-shadow: none;
    }
  }
  // Set validation states also for addons
  .input-group-addon {
    color: $text-color;
    border-color: $border-color;
    background-color: $background-color;
  }
  // Optional feedback icon
  .form-control-feedback {
    color: $text-color;
  }
}

// Form control sizing
//
// Relative text size, padding, and border-radii changes for form controls. For
// horizontal sizing, wrap controls in the predefined grid classes. `<select>`
// element gets special love because it's special, and that's a fact!
// [converter] $parent hack
@mixin input-size(
  $parent,
  $input-height,
  $padding-vertical,
  $padding-horizontal,
  $font-size,
  $line-height,
  $border-radius
) {
  #{$parent} {
    height: $input-height;
    padding: $padding-vertical $padding-horizontal;
    font-size: $font-size;
    line-height: $line-height;
    border-radius: 0px;
  }

  select#{$parent} {
    height: $input-height;
    line-height: $input-height;
  }

  textarea#{$parent},
  select[multiple]#{$parent} {
    height: auto;
  }
}
