/*!
  * Copyright (C) 2017 Robert Bosch GmbH Copyright (C) 2017 Robert Bosch Manufacturing Solutions GmbH, Germany. All rights reserved.
 */

@import '@bci-web-core/common-styles/sass/bci/bci-variables';
@import '@bci-web-core/common-styles/sass/bci/mixins/borders';
@import '@bci-web-core/common-styles/sass/bci/mixins/forms';

@mixin style-input-inside-form-field($color) {
  &:hover:enabled,
  &:hover,
  &:active:enabled,
  &.active,
  &:active:enabled:hover,
  &.active:hover,
  &:focus,
  &:focus:enabled,
  &:focus:hover,
  &:focus:enabled:hover,
  &:disabled,
  &:hover:disabled,
  &.disabled {
    background-color: $color;
    border-width: 0;
  }
}

input.mdc-slider__input {
  /* this input has to match the slider width, default input min-width causes mismatch between click area and visible slider area and thus an offset */
  min-width: auto;
}

.mat-mdc-form-field {
  min-width: 160px;
  display: flex;

  // overwrite angular default label focused color
  .mdc-text-field--filled:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-floating-label,
  .mdc-text-field--filled:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-floating-label--float-above {
    color: $boschBlack !important;
  }

  .mat-mdc-text-field-wrapper.mdc-text-field--filled,
  .mat-mdc-text-field-wrapper.mdc-text-field--outlined {
    display: flex;
    @include make-border(0px 0px 1px 0px, $boschBlack);
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    flex-shrink: 0;
    align-self: stretch;
    padding: 0;

    &:not(.mdc-text-field--disabled) {
      background-color: $form-field-default-theme-background-color;
    }

    .mat-mdc-form-field-focus-overlay {
      display: none;
    }

    // When no Label
    &.mdc-text-field--no-label {
      .mdc-text-field__input:not(.mat-mdc-chip-input),
      .mat-mdc-select {
        margin-top: 0;
      }

      .mat-mdc-form-field-infix {
        padding-top: 12px;
        padding-bottom: 11px;
      }
    }

    .mat-mdc-form-field-infix {
      padding: 4px 16px 3px 16px;
      min-height: 0;
    }

    .mat-mdc-form-field-flex {
      align-items: center;
    }

    // Label styling
    .mat-mdc-floating-label {
      overflow: hidden;
      color: $boschBlack;
      text-overflow: ellipsis;
      white-space: nowrap;
      height: 16px;
      /* Regular/Text S Regular */
      font-family: Bosch-Sans, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px; /* 150% */
      transform: translateY(-24px);
    }

    //Input styling
    .mdc-text-field__input:not(.mat-mdc-chip-input) {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      align-self: stretch;
      margin-top: 16px;
      color: $boschBlack;
      font-family: Bosch-Sans, sans-serif;
      font-weight: 400;
      font-size: 16px;
      line-height: 1.5;
      text-overflow: ellipsis;

      &::placeholder {
        color: $boschBlack;
        opacity: 0.5;
      }
    }
  }

  // Styles for readonly
  &:has(*[readonly]) {
    .mat-mdc-text-field-wrapper.mdc-text-field--filled,
    .mat-mdc-text-field-wrapper.mdc-text-field--outlined {
      background-color: $boschWhite;
    }
  }

  &:hover,
  &:active,
  &.mat-focused,
  &.mat-form-field-disabled {
    &:has(*[readonly]) {
      .mat-mdc-text-field-wrapper.mdc-text-field--filled,
      .mat-mdc-text-field-wrapper.mdc-text-field--outlined {
        background-color: $boschWhite !important;
      }
    }
  }

  *[readonly].mdc-text-field__input {
    // make readonly fields readable on firefox, since has() operator is not supported until 121
    background: transparent !important;
  }

  //Focus
  &.mat-focused,
  &:active.mat-focused,
  &:hover.mat-focused {
    .mat-mdc-text-field-wrapper.mdc-text-field--filled,
    .mat-mdc-text-field-wrapper.mdc-text-field--outlined {
      background-color: $form-field-default-theme-focused-background-color;
    }

    input,
    textarea,
    select {
      &:not([readonly]) {
        @include style-input-inside-form-field($form-field-default-theme-focused-background-color);
      }

      &[readonly] {
        @include style-input-inside-form-field($boschWhite);
      }
    }
  }

  //Hover
  &:not(.mat-focused):not(.mat-form-field-disabled):hover {
    .mat-mdc-text-field-wrapper.mdc-text-field--filled,
    .mat-mdc-text-field-wrapper.mdc-text-field--outlined {
      background-color: $form-field-default-theme-hovered-background-color;
    }

    input,
    textarea,
    select {
      &:not([readonly]) {
        @include style-input-inside-form-field($form-field-default-theme-hovered-background-color);
      }

      &[readonly] {
        @include style-input-inside-form-field($boschWhite);
      }
    }
  }

  //Pressed
  &:not(.mat-focused):not(.mat-form-field-disabled):active {
    .mat-mdc-text-field-wrapper.mdc-text-field--filled,
    .mat-mdc-text-field-wrapper.mdc-text-field--outlined {
      background-color: $form-field-default-theme-pressed-background-color;
    }

    input,
    textarea,
    select {
      &:not([readonly]) {
        @include style-input-inside-form-field($form-field-default-theme-pressed-background-color);
      }

      &[readonly] {
        @include style-input-inside-form-field($boschWhite);
      }
    }
  }

  //Disabled
  &.mat-form-field-disabled {
    .mat-mdc-text-field-wrapper.mdc-text-field--filled,
    .mat-mdc-text-field-wrapper.mdc-text-field--outlined {
      color: $boschGray55;
      background-color: $form-field-default-theme-disabled-background-color;
      @include make-border(0px 0px 1px 0px, $boschGray55);

      .mdc-text-field__input,
      .mat-mdc-floating-label,
      .mdc-text-field__input::placeholder {
        color: $boschGray55;
      }
    }

    .mat-mdc-form-field-icon-suffix,
    .mat-mdc-form-field-icon-prefix {
      .mat-mdc-icon-button {
        color: $boschGray55;
      }
    }

    .mat-mdc-form-field-text-prefix,
    .mat-mdc-form-field-text-suffix {
      color: $boschGray55;
    }

    input,
    textarea,
    select {
      &:not([readonly]) {
        @include style-input-inside-form-field($form-field-default-theme-disabled-background-color);
      }

      &[readonly] {
        @include style-input-inside-form-field($boschWhite);
      }
    }
  }

  // Hint and Error inside subscript wrapper
  .mat-mdc-form-field-subscript-wrapper {
    margin-bottom: 16px;

    &::before {
      display: none;
    }

    .mat-mdc-form-field-hint-spacer {
      flex: 0;
    }

    .mat-mdc-form-field-error {
      display: flex;
      color: $boschBlack;
    }

    .mat-mdc-form-field-hint-wrapper,
    .mat-mdc-form-field-error-wrapper {
      display: flex;
      flex-direction: column;
      padding: 0;
      position: relative;
    }

    mat-hint,
    mat-error {
      @include make-font-regular();
      padding: 4px 16px 4px 12px;
      line-height: 24px;
      flex-grow: 1;
      display: flex;
      align-items: flex-start;
      column-gap: 6px;
      color: $boschBlack;

      &:before {
        height: 24px;
        top: -3px;
      }
    }

    mat-hint {
      background-color: $boschBlue90;

      &:before {
        position: relative;
        color: $boschBlack;
        @include make-icon($Bosch-Ic-notification-info);
      }
    }

    mat-error {
      background-color: $boschRed90;

      &:before {
        position: relative;
        color: $boschBlack;
        @include make-icon($Bosch-Ic-notification-error-outline);
      }
    }

    mat-hint[success] {
      background-color: $boschGreen90;

      &:before {
        position: relative;
        color: $boschBlack;
        @include make-icon($Bosch-Ic-notification-success-outline);
      }
    }

    mat-error[warning] {
      background-color: $boschYellow90;

      &:before {
        position: relative;
        color: $boschBlack;
        @include make-icon($Bosch-Ic-notification-outline);
      }
    }
  }

  .mdc-line-ripple {
    display: none;
  }

  // Style changes for suffix / prefix
  [matsuffix],
  [matprefix] {
    display: inline-flex;
    align-items: center;
  }

  &.mat-focused {
    .mat-mdc-form-field-icon-suffix,
    .mat-mdc-form-field-icon-prefix {
      .mat-mdc-icon-button {
        background-color: $form-field-default-theme-background-color;

        &:hover {
          background-color: $form-field-default-theme-hovered-background-color;
        }

        &:active {
          background-color: $form-field-default-theme-pressed-background-color;
        }
      }
    }
  }

  .mat-mdc-form-field-icon-suffix,
  .mat-mdc-form-field-icon-prefix {
    padding: 0;

    > .mat-icon {
      padding: 11px 12px 12px;
    }

    .mat-mdc-icon-button {
      height: 47px;
    }
  }

  .mat-mdc-form-field-text-prefix,
  .mat-mdc-form-field-text-suffix {
    min-width: 24px;
    padding: 11px 12px 12px;
    display: flex;
    justify-content: center;
  }

  &.mat-mdc-form-field-has-icon-prefix,
  &.mat-mdc-form-field-has-icon-suffix {
    .mdc-text-field--no-label .mat-mdc-form-field-infix {
      padding-top: 12px;
      padding-bottom: 11px;
    }
  }
}
