@mixin make-border-color-grey {
  border-top-color: $boschGray40;
}
@mixin make-border-color-green {
  border-top-color: $boschSignalGreen;
}
@mixin make-border-color-blue {
  border-top-color: $boschBlue80;
}
@mixin make-border-color-yellow {
  border-top-color: $boschSignalYellow;
}
@mixin make-border-color-orange {
  border-top-color: $boschYellow85;
}
@mixin make-border-color-red {
  border-top-color: $boschSignalRed;
}

@mixin make-bci-tile-placeholder-text-component {
  display: grid;

  > span {
    white-space: nowrap;
    font-size: $font-size-small;
  }

  > span:first-of-type {
    color: $boschGray70;
    height: 17px;
  }

  > span:last-of-type {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@mixin make-bci-tile-textual-elements {
  display: flex;
  flex-wrap: wrap;

  > * {
    @include make-bci-tile-placeholder-text-component;
    margin: ($grid-size * 2) ($grid-size * 4) 0 0;
  }
}

@mixin make-bci-tile-action-elements {
  align-self: flex-end;
  display: flex;

  > :not(:last-child) {
    margin-right: $grid-size;
  }
}

@mixin make-bci-tile-large-text {
  @include make-bci-tile-placeholder-text-component;
  margin-top: $grid-size * 2;

  > :last-child {
    height: $grid-size * 4;
    white-space: unset;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
  }
}

@mixin make-bci-tile-content {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

@mixin make-bci-tile-content-double {
  > :first-child,
  > :last-child {
    @include make-bci-tile-content;
  }
}

@mixin make-bci-tile-title {
  @include make-bci-tile-placeholder-text-component;
  width: 100%;

  > span {
    width: 100%;
  }

  > span:last-of-type {
    font-size: $font-size-h4;
    line-height: 27px;
  }
}

@mixin make-bci-tile-header {
  display: flex;

  > * {
    align-self: flex-start;
  }
}

@mixin make-bci-tile {
  padding: ($grid-size * 3 - 4) ($grid-size * 3) ($grid-size * 3 - 1)
    ($grid-size * 3);
  width: 100%;
  height: $grid-size * 20;
  border-top: $grid-size solid;
  @include make-border-color-grey;
  background-color: $boschGray95;
  display: flex;
  flex: 1 0 ($grid-size * 37);
  flex-direction: column;
  justify-content: space-between;
}

@mixin make-bci-tile-view {
  display: flex;
  flex-flow: row wrap;
  margin-left: $grid-size * 4;
  > * {
    margin: ($grid-size * 4) ($grid-size * 4) 0 0;
  }
}
