/*!
  *  Copyright (C) 2017 Robert Bosch GmbH Copyright (C) 2017 Robert Bosch Manufacturing Solutions GmbH, Germany. All rights reserved.
 */

@mixin make-blockquote {
  padding: 10px 20px;
  margin: 0 0 20px;
  font-size: 17.5px;
  border-left: 4px solid $blockquote-border-color;
}

@mixin make-blockquote-footer {
  display: block;
  font-size: 80%;
  line-height: 1.428571429;
  color: $blockquote-footer-text-color;
}

@mixin make-blockquote-reverse {

}
