/*!
  * Copyright (C) 2019 Robert Bosch GmbH Copyright (C) 2019 Robert Bosch Manufacturing Solutions GmbH, Germany. All rights reserved.
 */

@import '@bci-web-core/common-styles/sass/bci/bci-variables';

mat-divider.mat-divider {
  border-top-width: 1px;
  border-top-color: $boschGray60;
}

mat-divider.mat-divider-vertical {
  border-right-color: $boschGray60;
}
