/*!
  *  Copyright (C) 2017 Robert Bosch GmbH Copyright (C) 2017 Robert Bosch Manufacturing Solutions GmbH, Germany. All rights reserved.
 */

@mixin make-icon-pseudo-element($font-size: 24px) {
  font-family: $font-family-icon-base;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  font-size: $font-size;

  vertical-align: middle;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin make-icon($icon-name, $font-size: 24px) {
  font-family: $font-family-icon-base;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  font-size: $font-size;

  content: $icon-name;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin make-icon-container($font-size: 24px) {
  line-height: 1; /*Changed to move little up when icon is used with text*/
  vertical-align: middle; /*Changed to middle when icon is used with texts*/

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &:before {
    @include make-icon-pseudo-element($font-size);
  }
}

@import "../bosch-ic";
@import "../bosch-ic-legacymapping";
