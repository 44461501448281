/*!
  * Copyright (C) 2017 Robert Bosch GmbH Copyright (C) 2017 Robert Bosch Manufacturing Solutions GmbH, Germany. All rights reserved.
 */

@import '@bci-web-core/common-styles/sass/bci/bci-variables';
@import '@bci-web-core/common-styles/sass/bci/mixins/typography';
@import '@bci-web-core/common-styles/sass/bci/mixins/borders';
@import '@bci-web-core/common-styles/sass/bci/mixins/icons';

mat-datepicker-content.mat-datepicker-content {
  box-shadow: none;
}

.mat-mdc-form-field-icon-suffix mat-datepicker-toggle {
  button.mdc-icon-button.mat-mdc-icon-button:before {
    display: inline-flex;
    border: 0;
    height: $grid-size * 3;
    width: $grid-size * 3;

    @include make-icon($Bosch-Ic-calendar, 24px);
    transform: translateY(-5px);
  }

  button.mat-mdc-icon-button {
    position: relative;
    padding: 12px;
    color: $boschBlack;

    .mat-button-wrapper {
      color: transparent;
    }

    .mat-button-ripple,
    .mat-button-focus-overlay,
    .mat-datepicker-toggle-default-icon {
      display: none;
    }

    &:hover,
    &.cdk-program-focused,
    &:active,
    &.cdk-keyboard-focused {
      .mat-mdc-button-persistent-ripple::before {
        opacity: 0;
      }
    }
  }

  .mat-button-focus-overlay {
    height: $button-size-small;
    top: 4px;
  }
}

mat-calendar.mat-calendar {
  @include make-border(1px, $boschGray70);
  height: auto;

  .mat-calendar-next-button::after {
    border-right-width: 1px;
    border-top-width: 1px;
  }

  .mat-calendar-previous-button::after {
    border-left-width: 1px;
    border-top-width: 1px;
  }

  .mat-calendar-header {
    padding: 8px;

    .mat-calendar-controls {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin: 0;
      padding: 0;

      .mat-calendar-period-button {
        order: 2;
        border: 0 none;
        flex-grow: 1;

        .mat-calendar-arrow {
          display: none;
        }

        .mat-button-focus-overlay {
          background-color: transparent;
        }
      }

      .mat-calendar-previous-button {
        order: 1;
      }

      .mat-calendar-next-button {
        order: 3;
      }

      .mat-calendar-spacer {
        display: none;
      }
    }
  }

  .mat-calendar-body-today.mat-calendar-body-selected {
    box-shadow: none;
  }

  .mat-calendar-table-header th {
    @include make-font-medium(14px);
    color: $boschBlack;
  }

  .mat-calendar-table-header-divider {
    padding: 0;
    display: none;
  }

  .mat-calendar-body-label {
    color: transparent;
  }

  .mat-calendar-content {
    padding-top: 16px;
    @include make-border(1px 0 0 0, $boschGray70);
  }
}
