/*!
  *  Copyright (C) 2017 Robert Bosch GmbH Copyright (C) 2017 Robert Bosch Manufacturing Solutions GmbH, Germany. All rights reserved.
 */

.clickable-element {
  @include make-clickable-element;
}

// Primary / Default Buttons
// --------------------------------------------------
button.button-primary,
button.button-default,
input.button-primary,
input.button-default {
  @include make-primary-button;

  &:not(.mat-mdc-icon-button):has(mat-icon) {
    @include button-padding-for-icon-with-label;
  }
}

// Secondary Buttons
// --------------------------------------------------
button.button-secondary,
input.button-secondary {
  @include make-secondary-button;

  &:not(.mat-mdc-icon-button):has(mat-icon) {
    padding: 0 15px 0 13px;
  }
}

// Tertiary Buttons
// --------------------------------------------------
button.button-tertiary,
input.button-tertiary {
  @include make-tertiary-button;

  &:not(.mat-mdc-icon-button):has(mat-icon) {
    @include button-padding-for-icon-with-label;
  }
}

// Integrated Buttons
// --------------------------------------------------
button.button-integrated,
input.button-integrated {
  @include make-integrated-button;

  &:not(.mat-mdc-icon-button):has(mat-icon) {
    @include button-padding-for-icon-with-label;
  }
}

// Link Buttons = Flat Buttons in Frontify
// -------------------------
button.button-link,
input.button-link {
  @include make-link-button;

  &.no-arrow {
    @include make-link-button-no-arrow;
  }

  &:not(.mat-mdc-icon-button):has(mat-icon) {
    @include button-padding-for-icon-with-label;
  }
}

// Block Buttons
// --------------------------------------------------
button.button-block,
input[type="submit"].button-block,
input[type="reset"].button-block,
input[type="button"].button-block {
  @include make-block-button;
}
