/*!
  *  Copyright (C) 2018 Robert Bosch GmbH Copyright (C) 2018 Robert Bosch Manufacturing Solutions GmbH, Germany. All rights reserved.
 */

@import "../bci-variables";
@import "buttons";

@mixin make-chip-base {
  @include make-button-base(
    $chip-height,
    $font-size-base,
    $chip-padding-vertical 6px $chip-padding-vertical $chip-padding-horizontal
  );
  display: inline-flex;
  cursor: default;
  color: $boschBlack;
  background-color: $boschGray90;
  border-radius: 16px;
}

@mixin make-chip {
  @include make-chip-base;
  @include make-font-regular($font-size-base, 40px);
  height: $chip-height;
}

@mixin make-chip-margin {
  margin: $grid-size * 0.5 $grid-size;
}

@mixin make-chip-list {
  display: flex;
  flex-wrap: wrap;

  & > * {
    margin: 0;
  }
}

@mixin make-chip-icon {
  text-align: center;
  box-sizing: border-box;
  display: inline-block;
  line-height: (
    $chip-height - 1px
  ); /* -1px to compensate for line-height rendering issue */
  height: (
    $chip-height - 1px
  ); /* -1px to compensate for line-height rendering issue */
  width: $chip-height;
  transform: translateY(0.5px);

  margin-right: -$chip-padding-horizontal;
  margin-bottom: 4px;

  &:before {
    font-size: $icon-font-size-small;
    line-height: $icon-font-size-small;
  }

  &:hover {
    font-weight: bold;
  }

  &.bosch-ic-clickable:hover,
  &.bosch-ic-clickable:hover:active {
    font-weight: bold;
  }
}

@mixin make-chip-disabled {
  background-color: $boschGray80;
  color: $boschWhite;
  border: none;
  outline: none;
  cursor: not-allowed;
  &:hover {
    background-color: $boschGray80;
  }
}
