/*!
  * Copyright (C) 2017 Robert Bosch GmbH Copyright (C) 2017 Robert Bosch Manufacturing Solutions GmbH, Germany. All rights reserved.
 */

@import '@bci-web-core/common-styles/sass/bci/bci-variables';
@import '@bci-web-core/common-styles/sass/bci/mixins/borders';
@import '@bci-web-core/common-styles/sass/bci/mixins/overlays';

.cdk-overlay-container {
  // it removes the triangle menu indicator for sub menus within nested menus
  .cdk-overlay-connected-position-bounding-box + .cdk-overlay-connected-position-bounding-box {
    .mat-menu-panel .mat-menu-content::after {
      content: none;
    }
  }

  .cdk-overlay-dark-backdrop {
    @include make-backdrop;
    backdrop-filter: blur(4px);
  }

  // needed to stick select panel at the top of form field if its open to the top
  .cdk-overlay-connected-position-bounding-box {
    .cdk-overlay-pane {
      transform: translateX(0px) translateY(0px) !important;
    }
  }

  // multiple selection dropdown: set selected option text font color
  .mat-mdc-select-panel.mat-primary,
  .mat-mdc-select-panel.mat-accent,
  .mat-mdc-select-panel.mat-warn {
    .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) {
      color: $font-color;
    }
  }
}

// ensure maximum/minimum width/height for VP1, 2, 3 and alert/modal (with and without additional title)
.cdk-overlay-pane.mat-mdc-dialog-panel,
.cdk-overlay-pane {
  // Modal VP3 and defaults for Alert VP3
  &.bci-modal-dialog,
  &.alert-dialog {
    max-width: 90%;
    max-height: 90%;
    --mat-dialog-container-max-width: 90%;
    min-width: min(620px, 90%);
    --mat-dialog-container-min-width: min(620px, 90%);
    min-height: 162px;

    &.additional-title {
      min-height: 211px;
    }
  }

  // special Alert VP3 settings
  &.alert-dialog {
    width: 640px;
  }

  // Modal VP2 and Alert VP2
  @media screen and (max-width: $screen-sm-min) {
    &.bci-modal-dialog,
    &.alert-dialog {
      min-height: 158px;

      &.additional-title {
        min-height: 207px;
      }
    }
  }

  // Modal P1 and Alert VP1
  @media screen and (max-width: $screen-xs-min) {
    &.bci-modal-dialog,
    &.alert-dialog {
      width: 100vw !important;
      height: 100vh !important;
      max-width: unset;
      max-height: unset;
      --mat-dialog-container-max-width: 100vh;
      --mat-dialog-container-small-max-width: 100vh;
      min-width: unset;
      --mat-dialog-container-min-width: unset;
      min-height: unset;

      &.additional-title {
        min-height: unset;
      }
    }
  }
}
