@font-face {
    font-family: "ctrlx-ic";
    src: url("./ctrlx-ic.ttf?8f58798e32d73acd1ff2c8e20179180e") format("truetype"),
url("./ctrlx-ic.woff?8f58798e32d73acd1ff2c8e20179180e") format("woff"),
url("./ctrlx-ic.woff2?8f58798e32d73acd1ff2c8e20179180e") format("woff2");
}

.ctrlx-ic, .mat-icon.mat-icon.ctrlx-ic {
    line-height: 1; /* same as font-size for proper relative vertical positioning at font type median */
    vertical-align: 5%; /* align icon to median (not middle!) of Bosch Font (https://en.wikipedia.org/wiki/Mean_line) */

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


.ctrlx-ic:before {
    font-family: "ctrlx-ic";
    font-size: 24px;
    font-style: normal;
    font-weight: normal;
    vertical-align: middle;
}

.ctrlx-ic-3d-viewer:before {
    content: "\f101";
}
.ctrlx-ic-archive:before {
    content: "\f102";
}
.ctrlx-ic-collapse-all:before {
    content: "\f103";
}
.ctrlx-ic-data-layer:before {
    content: "\f104";
}
.ctrlx-ic-drive-connect:before {
    content: "\f105";
}
.ctrlx-ic-drive-double-axis-inverter:before {
    content: "\f106";
}
.ctrlx-ic-drive-inverter:before {
    content: "\f107";
}
.ctrlx-ic-drive-single-axis-converter:before {
    content: "\f108";
}
.ctrlx-ic-drive-supplyunit:before {
    content: "\f109";
}
.ctrlx-ic-eject:before {
    content: "\f10a";
}
.ctrlx-ic-ethercat:before {
    content: "\f10b";
}
.ctrlx-ic-ethernet-frame:before {
    content: "\f10c";
}
.ctrlx-ic-ethernet:before {
    content: "\f10d";
}
.ctrlx-ic-expand-all:before {
    content: "\f10e";
}
.ctrlx-ic-factory-reset:before {
    content: "\f10f";
}
.ctrlx-ic-filter-fully-filled:before {
    content: "\f110";
}
.ctrlx-ic-flow:before {
    content: "\f111";
}
.ctrlx-ic-folder-ip-net-nfs:before {
    content: "\f112";
}
.ctrlx-ic-folder-ip-net-smb:before {
    content: "\f113";
}
.ctrlx-ic-globe-off-disabled-light:before {
    content: "\f114";
}
.ctrlx-ic-hard-disk-partitions:before {
    content: "\f115";
}
.ctrlx-ic-load-to-ctrlx-core:before {
    content: "\f116";
}
.ctrlx-ic-motion-axis-category-drive:before {
    content: "\f117";
}
.ctrlx-ic-motion-axis-category-encoder:before {
    content: "\f118";
}
.ctrlx-ic-motion-axis-encoder-cut-off-frequency:before {
    content: "\f119";
}
.ctrlx-ic-motion-axis-encoder-feed-constant:before {
    content: "\f11a";
}
.ctrlx-ic-motion-axis-encoder-filter:before {
    content: "\f11b";
}
.ctrlx-ic-motion-axis-encoder-gear-input:before {
    content: "\f11c";
}
.ctrlx-ic-motion-axis-encoder-gear-output:before {
    content: "\f11d";
}
.ctrlx-ic-motion-axis-encoder-resolution:before {
    content: "\f11e";
}
.ctrlx-ic-motion-axis-limit-acceleration-jerk:before {
    content: "\f11f";
}
.ctrlx-ic-motion-axis-limit-acceleration:before {
    content: "\f120";
}
.ctrlx-ic-motion-axis-limit-deceleration-jerk:before {
    content: "\f121";
}
.ctrlx-ic-motion-axis-limit-deceleration:before {
    content: "\f122";
}
.ctrlx-ic-motion-axis-limit-force-max:before {
    content: "\f123";
}
.ctrlx-ic-motion-axis-limit-position-max:before {
    content: "\f124";
}
.ctrlx-ic-motion-axis-limit-position-min:before {
    content: "\f125";
}
.ctrlx-ic-motion-axis-limit-time:before {
    content: "\f126";
}
.ctrlx-ic-motion-axis-limit-torque-max:before {
    content: "\f127";
}
.ctrlx-ic-motion-axis-limit-velocity-max:before {
    content: "\f128";
}
.ctrlx-ic-motion-axis-limit-velocity-min:before {
    content: "\f129";
}
.ctrlx-ic-motion-axis-linear-drive-gantry:before {
    content: "\f12a";
}
.ctrlx-ic-motion-axis-linear-drive:before {
    content: "\f12b";
}
.ctrlx-ic-motion-axis-linear-encoder:before {
    content: "\f12c";
}
.ctrlx-ic-motion-axis-rotational-drive-gantry:before {
    content: "\f12d";
}
.ctrlx-ic-motion-axis-rotational-drive:before {
    content: "\f12e";
}
.ctrlx-ic-motion-axis-rotational-encoder:before {
    content: "\f12f";
}
.ctrlx-ic-motion-axis-type-linear:before {
    content: "\f130";
}
.ctrlx-ic-motion-axis-type-rotational:before {
    content: "\f131";
}
.ctrlx-ic-motion-coordinate-x:before {
    content: "\f132";
}
.ctrlx-ic-motion-coordinate-y:before {
    content: "\f133";
}
.ctrlx-ic-motion-coordinate-z:before {
    content: "\f134";
}
.ctrlx-ic-motion-kin-axis-additional:before {
    content: "\f135";
}
.ctrlx-ic-motion-kin-axis-linear:before {
    content: "\f136";
}
.ctrlx-ic-motion-kin-axis-rotational:before {
    content: "\f137";
}
.ctrlx-ic-motion-kin-trafo-6axes:before {
    content: "\f138";
}
.ctrlx-ic-motion-kin-trafo-cartesian-xyz:before {
    content: "\f139";
}
.ctrlx-ic-motion-kin-trafo-delta-3axis:before {
    content: "\f13a";
}
.ctrlx-ic-motion-kin-trafo-scara:before {
    content: "\f13b";
}
.ctrlx-ic-motion-move-absolute:before {
    content: "\f13c";
}
.ctrlx-ic-motion-move-direct:before {
    content: "\f13d";
}
.ctrlx-ic-motion-move-jog-continuous:before {
    content: "\f13e";
}
.ctrlx-ic-motion-move-jog-step:before {
    content: "\f13f";
}
.ctrlx-ic-motion-move-linear:before {
    content: "\f140";
}
.ctrlx-ic-motion-move-relative:before {
    content: "\f141";
}
.ctrlx-ic-motion-orientation-x:before {
    content: "\f142";
}
.ctrlx-ic-motion-orientation-y:before {
    content: "\f143";
}
.ctrlx-ic-motion-orientation-z:before {
    content: "\f144";
}
.ctrlx-ic-motion-power-off:before {
    content: "\f145";
}
.ctrlx-ic-motion-power-on:before {
    content: "\f146";
}
.ctrlx-ic-motion:before {
    content: "\f147";
}
.ctrlx-ic-network:before {
    content: "\f148";
}
.ctrlx-ic-node-red:before {
    content: "\f149";
}
.ctrlx-ic-opc-ua:before {
    content: "\f14a";
}
.ctrlx-ic-plc:before {
    content: "\f14b";
}
.ctrlx-ic-profinet-device:before {
    content: "\f14c";
}
.ctrlx-ic-refresh:before {
    content: "\f14d";
}
.ctrlx-ic-remote-desktop:before {
    content: "\f14e";
}
.ctrlx-ic-reset-device-data:before {
    content: "\f14f";
}
.ctrlx-ic-root-folder:before {
    content: "\f150";
}
.ctrlx-ic-save-to-archive:before {
    content: "\f151";
}
.ctrlx-ic-source-control:before {
    content: "\f152";
}
.ctrlx-ic-storage-media:before {
    content: "\f153";
}
