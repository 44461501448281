/*!
  *  Copyright (C) 2022 Robert Bosch GmbH Copyright (C) 2022 Robert Bosch Manufacturing Solutions GmbH, Germany. All rights reserved.
 */

@import '@bci-web-core/common-styles/sass/bci/bci-variables';

mat-button-toggle.mat-button-toggle {
  color: $boschBlack;
  background-color: $boschGray90;
  height: 48px !important;

  &:hover {
    background-color: $boschGray80;
  }

  &:active {
    background-color: $boschGray70;
  }

  &.mat-button-toggle-disabled,
  &.mat-button-toggle-disabled:hover,
  &.mat-button-toggle-disabled:active {
    background-color: $boschGray90 !important;
    color: $boschGray70;
  }
}

mat-button-toggle.mat-button-toggle.mat-button-toggle-checked {
  color: $boschWhite;
  background-color: $boschBlue50;

  &:hover {
    background-color: $boschBlue40;
  }

  &:active {
    background-color: $boschBlue30;
  }

  &.mat-button-toggle-disabled,
  &.mat-button-toggle-disabled:hover,
  &.mat-button-toggle-disabled:active {
    background-color: $boschGray80 !important;
    color: $boschGray60;
  }
}

mat-button-toggle-group.mat-button-toggle-group {
  border-radius: unset;
}

.mat-button-toggle-checked .mat-button-toggle-focus-overlay {
  border-bottom: 0px !important;
}

.mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none !important;
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  border: none !important;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  padding: 12px 16px !important;
  line-height: 24px !important;
}

mat-button-toggle button mat-icon.mat-icon {
  margin: 0px !important;
  margin-right: 8px !important;
  display: inline;
}
