/*!
  *  Copyright (C) 2018 Robert Bosch GmbH Copyright (C) 2018 Robert Bosch Manufacturing Solutions GmbH, Germany. All rights reserved.
 */

// Table Cells

@function accentColorOf($color) {
  @return darken($color, $table-bg-accent-darken-by);
}

@mixin table-cell($color, $backgroundColor, $backgroundColorHover) {
  @if $color != "inherit" {
    border-bottom: 2px solid $color;
  }

  background: transparent;
  background-color: $backgroundColor;

  transition: background-color 0.1s;

  color: $color;
}

@mixin table-cell-hover($colorHover, $backgroundColor, $backgroundColorHover) {
  background-color: $backgroundColorHover;

  transition: background-color 0.2s;

  color: $colorHover;
}

@mixin table-cell-active(
  $colorActive,
  $backgroundColor,
  $backgroundColorActive
) {
  background-color: $backgroundColorActive;

  transition: background-color 0.2s;

  color: $colorActive;
}

@function getTableCellStylePlaceholder($state) {
  $prefix: "table-cell";
  @if ($state == "") {
    @return $prefix;
  }
  @return "#{$prefix}-#{$state}";
}

@mixin table-cells(
  $state,
  $color,
  $backgroundColor,
  $backgroundColorAccent: accentColorOf($backgroundColor),
  $colorHover: $color,
  $backgroundColorHover: $color,
  $backgroundColorAccentHover: accentColorOf($backgroundColorHover)
) {
  $placeholder: getTableCellStylePlaceholder($state);

  %#{$placeholder} {
    @include table-cell($color, $backgroundColor, $backgroundColorHover);
  }

  %#{$placeholder}-hover {
    @include table-cell-hover(
      $colorHover,
      $backgroundColor,
      $backgroundColorHover
    );
  }

  %#{$placeholder}-active {
    @include table-cell-active(
      $colorHover,
      $backgroundColor,
      $table-bg-pressed
    );
  }

  %#{$placeholder}-stripes {
    @include table-cell(
      $color,
      $backgroundColorAccent,
      $backgroundColorAccentHover
    );
  }

  %#{$placeholder}-stripes-hover {
    @include table-cell-hover(
      $colorHover,
      $backgroundColorAccent,
      $backgroundColorAccentHover
    );
  }

  %#{$placeholder}-stripes-active {
    @include table-cell-active(
      $colorHover,
      $backgroundColorAccent,
      $table-bg-accent-pressed
    );
  }

  %#{$placeholder}-stripes-columns {
    @include table-cell(
      $color,
      $backgroundColorAccent,
      $backgroundColorAccentHover
    );
  }

  %#{$placeholder}-stripes-columns-hover {
    @include table-cell-hover(
      $colorHover,
      $backgroundColorAccent,
      $backgroundColorAccentHover
    );
  }

  %#{$placeholder}-stripes-columns-active {
    @include table-cell-active(
      $colorHover,
      $backgroundColorAccent,
      $table-bg-accent-pressed
    );
  }
}

@mixin cell-with-indicator($border-color: $boschBlue50) {
  display: flex;

  @include make-color-circle($border-color, $grid-size * 2, 0 $grid-size 0 0);

  &::before {
    transform: translateY(4px);
  }
}

// predefined table row variants
@include table-cells(
  "",
  "inherit",
  $table-bg,
  $table-bg-accent,
  "inherit",
  $table-bg-hover,
  $table-bg-accent-hover
);
@include table-cells(
  "success",
  $state-success-text,
  $state-success-bg,
  $colorHover: $boschWhite
);
@include table-cells(
  "info",
  $state-info-text,
  $state-info-bg,
  $colorHover: $boschWhite
);
@include table-cells(
  "warning",
  $state-warning-text,
  $state-warning-bg,
  $colorHover: $boschWhite
);
@include table-cells(
  "danger",
  $state-danger-text,
  $state-danger-bg,
  $colorHover: $boschWhite
);
@include table-cells(
  "selected",
  $table-color-selected,
  $table-bg-selected,
  $backgroundColorAccent: $boschBlue90,
  $colorHover: $table-color-selected-hover,
  $backgroundColorHover: $table-bg-selected-hover
);

$tableRowStates: ("" "success" "info" "warning" "danger" "selected");
