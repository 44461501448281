/*!
  * Copyright (C) 2018 Robert Bosch GmbH Copyright (C) 2018 Robert Bosch Manufacturing Solutions GmbH, Germany. All rights reserved.
 */

@use 'sass:map';

@import '@bci-web-core/common-styles/sass/bci/bci-variables';
@import '@bci-web-core/common-styles/sass/bci/mixins/borders';
@import '@bci-web-core/common-styles/sass/bci/mixins/color-circle';
@import '@bci-web-core/common-styles/sass/bci/mixins/table-row';

// Table Rows
@function getMatStateSelector($state) {
  @if ($state == '') {
    @return '';
  }
  @return '.#{$state}';
}

$tables: (
  mat-table: (
    row: mat-row,
    cell: mat-cell,
  ),
  table: (
    row: 'tbody > tr',
    cell: td,
  ),
);

@mixin mat-table-row-variant($element, $state) {
  $stateSelector: getMatStateSelector($state);
  $elem: map.get($tables, $element);

  > #{map.get($elem, row)}.mat-mdc-row.mdc-data-table__row {
    > #{map.get($elem, cell)}.mat-mdc-cell.mdc-data-table__cell#{$stateSelector},
    &#{$stateSelector} > #{map.get($elem, cell)}.mat-mdc-cell.mdc-data-table__cell {
      @content;
    }
  }
}

@mixin mat-table-row-variant-hover($element, $state) {
  $stateSelector: getMatStateSelector($state);
  $elem: map.get($tables, $element);

  > #{map.get($elem, row)}.mat-mdc-row.mdc-data-table__row {
    > #{map.get($elem, cell)}.mat-mdc-cell.mdc-data-table__cell#{$stateSelector}:hover,
    &#{$stateSelector}:hover > #{map.get($elem, cell)}.mat-mdc-cell.mdc-data-table__cell:not(.mat-mdc-table-sticky) {
      @content;
    }
  }
}

@mixin mat-table-row-variant-active($element, $state) {
  $stateSelector: getMatStateSelector($state);
  $elem: map.get($tables, $element);

  > #{map.get($elem, row)}.mat-mdc-row.mdc-data-table__row {
    > #{map.get($elem, cell)}.mat-mdc-cell.mdc-data-table__cell#{$stateSelector}:active,
    &#{$stateSelector}:active > #{map.get($elem, cell)}.mat-mdc-cell.mdc-data-table__cell:not(.mat-mdc-table-sticky) {
      @content;
    }
  }
}

@mixin mat-table-row-variant-stripes($element, $state) {
  $stateSelector: getMatStateSelector($state);
  $elem: map.get($tables, $element);

  > #{map.get($elem, row)}.mat-mdc-row.mdc-data-table__row {
    > #{map.get($elem, cell)}.mat-mdc-cell.mdc-data-table__cell#{$stateSelector}:nth-of-type(even),
    &#{$stateSelector} > #{map.get($elem, cell)}.mat-mdc-cell.mdc-data-table__cell:nth-of-type(even) {
      @content;
    }
  }
}

@mixin mat-table-row-variant-stripes-hover($element, $state) {
  $stateSelector: getMatStateSelector($state);
  $elem: map.get($tables, $element);

  > #{map.get($elem, row)}.mat-mdc-row.mdc-data-table__row {
    > #{map.get($elem, cell)}.mat-mdc-cell.mdc-data-table__cell#{$stateSelector}:nth-of-type(even):hover,
    &#{$stateSelector}:hover > #{map.get($elem, cell)}.mat-mdc-cell.mdc-data-table__cell:nth-of-type(even) {
      @content;
    }
  }
}

@mixin mat-table-row-variant-stripes-active($element, $state) {
  $stateSelector: getMatStateSelector($state);
  $elem: map.get($tables, $element);

  > #{map.get($elem, row)}.mat-mdc-row.mdc-data-table__row {
    > #{map.get($elem, cell)}.mat-mdc-cell.mdc-data-table__cell#{$stateSelector}:nth-of-type(even):active,
    &#{$stateSelector}:active > #{map.get($elem, cell)}.mat-mdc-cell.mdc-data-table__cell:nth-of-type(even) {
      @content;
    }
  }
}

@mixin mat-table-row-variant-stripes-columns($element, $state) {
  $stateSelector: getMatStateSelector($state);
  $elem: map.get($tables, $element);

  > #{map.get($elem, row)}.mat-mdc-row.mdc-data-table__row:nth-of-type(odd) {
    > #{map.get($elem, cell)}.mat-mdc-cell.mdc-data-table__cell#{$stateSelector},
    &#{$stateSelector} > #{map.get($elem, cell)}.mat-mdc-cell.mdc-data-table__cell {
      @content;
    }
  }

  > #{map.get($elem, row)}.mat-mdc-row.mdc-data-table__row:nth-of-type(even) {
    &.selected > #{map.get($elem, cell)}.mat-mdc-cell.mdc-data-table__cell {
      background-color: $boschBlue95;
    }
  }
}

@mixin mat-table-row-variant-stripes-columns-hover($element, $state) {
  $stateSelector: getMatStateSelector($state);
  $elem: map.get($tables, $element);

  > #{map.get($elem, row)}.mat-mdc-row.mdc-data-table__row {
    &:nth-of-type(odd):hover > #{map.get($elem, cell)}.mat-mdc-cell.mdc-data-table__cell#{$stateSelector},
    &#{$stateSelector}:hover > #{map.get($elem, cell)}.mat-mdc-cell.mdc-data-table__cell {
      @content;
    }

    &:nth-of-type(even):hover > #{map.get($elem, cell)}.mat-mdc-cell.mdc-data-table__cell#{$stateSelector},
    &#{$stateSelector}:hover > #{map.get($elem, cell)}.mat-mdc-cell.mdc-data-table__cell {
      background-color: $boschGray90;
    }
  }
}

@mixin mat-table-row-variant-stripes-columns-active($element, $state) {
  $stateSelector: getMatStateSelector($state);
  $elem: map.get($tables, $element);

  > #{map.get($elem, row)}.mat-mdc-row.mdc-data-table__row {
    &:nth-of-type(odd):active > #{map.get($elem, cell)}.mat-mdc-cell.mdc-data-table__cell#{$stateSelector},
    &#{$stateSelector}:active > #{map.get($elem, cell)}.mat-mdc-cell.mdc-data-table__cell {
      @content;
    }

    &:nth-of-type(even):active > #{map.get($elem, cell)}.mat-mdc-cell.mdc-data-table__cell#{$stateSelector},
    &#{$stateSelector}:active > #{map.get($elem, cell)}.mat-mdc-cell.mdc-data-table__cell {
      background-color: $boschGray80;
    }
  }
}

@mixin mat-tables-row-variant-link($state) {
  $placeholder: getTableCellStylePlaceholder($state);

  @each $tableKey, $tableValue in $tables {
    #{$tableKey}.mat-mdc-table.table-striped-columns {
      @include mat-table-row-variant($tableKey, $state) {
        @extend %#{$placeholder};
      }
      @include mat-table-row-variant-hover($tableKey, $state) {
        @extend %#{$placeholder}-hover;
      }
      @include mat-table-row-variant-active($tableKey, $state) {
        @extend %#{$placeholder}-active;
      }
      @include mat-table-row-variant-stripes($tableKey, $state) {
        @extend %#{$placeholder}-stripes;
      }
      @include mat-table-row-variant-stripes-hover($tableKey, $state) {
        @extend %#{$placeholder}-stripes-hover;
      }
      @include mat-table-row-variant-stripes-active($tableKey, $state) {
        @extend %#{$placeholder}-stripes-active;
      }
    }
    #{$tableKey}.mat-mdc-table:not(.table-striped-columns) {
      @include mat-table-row-variant-stripes-columns($tableKey, $state) {
        @extend %#{$placeholder}-stripes-columns;
      }
      @include mat-table-row-variant-stripes-columns-hover($tableKey, $state) {
        @extend %#{$placeholder}-stripes-columns-hover;
      }
      @include mat-table-row-variant-stripes-columns-active($tableKey, $state) {
        @extend %#{$placeholder}-stripes-columns-active;
      }
    }
  }
}

@mixin mat-cell-with-indicator($border-color: $boschBlue50) {
  @include make-color-circle($border-color, $grid-size * 2, 0 $grid-size 0 0);

  &::before {
    transform: translateY(0);
  }
}

.mat-mdc-table {
  box-shadow: none !important;
  color: $font-color;

  .mat-mdc-cell.mdc-data-table__cell,
  .mat-mdc-footer-cell.mdc-data-table__cell {
    color: $font-color;
    border: 0px;
    // font-weight: $font-weight-regular;
  }

  .mat-mdc-header-row.mdc-data-table__header-row {
    border-bottom: $table-header-border-width solid $boschBlack;
    min-height: $grid-size * 6;
    padding: 0;
    height: auto;

    .mat-mdc-header-cell.mdc-data-table__header-cell {
      text-align: left;
      color: $font-color;
      padding: 0 16px;
      @include make-font-bold;
      align-self: stretch;
      border: 0px;

      &.mat-mdc-table-sticky {
        background-color: $boschWhite;
      }
    }
  }

  .mat-mdc-row.mdc-data-table__row {
    $min-value: $grid-size * 6;
    padding: 0;
    min-height: $min-value;
    height: auto;

    // vertical row indicators
    &.row-indicator-info {
      @include row-with-indicator($boschBlue50);
    }

    &.row-indicator-success {
      @include row-with-indicator($boschSignalGreen);
    }

    &.row-indicator-warning {
      @include row-with-indicator($boschSignalYellow);
    }

    &.row-indicator-error {
      @include row-with-indicator($boschSignalRed);
    }

    &.row-indicator-neutral {
      @include row-with-indicator($boschGray55);
    }

    &:not(:last-child) {
      border-bottom: $table-row-border-width solid $table-border-color;
    }

    mat-cell.mat-mdc-cell.mdc-data-table__cell {
      line-height: $line-height-base;
      padding: 12px 16px;
      display: flex;
      align-self: stretch;
      align-items: center;
      height: auto;
      min-height: $grid-size * 3;

      > * {
        margin: 0;
      }

      .mat-mdc-icon-button {
        height: $grid-size * 3;
        min-width: $grid-size * 3;
        line-height: 1.5;
        padding: 0;
      }

      &.mat-column-actions {
        padding: 12px;

        .mat-mdc-icon-button {
          line-height: 1.5;
          height: $grid-size * 3;
          min-width: $grid-size * 3;
          padding: 0;
          width: auto;

          .mat-icon {
            min-width: $grid-size * 3;
            height: auto;
          }
        }
      }

      // round cell indicators
      &.cell-indicator-info {
        @include mat-cell-with-indicator($boschBlue50);
      }

      &.cell-indicator-success {
        @include mat-cell-with-indicator($boschSignalGreen);
      }

      &.cell-indicator-warning {
        @include mat-cell-with-indicator($boschSignalYellow);
      }

      &.cell-indicator-error {
        @include mat-cell-with-indicator($boschSignalRed);
      }

      &.cell-indicator-neutral {
        @include mat-cell-with-indicator($boschGray55);
      }
    }

    .mat-mdc-cell.mdc-data-table__cell.mat-mdc-table-sticky {
      background-color: $boschWhite;
    }

    &.selected .mat-mdc-table-sticky {
      background-color: $boschBlue90;
    }
  }
}

table.mat-mdc-table {
  box-shadow: none !important;
  border-collapse: collapse;

  tr.mat-mdc-header-row.mdc-data-table__header-row {
    border-bottom: $table-header-border-width solid $table-border-color;
    height: 48px;
    padding: 0;

    th.mat-mdc-header-cell.mdc-data-table__header-cell {
      text-align: left;
      color: $font-color;
      padding: 0 16px;
      @include make-font-bold;
      border: 0px;
    }
  }

  tr.mat-mdc-row.mdc-data-table__row {
    padding: 0;

    &:not(:last-child) {
      border-bottom: $table-row-border-width solid $table-border-color;
    }

    td.mat-mdc-cell.mdc-data-table__cell {
      line-height: $line-height-base;
      padding: 8px 16px;
      border: 0px;
    }
  }
}

@each $tableRowState in $tableRowStates {
  @include mat-tables-row-variant-link($tableRowState);
}
