
 $path-to-assets: '@bci-web-core/common-styles';
 @import "@rexroth/core";
  

  .red-snackBar {
    background: rgb(255, 200, 0) !important;
  }

  /* You can add global styles to this file, and also import other style files */

  mat-expansion-panel.mat-expansion-panel:not([class*=mat-elevation-z]) .mat-expansion-panel-header .mat-content {
    font-weight: 500;
    font-size: 20px;
  }

  // // customize mat-progress-bar from bci-webcore which is dotted to blue color
  // .mat-progress-bar {
  //     .mat-progress-bar-buffer {
  //       background-color: #007bc0 !important;
  //     } 
  // }

