/*!
  * Copyright (C) 2017 Robert Bosch GmbH Copyright (C) 2017 Robert Bosch Manufacturing Solutions GmbH, Germany. All rights reserved.
 */
@use 'sass:math';

@import '@bci-web-core/common-styles/sass/bci/bci-variables';
@import '@bci-web-core/common-styles/sass/bci/mixins/typography';

mat-snack-bar-container.mat-mdc-snack-bar-container {
  border-radius: 0;
  margin: 0px !important; //to override the default margin coming from material
  padding: 20px 24px;
  box-shadow: 0px 0px 8px rgb(0 0 0 / 25%) !important;
  background-color: $boschWhite !important;

  .mdc-snackbar__surface {
    background-color: $boschWhite;
    box-shadow: none;
    padding: 0px;
  }

  .mat-mdc-simple-snack-bar {
    @include make-font-regular($font-size-base, 24px);
    gap: 16px;

    &:before {
      @include make-callout-icon();
      position: absolute;
      font-size: 20px !important;
      padding: 2px;
      color: $boschBlack;
      content: $Bosch-Ic-notification-info;
    }

    .mat-mdc-snack-bar-label {
      position: relative;
      margin-left: 32px;
      color: $boschBlack;
      padding: 0px;
    }
  }

  .mat-mdc-button.mat-mdc-snack-bar-action:not(:disabled) {
    --mat-mdc-button-persistent-ripple-color: none;
  }

  .mat-mdc-snack-bar-actions {
    margin: 0px;

    button.mat-mdc-snack-bar-action {
      @include make-integrated-button;
      height: $grid-size * 3;
      max-height: none;
      line-height: $grid-size * 2;
      color: $boschBlue50;
      padding: 0px;
      min-width: 0px;
      --mat-mdc-button-persistent-ripple-color: none;

      &:hover {
        .mdc-button__label {
          @include make-integrated-button-hover;
          color: $boschBlue40;
          text-decoration-line: underline;
        }
      }

      &:active {
        .mdc-button__label {
          @include make-integrated-button-active;
          color: $boschBlue30;
          text-decoration-line: underline;
        }
      }

      &:disabled,
      &.disabled {
        .mdc-button__label {
          @include make-integrated-button-disabled;
          color: $boschGray80;
        }
      }
    }
  }

  &.error {
    .mat-mdc-simple-snack-bar:before {
      content: $Bosch-Ic-notification-error-outline;
    }

    background-color: $boschWhite !important;
    color: $boschBlue50;
  }

  &.success {
    .mat-mdc-simple-snack-bar:before {
      content: $Bosch-Ic-notification-success-outline;
    }

    background-color: $boschWhite !important;
    color: $boschBlue50;
  }

  &.warning {
    .mat-mdc-simple-snack-bar:before {
      content: $Bosch-Ic-notification-outline;
    }

    background-color: $boschWhite !important;
    color: $boschBlue50;
  }
}
