@import "../bci-variables";

@mixin make-scrollbars() {
  /* width and height */
  &::-webkit-scrollbar {
    width: calc($scrollbar-width + 10px);
    height: calc($scrollbar-width + 10px);
    padding-right: 2px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $boschGray55;
    background-clip: padding-box;
    border: 5px solid transparent;
    min-height: 48px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: $boschGray30;
    background-clip: padding-box;
    border: 3px solid transparent;
  }
}

@mixin make-scrollbars-global() {
  /* width and height */
  ::-webkit-scrollbar {
    width: calc($scrollbar-width + 10px);
    height: calc($scrollbar-width + 10px);
    padding-right: 2px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $boschGray55;
    border: 5px solid transparent;
    background-clip: padding-box;
    min-height: 48px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: $boschGray30;
    background-clip: padding-box;
    border: 3px solid transparent;
  }
}
