/*!
  * Copyright (C) 2019 Robert Bosch GmbH Copyright (C) 2019 Robert Bosch Manufacturing Solutions GmbH, Germany. All rights reserved.
 */

@import '@bci-web-core/common-styles/sass/bci/bci-variables';
@import '@bci-web-core/common-styles/sass/bci/mixins/borders';
@import '@bci-web-core/common-styles/sass/bci/mixins/icons';

@mixin expansion-states {
  &:hover,
  &:focus {
    .mat-expansion-panel-header .mat-expansion-panel-header-title,
    .mat-expansion-panel-header .mat-content,
    .mat-expansion-panel-header .mat-expansion-indicator::after {
      color: $boschBlue50;
    }
  }

  &:active {
    .mat-expansion-panel-header .mat-expansion-panel-header-title,
    .mat-expansion-panel-header .mat-content,
    .mat-expansion-panel-header .mat-expansion-indicator::after {
      color: $boschBlue40;
    }
  }

  &[disabled] {
    .mat-expansion-panel-header .mat-expansion-panel-header-title,
    .mat-expansion-panel-header .mat-content,
    .mat-expansion-panel-header .mat-expansion-indicator::after {
      color: $boschGray80;
    }
  }
}

mat-expansion-panel.mat-expansion-panel,
mat-expansion-panel.mat-expansion-panel:not([class*='mat-elevation-z']) {
  box-shadow: none;
  background-color: none;
  @include make-border(1px 0 0 0, $boschGray60);
  @include expansion-states();

  &:first-of-type {
    border-radius: 0;
  }

  &.mat-expansion-panel-spacing {
    margin: 0;
  }

  &.mat-expansion-small {
    @include expansion-states();

    .mat-expansion-panel-header {
      min-height: 56px;
      height: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      padding: 0px;
      background: none;

      &:hover,
      &:active,
      &:focus {
        background: none;
      }

      .mat-expansion-indicator {
        &::after {
          @include make-icon($Bosch-Ic-arrow-expand-down, 24px);
          padding: 0px;
          transform: none;
          border: none;
          color: $boschBlack;
        }
      }

      .mat-expansion-panel-header-title,
      .mat-expansion-panel-header-description {
        padding: 16px 0px 16px 0px;
      }

      .mat-expansion-panel-header-description {
        @include make-font-regular($font-size-base);
        color: $boschBlack;
      }

      .mat-expansion-panel-header-title,
      .mat-content {
        @include make-font-bold($font-size-base);
        color: $boschBlack;
      }

      .mat-content {
        margin-right: $grid-size;
      }
    }

    .mat-expansion-panel-content {
      .mat-expansion-panel-body {
        @include make-font-regular();
        color: $boschBlack;
        padding: 0 0 $grid-size * 6 0;
      }

      .mat-action-row {
        border: none;
      }
    }
  }

  .mat-expansion-panel-header {
    min-height: 68px;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    padding: 0px;
    background: none;

    &:hover,
    &:active,
    &:focus {
      background: none !important;
    }

    .mat-expansion-indicator {
      &::after {
        @include make-icon($Bosch-Ic-arrow-expand-down, 36px);
        padding: 0px;
        transform: none;
        border: none;
        color: $boschBlack;
      }
    }

    .mat-expansion-panel-header-title,
    .mat-expansion-panel-header-description {
      padding: 17px 0px 17px 0px;
    }

    .mat-expansion-panel-header-description {
      @include make-font-regular($font-size-base);
      color: $boschBlack;
    }

    .mat-expansion-panel-header-title,
    .mat-content {
      @include make-font-bold(24px, 34px);
      color: $boschBlack;
    }

    .mat-content {
      margin-right: $grid-size * 1.5;
    }
  }

  .mat-expansion-panel-content {
    .mat-expansion-panel-body {
      @include make-font-regular();
      color: $boschBlack;
      padding: 0 $grid-size * 6 $grid-size * 6 0;
    }

    .mat-action-row {
      border: none;
    }
  }
}
