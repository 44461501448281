/*!
  * Copyright (C) 2017 Robert Bosch GmbH Copyright (C) 2017 Robert Bosch Manufacturing Solutions GmbH, Germany. All rights reserved.
 */

@import '@bci-web-core/common-styles/sass/bci/bci-variables';
@import '@bci-web-core/common-styles/sass/bci/mixins/typography';
@import '@bci-web-core/common-styles/sass/bci/mixins/borders';
@import '@bci-web-core/common-styles/sass/bci/mixins/icons';
@import '@bci-web-core/common-styles/sass/bci/mixins/content';

@mixin tab-chevron($font-icon, $icon-size) {
  border: none;
  transform: none;
  width: $grid-size * 3;
  height: $grid-size * 3;
  &::before {
    @include make-icon($font-icon, $icon-size);
  }
}

@mixin make-responsive-tab-padding() {
  @each $width, $padding in $content-padding {
    @media (min-width: $width) {
      .mdc-tab:first-child {
        margin-left: $padding;
      }

      .mat-mdc-tab-body-wrapper {
        padding-top: $grid-size * 2;
        padding-bottom: $grid-size * 2;
        padding-left: $padding;
        padding-right: $padding;
      }
    }
  }
}

.responsive-tab mat-tab-group.mat-mdc-tab-group,
mat-tab-group.mat-mdc-tab-group.responsive-tab {
  @include make-responsive-tab-padding();

  .mdc-tab {
    padding: 0px;
    // set margin for tabs
    // first & last tabs have higher margins, between tabs there is a fixed size on all view sizes
    @media screen {
      &:not(:last-child) {
        margin-right: $grid-size * 4;
      }
    }
  }
}

// header
.mat-mdc-tab-header {
  border: none;
  border-bottom: 1px solid $boschGray60 !important;
  // set pagination button size based on view size
  // takes same size as:
  //  - first tab, left margin size
  //  - content padding size
  .mat-mdc-tab-header-pagination {
    min-width: $grid-size * 6;

    &.mat-mdc-tab-header-pagination-disabled {
      display: none;
    }

    @media all and (max-width: $screen-sm-min) {
      min-width: $grid-size * 6;
      &.mat-mdc-tab-header-pagination-disabled {
        display: none;
      }
    }
    @media all and (max-width: $screen-xs-min) {
      min-width: $grid-size * 6;
      &.mat-mdc-tab-header-pagination-disabled {
        display: none;
      }
    }
  }
}

// individual tabs settings
.mat-mdc-tab-links .mdc-tab,
.mat-mdc-tab-labels .mdc-tab {
  min-width: 0;
  padding: 0;

  .mdc-tab__text-label {
    @include make-font-regular($font-size-h6);
    color: $font-color;
    line-height: $grid-size * 6;
    height: $grid-size * 6;
    padding: 0;
    text-align: center;
    min-width: $grid-size;
    opacity: 1;
  }

  &:hover {
    .mdc-tab__text-label {
      color: $boschBlue50;
    }

    .mdc-tab-indicator__content--underline {
      border-color: $boschBlue50;
    }
  }

  &:focus {
    .mdc-tab__text-label {
      color: $boschBlue40;
      background-color: transparent !important;
    }

    .mdc-tab-indicator__content--underline {
      border-color: $boschBlue40;
    }
  }

  &.mdc-tab--active {
    .mdc-tab__text-label {
      color: $boschBlue50;
    }

    .mdc-tab-indicator__content--underline {
      border-color: $boschBlue50;
    }

    &:hover {
      .mdc-tab__text-label {
        color: $boschBlue40;
      }

      .mdc-tab-indicator__content--underline {
        border-color: $boschBlue40;
      }
    }

    &:focus {
      .mdc-tab__text-label {
        color: $boschBlue30;
      }

      .mdc-tab-indicator__content--underline {
        border-color: $boschBlue30;
      }
    }
  }

  &.mat-mdc-tab-disabled {
    opacity: 1;
    cursor: default;

    .mdc-tab__text-label {
      color: $boschGray80;
    }

    .mdc-tab-indicator__content--underline {
      border-color: $boschGray80;
    }
  }

  .mdc-tab__ripple {
    display: none;
  }
}

@media screen {
  :not(.custom-tab-padding) {
    .mdc-tab:first-child {
      margin-left: 4 * $grid-size;
    }

    .mdc-tab:not(:last-child) {
      margin-right: $grid-size * 4;
    }

    .mat-mdc-tab-body-wrapper {
      padding: $grid-size * 2 $grid-size * 4;
    }
  }
}

// Use .no-margin class to mat-mdc-tab-group element to remove margin left from first tab
.mat-mdc-tab-group.no-margin .mdc-tab:first-child,
.mat-dialog-content .mat-mdc-tab-labels .mdc-tab:first-child {
  margin-left: 0;
}

// pagination enabled
.mat-mdc-tab-header-pagination-controls-enabled {
  // remove the margin, since the pagination controls are visible
  .mat-mdc-tab-links .mdc-tab,
  .mat-mdc-tab-labels .mdc-tab {
    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  .mat-mdc-tab-header-pagination-before,
  .mat-tab-header-rtl .mat-mdc-tab-header-pagination-after {
    padding-left: 0;
    box-shadow: none;

    .mat-mdc-tab-header-pagination-chevron {
      @include tab-chevron($bosch-ic-back-left, $grid-size * 3);
    }
  }

  .mat-mdc-tab-header-pagination-after,
  .mat-tab-header-rtl .mat-mdc-tab-header-pagination-before {
    padding-right: 0;
    box-shadow: none;

    .mat-mdc-tab-header-pagination-chevron {
      @include tab-chevron($bosch-ic-forward-right, $grid-size * 3);
    }
  }

  .mat-mdc-tab-header-pagination-disabled .mat-mdc-tab-header-pagination-chevron {
    display: none;
  }
}
