/*!
  *  Copyright (C) 2017 Robert Bosch GmbH Copyright (C) 2017 Robert Bosch Manufacturing Solutions GmbH, Germany. All rights reserved.
 */

@import "colors";

//
// Variables
// --------------------------------------------------

//== Typography
//
//## Font, line-height, and color for body text, headings, and more.
$font-family-base: "Bosch-Sans" !default;
$font-family-sans-serif: $font-family-base;
$font-family-sans: $font-family-base;
$font-family-serif: $font-family-base;
$font-family-monospace: $font-family-base;
$font-family-condensed: "Bosch-Sans-Condensed";

$font-family-icon-base: "Bosch-Ic" !default;

// font-size tiny, medium and extra-large are deprecated by v6, please update to small, medium or large.
$font-size-small: 12px;
$font-size-base: 16px;
$font-size-large: 20px;

$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-strong: 700;
$font-weight-black: 900;

$font-size-h1: 40px;
$font-size-h2: 32px;
$font-size-h3: 24px;
$font-size-h4: 20px;
$font-size-h5: 16px;
$font-size-h6: 16px;

// VP2 = Tablet
$font-size-h1-tablet: 32px;
$font-size-h2-tablet: 24px;
$font-size-h3-tablet: 20px;
$font-size-h4-tablet: 16px;
$font-size-h5-tablet: 16px;
$font-size-h6-tablet: 16px;

// VP1 = Mobile
$font-size-h1-mobile: 20px;
$font-size-h2-mobile: 16px;
$font-size-h3-mobile: 16px;
$font-size-h4-mobile: 16px;
$font-size-h5-mobile: 16px;
$font-size-h6-mobile: 16px;

$font-color: $boschBlack;

//== Icon Sizes

$icon-font-size-xlarge: 44px;
$icon-font-size-large: 28px;
$icon-font-size-medium: 24px;
$icon-font-size-normal: 20px;
$icon-font-size-small: 16px;
$icon-font-size-xsmall: 12px;

//== Transition Times
$transition-time: 0.8s;
$transition-time-half: $transition-time * 0.5;
$transition-time-quarter: $transition-time * 0.25;

//** Unit-less `line-height` for use in components like buttons.
$line-height-base: 1.5 !default; // 20/14
//** Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
$line-height-computed: floor(
  ($font-size-base * $line-height-base)
) !default; // ~20px

//** By default, this inherits from the `<body>`.
$headings-font-family: $font-family-base;
$headings-font-weight: 700;
$headings-color: inherit;

// Base Value for the grid size
$grid-size: 8px;

//== Media queries breakpoints
//
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.

// Extra small screen / phone
$screen-xxs-min: 320px;

// Extra small screen / phone
$screen-xs-min: 480px;

// Small screen / tablet
$screen-sm-min: 768px;

// Medium screen / desktop
$screen-md-min: 992px;

// Large screen / wide desktop
$screen-lg-min: 1280px;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max: ($screen-sm-min - 1);
$screen-sm-max: ($screen-md-min - 1);
$screen-md-max: ($screen-lg-min - 1);

//== Content
//
//## Define common padding for shown content for different view ports. This includes the header and content

$content-padding: (
  0px: 14px,
  // VP1
  $screen-sm-min: 16px,
  // VP2
  $screen-lg-min: 32px,
  // VP3
);

//== Components
//
//## Define common padding and border radius sizes and more. Values based on 14px text and 1.428 line-height (~20px to start).
$padding-base-vertical: 6px !default;
$padding-base-horizontal: 12px !default;

$padding-large-vertical: 10px !default;
$padding-large-horizontal: 16px !default;

$padding-small-vertical: 5px !default;
$padding-small-horizontal: 10px !default;

$padding-xs-vertical: 1px !default;
$padding-xs-horizontal: 5px !default;

$line-height-large: 1.3333333 !default; // extra decimals for Win 8.1 Chrome
$line-height-small: 1.5 !default;

$border-radius-base: 4px !default;
$border-radius-large: 6px !default;
$border-radius-small: 3px !default;

//== Dropdowns
$dropdown-z-index: 100;
$dropdown-font-size: $font-size-h6;
$dropdown-transition-time: 0.4s;
$dropdown-bg-color: $boschWhite;
$dropdown-dark-bg-color: $boschGray70;
$dropdown-border-transparent: 1px solid transparent;
$dropdown-border: 1px solid $boschGray70;

$dropdown-menu-color: $boschBlack;
$dropdown-menu-disabled-color: $boschGray70;
$dropdown-menu-hover-color: $boschBlack;
$dropdown-menu-hover-bg-color: $boschBlue80;
$dropdown-menu-active-color: $boschWhite;
$dropdown-menu-active-bg-color: $boschBlue50;
$dropdown-menu-selected-color: $boschWhite;
$dropdown-menu-selected-bg-color: $boschBlue35;

// colors for select dropdown

$dropdown-menu-select-bg-color: $boschGray90;
$dropdown-menu-select-bg-color-hover: $boschGray80;
$dropdown-menu-select-bg-color-active: $boschGray70;

$dropdown-menu-not-selected-color: $boschBlack;
$dropdown-menu-not-selected-bg-color: $boschWhite;
$dropdown-menu-not-selected-bg-color-hover: $boschGray90;
$dropdown-menu-not-selected-bg-color-pressed: $boschGray80;

$dropdown-menu-selected-color: $boschWhite;
$dropdown-menu-selected-bg-color: $boschBlue50;
$dropdown-menu-selected-bg-color-hover: $boschBlue40;
$dropdown-menu-selected-bg-color-pressed: $boschBlue30;

//== Tables
//
//## Customizes the `.table` component with basic values, each used across all table variations.
$table-border-color: $boschGray60;
$table-line-height: $line-height-base * 1.5;
$table-row-border-width: 1px;
$table-header-border-width: 1px;

//** Padding for `<th>`s and `<td>`s.
$table-cell-padding: 8px 12px;
//** Padding for cells in `.table-condensed`.
$table-condensed-cell-padding: 8px;

//== Form states and alerts
$state-success-text: $boschSignalGreen;
$state-success-border: $boschSignalGreen;
$state-success-bg: lighten($state-success-border, 40%);

$state-info-text: $boschBlue50;
$state-info-border: $boschBlue50;
$state-info-bg: lighten($state-info-border, 40%);

$state-warning-text: $boschSignalYellow;
$state-warning-border: $boschSignalYellow;
$state-warning-bg: lighten($state-warning-border, 40%);

$state-danger-text: $boschSignalRed;
$state-danger-border: $boschSignalRed;
$state-danger-bg: lighten($state-danger-border, 40%);

$state-selected-bg: $boschBlue50;

//** Default background color used for all tables.
$table-bg: transparent;
//** Background color used for `.table-striped`.
$table-bg-accent: $boschGray95;
//** Background color used for `.table-hover`.
$table-bg-hover: $boschGray90;
$table-bg-accent-darken-by: 5;
$table-bg-accent-hover: $boschGray85;
$table-bg-pressed: $boschGray80;
$table-bg-accent-pressed: $boschGray75;
$table-color-active: $boschBlue50;
$table-color-selected: inherit;
$table-bg-selected: $boschBlue95;
$table-color-selected-hover: inherit;
$table-bg-selected-hover: $table-bg-selected;

//== Typography
$muted-text-color: #777;
$primary-text-color: $boschBlue35;
$warning-text-color: $boschSignalYellow;
$danger-text-color: $boschSignalRed;
$success-text-color: $boschSignalGreen;
$success-text-bg-color: lighten($success-text-color, 40%);
$info-text-color: #008ecf;
$anchor-text-hover-color: #23527c;
$anchor-text-color: #337ab7;
$addr-text-dot-color: $boschGray80;
$blockquote-border-color: $boschGray95;
$blockquote-footer-text-color: $boschGray40;

//== Header
$supergraphic-height: 6px;
$header-logo-width: 132px;
$header-logo-width-screen-xs: 100px;
$header-height: 64px;
$header-height-screen-xs: 50px;

//== Breadcrumb
$breadcrumb-font-size: $font-size-h6;
$breadcrumb-separator-font-size: $font-size-h4;
$breadcrumb-color: $boschBlue50;
$breadcrumb-color-unlinked: $boschBlack;
$breadcrumb-color-last: $breadcrumb-color-unlinked; // deprecated
$breadcrumb-color-separator: $boschBlack;
$breadcrumb-element-height: 24px;

//== Sub Header
$sub-header-icon-color: $boschGray55;

//== Navigation Elements
$nav-tab-color: $boschBlack;
$nav-tab-hover-color: $boschBlue40;
$nav-tab-active-color: $boschBlue35;
$nav-tab-selected-color: $boschBlue50;
$nav-tab-disabled-color: $muted-text-color;
$nav-tab-font-size: $font-size-h6;
$nav-tab-line-height: $grid-size * 6;
$nav-tab-border-bottom-color: $boschGray90;
$nav-pill-background-color: $boschGray95;
$table-of-contents-color: $boschBlack;
$table-of-contents-border-color: $boschGray90;
$table-of-contents-hover-bg-color: $boschGray95;
$table-of-contents-hover-color: $info-text-color;

//== Alerts -- Deprecated!
$alert-font-weight: $font-weight-strong;
$alert-link-darkening-factor: 10%;
$alert-icon-font-size: $icon-font-size-xlarge; // former 44px, use variables;
$alert-close-font-size: $icon-font-size-normal; // former 21px
$alert-base-spacing: 16px;

$alert-success-bg: $boschGreen90;
$alert-success-text: $boschBlack;

$alert-info-bg: $boschBlue90;
$alert-info-text: $boschBlack;

$alert-warning-bg: $boschYellow90;
$alert-warning-text: $boschBlack;

$alert-danger-bg: $boschRed90;
$alert-danger-text: $boschBlack;

//== Callouts
$callout-font-weight: $font-weight-strong;
$callout-link-darkening-factor: 10%;
$callout-base-spacing: 8px;

$callout-success-bg: $boschGreen90;
$callout-success-text: $boschBlack;

$callout-info-bg: $boschBlue90;
$callout-info-text: $boschBlack;

$callout-warning-bg: $boschYellow90;
$callout-warning-text: $boschBlack;

$callout-danger-bg: $boschRed90;
$callout-danger-text: $boschBlack;

//== Dialogues
$modal-backdrop-blur-radius: 4px;
$modal-backdrop-dim-color: rgba($boschGray50, 0.25);

//== Pagination
$pagination-small-font-size: $font-size-small;
$pagination-small-size-base: 32px;
$pagination-medium-font-size: $font-size-base;
$pagination-medium-size-base: 40px;
$pagination-large-font-size: $font-size-large;
$pagination-large-size-base: 56px;

$pagination-default-font-color: $boschBlack;
$pagination-hover-bg-color: $boschGray90;
$pagination-pressed-bg-color: $boschGray80;
$pagination-disabled-bg-color: $boschWhite;
$pagination-disabled-font-color: $boschGray80;

$pagination-selected-bg-color: $boschBlue50;
$pagination-selected-hover-bg-color: $boschBlue40;
$pagination-selected-pressed-bg-color: $boschBlue30;
$pagination-selected-disabled-bg-color: $boschGray80;
$pagination-selected-disabled-font-color: $boschGray60;
$pagination-selected-font-color: $boschWhite;

$pagination-icon-default-color: $boschBlack;
$pagination-icon-hover-color: $boschBlue50;
$pagination-icon-pressed-color: $boschBlue40;
$pagination-icon-disabled-color: $boschGray80;

//== Path to assets
$path-to-assets: "../" !default;

//== Checkbox / RadioButton / Switch Sizes
$input-form-size: $grid-size * 3;

//== Buttonsizes
$button-size-large: $grid-size * 7;
$button-size-base: $grid-size * 6;
$button-size-small: $grid-size * 5;
$button-size-xsmall: $grid-size * 4;

//== Chips
$chip-height: 24px;
$chip-padding-vertical: $grid-size * 0.5;
$chip-padding-horizontal: $grid-size * 2;
$chip-margin-vertical: $grid-size * 1;
$chip-margin-horizontal: $grid-size * 2;

//== List, Tab
$list-tab-size-base: $grid-size * 7.5;

//== Formfield size
$form-field-size-base: $grid-size * 6;
$form-field-default-theme-background-color: $boschGray90;
$form-field-default-theme-hovered-background-color: $boschGray80;
$form-field-default-theme-pressed-background-color: $boschGray70;
$form-field-default-theme-focused-background-color: $boschBlue90;
$form-field-default-theme-disabled-background-color: $boschGray95;
$form-field-dark-theme-background-color: $boschWhite;
$form-field-dark-theme-focused-background-color: $boschGray95;
$form-field-disabled-color: $boschGray70;
$form-field-placeholder-color: $boschGray70;
$form-field-default-theme-focused-placeholder-color: $boschBlack;
$form-field-caret-color: $boschBlack;

//== select size
$select-size-base: $grid-size * 6;

//== size commandbar
$commandbar-size: $grid-size * 8;

//== size commandbar
$scrollbar-width: 6px;
