/*!
  * Copyright (C) 2017 Robert Bosch GmbH Copyright (C) 2017 Robert Bosch Manufacturing Solutions GmbH, Germany. All rights reserved.
 */

@import '@bci-web-core/common-styles/sass/bci/bci-variables';

$mat-radio-outer-circle-color: $boschGray60;
$mat-radio-outer-circle-color-hover: $boschGray50;
$mat-radio-outer-circle-color-active: $boschGray40;

$mat-radio-inner-circle-color-checked: $boschBlue50;
$mat-radio-inner-circle-color-checked-hover: $boschBlue40;
$mat-radio-inner-circle-color-checked-active: $boschBlue30;

$mat-radio-outer-circle-color-disabled: $boschGray90;

$mat-radio-inner-circle-color-checked-disabled: $boschGray80;
$mat-radio-outer-circle-color-checked-disabled: $boschGray60;

mat-radio-button.mat-mdc-radio-button {
  .mdc-radio .mat-radio-ripple {
    display: none;
  }

  .mdc-label {
    padding: 0px;
    font-size: $font-size-base;
  }

  // focus color not checked
  &.cdk-focused,
  &.cdk-keyboard-focused {
    .mdc-radio .mdc-radio__background {
      .mdc-radio__outer-circle {
        border-color: $mat-radio-outer-circle-color-hover !important;
      }
    }
  }

  // radio button base (not checked)
  .mdc-radio {
    height: 24px;
    width: 24px;
    padding: 8px;

    &:hover .mdc-radio__background,
    &:focus .mdc-radio__background {
      .mdc-radio__outer-circle {
        border-color: $mat-radio-outer-circle-color-hover !important;
      }
    }

    &:active .mdc-radio__background {
      .mdc-radio__outer-circle {
        border-color: $mat-radio-outer-circle-color-active !important;
      }
    }

    .mdc-radio__background {
      height: 24px !important;
      width: 24px !important;

      &::before {
        display: none !important;
      }

      .mdc-radio__inner-circle {
        transition: none !important;
      }

      .mdc-radio__outer-circle {
        border-width: 12px !important;
        border-color: $mat-radio-outer-circle-color !important;
      }
    }
  }

  // disabled styling
  .mdc-radio .mdc-radio__native-control:disabled {
    &:not(:checked) + .mdc-radio__background .mdc-radio__outer-circle {
      opacity: unset !important;
      border-color: $mat-radio-outer-circle-color-disabled !important;
    }

    &:checked {
      & + .mdc-radio__background .mdc-radio__inner-circle {
        opacity: unset !important;
        border-color: $mat-radio-inner-circle-color-checked-disabled !important;
      }

      & + .mdc-radio__background .mdc-radio__outer-circle {
        opacity: unset !important;
        border-color: $mat-radio-outer-circle-color-checked-disabled !important;
      }
    }
  }

  // checked styling
  &.mat-mdc-radio-checked {
    // focus color checked
    &.cdk-focused,
    &.cdk-keyboard-focused {
      .mdc-radio .mdc-radio__background {
        .mdc-radio__inner-circle {
          border-color: $mat-radio-inner-circle-color-checked-hover !important;
        }
      }
    }

    .mdc-radio .mdc-radio__background {
      .mdc-radio__outer-circle {
        border-color: $boschWhite !important;
      }

      .mdc-radio__inner-circle {
        border-width: 6px;
        border-color: $mat-radio-inner-circle-color-checked !important;
        transform: none !important;
        transition: none !important;
      }
    }

    .mdc-radio {
      &:hover .mdc-radio__background,
      &:focus .mdc-radio__background {
        .mdc-radio__inner-circle {
          border-color: $mat-radio-inner-circle-color-checked-hover !important;
        }
      }

      &:active .mdc-radio__background {
        .mdc-radio__inner-circle {
          border-color: $mat-radio-inner-circle-color-checked-active !important;
        }
      }
    }
  }
}
