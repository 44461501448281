/*!
  *  Copyright (C) 2022 Robert Bosch GmbH Copyright (C) 2022 Robert Bosch Manufacturing Solutions GmbH, Germany. All rights reserved.
 */

// class which should be only used in web core together with the value modificator button
span.span-for-button-value-modificator {
  // Value modificator Buttons
  // --------------------------------------------------
  button {
    @include make-value-modificator-button;
    padding: 12px;
  }
}

span.text-highlight {
  font-weight: bold;
}
