/*!
  *  Copyright (C) 2017 Robert Bosch GmbH Copyright (C) 2017 Robert Bosch Manufacturing Solutions GmbH, Germany. All rights reserved.
 */

@mixin make-breadcrumb-base() {
  white-space: nowrap;
  list-style: none;
  color: $breadcrumb-color-unlinked;
  text-decoration: none;
  font-size: $breadcrumb-font-size;
  font-weight: normal;
  height: $breadcrumb-element-height !important;
  line-height: $breadcrumb-element-height !important;

  * {
    font-size: $breadcrumb-font-size;
    color: $breadcrumb-color-unlinked;
  }

  &::after {
    font-family: "Bosch-Ic";
    content: $Bosch-IC-forward-right-small;
    color: $breadcrumb-color-separator;
    font-size: $breadcrumb-separator-font-size;
    font-weight: bold;
    font-weight: normal;
    margin: 0 0 !important;
    position: relative;
    top: 5px;
  }
}
@mixin make-breadcrumb-element-base() {
  @include make-breadcrumb-base;
}

@mixin make-breadcrumb-link() {
  color: $breadcrumb-color;

  * {
    color: $breadcrumb-color;
  }
}
@mixin make-breadcrumb-element-link() {
  @include make-breadcrumb-link;
}

@mixin make-breadcrumb-last() {
  &::after {
    content: "";
  }
}
@mixin make-breadcrumb-element-last() {
  @include make-breadcrumb-last;
}

@mixin make-breadcrumbs {
  display: flex;
  padding: 0;
  margin: 0;

  > * {
    @include make-breadcrumb-base;

    &:last-child {
      @include make-breadcrumb-last;
    }

    a {
      @include make-breadcrumb-link;
      text-decoration: none !important;

      &:last-child {
        @include make-breadcrumb-last;
      }

      &:hover {
        color: $boschBlue40;
      }

      &:active {
        color: $boschBlue30;
      }

      &:disabled {
        color: $boschGray80;
      }
    }
  }
}
@mixin make-breadcrumb {
  @include make-breadcrumb;
}
