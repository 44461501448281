/*!
  *  Copyright (C) 2017 Robert Bosch GmbH Copyright (C) 2017 Robert Bosch Manufacturing Solutions GmbH, Germany. All rights reserved.
 */

// Header for full screen viewport (consisting of the supergraphic, a logo-row and a toolbar-row)
// --------------------------------------------------
header.bci-header {
  .bci-logo-row {
    @include make-bci-logo-row;

    .breadcrumb {
      @include make-header-breadcrumb;
    }
  }

  .header-logo {
    @include make-header-logo;
  }

  .bci-toolbar-row {
    @include make-bci-toolbar-row;
  }
}
