/*!
  *  Copyright (C) 2017 Robert Bosch GmbH Copyright (C) 2017 Robert Bosch Manufacturing Solutions GmbH, Germany. All rights reserved.
 */

@mixin make-table {
  border-spacing: 0;

  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th,
      > td {
        padding: $table-cell-padding;
        line-height: $table-line-height;
        border-bottom: $table-row-border-width solid $table-border-color;
        @include make-font-regular;
      }
    }
  }
  // Bottom align for column headings
  > thead > tr > th {
    border-bottom: $table-header-border-width solid $table-border-color;
    text-align: left;
    @include make-font-bold;
  }
  // Account for multiple tbody instances
  > tbody + tbody {
    border-top: $table-header-border-width solid $table-border-color;
  }
  tr:last-child {
    border-bottom: $table-row-border-width solid $table-border-color;
  }
  tr:active {
    color: $table-color-active;
  }
}

@mixin make-table-striped {
  @include table-row-variant-stripes("") {
    @include table-cell("inherit", $table-bg-accent, $table-bg-accent-hover);
  }
}
