/*!
  *  Copyright (C) 2019 Robert Bosch GmbH Copyright (C) 2019 Robert Bosch Manufacturing Solutions GmbH, Germany. All rights reserved.
 */

// Deprecated styles

.callout {
    @include make-callout-base;
  
    .callout-content {
      @include make-callout-content;
    }
  
    .callout-icon {
      @include make-callout-icon;
    }
  
    .callout-close {
      @include make-callout-close;
    }
  }
  
  .callout-success {
    @include make-callout-success;
  }
  .callout-info {
    @include make-callout-info;
  }
  .callout-warn {
    @include make-callout-warn;
  }
  .callout-danger {
    @include make-callout-danger;
  }
