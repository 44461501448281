/*!
  *  Copyright (C) 2018 Robert Bosch GmbH Copyright (C) 2018 Robert Bosch Manufacturing Solutions GmbH, Germany. All rights reserved.
 */

.dialog-demo-body {
  position: relative;
  width: 50%;
  height: 200px;
  overflow: hidden;
}

.dialog-demo-content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 3 * $grid-size;

  @include make-backdrop-unblurred;
}

.dialog-demo-body:hover .dialog-demo-content {
  @include make-backdrop-blurred;
}

.dialog-demo-body:hover .dialog-backdrop {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  @include make-backdrop;
}

.dialog-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  background: $boschWhite;
  padding: 3 * $grid-size;
}
