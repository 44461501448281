/*!
  * Copyright (C) 2018 Robert Bosch GmbH Copyright (C) 2018 Robert Bosch Manufacturing Solutions GmbH, Germany. All rights reserved.
 */

@import '@bci-web-core/common-styles/sass/bci/bci-variables';
@import '@bci-web-core/common-styles/sass/bci/mixins/chips';

// option chip mat-chip-option
mat-chip-option.mat-mdc-chip.mat-mdc-chip-option {
  background-color: $boschGray90;
  border-radius: 16px;
  font-weight: $font-weight-regular;
  padding: 0px 2 * $grid-size;

  &.mat-mdc-standard-chip.mdc-evolution-chip--with-primary-graphic .mdc-evolution-chip__action--primary,
  &.mat-mdc-standard-chip.mdc-evolution-chip--with-primary-graphic .mdc-evolution-chip__graphic {
    padding: 0;
    overflow: hidden;
  }

  &.mat-mdc-standard-chip .mdc-evolution-chip__cell--primary,
  &.mat-mdc-standard-chip .mat-mdc-chip-action-label {
    overflow: hidden;
  }

  &:hover,
  &:focus {
    background-color: $boschGray80;
  }

  &:active {
    background-color: $boschGray70;
  }

  &.mat-mdc-chip-selected,
  &.mdc-evolution-chip--selected {
    background-color: $boschBlue50;

    &:hover,
    &:focus {
      background-color: $boschBlue40;
    }

    &:active {
      background-color: $boschBlue30;
    }

    .mdc-evolution-chip__graphic.mat-mdc-chip-graphic {
      display: none;
    }
  }

  &.mdc-evolution-chip--disabled {
    opacity: unset;
    background-color: $boschGray90;

    &.mat-mdc-chip-selected,
    &.mdc-evolution-chip--selected {
      background-color: $boschGray80;
      color: $boschGray45;
    }

    .mdc-evolution-chip__text-label {
      color: $boschGray45;
    }
  }
}

// standard chip mat-chip
mat-chip.mat-mdc-chip.mat-mdc-standard-chip {
  background-color: $boschGray90;
  border-radius: 16px;
  font-weight: $font-weight-regular;
  padding: 0px 16px;

  .mdc-evolution-chip__action {
    padding: 0;
  }

  &:hover,
  &:focus {
    background-color: $boschGray80;
  }

  &:active {
    background-color: $boschGray70;
  }

  &.mdc-evolution-chip--disabled {
    opacity: unset;

    .mdc-evolution-chip__text-label {
      color: $boschGray70;
    }
  }
}

// basic chip mat-basic-chip
mat-basic-chip.mat-mdc-chip {
  display: inline-flex;
  height: 32px;
  background-color: $boschGray90;
  padding: 0px 16px;

  &:hover,
  &:focus {
    background-color: $boschGray80;
  }

  &:active {
    background-color: $boschGray70;
  }

  &[disabled] {
    background-color: $boschGray90;
    color: $boschGray70;
  }
}

// chip row mat-chip-row
mat-chip-row.mat-mdc-chip-row {
  display: inline-flex;
  height: 32px;
  background-color: $boschGray90;
  padding: 0px 16px;

  &.mat-mdc-standard-chip.mdc-evolution-chip--with-trailing-action .mdc-evolution-chip__action--primary {
    padding-left: 0px;
  }

  &.mat-mdc-standard-chip .mdc-evolution-chip__action--primary {
    padding: 0px;
  }

  &.mat-mdc-standard-chip.mdc-evolution-chip--with-trailing-action .mdc-evolution-chip__action--trailing {
    padding: 0px;
    opacity: unset;

    .mat-icon {
      margin-right: -10px;
      display: inline-flex;
      align-items: center;
    }
  }

  &:hover,
  &:focus {
    background-color: $boschGray80;
  }

  &:active {
    background-color: $boschGray70;
  }

  &[disabled] {
    background-color: $boschGray90;
    color: $boschGray70;
  }
}

// all chips
mat-chip.mat-mdc-chip,
mat-basic-chip.mat-mdc-chip,
mat-chip-row.mat-mdc-chip-row,
mat-mdc-chip.mat-mdc-chip-option {
  margin: $grid-size * 0.5;
  border-radius: 16px;
  font-weight: $font-weight-regular;
}

.mat-mdc-chip-action-label {
  display: inline-flex;
  height: 24px;
  line-height: 24px;
}

// Styles for Input Chip list autocomplete
.mat-mdc-form-field.mat-mdc-form-field-type-chip-list-input-autocomplete {
  .mat-mdc-form-field-infix {
    padding: 0px !important;
  }

  .mat-mdc-text-field-wrapper {
    background-color: $boschWhite !important;
    border-bottom: none !important;
  }

  .mat-mdc-floating-label {
    padding-left: 16px;
  }

  .mat-mdc-chip-input {
    color: $boschBlack;
    background-color: $form-field-default-theme-background-color;
    border-bottom: 1px solid $boschBlack !important;
    font-family: Bosch-Sans, sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.5;
  }

  .chip-list-inline .mat-mdc-chip-grid {
    background-color: $form-field-default-theme-background-color;

    &.readonlyUserInput {
      background-color: transparent !important;
    }
  }

  &.mat-focused:not(.mat-form-field-disabled) {
    .mat-mdc-chip-input:not([readonly]),
    .chip-list-inline .mat-mdc-chip-grid {
      background-color: $form-field-default-theme-focused-background-color;
    }
  }

  &:not(.mat-focused):not(.mat-form-field-disabled) {
    &:hover {
      .mat-mdc-chip-input:not([readonly]),
      .chip-list-inline .mat-mdc-chip-grid {
        background-color: $form-field-default-theme-hovered-background-color;
      }
    }

    &:active {
      .mat-mdc-chip-input:not([readonly]),
      .chip-list-inline .mat-mdc-chip-grid {
        background-color: $form-field-default-theme-pressed-background-color;
      }
    }
  }

  &.mat-form-field-disabled {
    .mat-mdc-chip-input:not([readonly]) {
      color: $boschGray55;
      background-color: $form-field-default-theme-disabled-background-color;
      border-bottom: 1px solid $boschGray55 !important;
    }

    .chip-list-inline .mat-mdc-chip-grid {
      color: $boschGray55;
      background-color: $form-field-default-theme-disabled-background-color;
    }

    .mat-mdc-standard-chip.mat-mdc-chip-row {
      background-color: $boschGray90;
      margin: 0px;
    }
  }

  // For Input Chiplist
  bci-chip-list-autocomplete {
    // For Chiplist Inline Style
    &.chip-list-inline {
      .mdc-text-field__input.mat-mdc-chip-input {
        margin-left: 0px;
        padding-left: 16px;
        flex-basis: content;
        padding-bottom: 3px;

        &::placeholder {
          color: $boschBlack;
          opacity: 0.5;
        }
      }

      .mdc-evolution-chip-set__chips {
        flex-direction: column;
        margin-left: 0px;
        padding-top: 20px;
      }

      .chip-input-container {
        display: flex;
        flex-wrap: wrap;
        padding-left: 8px;
      }
    }

    // For Chips Outside style
    &:not(.chip-list-inline) {
      .mdc-text-field__input.mat-mdc-chip-input {
        padding-top: 20px;
        margin-left: 0px;
        padding-left: 16px;
        padding-bottom: 3px;

        &::placeholder {
          color: $boschBlack;
          opacity: 0.5;
        }
      }

      .mdc-evolution-chip-set__chips {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        padding-top: 4px;
        margin-left: 0px;
      }

      .mdc-evolution-chip-set .mdc-evolution-chip {
        margin-left: 0px;
      }
    }

    //Secondary background styles for chips inside Input chip list
    .mat-mdc-standard-chip.mat-mdc-chip-row:not(.mdc-evolution-chip--disabled) {
      background-color: $boschGray85;

      &:hover {
        background-color: $boschGray75;
      }

      &:active {
        background-color: $boschGray65;
      }
    }

    .mat-mdc-standard-chip.mat-mdc-chip-row.mdc-evolution-chip--disabled {
      background-color: $boschGray90;
    }
  }
}
