/*!
  * Copyright (C) 2017 Robert Bosch GmbH Copyright (C) 2017 Robert Bosch Manufacturing Solutions GmbH, Germany. All rights reserved.
 */

@import '@bci-web-core/common-styles/sass/bci/bci-variables';
@import '@bci-web-core/common-styles/sass/bci/mixins/typography';
@import '@bci-web-core/common-styles/sass/bci/mixins/borders';

.mat-mdc-tooltip {
  max-height: $grid-size * 16;
  @include make-border(1px, $boschGray90);
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  background-color: $boschWhite;
  padding: 4px 12px;
  margin: $grid-size 14px;
  display: inline-flex;
  align-items: flex-start;
  gap: 10px;

  .mdc-tooltip__surface {
    font-weight: $font-weight-regular !important;
    font-size: 2 * $grid-size !important;
    line-height: 3 * $grid-size !important;
    color: $boschBlack !important;
    background-color: $boschWhite !important;
    text-overflow: inherit;
    word-wrap: break-word;
    padding: 0px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    white-space: pre-wrap;
    max-width: 250px;
  }

  &.error,
  &.error .mdc-tooltip__surface {
    background-color: $boschRed90 !important;
  }

  &.success,
  &.success .mdc-tooltip__surface {
    background-color: $boschGreen90 !important;
  }

  &.warning,
  &.warning .mdc-tooltip__surface {
    background-color: $boschYellow90 !important;
  }
}

//VP1 480px
@media screen and (max-width: $screen-xs-min) {
  .mat-mdc-tooltip.tooltip-width {
    max-width: 238px;
  }
}

//VP2 && VP3 768px
@media screen and (min-width: ($screen-xs-min + 1)) {
  .mat-mdc-tooltip.tooltip-width {
    max-width: 411px;
  }
}
