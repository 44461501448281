/*!
  *  Copyright (C) 2019 Robert Bosch GmbH Copyright (C) 2019 Robert Bosch Manufacturing Solutions GmbH, Germany. All rights reserved.
 */

// active
$radiobox-outer-color: $boschGray90;
$radiobox-outer-color-checked: $boschBlue35;
$radiobox-inner-color-checked: $boschWhite;

// disabled
$radiobox-outer-color-disabled: $boschGray90;
$radiobox-inner-color-disabled: $boschGray80;
$radiobox-label-color-disabled: $boschGray80;
