/*!
  *  Copyright (C) 2019 Robert Bosch GmbH Copyright (C) 2019 Robert Bosch Manufacturing Solutions GmbH, Germany. All rights reserved.
 */

@mixin make-text-skeleton($width: 5em, $height: 0.9em) {
  &:after {
    content: "";
    font-size: inherit;
    line-height: inherit;

    display: inline-block;
    width: $width;
    max-width: 100%;
    height: $height;

    background: linear-gradient(
      to right,
      $boschGray95 0%,
      $boschGray90 50%,
      $boschGray95 100%
    );
    background-position-x: 0;
    background-size: 200% 200%;
    animation: loadingFadeIn 0.5s 0.3s ease-in-out forwards,
      backgroundLeftRight 4s ease-in-out infinite alternate;
  }

  &.block {
    @include make-text-skeleton-block;
  }
}

@mixin make-text-skeleton-block {
  &:after {
    width: 100%;
    height: 100%;
  }
}
