/*!
  * Copyright (C) 2018 Robert Bosch GmbH Copyright (C) 2018 Robert Bosch Manufacturing Solutions GmbH, Germany. All rights reserved.
 */

@import '@bci-web-core/common-styles/sass/bci/bci-variables';
@import '@bci-web-core/common-styles/sass/bci/mixins/borders';
@import '@bci-web-core/common-styles/sass/bci/mixins/bci-list';

mat-list.mat-mdc-list,
mat-selection-list.mat-mdc-selection-list {
  border: none;
  padding: 0;

  *[md-subheader] {
    margin: 0;
    line-height: 48px;
    padding: 0 16px;
    background-color: $boschGray95;
    @include make-font-bold($font-size-h6);
  }

  &.bci-list {
    mat-list-item.mat-mdc-list-item,
    mat-list-option.mat-mdc-list-item.mat-mdc-list-option {
      @include bci-list();

      &.active {
        span > button > mat-icon {
          color: $boschWhite;
        }
      }
    }
  }

  button.mat-mdc-icon-button {
    padding: 0;
    height: $grid-size * 3;
    width: $grid-size * 3;
    //  @include make-border(0 0 1px 0, $boschGray60);
    margin-left: auto;
  }

  mat-list-item.mat-mdc-list-item,
  mat-list-option.mat-mdc-list-item.mat-mdc-list-option {
    font-size: $font-size-base;
    line-height: initial;
    padding: 12px 16px;
    min-height: $grid-size * 6;
    box-sizing: border-box;
    @include make-border(0 0 1px 0, $boschGray60);
    display: flex;
    gap: $grid-size;

    &:focus {
      outline: none;
    }

    .mdc-list-item__content {
      min-height: $grid-size * 3;
      font-size: $font-size-base;
    }

    .mdc-list-item__primary-text {
      @include make-font-regular(16px);
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;
    }

    &:hover {
      background-color: $boschGray90;
    }

    &:active {
      background-color: $boschGray80;
    }

    &.active {
      background-color: $boschBlue95;
      color: $boschBlack;

      span > button mat-icon {
        color: $boschBlack;
      }
    }
  }

  &[dense] {
    mat-list-item.mat-mdc-list-item,
    mat-list-option.mat-mdc-list-item.mat-mdc-list-option {
      height: auto;
      min-height: 40px;

      .mdc-list-item__content {
        min-height: 40px;
        padding: 8px 16px;
      }
    }
  }
}

mat-selection-list.mat-mdc-selection-list {
  .mdc-list-item .mdc-list-item__start {
    margin: 0px;
    transform: translate(-10px, -12px);
  }

  .mat-mdc-list-option .mdc-checkbox {
    padding: 0px;
  }

  .mdc-list-item__primary-text {
    display: flex;

    * {
      font-weight: 400;
    }
  }
}

mat-nav-list.mat-mdc-nav-list bci-sidebar-nav-item {
  .mdc-list-item__content {
    padding: 0 12px 0px 4px;

    .mat-list-text {
      padding-left: 6px;
    }
  }

  .mdc-list-item__primary-text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-content: center;
    color: white;
    gap: 8px;
  }

  p {
    margin: 0px;
  }
}

// Before is not required for any list item
.mat-mdc-list-item,
.mat-mdc-list-option {
  &::before,
  &:hover::before,
  &:focus::before {
    display: none;
  }
}
