/*!
  * Copyright (C) 2017 Robert Bosch GmbH Copyright (C) 2017 Robert Bosch Manufacturing Solutions GmbH, Germany. All rights reserved.
 */

// standard colors
$slide-toggle-deactivated-color: $boschGray60;
$slide-toggle-deactivated-color-hover: $boschGray50;
$slide-toggle-deactivated-color-active: $boschGray40;

// selected/activated colors
$slide-toggle-activated-color: $boschBlue50;
$slide-toggle-activated-color-hover: $boschBlue40;
$slide-toggle-activated-color-active: $boschBlue30;

// disabled colors
$slide-toggle-activated-thumb-color-disabled: $boschGray60;
$slide-toggle-deactivated-thumb-color-disabled: $boschGray70;
$slide-toggle-activated-color-disabled: $boschGray80;
$slide-toggle-deactivated-color-disabled: $boschGray90;

mat-slide-toggle.mat-mdc-slide-toggle {
  .mdc-label {
    cursor: pointer;
    font-size: $font-size-base;
    padding-left: 8px;
  }

  &[ng-reflect-disabled='true'],
  &[ng-reflect-disabled] {
    .mdc-label {
      cursor: default !important;
    }
  }

  .mdc-switch {
    width: 48px;
    height: 24px;
  }

  .mdc-switch .mdc-switch__ripple {
    display: none;
  }

  .mdc-switch .mdc-switch__shadow {
    display: none;
  }

  .mdc-switch .mdc-switch__icons {
    display: none;
  }

  &.cdk-focused,
  &.cdk-keyboard-focused {
    .mdc-switch .mdc-switch__track {
      &::after {
        background: $slide-toggle-activated-color-hover !important;
      }

      &::before {
        background: $slide-toggle-deactivated-color-hover !important;
      }
    }
  }

  .mdc-switch .mdc-switch__track {
    background: $slide-toggle-deactivated-color;
    border-radius: 12px;
    width: 48px;
    height: 24px;

    &::after {
      background: $slide-toggle-activated-color !important;
    }

    &::before {
      background: $slide-toggle-deactivated-color !important;
    }
  }

  &:hover,
  &:focus {
    .mdc-switch:not(.mdc-switch--disabled) .mdc-switch__track {
      &::after {
        background: $slide-toggle-activated-color-hover !important;
      }

      &::before {
        background: $slide-toggle-deactivated-color-hover !important;
      }
    }
  }

  &:active {
    .mdc-switch:not(.mdc-switch--disabled) .mdc-switch__track {
      &::after {
        background: $slide-toggle-activated-color-active !important;
      }

      &::before {
        background: $slide-toggle-deactivated-color-active !important;
      }
    }
  }

  .mdc-switch.mdc-switch--unselected:enabled .mdc-switch__handle::after,
  .mdc-switch.mdc-switch--selected:enabled .mdc-switch__handle::after {
    background: none !important;
  }

  .mdc-switch .mdc-switch__handle {
    height: 12px;

    &::before {
      width: 12px;
      height: 12px;
    }

    &::after {
      display: none;
    }
  }

  .mdc-switch.mdc-switch--unselected .mdc-switch__handle {
    &::before {
      left: 6px;
    }
  }

  .mdc-switch.mdc-switch--selected .mdc-switch__handle {
    &::before {
      left: 2px;
    }
  }

  .mdc-switch.mdc-switch--disabled.mdc-switch--unselected .mdc-switch__track {
    &::before,
    &::after {
      background: $slide-toggle-deactivated-color-disabled !important;
      opacity: unset !important;
    }
  }

  .mdc-switch:disabled .mdc-switch__track {
    opacity: unset !important;
  }

  .mdc-switch.mdc-switch--disabled.mdc-switch--selected .mdc-switch__track {
    &::before,
    &::after {
      background: $slide-toggle-activated-color-disabled !important;
      opacity: unset !important;
    }
  }

  .mdc-switch.mdc-switch--unselected.mdc-switch--disabled .mdc-switch__handle {
    &::before {
      background: $slide-toggle-deactivated-thumb-color-disabled;
    }
  }

  .mdc-switch.mdc-switch--selected.mdc-switch--disabled .mdc-switch__handle {
    &::before {
      background: $slide-toggle-activated-thumb-color-disabled;
    }
  }
}
