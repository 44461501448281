/*!
  *  Copyright (C) 2017 Robert Bosch GmbH Copyright (C) 2017 Robert Bosch Manufacturing Solutions GmbH, Germany. All rights reserved.
 */

@mixin tables-row-variant-link($state) {
  $placeholder: getTableCellStylePlaceholder($state);

  .table, .table-primary {
    @include table-row-variant($state) {
      @extend %#{$placeholder};
    }
  }

  .table-hover {
    @include table-row-variant-hover($state) {
      @extend %#{$placeholder}-hover;
    }
  }

  .table-striped {
    @include table-row-variant-stripes($state) {
      @extend %#{$placeholder}-stripes;
    }
  }

  .table-striped.table-hover {
    @include table-row-variant-stripes-hover($state) {
      @extend %#{$placeholder}-stripes-hover;
    }
  }

  .table-striped-columns {
    @include table-row-variant-stripes-columns($state) {
      @extend %#{$placeholder}-stripes;
    }
  }

  .table-striped-columns.table-hover {
    @include table-row-variant-stripes-columns-hover($state) {
      @extend %#{$placeholder}-stripes-hover;
    }
  }

}

@each $tableRowState in $tableRowStates {
  @include tables-row-variant-link($tableRowState);
}

.table-primary {
  @include make-table;
}

.table-striped {
  @include make-table-striped;
}


table {
  tr {
    // vertical row indicators
    &.row-indicator-info {
      @include row-with-indicator($boschBlue50);
    }

    &.row-indicator-success {
      @include row-with-indicator($boschSignalGreen);
    }

    &.row-indicator-warning {
      @include row-with-indicator($boschSignalYellow);
    }

    &.row-indicator-error {
      @include row-with-indicator($boschSignalRed);
    }

    &.row-indicator-neutral {
      @include row-with-indicator($boschGray55);
    }

    td {
      // round cell indicators
      &.cell-indicator-info {
        @include cell-with-indicator($boschBlue50);
      }

      &.cell-indicator-success {
        @include cell-with-indicator($boschSignalGreen);
      }

      &.cell-indicator-warning {
        @include cell-with-indicator($boschSignalYellow);
      }

      &.cell-indicator-error {
        @include cell-with-indicator($boschSignalRed);
      }

      &.cell-indicator-neutral {
        @include cell-with-indicator($boschGray55);
      }
    }
  }
}
