/*!
  *  Copyright (C) 2017 Robert Bosch GmbH Copyright (C) 2017 Robert Bosch Manufacturing Solutions GmbH, Germany. All rights reserved.
 */

.table-of-contents {
  @include make-table-of-contents;

  > a {
    @include make-table-of-contents-element;
  }
}
