/*!
  * Copyright (C) 2017 Robert Bosch GmbH Copyright (C) 2017 Robert Bosch Manufacturing Solutions GmbH, Germany. All rights reserved.
 */

@import '@bci-web-core/common-styles/sass/bci/bci-variables';
@import '@bci-web-core/common-styles/sass/bci/mixins/typography';

mat-toolbar.mat-toolbar {
  @include make-font-regular(16px);
  color: $font-color;
  background-color: $boschGray95;

  .mat-mdc-form-field {
    padding: 0;
  }
}

mat-toolbar-row.mat-toolbar-row {
  min-height: 8 * $grid-size;
}
