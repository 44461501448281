@import '@bci-web-core/common-styles/sass/bci/bci-variables';
@import '@bci-web-core/common-styles/sass/bci/mixins/typography';
@import '@bci-web-core/common-styles/sass/bci/mixins/borders';

.mat-stepper-horizontal.mat-stepper-label-position-bottom,
.mat-stepper-horizontal {
  .mat-step-header {
    flex: 1;
    padding: 0px;
    // pointer-events: none; - Uncomment to remove the clickable function on the header
  }

  //Hover state
  .mat-step-header:hover:not([aria-disabled]),
  .mat-step-header:hover[aria-disabled='false'] {
    background: transparent;

    .mat-step-icon-selected,
    .mat-step-icon-state-done,
    .mat-step-icon-state-edit {
      background: $boschBlue40;
      color: $boschWhite;
    }

    .mat-step-label.mat-step-label-active.mat-step-label-selected,
    .mat-step-label.mat-step-label-active {
      background-color: $boschGray90;

      .mat-step-text-label {
        color: $boschBlack;
      }
    }
  }

  //active state
  .mat-step-header:active:not([aria-disabled]),
  .mat-step-header:active[aria-disabled='false'] {
    background: transparent;

    .mat-step-icon-selected,
    .mat-step-icon-state-done,
    .mat-step-icon-state-edit {
      background: $boschBlue30;
      color: $boschWhite;
    }

    .mat-step-label.mat-step-label-active.mat-step-label-selected,
    .mat-step-label.mat-step-label-active {
      background-color: $boschGray80;

      .mat-step-text-label {
        color: $boschBlack;
      }
    }
  }

  //focus state
  .mat-step-header:focus:not([aria-disabled]),
  .mat-step-header:focus[aria-disabled='false'] {
    background: transparent;

    .mat-step-icon-selected,
    .mat-step-icon-state-done,
    .mat-step-icon-state-edit {
      background: $boschBlue30;
      color: $boschWhite;
    }

    .mat-step-label.mat-step-label-active.mat-step-label-selected,
    .mat-step-label.mat-step-label-active {
      background-color: $boschBlue95;

      .mat-step-text-label {
        color: $boschBlack;
      }
    }
  }

  .mat-step-header.cdk-keyboard-focused,
  .mat-step-header.cdk-focused,
  .mat-horizontal-stepper-header.cdk-keyboard-focused,
  .mat-horizontal-stepper-header.cdk-focused {
    &[aria-selected='false'] {
      .mat-step-icon {
        background: $boschBlue30;
        color: $boschWhite;
      }

      .mat-step-label.mat-step-label-active {
        background-color: $boschGray80;

        .mat-step-text-label {
          color: $boschBlack;
        }
      }
    }
  }

  //Disabled state
  .mat-step-header[aria-disabled='true'] {
    &:hover,
    &:active,
    &:focus {
      cursor: default;
    }

    .mat-step-icon {
      background: $boschGray90;
      color: $boschGray70;
    }
  }

  //Step icon
  .mat-step-icon {
    height: 32px;
    width: 32px;
  }

  //Selected Step icon
  .mat-step-header .mat-step-icon-selected,
  .mat-step-header[aria-selected='true'] .mat-step-icon {
    background: $boschBlue30;
    color: $boschWhite;
  }

  .mat-step-header .mat-step-icon-state-done,
  .mat-step-header .mat-step-icon-state-edit {
    background: $boschBlue50;
    color: $boschWhite;
  }

  .mat-step-icon-state-done + .mat-step-label.mat-step-label-active,
  .mat-step-icon-state-edit + .mat-step-label.mat-step-label-active {
    .mat-step-text-label {
      color: $boschBlack;
    }
  }

  .mat-step-label.mat-step-label-active.mat-step-label-selected {
    background-color: $boschBlue95;

    .mat-step-text-label {
      color: $boschBlack;
    }
  }

  // Step Label
  .mat-step-label,
  .mat-horizontal-stepper-header .mat-step-label,
  .mat-step-header .mat-step-label,
  .mat-step-header .mat-step-optional {
    color: $boschGray80;
    font-size: $font-size-small;
    font-weight: $font-weight-regular;
    line-height: 18px;
    padding: 0px;
    margin-top: 4px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5; /* start showing ellipsis when 5th line is reached */
    white-space: pre-wrap; /* let the text wrap preserving spaces */

    .mat-step-text-label {
      overflow: visible;
      padding: 0px 12px;
    }

    &.mat-step-label-active {
      color: $boschBlack;
    }
  }

  // Horizontal line
  .mat-horizontal-stepper-header:not(:last-child)::after,
  .mat-horizontal-stepper-header:not(:first-child)::before //
  {
    top: 2 * $grid-size;
    width: calc(50% - 16px);
  }

  .mat-stepper-horizontal-line {
    flex: none;
    display: none;
  }

  // Selected Horizontal line
  .mat-horizontal-stepper-header[aria-selected='true'] {
    & + .mat-stepper-horizontal-line::after {
      border-top-color: $boschGray60;
    }
  }

  .mat-horizontal-stepper-header::before,
  .mat-horizontal-stepper-header::after,
  .mat-stepper-horizontal-line {
    border-top-color: $boschGray60;
  }

  .mat-horizontal-content-container {
    padding: 24px;
  }

  // Stepper buttons
  .mat-stepper-previous,
  .mat-stepper-next {
    float: right;
    padding: 12px 16px;
    height: 48px;
    line-height: 24px;
  }

  // Step Icon
  .mat-step-icon .mat-icon {
    height: 24px;
    width: 24px;
  }

  .mat-step-icon-content {
    top: 50%;
    left: 50%;
  }

  // For small Step indicator
  &[small] {
    // Step Icon
    .mat-step-icon,
    .mat-step-icon .mat-icon {
      height: 16px;
      width: 16px;
    }

    // Horizontal line
    .mat-horizontal-stepper-header:not(:last-child)::after,
    .mat-horizontal-stepper-header:not(:first-child)::before //
    {
      top: $grid-size;
      width: calc(50% - 8px);
    }
  }

  @for $i from 0 through 9 {
    &.stepper--step0#{$i}-active {
      .mat-horizontal-stepper-header:nth-of-type(#{$i + 1}) {
        &::after {
          border-top-color: $boschBlue50;
        }
      }

      .mat-horizontal-stepper-header:nth-of-type(#{$i + 2})[aria-disabled='true'] .mat-step-icon {
        background: $boschBlue50;
        color: $boschWhite;
      }

      .mat-horizontal-stepper-header:nth-of-type(#{$i + 2})[aria-disabled='true'] .mat-step-label {
        color: $boschBlack;
      }

      .mat-horizontal-stepper-header:nth-of-type(#{$i + 2}) {
        &::before {
          border-top-color: $boschBlue50;
        }
      }

      /* Active headers that are preceded by the selected header (e.g. after navigating backwards) */
      .mat-horizontal-stepper-header[aria-selected='true'] ~ .mat-horizontal-stepper-header:nth-of-type(#{$i + 1}) {
        &.mat-step-header .mat-step-label.mat-step-label-active {
          color: $boschGray80;
        }
      }
    }
  }
}
