/*!
  * Copyright (C) 2018 Robert Bosch GmbH Copyright (C) 2018 Robert Bosch Manufacturing Solutions GmbH, Germany. All rights reserved.
 */

@import '@bci-web-core/common-styles/sass/bci/bci-variables';
@import '@bci-web-core/common-styles/sass/bci/mixins/borders';
@import '@bci-web-core/common-styles/sass/bci/mixins/icons';
@import '@bci-web-core/common-styles/sass/bci/bosch-ic';

@mixin paginator-navigation-button {
  .mat-mdc-paginator-icon {
    display: none;
  }

  &:before {
    @content;
  }
}

mat-paginator.mat-mdc-paginator {
  color: $font-color;
  font-size: $font-size-base;
  font-weight: $font-weight-regular;

  .mat-mdc-paginator-container {
    padding: 0;
    margin: $grid-size * 2;
    justify-content: space-between;

    .mat-mdc-paginator-page-size {
      .mat-mdc-paginator-page-size-label {
        margin: 0 $grid-size * 2 0 0;
      }

      .mat-mdc-paginator-page-size-select {
        padding: 0;
        margin: 0;

        .mat-mdc-text-field-wrapper {
          padding: 0px;

          .mat-mdc-form-field-flex {
            top: 0;
          }
        }

        .mat-mdc-select-trigger {
          font-size: $font-size-base;

          .mat-mdc-select-value {
            padding: 0 0 0 $grid-size * 2;
            line-height: $grid-size * 6;
          }
        }
      }
    }

    .mat-mdc-paginator-range-label {
      padding: 12px 32px 12px 24px;
      font-weight: $font-weight-regular;
      height: 24px;
      line-height: 24px;
    }

    .mat-mdc-paginator-navigation-previous {
      @include paginator-navigation-button {
        @include make-icon($bosch-ic-back-left, 18px);
      }

      &[disabled] {
        color: $boschGray60;
      }
    }

    .mat-mdc-paginator-navigation-next {
      @include paginator-navigation-button {
        @include make-icon($bosch-ic-forward-right, 18px);
      }

      &[disabled] {
        color: $boschGray60;
      }
    }
  }
}
