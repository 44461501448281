/*!
  *  Copyright (C) 2017 Robert Bosch GmbH Copyright (C) 2017 Robert Bosch Manufacturing Solutions GmbH, Germany. All rights reserved.
 */

@mixin make-text-muted {
  color: $muted-text-color;
}

@mixin make-text-primary {
  color: $primary-text-color;
}

@mixin make-text-warning {
  color: $warning-text-color;
}

@mixin make-text-danger {
  color: $danger-text-color;
}

@mixin make-text-success {
  color: $success-text-color;
}

@mixin make-text-info {
  color: $info-text-color;
}

@mixin make-bg-success {
  @include make-text-success;
  background-color: $success-text-bg-color;
  padding: 8px;
  margin: -8px;
}

@mixin make-anchor {
  color: $boschBlue50;
  font-weight: $font-weight-regular;
  text-decoration: none;

  &:not([routerLinkActive]):not(.mdc-tab):not(.disabled):hover,
  &:not([routerLinkActive]):not(.mdc-tab):not(.disabled):focus-visible {
    color: $boschBlue40;
    text-decoration: underline;
    outline: none;
  }

  &:not([routerLinkActive]):not(.mdc-tab):not(.disabled):active {
    color: $boschBlue30;
    text-decoration: underline;
  }

  &.disabled span,
  &.disabled,
  &.disabled:focus-visible {
    color: $boschGray80;
    cursor: default;
    outline: none;
  }
}

@mixin make-small-text {
  font-size: 87%;
}

@mixin make-font(
  $font-size: $font-size-base,
  $font-weight: $font-weight-light,
  $line-height: $line-height-base
) {
  font-family: $font-family-base;
  font-weight: $font-weight;
  font-size: $font-size;
  line-height: $line-height;
}

@mixin make-font-regular(
  $font-size: $font-size-base,
  $line-height: $line-height-base
) {
  @include make-font($font-size, $font-weight-regular, $line-height);
}

@mixin make-font-medium(
  $font-size: $font-size-base,
  $line-height: $line-height-base
) {
  @include make-font($font-size, $font-weight-medium, $line-height);
}

@mixin make-font-bold(
  $font-size: $font-size-base,
  $line-height: $line-height-base
) {
  @include make-font($font-size, $font-weight-strong, $line-height);
}

@mixin make-font-black(
  $font-size: $font-size-base,
  $line-height: $line-height-base
) {
  @include make-font($font-size, $font-weight-black, $line-height);
}

@mixin make-text-condensed(
  $font-size: $font-size-base,
  $line-height: $line-height-base,
  $font-weight: $font-weight-light
) {
  @include make-font($font-size, $font-weight, $line-height);
  font-family: $font-family-condensed;
}

@mixin make-addr-text {
  cursor: help;
  border-bottom: 1px dotted $addr-text-dot-color;
}

@mixin paragraph-text-large {
  margin: 24px 0px 16px 0px;
  font-size: $font-size-large;
}
