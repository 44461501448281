/*!
  * Copyright (C) 2017 Robert Bosch GmbH Copyright (C) 2017 Robert Bosch Manufacturing Solutions GmbH, Germany. All rights reserved.
 */

@import '@bci-web-core/common-styles/sass/bci/bci-variables';
@import '@bci-web-core/common-styles/sass/bci/mixins/typography';
@import '@bci-web-core/common-styles/sass/bci/mixins/buttons';

button.mat-raised-button,
button.mat-raised-button.mat-accent {
  @include make-primary-button;
  box-shadow: none;
  min-width: $grid-size * 12;

  &:hover,
  &:active,
  &:not([disabled]):active,
  &.active,
  &:focus,
  &.focus {
    box-shadow: none;
    outline: none;
  }

  &[disabled],
  fieldset[disabled] {
    //cursor: not-allowed;
  }
}

button {
  background-color: transparent;

  &.mat-fab:not([class*='mat-elevation-z']),
  &.mat-mini-fab:not([class*='mat-elevation-z']),
  &.mat-raised-button:not([class*='mat-elevation-z']),
  &.mat-fab:not([disabled]):active:not([class*='mat-elevation-z']),
  &.mat-mini-fab:not([disabled]):active:not([class*='mat-elevation-z']),
  &.mat-raised-button:not([disabled]):active:not([class*='mat-elevation-z']) {
    box-shadow: none;
  }
}

button.mat-button,
button.mat-mdc-button:not(:disabled) {
  @include make-secondary-button;
  min-width: $grid-size * 12;
}

button.mat-button-base {
  .mat-button-focus-overlay {
    background-color: transparent !important;
  }
}

a.mat-button {
  @include make-link-button;

  .mat-icon {
    margin-right: $grid-size;
  }

  .mat-button-focus-overlay {
    background-color: transparent !important;
  }

  .mat-button-ripple {
    display: none;
  }
}

.mat-calendar-controls {
  button.mat-mdc-icon-button,
  button.mat-mdc-icon-button.mat-mdc-button-base {
    min-width: $button-size-base;
    height: $grid-size * 6;
  }
}

button.mat-mdc-icon-button {
  width: auto;
  display: inline-flex;
  height: $button-size-base;
  padding: 12px;
  border-radius: 0px;
  background-color: transparent;
  line-height: 1.5;

  &:not(.mat-button-disabled)::before,
  &:not(.mdc-icon-button-disabled)::before {
    color: $boschBlack;
  }

  .mat-ripple,
  .mat-button-focus-overlay {
    width: $button-size-base;
  }
}

button.mat-fab {
  width: $grid-size * 6;
  height: $grid-size * 6;

  &.mat-primary {
    @include make-primary-button;
  }
}

button.mat-primary {
  @include make-primary-button;
}

.mat-mdc-icon-button {
  &:hover,
  &.cdk-program-focused,
  &:active,
  &.cdk-keyboard-focused {
    .mat-mdc-button-persistent-ripple::before {
      opacity: 0 !important;
    }
  }
}
