/*!
  * Copyright (C) 2017 Robert Bosch GmbH Copyright (C) 2017 Robert Bosch Manufacturing Solutions GmbH, Germany. All rights reserved.
 */

@import '@bci-web-core/common-styles/sass/bci/bci-variables';
@import '@bci-web-core/common-styles/sass/bci/mixins/typography';
@import '@bci-web-core/common-styles/sass/bci/mixins/borders';
@import '@bci-web-core/common-styles/sass/bci/mixins/icons';
@import '@bci-web-core/common-styles/sass/bci/mixins/checkbox';
@import '@bci-web-core/common-styles/sass/bci/mixins/vendor-prefixes';

.mdc-menu-surface.mat-mdc-select-panel {
  padding: 0px !important;
}

.mat-mdc-select-arrow svg {
  display: none;
}

mat-mdc-form-field.mat-mdc-paginator-page-size-select.mat-mdc-form-field-type-mat-select,
mat-form-field.mat-mdc-form-field.mat-mdc-form-field-type-mat-select {
  min-width: 192px;

  .mat-mdc-form-field-infix {
    padding: 0px !important;
  }

  .mdc-text-field--outlined .mdc-notched-outline {
    display: none;
  }

  .mat-mdc-text-field-wrapper.mdc-text-field--filled,
  .mat-mdc-text-field-wrapper.mdc-text-field--outlined {
    border: 0px;
  }

  .mat-mdc-floating-label {
    z-index: 1;
    padding-left: 16px;
  }

  .mat-mdc-select {
    display: grid;
    min-width: 192px;

    .mat-mdc-select-trigger {
      min-width: 192px;
    }

    .mat-mdc-select-placeholder {
      color: $boschBlack;
      opacity: 0.5;
    }

    &.mat-mdc-select-disabled {
      .mat-mdc-select-placeholder {
        color: $form-field-placeholder-color;
      }

      .mat-mdc-select-arrow {
        color: $boschGray70;
      }

      .mat-mdc-select-value-text {
        color: $boschGray55;
      }
    }
  }

  .mdc-text-field--no-label .mat-mdc-select-value {
    @include make-font-regular(16px);
    @include make-form-input-padding-without-label;
    padding-right: 0;
  }

  &.mat-mdc-form-field-label-always-float :not(.mdc-text-field--no-label) .mat-mdc-select-value {
    @include make-form-input-padding-with-label;
    padding-bottom: 4px;
    padding-right: 0;
  }

  .mat-mdc-select-arrow-wrapper {
    height: $grid-size * 6;
    padding: 0px;
    width: $grid-size * 6;
    transform: translateY(0px);
  }

  mat-select[aria-expanded='true'] {
    .mat-mdc-select-arrow,
    &:focus &.mat-primary .mat-mdc-select-arrow {
      border: 0;
      height: inherit;
      color: $boschBlack;

      &:before {
        @include make-icon($Bosch-Ic-arrow-expand-up, $grid-size * 3);
        @include translate(0, -50%);
        cursor: pointer;
        position: relative;
        line-height: $grid-size * 3;
      }
    }
  }

  .mat-mdc-select-arrow,
  &:focus &.mat-primary .mat-mdc-select-arrow {
    border: 0;
    height: inherit;
    color: $boschBlack;
    padding: 0px;
    width: $grid-size * 6;
    margin: 0px;

    &:before {
      @include make-icon($Bosch-Ic-arrow-expand-down, $grid-size * 3);
      @include translate(0, -50%);
      line-height: $grid-size * 3;
      position: relative;
      padding: $grid-size * 1.5;
      bottom: $grid-size * -1.5;
    }
  }

  &.ng-valid.bci-form-field-success {
    .mat-mdc-select-arrow {
      color: $font-color;
    }
  }

  &.ng-valid.bci-form-field-warning,
  .bci-form-field-warning {
    .mat-mdc-select-arrow {
      color: $font-color;
    }
  }

  &.mat-form-field-invalid.ng-invalid,
  .bci-form-field-error {
    .mat-mdc-select-arrow {
      color: $font-color;
    }
  }
}

.cdk-overlay-container .mat-mdc-select-panel {
  background-color: $boschWhite;
  min-width: 192px !important;
  width: 100%;
  max-width: 100% !important;
  box-shadow: 0px 0px 8px rgb(0 0 0 / 25%) !important;
  overflow-y: overlay !important;
  border-width: 0px !important;
  border-radius: 0 !important;
  position: relative;

  mat-option.mat-mdc-option {
    @include make-font-regular(16px);
    @include make-form-input-padding-without-label;
    line-height: 24px;
    min-height: 24px;

    .mdc-list-item__primary-text {
      color: $font-color;
    }

    &.mdc-list-item--disabled .mdc-list-item__primary-text {
      color: $dropdown-menu-disabled-color;
      opacity: 1;
    }

    .mat-mdc-option-pseudo-checkbox,
    .mat-mdc-option-pseudo-checkbox.mat-pseudo-checkbox-checked {
      width: $checkbox-size;
      height: $checkbox-size;
      @include make-border(0, transparent);
    }

    .mat-mdc-option-pseudo-checkbox::after {
      color: $boschWhite;
      width: 14px;
      height: 6px;
      bottom: 2px;
    }

    //  styling selection multiple disabled checked checkbox
    .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled::after {
      color: $boschGray60 !important;
    }

    .mat-ripple,
    .mat-mdc-option-ripple {
      display: none;
    }

    span {
      white-space: nowrap;
    }
  }

  mat-option.mat-mdc-option:not(.mat-mdc-option-multiple) {
    &.mat-mdc-option:hover:not(.mdc-list-item--disabled),
    &.mat-mdc-option:hover:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
      background-color: $dropdown-menu-not-selected-bg-color-hover;
      color: $dropdown-menu-not-selected-color;
    }

    &.mat-mdc-option:active:not(.mdc-list-item--disabled),
    &.mat-mdc-option:active:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
      background-color: $dropdown-menu-not-selected-bg-color-pressed;
      color: $dropdown-menu-not-selected-color;
    }

    &:hover.mdc-list-item--selected {
      &:not(.mdc-list-item--disabled),
      &:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
        background-color: $dropdown-menu-selected-bg-color-hover;
        color: $dropdown-menu-selected-color;
      }
    }

    &:active.mdc-list-item--selected {
      &:not(.mdc-list-item--disabled),
      &:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
        background-color: $dropdown-menu-selected-bg-color-pressed;
        color: $dropdown-menu-selected-color;
      }
    }

    &.mdc-list-item--selected,
    &.mat-active.mdc-list-item--selected {
      background: $dropdown-menu-selected-bg-color;
      color: $dropdown-menu-selected-color !important;

      .mdc-list-item__primary-text {
        color: $dropdown-menu-selected-color;
      }

      &:hover {
        background-color: $dropdown-menu-selected-bg-color-hover;
      }

      &:active {
        background-color: $dropdown-menu-selected-bg-color-pressed;
      }
    }
  }

  // styling selection with multiple options (checkboxes)
  mat-option.mat-mdc-option.mat-mdc-option-multiple {
    &.mat-mdc-option:hover:not(.mdc-list-item--disabled) {
      background-color: $dropdown-menu-not-selected-bg-color-hover;
      color: $dropdown-menu-not-selected-color;

      .mdc-list-item__primary-text {
        color: $dropdown-menu-not-selected-color;
      }
    }

    &.mat-mdc-option:active:not(.mdc-list-item--disabled) {
      background-color: $dropdown-menu-not-selected-bg-color-pressed;
      color: $dropdown-menu-not-selected-color;

      .mdc-list-item__primary-text {
        color: $dropdown-menu-not-selected-color;
      }
    }

    &:hover,
    &.mdc-list-item--selected {
      background-color: $boschWhite;

      .mdc-list-item__primary-text {
        color: $dropdown-menu-not-selected-color;
      }
    }

    .mat-mdc-option-pseudo-checkbox {
      background-color: $checkbox-background-color;
    }

    &:hover {
      .mat-mdc-option-pseudo-checkbox:not(.mat-pseudo-checkbox-disabled) {
        background-color: $boschGray50;
      }
    }

    &:hover:active {
      .mat-mdc-option-pseudo-checkbox:not(.mat-pseudo-checkbox-disabled) {
        background-color: $boschGray40;
      }
    }

    &:hover.mdc-list-item--selected {
      .mat-mdc-option-pseudo-checkbox:not(.mat-pseudo-checkbox-disabled) {
        background-color: $checkbox-background-color-hover;
      }
    }

    &:hover:active.mdc-list-item--selected {
      .mat-mdc-option-pseudo-checkbox:not(.mat-pseudo-checkbox-disabled) {
        background-color: $checkbox-background-color-active;
      }
    }

    .mat-pseudo-checkbox-checked {
      background-color: $checkbox-background-color-checked;
      opacity: 1;
    }

    .mat-pseudo-checkbox-disabled {
      background-color: $boschGray90;
      opacity: 1;
    }

    .mat-mdc-option-pseudo-checkbox.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked {
      background-color: $boschGray80 !important;
      opacity: 1;
    }
  }

  mat-optgroup.mat-mdc-optgroup {
    &[aria-disabled='true'] .mat-mdc-optgroup-label .mdc-list-item__primary-text {
      color: $dropdown-menu-disabled-color;
    }

    .mat-mdc-optgroup-label,
    .mat-mdc-optgroup-label .mdc-list-item__primary-text {
      @include make-font-bold(12px);
      color: $font-color;
      min-height: 18px;
    }

    .mat-mdc-optgroup-label {
      margin-top: $grid-size;
      padding: $grid-size $grid-size * 2 $grid-size $grid-size * 2;
    }
  }
}

.mat-mdc-form-field {
  &.mat-focused {
    &.mat-primary .mat-mdc-select-arrow {
      color: $boschBlack !important;
    }

    &.mat-mdc-form-field-type-mat-select .mat-mdc-text-field-wrapper.mdc-text-field--filled,
    &.mat-mdc-form-field-type-mat-select .mat-mdc-text-field-wrapper.mdc-text-field--outlined {
      background-color: $form-field-default-theme-background-color;
    }
  }
}

.mdc-text-field--filled.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label,
.mdc-text-field--filled.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label--float-above {
  color: $boschBlack !important;
}

.mat-mdc-option .mat-pseudo-checkbox-minimal {
  display: none;
}

.mat-mdc-option .mdc-list-item__primary-text {
  opacity: 1;
}

// Changes for hint and error within the bci-select
bci-select {
  .mat-mdc-form-field-error {
    display: flex;
    color: $boschBlack;
  }

  mat-hint,
  mat-hint.mat-mdc-form-field-bottom-align,
  mat-error {
    @include make-font-regular();
    padding: 4px 16px 4px 12px;
    line-height: 24px;
    flex-grow: 1;
    display: flex;
    align-items: flex-start;
    column-gap: 6px;
    color: $boschBlack;

    &:before {
      height: 24px;
      line-height: 24px;
    }
  }

  mat-hint,
  mat-hint.mat-mdc-form-field-bottom-align {
    background-color: $boschBlue90;

    &:before {
      position: relative;
      color: $boschBlack;
      @include make-icon($Bosch-Ic-notification-info);
    }
  }

  mat-error,
  mat-error.mat-mdc-form-field-bottom-align {
    background-color: $boschRed90;

    &:before {
      position: relative;
      color: $boschBlack;
      @include make-icon($Bosch-Ic-notification-error-outline);
    }
  }

  mat-hint[success] {
    background-color: $boschGreen90;

    &:before {
      position: relative;
      color: $boschBlack;
      @include make-icon($Bosch-Ic-notification-success-outline);
    }
  }

  mat-error[warning] {
    background-color: $boschYellow90;

    &:before {
      position: relative;
      color: $boschBlack;
      @include make-icon($Bosch-Ic-notification-outline);
    }
  }
}
