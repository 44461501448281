@mixin make-toolbar-item {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  height: 6 * $grid-size;
  width: 6 * $grid-size;
  background-color: $boschWhite;

  > *,
  > ::slotted(*) {
    display: block;
    margin: 0;
    color: $boschGray20;
    font-size: 3 * $grid-size;
    font-weight: bold;
    text-decoration: none;
    text-align: center;
    height: 3 * $grid-size;
    width: 3 * $grid-size;
    min-width: 3 * $grid-size;
    padding: 0;
    background-color: $boschWhite;
  }
}

@mixin make-toolbar-item-hover {
  background-color: $boschGray95;
  color: $boschBlack;
}

@mixin make-toolbar-item-active {
  transition: 0s;
  background-color: $boschGray90;
}

@mixin make-toolbar-spacing {
  height: 4 * $grid-size;
  border-left: 1px solid $boschGray85;
  margin: 8px;
}

@mixin make-toolbar() {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: center;
  background-color: $boschWhite;
  height: 6 * $grid-size;
  min-width: fit-content;
  max-width: fit-content;

  > .toolbar-spacing,
  > ::slotted(.toolbar-spacing) {
    @include make-toolbar-spacing();
  }

  > .toolbar-item,
  > ::slotted(.toolbar-item) {
    @include make-toolbar-item();
  }

  > .toolbar-item:hover,
  > ::slotted(.toolbar-item:hover) {
    @include make-toolbar-item-hover();
  }

  > .toolbar-item:active,
  > ::slotted(.toolbar-item:active) {
    @include make-toolbar-item-active();
  }
}
