/*!
  * Copyright (C) 2019 Robert Bosch GmbH Copyright (C) 2019 Robert Bosch Manufacturing Solutions GmbH, Germany. All rights reserved.
 */

@import '@bci-web-core/common-styles/sass/bci/bci-variables';

$slider-default-color: $boschBlue50;
$slider-hover-color: $boschBlue40;
$slider-active-color: $boschBlue30;
$slider-track-default-color: $boschGray60;
$slider-track-hover-color: $boschGray50;
$slider-track-active-color: $boschGray40;
$slider-disalbed-color: $boschGray80;
$slider-track-disabled-color: $boschGray90;
$slider-thumb-size: $grid-size * 3;
$slider-default-margin: 8px;
$slider-label-width: $grid-size * 7.25; // 58px

mat-slider.mat-mdc-slider {
  margin-right: $slider-default-margin;

  .mdc-slider__track {
    height: 2px;
    background-color: $slider-track-default-color;

    &--inactive {
      opacity: unset;
      height: 2px;
      background-color: unset;
    }

    &--active {
      height: 2px;
      top: unset;
      border-radius: unset;

      &_fill {
        border-color: $slider-default-color;
        border-top-width: 2px;
      }
    }
  }

  .mdc-slider__thumb {
    &-knob {
      width: $slider-thumb-size;
      height: $slider-thumb-size;
      background-color: $slider-default-color;
      border-color: $slider-default-color;
    }
  }

  // slider thumb label
  .mdc-slider__value-indicator-container {
    width: $slider-label-width;
    bottom: -40px;

    .mdc-slider__value-indicator {
      &::after,
      &::before {
        display: none;
      }

      align-items: center;
      justify-content: center;
      opacity: unset;
      background-color: $boschWhite;
      color: $boschBlack;
      border-radius: 0px;
      padding: 4px 12px;
      box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);

      .mdc-slider__value-indicator-text {
        font-size: $font-size-base;
        font-weight: 400;
        line-height: 24px;
      }
    }
  }

  // disabled styling
  &.mdc-slider--disabled {
    opacity: unset;

    .mdc-slider__track {
      background-color: $slider-track-disabled-color;

      &--inactive {
        background-color: unset;
      }

      &--active_fill {
        border-color: $slider-disalbed-color;
      }
    }

    .mdc-slider__thumb-knob {
      background-color: $slider-disalbed-color;
      border-color: $slider-disalbed-color;
    }
  }

  &:hover:not(.mdc-slider--disabled),
  &:focus:not(.mdc-slider--disabled) {
    .mdc-slider__track {
      background-color: $slider-track-hover-color;

      &--active_fill {
        border-color: $slider-hover-color;
      }
    }

    .mdc-slider__thumb-knob {
      background-color: $slider-hover-color;
      border-color: $slider-hover-color;
    }
  }

  &:active:not(.mdc-slider--disabled) {
    .mdc-slider__track {
      background-color: $slider-track-active-color;

      &--active_fill {
        border-color: $slider-active-color;
      }
    }

    .mdc-slider__thumb-knob {
      background-color: $slider-active-color;
      border-color: $slider-active-color;
    }
  }

  .mat-mdc-focus-indicator {
    display: none;
  }
}
