/*!
  *  Copyright (C) 2019 Robert Bosch GmbH Copyright (C) 2019 Robert Bosch Manufacturing Solutions GmbH, Germany. All rights reserved.
 */

@import "../mixins/icons";

.Bosch-IC-find-part:before {
  content: $bosch-ic-find-id;
}

.Bosch-IC-next-station:before {
  content: $bosch-ic-skip-forward-frame;
}

.Bosch-Ic-HMI:before {
  content: $bosch-ic-ui;
}

.Bosch-Ic-add-outline:before {
  content: $bosch-ic-add-frame;
}

.Bosch-Ic-apas:before {
  content: $bosch-ic-robot;
}

.Bosch-Ic-abort:before,
.bosch-ic-abort:before {
  content: $bosch-ic-close;
}

.Bosch-Ic-cash:before {
  content: $bosch-ic-cash-frame;
}

.Bosch-Ic-pinterest:before {
  content: $bosch-ic-pinterest-frame;
}

.Bosch-Ic-problem:before {
  content: $bosch-ic-problem-frame;
}

.Bosch-Ic-promotion:before {
  content: $bosch-ic-promotion-frame;
}

.Bosch-Ic-arrow-diagonal:before {
  content: $bosch-ic-arrow-right-up;
}

.Bosch-Ic-arrow-down-filled:before {
  content: $bosch-ic-arrow-down-frame;
}

.Bosch-Ic-arrow-down-circle:before {
  content: $bosch-ic-arrow-down-frame;
}

.Bosch-Ic-arrow-end-left:before {
  content: $bosch-ic-back-left-double;
}

.Bosch-Ic-arrow-end-right:before {
  content: $bosch-ic-forward-right-double;
}

.Bosch-Ic-arrow-left-down-filled:before {
  content: $bosch-ic-arrow-left-down-frame;
}

.Bosch-Ic-arrow-left-filled:before {
  content: $bosch-ic-arrow-left-frame;
}

.Bosch-Ic-arrow-left-up-filled:before {
  content: $bosch-ic-arrow-left-up-frame;
}

.Bosch-Ic-arrow-right-down-filled:before {
  content: $bosch-ic-arrow-right-down-frame;
}

.Bosch-Ic-arrow-right-filled:before {
  content: $bosch-ic-arrow-right-frame;
}

.Bosch-Ic-arrow-right-up-filled:before {
  content: $bosch-ic-arrow-right-up-frame;
}

.Bosch-Ic-arrow-up-filled:before {
  content: $bosch-ic-arrow-up-frame;
}

.Bosch-Ic-batch:before {
  content: $bosch-ic-batch;
}

.Bosch-Ic-calculator:before {
  content: $bosch-ic-calculate;
}

.Bosch-Ic-calendar-delete:before {
  content: $bosch-ic-calendar-remove;
}

.Bosch-Ic-call-bluetooth:before {
  content: $bosch-ic-call-bluetooth;
}

.Bosch-Ic-call-deny-outline:before {
  content: $bosch-ic-call-deny-frame;
}

.Bosch-Ic-call-deny:before {
  content: $bosch-ic-call-deny;
}

.Bosch-Ic-call-pick-up-outline:before {
  content: $bosch-ic-call-frame;
}

.Bosch-Ic-call-pick-up:before {
  content: $bosch-ic-call;
}

.Bosch-Ic-call-settings:before {
  content: $bosch-ic-call-settings;
}

.Bosch-Ic-clear-all-annotations:before {
  content: $bosch-ic-clear-all;
}

.Bosch-Ic-connection:before {
  content: $bosch-ic-connection-on;
}

.Bosch-Ic-core-data:before {
  content: $bosch-ic-core-data;
}

.Bosch-Ic-curve-scaling-view:before {
  content: $bosch-ic-window-analysis;
}

.Bosch-Ic-dashboard:before {
  content: $bosch-ic-grid-view;
}

.Bosch-Ic-delete-material-manually:before {
  content: $bosch-ic-material-remove-manually;
}

.Bosch-Ic-dmc-code:before {
  content: $bosch-ic-dmc-code;
}

.Bosch-Ic-document-to-svg:before {
  content: $bosch-ic-document-svg;
}

.Bosch-Ic-emergency-order:before {
  content: $bosch-ic-document-error;
}

.Bosch-Ic-fast-backwards:before {
  content: $bosch-ic-fast-backward-frame;
}

.Bosch-Ic-fast-forwards:before {
  content: $bosch-ic-fast-forward-frame;
}

.Bosch-Ic-favorite:before {
  content: $bosch-ic-wishlist;
}

.Bosch-Ic-go-to:before {
  content: $bosch-ic-login;
}

.Bosch-Ic-gps:before {
  content: $bosch-ic-locator-spot;
}

.Bosch-Ic-home-filled:before {
  content: $bosch-ic-home;
}

.Bosch-Ic-input:before {
  content: $bosch-ic-login;
}

.Bosch-Ic-interface:before {
  content: $bosch-ic-interface;
}

.Bosch-Ic-list:before {
  content: $bosch-ic-list-view-mobile;
}

.Bosch-Ic-malfunction-history:before {
  content: $bosch-ic-caution-sign-clock;
}

.Bosch-Ic-malfunction-order:before {
  content: $bosch-ic-caution-sign-list;
}

.Bosch-Ic-material-list:before {
  content: $bosch-ic-material-list;
}

.Bosch-Ic-material:before {
  content: $bosch-ic-material;
}

.Bosch-Ic-maximize-window:before {
  content: $bosch-ic-stop;
}

.Bosch-Ic-menu-tree:before {
  content: $bosch-ic-menu-checkmark;
}

.Bosch-Ic-minimize-window:before {
  content: $bosch-ic-less-minimize;
}

.Bosch-Ic-notification-hint:before {
  content: $bosch-ic-problem-frame;
}

.Bosch-Ic-notification-question:before {
  content: $bosch-ic-question-frame;
}

.Bosch-Ic-notification-info:before {
  content: $bosch-ic-alert-info;
}

.Bosch-Ic-notification-error:before {
  content: $bosch-ic-alert-error-filled;
}

.Bosch-Ic-notification-error-outline:before {
  content :$bosch-ic-alert-error;
}

.Bosch-Ic-notification-outline:before {
  content: $bosch-ic-alert-warning;
}

.Bosch-Ic-notification-success:before {
  content: $bosch-ic-alert-success-filled;
}

.Bosch-Ic-notification-success-outline:before {
  content: $bosch-ic-alert-success;
}

.Bosch-Ic-notification-warning:before {
  content: $bosch-ic-alert-warning-filled;
}

.Bosch-Ic-notification:before {
  content: $bosch-ic-notification;
}

.Bosch-Ic-output:before {
  content: $bosch-ic-logout;
}

.Bosch-Ic-paste:before {
  content: $bosch-ic-clipboard;
}

.Bosch-Ic-qr-code:before {
  content: $bosch-ic-qr-code;
}

.Bosch-Ic-remote-cursor:before {
  content: $bosch-ic-cursor-connected;
}

.Bosch-Ic-scale:before {
  content: $bosch-ic-externallink;
}

.Bosch-Ic-search-chart:before {
  content: $bosch-ic-bar-chart-search;
}

.Bosch-Ic-search-web:before {
  content: $bosch-ic-globe-search;
}

.Bosch-Ic-search-window:before {
  content: $bosch-ic-window-search;
}

.Bosch-Ic-shape-ellipse:before {
  content: $bosch-ic-shape-ellipse;
}

.Bosch-Ic-shape-rectangle:before {
  content: $bosch-ic-stop;
}

.Bosch-Ic-show-more-vertical:before {
  content: $bosch-ic-options;
}

.Bosch-Ic-signal:before {
  content: $bosch-ic-signal-full;
}

.Bosch-Ic-skip-back:before {
  content: $bosch-ic-skip-backward-frame;
}

.Bosch-Ic-skip-backward:before {
  content: $bosch-ic-skip-fast-backward-frame;
}

.Bosch-Ic-StarterKit:before {
  content: $bosch-ic-cube-filled;
}

.Bosch-Ic-skip-for:before {
  content: $bosch-ic-skip-forward-frame;
}

.Bosch-Ic-skip-forward:before {
  content: $bosch-ic-skip-fast-forward-frame;
}

.Bosch-Ic-sort-alphabetically:before {
  content: $bosch-ic-sort-alphabetically;
}

.Bosch-Ic-switch-language:before {
  content: $bosch-ic-globe;
}

.Bosch-Ic-snyc-disabled:before {
  content: $bosch-ic-arrows-diagonal-disabled-light;
}

.Bosch-Ic-sync-disabled:before {
  content: $bosch-ic-arrows-diagonal-disabled-light;
}

.Bosch-Ic-sync-problem:before {
  content: $bosch-ic-arrows-caution;
}

.Bosch-Ic-sync:before {
  content: $bosch-ic-refresh;
}

.Bosch-Ic-test:before {
  content: $bosch-ic-confirmation;
}

.Bosch-Ic-to-order:before {
  content: $bosch-ic-document-arrow-up;
}

.Bosch-Ic-to-raw:before {
  content: $bosch-ic-document-arrow-down;
}

.Bosch-Ic-transport-orders:before {
  content: $bosch-ic-clipboard-list;
}

.Bosch-Ic-unpin:before {
  content: $bosch-ic-unpin-classic-disabled-light;
}

.Bosch-Ic-user-filled:before {
  content: $bosch-ic-user;
}

.Bosch-Ic-video-play:before {
  content: $bosch-ic-play;
}

.Bosch-Ic-weblink:before {
  content: $bosch-ic-link;
}

.Bosch-Ic-warning-outline:before {
  content: $bosch-ic-alert-warning;
}

/* old icon classname with uppercase for backwards compatibility */

.Bosch-Ic-add-material-manually:before {
  content: $bosch-ic-material-add-manually;
}

.Bosch-Ic-add-material:before {
  content: $bosch-ic-material-add;
}

.Bosch-Ic-add:before {
  content: $bosch-ic-add;
}

.Bosch-Ic-adress-consumer:before {
  content: $bosch-ic-address-consumer-data;
}

.Bosch-Ic-adressbook:before {
  content: $bosch-ic-adress-book;
}

.Bosch-Ic-alarm:before {
  content: $bosch-ic-notification;
}

.Bosch-Ic-alert:before {
  content: $bosch-ic-whistle;
}

.Bosch-Ic-analysis:before {
  content: $bosch-ic-analysis;
}

.Bosch-Ic-anchor:before {
  content: $bosch-ic-anchor;
}

.Bosch-IC-3d-print:before {
  content: $bosch-ic-3d-print;
}

.Bosch-Ic-tours:before {
  content: $bosch-ic-a-to-b;
}

.Bosch-Ic-add:before {
  content: $bosch-ic-add;
}

.Bosch-Ic-adress-consumer:before {
  content: $bosch-ic-address-consumer-data;
}

.Bosch-Ic-adressbook:before {
  content: $bosch-ic-adress-book;
}

.Bosch-IC-agility:before {
  content: $bosch-ic-agility;
}

.Bosch-Ic-clock-stop:before {
  content: $bosch-ic-alarm-clock;
}

.Bosch-IC-ambulance:before {
  content: $bosch-ic-ambulance;
}

.Bosch-Ic-analysis:before {
  content: $bosch-ic-analysis;
}

.Bosch-Ic-anchor:before {
  content: $bosch-ic-anchor;
}

.Bosch-Ic-arrow-down:before {
  content: $bosch-ic-arrow-down;
}

.Bosch-Ic-arrow-left:before {
  content: $bosch-ic-arrow-left;
}

.Bosch-Ic-arrow-left-down:before {
  content: $bosch-ic-arrow-left-down;
}

.Bosch-Ic-arrow-left-up:before {
  content: $bosch-ic-arrow-left-up;
}

.Bosch-Ic-arrow-right:before {
  content: $bosch-ic-arrow-right;
}

.Bosch-Ic-arrow-right-down:before {
  content: $bosch-ic-arrow-right-down;
}

.Bosch-Ic-arrow-right-up:before {
  content: $bosch-ic-arrow-right-up;
}

.Bosch-IC-arrow-turn-left:before {
  content: $bosch-ic-arrow-turn-left;
}

.Bosch-IC-arrow-turn-right:before {
  content: $bosch-ic-arrow-turn-right;
}

.Bosch-Ic-arrow-up:before {
  content: $bosch-ic-arrow-up;
}

.Bosch-IC-arrows:before {
  content: $bosch-ic-arrows;
}

.Bosch-IC-arrows-x-y-z:before {
  content: $bosch-ic-arrows-x-y-z;
}

.Bosch-IC-artificial-intelligence:before {
  content: $bosch-ic-artificial-intelligence;
}

.Bosch-IC-assembly-line:before {
  content: $bosch-ic-assembly-line;
}

.Bosch-IC-atom:before {
  content: $bosch-ic-atom;
}

.Bosch-IC-augmented-reality:before {
  content: $bosch-ic-augmented-reality;
}

.Bosch-Ic-autobod:before {
  content: $bosch-ic-autobod;
}

.Bosch-Ic-arrow-expand-left:before {
  content: $bosch-ic-back-left;
}

.Bosch-Ic-arrow-expand-double-left:before {
  content: $bosch-ic-back-left-double;
}

.Bosch-IC-bar-chart:before {
  content: $bosch-ic-bar-chart;
}

.Bosch-IC-bar-chart-double:before {
  content: $bosch-ic-bar-chart-double;
}

.Bosch-Ic-battery-empty:before {
  content: $bosch-ic-battery_0;
}

.Bosch-Ic-battery-25:before {
  content: $bosch-ic-battery_1;
}

.Bosch-Ic-battery-50:before {
  content: $bosch-ic-battery_2;
}

.Bosch-Ic-battery-75:before {
  content: $bosch-ic-battery_3;
}

.Bosch-Ic-battery-100:before {
  content: $bosch-ic-battery_4;
}

.Bosch-Ic-battery-error:before {
  content: $bosch-ic-battery_fail;
}

.Bosch-IC-battery_Li-Ion:before {
  content: $bosch-ic-battery_li-ion;
}

.Bosch-Ic-battery-charging:before {
  content: $bosch-ic-battery_loading;
}

.Bosch-Ic-help:before {
  content: $bosch-ic-question-frame;
}

.Bosch-IC-bed:before {
  content: $bosch-ic-bed;
}

.Bosch-IC-bicycle:before {
  content: $bosch-ic-bicycle;
}

.Bosch-IC-bicycle-e:before {
  content: $bosch-ic-bicycle-e;
}

.Bosch-IC-blog:before {
  content: $bosch-ic-blog;
}

.Bosch-IC-bluetooth:before {
  content: $bosch-ic-bluetooth;
}

.Bosch-IC-bluetooth-LE:before {
  content: $bosch-ic-bluetooth-le;
}

.Bosch-IC-board-speaker:before {
  content: $bosch-ic-board-speaker;
}

.Bosch-IC-boiler-connected:before {
  content: $bosch-ic-boiler-connected;
}

.Bosch-IC-book:before {
  content: $bosch-ic-book;
}

.Bosch-Ic-bookmark:before {
  content: $bosch-ic-bookmark;
}

.Bosch-Ic-bookmark-add:before {
  content: $bosch-ic-bookmark-add;
}

.Bosch-Ic-bookmark-check:before {
  content: $bosch-ic-bookmark-check;
}

.Bosch-Ic-bookmark-delete:before {
  content: $bosch-ic-bookmark-delete;
}

.Bosch-IC-books:before {
  content: $bosch-ic-books;
}

.Bosch-IC-bookshelf:before {
  content: $bosch-ic-bookshelf;
}

.Bosch-Ic-box:before {
  content: $bosch-ic-box;
}

.Bosch-IC-box-closed:before {
  content: $bosch-ic-box-closed;
}

.Bosch-IC-box-delivery:before {
  content: $bosch-ic-box-delivery;
}

.Bosch-IC-box-delivery-connected:before {
  content: $bosch-ic-box-delivery-connected;
}

.Bosch-IC-brake-disk:before {
  content: $bosch-ic-brake-disk;
}

.Bosch-IC-briefcase:before {
  content: $bosch-ic-briefcase;
}

.Bosch-IC-broom:before {
  content: $bosch-ic-broom;
}

.Bosch-IC-broom-cloud-dirt:before {
  content: $bosch-ic-broom-cloud-dirt;
}

.Bosch-Ic-brush:before {
  content: $bosch-ic-brush;
}

.Bosch-Ic-bug:before {
  content: $bosch-ic-bug;
}

.Bosch-IC-building:before {
  content: $bosch-ic-building;
}

.Bosch-IC-cactus:before {
  content: $bosch-ic-cactus;
}

.Bosch-IC-calculate:before {
  content: $bosch-ic-calculate;
}

.Bosch-Ic-calendar:before {
  content: $bosch-ic-calendar;
}

.Bosch-Ic-calendar-add:before {
  content: $bosch-ic-calendar-add;
}

.Bosch-IC-calendar-settings:before {
  content: $bosch-ic-calendar-settings;
}

.Bosch-Ic-call:before {
  content: $bosch-ic-call;
}

.Bosch-IC-call-add:before {
  content: $bosch-ic-call-add;
}

.Bosch-IC-call-remove:before {
  content: $bosch-ic-call-remove;
}

.Bosch-IC-call-sos:before {
  content: $bosch-ic-call-sos;
}

.Bosch-IC-call-team:before {
  content: $bosch-ic-call-team;
}

.Bosch-Ic-call-wifi:before {
  content: $bosch-ic-call-wifi;
}

.Bosch-Ic-camera:before {
  content: $bosch-ic-camera;
}

.Bosch-Ic-camera-switch:before {
  content: $bosch-ic-camera-switch;
}

.Bosch-IC-car:before {
  content: $bosch-ic-car;
}

.Bosch-IC-car-clock:before {
  content: $bosch-ic-car-clock;
}

.Bosch-IC-car-connected:before {
  content: $bosch-ic-car-connected;
}

.Bosch-IC-car-locator:before {
  content: $bosch-ic-car-locator;
}

.Bosch-IC-car-mechanic:before {
  content: $bosch-ic-car-mechanic;
}

.Bosch-IC-car-rental:before {
  content: $bosch-ic-car-rental;
}

.Bosch-IC-car-seat-connected:before {
  content: $bosch-ic-car-seat-connected;
}

.Bosch-IC-car-side:before {
  content: $bosch-ic-car-side;
}

.Bosch-IC-car-side-user:before {
  content: $bosch-ic-car-side-user;
}

.Bosch-Ic-milkrun-cart:before {
  content: $bosch-ic-cart;
}

.Bosch-Ic-certificate:before {
  content: $bosch-ic-certificate;
}

.Bosch-IC-certificate-pki:before {
  content: $bosch-ic-certificate-pki;
}

.Bosch-IC-change:before {
  content: $bosch-ic-change;
}

.Bosch-Ic-chart:before {
  content: $bosch-ic-chart-bar;
}

.Bosch-IC-chart-check:before {
  content: $bosch-ic-chart-check;
}

.Bosch-IC-chart-dummy:before {
  content: $bosch-ic-chart-dummy;
}

.Bosch-Ic-diagram:before {
  content: $bosch-ic-chart-line;
}

.Bosch-Ic-chat:before {
  content: $bosch-ic-chat;
}

.Bosch-IC-chat-add:before {
  content: $bosch-ic-chat-add;
}

.Bosch-IC-chat-question-answer:before {
  content: $bosch-ic-chat-question-answer;
}

.Bosch-IC-chat-remove:before {
  content: $bosch-ic-chat-remove;
}

.Bosch-Ic-checklist:before {
  content: $bosch-ic-checklist;
}

.Bosch-Ic-checkmark:before {
  content: $bosch-ic-checkmark;
}

.Bosch-Ic-chip:before {
  content: $bosch-ic-chip;
}

.Bosch-IC-circle-segment:before {
  content: $bosch-ic-circle-segment;
}

.Bosch-IC-clear-all:before {
  content: $bosch-ic-clear-all;
}

.Bosch-Ic-clipboard:before {
  content: $bosch-ic-clipboard;
}

.Bosch-Ic-clipboard-list:before {
  content: $bosch-ic-clipboard-list;
}

.Bosch-Ic-clock:before {
  content: $bosch-ic-clock;
}

.Bosch-Ic-abort:before,
.bosch-ic-abort:before {
  content: $bosch-ic-close;
}

.Bosch-IC-close-all:before {
  content: $bosch-ic-close-all;
}

.Bosch-Ic-cloud:before {
  content: $bosch-ic-cloud;
}

.Bosch-IC-cloud-co2:before {
  content: $bosch-ic-cloud-co2;
}

.Bosch-Ic-cloud-download:before {
  content: $bosch-ic-cloud-download;
}

.Bosch-IC-cloud-nox:before {
  content: $bosch-ic-cloud-nox;
}

.Bosch-Ic-cloud-upload:before {
  content: $bosch-ic-cloud-upload;
}

.Bosch-IC-club-arm-chair:before {
  content: $bosch-ic-club-arm-chair;
}

.Bosch-IC-coffee-break:before {
  content: $bosch-ic-coffee-break;
}

.Bosch-IC-coin-currency:before {
  content: $bosch-ic-coin-currency;
}

.Bosch-Ic-currency:before {
  content: $bosch-ic-coin-dollar;
}

.Bosch-Ic-color-picker:before {
  content: $bosch-ic-colorpicker;
}

.Bosch-IC-colors:before {
  content: $bosch-ic-colors;
}

.Bosch-Ic-command:before {
  content: $bosch-ic-command;
}

.Bosch-IC-communicator:before {
  content: $bosch-ic-communicator;
}

.Bosch-Ic-compare:before {
  content: $bosch-ic-compare;
}

.Bosch-Ic-components:before {
  content: $bosch-ic-components;
}

.Bosch-Ic-components-available:before {
  content: $bosch-ic-components-available;
}

.Bosch-Ic-components-reservation:before {
  content: $bosch-ic-components-reservation;
}

.Bosch-IC-concierge-bell:before {
  content: $bosch-ic-concierge-bell;
}

.Bosch-IC-configuration:before {
  content: $bosch-ic-configuration;
}

.Bosch-Ic-confirmation:before {
  content: $bosch-ic-confirmation;
}

.Bosch-Ic-unconnected:before {
  content: $bosch-ic-connection-off;
}

.Bosch-Ic-connected:before {
  content: $bosch-ic-connection-on;
}

.Bosch-IC-connectivity:before {
  content: $bosch-ic-connectivity;
}

.Bosch-Ic-builder:before {
  content: $bosch-ic-construction;
}

.Bosch-IC-consumer-goods:before {
  content: $bosch-ic-consumer-goods;
}

.Bosch-IC-contract:before {
  content: $bosch-ic-contract;
}

.Bosch-Ic-copy:before {
  content: $bosch-ic-copy;
}

.Bosch-IC-cube:before {
  content: $bosch-ic-cube;
}

.Bosch-IC-cube-arrows:before {
  content: $bosch-ic-cube-arrows;
}

.Bosch-Ic-StarterKit:before {
  content: $bosch-ic-cube-filled;
}

.Bosch-IC-cube-shock:before {
  content: $bosch-ic-cube-shock;
}

.Bosch-IC-cube-stacked:before {
  content: $bosch-ic-cube-stacked;
}

.Bosch-Ic-customerservice:before {
  content: $bosch-ic-customerservice;
}

.Bosch-IC-cutlery:before {
  content: $bosch-ic-cutlery;
}

.Bosch-IC-damper:before {
  content: $bosch-ic-damper;
}

.Bosch-IC-danger-flash:before {
  content: $bosch-ic-danger-flash;
}

.Bosch-IC-data-loss:before {
  content: $bosch-ic-data-loss;
}

.Bosch-Ic-dealer:before {
  content: $bosch-ic-dealer-details;
}

.Bosch-Ic-delete:before {
  content: $bosch-ic-delete;
}

.Bosch-Ic-delivery:before {
  content: $bosch-ic-delivery;
}

.Bosch-IC-denied:before {
  content: $bosch-ic-denied;
}

.Bosch-Ic-desktop:before {
  content: $bosch-ic-desktop;
}

.Bosch-IC-desktop-application-2d:before {
  content: $bosch-ic-desktop-application-2d;
}

.Bosch-IC-desktop-application-3d:before {
  content: $bosch-ic-desktop-application-3d;
}

.Bosch-IC-development:before {
  content: $bosch-ic-development;
}

.Bosch-Ic-devices:before {
  content: $bosch-ic-devices;
}

.Bosch-IC-devices-mobile:before {
  content: $bosch-ic-devices-mobile;
}

.Bosch-Ic-directory:before {
  content: $bosch-ic-directory;
}

.Bosch-Ic-dna:before {
  content: $bosch-ic-dna;
}

.Bosch-Ic-do-not-disturb:before {
  content: $bosch-ic-do-not-disturb;
}

.Bosch-Ic-document:before {
  content: $bosch-ic-document;
}

.Bosch-Ic-document-add:before {
  content: $bosch-ic-document-add;
}

.Bosch-IC-document-arrow-down:before {
  content: $bosch-ic-document-arrow-down;
}

.Bosch-IC-document-arrow-up:before {
  content: $bosch-ic-document-arrow-up;
}

.Bosch-Ic-document-audio:before {
  content: $bosch-ic-document-audio;
}

.Bosch-IC-document-check:before {
  content: $bosch-ic-document-check;
}

.Bosch-Ic-document-to-cloud:before {
  content: $bosch-ic-document-cloud;
}

.Bosch-Ic-document-copy:before {
  content: $bosch-ic-document-copy;
}

.Bosch-Ic-document-delete:before {
  content: $bosch-ic-document-delete;
}

.Bosch-Ic-document-to-doc:before {
  content: $bosch-ic-document-doc;
}

.Bosch-Ic-document-edit:before {
  content: $bosch-ic-document-edit;
}

.Bosch-Ic-document-error:before {
  content: $bosch-ic-document-error;
}

.Bosch-Ic-document-locked:before {
  content: $bosch-ic-document-locked;
}

.Bosch-Ic-log-file:before {
  content: $bosch-ic-document-log;
}

.Bosch-Ic-document-to-one:before {
  content: $bosch-ic-document-one;
}

.Bosch-Ic-document-to-pdf:before {
  content: $bosch-ic-document-pdf;
}

.Bosch-Ic-document-plain:before {
  content: $bosch-ic-document-plain;
}

.Bosch-Ic-document-plain-add:before {
  content: $bosch-ic-document-plain-add;
}

.Bosch-Ic-document-plain-delete:before {
  content: $bosch-ic-document-plain-delete;
}

.Bosch-Ic-document-to-ppt:before {
  content: $bosch-ic-document-ppt;
}

.Bosch-Ic-document-to-pub:before {
  content: $bosch-ic-document-pub;
}

.Bosch-Ic-document-to-rtf:before {
  content: $bosch-ic-document-rtf;
}

.Bosch-Ic-document-save-to:before {
  content: $bosch-ic-document-save-to;
}

.Bosch-Ic-search-document:before {
  content: $bosch-ic-document-search;
}

.Bosch-Ic-document-settings:before {
  content: $bosch-ic-document-settings;
}

.Bosch-Ic-document-test:before {
  content: $bosch-ic-document-test;
}

.Bosch-Ic-document-text:before {
  content: $bosch-ic-document-text;
}

.Bosch-Ic-document-to-txt:before {
  content: $bosch-ic-document-txt;
}

.Bosch-Ic-document-to-vsd:before {
  content: $bosch-ic-document-vsd;
}

.Bosch-Ic-document-to-xls:before {
  content: $bosch-ic-document-xls;
}

.Bosch-Ic-document-to-xml:before {
  content: $bosch-ic-document-xml;
}

.Bosch-Ic-document-to-zip:before {
  content: $bosch-ic-document-zip;
}

.Bosch-IC-dome:before {
  content: $bosch-ic-dome;
}

.Bosch-IC-door:before {
  content: $bosch-ic-door;
}

.Bosch-IC-door-sensor:before {
  content: $bosch-ic-door-sensor;
}

.Bosch-Ic-arrow-expand-down:before {
  content: $bosch-ic-down;
}

.Bosch-Ic-arrow-expand-double-down:before {
  content: $bosch-ic-down-double;
}

.Bosch-Ic-arrow-left-circle:before {
  content: $bosch-ic-arrow-left-frame;
}

.Bosch-Ic-arrow-right-circle:before {
  content: $bosch-ic-arrow-right-frame;
}

.Bosch-Ic-arrow-right-down-circle:before {
  content: $bosch-ic-arrow-right-down-frame;
}

.Bosch-Ic-arrow-right-up-circle:before {
  content: $bosch-ic-arrow-right-up-frame;
}

.Bosch-Ic-arrow-left-down-circle:before {
  content: $bosch-ic-arrow-left-down-frame;
}

.Bosch-Ic-arrow-left-up-circle:before {
  content: $bosch-ic-arrow-left-up-frame;
}

.Bosch-Ic-arrow-up-circle:before {
  content: $bosch-ic-arrow-up-frame;
}

.Bosch-Ic-download:before {
  content: $bosch-ic-download;
}

.Bosch-IC-drag-handle:before {
  content: $bosch-ic-drag-handle;
}

.Bosch-IC-dragdrop:before {
  content: $bosch-ic-dragdrop;
}

.Bosch-IC-drop:before {
  content: $bosch-ic-drop;
}

.Bosch-IC-e-Charging:before {
  content: $bosch-ic-e-charging;
}

.Bosch-IC-e-mobility:before {
  content: $bosch-ic-e-mobility;
}

.Bosch-IC-ear-off:before {
  content: $bosch-ic-ear-off-disabled-light;
}

.Bosch-IC-ear-on:before {
  content: $bosch-ic-ear-on;
}

.Bosch-IC-eco-system:before {
  content: $bosch-ic-eco-system;
}

.Bosch-Ic-edit:before {
  content: $bosch-ic-edit;
}

.Bosch-IC-education:before {
  content: $bosch-ic-education;
}

.Bosch-IC-efficiency:before {
  content: $bosch-ic-efficiency;
}

.Bosch-IC-elevator:before {
  content: $bosch-ic-elevator;
}

.Bosch-IC-elevator-alarm:before {
  content: $bosch-ic-elevator-alarm;
}

.Bosch-IC-elevator-cloud:before {
  content: $bosch-ic-elevator-cloud;
}

.Bosch-IC-elevator-headset:before {
  content: $bosch-ic-elevator-headset;
}

.Bosch-IC-elevator-service:before {
  content: $bosch-ic-elevator-service;
}

.Bosch-IC-emergency-exit:before {
  content: $bosch-ic-emergency-exit;
}

.Bosch-IC-emoji-happy:before {
  content: $bosch-ic-emoji-happy;
}

.Bosch-IC-emoji-neutral:before {
  content: $bosch-ic-emoji-neutral;
}

.Bosch-IC-emoji-sad:before {
  content: $bosch-ic-emoji-sad;
}

.Bosch-IC-emoji-super-happy:before {
  content: $bosch-ic-emoji-super-happy;
}

.Bosch-IC-emoji-very-sad:before {
  content: $bosch-ic-emoji-very-sad;
}

.Bosch-IC-EU-energy-label:before {
  content: $bosch-ic-eu-energy-label;
}

.Bosch-IC-excavator:before {
  content: $bosch-ic-excavator;
}

.Bosch-Ic-exit:before {
  content: $bosch-ic-exit;
}

.Bosch-IC-expansion-arrows:before {
  content: $bosch-ic-expansion-arrows;
}

.Bosch-IC-explosion:before {
  content: $bosch-ic-explosion;
}

.Bosch-Ic-export:before {
  content: $bosch-ic-export;
}

.Bosch-IC-externallink:before {
  content: $bosch-ic-externallink;
}

.Bosch-IC-fast-forward:before {
  content: $bosch-ic-fast-forward;
}

.Bosch-IC-faucet:before {
  content: $bosch-ic-faucet;
}

.Bosch-IC-favorites:before {
  content: $bosch-ic-favorites;
}

.Bosch-IC-fax:before {
  content: $bosch-ic-fax;
}

.Bosch-IC-female:before {
  content: $bosch-ic-female;
}

.Bosch-IC-film:before {
  content: $bosch-ic-film;
}

.Bosch-Ic-filter:before {
  content: $bosch-ic-filter;
}

.Bosch-IC-filter-success:before {
  content: $bosch-ic-filter-success;
}

.Bosch-IC-fingerprint:before {
  content: $bosch-ic-fingerprint;
}

.Bosch-IC-fire:before {
  content: $bosch-ic-fire;
}

.Bosch-IC-fire-emergency:before {
  content: $bosch-ic-fire-emergency;
}

.Bosch-IC-fireworks:before {
  content: $bosch-ic-fireworks;
}

.Bosch-Ic-first-aid:before {
  content: $bosch-ic-first-aid;
}

.Bosch-IC-first-aid-cross:before {
  content: $bosch-ic-first-aid-cross;
}

.Bosch-IC-fit-to-sceen:before {
  content: $bosch-ic-fit-to-sceen;
}

.Bosch-Ic-flag:before {
  content: $bosch-ic-flag;
}

.Bosch-Ic-danger-flash-outline:before {
  content: $bosch-ic-flash;
}

.Bosch-IC-flask:before {
  content: $bosch-ic-flask;
}

.Bosch-Ic-flexpress:before {
  content: $bosch-ic-flexpress;
}

.Bosch-Ic-twitter:before {
  content: $bosch-ic-twitter-frame;
}

.Bosch-Ic-folder:before {
  content: $bosch-ic-folder;
}

.Bosch-Ic-download-from-folder:before {
  content: $bosch-ic-folder-download;
}

.Bosch-Ic-download-frame:before {
  content: $bosch-ic-download-frame;
}

.Bosch-IC-folder-open:before {
  content: $bosch-ic-folder-open;
}

.Bosch-Ic-upload-to-folder:before {
  content: $bosch-ic-folder-upload;
}

.Bosch-Ic-fork-lift:before {
  content: $bosch-ic-fork-lift;
}

.Bosch-IC-fork-lift-locator:before {
  content: $bosch-ic-fork-lift-locator;
}

.Bosch-Ic-arrow-expand-right:before {
  content: $bosch-ic-forward-right;
}

.Bosch-Ic-arrow-expand-double-right:before {
  content: $bosch-ic-forward-right-double;
}

.Bosch-IC-full-empty:before {
  content: $bosch-ic-full-empty;
}

.Bosch-Ic-fullscreen:before {
  content: $bosch-ic-fullscreen;
}

.Bosch-IC-fullscreen-arrows:before {
  content: $bosch-ic-fullscreen-arrows;
}

.Bosch-Ic-fullscreen-exit:before {
  content: $bosch-ic-fullscreen-exit;
}

.Bosch-IC-g-arrow-down:before {
  content: $bosch-ic-g-arrow-down;
}

.Bosch-IC-g-arrow-up:before {
  content: $bosch-ic-g-arrow-up;
}

.Bosch-IC-generator:before {
  content: $bosch-ic-generator;
}

.Bosch-IC-gift:before {
  content: $bosch-ic-gift;
}

.Bosch-IC-glance:before {
  content: $bosch-ic-glance;
}

.Bosch-IC-glas-plate:before {
  content: $bosch-ic-glas-plate;
}

.Bosch-IC-globe:before {
  content: $bosch-ic-globe;
}

.Bosch-IC-globe-arrow:before {
  content: $bosch-ic-globe-arrow;
}

.Bosch-IC-glossary:before {
  content: $bosch-ic-glossary;
}

.Bosch-Ic-grid:before {
  content: $bosch-ic-grid-view;
}

.Bosch-IC-hammer:before {
  content: $bosch-ic-hammer;
}

.Bosch-Ic-hand:before {
  content: $bosch-ic-hand;
}

.Bosch-IC-hand-motion:before {
  content: $bosch-ic-hand-motion;
}

.Bosch-IC-hand-motion-off:before {
  content: $bosch-ic-hand-motion-off-disabled-light;
}

.Bosch-IC-handlewithcare:before {
  content: $bosch-ic-handlewithcare;
}

.Bosch-Ic-add-material-manually:before {
  content: $bosch-ic-handover-add;
}

.Bosch-Ic-handover-check:before {
  content: $bosch-ic-handover-check;
}

.Bosch-Ic-remove-from-handover:before {
  content: $bosch-ic-handover-remove;
}

.Bosch-IC-hanger:before {
  content: $bosch-ic-hanger;
}

.Bosch-Ic-health:before {
  content: $bosch-ic-health;
}

.Bosch-IC-heart:before {
  content: $bosch-ic-heart;
}

.Bosch-Ic-history:before {
  content: $bosch-ic-history;
}

.Bosch-Ic-home-outline:before {
  content: $bosch-ic-home;
}

.Bosch-IC-home-locator:before {
  content: $bosch-ic-home-locator;
}

.Bosch-Ic-sandclock:before {
  content: $bosch-ic-hourglass;
}

.Bosch-IC-hydrant:before {
  content: $bosch-ic-hydrant;
}

.Bosch-IC-ice:before {
  content: $bosch-ic-ice;
}

.Bosch-Ic-image:before {
  content: $bosch-ic-imagery;
}

.Bosch-IC-imagery-add:before {
  content: $bosch-ic-imagery-add;
}

.Bosch-IC-imagery-remove:before {
  content: $bosch-ic-imagery-remove;
}

.Bosch-Ic-import:before {
  content: $bosch-ic-import;
}

.Bosch-Ic-imprint:before {
  content: $bosch-ic-imprint;
}

.Bosch-IC-impulse:before {
  content: $bosch-ic-impulse;
}

.Bosch-Ic-fabric:before {
  content: $bosch-ic-industry;
}

.Bosch-IC-industry-clock:before {
  content: $bosch-ic-industry-clock;
}

.Bosch-IC-industry-connected:before {
  content: $bosch-ic-industry-connected;
}

.Bosch-Ic-information:before {
  content: $bosch-ic-info-i;
}

.Bosch-Ic-information-tooltip:before {
  content: $bosch-ic-info-i;
}

.Bosch-IC-interval:before {
  content: $bosch-ic-interval;
}

.Bosch-IC-it-device:before {
  content: $bosch-ic-it-device;
}

.Bosch-IC-jewel:before {
  content: $bosch-ic-jewel;
}

.Bosch-IC-keyboard:before {
  content: $bosch-ic-keyboard;
}

.Bosch-IC-label:before {
  content: $bosch-ic-label;
}

.Bosch-Ic-laptop-01:before {
  content: $bosch-ic-laptop;
}

.Bosch-IC-laser:before {
  content: $bosch-ic-laser;
}

.Bosch-IC-layout:before {
  content: $bosch-ic-layout;
}

.Bosch-IC-leaf:before {
  content: $bosch-ic-leaf;
}

.Bosch-IC-led:before {
  content: $bosch-ic-led;
}

.Bosch-Ic-world:before {
  content: $bosch-ic-world-frame;
}

.Bosch-Ic-facebook:before {
  content: $bosch-ic-facebook-frame;
}

.Bosch-Ic-checkmark-outline:before {
  content: $bosch-ic-checkmark-frame;
}

.Bosch-Ic-youtube:before {
  content: $bosch-ic-youtube-frame;
}

.Bosch-Ic-share:before {
  content: $bosch-ic-share;
}

.Bosch-Ic-user:before {
  content: $bosch-ic-user;
}

.Bosch-Ic-upload_outline:before {
  content: $bosch-ic-upload-frame;
}

.Bosch-Ic-download_outline:before {
  content: $bosch-ic-download-frame;
}

.Bosch-Ic-wishlist-add:before {
  content: $bosch-ic-wishlist-add;
}

.Bosch-Ic-youtube-frame:before {
  content: $bosch-ic-youtube-frame;
}

.Bosch-Ic-less-minimize:before {
  content: $bosch-ic-less-minimize;
}

.Bosch-IC-lightbulb:before {
  content: $bosch-ic-lightbulb;
}

.bosch-Ic-innovation:before {
  content: $bosch-ic-lightbulb-off;
}

.Bosch-IC-line-chart:before {
  content: $bosch-ic-line-chart;
}

.Bosch-Ic-link:before {
  content: $bosch-ic-link;
}

.Bosch-IC-link-broken:before {
  content: $bosch-ic-link-broken;
}

.Bosch-Ic-list-view:before {
  content: $bosch-ic-list-view;
}

.Bosch-Ic-menu:before {
  content: $bosch-ic-list-view-mobile;
}

.Bosch-IC-livechat:before {
  content: $bosch-ic-livechat;
}

.Bosch-Ic-location-pin:before {
  content: $bosch-ic-locator;
}

.Bosch-IC-locator-ip-connected:before {
  content: $bosch-ic-locator-ip-connected;
}

.Bosch-IC-locator-ip-disconnected:before {
  content: $bosch-ic-locator-ip-disconnected;
}

.Bosch-IC-locator-spot:before {
  content: $bosch-ic-locator-spot;
}

.Bosch-IC-locator-spot-check:before {
  content: $bosch-ic-locator-spot-check;
}

.Bosch-Ic-lock-closed:before {
  content: $bosch-ic-lock-closed;
}

.Bosch-Ic-lock-open:before {
  content: $bosch-ic-lock-open;
}

.Bosch-Ic-log-in:before {
  content: $bosch-ic-login;
}

.Bosch-Ic-logistics:before {
  content: $bosch-ic-logistics;
}

.Bosch-Ic-log-out:before {
  content: $bosch-ic-logout;
}

.Bosch-IC-machine:before {
  content: $bosch-ic-machine;
}

.Bosch-Ic-magnet:before {
  content: $bosch-ic-magnet;
}

.Bosch-Ic-mail:before {
  content: $bosch-ic-mail;
}

.Bosch-Ic-mail-forward:before {
  content: $bosch-ic-mail-forward;
}

.Bosch-Ic-mail-open:before {
  content: $bosch-ic-mail-open;
}

.Bosch-Ic-mail-out:before {
  content: $bosch-ic-mail-out;
}

.Bosch-Ic-mail-answer:before {
  content: $bosch-ic-mail-reply;
}

.Bosch-IC-male:before {
  content: $bosch-ic-male;
}

.Bosch-Ic-map:before {
  content: $bosch-ic-map;
}

.Bosch-Ic-add-material:before {
  content: $bosch-ic-material-add;
}

.Bosch-IC-material-check:before {
  content: $bosch-ic-material-check;
}

.Bosch-Ic-remove-material:before {
  content: $bosch-ic-material-remove;
}

.Bosch-IC-maximize:before {
  content: $bosch-ic-maximize;
}

.Bosch-IC-mechanic:before {
  content: $bosch-ic-mechanic;
}

.Bosch-IC-megaphone:before {
  content: $bosch-ic-megaphone;
}

.Bosch-Ic-message:before {
  content: $bosch-ic-message;
}

.Bosch-IC-mic:before {
  content: $bosch-ic-mic;
}

.Bosch-Ic-microphone:before {
  content: $bosch-ic-microphone;
}

.Bosch-IC-microphone-classic:before {
  content: $bosch-ic-microphone-classic;
}

.Bosch-IC-minimize:before {
  content: $bosch-ic-minimize;
}

.Bosch-IC-money-currency:before {
  content: $bosch-ic-money-currency;
}

.Bosch-IC-money-dollar:before {
  content: $bosch-ic-money-dollar;
}

.Bosch-IC-moneybag-currency:before {
  content: $bosch-ic-moneybag-currency;
}

.Bosch-IC-moneybag-dollar:before {
  content: $bosch-ic-moneybag-dollar;
}

.Bosch-IC-monument:before {
  content: $bosch-ic-monument;
}

.Bosch-IC-moon:before {
  content: $bosch-ic-moon;
}

.Bosch-IC-mouse:before {
  content: $bosch-ic-mouse;
}

.Bosch-IC-mouse-left:before {
  content: $bosch-ic-mouse-left;
}

.Bosch-IC-mouse-right:before {
  content: $bosch-ic-mouse-right;
}

.Bosch-IC-mouth:before {
  content: $bosch-ic-mouth;
}

.Bosch-Ic-my-product:before {
  content: $bosch-ic-my-product;
}

.Bosch-IC-new-way-work:before {
  content: $bosch-ic-new-way-work;
}

.Bosch-Ic-mail-sent:before {
  content: $bosch-ic-newsletter;
}

.Bosch-Ic-news:before {
  content: $bosch-ic-newspaper;
}

.Bosch-IC-nose:before {
  content: $bosch-ic-nose;
}

.Bosch-Ic-notes:before {
  content: $bosch-ic-notepad;
}

.Bosch-Ic-notes-edit:before {
  content: $bosch-ic-notepad-edit;
}

.Bosch-Ic-alarm:before {
  content: $bosch-ic-notification;
}

.Bosch-IC-notification-active:before {
  content: $bosch-ic-notification-active;
}

.Bosch-IC-notification-add:before {
  content: $bosch-ic-notification-add;
}

.Bosch-IC-notification-remove:before {
  content: $bosch-ic-notification-remove;
}

.Bosch-Ic-oil-trailer:before {
  content: $bosch-ic-oil-car;
}

.Bosch-IC-omnichannel:before {
  content: $bosch-ic-omnichannel;
}

.Bosch-Ic-show-more-horizontal:before {
  content: $bosch-ic-options;
}

.Bosch-IC-origami-boat:before {
  content: $bosch-ic-origami-boat;
}

.Bosch-Ic-palette-trailer:before {
  content: $bosch-ic-pallete-car;
}

.Bosch-IC-pallete-car-connected:before {
  content: $bosch-ic-pallete-car-connected;
}

.Bosch-IC-panel:before {
  content: $bosch-ic-panel;
}

.Bosch-Ic-paperclip:before {
  content: $bosch-ic-paperclip;
}

.Bosch-IC-paperplane:before {
  content: $bosch-ic-paperplane;
}

.Bosch-IC-parking:before {
  content: $bosch-ic-parking;
}

.Bosch-Ic-pause:before {
  content: $bosch-ic-pause;
}

.Bosch-Ic-creditcard:before {
  content: $bosch-ic-payment;
}

.Bosch-IC-people:before {
  content: $bosch-ic-people;
}

.Bosch-IC-petrol-station:before {
  content: $bosch-ic-petrol-station;
}

.Bosch-IC-piggybank:before {
  content: $bosch-ic-piggybank;
}

.Bosch-Ic-pin:before {
  content: $bosch-ic-pin-classic;
}

.Bosch-IC-pin-modern:before {
  content: $bosch-ic-pin-modern;
}

.Bosch-IC-plane-side:before {
  content: $bosch-ic-plane-side;
}

.Bosch-Ic-play:before {
  content: $bosch-ic-play;
}

.Bosch-IC-plug-12V:before {
  content: $bosch-ic-plug-12v;
}

.Bosch-IC-podium-speaker:before {
  content: $bosch-ic-podium-speaker;
}

.Bosch-IC-police:before {
  content: $bosch-ic-police;
}

.Bosch-IC-poop:before {
  content: $bosch-ic-poop;
}

.Bosch-IC-post-it:before {
  content: $bosch-ic-post-it;
}

.Bosch-IC-postal-code:before {
  content: $bosch-ic-postal-code;
}

.Bosch-IC-power:before {
  content: $bosch-ic-power;
}

.Bosch-Ic-print:before {
  content: $bosch-ic-print;
}

.Bosch-IC-prototyping:before {
  content: $bosch-ic-prototyping;
}

.Bosch-IC-puzzle:before {
  content: $bosch-ic-puzzle;
}

.Bosch-IC-quad:before {
  content: $bosch-ic-quad;
}

.Bosch-IC-question:before {
  content: $bosch-ic-question;
}

.Bosch-IC-radar:before {
  content: $bosch-ic-radar;
}

.Bosch-IC-radio:before {
  content: $bosch-ic-radio;
}

.Bosch-Ic-radio:before {
  content: $bosch-ic-radiotower;
}

.Bosch-IC-redirect:before {
  content: $bosch-ic-redirect;
}

.Bosch-Ic-redo:before {
  content: $bosch-ic-redo;
}

.Bosch-IC-reference:before {
  content: $bosch-ic-reference;
}

.Bosch-Ic-refresh:before {
  content: $bosch-ic-refresh;
}

.Bosch-IC-refresh-cloud:before {
  content: $bosch-ic-refresh-cloud;
}

.Bosch-Ic-registration:before {
  content: $bosch-ic-registration;
}

.Bosch-IC-remote:before {
  content: $bosch-ic-remote;
}

.Bosch-Ic-reset:before {
  content: $bosch-ic-reset;
}

.Bosch-IC-resolution:before {
  content: $bosch-ic-resolution;
}

.Bosch-Ic-robot-automation:before {
  content: $bosch-ic-robot;
}

.Bosch-IC-robot-connected:before {
  content: $bosch-ic-robot-connected;
}

.Bosch-IC-robothead:before {
  content: $bosch-ic-robothead;
}

.Bosch-IC-rocket:before {
  content: $bosch-ic-rocket;
}

.Bosch-Ic-rotation:before {
  content: $bosch-ic-rotation;
}

.Bosch-Ic-rotate-360-degrees:before {
  content: $bosch-ic-rotation-360;
}

.Bosch-Ic-rotate-clockwise:before {
  content: $bosch-ic-rotation-x-left;
}

.Bosch-Ic-rotate-counterclockwise:before {
  content: $bosch-ic-rotation-x-right;
}

.Bosch-IC-rotation-y-down:before {
  content: $bosch-ic-rotation-y-down;
}

.Bosch-IC-rotation-y-up:before {
  content: $bosch-ic-rotation-y-up;
}

.Bosch-Ic-navigation:before {
  content: $bosch-ic-route;
}

.Bosch-Ic-measure:before {
  content: $bosch-ic-ruler;
}

.Bosch-IC-ruler-pen:before {
  content: $bosch-ic-ruler-pen;
}

.Bosch-Ic-satelite:before {
  content: $bosch-ic-satellite;
}

.Bosch-Ic-save:before {
  content: $bosch-ic-save;
}

.Bosch-IC-scale:before {
  content: $bosch-ic-scale;
}

.Bosch-Ic-cut:before {
  content: $bosch-ic-scissors;
}

.Bosch-Ic-search:before {
  content: $bosch-ic-search;
}

.Bosch-IC-security:before {
  content: $bosch-ic-security;
}

.Bosch-IC-security-check:before {
  content: $bosch-ic-security-check;
}

.Bosch-IC-security-user:before {
  content: $bosch-ic-security-user;
}

.Bosch-Ic-sensor:before {
  content: $bosch-ic-sensor;
}

.Bosch-Ic-server:before {
  content: $bosch-ic-server;
}

.Bosch-IC-server-expansion:before {
  content: $bosch-ic-server-expansion;
}

.Bosch-IC-server-rate:before {
  content: $bosch-ic-server-rate;
}

.Bosch-IC-service-agent:before {
  content: $bosch-ic-service-agent;
}

.Bosch-IC-service-time:before {
  content: $bosch-ic-service-time;
}

.Bosch-Ic-settings:before {
  content: $bosch-ic-settings;
}

.Bosch-IC-settings-arrows:before {
  content: $bosch-ic-settings-arrows;
}

.Bosch-IC-settings-connected:before {
  content: $bosch-ic-settings-connected;
}

.Bosch-Ic-share-outline:before {
  content: $bosch-ic-share;
}

.Bosch-IC-shield-stop:before {
  content: $bosch-ic-shield-stop;
}

.Bosch-IC-ship:before {
  content: $bosch-ic-ship;
}

.Bosch-IC-ship-side:before {
  content: $bosch-ic-ship-side;
}

.Bosch-Ic-shoppingcart:before {
  content: $bosch-ic-shoppingcart;
}

.Bosch-IC-shoppingcart-add:before {
  content: $bosch-ic-shoppingcart-add;
}

.Bosch-IC-shoppingcart-switch:before {
  content: $bosch-ic-shoppingcart-switch;
}

.Bosch-IC-signal-full:before {
  content: $bosch-ic-signal-full;
}

.Bosch-IC-signal-half:before {
  content: $bosch-ic-signal-half;
}

.Bosch-IC-signal-lost:before {
  content: $bosch-ic-signal-lost;
}

.Bosch-IC-signpost:before {
  content: $bosch-ic-signpost;
}

.Bosch-IC-simcard:before {
  content: $bosch-ic-simcard;
}

.Bosch-IC-skyscraper:before {
  content: $bosch-ic-skyscraper;
}

.Bosch-IC-smartcity:before {
  content: $bosch-ic-smartcity;
}

.Bosch-IC-smarthome:before {
  content: $bosch-ic-smarthome;
}

.Bosch-Ic-phone:before {
  content: $bosch-ic-smartphone;
}

.Bosch-IC-smartphone-acoustic:before {
  content: $bosch-ic-smartphone-acoustic;
}

.Bosch-IC-smartphone-arrow-right:before {
  content: $bosch-ic-smartphone-arrow-right;
}

.Bosch-IC-smartphone-arrows-x-y-z:before {
  content: $bosch-ic-smartphone-arrows-x-y-z;
}

.Bosch-IC-smartphone-clock:before {
  content: $bosch-ic-smartphone-clock;
}

.Bosch-IC-smartphone-cloud:before {
  content: $bosch-ic-smartphone-cloud;
}

.Bosch-IC-smartphone-loading-wheel:before {
  content: $bosch-ic-smartphone-loading-wheel;
}

.Bosch-IC-smartphone-smashed:before {
  content: $bosch-ic-smartphone-smashed;
}

.Bosch-IC-smartphone-vibration:before {
  content: $bosch-ic-smartphone-vibration;
}

.Bosch-IC-smartphone-z:before {
  content: $bosch-ic-smartphone-z;
}

.Bosch-Ic-spareparts:before {
  content: $bosch-ic-spare-parts;
}

.Bosch-IC-spark-plug:before {
  content: $bosch-ic-spark-plug;
}

.Bosch-IC-speaker-acoustic:before {
  content: $bosch-ic-speaker-acoustic;
}

.Bosch-IC-speedometer:before {
  content: $bosch-ic-speedometer;
}

.Bosch-IC-stadium:before {
  content: $bosch-ic-stadium;
}

.Bosch-IC-stairs:before {
  content: $bosch-ic-stairs;
}

.Bosch-IC-stamp:before {
  content: $bosch-ic-stamp;
}

.Bosch-IC-standby:before {
  content: $bosch-ic-standby;
}

.Bosch-IC-steering-wheel:before {
  content: $bosch-ic-steering-wheel;
}

.Bosch-IC-steering-wheel-connected:before {
  content: $bosch-ic-steering-wheel-connected;
}

.Bosch-IC-steering-wheel-hands:before {
  content: $bosch-ic-steering-wheel-hands;
}

.Bosch-IC-steps:before {
  content: $bosch-ic-steps;
}

.Bosch-IC-stethoscope:before {
  content: $bosch-ic-stethoscope;
}

.Bosch-Ic-stop:before {
  content: $bosch-ic-stop;
}

.Bosch-IC-stopwatch:before {
  content: $bosch-ic-stopwatch;
}

.Bosch-IC-storage-local:before {
  content: $bosch-ic-storage-local;
}

.Bosch-IC-structure:before {
  content: $bosch-ic-structure;
}

.Bosch-IC-subtitles:before {
  content: $bosch-ic-subtitles;
}

.Bosch-Ic-summary:before {
  content: $bosch-ic-summary;
}

.Bosch-IC-sun:before {
  content: $bosch-ic-sun;
}

.Bosch-IC-sun-half-filled:before {
  content: $bosch-ic-sun-half-filled;
}

.Bosch-IC-sunshade-table:before {
  content: $bosch-ic-sunshade-table;
}

.Bosch-IC-surveillance:before {
  content: $bosch-ic-surveillance;
}

.Bosch-IC-table-chairs:before {
  content: $bosch-ic-table-chairs;
}

.Bosch-Ic-tablet:before {
  content: $bosch-ic-tablet;
}

.Bosch-IC-team:before {
  content: $bosch-ic-team;
}

.Bosch-IC-team-lightbulb:before {
  content: $bosch-ic-team-lightbulb;
}

.Bosch-IC-technical-service:before {
  content: $bosch-ic-technical-service;
}

.Bosch-Ic-temperature:before {
  content: $bosch-ic-temperature;
}

.Bosch-IC-temperature-high:before {
  content: $bosch-ic-temperature-high;
}

.Bosch-IC-temperature-low:before {
  content: $bosch-ic-temperature-low;
}

.Bosch-IC-theater:before {
  content: $bosch-ic-theater;
}

.Bosch-Ic-dislike:before {
  content: $bosch-ic-thumb-down;
}

.Bosch-Ic-like:before {
  content: $bosch-ic-thumb-up;
}

.Bosch-Ic-ticket:before {
  content: $bosch-ic-ticket;
}

.Bosch-IC-touch:before {
  content: $bosch-ic-touch;
}

.Bosch-IC-touch-sos:before {
  content: $bosch-ic-touch-sos;
}

.Bosch-IC-towing-truck:before {
  content: $bosch-ic-towing-truck;
}

.Bosch-IC-train:before {
  content: $bosch-ic-train;
}

.Bosch-IC-train-side:before {
  content: $bosch-ic-train-side;
}

.Bosch-Ic-transport-movements:before {
  content: $bosch-ic-transport-movements;
}

.Bosch-IC-transscript:before {
  content: $bosch-ic-transscript;
}

.Bosch-IC-transscript-off:before {
  content: $bosch-ic-transscript-off;
}

.Bosch-IC-transscript-on:before {
  content: $bosch-ic-transscript-on;
}

.Bosch-IC-tree:before {
  content: $bosch-ic-tree;
}

.Bosch-IC-tricycle:before {
  content: $bosch-ic-tricycle;
}

.Bosch-Ic-default-trailer:before {
  content: $bosch-ic-trolley-empty;
}

.Bosch-Ic-taxi-trailer:before {
  content: $bosch-ic-trolley-filled-box;
}

.Bosch-Ic-shelve-trailer:before {
  content: $bosch-ic-trolly-filled-files;
}

.Bosch-IC-trophy:before {
  content: $bosch-ic-trophy;
}

.Bosch-IC-truck:before {
  content: $bosch-ic-truck;
}

.Bosch-IC-tuktuk:before {
  content: $bosch-ic-tuktuk;
}

.Bosch-Ic-tune:before {
  content: $bosch-ic-tune;
}

.Bosch-IC-typography:before {
  content: $bosch-ic-typography;
}

.Bosch-IC-ui:before {
  content: $bosch-ic-ui;
}

.Bosch-IC-umbrella:before {
  content: $bosch-ic-umbrella;
}

.Bosch-Ic-undo:before {
  content: $bosch-ic-undo;
}

.Bosch-Ic-arrow-expand-up:before {
  content: $bosch-ic-up;
}

.Bosch-Ic-arrow-expand-double-up:before {
  content: $bosch-ic-up-double;
}

.Bosch-Ic-sort:before {
  content: $bosch-ic-up-down;
}

.Bosch-Ic-upload:before {
  content: $bosch-ic-upload;
}

.Bosch-IC-usb:before {
  content: $bosch-ic-usb;
}

.Bosch-Ic-user-outline:before {
  content: $bosch-ic-user;
}

.Bosch-IC-user-advanced:before {
  content: $bosch-ic-user-advanced;
}

.Bosch-IC-user-beginner:before {
  content: $bosch-ic-user-beginner;
}

.Bosch-IC-user-desktop:before {
  content: $bosch-ic-user-desktop;
}

.Bosch-IC-user-down:before {
  content: $bosch-ic-user-down;
}

.Bosch-IC-user-falling:before {
  content: $bosch-ic-user-falling;
}

.Bosch-IC-user-hand:before {
  content: $bosch-ic-user-hand;
}

.Bosch-IC-user-hand-stop:before {
  content: $bosch-ic-user-hand-stop;
}

.Bosch-IC-user-professional:before {
  content: $bosch-ic-user-professional;
}

.Bosch-IC-user-run:before {
  content: $bosch-ic-user-run;
}

.Bosch-IC-user-share:before {
  content: $bosch-ic-user-share;
}

.Bosch-IC-user-voice:before {
  content: $bosch-ic-user-voice;
}

.Bosch-IC-user-walk:before {
  content: $bosch-ic-user-walk;
}

.Bosch-Ic-videocam:before {
  content: $bosch-ic-video;
}

.Bosch-Ic-videocam-disabled:before {
  content: $bosch-ic-video-disabled-light;
}

.Bosch-IC-video-record:before {
  content: $bosch-ic-video-record;
}

.Bosch-IC-virtual-reality:before {
  content: $bosch-ic-virtual-reality;
}

.Bosch-Ic-volume-disabled:before {
  content: $bosch-ic-volume-disabled;
}

.Bosch-Ic-volume-high:before {
  content: $bosch-ic-volume-high;
}

.Bosch-Ic-volume-low:before {
  content: $bosch-ic-volume-low;
}

.Bosch-Ic-volume-off:before {
  content: $bosch-ic-volume-off-light;
}

.Bosch-Ic-magic-wand:before {
  content: $bosch-ic-wand;
}

.Bosch-Ic-hidden-project:before {
  content: $bosch-ic-watch-off-disabled-light;
}

.Bosch-Ic-show:before {
  content: $bosch-ic-watch-on;
}

.Bosch-IC-water-shower:before {
  content: $bosch-ic-water-shower;
}

.Bosch-IC-welcome:before {
  content: $bosch-ic-welcome;
}

.Bosch-IC-wellness:before {
  content: $bosch-ic-wellness;
}

.Bosch-IC-wheelchair:before {
  content: $bosch-ic-wheelchair;
}

.Bosch-Ic-alert:before {
  content: $bosch-ic-whistle;
}

.Bosch-IC-wide-angled-arrow:before {
  content: $bosch-ic-wide-angled-arrow;
}

.Bosch-Ic-wifi:before {
  content: $bosch-ic-wifi;
}

.Bosch-Ic-window-analysis:before {
  content: $bosch-ic-window-analysis;
}

.Bosch-Ic-window-browser:before {
  content: $bosch-ic-window-browser;
}

.Bosch-Ic-window-console:before {
  content: $bosch-ic-window-console;
}

.Bosch-Ic-gaussian-view:before {
  content: $bosch-ic-window-gaussian-view;
}

.Bosch-Ic-window-info:before {
  content: $bosch-ic-window-info;
}

.Bosch-IC-window-new:before {
  content: $bosch-ic-window-new;
}

.Bosch-IC-window-resize:before {
  content: $bosch-ic-window-resize;
}

.Bosch-Ic-general-scaling-view:before {
  content: $bosch-ic-window-scaling-view;
}

.Bosch-Ic-restore-window:before {
  content: $bosch-ic-window-shuffle;
}

.Bosch-Ic-window-statistic:before {
  content: $bosch-ic-window-statistic;
}

.Bosch-Ic-wishlist:before {
  content: $bosch-ic-wishlist;
}

.Bosch-Ic-work-orders:before {
  content: $bosch-ic-work-order;
}

.Bosch-IC-worldwideweb:before {
  content: $bosch-ic-worldwideweb;
}

.Bosch-Ic-wrench:before {
  content: $bosch-ic-wrench;
}

.Bosch-Ic-zoom-in:before {
  content: $bosch-ic-zoom-in;
}

.Bosch-Ic-zoom-out:before {
  content: $bosch-ic-zoom-out;
}
