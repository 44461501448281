/*!
 *  Copyright (C) 2017 Robert Bosch GmbH Copyright (C) 2017 Robert Bosch Manufacturing Solutions GmbH, Germany. All rights reserved.
*/
// This is a GENERATED FILE! Do not touch.

$bosch-ic-3d-print: "\e085";
$bosch-ic-a-to-b: "\e086";
$bosch-ic-add: "\e087";
$bosch-ic-address-consumer-data: "\e088";
$bosch-ic-adress-book: "\e089";
$bosch-ic-agility: "\e08a";
$bosch-ic-alarm-clock: "\e08b";
$bosch-ic-ambulance: "\e08c";
$bosch-ic-analysis: "\e08d";
$bosch-ic-anchor: "\e08e";
$bosch-ic-arrow-down: "\e08f";
$bosch-ic-arrow-left: "\e090";
$bosch-ic-arrow-left-down: "\e091";
$bosch-ic-arrow-left-up: "\e092";
$bosch-ic-arrow-right: "\e093";
$bosch-ic-arrow-right-down: "\e094";
$bosch-ic-arrow-right-up: "\e095";
$bosch-ic-arrow-turn-left: "\e096";
$bosch-ic-arrow-turn-right: "\e097";
$bosch-ic-arrow-up: "\e098";
$bosch-ic-arrows: "\e099";
$bosch-ic-arrows-x-y-z: "\e09a";
$bosch-ic-artificial-intelligence: "\e09b";
$bosch-ic-assembly-line: "\e09c";
$bosch-ic-atom: "\e09d";
$bosch-ic-augmented-reality: "\e09e";
$bosch-ic-autobod: "\e09f";
$bosch-ic-back-left: "\e0a0";
$bosch-ic-back-left-double: "\e0a1";
$bosch-ic-bar-chart: "\e0a2";
$bosch-ic-bar-chart-double: "\e0a3";
$bosch-ic-battery-0: "\e0a4";
$bosch-ic-battery-1: "\e0a5";
$bosch-ic-battery-2: "\e0a6";
$bosch-ic-battery-3: "\e0a7";
$bosch-ic-battery-4: "\e0a8";
$bosch-ic-battery-fail: "\e0a9";
$bosch-ic-battery-li-ion: "\e0aa";
$bosch-ic-battery-loading: "\e0ab";
$bosch-ic-bed: "\e0ac";
$bosch-ic-bicycle: "\e0ad";
$bosch-ic-bicycle-e: "\e0ae";
$bosch-ic-blog: "\e0af";
$bosch-ic-bluetooth: "\e0b0";
$bosch-ic-bluetooth-le: "\e0b1";
$bosch-ic-board-speaker: "\e0b2";
$bosch-ic-boiler-connected: "\e0b3";
$bosch-ic-book: "\e0b4";
$bosch-ic-bookmark: "\e0b5";
$bosch-ic-bookmark-add: "\e0b6";
$bosch-ic-bookmark-check: "\e0b7";
$bosch-ic-bookmark-delete: "\e0b8";
$bosch-ic-books: "\e0b9";
$bosch-ic-bookshelf: "\e0ba";
$bosch-ic-box: "\e0bb";
$bosch-ic-box-closed: "\e0bc";
$bosch-ic-box-delivery: "\e0bd";
$bosch-ic-box-delivery-connected: "\e0be";
$bosch-ic-brake-disk: "\e0bf";
$bosch-ic-briefcase: "\e0c0";
$bosch-ic-broom: "\e0c1";
$bosch-ic-broom-cloud-dirt: "\e0c2";
$bosch-ic-brush: "\e0c3";
$bosch-ic-bug: "\e0c4";
$bosch-ic-building: "\e0c5";
$bosch-ic-cactus: "\e0c6";
$bosch-ic-calculate: "\e0c7";
$bosch-ic-calendar: "\e0c8";
$bosch-ic-calendar-add: "\e0c9";
$bosch-ic-calendar-settings: "\e0ca";
$bosch-ic-call: "\e0cb";
$bosch-ic-call-add: "\e0cc";
$bosch-ic-call-remove: "\e0cd";
$bosch-ic-call-sos: "\e0ce";
$bosch-ic-call-team: "\e0cf";
$bosch-ic-call-wifi: "\e0d0";
$bosch-ic-camera: "\e0d1";
$bosch-ic-camera-switch: "\e0d2";
$bosch-ic-car: "\e0d3";
$bosch-ic-car-clock: "\e0d4";
$bosch-ic-car-connected: "\e0d5";
$bosch-ic-car-locator: "\e0d6";
$bosch-ic-car-mechanic: "\e0d7";
$bosch-ic-car-rental: "\e0d8";
$bosch-ic-car-seat-connected: "\e0d9";
$bosch-ic-car-side: "\e0da";
$bosch-ic-car-side-user: "\e0db";
$bosch-ic-cart: "\e0dc";
$bosch-ic-certificate: "\e0dd";
$bosch-ic-certificate-pki: "\e0de";
$bosch-ic-change: "\e0df";
$bosch-ic-chart-bar: "\e0e0";
$bosch-ic-chart-check: "\e0e1";
$bosch-ic-chart-dummy: "\e0e2";
$bosch-ic-chart-line: "\e0e3";
$bosch-ic-chat: "\e0e4";
$bosch-ic-chat-add: "\e0e5";
$bosch-ic-chat-question-answer: "\e0e6";
$bosch-ic-chat-remove: "\e0e7";
$bosch-ic-checklist: "\e0e8";
$bosch-ic-checkmark: "\e0e9";
$bosch-ic-chip: "\e0ea";
$bosch-ic-circle-segment: "\e0eb";
$bosch-ic-clear-all: "\e0ec";
$bosch-ic-clipboard: "\e0ed";
$bosch-ic-clipboard-list: "\e0ee";
$bosch-ic-clock: "\e0ef";
$bosch-ic-close: "\e0f0";
$bosch-ic-close-all: "\e0f1";
$bosch-ic-cloud: "\e0f2";
$bosch-ic-cloud-co2: "\e0f3";
$bosch-ic-cloud-download: "\e0f4";
$bosch-ic-cloud-nox: "\e0f5";
$bosch-ic-cloud-upload: "\e0f6";
$bosch-ic-club-arm-chair: "\e0f7";
$bosch-ic-coffee-break: "\e0f8";
$bosch-ic-coin-currency: "\e0f9";
$bosch-ic-coin-dollar: "\e0fa";
$bosch-ic-colorpicker: "\e0fb";
$bosch-ic-colors: "\e0fc";
$bosch-ic-command: "\e0fd";
$bosch-ic-communicator: "\e0fe";
$bosch-ic-compare: "\e0ff";
$bosch-ic-components: "\e100";
$bosch-ic-components-available: "\e101";
$bosch-ic-components-reservation: "\e102";
$bosch-ic-concierge-bell: "\e103";
$bosch-ic-configuration: "\e104";
$bosch-ic-confirmation: "\e105";
$bosch-ic-connection-off: "\e106";
$bosch-ic-connection-on: "\e107";
$bosch-ic-connectivity: "\e108";
$bosch-ic-construction: "\e109";
$bosch-ic-consumer-goods: "\e10a";
$bosch-ic-contract: "\e10b";
$bosch-ic-copy: "\e10c";
$bosch-ic-cube: "\e10d";
$bosch-ic-cube-arrows: "\e10e";
$bosch-ic-cube-filled: "\e10f";
$bosch-ic-cube-shock: "\e110";
$bosch-ic-cube-stacked: "\e111";
$bosch-ic-customerservice: "\e112";
$bosch-ic-cutlery: "\e113";
$bosch-ic-damper: "\e114";
$bosch-ic-danger-flash: "\e115";
$bosch-ic-data-loss: "\e116";
$bosch-ic-dealer-details: "\e117";
$bosch-ic-delete: "\e118";
$bosch-ic-delivery: "\e119";
$bosch-ic-denied: "\e11a";
$bosch-ic-desktop: "\e11b";
$bosch-ic-desktop-application-2d: "\e11c";
$bosch-ic-desktop-application-3d: "\e11d";
$bosch-ic-development: "\e11e";
$bosch-ic-devices: "\e11f";
$bosch-ic-devices-mobile: "\e120";
$bosch-ic-directory: "\e121";
$bosch-ic-dna: "\e122";
$bosch-ic-do-not-disturb: "\e123";
$bosch-ic-document: "\e124";
$bosch-ic-document-add: "\e125";
$bosch-ic-document-arrow-down: "\e126";
$bosch-ic-document-arrow-up: "\e127";
$bosch-ic-document-audio: "\e128";
$bosch-ic-document-check: "\e129";
$bosch-ic-document-cloud: "\e12a";
$bosch-ic-document-copy: "\e12b";
$bosch-ic-document-delete: "\e12c";
$bosch-ic-document-doc: "\e12d";
$bosch-ic-document-edit: "\e12e";
$bosch-ic-document-error: "\e12f";
$bosch-ic-document-locked: "\e130";
$bosch-ic-document-log: "\e131";
$bosch-ic-document-one: "\e132";
$bosch-ic-document-pdf: "\e133";
$bosch-ic-document-plain: "\e134";
$bosch-ic-document-plain-add: "\e135";
$bosch-ic-document-plain-delete: "\e136";
$bosch-ic-document-ppt: "\e137";
$bosch-ic-document-pub: "\e138";
$bosch-ic-document-rtf: "\e139";
$bosch-ic-document-save-to: "\e13a";
$bosch-ic-document-search: "\e13b";
$bosch-ic-document-settings: "\e13c";
$bosch-ic-document-test: "\e13d";
$bosch-ic-document-text: "\e13e";
$bosch-ic-document-txt: "\e13f";
$bosch-ic-document-vsd: "\e140";
$bosch-ic-document-xls: "\e141";
$bosch-ic-document-xml: "\e142";
$bosch-ic-document-zip: "\e143";
$bosch-ic-dome: "\e144";
$bosch-ic-door: "\e145";
$bosch-ic-door-sensor: "\e146";
$bosch-ic-down: "\e147";
$bosch-ic-down-double: "\e148";
$bosch-ic-download: "\e149";
$bosch-ic-drag-handle: "\e14a";
$bosch-ic-dragdrop: "\e14b";
$bosch-ic-drop: "\e14c";
$bosch-ic-e-charging: "\e14d";
$bosch-ic-e-mobility: "\e14e";
$bosch-ic-ear-off-disabled-light: "\e14f";
$bosch-ic-ear-on: "\e150";
$bosch-ic-eco-system: "\e151";
$bosch-ic-edit: "\e152";
$bosch-ic-education: "\e153";
$bosch-ic-efficiency: "\e154";
$bosch-ic-elevator: "\e155";
$bosch-ic-elevator-alarm: "\e156";
$bosch-ic-elevator-cloud: "\e157";
$bosch-ic-elevator-headset: "\e158";
$bosch-ic-elevator-service: "\e159";
$bosch-ic-emergency-exit: "\e15a";
$bosch-ic-emoji-happy: "\e15b";
$bosch-ic-emoji-neutral: "\e15c";
$bosch-ic-emoji-sad: "\e15d";
$bosch-ic-emoji-super-happy: "\e15e";
$bosch-ic-emoji-very-sad: "\e15f";
$bosch-ic-eu-energy-label: "\e160";
$bosch-ic-excavator: "\e161";
$bosch-ic-exit: "\e163";
$bosch-ic-expansion-arrows: "\e164";
$bosch-ic-explosion: "\e165";
$bosch-ic-export: "\e166";
$bosch-ic-externallink: "\e167";
$bosch-ic-fast-forward: "\e168";
$bosch-ic-faucet: "\e169";
$bosch-ic-favorites: "\e16a";
$bosch-ic-fax: "\e16b";
$bosch-ic-female: "\e16c";
$bosch-ic-film: "\e16d";
$bosch-ic-filter: "\e16e";
$bosch-ic-filter-success: "\e16f";
$bosch-ic-fingerprint: "\e170";
$bosch-ic-fire: "\e171";
$bosch-ic-fire-emergency: "\e172";
$bosch-ic-fireworks: "\e173";
$bosch-ic-first-aid: "\e174";
$bosch-ic-first-aid-cross: "\e175";
$bosch-ic-fit-to-sceen: "\e176";
$bosch-ic-flag: "\e177";
$bosch-ic-flash: "\e178";
$bosch-ic-flask: "\e179";
$bosch-ic-flexpress: "\e17a";
$bosch-ic-folder: "\e17b";
$bosch-ic-folder-download: "\e17c";
$bosch-ic-folder-open: "\e17d";
$bosch-ic-folder-upload: "\e17e";
$bosch-ic-fork-lift: "\e17f";
$bosch-ic-fork-lift-locator: "\e180";
$bosch-ic-forward-right: "\e181";
$bosch-ic-forward-right-double: "\e182";
$bosch-ic-full-empty: "\e183";
$bosch-ic-fullscreen: "\e184";
$bosch-ic-fullscreen-arrows: "\e185";
$bosch-ic-fullscreen-exit: "\e186";
$bosch-ic-g-arrow-down: "\e187";
$bosch-ic-g-arrow-up: "\e188";
$bosch-ic-generator: "\e189";
$bosch-ic-gift: "\e18a";
$bosch-ic-glance: "\e18b";
$bosch-ic-glas-plate: "\e18c";
$bosch-ic-globe: "\e18d";
$bosch-ic-globe-arrow: "\e18e";
$bosch-ic-glossary: "\e18f";
$bosch-ic-grid-view: "\e190";
$bosch-ic-hammer: "\e191";
$bosch-ic-hand: "\e192";
$bosch-ic-hand-motion: "\e193";
$bosch-ic-hand-motion-off-disabled-light: "\e194";
$bosch-ic-handlewithcare: "\e195";
$bosch-ic-handover-add: "\e196";
$bosch-ic-handover-check: "\e197";
$bosch-ic-handover-remove: "\e198";
$bosch-ic-hanger: "\e199";
$bosch-ic-health: "\e19a";
$bosch-ic-heart: "\e19b";
$bosch-ic-history: "\e19c";
$bosch-ic-home: "\e19d";
$bosch-ic-home-locator: "\e19e";
$bosch-ic-hourglass: "\e19f";
$bosch-ic-hydrant: "\e1a0";
$bosch-ic-ice: "\e1a1";
$bosch-ic-imagery: "\e1a2";
$bosch-ic-imagery-add: "\e1a3";
$bosch-ic-imagery-remove: "\e1a4";
$bosch-ic-import: "\e1a5";
$bosch-ic-imprint: "\e1a6";
$bosch-ic-impulse: "\e1a7";
$bosch-ic-industry: "\e1a8";
$bosch-ic-industry-clock: "\e1a9";
$bosch-ic-industry-connected: "\e1aa";
$bosch-ic-info: "\e1ab";
$bosch-ic-info-i: "\e1ac";
$bosch-ic-interval: "\e1ad";
$bosch-ic-it-device: "\e1ae";
$bosch-ic-jewel: "\e1af";
$bosch-ic-keyboard: "\e1b0";
$bosch-ic-label: "\e1b1";
$bosch-ic-laptop: "\e1b2";
$bosch-ic-laser: "\e1b3";
$bosch-ic-layout: "\e1b4";
$bosch-ic-leaf: "\e1b5";
$bosch-ic-led: "\e1b6";
$bosch-ic-brick: "\e1b7";
$bosch-ic-brick-slanting-1: "\e1b8";
$bosch-ic-brick-slanting-2: "\e1b9";
$bosch-ic-less-minimize: "\e1ba";
$bosch-ic-lightbulb: "\e1bb";
$bosch-ic-lightbulb-off: "\e1bc";
$bosch-ic-line-chart: "\e1bd";
$bosch-ic-link: "\e1be";
$bosch-ic-link-broken: "\e1bf";
$bosch-ic-list-view: "\e1c0";
$bosch-ic-list-view-mobile: "\e1c1";
$bosch-ic-livechat: "\e1c2";
$bosch-ic-locator: "\e1c3";
$bosch-ic-locator-ip-connected: "\e1c4";
$bosch-ic-locator-ip-disconnected: "\e1c5";
$bosch-ic-locator-spot: "\e1c6";
$bosch-ic-locator-spot-check: "\e1c7";
$bosch-ic-lock-closed: "\e1c8";
$bosch-ic-lock-open: "\e1c9";
$bosch-ic-login: "\e1ca";
$bosch-ic-logistics: "\e1cb";
$bosch-ic-logout: "\e1cc";
$bosch-ic-machine: "\e1cd";
$bosch-ic-magnet: "\e1ce";
$bosch-ic-mail: "\e1cf";
$bosch-ic-mail-forward: "\e1d0";
$bosch-ic-mail-open: "\e1d1";
$bosch-ic-mail-out: "\e1d2";
$bosch-ic-mail-reply: "\e1d3";
$bosch-ic-male: "\e1d4";
$bosch-ic-map: "\e1d5";
$bosch-ic-material-add: "\e1d6";
$bosch-ic-material-check: "\e1d7";
$bosch-ic-material-remove: "\e1d8";
$bosch-ic-maximize: "\e1d9";
$bosch-ic-mechanic: "\e1da";
$bosch-ic-megaphone: "\e1db";
$bosch-ic-message: "\e1dc";
$bosch-ic-mic: "\e1dd";
$bosch-ic-microphone: "\e1de";
$bosch-ic-microphone-classic: "\e1df";
$bosch-ic-minimize: "\e1e0";
$bosch-ic-money-currency: "\e1e1";
$bosch-ic-money-dollar: "\e1e2";
$bosch-ic-moneybag-currency: "\e1e3";
$bosch-ic-moneybag-dollar: "\e1e4";
$bosch-ic-monument: "\e1e5";
$bosch-ic-moon: "\e1e6";
$bosch-ic-motorcycle: "\e1e7";
$bosch-ic-motorcycle-side-car: "\e1e8";
$bosch-ic-motorcycle-side: "\e1e9";
$bosch-ic-mouse: "\e1ea";
$bosch-ic-mouse-left: "\e1eb";
$bosch-ic-mouse-right: "\e1ec";
$bosch-ic-mouth: "\e1ed";
$bosch-ic-my-product: "\e1ee";
$bosch-ic-new-way-work: "\e1ef";
$bosch-ic-newsletter: "\e1f0";
$bosch-ic-newspaper: "\e1f1";
$bosch-ic-nose: "\e1f2";
$bosch-ic-notepad: "\e1f3";
$bosch-ic-notepad-edit: "\e1f4";
$bosch-ic-notification: "\e1f5";
$bosch-ic-notification-active: "\e1f6";
$bosch-ic-notification-add: "\e1f7";
$bosch-ic-notification-remove: "\e1f8";
$bosch-ic-oil-car: "\e1f9";
$bosch-ic-omnichannel: "\e1fa";
$bosch-ic-options: "\e1fb";
$bosch-ic-origami-boat: "\e1fc";
$bosch-ic-pallete-car: "\e1fd";
$bosch-ic-pallete-car-connected: "\e1fe";
$bosch-ic-panel: "\e1ff";
$bosch-ic-paperclip: "\e200";
$bosch-ic-paperplane: "\e201";
$bosch-ic-parking: "\e202";
$bosch-ic-pause: "\e203";
$bosch-ic-payment: "\e204";
$bosch-ic-people: "\e205";
$bosch-ic-petrol-station: "\e206";
$bosch-ic-piggybank: "\e207";
$bosch-ic-pin-classic: "\e208";
$bosch-ic-pin-modern: "\e209";
$bosch-ic-plane-side: "\e20a";
$bosch-ic-play: "\e20b";
$bosch-ic-plug-12v: "\e20c";
$bosch-ic-podium-speaker: "\e20d";
$bosch-ic-police: "\e20e";
$bosch-ic-poop: "\e20f";
$bosch-ic-post-it: "\e210";
$bosch-ic-postal-code: "\e211";
$bosch-ic-power: "\e212";
$bosch-ic-print: "\e213";
$bosch-ic-prototyping: "\e214";
$bosch-ic-puzzle: "\e215";
$bosch-ic-quad: "\e216";
$bosch-ic-question: "\e217";
$bosch-ic-radar: "\e218";
$bosch-ic-radio: "\e219";
$bosch-ic-radiotower: "\e21a";
$bosch-ic-redirect: "\e21b";
$bosch-ic-redo: "\e21c";
$bosch-ic-reference: "\e21d";
$bosch-ic-refresh: "\e21e";
$bosch-ic-refresh-cloud: "\e21f";
$bosch-ic-registration: "\e220";
$bosch-ic-remote: "\e221";
$bosch-ic-reset: "\e222";
$bosch-ic-resolution: "\e223";
$bosch-ic-robot: "\e224";
$bosch-ic-robot-connected: "\e225";
$bosch-ic-robothead: "\e226";
$bosch-ic-rocket: "\e227";
$bosch-ic-rotation: "\e228";
$bosch-ic-rotation-360: "\e229";
$bosch-ic-rotation-x-left: "\e22a";
$bosch-ic-rotation-x-right: "\e22b";
$bosch-ic-rotation-y-down: "\e22c";
$bosch-ic-rotation-y-up: "\e22d";
$bosch-ic-route: "\e22e";
$bosch-ic-ruler: "\e22f";
$bosch-ic-ruler-pen: "\e230";
$bosch-ic-satellite: "\e231";
$bosch-ic-save: "\e232";
$bosch-ic-scale: "\e233";
$bosch-ic-scissors: "\e234";
$bosch-ic-search: "\e235";
$bosch-ic-security: "\e236";
$bosch-ic-security-check: "\e237";
$bosch-ic-security-user: "\e238";
$bosch-ic-sensor: "\e239";
$bosch-ic-server: "\e23a";
$bosch-ic-server-expansion: "\e23b";
$bosch-ic-server-rate: "\e23c";
$bosch-ic-service-agent: "\e23d";
$bosch-ic-service-time: "\e23e";
$bosch-ic-settings: "\e23f";
$bosch-ic-settings-arrows: "\e240";
$bosch-ic-settings-connected: "\e241";
$bosch-ic-share: "\e242";
$bosch-ic-shield-stop: "\e243";
$bosch-ic-ship: "\e244";
$bosch-ic-ship-side: "\e245";
$bosch-ic-shoppingcart: "\e246";
$bosch-ic-shoppingcart-add: "\e247";
$bosch-ic-shoppingcart-switch: "\e248";
$bosch-ic-signal-full: "\e249";
$bosch-ic-signal-half: "\e24a";
$bosch-ic-signal-lost: "\e24b";
$bosch-ic-signpost: "\e24c";
$bosch-ic-simcard: "\e24d";
$bosch-ic-skyscraper: "\e24e";
$bosch-ic-smartcity: "\e24f";
$bosch-ic-smarthome: "\e250";
$bosch-ic-smartphone: "\e251";
$bosch-ic-smartphone-acoustic: "\e252";
$bosch-ic-smartphone-arrow-right: "\e253";
$bosch-ic-smartphone-arrows-x-y-z: "\e254";
$bosch-ic-smartphone-clock: "\e255";
$bosch-ic-smartphone-cloud: "\e256";
$bosch-ic-smartphone-loading-wheel: "\e257";
$bosch-ic-smartphone-smashed: "\e258";
$bosch-ic-smartphone-vibration: "\e259";
$bosch-ic-smartphone-z: "\e25a";
$bosch-ic-smartwatch-connected: "\e25b";
$bosch-ic-spare-parts: "\e25c";
$bosch-ic-spark-plug: "\e25d";
$bosch-ic-speaker-acoustic: "\e25e";
$bosch-ic-speedometer: "\e25f";
$bosch-ic-stadium: "\e260";
$bosch-ic-stairs: "\e261";
$bosch-ic-stamp: "\e262";
$bosch-ic-standby: "\e263";
$bosch-ic-steering-wheel: "\e264";
$bosch-ic-steering-wheel-connected: "\e265";
$bosch-ic-steering-wheel-hands: "\e266";
$bosch-ic-steps: "\e267";
$bosch-ic-stethoscope: "\e268";
$bosch-ic-stop: "\e269";
$bosch-ic-stopwatch: "\e26a";
$bosch-ic-storage-local: "\e26b";
$bosch-ic-structure: "\e26c";
$bosch-ic-subtitles: "\e26d";
$bosch-ic-subtitles-off-light: "\e26e";
$bosch-ic-summary: "\e26f";
$bosch-ic-sun: "\e270";
$bosch-ic-sun-half-filled: "\e271";
$bosch-ic-sunshade-table: "\e272";
$bosch-ic-surveillance: "\e273";
$bosch-ic-table-chairs: "\e274";
$bosch-ic-tablet: "\e275";
$bosch-ic-team: "\e276";
$bosch-ic-team-lightbulb: "\e277";
$bosch-ic-technical-service: "\e278";
$bosch-ic-temperature: "\e279";
$bosch-ic-temperature-high: "\e27a";
$bosch-ic-temperature-low: "\e27b";
$bosch-ic-theater: "\e27c";
$bosch-ic-thumb-down: "\e27d";
$bosch-ic-thumb-up: "\e27e";
$bosch-ic-ticket: "\e27f";
$bosch-ic-touch: "\e280";
$bosch-ic-touch-sos: "\e281";
$bosch-ic-towing-truck: "\e282";
$bosch-ic-train: "\e283";
$bosch-ic-train-side: "\e284";
$bosch-ic-transport-movements: "\e285";
$bosch-ic-transscript: "\e286";
$bosch-ic-transscript-off: "\e287";
$bosch-ic-transscript-on: "\e288";
$bosch-ic-tree: "\e289";
$bosch-ic-tricycle: "\e28a";
$bosch-ic-trolley-empty: "\e28b";
$bosch-ic-trolley-filled-box: "\e28c";
$bosch-ic-trolly-filled-files: "\e28d";
$bosch-ic-trophy: "\e28e";
$bosch-ic-truck: "\e28f";
$bosch-ic-tuktuk: "\e290";
$bosch-ic-tune: "\e291";
$bosch-ic-typography: "\e292";
$bosch-ic-ui: "\e293";
$bosch-ic-umbrella: "\e294";
$bosch-ic-undo: "\e295";
$bosch-ic-up: "\e296";
$bosch-ic-up-double: "\e297";
$bosch-ic-up-down: "\e298";
$bosch-ic-upload: "\e299";
$bosch-ic-usb: "\e29a";
$bosch-ic-user: "\e29b";
$bosch-ic-user-advanced: "\e29c";
$bosch-ic-user-beginner: "\e29d";
$bosch-ic-user-desktop: "\e29e";
$bosch-ic-user-down: "\e29f";
$bosch-ic-user-falling: "\e2a0";
$bosch-ic-user-hand: "\e2a1";
$bosch-ic-user-hand-stop: "\e2a2";
$bosch-ic-user-professional: "\e2a3";
$bosch-ic-user-run: "\e2a4";
$bosch-ic-user-share: "\e2a5";
$bosch-ic-user-voice: "\e2a6";
$bosch-ic-user-walk: "\e2a7";
$bosch-ic-video: "\e2a8";
$bosch-ic-video-disabled-light: "\e2a9";
$bosch-ic-video-record: "\e2aa";
$bosch-ic-virtual-reality: "\e2ab";
$bosch-ic-volume-disabled: "\e2ac";
$bosch-ic-volume-high: "\e2ad";
$bosch-ic-volume-low: "\e2ae";
$bosch-ic-volume-off-light: "\e2af";
$bosch-ic-wand: "\e2b0";
$bosch-ic-warranty: "\e2b1";
$bosch-ic-watch-off-disabled-light: "\e2b2";
$bosch-ic-watch-on: "\e2b3";
$bosch-ic-water-shower: "\e2b4";
$bosch-ic-welcome: "\e2b5";
$bosch-ic-wellness: "\e2b6";
$bosch-ic-wheelchair: "\e2b7";
$bosch-ic-whistle: "\e2b8";
$bosch-ic-wide-angled-arrow: "\e2b9";
$bosch-ic-wifi: "\e2ba";
$bosch-ic-window-analysis: "\e2bb";
$bosch-ic-window-browser: "\e2bc";
$bosch-ic-window-console: "\e2bd";
$bosch-ic-window-gaussian-view: "\e2be";
$bosch-ic-window-info: "\e2bf";
$bosch-ic-window-new: "\e2c0";
$bosch-ic-window-resize: "\e2c1";
$bosch-ic-window-scaling-view: "\e2c2";
$bosch-ic-window-shuffle: "\e2c3";
$bosch-ic-window-statistic: "\e2c4";
$bosch-ic-wishlist: "\e2c5";
$bosch-ic-work-order: "\e2c6";
$bosch-ic-worldwideweb: "\e2c7";
$bosch-ic-wrench: "\e2c8";
$bosch-ic-zoom-in: "\e2c9";
$bosch-ic-zoom-out: "\e2ca";
$bosch-ic-scooter: "\e2cb";
$bosch-ic-cars-traffic: "\e2cc";
$bosch-ic-arm-chair: "\e2cd";
$bosch-ic-car-phone: "\e2ce";
$bosch-ic-box-lightbulb: "\e2cf";
$bosch-ic-chair-officedesk: "\e2d0";
$bosch-ic-calendar-clock: "\e2d1";
$bosch-ic-document-cv: "\e2d2";
$bosch-ic-user-run-bag: "\e2d3";
$bosch-ic-brake-disk-spray: "\e2d4";
$bosch-ic-circle-abs: "\e2d5";
$bosch-ic-bright-down: "\e2d6";
$bosch-ic-bright-up: "\e2d7";
$bosch-ic-fan: "\e2d8";
$bosch-ic-sun-moon: "\e2d9";
$bosch-ic-lightbulb-attention: "\e2da";
$bosch-ic-settings-attention: "\e2db";
$bosch-ic-danger-hazards: "\e2dc";
$bosch-ic-windshield-spray: "\e2dd";
$bosch-ic-windshield-heat: "\e2de";
$bosch-ic-light-drop: "\e2df";
$bosch-ic-starter: "\e2e0";
$bosch-ic-turbo: "\e2e1";
$bosch-ic-settings-lock: "\e2e2";
$bosch-ic-settings-n: "\e2e3";
$bosch-ic-light-beam-high: "\e2e4";
$bosch-ic-light-beam-low: "\e2e5";
$bosch-ic-glas: "\e2e6";
$bosch-ic-shirt-ice: "\e2e7";
$bosch-ic-helmet-fan: "\e2e8";
$bosch-ic-notification-arrow: "\e2e9";
$bosch-ic-steering-wheel-arrow: "\e2ea";
$bosch-ic-circle-arrow: "\e2eb";
$bosch-ic-tempomat-pit: "\e2ec";
$bosch-ic-radiator-fan-spray: "\e2ed";
$bosch-ic-boost-down: "\e2ee";
$bosch-ic-boost-up: "\e2ef";
$bosch-ic-circle-lock: "\e2f0";
$bosch-ic-mp-down: "\e2f1";
$bosch-ic-mp-up: "\e2f2";
$bosch-ic-tc-down: "\e2f3";
$bosch-ic-tc-up: "\e2f4";
$bosch-ic-danger-arrow-attention: "\e2f5";
$bosch-ic-user-remove: "\e2f6";
$bosch-ic-user-add: "\e2f7";
$bosch-ic-post-it-collection: "\e2f8";
$bosch-ic-horn: "\e2f9";
$bosch-ic-desktop-notification: "\e2fa";
$bosch-ic-router: "\e2fb";
$bosch-ic-berry: "\e2fc";
$bosch-ic-chat-language: "\e2fd";
$bosch-ic-game-controller: "\e2fe";
$bosch-ic-user-brain: "\e2ff";
$bosch-ic-mouth-finger: "\e300";
$bosch-ic-excavator-shovel: "\e301";
$bosch-ic-ear-in: "\e302";
$bosch-ic-ear-out: "\e303";
$bosch-ic-lion: "\e304";
$bosch-ic-car-convertible: "\e305";
$bosch-ic-car-sports: "\e306";
$bosch-ic-bear: "\e307";
$bosch-ic-pot: "\e308";
$bosch-ic-bed-double: "\e309";
$bosch-ic-air-hot: "\e30a";
$bosch-ic-air-ice: "\e30b";
$bosch-ic-air-leaf: "\e30c";
$bosch-ic-air-purifier-0: "\e30d";
$bosch-ic-air-purifier-1: "\e30e";
$bosch-ic-air-purifier-2: "\e30f";
$bosch-ic-air-purifier-3: "\e310";
$bosch-ic-air: "\e311";
$bosch-ic-fan-off-disabled-bold: "\e312";
$bosch-ic-ice-off-disabled-bold: "\e313";
$bosch-ic-faucet-boost: "\e314";
$bosch-ic-faucet-mode: "\e315";
$bosch-ic-faucet-off-disabled-bold: "\e316";
$bosch-ic-faucet-schedule: "\e317";
$bosch-ic-fuel-cell: "\e318";
$bosch-ic-heating-floor-off-disabled-bold: "\e319";
$bosch-ic-heating-floor: "\e31a";
$bosch-ic-heating-radiator-boost: "\e31b";
$bosch-ic-heating-radiator-mode: "\e31c";
$bosch-ic-heating-radiator-off-disabled-bold: "\e31d";
$bosch-ic-heating-radiator-schedule: "\e31e";
$bosch-ic-leaf-simple: "\e31f";
$bosch-ic-oil-barrel: "\e320";
$bosch-ic-water-bathtub: "\e321";
$bosch-ic-water-dishwash: "\e322";
$bosch-ic-water-handwash: "\e323";
$bosch-ic-water-hot: "\e324";
$bosch-ic-water-ice: "\e325";
$bosch-ic-water-leaf: "\e326";
$bosch-ic-water-ladder: "\e327";
$bosch-ic-water-purifier-0: "\e328";
$bosch-ic-water-purifier-1: "\e329";
$bosch-ic-water-purifier-2: "\e32a";
$bosch-ic-water-purifier-3: "\e32b";
$bosch-ic-water-shower-boost: "\e32c";
$bosch-ic-water-shower-mode: "\e32d";
$bosch-ic-water-shower-off-disabled-bold: "\e32e";
$bosch-ic-water-shower-schedule: "\e32f";
$bosch-ic-water: "\e330";
$bosch-ic-chart-bar-drop: "\e331";
$bosch-ic-chart-bar-fire: "\e332";
$bosch-ic-chart-bar-flash: "\e333";
$bosch-ic-chart-bar-oil: "\e334";
$bosch-ic-drop-minus: "\e335";
$bosch-ic-drop-plus: "\e336";
$bosch-ic-fan-minus: "\e337";
$bosch-ic-fan-plus: "\e338";
$bosch-ic-home-drops-0: "\e339";
$bosch-ic-home-drops-1: "\e33a";
$bosch-ic-home-drops-2: "\e33b";
$bosch-ic-home-drops-3: "\e33c";
$bosch-ic-home-temperature-in: "\e33d";
$bosch-ic-home-temperature-out: "\e33e";
$bosch-ic-home-user-in: "\e33f";
$bosch-ic-ice-minus: "\e340";
$bosch-ic-ice-plus: "\e341";
$bosch-ic-chimney-sweeper: "\e342";
$bosch-ic-menu-up: "\e343";
$bosch-ic-security-ice: "\e344";
$bosch-ic-security-legionella: "\e345";
$bosch-ic-trolley-case: "\e346";
$bosch-ic-sun-minus: "\e347";
$bosch-ic-sun-plug: "\e348";
$bosch-ic-sun-plus: "\e349";
$bosch-ic-touch-pit: "\e34a";
$bosch-ic-temperature-current: "\e34b";
$bosch-ic-temperature-set: "\e34c";
$bosch-ic-teddy-bear: "\e34d";
$bosch-ic-document-svg: "\e34e";
$bosch-ic-back-menu: "\e34f";
$bosch-ic-car-arrows: "\e350";
$bosch-ic-wrench-cloud: "\e351";
$bosch-ic-weather-cloud-sun: "\e352";
$bosch-ic-weather-cloudy: "\e353";
$bosch-ic-weather-gale: "\e354";
$bosch-ic-weather-mist: "\e355";
$bosch-ic-weather-moon-cloud: "\e356";
$bosch-ic-weather-rain-snow: "\e357";
$bosch-ic-weather-rain-thunder: "\e358";
$bosch-ic-weather-rain: "\e359";
$bosch-ic-weather-snow: "\e35a";
$bosch-ic-weather-thunder: "\e35b";
$bosch-ic-weather-wind: "\e35c";
$bosch-ic-plane-start: "\e35d";
$bosch-ic-plane-land: "\e35e";
$bosch-ic-graph-power: "\e35f";
$bosch-ic-floorplan: "\e360";
$bosch-ic-clock-pause: "\e361";
$bosch-ic-clock-start: "\e362";
$bosch-ic-clock-stop: "\e363";
$bosch-ic-computer-retro: "\e364";
$bosch-ic-smartphone-retro: "\e365";
$bosch-ic-wood: "\e366";
$bosch-ic-solid-fuel: "\e367";
$bosch-ic-appliance-fan: "\e368";
$bosch-ic-appliance-flame: "\e369";
$bosch-ic-appliance-ice: "\e36a";
$bosch-ic-appliance-oil: "\e36b";
$bosch-ic-appliance-solid-fuel: "\e36c";
$bosch-ic-appliance-sun-ice: "\e36d";
$bosch-ic-appliance-sun: "\e36e";
$bosch-ic-appliance-wood: "\e36f";
$bosch-ic-appliance-air-leaf: "\e370";
$bosch-ic-appliance-water-air: "\e371";
$bosch-ic-appliance-water-leaf: "\e372";
$bosch-ic-appliance-water-oil: "\e373";
$bosch-ic-appliance-water: "\e374";
$bosch-ic-appliance-heat-pump-air: "\e375";
$bosch-ic-appliance-heat-pump-ground: "\e376";
$bosch-ic-appliance-heat-pump-water: "\e377";
$bosch-ic-appliance-plug-air: "\e378";
$bosch-ic-appliance-plug-fuel-cell: "\e379";
$bosch-ic-appliance-plug-leaf: "\e37a";
$bosch-ic-appliance-plug-water-air: "\e37b";
$bosch-ic-thermostat-connected-c: "\e37c";
$bosch-ic-thermostat-connected-f: "\e37d";
$bosch-ic-image-gallery: "\e37e";
$bosch-ic-arrows-caution: "\e37f";
$bosch-ic-arrows-diagonal-disabled-bold: "\e380";
$bosch-ic-back-left-end: "\e381";
$bosch-ic-back-right-end: "\e382";
$bosch-ic-bar-chart-search: "\e383";
$bosch-ic-batch: "\e384";
$bosch-ic-calendar-remove: "\e385";
$bosch-ic-call-bluetooth: "\e386";
$bosch-ic-call-deny: "\e387";
$bosch-ic-call-settings: "\e388";
$bosch-ic-caution-sign-clock: "\e389";
$bosch-ic-caution-sign-list: "\e38a";
$bosch-ic-core-data: "\e38b";
$bosch-ic-cursor-connected: "\e38c";
$bosch-ic-cursor: "\e38d";
$bosch-ic-dmc-code: "\e38e";
$bosch-ic-find-id: "\e38f";
$bosch-ic-globe-search: "\e390";
$bosch-ic-interface: "\e391";
$bosch-ic-material-add-manually: "\e392";
$bosch-ic-material-list: "\e393";
$bosch-ic-material-remove-manually: "\e394";
$bosch-ic-material: "\e395";
$bosch-ic-menu-checkmark: "\e396";
$bosch-ic-qr-code: "\e397";
$bosch-ic-sort-alphabetically: "\e398";
$bosch-ic-unpin-classic-disabled-bold: "\e399";
$bosch-ic-unpin-modern-disabled-bold: "\e39a";
$bosch-ic-window-search: "\e39b";
$bosch-ic-cloud-clock: "\e39c";
$bosch-ic-concierge: "\e39d";
$bosch-ic-heating-radiator: "\e39e";
$bosch-ic-home-user-out: "\e39f";
$bosch-ic-hot: "\e3a0";
$bosch-ic-screen-service: "\e3a1";
$bosch-ic-sun-ice: "\e3a2";
$bosch-ic-sun-off-disabled-bold: "\e3a3";
$bosch-ic-tractor: "\e3a4";
$bosch-ic-video-recorder-digital: "\e3a5";
$bosch-ic-windshield-wiper: "\e3a6";
$bosch-ic-weather-snow-thunder: "\e3a7";
$bosch-ic-electricity-pylon: "\e3a8";
$bosch-ic-box-questionmark: "\e3a9";
$bosch-ic-rainbow: "\e3aa";
$bosch-ic-medal: "\e3ab";
$bosch-ic-wheel: "\e3ac";
$bosch-ic-borescope-connected: "\e3ad";
$bosch-ic-filter-delete: "\e3ae";
$bosch-ic-filter-add: "\e3af";
$bosch-ic-man: "\e3b0";
$bosch-ic-woman: "\e3b1";
$bosch-ic-fridge: "\e3b2";
$bosch-ic-battery-car: "\e3b3";
$bosch-ic-bag-escalator: "\e3b4";
$bosch-ic-shopping-bags: "\e3b5";
$bosch-ic-car-steering-left: "\e3b6";
$bosch-ic-car-steering-right: "\e3b7";
$bosch-ic-thumb-up-down: "\e3b8";
$bosch-ic-user-run-detection: "\e3b9";
$bosch-ic-bank: "\e3ba";
$bosch-ic-transportation: "\e3bb";
$bosch-ic-government: "\e3bc";
$bosch-ic-user-music: "\e3bd";
$bosch-ic-bus: "\e3be";
$bosch-ic-car-euro: "\e3bf";
$bosch-ic-bag: "\e3c0";
$bosch-ic-plate-tetrapack: "\e3c1";
$bosch-ic-hospital: "\e3c2";
$bosch-ic-desktop-structure: "\e3c3";
$bosch-ic-frog-head: "\e3c4";
$bosch-ic-sportboat: "\e3c5";
$bosch-ic-yacht: "\e3c6";
$bosch-ic-leaf-tilia: "\e3c7";
$bosch-ic-music: "\e3c8";
$bosch-ic-oil-lamp: "\e3c9";
$bosch-ic-windshield-wiper-rear: "\e3ca";
$bosch-ic-dongle-connected: "\e3cb";
$bosch-ic-slate: "\e3cc";
$bosch-ic-appliance-heat-pump-exhaust: "\e3cd";
$bosch-ic-alert-list: "\e3ce";
$bosch-ic-arrow-left-end-frame: "\e3cf";
$bosch-ic-arrow-right-end-frame: "\e3d0";
$bosch-ic-shape-ellipse: "\e3d1";
$bosch-ic-shape-rectangle: "\e3d2";
$bosch-ic-wishlist-add: "\e3d3";
$bosch-ic-smartphone-disabled-light: "\e3d4";
$bosch-ic-airbag: "\e3d5";
$bosch-ic-compass: "\e3d6";
$bosch-ic-turbine-wind: "\e3d7";
$bosch-ic-arrows-diagonal-disabled-light: "\e3d8";
$bosch-ic-ear-off-disabled-bold: "\e3d9";
$bosch-ic-fan-off-disabled-light: "\e3da";
$bosch-ic-faucet-off-disabled-light: "\e3db";
$bosch-ic-hand-motion-off-disabled-bold: "\e3dc";
$bosch-ic-heating-floor-off-disabled-light: "\e3dd";
$bosch-ic-heating-radiator-off-disabled-light: "\e3de";
$bosch-ic-ice-off-disabled-light: "\e3df";
$bosch-ic-smartphone-disabled-bold: "\e3e0";
$bosch-ic-subtitles-off-bold: "\e3e1";
$bosch-ic-sun-off-disabled-light: "\e3e2";
$bosch-ic-video-disabled-bold: "\e3e3";
$bosch-ic-volume-off-bold: "\e3e4";
$bosch-ic-watch-off-disabled-bold: "\e3e5";
$bosch-ic-water-shower-off-disabled-light: "\e3e6";
$bosch-ic-3d-frame-play: "\e3e7";
$bosch-ic-alert-error-checkmark: "\e3e8";
$bosch-ic-arrow-up-frame-check: "\e3e9";
$bosch-ic-arrow-up-frame-error: "\e3ea";
$bosch-ic-arrows-checkmark: "\e3eb";
$bosch-ic-assembly-line-settings: "\e3ec";
$bosch-ic-augmented-reality-player: "\e3ed";
$bosch-ic-badge-checkmark: "\e3ee";
$bosch-ic-bar-chart-kappa: "\e3ef";
$bosch-ic-bar-chart-search-detail: "\e3f0";
$bosch-ic-barcode-checkmark: "\e3f1";
$bosch-ic-barcode-scan-bulb: "\e3f2";
$bosch-ic-barcode: "\e3f3";
$bosch-ic-binary: "\e3f4";
$bosch-ic-book-lightbulb: "\e3f5";
$bosch-ic-box-arrow-down: "\e3f6";
$bosch-ic-box-delivery-checkmark: "\e3f7";
$bosch-ic-globe-checkmark: "\e3f8";
$bosch-ic-calendar-boxes: "\e3f9";
$bosch-ic-calendar-service: "\e3fa";
$bosch-ic-camera-processor: "\e3fb";
$bosch-ic-camera-settings: "\e3fc";
$bosch-ic-chart-line-checkmark: "\e3fd";
$bosch-ic-checklist-stack: "\e3fe";
$bosch-ic-checkmark-frame-minimum: "\e3ff";
$bosch-ic-checkmark-frame-tripple: "\e400";
$bosch-ic-clipboard-checkmark: "\e401";
$bosch-ic-clipboard-list-parts: "\e402";
$bosch-ic-clipboard-settings: "\e403";
$bosch-ic-computer-tower-box: "\e404";
$bosch-ic-connectivity-lock: "\e405";
$bosch-ic-counter-current: "\e406";
$bosch-ic-counter-target: "\e407";
$bosch-ic-cube-checkmark: "\e408";
$bosch-ic-cube-play: "\e409";
$bosch-ic-timeline-settings: "\e40a";
$bosch-ic-timeline-view: "\e40b";
$bosch-ic-timeline: "\e40c";
$bosch-ic-delivery-checkmark: "\e40d";
$bosch-ic-desktop-application-3d-play: "\e40e";
$bosch-ic-desktop-apps-download: "\e40f";
$bosch-ic-desktop-dashboard: "\e410";
$bosch-ic-desktop-graph-checkmark: "\e411";
$bosch-ic-desktop-graph-search: "\e412";
$bosch-ic-desktop-graph: "\e413";
$bosch-ic-development-kit: "\e414";
$bosch-ic-directory-cloud-settings: "\e415";
$bosch-ic-directory-cloud: "\e416";
$bosch-ic-directory-settings: "\e417";
$bosch-ic-document-clock-cycle: "\e418";
$bosch-ic-document-code-stack: "\e419";
$bosch-ic-document-dat-edit: "\e41a";
$bosch-ic-document-ohd-arrow-down: "\e41b";
$bosch-ic-dolphin: "\e41c";
$bosch-ic-energy-management: "\e41d";
$bosch-ic-eraser: "\e41e";
$bosch-ic-folder-broom: "\e41f";
$bosch-ic-folder-check-broom: "\e420";
$bosch-ic-folder-checkmark: "\e421";
$bosch-ic-folder-oes-check: "\e422";
$bosch-ic-folder-open-service: "\e423";
$bosch-ic-folder-reload: "\e424";
$bosch-ic-hand-command: "\e425";
$bosch-ic-heating-start: "\e426";
$bosch-ic-hierarchy-refresh: "\e427";
$bosch-ic-hierarchy-search: "\e428";
$bosch-ic-hierarchy-settings: "\e429";
$bosch-ic-hierarchy: "\e42a";
$bosch-ic-hmi-desktop-settings: "\e42b";
$bosch-ic-human-machine-interface: "\e42c";
$bosch-ic-keys-user-access: "\e42d";
$bosch-ic-keys: "\e42e";
$bosch-ic-label-edit: "\e42f";
$bosch-ic-line-arrow-checkmark: "\e430";
$bosch-ic-link-checkmark: "\e431";
$bosch-ic-lock-closed-checkmark: "\e432";
$bosch-ic-lock-open-checkmark: "\e433";
$bosch-ic-material-search: "\e434";
$bosch-ic-objects-search: "\e435";
$bosch-ic-objects: "\e436";
$bosch-ic-plan-a-to-b: "\e437";
$bosch-ic-plan-chart: "\e438";
$bosch-ic-print-send: "\e439";
$bosch-ic-refresh-analysis: "\e43a";
$bosch-ic-remote-desktop-add: "\e43b";
$bosch-ic-rfid-checkmark: "\e43c";
$bosch-ic-rfid-tag: "\e43d";
$bosch-ic-sequence-checkmark: "\e43e";
$bosch-ic-server-arrow-up: "\e43f";
$bosch-ic-server-arrows: "\e440";
$bosch-ic-server-settings: "\e441";
$bosch-ic-server-share: "\e442";
$bosch-ic-settings-editor: "\e443";
$bosch-ic-survey-checkmark: "\e444";
$bosch-ic-target-dart: "\e445";
$bosch-ic-target-view: "\e446";
$bosch-ic-target: "\e447";
$bosch-ic-thickness-selection: "\e448";
$bosch-ic-toolbox-checkmark: "\e449";
$bosch-ic-toolbox: "\e44a";
$bosch-ic-translate: "\e44b";
$bosch-ic-tune-vertical-checkmark: "\e44c";
$bosch-ic-wand-user-add: "\e44d";
$bosch-ic-wand-user-settings: "\e44e";
$bosch-ic-wand-user: "\e44f";
$bosch-ic-window-globe: "\e450";
$bosch-ic-wrench-change: "\e451";
$bosch-ic-clock-24-7: "\e452";
$bosch-ic-eagle: "\e453";
$bosch-ic-fluorescent-light: "\e454";
$bosch-ic-home-cloud-0: "\e455";
$bosch-ic-home-cloud-1: "\e456";
$bosch-ic-home-cloud-2: "\e457";
$bosch-ic-home-cloud-3: "\e458";
$bosch-ic-home-shadow: "\e459";
$bosch-ic-motorcycle-sport: "\e45a";
$bosch-ic-smartphone-connected: "\e45b";
$bosch-ic-usb-stick: "\e45c";
$bosch-ic-user-setting: "\e45d";
$bosch-ic-timeline-clock: "\e45e";
$bosch-ic-alarm-fire: "\e45f";
$bosch-ic-emergency-front: "\e460";
$bosch-ic-transporter-front: "\e461";
$bosch-ic-truck-front: "\e462";
$bosch-ic-unpin-classic-disabled-light: "\e463";
$bosch-ic-unpin-modern-disabled-light: "\e464";
$bosch-ic-smartphone-ban: "\e465";
$bosch-ic-helmet: "\e466";
$bosch-ic-gate-closed: "\e467";
$bosch-ic-fence: "\e468";
$bosch-ic-desktop-apps: "\e469";
$bosch-ic-candle: "\e46a";
$bosch-ic-chart-oee: "\e46b";
$bosch-ic-cycletime-eighth: "\e46c";
$bosch-ic-cycletime-quarter: "\e46d";
$bosch-ic-gateway: "\e46e";
$bosch-ic-heatmap: "\e46f";
$bosch-ic-laptop-checklist: "\e470";
$bosch-ic-molecule: "\e471";
$bosch-ic-robot-settings: "\e472";
$bosch-ic-sensor-cable: "\e473";
$bosch-ic-tank-layers: "\e474";
$bosch-ic-window-settings: "\e475";
$bosch-ic-heatmap-independent: "\e476";
$bosch-ic-dumble: "\e477";
$bosch-ic-truck-wash: "\e478";
$bosch-ic-smart-sensor: "\e479";
$bosch-ic-audio-add: "\e47a";
$bosch-ic-audio-checked: "\e47b";
$bosch-ic-audio-play: "\e47c";
$bosch-ic-audio-remove: "\e47d";
$bosch-ic-image-gallery-checked: "\e47e";
$bosch-ic-imagery-checked: "\e47f";
$bosch-ic-image-gallery-remove: "\e480";
$bosch-ic-recording: "\e481";
$bosch-ic-stop-frame: "\e482";
$bosch-ic-roof: "\e483";
$bosch-ic-projection-interactive: "\e484";
$bosch-ic-bridge: "\e485";
$bosch-ic-box-arrows: "\e486";
$bosch-ic-chart-line-fit: "\e487";
$bosch-ic-chart-line-compress: "\e488";
$bosch-ic-ciss: "\e489";
$bosch-ic-scd: "\e48a";
$bosch-ic-vci: "\e48b";
$bosch-ic-access-controller: "\e48c";
$bosch-ic-card-reader: "\e48d";
$bosch-ic-table: "\e48e";
$bosch-ic-replace: "\e48f";
$bosch-ic-chart-topflop-organized-horizontal: "\e490";
$bosch-ic-chart-topflop-random-horizontal: "\e491";
$bosch-ic-chart-topflop-organized-vertical: "\e492";
$bosch-ic-chart-topflop-random-vertical: "\e493";
$bosch-ic-ois: "\e494";
$bosch-ic-cloud-refresh-locked: "\e495";
$bosch-ic-car-bluetooth: "\e496";
$bosch-ic-camera-trainer: "\e497";
$bosch-ic-magnifier-moving: "\e498";
$bosch-ic-absolute-orientation: "\e499";
$bosch-ic-accelerometer: "\e49a";
$bosch-ic-imu: "\e49b";
$bosch-ic-gyroscope: "\e49c";
$bosch-ic-magneotmeter: "\e49d";
$bosch-ic-battery-car-1: "\e49e";
$bosch-ic-battery-car-2: "\e49f";
$bosch-ic-battery-car-3: "\e4a0";
$bosch-ic-battery-car-charging: "\e4a1";
$bosch-ic-battery-car-defective: "\e4a2";
$bosch-ic-truck-crane: "\e4a3";
$bosch-ic-train-diesel: "\e4a4";
$bosch-ic-snowmobile: "\e4a5";
$bosch-ic-riding-mower: "\e4a6";
$bosch-ic-motorcycle-small: "\e4a7";
$bosch-ic-mining-truck: "\e4a8";
$bosch-ic-lawnmower: "\e4a9";
$bosch-ic-jetski: "\e4aa";
$bosch-ic-golf-caddy: "\e4ab";
$bosch-ic-generator-electric: "\e4ac";
$bosch-ic-garbage-truck: "\e4ad";
$bosch-ic-fork-lift-big: "\e4ae";
$bosch-ic-engine-small: "\e4af";
$bosch-ic-engine-big: "\e4b0";
$bosch-ic-coach: "\e4b1";
$bosch-ic-chainsaw: "\e4b2";
$bosch-ic-car-van: "\e4b3";
$bosch-ic-car-suv: "\e4b4";
$bosch-ic-car-race: "\e4b5";
$bosch-ic-car-old: "\e4b6";
$bosch-ic-car-camping-van: "\e4b7";
$bosch-ic-car-camper: "\e4b8";
$bosch-ic-bus-side: "\e4b9";
$bosch-ic-book-info: "\e4ba";
$bosch-ic-gears: "\e4bb";
$bosch-ic-shark: "\e4bc";
$bosch-ic-mountaintop: "\e4bd";
$bosch-ic-baby: "\e4be";
$bosch-ic-coffee-bean: "\e4bf";
$bosch-ic-coffee-beans: "\e4c0";
$bosch-ic-skull: "\e4c1";
$bosch-ic-stroller: "\e4c2";
$bosch-ic-glasses: "\e4c3";
$bosch-ic-detergent: "\e4c4";
$bosch-ic-washing-machine: "\e4c5";
$bosch-ic-sdcard: "\e4c6";
$bosch-ic-water-connectivity: "\e4c7";
$bosch-ic-server-connectivity: "\e4c8";
$bosch-ic-presentation-speaker: "\e4c9";
$bosch-ic-chart-bar-arrow: "\e4ca";
$bosch-ic-book-connectivity: "\e4cb";
$bosch-ic-route-focus: "\e4cc";
$bosch-ic-bicycle-helmet: "\e4cd";
$bosch-ic-cookie: "\e4ce";
$bosch-ic-glass-wine: "\e4cf";
$bosch-ic-cup-small: "\e4d0";
$bosch-ic-cup-large: "\e4d1";
$bosch-ic-bowl: "\e4d2";
$bosch-ic-glass-small: "\e4d3";
$bosch-ic-ice-bottle: "\e4d4";
$bosch-ic-cup-plate-drop: "\e4d5";
$bosch-ic-plate: "\e4d6";
$bosch-ic-cup-plate: "\e4d7";
$bosch-ic-bubbles: "\e4d8";
$bosch-ic-button-start-stop: "\e4d9";
$bosch-ic-elevator-freight-max: "\e4da";
$bosch-ic-elevator-freight: "\e4db";
$bosch-ic-elevator-persons: "\e4dc";
$bosch-ic-embed: "\e4dd";
$bosch-ic-female-male: "\e4de";
$bosch-ic-fork-knife-spoon: "\e4df";
$bosch-ic-global-entrepreneur: "\e4e0";
$bosch-ic-arrows-left-right-checkmark: "\e4e1";
$bosch-ic-automatic-mode: "\e4e2";
$bosch-ic-babybottle: "\e4e3";
$bosch-ic-barcode-scan: "\e4e4";
$bosch-ic-barcode-scanner: "\e4e5";
$bosch-ic-cash-frame-rupee: "\e4e6";
$bosch-ic-chart-heat-curve: "\e4e7";
$bosch-ic-compressor: "\e4e8";
$bosch-ic-cubes: "\e4e9";
$bosch-ic-highway: "\e4ea";
$bosch-ic-industry-workers: "\e4eb";
$bosch-ic-industry-workers-connected: "\e4ec";
$bosch-ic-machine-station-control: "\e4ed";
$bosch-ic-map-city: "\e4ee";
$bosch-ic-ordernumber: "\e4ef";
$bosch-ic-shirt-business: "\e4f0";
$bosch-ic-shirt-casual: "\e4f1";
$bosch-ic-suitcase: "\e4f2";
$bosch-ic-weather-cloud-sun-sensor: "\e4f3";
$bosch-ic-window-code: "\e4f4";
$bosch-ic-wrench-warning: "\e4f5";
$bosch-ic-arrow-circle-a: "\e4f6";
$bosch-ic-arrows-agility: "\e4f7";
$bosch-ic-arrows-collapse-object: "\e4f8";
$bosch-ic-arrows-expand-object: "\e4f9";
$bosch-ic-arrows-left-right: "\e4fa";
$bosch-ic-fan-heat: "\e4fb";
$bosch-ic-battery-car-arrows-circle: "\e4fc";
$bosch-ic-battery-car-clock: "\e4fd";
$bosch-ic-battery-vibration: "\e4fe";
$bosch-ic-belt-elastic: "\e4ff";
$bosch-ic-bicycle-e-arrow-away: "\e500";
$bosch-ic-bicycle-e-arrow-towards: "\e501";
$bosch-ic-brake-decoupled-power: "\e502";
$bosch-ic-brake-i-booster: "\e503";
$bosch-ic-brake-system-esp: "\e504";
$bosch-ic-brake-system-integrated: "\e505";
$bosch-ic-brake-vacuum-booster: "\e506";
$bosch-ic-capacity: "\e507";
$bosch-ic-car-beam-length: "\e508";
$bosch-ic-car-smartphone-locator: "\e509";
$bosch-ic-chart-pie: "\e50a";
$bosch-ic-chess-jumper: "\e50b";
$bosch-ic-clock-time-off: "\e50c";
$bosch-ic-clock-time-on: "\e50d";
$bosch-ic-coin: "\e50e";
$bosch-ic-connection-off-settings: "\e50f";
$bosch-ic-connection-on-settings: "\e510";
$bosch-ic-control-unit: "\e511";
$bosch-ic-customer: "\e512";
$bosch-ic-engine: "\e513";
$bosch-ic-filter-air: "\e514";
$bosch-ic-filter-cabin-carbon: "\e515";
$bosch-ic-filter-cabin: "\e516";
$bosch-ic-filter-cooling-water: "\e517";
$bosch-ic-filter-denox-cover: "\e518";
$bosch-ic-filter-denox: "\e519";
$bosch-ic-filter-element-compensation-sealing: "\e51a";
$bosch-ic-filter-element-compensation: "\e51b";
$bosch-ic-filter-element: "\e51c";
$bosch-ic-filter-oil: "\e51d";
$bosch-ic-filter-petrol: "\e51e";
$bosch-ic-filter-prefilter: "\e51f";
$bosch-ic-fireplace: "\e520";
$bosch-ic-flash-plus: "\e521";
$bosch-ic-globe-worldwideweb: "\e522";
$bosch-ic-glow-control-unit: "\e523";
$bosch-ic-glow-plug: "\e524";
$bosch-ic-hand-cloth: "\e525";
$bosch-ic-leaf-plus: "\e526";
$bosch-ic-machine-warning: "\e527";
$bosch-ic-measuring-jug: "\e528";
$bosch-ic-plug-play: "\e529";
$bosch-ic-refresh-checkmark: "\e52a";
$bosch-ic-replacement-filament: "\e52b";
$bosch-ic-screw: "\e52c";
$bosch-ic-seat-belt: "\e52d";
$bosch-ic-steering-wheel-hands-automated: "\e52e";
$bosch-ic-sun-ice-parking: "\e52f";
$bosch-ic-syringe-change-needle: "\e530";
$bosch-ic-syringe-change: "\e531";
$bosch-ic-syringe: "\e532";
$bosch-ic-thread-diameter: "\e533";
$bosch-ic-tool-extraction: "\e534";
$bosch-ic-transporter-car-front: "\e535";
$bosch-ic-truck-car-motorcycle: "\e536";
$bosch-ic-truck-car: "\e537";
$bosch-ic-truck-logistics-box: "\e538";
$bosch-ic-truck-transporter: "\e539";
$bosch-ic-watch-on-warning: "\e53a";
$bosch-ic-wrench-calibrate: "\e53b";
$bosch-ic-wrench-dummy: "\e53c";
$bosch-ic-wrench-tightening-torque: "\e53d";
$bosch-ic-wrench-width-across-flats: "\e53e";
$bosch-ic-user-arm-bandage: "\e53f";
$bosch-ic-user-eye-blind: "\e540";
$bosch-ic-user-ear-deaf: "\e541";
$bosch-ic-user-car-distracted: "\e542";
$bosch-ic-user-mouth-mute: "\e543";
$bosch-ic-user-ear-blocked: "\e544";
$bosch-ic-user-bavarian: "\e545";
$bosch-ic-user-ear-loud: "\e546";
$bosch-ic-user-walk-crutch: "\e547";
$bosch-ic-user-mouth-speechless: "\e548";
$bosch-ic-user-arm-baby: "\e549";
$bosch-ic-user-arm-missing: "\e54a";
$bosch-ic-user-eye-eyepatch: "\e54b";
$bosch-ic-user-strapped: "\e54c";
$bosch-ic-cleaning-agent: "\e54d";
$bosch-ic-cup-plate-dirty: "\e54e";
$bosch-ic-cup-plate-private: "\e54f";
$bosch-ic-rubbish-apple: "\e550";
$bosch-ic-rubbish-fish: "\e551";
$bosch-ic-rubbish-organic-bin: "\e552";
$bosch-ic-rubbish-paper: "\e553";
$bosch-ic-rubbish-paper-bin: "\e554";
$bosch-ic-rubbish-residual-bin: "\e555";
$bosch-ic-shower: "\e556";
$bosch-ic-shower-female: "\e557";
$bosch-ic-shower-male: "\e558";
$bosch-ic-wardrobe-female: "\e559";
$bosch-ic-wardrobe-male: "\e55a";
$bosch-ic-car-top: "\e55b";
$bosch-ic-label-info: "\e55c";
$bosch-ic-perspective-view: "\e55d";
$bosch-ic-road: "\e55e";
$bosch-ic-screen-split-horizontal: "\e55f";
$bosch-ic-screen-split-vertical: "\e560";
$bosch-ic-screenshot-frame: "\e561";
$bosch-ic-sensor-lidar: "\e562";
$bosch-ic-tram: "\e563";
$bosch-ic-video-360-degrees: "\e564";
$bosch-ic-belt-groove-drives: "\e565";
$bosch-ic-bottle-champagne: "\e566";
$bosch-ic-box-falling: "\e567";
$bosch-ic-box-falling-off-disabled-bold: "\e568";
$bosch-ic-box-falling-off-disabled-light: "\e569";
$bosch-ic-brake-pedal: "\e56a";
$bosch-ic-car-front-light-inside: "\e56b";
$bosch-ic-car-licence-plate-light: "\e56c";
$bosch-ic-car-side-light-back: "\e56d";
$bosch-ic-car-side-light-front: "\e56e";
$bosch-ic-light-fog-warning: "\e56f";
$bosch-ic-prism: "\e570";
$bosch-ic-turnstile: "\e571";
$bosch-ic-turnstile-open-in-and-outbound: "\e572";
$bosch-ic-turnstile-open-inbound: "\e573";
$bosch-ic-turnstile-open-outbound: "\e574";
$bosch-ic-uwb: "\e575";
$bosch-ic-heat-reduce-active: "\e576";
$bosch-ic-heat-reduce-passive: "\e577";
$bosch-ic-hourglass-add: "\e578";
$bosch-ic-light-fog: "\e579";
$bosch-ic-car-turn-signal: "\e57a";
$bosch-ic-car-turn-signal-left: "\e57b";
$bosch-ic-car-turn-signal-right: "\e57c";
$bosch-ic-light-rear: "\e57d";
$bosch-ic-car-top-turn-signal-front: "\e57e";
$bosch-ic-car-top-turn-signal-back: "\e57f";
$bosch-ic-ccu-cm: "\e580";
$bosch-ic-lung: "\e581";
$bosch-ic-head-speaking-voice: "\e582";
$bosch-ic-car-motorcycle: "\e583";
$bosch-ic-car-turn-signal-side: "\e584";
$bosch-ic-car-light-parking: "\e585";
$bosch-ic-phoenix: "\e586";
$bosch-ic-sensor-air-quality: "\e587";
$bosch-ic-weather-station: "\e588";
$bosch-ic-watering-can: "\e589";
$bosch-ic-propeller: "\e58a";
$bosch-ic-temperature-fluctuating: "\e58b";
$bosch-ic-stove-top: "\e58c";
$bosch-ic-socket: "\e58d";
$bosch-ic-siren: "\e58e";
$bosch-ic-sensor-water: "\e58f";
$bosch-ic-user-run-sensor-motion: "\e590";
$bosch-ic-robot-lawnmower: "\e591";
$bosch-ic-robot-cleaning: "\e592";
$bosch-ic-oven: "\e593";
$bosch-ic-loudspeaker: "\e594";
$bosch-ic-keypad: "\e595";
$bosch-ic-shape-circle-square: "\e596";
$bosch-ic-garage: "\e597";
$bosch-ic-fume-hood: "\e598";
$bosch-ic-freezer: "\e599";
$bosch-ic-food-processor: "\e59a";
$bosch-ic-floorplan-rooms: "\e59b";
$bosch-ic-dryer-tumble: "\e59c";
$bosch-ic-dryer: "\e59d";
$bosch-ic-door-window: "\e59e";
$bosch-ic-door-lock: "\e59f";
$bosch-ic-door-bell: "\e5a0";
$bosch-ic-dishwasher: "\e5a1";
$bosch-ic-dimmer: "\e5a2";
$bosch-ic-detector-smoke: "\e5a3";
$bosch-ic-detector-presence: "\e5a4";
$bosch-ic-cooking: "\e5a5";
$bosch-ic-coffee-maschine: "\e5a6";
$bosch-ic-camera-indoor: "\e5a7";
$bosch-ic-broom-clean: "\e5a8";
$bosch-ic-blinds: "\e5a9";
$bosch-ic-bicycle-e-flash: "\e5aa";
$bosch-ic-finger-domino-trigger: "\e5ab";
$bosch-ic-automation-start: "\e5ac";
$bosch-ic-automation: "\e5ad";
$bosch-ic-sensor-multifunctional: "\e5ae";
$bosch-ic-plane-top: "\e5af";
$bosch-ic-shape-square-overlapping: "\e5b0";
$bosch-ic-square-overlapping: "\e5b1";
$bosch-ic-battery-car-14v: "\e5b2";
$bosch-ic-calibration: "\e5b3";
$bosch-ic-structure-can-bus: "\e5b4";
$bosch-ic-gears-direction-arrows: "\e5b5";
$bosch-ic-car-limousine: "\e5b6";
$bosch-ic-molecule-gas: "\e5b7";
$bosch-ic-money-euro: "\e5b8";
$bosch-ic-revolution-rpm: "\e5b9";
$bosch-ic-rikscha-e: "\e5ba";
$bosch-ic-sensor-circle: "\e5bb";
$bosch-ic-speedometer-high: "\e5bc";
$bosch-ic-steering-wheel-arrows: "\e5bd";
$bosch-ic-tablet-checklist: "\e5be";
$bosch-ic-petrol-charging-station-hybrid: "\e5bf";
$bosch-ic-weather-cloud-sun-rain-snow: "\e5c0";
$bosch-ic-light-half: "\e5c1";
$bosch-ic-video-sensor: "\e5c2";
$bosch-ic-binary-desktop: "\e5c3";
$bosch-ic-truck-delivery: "\e5c4";
$bosch-ic-van-delivery: "\e5c5";
$bosch-ic-fullscreen-user: "\e5c6";
$bosch-ic-user-helmet-motorcycle: "\e5c7";
$bosch-ic-user-hand-disabled-light: "\e5c8";
$bosch-ic-user-hand-disabled-bold: "\e5c9";
$bosch-ic-link-connected: "\e5ca";
$bosch-ic-gate-open: "\e5cb";
$bosch-ic-box-checkmark: "\e5cc";
$bosch-ic-box-place: "\e5cd";
$bosch-ic-logistics-dock: "\e5ce";
$bosch-ic-barcode-scan-logistics: "\e5cf";
$bosch-ic-locator-tracking: "\e5d0";
$bosch-ic-car-light-trunk: "\e5d1";
$bosch-ic-car-light-glove-compartment: "\e5d2";
$bosch-ic-car-light-dashboard: "\e5d3";
$bosch-ic-fire-leaf: "\e5d4";
$bosch-ic-laptop-hacker: "\e5d5";
$bosch-ic-hands-drops: "\e5d6";
$bosch-ic-team-disabled-bold: "\e5d7";
$bosch-ic-team-disabled-light: "\e5d8";
$bosch-ic-user-head-arm: "\e5d9";
$bosch-ic-user-head-hand-disabled-bold: "\e5da";
$bosch-ic-user-head-hand-disabled-light: "\e5db";
$bosch-ic-welcome-disabled-bold: "\e5dc";
$bosch-ic-welcome-disabled-light: "\e5dd";
$bosch-ic-female-male-arrows: "\e5de";
$bosch-ic-broccoli: "\e5df";
$bosch-ic-chicken: "\e5e0";
$bosch-ic-cow: "\e5e1";
$bosch-ic-deer: "\e5e2";
$bosch-ic-fish: "\e5e3";
$bosch-ic-garlic: "\e5e4";
$bosch-ic-hotpepper: "\e5e5";
$bosch-ic-pig: "\e5e6";
$bosch-ic-rabbit: "\e5e7";
$bosch-ic-sheep: "\e5e8";
$bosch-ic-shell: "\e5e9";
$bosch-ic-shrimp: "\e5ea";
$bosch-ic-car-seat: "\e5eb";
$bosch-ic-car-seat-steering-wheel: "\e5ec";
$bosch-ic-car-side-arrow-back: "\e5ed";
$bosch-ic-car-side-arrow-front: "\e5ee";
$bosch-ic-car-steering-left-disabled-bold: "\e5ef";
$bosch-ic-car-steering-left-disabled-light: "\e5f0";
$bosch-ic-car-steering-left-lle: "\e5f1";
$bosch-ic-car-steering-left-lle-disabled-bold: "\e5f2";
$bosch-ic-car-steering-left-lle-disabled-light: "\e5f3";
$bosch-ic-car-steering-right-disabled-bold: "\e5f4";
$bosch-ic-car-steering-right-disabled-light: "\e5f5";
$bosch-ic-car-steering-right-rle: "\e5f6";
$bosch-ic-car-steering-right-rle-disabled-bold: "\e5f7";
$bosch-ic-car-steering-right-rle-disabled-light: "\e5f8";
$bosch-ic-cars-traffic-left-right: "\e5f9";
$bosch-ic-configuration-wrench: "\e5fa";
$bosch-ic-hook: "\e5fb";
$bosch-ic-hook-arrows: "\e5fc";
$bosch-ic-hook-arrows-wiperblade: "\e5fd";
$bosch-ic-hook-disabled-bold: "\e5fe";
$bosch-ic-hook-disabled-light: "\e5ff";
$bosch-ic-info-i-frame-add: "\e600";
$bosch-ic-search-number: "\e601";
$bosch-ic-search-number-add: "\e602";
$bosch-ic-set: "\e603";
$bosch-ic-speedometer-speed-high: "\e604";
$bosch-ic-windshield-wiper-clean: "\e605";
$bosch-ic-windshield-wiper-double: "\e606";
$bosch-ic-wiper-adapter: "\e607";
$bosch-ic-wiper-blade: "\e608";
$bosch-ic-wiper-blade-arrow: "\e609";
$bosch-ic-wiper-blade-eco: "\e60a";
$bosch-ic-wiper-blade-mm: "\e60b";
$bosch-ic-wiper-blade-mm-arrows: "\e60c";
$bosch-ic-wiper-blade-spoiler: "\e60d";
$bosch-ic-wiper-blade-twin: "\e60e";
$bosch-ic-wiper-flatblade: "\e60f";
$bosch-ic-wiper-flatblade-aeroeco: "\e610";
$bosch-ic-wiper-flatblade-aerofit: "\e611";
$bosch-ic-wiper-flatblade-aerotwin: "\e612";
$bosch-ic-wiper-flatblade-arrow: "\e613";
$bosch-ic-wiper-flatblade-mm: "\e614";
$bosch-ic-wiper-flatblade-mm-arrows: "\e615";
$bosch-ic-wiper-rubber: "\e616";
$bosch-ic-address-control-system: "\e617";
$bosch-ic-balcony: "\e618";
$bosch-ic-battery-car-45-degree-arrow: "\e619";
$bosch-ic-boiler-warning: "\e61a";
$bosch-ic-book-wrench: "\e61b";
$bosch-ic-car-driver-passenger-arrows: "\e61c";
$bosch-ic-car-licence-plate: "\e61d";
$bosch-ic-car-side-arrow-back-tires: "\e61e";
$bosch-ic-car-side-arrow-front-tires: "\e61f";
$bosch-ic-car-side-half-arrow-back: "\e620";
$bosch-ic-car-steering-left-arrow: "\e621";
$bosch-ic-car-steering-left-passenger-arrow: "\e622";
$bosch-ic-car-steering-right-arrow: "\e623";
$bosch-ic-car-steering-right-passenger-arrow: "\e624";
$bosch-ic-chess-board-transparency: "\e625";
$bosch-ic-cloud-locked: "\e626";
$bosch-ic-conference-system: "\e627";
$bosch-ic-cycletime-quarter-arrows-interval: "\e628";
$bosch-ic-desktop-connectivity: "\e629";
$bosch-ic-hierarchy-checkmark: "\e62a";
$bosch-ic-home-office: "\e62b";
$bosch-ic-home-semi-detached-house: "\e62c";
$bosch-ic-kitchen: "\e62d";
$bosch-ic-lambda-arrows-length: "\e62e";
$bosch-ic-lambda-oxygen: "\e62f";
$bosch-ic-lambda-sensor-cores: "\e630";
$bosch-ic-panel-control: "\e631";
$bosch-ic-prison: "\e632";
$bosch-ic-prison-cell: "\e633";
$bosch-ic-radio-broadcasting: "\e634";
$bosch-ic-robot-body: "\e635";
$bosch-ic-robot-body-arrow: "\e636";
$bosch-ic-skyscraper-danger: "\e637";
$bosch-ic-skyscraper-flat: "\e638";
$bosch-ic-skyscraper-flat-floor-low: "\e639";
$bosch-ic-skyscraper-flat-floor-middle: "\e63a";
$bosch-ic-skyscraper-flat-floor-top: "\e63b";
$bosch-ic-skyscraper-flat-semi-detached: "\e63c";
$bosch-ic-skyscraper-locked: "\e63d";
$bosch-ic-skyscraper-settings: "\e63e";
$bosch-ic-team-signpost: "\e63f";
$bosch-ic-toaster-hand-warning: "\e640";
$bosch-ic-user-arrows-heart: "\e641";
$bosch-ic-user-graduate-chat: "\e642";
$bosch-ic-user-head-mask: "\e643";
$bosch-ic-warehouse: "\e644";
$bosch-ic-world-paperplane: "\e645";
$bosch-ic-aperture: "\e646";
$bosch-ic-hook-wiperblade-arrows: "\e647";
$bosch-ic-image-remove: "\e648";
$bosch-ic-image-checked: "\e649";
$bosch-ic-apartment-in-house-terraced: "\e64b";
$bosch-ic-apartment-in-house: "\e64c";
$bosch-ic-appliance-heat-pump-leaf-add: "\e64d";
$bosch-ic-appliance-heat-pump-leaf-reload: "\e64e";
$bosch-ic-appliance-heat-pump-oil-refresh: "\e64f";
$bosch-ic-appliance-water-drop: "\e650";
$bosch-ic-appliance-water-sun: "\e651";
$bosch-ic-brake-pedal-thumb-up: "\e652";
$bosch-ic-call-plus: "\e653";
$bosch-ic-battery-car-3-plug: "\e654";
$bosch-ic-cloud-share: "\e655";
$bosch-ic-crankshaft: "\e656";
$bosch-ic-detector-motion: "\e657";
$bosch-ic-device-analog: "\e658";
$bosch-ic-device-digital: "\e659";
$bosch-ic-externallink-landscape: "\e65a";
$bosch-ic-gears-interlocked-arrow: "\e65b";
$bosch-ic-gearshift-reverse-gear: "\e65c";
$bosch-ic-glove: "\e65d";
$bosch-ic-hand-warranty: "\e65e";
$bosch-ic-heating-floor-radiator: "\e65f";
$bosch-ic-home-appliance-heat-pump-air-in: "\e660";
$bosch-ic-home-appliance-heat-pump-air-out: "\e661";
$bosch-ic-lightbulb-gear: "\e662";
$bosch-ic-line-open-closed: "\e663";
$bosch-ic-road-disabled-bold: "\e664";
$bosch-ic-road-disabled-light: "\e665";
$bosch-ic-shower-drop-1: "\e666";
$bosch-ic-shower-drop-2: "\e667";
$bosch-ic-shower-drop-3: "\e668";
$bosch-ic-shower-drop-4: "\e669";
$bosch-ic-sinus-curve: "\e66a";
$bosch-ic-team-3: "\e66b";
$bosch-ic-team-4: "\e66c";
$bosch-ic-team-5: "\e66d";
$bosch-ic-test-tube: "\e66e";
$bosch-ic-truck-car-motorcycle-arrows: "\e66f";
$bosch-ic-weather-cloud-dust: "\e670";
$bosch-ic-windshield-wiper-double-parallel-left: "\e671";
$bosch-ic-windshield-wiper-double-parallel-right: "\e672";
$bosch-ic-world-pin: "\e673";
$bosch-ic-sign-warning-slope: "\e674";
$bosch-ic-bond-laser: "\e675";
$bosch-ic-bond-ribbon-thick: "\e676";
$bosch-ic-bond-ribbon-thin: "\e677";
$bosch-ic-bond-wire-thick: "\e678";
$bosch-ic-bond-wire-thin: "\e679";
$bosch-ic-clamp-connection-insulation: "\e67a";
$bosch-ic-clamp-connection-stamping-grid: "\e67b";
$bosch-ic-clinching: "\e67c";
$bosch-ic-connector: "\e67d";
$bosch-ic-connector-potting: "\e67e";
$bosch-ic-element-damping: "\e67f";
$bosch-ic-element-pressure-compensation: "\e680";
$bosch-ic-flanging: "\e681";
$bosch-ic-gap-pad: "\e682";
$bosch-ic-heat-sink: "\e683";
$bosch-ic-hook-clipsing: "\e684";
$bosch-ic-interconnection-board-to-board: "\e685";
$bosch-ic-labyrinth-geometry: "\e686";
$bosch-ic-lacquering-pcb: "\e687";
$bosch-ic-magnetic-rotor: "\e688";
$bosch-ic-magnetic-switch: "\e689";
$bosch-ic-pin-pressfit: "\e68a";
$bosch-ic-power-contact: "\e68b";
$bosch-ic-rivet: "\e68c";
$bosch-ic-seal-ring: "\e68d";
$bosch-ic-sealing-cipg: "\e68e";
$bosch-ic-sealing-solid: "\e68f";
$bosch-ic-sealing-tongue-groove: "\e690";
$bosch-ic-shrinking-arrows-circle: "\e691";
$bosch-ic-solder-selective: "\e692";
$bosch-ic-spring-mechanical: "\e693";
$bosch-ic-staking-hot-air: "\e694";
$bosch-ic-stitching: "\e695";
$bosch-ic-thermal-inlay: "\e696";
$bosch-ic-thermal-via: "\e697";
$bosch-ic-tube: "\e698";
$bosch-ic-tube-gapfiller: "\e699";
$bosch-ic-tube-gelling: "\e69a";
$bosch-ic-tube-gluing: "\e69b";
$bosch-ic-tube-thermal-adhesive: "\e69c";
$bosch-ic-tube-underfill: "\e69d";
$bosch-ic-welding-laser: "\e69e";
$bosch-ic-welding-resistance: "\e69f";
$bosch-ic-welding-ultrasonic: "\e6a0";
$bosch-ic-air-condition-cassette: "\e6a1";
$bosch-ic-air-condition-ceiling: "\e6a2";
$bosch-ic-air-condition-convertible: "\e6a3";
$bosch-ic-air-condition-duct: "\e6a4";
$bosch-ic-air-condition-floor: "\e6a5";
$bosch-ic-air-condition-outdoor: "\e6a6";
$bosch-ic-air-condition-outdoor-hp: "\e6a7";
$bosch-ic-air-condition-outdoor-hr: "\e6a8";
$bosch-ic-air-condition-spot-vertical-airflow-swing: "\e6a9";
$bosch-ic-air-condition-horizontal-swing: "\e6aa";
$bosch-ic-air-condition-wall: "\e6ab";
$bosch-ic-angle-curvature-motorcycle: "\e6ac";
$bosch-ic-appliance-hot: "\e6ad";
$bosch-ic-appliance-water-hot: "\e6ae";
$bosch-ic-appliances-hot: "\e6af";
$bosch-ic-arrows-left-right-a: "\e6b0";
$bosch-ic-arrows-left-right-exchange: "\e6b1";
$bosch-ic-arrows-left-right-exchange-unit: "\e6b2";
$bosch-ic-arrows-left-right-ice: "\e6b3";
$bosch-ic-assembly-line-robot: "\e6b4";
$bosch-ic-back-left-small: "\e6b5";
$bosch-ic-bacteria: "\e6b6";
$bosch-ic-battery-car-drop-leakage-proof: "\e6b7";
$bosch-ic-battery-car-hourglass: "\e6b8";
$bosch-ic-brake-disk-arrow: "\e6b9";
$bosch-ic-brake-disk-compound: "\e6ba";
$bosch-ic-building-religious-church: "\e6bb";
$bosch-ic-calendar-sheet: "\e6bc";
$bosch-ic-caliper: "\e6bd";
$bosch-ic-car-middle-arrow: "\e6be";
$bosch-ic-checkmark-bold: "\e6bf";
$bosch-ic-close-small: "\e6c0";
$bosch-ic-data-center: "\e6c1";
$bosch-ic-dot: "\e6c2";
$bosch-ic-down-small: "\e6c3";
$bosch-ic-e-call: "\e6c4";
$bosch-ic-fire-extinguisher: "\e6c5";
$bosch-ic-fire-off-bold: "\e6c6";
$bosch-ic-fire-off-light: "\e6c7";
$bosch-ic-forward-right-small: "\e6c8";
$bosch-ic-health-warning: "\e6c9";
$bosch-ic-ignition-coil-pencil: "\e6ca";
$bosch-ic-ignition-condensor: "\e6cb";
$bosch-ic-ignition-contact-set: "\e6cc";
$bosch-ic-ignition-distributor-cap: "\e6cd";
$bosch-ic-ignition-distributor-rotor: "\e6ce";
$bosch-ic-ignition-module: "\e6cf";
$bosch-ic-less-minimize-bold: "\e6d0";
$bosch-ic-leaf-plus-boost: "\e6d1";
$bosch-ic-sensor-dynamic: "\e6d2";
$bosch-ic-sensor-radar: "\e6d3";
$bosch-ic-up-small: "\e6d4";
$bosch-ic-water-ladder-off-bold: "\e6d5";
$bosch-ic-water-ladder-off-light: "\e6d6";
$bosch-ic-nose-desodorize: "\e6d7";
$bosch-ic-nose-fine-dust: "\e6d8";
$bosch-ic-smartcity-camera: "\e6d9";
$bosch-ic-heating-underfloor: "\e6da";
$bosch-ic-drill-bit: "\e6db";
$bosch-ic-parameter-process: "\e6dc";
$bosch-ic-car-side-plug-flash: "\e6dd";
$bosch-ic-car-side-plug-engine-flash: "\e6de";
$bosch-ic-car-side-engine-flash: "\e6df";
$bosch-ic-car-side-hydrogen-flash: "\e6e0";
$bosch-ic-car-side-engine: "\e6e1";
$bosch-ic-tunnel: "\e6e2";
$bosch-ic-stadium-sport: "\e6e3";
$bosch-ic-refinery-oil: "\e6e4";
$bosch-ic-power-plants: "\e6e5";
$bosch-ic-hammer-chisel: "\e6e6";
$bosch-ic-glass-cocktail: "\e6e7";
$bosch-ic-traffic-light: "\e6e8";
$bosch-ic-vehicle-off-road: "\e6e9";
$bosch-ic-tablet-finger: "\e6ea";
$bosch-ic-scooter-kick: "\e6eb";
$bosch-ic-handlebar-motorcycle: "\e6ec";
$bosch-ic-display-motorcycle: "\e6ed";
$bosch-ic-case-label-product: "\e6ee";
$bosch-ic-case-label: "\e6ef";
$bosch-ic-product-falling: "\e6f0";
$bosch-ic-product-checkmark: "\e6f1";
$bosch-ic-product-broken: "\e6f2";
$bosch-ic-lightbulb-halogen-rays-bright: "\e6f3";
$bosch-ic-lightbulb-halogen-rays: "\e6f4";
$bosch-ic-lamp-gas-discharge-rays: "\e6f5";
$bosch-ic-home-lightbulb-off-disabled-bold: "\e6f6";
$bosch-ic-home-lightbulb-off-disabled-light: "\e6f7";
$bosch-ic-fork-lift-boxes: "\e6f8";
$bosch-ic-harbour-ship-cargo: "\e6f9";
$bosch-ic-rollercoaster: "\e6fa";
$bosch-ic-sound-off-light: "\e6fb";
$bosch-ic-sound-off-bold: "\e6fc";
$bosch-ic-car-door: "\e6fd";
$bosch-ic-car-door-tailgate: "\e6fe";
$bosch-ic-car-door-split-rear: "\e6ff";
$bosch-ic-car-door-rear: "\e700";
$bosch-ic-drill-driver-cordless: "\e701";
$bosch-ic-virus: "\e702";
$bosch-ic-ice-temperature: "\e703";
$bosch-ic-sensor-ultrasonic: "\e704";
$bosch-ic-user-artificial-intelligence: "\e705";
$bosch-ic-detector-leak: "\e706";
$bosch-ic-car-seat-add: "\e707";
$bosch-ic-car-shuttle-robo: "\e708";
$bosch-ic-caravan: "\e709";
$bosch-ic-user-water-swimming: "\e70a";
$bosch-ic-square-add: "\e70b";
$bosch-ic-map-zoom-in: "\e70c";
$bosch-ic-map-arrow-pop-out: "\e70d";
$bosch-ic-home-user-in-error: "\e70e";
$bosch-ic-home-arrow-right-in-denied: "\e70f";
$bosch-ic-home-arrow-right-in-check: "\e710";
$bosch-ic-home-arrow-right-in: "\e711";
$bosch-ic-home-arrow-left-out: "\e712";
$bosch-ic-file-error: "\e713";
$bosch-ic-dealer-details-missing: "\e714";
$bosch-ic-dealer-details-check: "\e715";
$bosch-ic-components-add: "\e716";
$bosch-ic-machine-cell: "\e717";
$bosch-ic-wheel-rim-clean: "\e718";
$bosch-ic-gas-can: "\e719";
$bosch-ic-steps-locator: "\e71a";
$bosch-ic-panel-control-manual: "\e71b";
$bosch-ic-socket-europe: "\e71c";
$bosch-ic-tripod: "\e71d";
$bosch-ic-document-dxf: "\e71e";
$bosch-ic-document-dwg: "\e71f";
$bosch-ic-hook-wiperblade: "\e720";
$bosch-ic-traffic-cone: "\e721";
$bosch-ic-folder-locked: "\e722";
$bosch-ic-package-self-service: "\e723";
$bosch-ic-drop-ice: "\e724";
$bosch-ic-lamp-gas-discharge: "\e725";
$bosch-ic-home-basement: "\e726";
$bosch-ic-link-edit: "\e727";
$bosch-ic-table-header-add: "\e728";
$bosch-ic-table-column-add-after: "\e729";
$bosch-ic-table-row-add-above: "\e72a";
$bosch-ic-table-column-add-before: "\e72b";
$bosch-ic-table-row-add-below: "\e72c";
$bosch-ic-table-cells-split: "\e72d";
$bosch-ic-table-cells-merge: "\e72e";
$bosch-ic-table-row-delete: "\e72f";
$bosch-ic-text-indent-arrow: "\e730";
$bosch-ic-text-outdent-arrow: "\e731";
$bosch-ic-car-side-flash: "\e732";
$bosch-ic-battery-plug-flash: "\e733";
$bosch-ic-car-chip: "\e734";
$bosch-ic-car-cloud-upload: "\e735";
$bosch-ic-circle-parking: "\e736";
$bosch-ic-engine-battery-flash: "\e737";
$bosch-ic-engine-battery-flash-plug: "\e738";
$bosch-ic-engine-combustion: "\e739";
$bosch-ic-user-head-scan: "\e73a";
$bosch-ic-bluetooth-off-disabled-bold: "\e73b";
$bosch-ic-bluetooth-off-disabled-light: "\e73c";
$bosch-ic-communicator-off-disabled-bold: "\e73d";
$bosch-ic-communicator-off-disabled-light: "\e73e";
$bosch-ic-connectivity-off-disabled-bold: "\e73f";
$bosch-ic-connectivity-off-disabled-light: "\e740";
$bosch-ic-globe-off-disabled-bold: "\e741";
$bosch-ic-globe-off-disabled-light: "\e742";
$bosch-ic-lamp-led: "\e743";
$bosch-ic-wifi-off-disabled-bold: "\e744";
$bosch-ic-wifi-off-disabled-light: "\e745";
$bosch-ic-wheel-vehicle: "\e746";
$bosch-ic-ignition-cable: "\e747";
$bosch-ic-ignition-coil: "\e748";
$bosch-ic-ignition-distributor: "\e749";
$bosch-ic-microphone-classic-off-disabled-light: "\e74a";
$bosch-ic-microphone-classic-off-disabled-bold: "\e74b";
$bosch-ic-cloud-refresh: "\e74c";
$bosch-ic-ignition-coil-module: "\e74d";
$bosch-ic-abort-frame: "\e886";
$bosch-ic-arrow-down-frame: "\e887";
$bosch-ic-arrow-left-down-frame: "\e888";
$bosch-ic-arrow-left-frame: "\e889";
$bosch-ic-arrow-left-up-frame: "\e88a";
$bosch-ic-arrow-right-down-frame: "\e88b";
$bosch-ic-arrow-right-frame: "\e88c";
$bosch-ic-arrow-right-up-frame: "\e88d";
$bosch-ic-arrow-up-frame: "\e88e";
$bosch-ic-asiapacific-frame: "\e88f";
$bosch-ic-cash-frame: "\e890";
$bosch-ic-checkmark-frame: "\e891";
$bosch-ic-copyright-frame: "\e892";
$bosch-ic-download-frame: "\e893";
$bosch-ic-europe-frame: "\e894";
$bosch-ic-lock-closed-frame: "\e895";
$bosch-ic-lock-open-frame: "\e896";
$bosch-ic-my-brand-frame: "\e897";
$bosch-ic-northamerica-frame: "\e898";
$bosch-ic-problem-frame: "\e899";
$bosch-ic-promotion-frame: "\e89a";
$bosch-ic-question-frame: "\e89b";
$bosch-ic-share-frame: "\e89c";
$bosch-ic-southamerica-frame: "\e89d";
$bosch-ic-start-play-frame: "\e89e";
$bosch-ic-upload-frame: "\e89f";
$bosch-ic-world-frame: "\e8a0";
$bosch-ic-add-frame: "\e8a1";
$bosch-ic-call-deny-frame: "\e8a2";
$bosch-ic-call-frame: "\e8a3";
$bosch-ic-fast-backward-frame: "\e8a4";
$bosch-ic-fast-forward-frame: "\e8a5";
$bosch-ic-skip-backward-frame: "\e8a6";
$bosch-ic-skip-fast-backward-frame: "\e8a7";
$bosch-ic-skip-fast-forward-frame: "\e8a8";
$bosch-ic-skip-forward-frame: "\e8a9";
$bosch-ic-subtract-frame: "\e8aa";
$bosch-ic-info-i-frame: "\e8ab";
$bosch-ic-stop-frame1: "\e8ac";
$bosch-ic-arrow-up-frame-error1: "\e8ad";
$bosch-ic-arrow-up-frame-check1: "\e8ae";
$bosch-ic-arrow-left-end-frame1: "\e8af";
$bosch-ic-arrow-right-end-frame1: "\e8b0";
$bosch-ic-bluetooth-frame: "\e8b1";
$bosch-ic-bluetooth-frame-error: "\e8b2";
$bosch-ic-ece-ccc-frame: "\e8b3";
$bosch-ic-recycle-arrows-pap20-frame: "\e8b4";
$bosch-ic-umbrella-drops-frame: "\e8b5";
$bosch-ic-arrows-this-side-up-frame: "\e8b6";
$bosch-ic-glass-wine-frame: "\e8b7";
$bosch-ic-pb-frame: "\e8b8";
$bosch-ic-pb-frame-disabled-bold: "\e8b9";
$bosch-ic-pb-frame-disabled-light: "\e8ba";
$bosch-ic-mode-frame: "\e8bb";
$bosch-ic-uv-frame-disabled-bold: "\e8bc";
$bosch-ic-uv-frame-disabled-light: "\e8bd";
$bosch-ic-fan-frame: "\e8be";
$bosch-ic-flash-frame: "\e8bf";
$bosch-ic-facebook-frame: "\ea87";
$bosch-ic-googleplus-frame: "\ea88";
$bosch-ic-instagram-frame: "\ea89";
$bosch-ic-lineapp-frame: "\ea8a";
$bosch-ic-linkedin-frame: "\ea8b";
$bosch-ic-pinterest-frame: "\ea8c";
$bosch-ic-snapchat-frame: "\ea8d";
$bosch-ic-tumblr-frame: "\ea8e";
$bosch-ic-twitter-frame: "\ea8f";
$bosch-ic-vimeo-frame: "\ea90";
$bosch-ic-wechat-frame: "\ea91";
$bosch-ic-weibo-frame: "\ea92";
$bosch-ic-whatsapp-frame: "\ea93";
$bosch-ic-xing-frame: "\ea94";
$bosch-ic-youku-frame: "\ea95";
$bosch-ic-youtube-frame: "\ea96";
$bosch-ic-vk-frame: "\ea97";
$bosch-ic-skype-frame: "\ea98";
$bosch-ic-facebook: "\ea99";
$bosch-ic-google-frame: "\ea9a";
$bosch-ic-google: "\ea9b";
$bosch-ic-googleplus: "\ea9c";
$bosch-ic-instagram: "\ea9d";
$bosch-ic-lineapp: "\ea9e";
$bosch-ic-linkedin: "\ea9f";
$bosch-ic-pinterest: "\eaa0";
$bosch-ic-skype: "\eaa1";
$bosch-ic-snapchat: "\eaa2";
$bosch-ic-tumblr: "\eaa3";
$bosch-ic-twitter: "\eaa4";
$bosch-ic-vimeo: "\eaa5";
$bosch-ic-vk: "\eaa6";
$bosch-ic-wechat: "\eaa7";
$bosch-ic-weibo: "\eaa8";
$bosch-ic-whatsapp: "\eaa9";
$bosch-ic-xing: "\eaaa";
$bosch-ic-youku: "\eaab";
$bosch-ic-youtube: "\eaac";
$bosch-ic-ms-teams: "\eaad";
$bosch-ic-ms-teams-frame: "\eaae";
$bosch-ic-kakao: "\eaaf";
$bosch-ic-kakao-frame: "\eab0";
$bosch-ic-naver: "\eab1";
$bosch-ic-naver-frame: "\eab2";
$bosch-ic-tencent: "\eab3";
$bosch-ic-tencent-frame: "\eab4";
$bosch-ic-wordpress: "\eab5";
$bosch-ic-wordpress-frame: "\eab6";
$bosch-ic-mini-program-frame: "\eab7";
$bosch-ic-mini-program: "\eab8";
$bosch-ic-tiktok: "\eab9";
$bosch-ic-tiktok-frame: "\eaba";
$bosch-ic-glassdoor: "\eabb";
$bosch-ic-glassdoor-frame: "\eabc";
$bosch-ic-git-hub: "\eabd";
$bosch-ic-git-hub-frame: "\eabe";
$bosch-ic-auracast-marketing: "\eac1";
$bosch-ic-auracast-product: "\eac2";
$bosch-ic-nfc-charging: "\eac3";
$bosch-ic-nfc-charging-frame: "\eac4";
$bosch-ic-nfc-directional: "\eac5";
$bosch-ic-nfc-instructional-directional: "\eac6";
$bosch-ic-nfc-instructional-simplified: "\eac7";
$bosch-ic-nfc-simplified: "\eac8";
$bosch-ic-nfc-simplified-frame: "\eac9";
$bosch-ic-plug-and-charge: "\eaca";
$bosch-ic-plug-and-charge-frame: "\eacb";
$bosch-ic-plug-and-charge-symbol: "\eacc";
$bosch-ic-plug-and-charge-symbol-frame: "\eacd";
$bosch-ic-twitter-x: "\eace";
$bosch-ic-twitter-x-frame: "\eacf";
$bosch-ic-alert-error-filled: "\eb08";
$bosch-ic-alert-error: "\eb09";
$bosch-ic-alert-success-filled: "\eb0a";
$bosch-ic-alert-success: "\eb0b";
$bosch-ic-alert-warning-filled: "\eb0c";
$bosch-ic-alert-warning: "\eb0d";
$bosch-ic-alert-info-filled: "\eb0e";
$bosch-ic-alert-info: "\eb0f";
$bosch-ic-mold: "\f0000";
$bosch-ic-battery-car-cranking-power-high: "\f0001";
$bosch-ic-brake-disk-spray-can-coated: "\f0002";
$bosch-ic-car-top-arrows-left-front-right-back: "\f0003";
$bosch-ic-car-top-arrows-left-back-right-front: "\f0004";
$bosch-ic-car-top-arrows-front: "\f0005";
$bosch-ic-car-top-arrows-back-front-left-right: "\f0006";
$bosch-ic-car-top-arrows-back: "\f0007";
$bosch-ic-car-top-arrow-right: "\f0008";
$bosch-ic-car-top-arrow-left: "\f0009";
$bosch-ic-car-top-arrow-front-right: "\f000a";
$bosch-ic-car-top-arrow-front-left: "\f000b";
$bosch-ic-car-top-arrow-back-right: "\f000c";
$bosch-ic-car-top-arrow-back-left: "\f000d";
$bosch-ic-watermelon: "\f000e";
$bosch-ic-wind-vane-moderate: "\f000f";
$bosch-ic-radioactive: "\f0010";
$bosch-ic-heating-underfloor-off-bold: "\f0011";
$bosch-ic-heating-underfloor-off-light: "\f0012";
$bosch-ic-goat: "\f0013";
$bosch-ic-fire-hose: "\f0014";
$bosch-ic-user-run-arrow: "\f0015";
$bosch-ic-store: "\f0016";
$bosch-ic-user-work-dig: "\f0017";
$bosch-ic-car-top-arrow-angle-right: "\f0018";
$bosch-ic-control-unit-ecu: "\f0019";
$bosch-ic-cigarette: "\f001a";
$bosch-ic-structure-line: "\f001b";
$bosch-ic-product-database-table: "\f001c";
$bosch-ic-bicycle-e-remote-led: "\f001d";
$bosch-ic-bicycle-e-drive-unit: "\f001e";
$bosch-ic-bicycle-e-drive-unit-individual-support: "\f001f";
$bosch-ic-nm-arrow-circle: "\f0020";
$bosch-ic-tree-path: "\f0021";
$bosch-ic-bicycle-e-wheel-arrows: "\f0022";
$bosch-ic-tune-vertical: "\f0023";
$bosch-ic-sound-professional: "\f0024";
$bosch-ic-plane-top-headset: "\f0025";
$bosch-ic-desktop-user-headset: "\f0026";
$bosch-ic-user-service: "\f0027";
$bosch-ic-audio-commercial: "\f0028";
$bosch-ic-loudspeaker-portable-note: "\f0029";
$bosch-ic-audio-loudspeaker-public: "\f002a";
$bosch-ic-items-lost-questionmark: "\f002b";
$bosch-ic-hand-pull: "\f002c";
$bosch-ic-steering-wheel-hand-horn: "\f002d";
$bosch-ic-car-chassis: "\f002e";
$bosch-ic-steering-system: "\f002f";
$bosch-ic-wheel-suspension: "\f0030";
$bosch-ic-car-body-side: "\f0031";
$bosch-ic-address-consumer-data-upright: "\f0032";
$bosch-ic-weather-wind-rain: "\f0033";
$bosch-ic-weather-rain-warning: "\f0034";
$bosch-ic-user-check: "\f0035";
$bosch-ic-user-warning: "\f0036";
$bosch-ic-black-white-stars-circle: "\f0037";
$bosch-ic-team-warning: "\f0038";
$bosch-ic-black-white-circle: "\f0039";
$bosch-ic-suitcase-small: "\f003a";
$bosch-ic-suitcase-small-remove: "\f003b";
$bosch-ic-home-arrow-out: "\f003c";
$bosch-ic-home-arrow-in: "\f003d";
$bosch-ic-document-copy-check: "\f003e";
$bosch-ic-ring-infrared: "\f003f";
$bosch-ic-spirit-level: "\f0040";
$bosch-ic-drill-driver-check: "\f0041";
$bosch-ic-desktop-led: "\f0042";
$bosch-ic-desktop-black-white: "\f0043";
$bosch-ic-desktop-warning: "\f0044";
$bosch-ic-fire-wall: "\f0045";
$bosch-ic-onroad-car-top: "\f0046";
$bosch-ic-onroad-suv-top: "\f0047";
$bosch-ic-onroad-truck-top: "\f0048";
$bosch-ic-weather-rain-ip44: "\f0049";
$bosch-ic-weather-rain-ip65: "\f004a";
$bosch-ic-weather-rain-ip66: "\f004b";
$bosch-ic-weather-rain-ip68: "\f004c";
$bosch-ic-weather-rain-ip67: "\f004d";
$bosch-ic-user-masking: "\f004e";
$bosch-ic-address-consumer-data-dual: "\f004f";
$bosch-ic-user-framed: "\f0050";
$bosch-ic-user-counting-1: "\f0051";
$bosch-ic-square-kx-uhd: "\f0052";
$bosch-ic-square-qvga: "\f0053";
$bosch-ic-square-vga: "\f0054";
$bosch-ic-square-xmp: "\f0055";
$bosch-ic-square-xxxp: "\f0056";
$bosch-ic-angle-arrows-across: "\f0057";
$bosch-ic-angle: "\f0058";
$bosch-ic-angle-arrow-up: "\f0059";
$bosch-ic-angle-view-wide: "\f005a";
$bosch-ic-chat-language-selection: "\f005b";
$bosch-ic-smartphone-app: "\f005c";
$bosch-ic-commissioning-remote: "\f005d";
$bosch-ic-user-run-detection-pir: "\f005e";
$bosch-ic-field-in-motion: "\f005f";
$bosch-ic-building-integration-system: "\f0060";
$bosch-ic-desktop-mamangement: "\f0061";
$bosch-ic-desktop-management-camera: "\f0062";
$bosch-ic-screwdriver-check: "\f0063";
$bosch-ic-screwdriver: "\f0064";
$bosch-ic-desktop-hand-touch: "\f0065";
$bosch-ic-user-robot-head: "\f0066";
$bosch-ic-gear-circles-elements: "\f0067";
$bosch-ic-gears-3: "\f0068";
$bosch-ic-door-address-consumer-data: "\f0069";
$bosch-ic-lock-closed-128bit: "\f006a";
$bosch-ic-lock-closed-connected-wps: "\f006b";
$bosch-ic-battery-arrow-right: "\f006c";
$bosch-ic-connector-bnc: "\f006d";
$bosch-ic-tower-connection-cdma: "\f006e";
$bosch-ic-tower-connection-gprs: "\f006f";
$bosch-ic-tritech: "\f0070";
$bosch-ic-quad-sensor-two: "\f0071";
$bosch-ic-dynamic-range-hdr: "\f0072";
$bosch-ic-dynamic-range-wdr: "\f0073";
$bosch-ic-battery-thumb-up-arrow-right: "\f0074";
$bosch-ic-screwdriver-thumb-up: "\f0075";
$bosch-ic-thumb-up-arrow-up: "\f0076";
$bosch-ic-core-data-sql: "\f0077";
$bosch-ic-frequency-869: "\f0078";
$bosch-ic-coins: "\f0079";
$bosch-ic-frames-60: "\f007a";
$bosch-ic-hand-card: "\f007b";
$bosch-ic-microphone-plugabble: "\f007c";
$bosch-ic-video-h264: "\f007d";
$bosch-ic-video-hd: "\f007e";
$bosch-ic-video-mpeg: "\f007f";
$bosch-ic-video-hd-4k: "\f0080";
$bosch-ic-video-hd-720: "\f0081";
$bosch-ic-lasso: "\f0082";
$bosch-ic-lasso-remove: "\f0083";
$bosch-ic-battery-car-3-clock: "\f0084";
$bosch-ic-battery-car-agm: "\f0085";
$bosch-ic-battery-car-flash: "\f0086";
$bosch-ic-filter-binary: "\f0087";
$bosch-ic-speedometer-bitrate-reduce: "\f0088";
$bosch-ic-voting: "\f0089";
$bosch-ic-voting-dual: "\f008a";
$bosch-ic-sound-pressure-level-90: "\f008b";
$bosch-ic-sound-pressure-level-88: "\f008c";
$bosch-ic-sound-pressure-level-80: "\f008d";
$bosch-ic-power-rated-20: "\f008e";
$bosch-ic-labyrinth-geometry-integration: "\f008f";
$bosch-ic-head-speaking-voice-2: "\f0090";
$bosch-ic-volume-microphone: "\f0091";
$bosch-ic-user-walk-route: "\f0092";
$bosch-ic-user-walk-line-crossing: "\f0093";
$bosch-ic-user-walk-line-count: "\f0094";
$bosch-ic-fullscreen-arrows-circle: "\f0095";
$bosch-ic-field-arrows-counter-flow: "\f0096";
$bosch-ic-field-arrows-directed-flow: "\f0097";
$bosch-ic-user-birds-eye-count-12: "\f0098";
$bosch-ic-field-user-count-12: "\f0099";
$bosch-ic-field-user-falling: "\f009a";
$bosch-ic-field-user-loitering-arrow: "\f009b";
$bosch-ic-field-user-similarity: "\f009c";
$bosch-ic-field-user-walk: "\f009d";
$bosch-ic-field-user-walk-arrow-behind: "\f009e";
$bosch-ic-field-user-walk-arrow-in-front: "\f009f";
$bosch-ic-field-users: "\f00a0";
$bosch-ic-shock-resistant: "\f00a1";
$bosch-ic-bus-sdi: "\f00a2";
$bosch-ic-bus-sdi2: "\f00a3";
$bosch-ic-folder-ip-net: "\f00a4";
$bosch-ic-globe-iscsi: "\f00a5";
$bosch-ic-offline-www: "\f00a6";
$bosch-ic-opc: "\f00a7";
$bosch-ic-osdp: "\f00a8";
$bosch-ic-settings-connected-ptrz: "\f00a9";
$bosch-ic-raid: "\f00aa";
$bosch-ic-lsn: "\f00ab";
$bosch-ic-pointbus: "\f00ac";
$bosch-ic-male-count-12: "\f00ad";
$bosch-ic-circle-point-record: "\f00ae";
$bosch-ic-plug-poe: "\f00af";
$bosch-ic-ir-distribution: "\f00b0";
$bosch-ic-ir-range: "\f00b1";
$bosch-ic-connectivity-it: "\f00b2";
$bosch-ic-music-note-plus: "\f00b3";
$bosch-ic-music-note-plus-two: "\f00b4";
$bosch-ic-chat-language-chn: "\f00b5";
$bosch-ic-microphone-high-directive: "\f00b6";
$bosch-ic-document-copy-rg-gf: "\f00b7";
$bosch-ic-document-copy-cdq: "\f00b8";
$bosch-ic-document-copy-cgp: "\f00b9";
$bosch-ic-folder-add: "\f00ba";
$bosch-ic-user-tie: "\f00bb";
$bosch-ic-pill: "\f00bc";
$bosch-ic-sonotrode: "\f00bd";
$bosch-ic-user-thumb-up: "\f00be";
$bosch-ic-architecture: "\f00bf";
$bosch-ic-clamping-area-arrows: "\f00c0";
$bosch-ic-clamping-rib: "\f00c1";
$bosch-ic-connection-plug-in: "\f00c2";
$bosch-ic-connection-plug-in-electrical: "\f00c3";
$bosch-ic-connection-plug-in-socket: "\f00c4";
$bosch-ic-connector-shield-emc: "\f00c5";
$bosch-ic-foil-flex: "\f00c6";
$bosch-ic-grid-fan: "\f00c7";
$bosch-ic-screw-nut: "\f00c8";
$bosch-ic-sealing-groove: "\f00c9";
$bosch-ic-sealing-tongue-groove-flash: "\f00ca";
$bosch-ic-solder: "\f00cb";
$bosch-ic-solder-selective-ssl: "\f00cc";
$bosch-ic-solder-selective-thrs: "\f00cd";
$bosch-ic-tuner-box: "\f00ce";
$bosch-ic-water-cooling-arrow-up: "\f00cf";
$bosch-ic-generic-device-connected: "\f00d0";
$bosch-ic-home-sun-flash: "\f00d1";
$bosch-ic-home-sun-wrench: "\f00d2";
$bosch-ic-temperature-lock: "\f00d3";
$bosch-ic-solder-method-smd: "\f00d4";
$bosch-ic-pins: "\f00d5";
$bosch-ic-connector-housing: "\f00d6";
$bosch-ic-antenna-cover-arrow-up: "\f00d7";
$bosch-ic-angle-open-horizontal-130: "\f00d8";
$bosch-ic-angle-open-vertical-130: "\f00d9";
$bosch-ic-angle-open-vertical-90: "\f00da";
$bosch-ic-angle-open-horizontal-145: "\f00db";
$bosch-ic-steering-system-steer-by-wire: "\f00dc";
$bosch-ic-tune-vertical-music-note: "\f00dd";
$bosch-ic-hammer-movement: "\f00de";
$bosch-ic-drill-drive-percent-35: "\f00df";
$bosch-ic-country-switzerland: "\f00e0";
$bosch-ic-speedlimit-revoking: "\f00e1";
$bosch-ic-speedlimit-80-revoking: "\f00e2";
$bosch-ic-speedlimit-80-dirty: "\f00e3";
$bosch-ic-battery-car-mf: "\f00e4";
$bosch-ic-access-controller-2: "\f00e5";
$bosch-ic-access-controller-arrow-up: "\f00e6";
$bosch-ic-access-controller-points-set: "\f00e7";
$bosch-ic-analog-value: "\f00e8";
$bosch-ic-analog-value-arrow-in: "\f00e9";
$bosch-ic-analog-value-arrow-out: "\f00ea";
$bosch-ic-binary-value: "\f00eb";
$bosch-ic-binary-value-arrow-in: "\f00ec";
$bosch-ic-binary-value-arrow-out: "\f00ed";
$bosch-ic-calendar-date-single: "\f00ee";
$bosch-ic-calendar-date-weekend: "\f00ef";
$bosch-ic-calendar-view: "\f00f0";
$bosch-ic-chart-average: "\f00f1";
$bosch-ic-chart-line-arrows-up: "\f00f2";
$bosch-ic-configuration-points-set: "\f00f3";
$bosch-ic-data-points-set: "\f00f4";
$bosch-ic-document-points-set: "\f00f5";
$bosch-ic-multistate-value: "\f00f6";
$bosch-ic-multistate-value-arrow-in: "\f00f7";
$bosch-ic-multistate-value-arrow-out: "\f00f8";
$bosch-ic-structure-bacnet: "\f00f9";
$bosch-ic-structure-sbus: "\f00fa";
$bosch-ic-team-arrows-in: "\f00fb";
$bosch-ic-import-points-set: "\f00fc";
$bosch-ic-image-points-set: "\f00fd";
$bosch-ic-circuit-hydraulic: "\f00fe";
$bosch-ic-structure-area: "\f00ff";
$bosch-ic-structure-calculate: "\f0100";
$bosch-ic-structure-clipboard: "\f0101";
$bosch-ic-structure-collection: "\f0102";
$bosch-ic-structure-components: "\f0103";
$bosch-ic-structure-device: "\f0104";
$bosch-ic-structure-dragdrop: "\f0105";
$bosch-ic-structure-floor: "\f0106";
$bosch-ic-structure-organization: "\f0107";
$bosch-ic-structure-questionmark: "\f0108";
$bosch-ic-structure-settings: "\f0109";
$bosch-ic-structure-skyscraper: "\f010a";
$bosch-ic-structure-window-console: "\f010b";
$bosch-ic-structure-wrench: "\f010c";
$bosch-ic-car-pickup: "\f010d";
$bosch-ic-desktop-team: "\f010e";
$bosch-ic-keyboard-arrow-enter-return: "\f010f";
$bosch-ic-keyboard-arrow-left: "\f0110";
$bosch-ic-keyboard-arrow-right-tab: "\f0111";
$bosch-ic-keyboard-capslock: "\f0112";
$bosch-ic-keyboard-shift: "\f0113";
$bosch-ic-keyboard-space: "\f0114";
$bosch-ic-bluetooth-le-off-disabled-light: "\f0115";
$bosch-ic-structure-label-edit: "\f0116";
$bosch-ic-structure-server: "\f0117";
$bosch-ic-truck-box: "\f0118";
$bosch-ic-imagery-off-disabled-light: "\f0119";
$bosch-ic-imagery-off-disabled-bold: "\f011a";
$bosch-ic-plan-floorplan-edit: "\f011b";
$bosch-ic-desktop-building-ruler-pen: "\f011c";
$bosch-ic-conference-system-connected: "\f011d";
$bosch-ic-receiver-pocket-headphones: "\f011e";
$bosch-ic-conference-system-microphone: "\f011f";
$bosch-ic-user-carry-box: "\f0120";
$bosch-ic-user-handcart: "\f0121";
$bosch-ic-user-carry-box-2: "\f0122";
$bosch-ic-locator-error: "\f0123";
$bosch-ic-locator-offline: "\f0124";
$bosch-ic-locator-refresh: "\f0125";
$bosch-ic-chart-bar-plug-arrow-up: "\f0126";
$bosch-ic-clipboard-search-leaf: "\f0127";
$bosch-ic-handover-leaf: "\f0128";
$bosch-ic-home-sun-plug: "\f0129";
$bosch-ic-lightbulb-plug-leaf: "\f012a";
$bosch-ic-wheel-water-plug: "\f012b";
$bosch-ic-windmill-plug: "\f012c";
$bosch-ic-windmill-plug-sun-water: "\f012d";
$bosch-ic-scale-co2: "\f012e";
$bosch-ic-certificat-co2: "\f012f";
$bosch-ic-car-crash: "\f0130";
$bosch-ic-headphones: "\f0131";
$bosch-ic-music-note: "\f0132";
$bosch-ic-clef-treble: "\f0133";
$bosch-ic-clef-bass: "\f0134";
$bosch-ic-volume: "\f0135";
$bosch-ic-volume-increase: "\f0136";
$bosch-ic-head-speach-speaking: "\f0137";
$bosch-ic-head-speaking-request: "\f0138";
$bosch-ic-tetragon-priority: "\f0139";
$bosch-ic-head-respond-speaking: "\f013a";
$bosch-ic-head-respond-request: "\f013b";
$bosch-ic-hand-stop: "\f013c";
$bosch-ic-layers-stack: "\f013d";
$bosch-ic-puzzle-binary: "\f013e";
$bosch-ic-sdcard-micro: "\f013f";
$bosch-ic-reader-card: "\f0140";
$bosch-ic-reader-user-recognition-face: "\f0141";
$bosch-ic-detector-point-type: "\f0142";
$bosch-ic-manual-call-point: "\f0143";
$bosch-ic-gun-magnifier: "\f0144";
$bosch-ic-interface-module: "\f0145";
$bosch-ic-fire-detector-automatic: "\f0146";
$bosch-ic-fire-brigade-emergency: "\f0147";
$bosch-ic-volume-siren-device: "\f0148";
$bosch-ic-user-reception: "\f0149";
$bosch-ic-shredder-machine: "\f014a";
$bosch-ic-badge-clean: "\f014b";
$bosch-ic-parking-assistance: "\f014c";
$bosch-ic-laptop-questionmark: "\f014d";
$bosch-ic-security-lock: "\f014e";
$bosch-ic-battery-car-12v: "\f014f";
$bosch-ic-car-far-propagation: "\f0150";
$bosch-ic-car-wide-propagation: "\f0151";
$bosch-ic-ear-sound: "\f0152";
$bosch-ic-umbrella-drops: "\f0153";
$bosch-ic-horn-arrows-in: "\f0154";
$bosch-ic-video-bell: "\f0155";
$bosch-ic-steering-wheel-arrows-up: "\f0156";
$bosch-ic-circle-full: "\f0157";
$bosch-ic-circle-half: "\f0158";
$bosch-ic-circle-quarter: "\f0159";
$bosch-ic-circle-quarter-three: "\f015a";
$bosch-ic-appliance-flame-arrow: "\f015b";
$bosch-ic-appliance-flame-arrow-bold: "\f015c";
$bosch-ic-appliance-flame-disabled-bold: "\f015d";
$bosch-ic-appliance-flame-disabled-light: "\f015e";
$bosch-ic-appliance-flame-plus: "\f015f";
$bosch-ic-appliance-flame-plus-bold: "\f0160";
$bosch-ic-flash-minus-bold: "\f0161";
$bosch-ic-flash-minus: "\f0162";
$bosch-ic-flash-off: "\f0163";
$bosch-ic-flash-plus-bold: "\f0164";
$bosch-ic-flash-smart-grid: "\f0165";
$bosch-ic-signal-high: "\f0166";
$bosch-ic-signal-low: "\f0167";
$bosch-ic-signal-very-low: "\f0168";
$bosch-ic-5g: "\f0169";
$bosch-ic-sun-photovoltaic: "\f016a";
$bosch-ic-inverter: "\f016b";
$bosch-ic-car-arrow-down: "\f016c";
$bosch-ic-car-distance: "\f016d";
$bosch-ic-car-sensor: "\f016e";
$bosch-ic-car-sensor-environment: "\f016f";
$bosch-ic-car-sensor-front: "\f0170";
$bosch-ic-streets-locator: "\f0171";
$bosch-ic-truck-distance: "\f0172";
$bosch-ic-warning-user-walk: "\f0173";
$bosch-ic-parking-check: "\f0174";
$bosch-ic-dremel: "\f0175";
$bosch-ic-allergens: "\f0176";
$bosch-ic-money-currency-coins: "\f0177";
$bosch-ic-hydrogen-small: "\f0178";
$bosch-ic-hydrogen-large: "\f0179";
$bosch-ic-shoppingcart-remove: "\f017a";
$bosch-ic-chimney-sweeper-disabled-light: "\f017b";
$bosch-ic-chimney-sweeper-disabled-bold: "\f017c";
$bosch-ic-car-waves: "\f017d";
$bosch-ic-360-degrees-arrow: "\f017e";
$bosch-ic-clock-info: "\f017f";
$bosch-ic-truck-front-disabled-bold: "\f0180";
$bosch-ic-truck-front-disabled-light: "\f0181";
$bosch-ic-box-closed-list: "\f0182";
$bosch-ic-battery-car-exchange: "\f0183";
$bosch-ic-guitar: "\f0184";
$bosch-ic-actuator-by-wire: "\f0185";
$bosch-ic-guitar-electric: "\f0186";
$bosch-ic-format-align-center: "\f0187";
$bosch-ic-format-align-justify: "\f0188";
$bosch-ic-format-align-right: "\f0189";
$bosch-ic-format-checklist: "\f018a";
$bosch-ic-format-italic: "\f018b";
$bosch-ic-format-ordered-list: "\f018c";
$bosch-ic-format-strike: "\f018d";
$bosch-ic-format-underline: "\f018e";
$bosch-ic-format-align-left: "\f018f";
$bosch-ic-format-fill-drop: "\f0190";
$bosch-ic-format-color-a: "\f0191";
$bosch-ic-format-bold-b: "\f0192";
$bosch-ic-fly: "\f0193";
$bosch-ic-paw: "\f0194";
$bosch-ic-desktop-video-response-fast: "\f0195";
$bosch-ic-user-walk-weather-rain: "\f0196";
$bosch-ic-brake-pedal-plug: "\f0197";
$bosch-ic-detector-motion-range-down-view: "\f0198";
$bosch-ic-detector-motion-range-long: "\f0199";
$bosch-ic-monitor-tiled: "\f019a";
$bosch-ic-radar-doppler: "\f019b";
$bosch-ic-radioactive-disabled-light: "\f019c";
$bosch-ic-radioactive-disabled-bold: "\f019d";
$bosch-ic-bug-disabled-light: "\f019e";
$bosch-ic-bug-disabled-bold: "\f019f";
$bosch-ic-fly-disabled-light: "\f01a0";
$bosch-ic-fly-disabled-bold: "\f01a1";
$bosch-ic-puzzle-2: "\f01a2";
$bosch-ic-camera-lenses-multi-images: "\f01a3";
$bosch-ic-detector-spray: "\f01a4";
$bosch-ic-weather-rain-ip56: "\f01a5";
$bosch-ic-document-log-start: "\f01a6";
$bosch-ic-document-log-stop: "\f01a7";
$bosch-ic-document-3d-pdf: "\f01a8";
$bosch-ic-document-ifc: "\f01a9";
$bosch-ic-document-rfa: "\f01aa";
$bosch-ic-document-stp: "\f01ab";
$bosch-ic-online-www: "\f01ac";
$bosch-ic-bluetooth-connected: "\f01ad";
$bosch-ic-connected-tools: "\f01ae";
$bosch-ic-badge-arrow-kickback: "\f01af";
$bosch-ic-copy-paste: "\f01b0";
$bosch-ic-volleyball: "\f01b1";
$bosch-ic-car-top-trailer: "\f01b2";
$bosch-ic-mountain: "\f01b3";
$bosch-ic-battery-car-oil-lamp: "\f01b4";
$bosch-ic-battery-car-efb: "\f01b5";
$bosch-ic-battery-car-ams: "\f01b6";
$bosch-ic-battery-car-ag: "\f01b7";
$bosch-ic-display-fuel-saving: "\f01b8";
$bosch-ic-circle-parking-arrows: "\f01b9";
$bosch-ic-unicorn-head: "\f01ba";
$bosch-ic-car-parking-automated: "\f01bb";
$bosch-ic-lunchboard: "\f01bc";
$bosch-ic-tetrapack: "\f01bd";
$bosch-ic-recycle-arrows: "\f01be";
$bosch-ic-stairs-arrow-up: "\f01bf";
$bosch-ic-stairs-arrow-down: "\f01c0";
$bosch-ic-industry-car-arrow: "\f01c1";
$bosch-ic-sensor-throttle-position: "\f01c2";
$bosch-ic-sensor-knock: "\f01c3";
$bosch-ic-sensor-air-mass-meter: "\f01c4";
$bosch-ic-sensor-pressure: "\f01c5";
$bosch-ic-sensor-temperature: "\f01c6";
$bosch-ic-sensor-speed-rpm: "\f01c7";
$bosch-ic-desktop-user-software-clock: "\f01c8";
$bosch-ic-book-service-wrench-add: "\f01c9";
$bosch-ic-book-service-wrench: "\f01ca";
$bosch-ic-bicycle-e-drive-unit-individual-support-off-light: "\f01cb";
$bosch-ic-bicycle-e-drive-unit-individual-support-off-bold: "\f01cc";
$bosch-ic-circle-parking-disabled-bold: "\f01cd";
$bosch-ic-circle-parking-disabled-light: "\f01ce";
$bosch-ic-interval-30sec: "\f01cf";
$bosch-ic-truck-front-light-inside: "\f01d0";
$bosch-ic-software-tex: "\f01d1";
$bosch-ic-fire-arrow-circle: "\f01d2";
$bosch-ic-heating-underfloor-circle-global-off: "\f01d3";
$bosch-ic-heating-radiator-circle-global-off: "\f01d4";
$bosch-ic-faucet-circle-global-off: "\f01d5";
$bosch-ic-moon-stars-starlight: "\f01d6";
$bosch-ic-tube-thumb-up: "\f01d7";
$bosch-ic-detector-microwave: "\f01d8";
$bosch-ic-user-headset-thumb-up: "\f01d9";
$bosch-ic-cloud-battery: "\f01da";
$bosch-ic-temperature-arrow-up-increase: "\f01db";
$bosch-ic-temperature-arrow-down-decrease: "\f01dc";
$bosch-ic-moneybag-dollar-chart-double: "\f01dd";
$bosch-ic-locator-remove: "\f01de";
$bosch-ic-square-remove: "\f01df";
$bosch-ic-sun-hdr: "\f01e0";
$bosch-ic-moon-stars-circle: "\f01e1";
$bosch-ic-temperature-user-detection: "\f01e2";
$bosch-ic-wrench-thumb-up: "\f01e3";
$bosch-ic-ring-infrared-ir: "\f01e4";
$bosch-ic-play-back-arrow: "\f01e5";
$bosch-ic-ignition-off: "\f01e6";
$bosch-ic-arrow-up-down: "\f01e7";
$bosch-ic-arrow-left-right: "\f01e8";
$bosch-ic-number-0-bold: "\f01e9";
$bosch-ic-number-1-bold: "\f01ea";
$bosch-ic-number-2-bold: "\f01eb";
$bosch-ic-number-3-bold: "\f01ec";
$bosch-ic-number-4-bold: "\f01ed";
$bosch-ic-number-5-bold: "\f01ee";
$bosch-ic-number-6-bold: "\f01ef";
$bosch-ic-number-7-bold: "\f01f0";
$bosch-ic-number-8-bold: "\f01f1";
$bosch-ic-number-9-bold: "\f01f2";
$bosch-ic-e-charging-plug-dc: "\f01f3";
$bosch-ic-e-charging-plug-ac-dc: "\f01f4";
$bosch-ic-angle-view-wide-monitor: "\f01f5";
$bosch-ic-settings-range-ptrz: "\f01f6";
$bosch-ic-puzzle-video-integration: "\f01f7";
$bosch-ic-number-0-regular: "\f01f8";
$bosch-ic-number-1-regular: "\f01f9";
$bosch-ic-number-2-regular: "\f01fa";
$bosch-ic-number-3-regular: "\f01fb";
$bosch-ic-number-4-regular: "\f01fc";
$bosch-ic-number-5-regular: "\f01fd";
$bosch-ic-number-6-regular: "\f01fe";
$bosch-ic-number-7-regular: "\f01ff";
$bosch-ic-number-8-regular: "\f0200";
$bosch-ic-number-9-regular: "\f0201";
$bosch-ic-import-beep: "\f0202";
$bosch-ic-file-questionmark: "\f0203";
$bosch-ic-file-dollar: "\f0204";
$bosch-ic-document-reporting: "\f0205";
$bosch-ic-box-questionmark1: "\f0206";
$bosch-ic-chat-faq: "\f0207";
$bosch-ic-wishlist-filled: "\f0208";
$bosch-ic-wishlist-half-filled: "\f0209";
$bosch-ic-glove-work: "\f020a";
$bosch-ic-lightbulb-halogen-hand-disabled-bold: "\f020b";
$bosch-ic-lightbulb-halogen-hand-disabled-light: "\f020c";
$bosch-ic-lightbulb-halogen-cracked-disabled-bold: "\f020d";
$bosch-ic-lightbulb-halogen-cracked-disabled-light: "\f020e";
$bosch-ic-lightbulb-halogen-cracked: "\f020f";
$bosch-ic-drops: "\f0210";
$bosch-ic-drops-disabled-bold: "\f0211";
$bosch-ic-drops-disabled-light: "\f0212";
$bosch-ic-temperature-heat: "\f0213";
$bosch-ic-allen-key: "\f0214";
$bosch-ic-aperture-closed: "\f0215";
$bosch-ic-appliance-heat-pump-leaf-connected: "\f0216";
$bosch-ic-badge-flash: "\f0217";
$bosch-ic-battery-car-1-plug-warning: "\f0218";
$bosch-ic-battery-car-3-automatic-mode: "\f0219";
$bosch-ic-battery-car-3-ice: "\f021a";
$bosch-ic-battery-car-memory-function: "\f021b";
$bosch-ic-battery-polarity-reverse-warning: "\f021c";
$bosch-ic-book-questionmark: "\f021d";
$bosch-ic-book-service-wrench-refresh: "\f021e";
$bosch-ic-box-arrow-up: "\f021f";
$bosch-ic-box-arrows-up-down: "\f0220";
$bosch-ic-camera-optic-sensor: "\f0221";
$bosch-ic-car-cloud-software: "\f0222";
$bosch-ic-car-gear-technology: "\f0223";
$bosch-ic-car-street: "\f0224";
$bosch-ic-chip-sensor: "\f0225";
$bosch-ic-circle-blank: "\f0226";
$bosch-ic-detector-point-type-replace: "\f0227";
$bosch-ic-document-x: "\f0228";
$bosch-ic-drag-handle-arrows-up-down: "\f0229";
$bosch-ic-electrolyzer: "\f022a";
$bosch-ic-exhaust-pipe: "\f022b";
$bosch-ic-flower-focus-near: "\f022c";
$bosch-ic-fuel-pump-electric: "\f022d";
$bosch-ic-gear-dollar: "\f022e";
$bosch-ic-gloves-work: "\f022f";
$bosch-ic-handlewithcare-globe: "\f0230";
$bosch-ic-handlewithcare-user: "\f0231";
$bosch-ic-hard-disk: "\f0232";
$bosch-ic-hierarchy-child: "\f0233";
$bosch-ic-hierarchy-parent: "\f0234";
$bosch-ic-hydraulic-connection-leaking-drops: "\f0235";
$bosch-ic-hydraulic-connection-off: "\f0236";
$bosch-ic-hydraulic-connection-on: "\f0237";
$bosch-ic-light-beam: "\f0238";
$bosch-ic-map5000: "\f0239";
$bosch-ic-module-anti-blocking-system: "\f023a";
$bosch-ic-module-ecg: "\f023b";
$bosch-ic-module-ecg-suction: "\f023c";
$bosch-ic-module-navigation: "\f023d";
$bosch-ic-power-supply-unit-plug: "\f023e";
$bosch-ic-security-street: "\f023f";
$bosch-ic-server-single: "\f0240";
$bosch-ic-skip-backward: "\f0241";
$bosch-ic-skip-forward: "\f0242";
$bosch-ic-speedometer-instrument-cluster: "\f0243";
$bosch-ic-system-radar-connected: "\f0244";
$bosch-ic-truck-dump: "\f0245";
$bosch-ic-truck-loader: "\f0246";
$bosch-ic-user-head-twin-digital: "\f0247";
$bosch-ic-wind-vane-strong: "\f0248";
$bosch-ic-wind-vane-weak: "\f0249";
$bosch-ic-windshield-crack: "\f024a";
$bosch-ic-common-rail: "\f024b";
$bosch-ic-common-rail-injector-cri1-cri2: "\f024c";
$bosch-ic-common-rail-injector-cril3: "\f024d";
$bosch-ic-common-rail-injector-crin4-2: "\f024f";
$bosch-ic-common-rail-injector-crin123: "\f0250";
$bosch-ic-conventional-pump-a: "\f0251";
$bosch-ic-conventional-pump-b: "\f0252";
$bosch-ic-edc-inline-injection-pump-pes6p: "\f0253";
$bosch-ic-edc-inline-injection-pump-pes6h: "\f0254";
$bosch-ic-nozzle-holder: "\f0255";
$bosch-ic-unit-injector: "\f0256";
$bosch-ic-qr-code-scan: "\f0257";
$bosch-ic-car-mechanic-plug: "\f0258";
$bosch-ic-copy-machine: "\f0259";
$bosch-ic-dispenser-paper-towel: "\f025a";
$bosch-ic-hand-drops-soap: "\f025b";
$bosch-ic-door-automatic: "\f025c";
$bosch-ic-door-automatic-slide: "\f025d";
$bosch-ic-door-pull: "\f025e";
$bosch-ic-door-push: "\f025f";
$bosch-ic-floor-load-max: "\f0260";
$bosch-ic-lockers: "\f0261";
$bosch-ic-rubbish-trash-bin: "\f0262";
$bosch-ic-rubbish-waste-bin: "\f0263";
$bosch-ic-rubbish-waste-container: "\f0264";
$bosch-ic-sugar: "\f0265";
$bosch-ic-rubbish-glass-bin: "\f0266";
$bosch-ic-car-seat-steering-wheel-stowable: "\f0267";
$bosch-ic-car-seat-steering-wheel-standard: "\f0268";
$bosch-ic-car-seat-steering-wheel-extended: "\f0269";
$bosch-ic-device-bridge: "\f026a";
$bosch-ic-screw-cross-slot: "\f026b";
$bosch-ic-screw-hexagon-head: "\f026c";
$bosch-ic-screw-hexagon-internal: "\f026d";
$bosch-ic-screw-slotted: "\f026e";
$bosch-ic-screw-torx: "\f026f";
$bosch-ic-tape: "\f0270";
$bosch-ic-tape-teflon: "\f0271";
$bosch-ic-security-fire: "\f0272";
$bosch-ic-led-fault: "\f0273";
$bosch-ic-led-alarm: "\f0274";
$bosch-ic-firepanel: "\f0275";
$bosch-ic-fire-output-disabled: "\f0276";
$bosch-ic-user-fire-alarm: "\f0277";
$bosch-ic-car-desktop-graph: "\f0278";
$bosch-ic-desktop-management-statistics: "\f0279";
$bosch-ic-document-key: "\f027a";
$bosch-ic-options-vertical: "\f027b";
$bosch-ic-euro-arrows: "\f027c";
$bosch-ic-monkey: "\f027d";
$bosch-ic-appliance-resistance: "\f027e";
$bosch-ic-pipe-cutter: "\f027f";
$bosch-ic-flash-arrow: "\f0280";
$bosch-ic-flash-arrow-disabled-light: "\f0281";
$bosch-ic-heating-ceiling: "\f0282";
$bosch-ic-heating-ceiling-off-light: "\f0283";
$bosch-ic-heating-ceiling-off-bold: "\f0284";
$bosch-ic-battery-analysis: "\f0285";
$bosch-ic-battery-ice: "\f0286";
$bosch-ic-battery-refresh: "\f0287";
$bosch-ic-device-ids: "\f0288";
$bosch-ic-device-thermostat-bcc50-72: "\f0289";
$bosch-ic-device-thermostat-bcc100-72: "\f028a";
$bosch-ic-silos-gas-natural: "\f028b";
$bosch-ic-silos-gas-leaf-biogas: "\f028c";
$bosch-ic-circle-decentralized: "\f028d";
$bosch-ic-molecules-h2: "\f028e";
$bosch-ic-megawatt-range-arrow-scalable: "\f028f";
$bosch-ic-fan-arrows: "\f0290";
$bosch-ic-recycle-arrows-pvc3: "\f0291";
$bosch-ic-recycle-arrows-ps6: "\f0292";
$bosch-ic-recycle-arrows-pp5: "\f0293";
$bosch-ic-recycle-arrows-pet1: "\f0294";
$bosch-ic-recycle-arrows-pap22: "\f0295";
$bosch-ic-recycle-arrows-pap21: "\f0296";
$bosch-ic-recycle-arrows-pap20: "\f0297";
$bosch-ic-recycle-arrows-oth7: "\f0298";
$bosch-ic-recycle-arrows-ldpe4: "\f0299";
$bosch-ic-recycle-arrows-hdpe2: "\f029a";
$bosch-ic-car-petrol-plug: "\f029b";
$bosch-ic-user-diverse: "\f029c";
$bosch-ic-fire-emergency-run-map: "\f029d";
$bosch-ic-automatic-mode-fan: "\f029e";
$bosch-ic-air-condition-swing-pac: "\f029f";
$bosch-ic-user-fire-alarm-check: "\f02a0";
$bosch-ic-handlewithcare-team: "\f02a1";
$bosch-ic-settings-user-dollar: "\f02a2";
$bosch-ic-skyscraper-connected: "\f02a3";
$bosch-ic-security-ac: "\f02a4";
$bosch-ic-windshield-glare-sun: "\f02a5";
$bosch-ic-user-flag: "\f02a6";
$bosch-ic-hammer-legal: "\f02a7";
$bosch-ic-handover-star: "\f02a8";
$bosch-ic-laptop-wrench: "\f02a9";
$bosch-ic-fan-speed-1-display: "\f02aa";
$bosch-ic-fan-speed-2-display: "\f02ab";
$bosch-ic-fan-speed-3-display: "\f02ac";
$bosch-ic-puzzle-binary-filled: "\f02ad";
$bosch-ic-fan-moving: "\f02ae";
$bosch-ic-dog-head-side: "\f02af";
$bosch-ic-imu-binary: "\f02b0";
$bosch-ic-box-recycling: "\f02b1";
$bosch-ic-video-perception: "\f02b2";
$bosch-ic-fan-speed-0-display: "\f02b3";
$bosch-ic-air-soft-wind: "\f02b4";
$bosch-ic-clean: "\f02b5";
$bosch-ic-air-condition-ion-negative: "\f02b6";
$bosch-ic-glasses-safety: "\f02b7";
$bosch-ic-bottle-squeeze: "\f02b8";
$bosch-ic-bottle-squeeze-water: "\f02b9";
$bosch-ic-knife: "\f02ba";
$bosch-ic-car-side-engine-h2: "\f02bb";
$bosch-ic-nitrogen: "\f02bc";
$bosch-ic-spring-tension: "\f02bd";
$bosch-ic-manometer: "\f02be";
$bosch-ic-badge-50: "\f02bf";
$bosch-ic-badge-20: "\f02c0";
$bosch-ic-badge-10: "\f02c1";
$bosch-ic-garage-car-side-flash: "\f02c2";
$bosch-ic-home-district: "\f02c3";
$bosch-ic-scissors-ribbon-cut: "\f02c4";
$bosch-ic-sign-warning-slope-high: "\f02c5";
$bosch-ic-air-condition-horizontal-airflow-auto: "\f02c6";
$bosch-ic-air-condition-horizontal-airflow-right: "\f02c7";
$bosch-ic-air-condition-horizontal-airflow-center: "\f02c8";
$bosch-ic-air-condition-horizontal-airflow-left: "\f02c9";
$bosch-ic-air-condition-horizontal-airflow-off: "\f02ca";
$bosch-ic-air-condition-horizontal-airflow-wide: "\f02cb";
$bosch-ic-air-condition-horizontal-airflow-ultra-wide: "\f02cc";
$bosch-ic-air-condition-horizontal-airflow-swing: "\f02cd";
$bosch-ic-air-condition-horizontal-airflow-swing-off: "\f02ce";
$bosch-ic-air-condition-spot-air-top-right: "\f02cf";
$bosch-ic-air-condition-spot-air-top-left: "\f02d0";
$bosch-ic-air-condition-spot-air-top-center: "\f02d1";
$bosch-ic-air-condition-spot-air-off: "\f02d2";
$bosch-ic-air-condition-spot-air-full: "\f02d3";
$bosch-ic-air-condition-spot-air-bottom-right: "\f02d4";
$bosch-ic-air-condition-spot-air-bottom-left: "\f02d5";
$bosch-ic-air-condition-spot-air-bottom-center: "\f02d6";
$bosch-ic-air-condition-spot-vertical-airflow-angle-5: "\f02d7";
$bosch-ic-air-condition-spot-vertical-airflow-angle-4: "\f02d8";
$bosch-ic-air-condition-spot-vertical-airflow-angle-3: "\f02d9";
$bosch-ic-air-condition-spot-vertical-airflow-angle-2: "\f02da";
$bosch-ic-air-condition-spot-vertical-airflow-angle-6: "\f02db";
$bosch-ic-air-condition-spot-vertical-airflow-swing1: "\f02dc";
$bosch-ic-air-condition-spot-vertical-airflow-swing-off: "\f02dd";
$bosch-ic-air-condition-spot-vertical-airflow-angle-1: "\f02de";
$bosch-ic-air-condition-spot-vertical-airflow-auto: "\f02df";
$bosch-car-refresh-graph: "\f02e0";
$bosch-ic-angle-grinder: "\f02e1";
$bosch-ic-screwdriver-battery-powered: "\f02e2";
$bosch-ic-hammer-drill-battery-powered: "\f02e3";
$bosch-ic-laser-rangefinder: "\f02e4";
$bosch-ic-braking-system-decentralized: "\f02e5";
$bosch-ic-braking-system-centralized: "\f02e6";
$bosch-ic-coil: "\f02e7";
$bosch-ic-circle-arrows-n-s: "\f02e8";
$bosch-ic-winter-check: "\f02e9";
$bosch-ic-spring-check: "\f02ea";
$bosch-ic-car-check-multi-point: "\f02eb";
$bosch-ic-holiday-check: "\f02ec";
$bosch-ic-brake-disk-caliper-actuator-hydraulic: "\f02ed";
$bosch-ic-brake-disk-electromechanical: "\f02ee";
$bosch-ic-settings-drop-check: "\f02ef";
$bosch-ic-drops-hygroscopic: "\f02f0";
$bosch-ic-roof-solo: "\f02f1";
$bosch-ic-badge-cleaned: "\f02f2";
$bosch-ic-10-point-check: "\f02f3";
$bosch-ic-car-check: "\f02f4";
$bosch-ic-mot-check: "\f02f5";
$bosch-ic-vehicle-diagnostic-check: "\f02f6";
$bosch-ic-eletronic-diagnosis: "\f02f7";
$bosch-ic-battery-services: "\f02f8";
$bosch-ic-engine-diagnosis: "\f02f9";
$bosch-ic-engine-service: "\f02fa";
$bosch-ic-oil-filter-change: "\f02fb";
$bosch-ic-brake-repair: "\f02fd";
$bosch-ic-car-auxiliary-heating: "\f02fe";
$bosch-ic-wheel-alignment: "\f02ff";
$bosch-ic-tire-service: "\f0300";
$bosch-ic-bosch-talks: "\f0301";
$bosch-ic-brake-fluid-change: "\f0302";
$bosch-ic-fault-diagnosis: "\f0303";
$bosch-ic-oil-bottle: "\f0304";
$bosch-ic-oil-change: "\f0305";
$bosch-ic-repair-appointment: "\f0306";
$bosch-ic-aa-warehouse-karlsruhe: "\f0307";
$bosch-ic-wholesaler: "\f0308";
$bosch-ic-notification-off-light: "\f0309";
$bosch-ic-notification-off-bold: "\f030a";
$bosch-ic-laptop-checklist-education: "\f030b";
$bosch-ic-truck-small: "\f030c";
$bosch-ic-fork-lift-loading: "\f030d";
$bosch-ic-truck-large: "\f030e";
$bosch-ic-fork-lift-big1: "\f030f";
$bosch-ic-delivery-man: "\f0310";
$bosch-ic-hand-rocking: "\f0311";
$bosch-ic-brush-wall: "\f0312";
$bosch-ic-drywall: "\f0313";
$bosch-ic-l-boxx-large-options: "\f0314";
$bosch-ic-l-boxx-large-roof: "\f0315";
$bosch-ic-skyscraper-wrench: "\f0316";
$bosch-ic-industry-angle-grinder: "\f0317";
$bosch-ic-user-angle-grinder: "\f0318";
$bosch-ic-workbench-saw-blade: "\f0319";
$bosch-ic-pipe-water: "\f031a";
$bosch-ic-crane-roof-truss: "\f031b";
$bosch-ic-helmet-construction: "\f031c";
$bosch-ic-vehicle-diagnostic-kts: "\f031d";
$bosch-ic-skyscraper-emoji-happy: "\f031e";
$bosch-ic-vehicle-parts-delivery: "\f031f";
$bosch-ic-fork-lift-stacking: "\f0320";
$bosch-ic-box-refresh: "\f0321";
$bosch-ic-excavator-arm-stones: "\f0322";
$bosch-ic-helmet-construction-plan: "\f0323";
$bosch-ic-metal: "\f0324";
$bosch-ic-document-bi: "\f0325";
$bosch-ic-plasmacluster: "\f0326";
$bosch-ic-coanda-effect-arrows: "\f0327";
$bosch-ic-attention-drop: "\f0328";
$bosch-ic-appliance-temperature-56-degrees: "\f0329";
$bosch-ic-follow-me-remote: "\f032a";
$bosch-ic-first-aid-cross-check: "\f032b";
$bosch-ic-drop-check: "\f032c";
$bosch-ic-outdoor-ear-off: "\f032d";
$bosch-ic-multi-space-mode: "\f032e";
$bosch-ic-ultra-wide-air-flow: "\f032f";
$bosch-ic-ionizer: "\f0330";
$bosch-ic-mode-wind-avoid-user: "\f0331";
$bosch-ic-mode-intelligent-eye: "\f0332";
$bosch-ic-flash-restart: "\f0333";
$bosch-ic-mode-dry: "\f0334";
$bosch-ic-mode-spot-air: "\f0335";
$bosch-ic-user-sensor-movment-30-min: "\f0336";
$bosch-ic-user-sensor-movment-60-min: "\f0337";
$bosch-ic-ambient-cooling-low-ice: "\f0338";
$bosch-ic-appliance-thermostat-10-degrees: "\f0339";
$bosch-ic-appliance-thermostat-8-degrees: "\f033a";
$bosch-ic-screwdriver-voltage-tester-flash: "\f033b";
$bosch-ic-user-mechanic: "\f033c";
$bosch-ic-business-training: "\f033d";
$bosch-ic-category-management: "\f033e";
$bosch-ic-cooperation-partners: "\f033f";
$bosch-ic-e-learning: "\f0340";
$bosch-ic-desktop-online-booking: "\f0341";
$bosch-ic-workshop-leads: "\f0342";
$bosch-ic-fleet-managment: "\f0343";
$bosch-ic-anti-corrosion: "\f0344";
$bosch-ic-workshop-car: "\f0345";
$bosch-ic-workshop-consultant: "\f0346";
$bosch-ic-technical-training-at-vehicle: "\f0347";
$bosch-ic-cloud-digital-ecosystem: "\f0348";
$bosch-ic-smartphone-vehicle-reception-app: "\f0349";
$bosch-ic-air-gland-nozzle: "\f034a";
$bosch-ic-pump-technical-symbol: "\f034b";
$bosch-ic-valve-technical-symbol: "\f034c";
$bosch-ic-mode-gear-changer: "\f034d";
$bosch-ic-box-error: "\f034e";
$bosch-ic-proving-ground-location-boxberg: "\f034f";
$bosch-ic-usb-c: "\f0350";
$bosch-ic-desktop-share-cancel-cross: "\f0351";
$bosch-ic-desktop-share-start-arrow: "\f0352";
$bosch-ic-knife-cutting: "\f0353";
$bosch-ic-globe-cross: "\f0354";
$bosch-ic-box-archive: "\f0355";
$bosch-ic-air-gentle-wind: "\f0356";
$bosch-ic-air-condition-radar: "\f0357";
$bosch-ic-autobod-box: "\f0358";
$bosch-ic-autobod-fork-lift: "\f0359";
$bosch-ic-autobod-dolly: "\f035a";
$bosch-ic-autobod-tugger: "\f035b";
$bosch-ic-autobod-underrider: "\f035c";
$bosch-ic-dbc1: "\f035d";
$bosch-ic-pcb: "\f035e";
$bosch-ic-ltcc: "\f035f";
$bosch-ic-electric-motor-uvw: "\f0360";
$bosch-ic-coil-schemes: "\f0361";
$bosch-ic-electric-motor-flash: "\f0362";
$bosch-ic-obd2-port: "\f0363";
$bosch-ic-adas: "\f0364";
$bosch-ic-module-safety: "\f0365";
$bosch-ic-acs: "\f0366";
$bosch-ic-cloud-certificate-checkmark: "\f0367";
$bosch-ic-belt-timing-belt: "\f0368";
$bosch-ic-exhaust-gas-treatment-diesel-engine: "\f0369";
$bosch-ic-document-service-schedule: "\f036a";
$bosch-ic-laptop-user-remote: "\f036b";
$bosch-ic-structure-add: "\f036c";
$bosch-ic-firepanel-wrench: "\f036d";
$bosch-ic-belt-auxiliary-drive: "\f036e";
$bosch-ic-search-questionmark: "\f036f";
$bosch-ic-inspection-magnifier: "\f0370";
$bosch-ic-clipboard-health-connected: "\f0371";
$bosch-ic-autobod-dolly-arrow-down: "\f0372";
$bosch-ic-autobod-dolly-arrow-up: "\f0373";
$bosch-ic-autobod-dolly-arrow-up-down: "\f0374";
$bosch-ic-autobod-underrider-arrow-down: "\f0375";
$bosch-ic-autobod-underrider-arrow-up: "\f0376";
$bosch-ic-autobod-underrider-arrow-up-down: "\f0377";
$bosch-ic-keyboard-delete-large: "\f0378";
$bosch-ic-keyboard-eject-large: "\f0379";
$bosch-ic-keyboard-shift-large: "\f037a";
$bosch-ic-diagonals: "\f037b";
$bosch-ic-blade-putty: "\f037c";
$bosch-ic-bbm-domain-adas: "\f037d";
$bosch-ic-bbm-domain-application-software: "\f037e";
$bosch-ic-bbm-domain-compute: "\f037f";
$bosch-ic-bbm-domain-energy: "\f0380";
$bosch-ic-bbm-domain-motion: "\f0381";
$bosch-ic-bbm-domain-service-software: "\f0382";
$bosch-ic-desktop-spark-cursor-wom: "\f0383";
$bosch-ic-cloud-car-wrench-wsms: "\f0384";
$bosch-ic-globe-language: "\f0385";
$bosch-ic-device-smart-service-key: "\f0386";
$bosch-ic-car-seat-baby: "\f0387";
$bosch-ic-car-seat-baby-connected: "\f0388";
$bosch-ic-car-seat-baby-front: "\f0389";
$bosch-ic-car-seat-baby-front-connected: "\f038a";
$bosch-ic-square-bend-45-degree-arrow: "\f038b";
$bosch-ic-hacksaw: "\f038c";
$bosch-ic-object-room: "\f038d";
$bosch-ic-object-wall: "\f038e";
$bosch-ic-objects-generic: "\f038f";
$bosch-ic-tape-lock: "\f0390";
$bosch-ic-connection-mslot: "\f0391";
$bosch-ic-solder-heat: "\f0392";
$bosch-ic-bracket-holder: "\f0393";
$bosch-ic-welding-laser-transmission: "\f0394";
$bosch-ic-magnetic-interface: "\f0395";
$bosch-ic-components-lug-down: "\f0396";
$bosch-ic-components-lug-up: "\f0397";
$bosch-ic-speed-nut: "\f0398";
$bosch-ic-desktop-tablet-smartphone: "\f0399";
$bosch-ic-tablet-orientation-rotate: "\f039a";
$bosch-ic-touch-gesture-move: "\f039b";
$bosch-ic-touch-gesture-pinch-in: "\f039c";
$bosch-ic-touch-gesture-pinch-out: "\f039d";
$bosch-ic-touch-gesture-rotate: "\f039e";
$bosch-ic-touch-gesture-scroll-horizontal: "\f039f";
$bosch-ic-touch-gesture-scroll-vertical: "\f03a0";
$bosch-ic-touch-gesture-swipe-down: "\f03a1";
$bosch-ic-touch-gesture-swipe-left: "\f03a2";
$bosch-ic-touch-gesture-swipe-right: "\f03a3";
$bosch-ic-touch-gesture-swipe-up: "\f03a4";
$bosch-ic-touch-gesture-tap-double: "\f03a5";
$bosch-ic-touch-gesture-tap-hold: "\f03a6";
$bosch-ic-touch-gesture-tap-single: "\f03a7";
$bosch-ic-reset-lock: "\f03a8";
$bosch-ic-truck-large-arrow-turn-left: "\f03a9";
$bosch-ic-truck-large-distance-arrows: "\f03aa";
$bosch-ic-petrol-station-diesel: "\f03ab";
$bosch-ic-lng: "\f03ac";
$bosch-ic-adr: "\f03ad";
$bosch-ic-rhombus-turn-arrow-right: "\f03ae";
$bosch-ic-document-fwr: "\f03af";
$bosch-ic-document-view: "\f03b0";
$bosch-ic-refresh-lock: "\f03b1";
$bosch-ic-e-charging-station-ice: "\f03b2";
$bosch-ic-language-en: "\f03b3";
$bosch-ic-temperature-checkmark: "\f03b4";
$bosch-ic-list-view-add: "\f03b5";
$bosch-ic-opening-generic: "\f03b6";
$bosch-ic-passage: "\f03b7";
$bosch-ic-molecule-propan: "\f03b8";
$bosch-ic-locator-multiple: "\f03b9";
$bosch-ic-bucket-paint: "\f03ba";
$bosch-ic-rat-mouse: "\f03bb";
$bosch-ic-power-point: "\f03bc";
$bosch-ic-power-point-slide-set: "\f03bd";
$bosch-ic-binoculars: "\f03be";
$bosch-ic-tube-hp-tim: "\f03bf";
$bosch-ic-system-in-package: "\f03c0";
$bosch-ic-nupsis: "\f03c1";
$bosch-ic-connection-rf-sideview: "\f03c2";
$bosch-ic-connection-mslot-sideview: "\f03c3";
$bosch-ic-power-point-slide: "\f03c4";
$bosch-ic-lung-simple: "\f03c5";
$bosch-ic-battery-car-48v: "\f03c6";
$bosch-ic-bbm-domain-infotainment: "\f03c7";
$bosch-ic-bbm-domain-body-and-comfort: "\f03c8";
$bosch-ic-reset-gear: "\f03c9";
$bosch-ic-drop-leaf: "\f03ca";
$bosch-ic-petrol-station-leaf: "\f03cb";
$bosch-ic-steps-co2: "\f03cc";
$bosch-ic-car-map-locator-cloud: "\f03cd";
$bosch-ic-battery-tool: "\f03ce";
$bosch-ic-bluetooth-search: "\f03cf";
$bosch-ic-drill-driver-cordless-check: "\f03d0";
$bosch-ic-drill-driver-cordless-reset: "\f03d1";
$bosch-ic-scan-drill-driver-cordless: "\f03d2";
$bosch-ic-scan-suitcase: "\f03d3";
$bosch-ic-id-label-qr-code: "\f03d4";
$bosch-ic-id-label-check: "\f03d5";
$bosch-ic-charger-plug-flash: "\f03d6";
$bosch-ic-storage-tank-charging-system: "\f03d7";
$bosch-ic-storage-tank-monovalent: "\f03d8";
$bosch-ic-storage-tank-series-connection: "\f03d9";
$bosch-ic-buffer-tank-fresh-water-station: "\f03da";
$bosch-ic-storage-tank-bivalent: "\f03db";
$bosch-ic-search-graph: "\f03dc";
$bosch-ic-desktop-consumer-data: "\f03dd";
$bosch-ic-door-smartphone: "\f03de";
$bosch-ic-desktop-dashboard-rps: "\f03df";
$bosch-ic-camera-fingerprint-check: "\f03e0";
$bosch-ic-desktop-chart-line-arrow: "\f03e1";
$bosch-ic-chart-tcfc: "\f03e2";
$bosch-ic-gas-valve-off: "\f03e3";
$bosch-ic-gas-valve-on: "\f03e4";
$bosch-ic-light-on: "\f03e5";
$bosch-ic-light-off: "\f03e6";
$bosch-ic-light-medium: "\f03e7";
$bosch-ic-throttle-decelerate: "\f03e8";
$bosch-ic-throttle-accelerate: "\f03e9";
$bosch-ic-speedometer-arrow: "\f03ea";
$bosch-ic-scooter-moving-user-disabled-bold: "\f03eb";
$bosch-ic-scooter-moving-user-disabled-light: "\f03ec";
$bosch-ic-motorcycle-sport-rear-wheel-arrow: "\f03ed";
$bosch-ic-motorcycle-sport-hill-brake-disk: "\f03ee";
$bosch-ic-mountains-abs: "\f03ef";
$bosch-ic-brake-lever-clutch-level-hand: "\f03f0";
$bosch-ic-scooter-moving-flash: "\f03f1";
$bosch-ic-throttle-hand: "\f03f2";
$bosch-ic-motorcycle-forward-collision-warning: "\f03f3";
$bosch-ic-motorcycle-electric-traction-control: "\f03f4";
$bosch-ic-motorcycle-cornering-drag-torque-control: "\f03f5";
$bosch-ic-motorcycle-blind-spot: "\f03f6";
$bosch-ic-motorcycle-cornering-traction-control: "\f03f7";
$bosch-ic-motorcycle-sport-ecbs-brake-distribution: "\f03f8";
$bosch-ic-lock-torque-closed: "\f03f9";
$bosch-ic-smartphone-pin-off: "\f03fa";
$bosch-ic-hand-vibration: "\f03fb";
$bosch-ic-feather: "\f03fc";
$bosch-ic-smartphone-pin-check: "\f03fd";
$bosch-ic-bicycle-e-2-displays: "\f03fe";
$bosch-ic-bicycle-e-4a-charger: "\f03ff";
$bosch-ic-bicycle-e-600-watt: "\f0400";
$bosch-ic-bicycle-e-alarm: "\f0401";
$bosch-ic-bicycle-e-auto-downshift: "\f0402";
$bosch-ic-bicycle-e-auto-mode: "\f0403";
$bosch-ic-bicycle-e-backpedal: "\f0404";
$bosch-ic-bicycle-e-battery: "\f0405";
$bosch-ic-bicycle-e-cargo-mode: "\f0406";
$bosch-ic-bicycle-e-cruise-25kmh: "\f0407";
$bosch-ic-bicycle-e-display: "\f0408";
$bosch-ic-bicycle-e-displaysize: "\f0409";
$bosch-ic-bicycle-e-eco-mode: "\f040a";
$bosch-ic-bicycle-e-emtb-mode: "\f040b";
$bosch-ic-bicycle-e-extended-boost: "\f040c";
$bosch-ic-bicycle-e-gearshift: "\f040d";
$bosch-ic-bicycle-e-kiox300: "\f040e";
$bosch-ic-bicycle-e-wireless-speed-sensor: "\f040f";
$bosch-ic-bicycle-e-weight-61lbs: "\f0410";
$bosch-ic-bicycle-e-weight-45lbs: "\f0411";
$bosch-ic-bicycle-e-weight-2kg: "\f0412";
$bosch-ic-bicycle-e-weight-275kg: "\f0413";
$bosch-ic-bicycle-e-weight-29kg: "\f0414";
$bosch-ic-bicycle-e-turbo-mode: "\f0415";
$bosch-ic-bicycle-e-tour-mode: "\f0416";
$bosch-ic-bicycle-e-torque-85nm: "\f0417";
$bosch-ic-bicycle-e-torque-75nm: "\f0418";
$bosch-ic-bicycle-e-torque-65nm: "\f0419";
$bosch-ic-bicycle-e-torque-55nm: "\f041a";
$bosch-ic-bicycle-e-torque-50nm: "\f041b";
$bosch-ic-bicycle-e-torque-40nm: "\f041c";
$bosch-ic-bicycle-e-support-340-percent: "\f041d";
$bosch-ic-bicycle-e-sprint-mode: "\f041e";
$bosch-ic-bicycle-e-sport-mode: "\f041f";
$bosch-ic-bicycle-e-speed-45kmh: "\f0420";
$bosch-ic-bicycle-e-speed-28mph: "\f0421";
$bosch-ic-bicycle-e-smartphone-key: "\f0422";
$bosch-ic-bicycle-e-smartphone-display: "\f0423";
$bosch-ic-bicycle-e-sharing-riding-data: "\f0424";
$bosch-ic-bicycle-e-safety-handling: "\f0425";
$bosch-ic-bicycle-e-protect: "\f0426";
$bosch-ic-bicycle-e-power-to-weight-ratio: "\f0427";
$bosch-ic-bicycle-e-navigation-personal-route-planning: "\f0428";
$bosch-ic-bicycle-e-navigation-app-route-planning: "\f0429";
$bosch-ic-bicycle-e-emtb: "\f042a";
$bosch-ic-bicycle-e-easy-handling: "\f042b";
$bosch-ic-bicycle-e-ambitious-assistants: "\f042c";
$bosch-ic-bicycle-e-connectmodule: "\f042d";
$bosch-ic-bicycle-e-display-key: "\f042e";
$bosch-ic-bicycle-e-abs-control-unit: "\f042f";
$bosch-ic-temperature-stable: "\f0430";
$bosch-ic-smartphone-graph-analysis: "\f0431";
$bosch-ic-bicycle-e-tour-plus-mode: "\f0432";
$bosch-ic-handover-leaf-industry: "\f0433";
$bosch-ic-map5000-2: "\f0434";
$bosch-ic-bicycle-e-design: "\f0437";
$bosch-ic-bicycle-e-sharing-personal-data: "\f0438";
$bosch-ic-text-bar-arrow-down: "\f0439";
$bosch-ic-text-area: "\f043a";
$bosch-ic-battery-4-check: "\f043b";
$bosch-ic-battery-2-check: "\f043c";
$bosch-ic-hourglass-expiry-date-cartridge: "\f043d";
$bosch-ic-search-user: "\f043e";
$bosch-ic-calendar-reservation: "\f043f";
$bosch-ic-heart-flash: "\f0440";
$bosch-ic-livechat-questionmark: "\f0441";
$bosch-ic-building-gear-warning: "\f0442";
$bosch-ic-home-roof-flat-arrow: "\f0443";
$bosch-ic-home-roof-flat: "\f0444";
$bosch-ic-fire-leaf-light-half: "\f0445";
$bosch-ic-back-menu-disabled-off-light: "\f0446";
$bosch-ic-book-add: "\f0447";
$bosch-ic-box-archive-add: "\f0448";
$bosch-ic-camera-add: "\f0449";
$bosch-ic-clock-add: "\f044a";
$bosch-ic-crown: "\f044b";
$bosch-ic-three-dots-circle-add: "\f044c";
$bosch-ic-three-dots-circle: "\f044d";
$bosch-ic-text-collection: "\f044e";
$bosch-ic-text-add: "\f044f";
$bosch-ic-receipt: "\f0450";
$bosch-ic-four-dots-circle: "\f0451";
$bosch-ic-format-paragraph: "\f0452";
$bosch-ic-user-worker: "\f0453";
$bosch-ic-heatpump-add: "\f0454";
$bosch-ic-heatpump-boiler-add: "\f0455";
$bosch-ic-car-steering-wheel: "\f0456";
$bosch-ic-commercial-vehicle-steering-wheel: "\f0457";
$bosch-ic-brake-disk-steering-wheel: "\f0458";
$bosch-ic-speedlimit-60: "\f0459";
$bosch-ic-sign-warning-gradient-10-percent: "\f045a";
$bosch-ic-sign-curve-s: "\f045b";
$bosch-ic-sign-cars-traffic-jam: "\f045c";
$bosch-ic-bicycle-e-powertube: "\f045d";
$bosch-ic-bicycle-e-race-mode: "\f045e";
$bosch-ic-bicycle-e-off-mode: "\f045f";
$bosch-ic-bicycle-e-streetsign: "\f0460";
$bosch-ic-bicycle-e-limit-mode: "\f0461";
$bosch-ic-fire-pre-alarm: "\f0462";
$bosch-ic-book-text-page: "\f0463";
$bosch-ic-battery-car-sli: "\f0464";
$bosch-ic-battery-car-gel: "\f0465";
$bosch-ic-battery-car-open: "\f0466";
$bosch-ic-battery-car-li-ion-2: "\f0467";
$bosch-ic-wooden-pallet-box: "\f0468";
$bosch-ic-wooden-pallet-staked: "\f0469";
$bosch-ic-wooden-pallet: "\f046a";
$bosch-ic-wooden-pallet-packed-full: "\f046b";
$bosch-ic-bicycle-e-eco-plus-mode: "\f046c";
$bosch-ic-bicycle-e-support-400-percent: "\f046d";
$bosch-ic-bicycle-e-weight-1-6kg: "\f046e";
$bosch-ic-bicycle-e-weight-3-65kg: "\f0470";
$bosch-ic-bicycle-e-weight-35lbs: "\f0471";
$bosch-ic-bicycle-e-weight-3kg: "\f0473";
$bosch-ic-bicycle-e-weight-4-3kg: "\f0474";
$bosch-ic-bicycle-e-weight-66lbs: "\f0476";
$bosch-ic-bicycle-e-weight-8lbs: "\f0477";
$bosch-ic-bicycle-e-weight-95lbs: "\f0478";
$bosch-ic-compass-needle: "\f0479";
$bosch-ic-map-pitch: "\f047a";
$bosch-ic-podium-winner-trophy: "\f047b";
$bosch-ic-chart-competition-user: "\f047c";
$bosch-ic-clipboard-add: "\f047d";
$bosch-ic-user-workpackage: "\f047e";
$bosch-ic-arrow-ignore-plan-data: "\f047f";
$bosch-ic-number-1-regular-square: "\f0480";
$bosch-ic-number-2-regular-square: "\f0481";
$bosch-ic-number-0-regular-circle-black: "\f0482";
$bosch-ic-manage-project-overview: "\f0483";
$bosch-ic-manage-project: "\f0484";
$bosch-ic-milestone: "\f0485";
$bosch-ic-ms-project-plan: "\f0486";
$bosch-ic-plan-outline-checkout: "\f0487";
$bosch-ic-plan-outline: "\f0488";
$bosch-ic-reporting: "\f0489";
$bosch-ic-user-fields-tracking-key-figure: "\f048a";
$bosch-ic-ms-project-plan-checkout: "\f048b";
$bosch-ic-user-workpackage-group: "\f048c";
$bosch-ic-car-dollar: "\f048d";
$bosch-ic-badge-3-year-warranty-superhappy: "\f048e";
$bosch-ic-badge-5-year-warranty-superhappy: "\f048f";
$bosch-ic-key: "\f0490";
$bosch-ic-data-fabric: "\f0491";
$bosch-ic-ontologies: "\f0492";
$bosch-ic-battery-car-3-badge-flash: "\f0493";
$bosch-ic-diode: "\f0494";
$bosch-ic-parachute-user: "\f0495";
$bosch-ic-emergency-exit-user-run: "\f0496";
$bosch-ic-user-standing-laptop: "\f0497";
$bosch-ic-user-sitting-laptop: "\f0498";
$bosch-ic-speachbubble-user: "\f0499";
$bosch-ic-smartphone-ar-diorama: "\f049a";
$bosch-ic-bicycle-e-hillstart: "\f049b";
$bosch-ic-heatpump-horizontal: "\f049c";
$bosch-ic-heatpump-vertical: "\f049d";
$bosch-ic-skyscraper-camera: "\f049e";
$bosch-ic-skyscraper-flame: "\f049f";
$bosch-ic-fire-detector-check: "\f04a0";
$bosch-ic-fire-detector-add: "\f04a1";
$bosch-ic-remote-desktop-fire-panel: "\f04a2";
$bosch-ic-gear-check: "\f04a3";
$bosch-ic-remote-desktop-fire-panel-add: "\f04a4";
$bosch-ic-skyscraper-building-gpt: "\f04a5";
$bosch-ic-checkmark-frame-info: "\f04a6";
$bosch-ic-plug-socket: "\f04a7";
$bosch-ic-chair-user-sitting-check: "\f04a8";
$bosch-ic-tent-event: "\f04a9";
$bosch-ic-hall-event-user: "\f04aa";
$bosch-ic-ear-noises: "\f04ab";
$bosch-ic-warning-brake-judder: "\f04ac";
$bosch-ic-flower-bloom: "\f04ad";
$bosch-ic-chair-user-running: "\f04ae";
$bosch-ic-fuse: "\f04af";
$bosch-ic-vc0: "\f04b0";
$bosch-ic-chart-line-coin-euro: "\f04b1";
$bosch-ic-chart-line-coin-dollar: "\f04b2";
$bosch-ic-clock-warning: "\f04b3";
$bosch-ic-spark-plug-change: "\f04b4";
$bosch-ic-cup-filled: "\f04b5";
$bosch-ic-volume-noise-increase-arrow-up: "\f04b6";
$bosch-ic-volume-reduction-arrow-down: "\f04b7";
$bosch-ic-keyboard-caps-lock-large: "\f04b8";
$bosch-ic-low-loss-header: "\f04b9";
$bosch-ic-bicycle-e-mounting-position-purion-400: "\f04ba";
$bosch-ic-skyscraper-digital-twin: "\f04bb";
$bosch-ic-robot-onboarding-copilot-body: "\f04bc";
$bosch-ic-robot-onboarding-copilot: "\f04bd";
$bosch-ic-plug-ethernet: "\f04be";
$bosch-ic-battery-car-internal-resistance: "\f04bf";
$bosch-ic-multimeter: "\f04c0";
$bosch-ic-spark-plug-recycle: "\f04c1";
$bosch-ic-battery-car-truck: "\f04c2";
$bosch-ic-battery-car-new: "\f04c3";
$bosch-ic-voltage-current-va: "\f04c4";
$bosch-ic-rasp: "\f04c5";
$bosch-ic-car-top-sensor-inside: "\f04c6";
$bosch-ic-car-sensor-inside: "\f04c7";
$bosch-ic-car-rollover: "\f04c8";
$bosch-ic-engine-misfires: "\f04c9";
$bosch-ic-car-starting-difficulties: "\f04ca";
$bosch-ic-document-arrow-side-down: "\f04cb";
$bosch-ic-wiper-flatblade-reuse: "\f04cc";
