/*!
  *  Copyright (C) 2017 Robert Bosch GmbH Copyright (C) 2017 Robert Bosch Manufacturing Solutions GmbH, Germany. All rights reserved.
 */

.sub-header {
  @include make-sub-header;

  .sub-header-element {
    @include make-sub-header-element;
  }
}
