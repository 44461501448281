/*!
  *  Copyright (C) 2017 Robert Bosch GmbH Copyright (C) 2017 Robert Bosch Manufacturing Solutions GmbH, Germany. All rights reserved.
 */

//-------------------------------------------------------------------------------------------------------------- NAVIGATION BUTTONS
@mixin make-pagination-button-previous($font-size) {
  &::after {
    @include make-icon($Bosch-Ic-arrow-expand-left, $font-size);
  }
}

@mixin make-pagination-button-next($font-size) {
  &::after {
    @include make-icon($Bosch-Ic-arrow-expand-right, $font-size);
  }
}

//-------------------------------------------------------------------------------------------------------------- BASIC ELEMENT STYLES
@mixin reset-pagination-element-pre-defined-styles {
  margin: 0;
  padding: 0;
  line-height: $line-height-base;
  color: $font-color;
  border: none;
  background: none;
  text-decoration: none;
}

@mixin make-pagination-element($base-size, $font-size) {
  cursor: pointer;
  font-size: $font-size;
  display: inline-block;
  min-width: $base-size;
  width: $base-size;
  height: $base-size;
  line-height: $base-size;
  border-radius: 50%;
  text-align: center;
  margin-right: 8px;
  color: $boschBlack;
  background-color: transparent;

  &:first-child,
  &:last-child {
    height: $grid-size * 3;
    line-height: 1;
    width: $grid-size * 3;
    min-width: $grid-size * 3;
    color: $pagination-icon-default-color;
    background-color: transparent;

    &:hover {
      color: $pagination-icon-hover-color;
    }

    &:active {
      color: $pagination-icon-pressed-color;
    }

    &:disabled,
    &.disabled {
      color: $pagination-icon-disabled-color;
      cursor: default;
    }
  }

  &:first-child {
    @include make-pagination-button-previous(24px);
  }

  &:last-child {
    @include make-pagination-button-next(24px);
    margin-right: 0px;
  }
}

//-------------------------------------------------------------------------------------------------------------- ELEMENT STATES

@mixin make-pagination-element-selected($base-size) {
  color: $pagination-selected-font-color;
  background-color: $pagination-selected-bg-color;
  cursor: default;

  &:hover {
    background-color: $pagination-selected-hover-bg-color;
  }

  &:active {
    background-color: $pagination-selected-pressed-bg-color;
  }

  &:disabled,
  &.disabled {
    background-color: $pagination-selected-disabled-bg-color;
    color: $pagination-selected-disabled-font-color;
  }
}

@mixin make-pagination-element-hover {
  background-color: $pagination-hover-bg-color;

  * {
    background-color: $pagination-hover-bg-color;
  }
}

@mixin make-pagination-element-active {
  background-color: $pagination-pressed-bg-color;

  * {
    background-color: $pagination-pressed-bg-color;
  }
}

@mixin make-pagination-element-disabled {
  cursor: default;
  color: $pagination-disabled-font-color;
  background-color: $pagination-disabled-bg-color;

  * {
    cursor: default;
    color: $pagination-disabled-font-color;
    background-color: $pagination-disabled-bg-color;
  }
}

@mixin make-pagination-element-wide($base-size, $font-size) {
  width: fit-content;
  max-width: fit-content;
  border-radius: 25px;
  padding: 0 $grid-size;
}

@mixin make-pagination-ellipsis {
  color: $boschBlack;
  cursor: default;

  &:hover {
    color: $boschBlack;
    background-color: transparent;
  }
}

//-------------------------------------------------------------------------------------------------------------- PAGINATION TYPES
@mixin make-pagination(
  $base-size: $pagination-medium-size-base,
  $font-size: $pagination-medium-font-size
) {
  *,
  *:hover,
  *:active,
  *:focus {
    @include reset-pagination-element-pre-defined-styles;
  }

  > *,
  > *:hover,
  > *:active,
  > *:focus {
    @include make-pagination-element($base-size, $font-size);
  }

  > *:hover {
    @include make-pagination-element-hover;
  }

  > *:active {
    @include make-pagination-element-active;
  }

  > *:not(:last-child):not(:first-child):disabled,
  *:not(:last-child):not(:first-child).disabled {
    @include make-pagination-element-disabled;
  }

  > *:first-child:disabled,
  *:last-child:disabled *:first-child.disabled,
  *:last-child.disabled {
    color: $pagination-icon-disabled-color;
    background-color: transparent;
    cursor: default;
  }

  > *.selected {
    @include make-pagination-element-selected($base-size);
  }

  > *.wide {
    @include make-pagination-element-wide($base-size, $font-size);
  }

  > *.ellipsis {
    @include make-pagination-ellipsis;
  }

  > *.mobile {
    display: none;
  }
}

@mixin make-pagination-small {
  @include make-pagination(
    $pagination-small-size-base,
    $pagination-small-font-size
  );
}

@mixin make-pagination-medium {
  @include make-pagination(
    $pagination-medium-size-base,
    $pagination-medium-font-size
  );
}

@mixin make-pagination-large {
  @include make-pagination(
    $pagination-large-size-base,
    $pagination-large-font-size
  );
}

@mixin make-pagination-mobile(
  $base-size: $pagination-medium-size-base,
  $font-size: $pagination-medium-font-size
) {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  justify-content: space-between;

  *,
  *:hover,
  *:active,
  *:focus {
    @include reset-pagination-element-pre-defined-styles;
  }

  > *,
  > *:hover,
  > *:active,
  > *:focus {
    @include make-pagination-element($base-size, $font-size);
    display: none;
  }

  > *:disabled,
  *.disabled {
    @include make-pagination-element-disabled;
  }

  > *.wide {
    @include make-pagination-element-wide($base-size, $font-size);
  }

  > *.mobile,
  *:first-child,
  *:last-child {
    display: inline;
  }
}
