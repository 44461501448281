/*!
  * Copyright (C) 2017 Robert Bosch GmbH Copyright (C) 2017 Robert Bosch Manufacturing Solutions GmbH, Germany. All rights reserved.
 */

@import '@bci-web-core/common-styles/sass/bci-theme';
@import '@bci-web-core/common-styles/sass/bci/mixins/icons';

.mat-icon.mat-icon {
  &.Bosch-Ic {
    @include make-icon-container(24px);
  }
}
