/*!
  *  Copyright (C) 2019 Robert Bosch GmbH Copyright (C) 2019 Robert Bosch Manufacturing Solutions GmbH, Germany. All rights reserved.
 */

@mixin make-callout-base {
  padding: $grid-size * 0.5 $grid-size * 2;
  display: flex;
  align-items: center;

  a {
    cursor: pointer;
    text-decoration: underline;
  }
}

@mixin make-callout-variant($bgColor, $color) {
  background-color: $bgColor;
  color: $color;

  * {
    color: $color;
  }

  a,
  a:hover {
    color: darken($color, $callout-link-darkening-factor) !important;
  }
}

@mixin make-callout-success {
  @include make-callout-variant($callout-success-bg, $callout-success-text);
}
@mixin make-callout-info {
  @include make-callout-variant($callout-info-bg, $callout-info-text);
}
@mixin make-callout-warn {
  @include make-callout-variant($callout-warning-bg, $callout-warning-text);
}
@mixin make-callout-danger {
  @include make-callout-variant($callout-danger-bg, $callout-danger-text);
}

@mixin make-callout-content {
  @include make-font($font-size-base, $font-weight-regular);
  text-overflow: clip;
  white-space: normal;
  overflow-x: auto;
  overflow-y: hidden;
}

@mixin make-callout-icon {
  @extend .bosch-ic;
  align-self: flex-start;
  margin-right: 8px;

  &:before {
    font-size: $icon-font-size-medium;
  }
}

@mixin make-callout-close {
  @extend .bosch-ic;
  @extend .bosch-ic-abort;

  font-size: $icon-font-size-medium;
  margin-left: auto;
  align-self: flex-start;
  cursor: pointer;

  &:hover {
    color: black;
  }
}
