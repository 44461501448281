/*!
  *  Copyright (C) 2017 Robert Bosch GmbH Copyright (C) 2017 Robert Bosch Manufacturing Solutions GmbH, Germany. All rights reserved.
 */

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  @include make-font;
  color: $headings-color;
  font-weight: $headings-font-weight;

  small,
  .small {
    line-height: 1;
    color: $boschGray80;
  }
}

h1,
.h1,
h2,
.h2,
h3,
.h3 {
  margin-top: $line-height-computed * 2;
  margin-bottom: ($line-height-computed * 0.5);

  small,
  .small {
    font-size: 65%;
  }
}
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  margin-top: ($line-height-computed * 0.5);
  margin-bottom: ($line-height-computed * 0.5);

  small,
  .small {
    font-size: 75%;
  }
}

@mixin make-responsive-header(
  $font-size-mobile,
  $font-size-tablet,
  $font-size-desktop
) {
  font-size: $font-size-mobile;
  line-height: floor($font-size-mobile * 1.4 * 0.5) * 2;

  @media only screen and (min-width: $screen-sm-min) {
    font-size: $font-size-tablet;
    line-height: floor($font-size-tablet * 1.3 * 0.5) * 2;
  }
  @media only screen and (min-width: $screen-lg-min) {
    font-size: $font-size-desktop;
    line-height: floor($font-size-desktop * 1.3 * 0.5) * 2;
  }
}

h1,
.h1 {
  @include make-responsive-header(
    $font-size-h1-mobile,
    $font-size-h1-tablet,
    $font-size-h1
  );
  margin: 32px 0px 24px 0px;
}

h2,
.h2 {
  @include make-responsive-header(
    $font-size-h2-mobile,
    $font-size-h2-tablet,
    $font-size-h2
  );
  margin: 24px 0px 24px 0px;
}

h3,
.h3 {
  @include make-responsive-header(
    $font-size-h3-mobile,
    $font-size-h3-tablet,
    $font-size-h3
  );
  margin: 24px 0px 16px 0px;
}

h4,
.h4 {
  @include make-responsive-header(
    $font-size-h4-mobile,
    $font-size-h4-tablet,
    $font-size-h4
  );
  margin: 24px 0px 16px 0px;
}

h5,
.h5 {
  @include make-responsive-header(
    $font-size-h5-mobile,
    $font-size-h5-tablet,
    $font-size-h5
  );
  margin: 24px 0px 16px 0px;
}

h6,
.h6 {
  @include make-responsive-header(
    $font-size-h6-mobile,
    $font-size-h6-tablet,
    $font-size-h6
  );
  margin: 24px 0px 16px 0px;
}
