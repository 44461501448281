/*!
  *  Copyright (C) 2018 Robert Bosch GmbH Copyright (C) 2018 Robert Bosch Manufacturing Solutions GmbH, Germany. All rights reserved.
 */

@import "../bci-variables";

@mixin make-backdrop() {
  background: $modal-backdrop-dim-color;
}

@mixin make-backdrop-blurred() {
  filter: blur($modal-backdrop-blur-radius);
}

@mixin make-backdrop-unblurred() {
  transition: filter 0.3s;
  filter: blur(0);
}
