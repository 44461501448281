/*!
  *  Copyright (C) 2017 Robert Bosch GmbH Copyright (C) 2017 Robert Bosch Manufacturing Solutions GmbH, Germany. All rights reserved.
 */

// Table Rows

@function getStateSelector($state) {
  @if ($state == '') {
    @return '';
  }
  @return '.#{$state}';
}

@mixin table-row-variant($state) {
  // Exact selectors below required to override `.table-striped` and prevent
  // inheritance to nested tables.

  $stateSelector: getStateSelector($state);

  > thead > tr,
  > tbody > tr,
  > tfoot > tr {
    > td#{$stateSelector},
    > th#{$stateSelector},
    &#{$stateSelector} > td,
    &#{$stateSelector} > th {
      @content
    }
  }
}

@mixin table-row-variant-hover($state) {
  // Exact selectors below required to override `.table-striped` and prevent
  // inheritance to nested tables.

  $stateSelector: getStateSelector($state);

  // Note: this is not available for cells or rows within `thead` or `tfoot`.
  > tbody > tr {
    > td#{$stateSelector}:hover,
    > th#{$stateSelector}:hover,
    &#{$stateSelector}:hover > td,
    &#{$stateSelector}:hover > th {
      @content
    }
  }
}

@mixin table-row-variant-stripes($state) {
  // Exact selectors below required to override `.table-striped` and prevent
  // inheritance to nested tables.

  $stateSelector: getStateSelector($state);

  > thead > tr,
  > tbody > tr,
  > tfoot > tr {
    > td#{$stateSelector}:nth-of-type(even),
    &#{$stateSelector} > td:nth-of-type(even) {
      @content
    }
  }
}

@mixin table-row-variant-stripes-hover($state) {
  // Exact selectors below required to override `.table-striped` and prevent
  // inheritance to nested tables.

  $stateSelector: getStateSelector($state);

  // Note: this is not available for cells or rows within `thead` or `tfoot`.
  > tbody > tr {
    > td#{$stateSelector}:nth-of-type(even):hover,
    &#{$stateSelector}:hover > td:nth-of-type(even) {
      @content
    }
  }
}


@mixin table-row-variant-stripes-columns($state) {
  // Exact selectors below required to override `.table-striped` and prevent
  // inheritance to nested tables.

  $stateSelector: getStateSelector($state);

  > tbody > tr:nth-of-type(even) {
    > td#{$stateSelector},
    &#{$stateSelector} > td {
      @content
    }
  }
}

@mixin table-row-variant-stripes-columns-hover($state) {
  // Exact selectors below required to override `.table-striped` and prevent
  // inheritance to nested tables.

  $stateSelector: getStateSelector($state);

  // Note: this is not available for cells or rows within `thead` or `tfoot`.
  > tbody > tr {
    > td#{$stateSelector}:hover,
    &#{$stateSelector}:hover > td {
      @content
    }
  }
}

@mixin row-with-indicator($border-color: $boschBlue50) {
  position: relative;

  &:after {
    content: '';
    display: block;
    width: 6px;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    background-color: $border-color;
  }
}
