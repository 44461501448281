/*!
  * Copyright (C) 2017 Robert Bosch GmbH Copyright (C) 2017 Robert Bosch Manufacturing Solutions GmbH, Germany. All rights reserved.
 */

@import '@bci-web-core/common-styles/sass/bci/bci-variables';
@import '@bci-web-core/common-styles/sass/bci/mixins/typography';

// reset max and min height and width to overwrite angular default and apply bci default
@mixin reset-width-height() {
  max-width: none;
  max-height: 100vh;
  min-width: unset;
  min-height: unset;
}

mat-dialog-container.mat-mdc-dialog-container {
  @include reset-width-height();

  .mdc-dialog__container {
    @include reset-width-height();

    .mat-mdc-dialog-surface {
      background-color: $boschWhite;
      box-shadow: none;
      position: relative;
      border-radius: 0;
      padding: $grid-size * 3 $grid-size * 4 $grid-size * 4 $grid-size * 4;

      .dialog-header,
      .dialog-additional-header {
        .close-button {
          line-height: 1;
          top: 0;
          right: 0;

          .mat-button-wrapper :last-child:not(.mat-icon) {
            margin: 0;
          }
        }
      }

      .dialog-header {
        .close-button {
          position: absolute;
        }
      }

      .dialog-additional-header {
        .close-button {
          margin-left: auto;
        }
      }

      mat-dialog-content.mat-mdc-dialog-content,
      .mdc-dialog__content {
        margin: 0 0;
        padding: 0 0;
        color: $boschBlack;
        @include make-font-regular($font-size-base);
      }
    }
  }
}

//VP1
@media screen and (max-width: $screen-xs-min) {
  mat-dialog-container.mat-mdc-dialog-container {
    .mdc-dialog__container {
      .mat-mdc-dialog-surface {
        padding: 2 * $grid-size;
        overflow-x: hidden;

        .dialog-header {
          margin: 8px 0 $grid-size * 2 0;

          h1,
          h2,
          h3,
          h4 {
            @include make-font-regular(16px);
            font-weight: $font-weight-strong;
          }
        }

        mat-dialog-content.mat-mdc-dialog-content,
        .mdc-dialog__content {
          max-height: none;
          overflow: unset;
        }

        mat-dialog-actions.mat-mdc-dialog-actions,
        .mdc-dialog__actions {
          width: 100%;
          padding-bottom: 2 * $grid-size;
          margin-bottom: -16px;

          button {
            width: 100%;
            justify-content: center;

            &.mdc-button,
            &.mdc-button--raised {
              margin-left: 0;

              &:not(:first-child) {
                margin-top: $grid-size * 2;
              }
            }
          }
        }
      }
    }
  }
}

// VP2-3
@media screen and (min-width: ($screen-xs-min + 1)) {
  mat-dialog-container.mat-mdc-dialog-container {
    .mdc-dialog__container {
      .mat-mdc-dialog-surface {
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.25) !important;

        .dialog-header {
          margin: 0 0 $grid-size * 2 0;

          h1,
          h2,
          h3,
          h4 {
            @include make-font-regular(20px);
            font-weight: $font-weight-strong;
          }
        }

        mat-dialog-content.mat-mdc-dialog-content,
        .mdc-dialog__content {
          margin: 0 -32px;
          padding: 0 32px;
        }

        mat-dialog-actions.mat-mdc-dialog-actions,
        .mdc-dialog__actions {
          padding: 2 * $grid-size 0 4 * $grid-size 0;
          margin-bottom: -$grid-size * 4;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: flex-end;

          .mdc-button,
          .mat-button--raised {
            margin-left: $grid-size * 2;
          }

          // hide actions if there is nothing inside
          &:empty {
            display: none;
          }
        }
      }
    }
  }
}
