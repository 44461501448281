/*!
  * Copyright (C) 2017 Robert Bosch GmbH Copyright (C) 2017 Robert Bosch Manufacturing Solutions GmbH, Germany. All rights reserved.
 */

@import '@bci-web-core/common-styles/sass/bci/bci-variables';
@import '@bci-web-core/common-styles/sass/bci/mixins/borders';

@mixin mat-card-focus() {
  &[focus] {
    background-color: $boschBlue90;
  }
}

@mixin mat-card-with-horizontal-border($border-color: $boschBlue50) {
  @include mat-card-without-border();
  padding: 7px 16px 7px 10px;
  border-left: 6px solid $border-color !important;
}

@mixin mat-card-with-border($border-color: $boschBlue50) {
  @include mat-card-without-border();
  padding: 10px 16px 16px 16px;
  border-top: 6px solid $border-color !important;
}

@mixin mat-card-without-border() {
  box-shadow: none !important;
  background-color: $boschGray95;
  padding: 16px;

  @include make-border(0px, transparent);
  @include mat-card-focus();

  .mat-mdc-card-title {
    font-size: 20px;
    line-height: 30px;
    font-weight: $font-weight-strong;
    margin: 0px !important;
  }
}

@mixin mat-card-disabled() {
  @include mat-card-without-border();

  &:hover {
    background: $boschGray95;
    cursor: default;
  }

  &:active {
    background: $boschGray95;
    cursor: default;
  }

  &[focus] {
    background-color: $boschGray95;
  }

  * {
    color: $boschGray75 !important;
  }
}

@mixin mat-card-disabled-with-border() {
  @include mat-card-with-border($boschGray85);
}

@mixin mat-card-horizontal-disabled-with-border() {
  @include mat-card-with-horizontal-border($boschGray85);
}

mat-card.mat-mdc-card.mdc-card {
  @include mat-card-without-border();

  .mat-mdc-card-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

// vertical card borders

mat-card.mat-mdc-card.mdc-card.mat-card-border {
  @include mat-card-without-border();
  padding-top: 16px;
}

mat-card.mat-mdc-card.mdc-card.mat-card-border-blue {
  @include mat-card-with-border();
}

mat-card.mat-mdc-card.mdc-card.mat-card-border-green {
  @include mat-card-with-border($boschGreen50);
}

mat-card.mat-mdc-card.mdc-card.mat-card-border-yellow {
  @include mat-card-with-border($boschYellow85);
}

mat-card.mat-mdc-card.mdc-card.mat-card-border-red {
  @include mat-card-with-border($boschRed50);
}

mat-card.mat-mdc-card.mdc-card.mat-card-border-neutral {
  @include mat-card-with-border($boschGray55);
}

// horizontal card borders

mat-card.mat-mdc-card.mdc-card.mat-card-horizontal-border {
  @include mat-card-without-border();
  padding: 7px 16px 7px 16px;
}

mat-card.mat-mdc-card.mdc-card.mat-card-horizontal-border-blue {
  @include mat-card-with-horizontal-border();
}

mat-card.mat-mdc-card.mdc-card.mat-card-horizontal-border-green {
  @include mat-card-with-horizontal-border($boschGreen50);
}

mat-card.mat-mdc-card.mdc-card.mat-card-horizontal-border-yellow {
  @include mat-card-with-horizontal-border($boschYellow85);
}

mat-card.mat-mdc-card.mdc-card.mat-card-horizontal-border-red {
  @include mat-card-with-horizontal-border($boschRed50);
}

mat-card.mat-mdc-card.mdc-card.mat-card-horizontal-border-neutral {
  @include mat-card-with-horizontal-border($boschGray55);
}

mat-card.mat-mdc-card.mdc-card.mat-card-hover-pressed {
  &:hover {
    background: $boschGray85;
    cursor: pointer;
  }

  &:active {
    background: $boschGray75;
    cursor: pointer;
  }
}

// disabled horizontal

mat-card.mat-mdc-card.mdc-card.mat-card-disabled {
  @include mat-card-disabled();
  padding: 16px;
}

mat-card.mat-mdc-card.mdc-card.mat-card-disabled-with-color-border {
  @include mat-card-disabled();
  padding-top: 10px;
}

// disabled horizontal

mat-card.mat-mdc-card.mdc-card.mat-card-horizontal-disabled {
  @include mat-card-disabled();
  padding: 7px 16px 7px 16px;
}

mat-card.mat-mdc-card.mdc-card.mat-card-horizontal-disabled-with-color-border {
  @include mat-card-disabled();
  padding: 7px 16px 7px 10px;
}

mat-card.mat-mdc-card.mdc-card.mat-card-horizontal-disabled-with-border {
  @include mat-card-horizontal-disabled-with-border();
  padding: 7px 16px 7px 10px;

  &[focus] {
    background-color: $boschGray95 !important;
  }
}

mat-card.mat-mdc-card.mdc-card.mat-card-disabled-with-border {
  @include mat-card-disabled-with-border();
  padding: 10px 16px 16px 16px;

  &[focus] {
    background-color: $boschGray95 !important;
  }
}
