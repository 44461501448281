/*!
  *  Copyright (C) 2024 Robert Bosch GmbH Copyright (C) 2017 Robert Bosch Manufacturing Solutions GmbH, Germany. All rights reserved.
 */

@mixin make-color-circle($color: $boschGray55, $size: $grid-size * 2, $margin: 0) {
  &::before{
    content: '';
    display: inline-block;
    position: relative;
    vertical-align: middle;
    transform: translateY(-2px);
    width: $size;
    height: $size;
    margin: $margin;
    border-radius: 50%;
    background-color: $color;
  }
}
