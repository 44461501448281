/*!
  *  Copyright (C) 2017 Robert Bosch GmbH Copyright (C) 2017 Robert Bosch Manufacturing Solutions GmbH, Germany. All rights reserved.
 */

@mixin make-indicator-overlay(
  $size: 72px,
  $animation-duration: 6s,
  $square-size: $grid-size * 3
) {
  z-index: 10000;
  width: $size;
  height: $size;
  transform: translate(-50%, -50%);
  margin: 0 auto;
  @content;

  &::before {
    content: " ";
    width: $square-size;
    height: $square-size;
    position: absolute;
    animation: top-right $animation-duration infinite;
  }

  &::after {
    content: " ";
    width: $square-size;
    height: $square-size;
    position: absolute;
    animation: bottom-left $animation-duration infinite;
  }

  @keyframes bottom-left {
    0% {
      background-color: transparent;
      left: $square-size;
      top: $square-size;
    }
    6.25% {
      background-color: $boschPurple30;
      left: $square-size;
      top: $square-size;
    }
    12.5% {
      background-color: $boschPurple30;
      left: $square-size;
      top: $square-size * 2;
    }
    18.75% {
      background-color: $boschRed50;
      left: $square-size;
      top: $square-size;
    }
    25% {
      background-color: $boschRed50;
      left: 0;
      top: $square-size;
    }
    31.25% {
      background-color: $boschGreen25;
      left: $square-size;
      top: $square-size;
    }
    37.5% {
      background-color: $boschGreen25;
      left: $square-size;
      top: $square-size * 2;
    }
    43.75% {
      background-color: $boschGreen50;
      left: $square-size;
      top: $square-size;
    }
    50% {
      background-color: $boschGreen50;
      left: 0;
      top: $square-size;
    }
    56.25% {
      background-color: $boschTurquoise50;
      left: $square-size;
      top: $square-size;
    }
    62.5% {
      background-color: $boschTurquoise50;
      left: $square-size;
      top: $square-size * 2;
    }
    68.75% {
      background-color: $boschBlue50;
      left: $square-size;
      top: $square-size;
    }
    75% {
      background-color: $boschBlue50;
      left: 0;
      top: $square-size;
    }
    81.25% {
      background-color: $boschBlue35;
      left: $square-size;
      top: $square-size;
    }
    87.5% {
      background-color: $boschBlue35;
      left: $square-size;
      top: $square-size * 2;
    }
    93.75% {
      background-color: $boschPurple50;
      left: $square-size;
      top: $square-size;
    }
    100% {
      background-color: transparent;
      left: $square-size;
      top: $square-size;
    }
  }

  @keyframes top-right {
    0% {
      background-color: transparent;
      left: $square-size;
      top: $square-size;
    }
    6.25% {
      background-color: $boschPurple30;
      left: $square-size;
      top: $square-size;
    }
    12.5% {
      background-color: $boschPurple50;
      left: $square-size;
      top: 0;
    }
    18.75% {
      background-color: $boschRed50;
      left: $square-size;
      top: $square-size;
    }
    25% {
      background-color: $boschPurple30;
      left: $square-size * 2;
      top: $square-size;
    }
    31.25% {
      background-color: $boschGreen25;
      left: $square-size;
      top: $square-size;
    }
    37.5% {
      background-color: $boschRed50;
      left: $square-size;
      top: 0;
    }
    43.75% {
      background-color: $boschGreen50;
      left: $square-size;
      top: $square-size;
    }
    50% {
      background-color: $boschGreen25;
      left: $square-size * 2;
      top: $square-size;
    }
    56.25% {
      background-color: $boschTurquoise50;
      left: $square-size;
      top: $square-size;
    }
    62.5% {
      background-color: $boschGreen50;
      left: $square-size;
      top: 0;
    }
    68.75% {
      background-color: $boschBlue50;
      left: $square-size;
      top: $square-size;
    }
    75% {
      background-color: $boschTurquoise50;
      left: $square-size * 2;
      top: $square-size;
    }
    81.25% {
      background-color: $boschBlue35;
      left: $square-size;
      top: $square-size;
    }
    87.5% {
      background-color: $boschBlue50;
      left: $square-size;
      top: 0;
    }
    93.75% {
      background-color: $boschPurple50;
      left: $square-size;
      top: $square-size;
    }
    100% {
      background-color: transparent;
      left: $square-size;
      top: $square-size;
    }
  }
}
