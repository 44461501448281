/*!
* Copyright (C) 2017 Robert Bosch GmbH Copyright (C) 2017 Robert Bosch Manufacturing Solutions GmbH, Germany. All rights reserved.
*/
@use '@angular/material' as mat;

@import 'palette';
@import 'components';

// @todo: import these global styles into one scss file..
@import '@bci-web-core/common-styles/sass/bci/styles/header';
@import '@bci-web-core/common-styles/sass/bci/styles/labels';

@import '@bci-web-core/common-styles/sass/bci-theme';
@import '@bci-web-core/common-styles/sass/bci/styles/typography';
@import '@bci-web-core/common-styles/sass/bci/styles/headings';
@import '@bci-web-core/common-styles/sass/bci/styles/breadcrumb';
@import '@bci-web-core/common-styles/sass/bci/styles/icons';

@include mat.core();

// BCI theme
$bci-app-primary: mat.m2-define-palette($mat-bci);
$bci-app-accent: mat.m2-define-palette($mat-bci-light-blue, A200, A100, A400);
$bci-app-warn: $boschSignalRed;

// Define bci typography config
$bci-typography: mat.m2-define-typography-config(
  $font-family: $font-family-base,
  $headline-1: mat.m2-define-typography-level($font-size-h1, 52px, 400),
  $headline-2: mat.m2-define-typography-level($font-size-h2, 40px, 400),
  $headline-3: mat.m2-define-typography-level($font-size-h3, 32px, 400),
  $headline-4: mat.m2-define-typography-level($font-size-h4, 28px, 400),
  $headline-5: mat.m2-define-typography-level($font-size-h5, 24px, 400),
  $headline-6: mat.m2-define-typography-level($font-size-h6, 20px, 400),
  $subtitle-1: mat.m2-define-typography-level(28px, 1.2, 400),
  $subtitle-2: mat.m2-define-typography-level(32px, 1.2, 400),
  $body-2: mat.m2-define-typography-level($font-size-base, 24px, 400),
  $body-1: mat.m2-define-typography-level($font-size-base, 24px, 400),
  $caption: mat.m2-define-typography-level($font-size-h3, 32px, 400),
  $button: mat.m2-define-typography-level($font-size-base, 48px, 400),
  $overline: null,
);

@include mat.all-component-typographies($bci-typography);

@include mat.typography-hierarchy($bci-typography);

$bci-app-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $bci-app-primary,
      accent: $bci-app-accent,
    ),
    typography: $bci-typography,
  )
);

//$bci-app-theme: (foreground mat-palette($mat-bci-theme-foreground));
//append($bci-app-theme, )

@include mat.all-component-themes($bci-app-theme);
