/*!
  *  Copyright (C) 2017 Robert Bosch GmbH Copyright (C) 2017 Robert Bosch Manufacturing Solutions GmbH, Germany. All rights reserved.
 */

@import "../mixins/icons";

.bosch-ic,
.Bosch-Ic {
  @include make-icon-container();
}

.bosch-ic-large,
.Bosch-Ic-Large {
  @include make-icon-container($icon-font-size-large);
}

.bosch-ic-medium,
.Bosch-Ic-Medium {
  @include make-icon-container($icon-font-size-medium);
}

.bosch-ic-normal,
.Bosch-Ic-Normal {
  @include make-icon-container($icon-font-size-normal);
}

.bosch-ic-small,
.Bosch-Ic-Small {
  @include make-icon-container($icon-font-size-small);
}

.Bosch-Ic-Disabled,
.bosch-ic-disabled,
.Bosch-Ic-Disabled:hover,
.bosch-ic-disabled:hover,
.Bosch-Ic[disabled],
.bosch-ic[disabled],
.Bosch-Ic[disabled]:hover,
.bosch-ic[disabled]:hover {
  cursor: not-allowed;
  color: $boschGray85;

  &:hover {
    color: $boschGray85;
  }
}

.bosch-ic-clickable,
.Bosch-Ic-Clickable {
  cursor: pointer;

  &:disabled,
  &.disabled {
    color: $boschGray70;
    cursor: not-allowed;
  }
}

.bosch-ic-activated,
.Bosch-Ic-Activated {
  color: $boschBlue50;
}

// deprecated, to be removed in v9
.bosch-ic-forklift-stacking:before {
  content: $bosch-ic-fork-lift-stacking;
}
// deprecated, to be removed in v9
.bosch-ic-air-condition-horizontal:before {
  content: $bosch-ic-air-condition-horizontal-swing;
}
// deprecated, to be removed in v9
.bosch-ic-auracast:before {
  content: $bosch-ic-auracast-product;
}
// deprecated, to be removed in v9
.bosch-ic-auracast-frame:before {
  content: $bosch-ic-auracast-product;
}
// deprecated, to be removed in v9
.bosch-ic-forklift:before {
  content: $bosch-ic-fork-lift-big1;
}
// deprecated, to be removed in v9
.bosch-ic-forklift-loading:before {
  content: $bosch-ic-fork-lift-loading;
}

@import "icons_list";

@import "icons_legacy";
