/*!
  *  Copyright (C) 2017 Robert Bosch GmbH Copyright (C) 2017 Robert Bosch Manufacturing Solutions GmbH, Germany. All rights reserved.
 */

@font-face {
  font-family: $font-family-base;
  font-weight: 400;
  src: url("#{$path-to-assets}/fonts/BoschSans-Regular.eot"); /* IE9 Compat Modes */
  src: url("#{$path-to-assets}/fonts/BoschSans-Regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("#{$path-to-assets}/fonts/BoschSans-Regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("#{$path-to-assets}/fonts/BoschSans-Regular.woff") format("woff"),
    /* Pretty Modern Browsers */
      url("#{$path-to-assets}/fonts/BoschSans-Regular.ttf") format("truetype"),
    /* Safari, Android, iOS */
      url("#{$path-to-assets}/fonts/BoschSans-RegularItalic.woff2")
      format("woff2"),
    /* Italic - Super Modern Browsers */
      url("#{$path-to-assets}/fonts/BoschSans-RegularItalic.woff")
      format("woff"),
    /* Italic - Pretty Modern Browsers */
      url("#{$path-to-assets}/fonts/BoschSans-RegularItalic.ttf")
      format("truetype"),
    /* Italic - Safari, Android, iOS */
      url("#{$path-to-assets}/fonts/BoschSans-Regular.svg#svgFontName")
      format("svg"); /* Legacy iOS */
}

@font-face {
  font-family: $font-family-base;
  font-weight: 300;
  src: url("#{$path-to-assets}/fonts/BoschSans-Light.eot"); /* IE9 Compat Modes */
  src: url("#{$path-to-assets}/fonts/BoschSans-Light.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("#{$path-to-assets}/fonts/BoschSans-Light.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("#{$path-to-assets}/fonts/BoschSans-Light.woff") format("woff"),
    /* Pretty Modern Browsers */
      url("#{$path-to-assets}/fonts/BoschSans-Light.ttf") format("truetype"),
    /* Safari, Android, iOS */
      url("#{$path-to-assets}/fonts/BoschSans-LightItalic.woff2")
      format("woff2"),
    /* Italic - Super Modern Browsers */
      url("#{$path-to-assets}/fonts/BoschSans-LightItalic.woff") format("woff"),
    /* Italic - Pretty Modern Browsers */
      url("#{$path-to-assets}/fonts/BoschSans-LightItalic.ttf")
      format("truetype"),
    /* Italic - Safari, Android, iOS */
      url("#{$path-to-assets}/fonts/BoschSans-Light.svg#svgFontName")
      format("svg"); /* Legacy iOS */
}

@font-face {
  font-family: $font-family-base;
  font-weight: 500;
  src: url("#{$path-to-assets}/fonts/BoschSans-Medium.eot"); /* IE9 Compat Modes */
  src: url("#{$path-to-assets}/fonts/BoschSans-Medium.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("#{$path-to-assets}/fonts/BoschSans-Medium.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("#{$path-to-assets}/fonts/BoschSans-Medium.woff") format("woff"),
    /* Pretty Modern Browsers */
      url("#{$path-to-assets}/fonts/BoschSans-Medium.ttf") format("truetype"),
    /* Safari, Android, iOS */
      url("#{$path-to-assets}/fonts/BoschSans-MediumItalic.woff2")
      format("woff2"),
    /* Italic - Super Modern Browsers */
      url("#{$path-to-assets}/fonts/BoschSans-MediumItalic.woff") format("woff"),
    /* Italic - Pretty Modern Browsers */
      url("#{$path-to-assets}/fonts/BoschSans-MediumItalic.ttf")
      format("truetype"),
    /* Italic - Safari, Android, iOS */
      url("#{$path-to-assets}/fonts/BoschSans-Medium.svg#svgFontName")
      format("svg"); /* Legacy iOS */
}

@font-face {
  font-family: $font-family-base;
  font-weight: 700;
  src: url("#{$path-to-assets}/fonts/BoschSans-Bold.eot"); /* IE9 Compat Modes */
  src: url("#{$path-to-assets}/fonts/BoschSans-Bold.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("#{$path-to-assets}/fonts/BoschSans-Bold.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("#{$path-to-assets}/fonts/BoschSans-Bold.woff") format("woff"),
    /* Pretty Modern Browsers */
      url("#{$path-to-assets}/fonts/BoschSans-Bold.ttf") format("truetype"),
    /* Safari, Android, iOS */
      url("#{$path-to-assets}/fonts/BoschSans-BoldItalic.woff2") format("woff2"),
    /* Italic - Super Modern Browsers */
      url("#{$path-to-assets}/fonts/BoschSans-BoldItalic.woff") format("woff"),
    /* Italic - Pretty Modern Browsers */
      url("#{$path-to-assets}/fonts/BoschSans-BoldItalic.ttf")
      format("truetype"),
    /* Italic - Safari, Android, iOS */
      url("#{$path-to-assets}/fonts/BoschSans-Bold.svg#svgFontName")
      format("svg"); /* Legacy iOS */
}

@font-face {
  font-family: $font-family-base;
  font-weight: 900;
  src: url("#{$path-to-assets}/fonts/BoschSans-Black.eot"); /* IE9 Compat Modes */
  src: url("#{$path-to-assets}/fonts/BoschSans-Black.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("#{$path-to-assets}/fonts/BoschSans-Black.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("#{$path-to-assets}/fonts/BoschSans-Black.woff") format("woff"),
    /* Pretty Modern Browsers */
      url("#{$path-to-assets}/fonts/BoschSans-Black.ttf") format("truetype"),
    /* Safari, Android, iOS */
      url("#{$path-to-assets}/fonts/BoschSans-BlackItalic.woff2")
      format("woff2"),
    /* Italic - Super Modern Browsers */
      url("#{$path-to-assets}/fonts/BoschSans-BlackItalic.woff") format("woff"),
    /* Italic - Pretty Modern Browsers */
      url("#{$path-to-assets}/fonts/BoschSans-BlackItalic.ttf")
      format("truetype"),
    /* Italic - Safari, Android, iOS */
      url("#{$path-to-assets}/fonts/BoschSans-Black.svg#svgFontName")
      format("svg"); /* Legacy iOS */
}

@font-face {
  font-family: $font-family-icon-base;
  font-style: normal;
  font-stretch: normal;
  font-weight: normal;
  font-display: block;
  src: url("#{$path-to-assets}/fonts/Bosch-Icon.eot?mh5qa9");
  src: url("#{$path-to-assets}/fonts/Bosch-Icon.eot?mh5qa9#iefix") format("embedded-opentype"),
    url("#{$path-to-assets}/fonts/Bosch-Icon.ttf?mh5qa9") format("truetype"),
    url("#{$path-to-assets}/fonts/Bosch-Icon.woff?mh5qa9") format("woff"),
    url("#{$path-to-assets}/fonts/Bosch-Icon.svg?mh5qa9#Bosch-Icon") format("svg");
}

@font-face {
  font-family: $font-family-condensed;
  font-weight: $font-weight-light;
  src: url("#{$path-to-assets}/fonts/BoschSansCond-Regular.otf"),
    url("#{$path-to-assets}/fonts/BoschSansCondensed-Regular.ttf")
      format("truetype"),
    url("#{$path-to-assets}/fonts/BoschSansCondensed-RegularItalic.ttf")
      format("truetype");
}

@font-face {
  font-family: $font-family-condensed;
  font-weight: $font-weight-strong;
  src: url("#{$path-to-assets}/fonts/BoschSansCond-Bold.otf"),
    url("#{$path-to-assets}/fonts/BoschSansCondensed-Bold.ttf")
      format("truetype"),
    url("#{$path-to-assets}/fonts/BoschSansCondensed-BoldItalic.ttf")
      format("truetype");
}
