/*!
  *  Copyright (C) 2017 Robert Bosch GmbH Copyright (C) 2017 Robert Bosch Manufacturing Solutions GmbH, Germany. All rights reserved.
 */

.text-muted {
  @include make-text-muted;
}

.text-primary {
  @include make-text-primary;
}

.text-warning {
  @include make-text-warning;
}

.text-danger {
  @include make-text-danger;
}

.text-success {
  @include make-text-success;
}

.text-condensed {
  @include make-text-condensed;
}

.text-info {
  @include make-text-info;
}

.bg-success {
  @include make-bg-success;
}

a {
  @include make-anchor;
}

small,
.small {
  @include make-small-text;
}

strong,
.strong {
  @include make-font-bold;
}

// in bosch cd there is no italic
em,
.italic {
  @include make-font-regular;
}

// in bosch cd there is no bold italic
strong em,
em strong,
strong .italic,
.italic strong,
.italic .strong,
.strong .italic {
  @include make-font-bold;
}

abbr[data-original-title],
abbr[title] {
  @include make-addr-text;
}

p {
  line-height: $line-height-base;
}

.p-large {
  @include paragraph-text-large;
}
