/*!
  * Copyright (C) 2017 Robert Bosch GmbH Copyright (C) 2017 Robert Bosch Manufacturing Solutions GmbH, Germany. All rights reserved.
 */

@import '@bci-web-core/common-styles/sass/bci/bci-variables';
@import '@bci-web-core/common-styles/sass/bci/mixins/borders';

mat-option.mat-option,
mat-list-option.mat-mdc-list-option,
mat-checkbox.mat-mdc-checkbox {
  .mdc-form-field {
    .mdc-label {
      font-size: $font-size-base;
      line-height: $grid-size * 3;
      padding-left: $grid-size;
    }

    .mdc-checkbox {
      height: $checkbox-size;
      width: $checkbox-size;
      padding: 0px;
      display: flex;

      .mdc-checkbox__ripple,
      .mat-mdc-checkbox-ripple {
        border-radius: unset;
      }

      .mdc-checkbox__background {
        border: none;
        border-radius: 0px;
        height: 24px;
        width: 24px;

        .mdc-checkbox__checkmark {
          scale: 0.7;
        }

        .mdc-checkbox__mixedmark {
          scale: 0.7;
        }
      }

      &:hover,
      &:focus {
        .mdc-checkbox__native-control {
          &:not(:checked):not(:indeterminate) ~ .mdc-checkbox__background {
            background-color: $boschGray50;
          }

          &:checked,
          &:indeterminate {
            ~ .mdc-checkbox__background {
              background-color: $checkbox-background-color-hover;
            }
          }
        }
      }

      &:active {
        .mdc-checkbox__native-control {
          &:not(:checked):not(:indeterminate) ~ .mdc-checkbox__background {
            background-color: $boschGray40 !important;
          }

          &:checked,
          &:indeterminate {
            ~ .mdc-checkbox__background {
              background-color: $checkbox-background-color-active;
            }
          }
        }
      }

      .mdc-checkbox__native-control {
        ~ .mdc-checkbox__background {
          position: initial;
        }

        // standard colors
        &:not(:checked):not(:indeterminate) ~ .mdc-checkbox__background {
          background-color: $checkbox-background-color;
        }

        // diabled colors
        &[disabled] {
          &:not(:checked):not(:indeterminate) ~ .mdc-checkbox__background {
            background-color: $boschGray90;
          }

          &:checked,
          &:indeterminate {
            & ~ .mdc-checkbox__background {
              background-color: $boschGray80;

              .mdc-checkbox__checkmark {
                color: $checkbox-stroke-color-disabled;
              }
            }
          }
        }

        // indeterminate disabled stroke color and width
        &:disabled ~ .mdc-checkbox__background .mdc-checkbox__mixedmark {
          border-color: $boschGray60;
          border-width: 2px;
        }

        // indeterminate checked stroke width
        &:enabled ~ .mdc-checkbox__background .mdc-checkbox__mixedmark {
          border-width: 2px;
        }

        // checked colors
        &:checked,
        &:indeterminate {
          & ~ .mdc-checkbox__background {
            background-color: $checkbox-background-color-checked;
          }
        }
      }
    }
  }
}

// To Style checkbox according to new DDS inside the list, it has to be override the material theme default styles
.mat-mdc-list-option {
  mat-checkbox .mdc-checkbox {
    // prettier-ignore
    .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate='true']) ~ .mdc-checkbox__background {
      background-color: $checkbox-background-color !important;
    }

    .mdc-checkbox__native-control:checked,
    .mdc-checkbox__native-control:indeterminate ~ .mdc-checkbox__background {
      background-color: $checkbox-background-color-checked !important;
    }

    &:hover,
    &:focus {
      // prettier-ignore
      .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate='true']) ~ .mdc-checkbox__background {
        background-color: $boschGray50 !important;
      }

      .mdc-checkbox__native-control:checked,
      .mdc-checkbox__native-control:indeterminate ~ .mdc-checkbox__background {
        background-color: $checkbox-background-color-hover !important;
      }
    }

    &:active {
      // prettier-ignore
      .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate='true']) ~ .mdc-checkbox__background {
        background-color: $boschGray40 !important;
      }

      .mdc-checkbox__native-control:checked,
      .mdc-checkbox__native-control:indeterminate ~ .mdc-checkbox__background {
        background-color: $checkbox-background-color-active !important;
      }
    }
  }
}
