/*!
  *  Copyright (C) 2020 Robert Bosch GmbH Copyright (C) 2020 Robert Bosch Manufacturing Solutions GmbH, Germany. All rights reserved.
 */

.badge {
  @include make-badge;
}

.badge-hidden {
  @include make-badge-hidden;
}

.badge-warn {
  @include make-badge-warn;
}

.badge-accent {
  @include make-badge-accent;
}
