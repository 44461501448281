/*!
  *  Copyright (C) 2022 Robert Bosch GmbH Copyright (C) 2022 Robert Bosch Manufacturing Solutions GmbH, Germany. All rights reserved.
 */

ul:not(.check-list):not(.mdc-list) {
  margin: 0 0 1.5rem;
  padding-left: 2 * $grid-size;
  li {
    list-style: none;
    margin-bottom: $grid-size;
    padding-left: 4 * $grid-size;
    position: relative;
    font-size: $grid-size * 2;
    line-height: $grid-size * 3;
    font-weight: $font-weight-regular;
    margin-top: 0;
    overflow-wrap: break-word;

    &::before {
      content: "";
      position: absolute;
      display: inline-block;
      width: $grid-size;
      height: $grid-size;
      margin: $grid-size $grid-size * 2 $grid-size $grid-size;
      background-color: $boschBlack;
      left: 0;
    }
  }
}

ol {
  margin: 0 0 1.5rem;
  li {
    margin-bottom: $grid-size;
    padding-left: $grid-size;
    position: relative;
    font-size: $grid-size * 2;
    line-height: $grid-size * 3;
    font-weight: $font-weight-regular;
    overflow-wrap: break-word;
  }
  ::marker {
    left: 0;
    position: absolute;
    background-color: $boschBlack;
    font-weight: $font-weight-strong;
  }
}

ul.check-list {
  list-style-type: none;
  margin: 0 0 1.5rem;
  font-size: $grid-size * 2;
  line-height: $grid-size * 3;
  font-weight: $font-weight-regular;
  padding-left: $grid-size * 2;

  li {
    margin-bottom: $grid-size;
    position: relative;
    overflow-wrap: break-word;
    padding-left: 32px;
    &::before {
      @include make-icon($bosch-ic-checkmark-bold, $grid-size * 3);
      vertical-align: top;
      position: absolute;
      left: 0;
    }
  }
}
