/*!
  *  Copyright (C) 2023 Robert Bosch GmbH Copyright (C) 2023 Robert Bosch Manufacturing Solutions GmbH, Germany. All rights reserved.
 */

// Select

@mixin make-select-panel() {
  margin: 0;
  background: $dropdown-bg-color;
  outline: none;
  list-style-type: none;
  padding: 0 !important;
  overflow-y: auto;
  overflow-y: overlay;
}

@mixin make-select-option-base() {
  line-height: 1.5;
  padding: 12px 16px !important;
  cursor: pointer;
  display: flex;
  align-items: center;
  overflow: hidden;
  margin: 0 !important;

  &::before {
    content: none !important;
  }
}

@mixin make-select-option() {
  @include make-select-option-base();
  background-color: $dropdown-menu-not-selected-bg-color;
  color: $dropdown-menu-not-selected-color;

  &:hover {
    background-color: $dropdown-menu-not-selected-bg-color-hover;
  }
  &:active {
    background-color: $dropdown-menu-not-selected-bg-color-pressed;
  }
}

@mixin make-select-option-selected() {
  @include make-select-option-base();
  background-color: $dropdown-menu-selected-bg-color;
  color: $dropdown-menu-selected-color;
  &:hover {
    background-color: $dropdown-menu-selected-bg-color-hover;
  }

  &:active {
    background-color: $dropdown-menu-selected-bg-color-pressed;
  }
}

@mixin make-select-option-disabled() {
  background: $dropdown-menu-not-selected-bg-color;
  cursor: default;
  pointer-events: none;
  color: $dropdown-menu-disabled-color;
}

@mixin make-select-optiongroup-label() {
  font-weight: 700;
  font-size: 12px;
  line-height: 1.5;
  padding: 12px 16px 4px 16px;
  width: 100%;
  box-sizing: border-box;
  user-select: none;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;

  &.disabled,
  &:disabled {
    color: $dropdown-menu-disabled-color;
  }
}
