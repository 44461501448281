/*!
  *  Copyright (C) 2018 Robert Bosch GmbH Copyright (C) 2018 Robert Bosch Manufacturing Solutions GmbH, Germany. All rights reserved.
 */

@mixin make-resize-handle() {
  position: absolute;
  z-index: 10;
  right: -$grid-size*2;
  top: calc(50% - 12px);

  cursor: e-resize;

  @include make-border(1px, $boschLightGray50);
  @include border-radius(50%);
  background: $boschWhite;

  transform: rotate(90deg);

  @extend .Bosch-Ic;
  @extend .Bosch-Ic-Normal;
  @extend .Bosch-Ic-menu;

  width: $grid-size*4;
  height: $grid-size*4;
  line-height: $grid-size*4;
  text-align: center;
}
