/*!
  *  Copyright (C) 2018 Robert Bosch GmbH Copyright (C) 2018 Robert Bosch Manufacturing Solutions GmbH, Germany. All rights reserved.
 */

.chip {
  @include make-chip;

  .chip-icon {
    @include make-chip-icon;
  }

  &:not(.disabled) {
    height: $chip-height;
  }

  &.disabled {
    @include make-chip-disabled;
  }
}

.chip-list {
  @include make-chip-list;
}
