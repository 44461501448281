/*!
  *  Copyright (C) 2017 Robert Bosch GmbH Copyright (C) 2017 Robert Bosch Manufacturing Solutions GmbH, Germany. All rights reserved.
 */

.pagination {
  @include make-pagination-medium;
}

.pagination-sm {
  @include make-pagination-small;
}

.pagination-lg {
  @include make-pagination-large;
}
