/*!
  *  Copyright (C) 2017 Robert Bosch GmbH Copyright (C) 2017 Robert Bosch Manufacturing Solutions GmbH, Germany. All rights reserved.
 */

.bci-expand-height {
  @include make-expand-height();
}

.bci-expand-width {
  @include make-expand-width();
}
