/*!
  * Copyright (C) 2018 Robert Bosch GmbH Copyright (C) 2018 Robert Bosch Manufacturing Solutions GmbH, Germany. All rights reserved.
 */

@import '@bci-web-core/common-styles/sass/bci/bci-variables';

.mat-drawer-container,
.mat-drawer-content {
  background-color: $boschWhite;
  color: $font-color;
}
