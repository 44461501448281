/*!
  *  Copyright (C) 2019 Robert Bosch GmbH Copyright (C) 2019 Robert Bosch Manufacturing Solutions GmbH, Germany. All rights reserved.
 */

@import "bosch-ic";

$Bosch-IC-find-part: $bosch-ic-find-id;
$Bosch-IC-next-station: $bosch-ic-skip-forward-frame;
$Bosch-Ic-HMI: $bosch-ic-ui;
$Bosch-Ic-add-outline: $bosch-ic-add-frame;
$Bosch-Ic-apas: $bosch-ic-robot;
$Bosch-Ic-abort: $bosch-ic-close;
$Bosch-Ic-cash: $bosch-ic-cash-frame;
$Bosch-Ic-pinterest: $bosch-ic-pinterest-frame;
$Bosch-Ic-problem: $bosch-ic-problem-frame;
$Bosch-Ic-promotion: $bosch-ic-promotion-frame;
$Bosch-Ic-arrow-diagonal: $bosch-ic-arrow-right-up;
$Bosch-Ic-arrow-down-filled: $bosch-ic-arrow-down-frame;
$Bosch-Ic-arrow-down-circle: $bosch-ic-arrow-down-frame;
$Bosch-Ic-arrow-end-left: $bosch-ic-back-left-double;
$Bosch-Ic-arrow-end-right: $bosch-ic-forward-right-double;
$Bosch-Ic-arrow-left-down-filled: $bosch-ic-arrow-left-down-frame;
$Bosch-Ic-arrow-left-filled: $bosch-ic-arrow-left-frame;
$Bosch-Ic-arrow-left-up-filled: $bosch-ic-arrow-left-up-frame;
$Bosch-Ic-arrow-right-down-filled: $bosch-ic-arrow-right-down-frame;
$Bosch-Ic-arrow-right-filled: $bosch-ic-arrow-right-frame;
$Bosch-Ic-arrow-right-up-filled: $bosch-ic-arrow-right-up-frame;
$Bosch-Ic-arrow-up-filled: $bosch-ic-arrow-up-frame;
$Bosch-Ic-batch: $bosch-ic-batch;
$Bosch-Ic-calculator: $bosch-ic-calculate;
$Bosch-Ic-calendar-delete: $bosch-ic-calendar-remove;
$Bosch-Ic-call-bluetooth: $bosch-ic-call-bluetooth;
$Bosch-Ic-call-deny-outline: $bosch-ic-call-deny-frame;
$Bosch-Ic-call-deny: $bosch-ic-call-deny;
$Bosch-Ic-call-pick-up-outline: $bosch-ic-call-frame;
$Bosch-Ic-call-pick-up: $bosch-ic-call;
$Bosch-Ic-call-settings: $bosch-ic-call-settings;
$Bosch-Ic-clear-all-annotations: $bosch-ic-clear-all;
$Bosch-Ic-connection: $bosch-ic-connection-on;
$Bosch-Ic-core-data: $bosch-ic-core-data;
$Bosch-Ic-curve-scaling-view: $bosch-ic-window-analysis;
$Bosch-Ic-dashboard: $bosch-ic-grid-view;
$Bosch-Ic-delete-material-manually: $bosch-ic-material-remove-manually;
$Bosch-Ic-dmc-code: $bosch-ic-dmc-code;
$Bosch-Ic-document-to-svg: $bosch-ic-document-svg;
$Bosch-Ic-emergency-order: $bosch-ic-document-error;
$Bosch-Ic-fast-backwards: $bosch-ic-fast-backward-frame;
$Bosch-Ic-fast-forwards: $bosch-ic-fast-forward-frame;
$Bosch-Ic-favorite: $bosch-ic-wishlist;
$Bosch-Ic-go-to: $bosch-ic-login;
$Bosch-Ic-gps: $bosch-ic-locator-spot;
$Bosch-Ic-home-filled: $bosch-ic-home;
$Bosch-Ic-input: $bosch-ic-login;
$Bosch-Ic-interface: $bosch-ic-interface;
$Bosch-Ic-list: $bosch-ic-list-view-mobile;
$Bosch-Ic-malfunction-history: $bosch-ic-caution-sign-clock;
$Bosch-Ic-malfunction-order: $bosch-ic-caution-sign-list;
$Bosch-Ic-material-list: $bosch-ic-material-list;
$Bosch-Ic-material: $bosch-ic-material;
$Bosch-Ic-maximize-window: $bosch-ic-stop;
$Bosch-Ic-menu-tree: $bosch-ic-menu-checkmark;
$Bosch-Ic-minimize-window: $bosch-ic-less-minimize;
$Bosch-Ic-notification-hint: $bosch-ic-problem-frame;
$Bosch-Ic-notification-question: $bosch-ic-question-frame;
$Bosch-Ic-notification-info: $bosch-ic-alert-info;
$Bosch-Ic-notification-error: $bosch-ic-alert-error-filled;
$Bosch-Ic-notification-error-outline: $bosch-ic-alert-error;
$Bosch-Ic-notification-outline: $bosch-ic-alert-warning;
$Bosch-Ic-notification-success-outline: $bosch-ic-alert-success;
$Bosch-Ic-notification-success: $bosch-ic-alert-success-filled;
$Bosch-Ic-notification-warning: $bosch-ic-alert-warning-filled;
$Bosch-Ic-notification: $bosch-ic-notification;
$Bosch-Ic-output: $bosch-ic-logout;
$Bosch-Ic-paste: $bosch-ic-clipboard;
$Bosch-Ic-qr-code: $bosch-ic-qr-code;
$Bosch-Ic-remote-cursor: $bosch-ic-cursor-connected;
$Bosch-Ic-scale: $bosch-ic-externallink;
$Bosch-Ic-search-chart: $bosch-ic-bar-chart-search;
$Bosch-Ic-search-web: $bosch-ic-globe-search;
$Bosch-Ic-search-window: $bosch-ic-window-search;
$Bosch-Ic-shape-ellipse: $bosch-ic-shape-ellipse;
$Bosch-Ic-shape-rectangle: $bosch-ic-stop;
$Bosch-Ic-show-more-vertical: $bosch-ic-options;
$Bosch-Ic-signal: $bosch-ic-signal-full;
$Bosch-Ic-skip-back: $bosch-ic-skip-backward-frame;
$Bosch-Ic-skip-backward: $bosch-ic-skip-fast-backward-frame;
$Bosch-Ic-StarterKit: $bosch-ic-cube-filled;
$Bosch-Ic-skip-for: $bosch-ic-skip-forward-frame;
$Bosch-Ic-skip-forward: $bosch-ic-skip-fast-forward-frame;
$Bosch-Ic-sort-alphabetically: $bosch-ic-sort-alphabetically;
$Bosch-Ic-switch-language: $bosch-ic-globe;
$Bosch-Ic-snyc-disabled: $bosch-ic-arrows-diagonal-disabled-light;
$Bosch-Ic-sync-disabled: $bosch-ic-arrows-diagonal-disabled-light;
$Bosch-Ic-sync-problem: $bosch-ic-arrows-caution;
$Bosch-Ic-sync: $bosch-ic-refresh;
$Bosch-Ic-test: $bosch-ic-confirmation;
$Bosch-Ic-to-order: $bosch-ic-document-arrow-up;
$Bosch-Ic-to-raw: $bosch-ic-document-arrow-down;
$Bosch-Ic-transport-orders: $bosch-ic-clipboard-list;
$Bosch-Ic-unpin: $bosch-ic-unpin-classic-disabled-light;
$Bosch-Ic-user-filled: $bosch-ic-user;
$Bosch-Ic-video-play: $bosch-ic-play;
$Bosch-Ic-weblink: $bosch-ic-link;
$Bosch-Ic-warning-outline: $bosch-ic-alert-warning;
$Bosch-Ic-add-material-manually: $bosch-ic-material-add-manually;
$Bosch-Ic-add-material: $bosch-ic-material-add;
$Bosch-Ic-add: $bosch-ic-add;
$Bosch-Ic-adress-consumer: $bosch-ic-address-consumer-data;
$Bosch-Ic-adressbook: $bosch-ic-adress-book;
$Bosch-Ic-alarm: $bosch-ic-notification;
$Bosch-Ic-alert: $bosch-ic-whistle;
$Bosch-Ic-analysis: $bosch-ic-analysis;
$Bosch-Ic-anchor: $bosch-ic-anchor;
$Bosch-IC-3d-print: $bosch-ic-3d-print;
$Bosch-Ic-tours: $bosch-ic-a-to-b;
$Bosch-Ic-add: $bosch-ic-add;
$Bosch-Ic-adress-consumer: $bosch-ic-address-consumer-data;
$Bosch-Ic-adressbook: $bosch-ic-adress-book;
$Bosch-IC-agility: $bosch-ic-agility;
$Bosch-Ic-clock-stop: $bosch-ic-alarm-clock;
$Bosch-IC-ambulance: $bosch-ic-ambulance;
$Bosch-Ic-analysis: $bosch-ic-analysis;
$Bosch-Ic-anchor: $bosch-ic-anchor;
$Bosch-Ic-arrow-down: $bosch-ic-arrow-down;
$Bosch-Ic-arrow-left: $bosch-ic-arrow-left;
$Bosch-Ic-arrow-left-down: $bosch-ic-arrow-left-down;
$Bosch-Ic-arrow-left-up: $bosch-ic-arrow-left-up;
$Bosch-Ic-arrow-right: $bosch-ic-arrow-right;
$Bosch-Ic-arrow-right-down: $bosch-ic-arrow-right-down;
$Bosch-Ic-arrow-right-up: $bosch-ic-arrow-right-up;
$Bosch-IC-arrow-turn-left: $bosch-ic-arrow-turn-left;
$Bosch-IC-arrow-turn-right: $bosch-ic-arrow-turn-right;
$Bosch-Ic-arrow-up: $bosch-ic-arrow-up;
$Bosch-IC-arrows: $bosch-ic-arrows;
$Bosch-IC-arrows-x-y-z: $bosch-ic-arrows-x-y-z;
$Bosch-IC-artificial-intelligence: $bosch-ic-artificial-intelligence;
$Bosch-IC-assembly-line: $bosch-ic-assembly-line;
$Bosch-IC-atom: $bosch-ic-atom;
$Bosch-IC-augmented-reality: $bosch-ic-augmented-reality;
$Bosch-Ic-autobod: $bosch-ic-autobod;
$Bosch-Ic-arrow-expand-left: $bosch-ic-back-left;
$Bosch-Ic-arrow-expand-double-left: $bosch-ic-back-left-double;
$Bosch-IC-bar-chart: $bosch-ic-bar-chart;
$Bosch-IC-bar-chart-double: $bosch-ic-bar-chart-double;
$Bosch-Ic-battery-empty: $bosch-ic-battery_0;
$Bosch-Ic-battery-25: $bosch-ic-battery_1;
$Bosch-Ic-battery-50: $bosch-ic-battery_2;
$Bosch-Ic-battery-75: $bosch-ic-battery_3;
$Bosch-Ic-battery-100: $bosch-ic-battery_4;
$Bosch-Ic-battery-error: $bosch-ic-battery_fail;
$Bosch-IC-battery_Li-Ion: $bosch-ic-battery_li-ion;
$Bosch-Ic-battery-charging: $bosch-ic-battery_loading;
$Bosch-Ic-help: $bosch-ic-question-frame;
$Bosch-IC-bed: $bosch-ic-bed;
$Bosch-IC-bicycle: $bosch-ic-bicycle;
$Bosch-IC-bicycle-e: $bosch-ic-bicycle-e;
$Bosch-IC-blog: $bosch-ic-blog;
$Bosch-IC-bluetooth: $bosch-ic-bluetooth;
$Bosch-IC-bluetooth-LE: $bosch-ic-bluetooth-le;
$Bosch-IC-board-speaker: $bosch-ic-board-speaker;
$Bosch-IC-boiler-connected: $bosch-ic-boiler-connected;
$Bosch-IC-book: $bosch-ic-book;
$Bosch-Ic-bookmark: $bosch-ic-bookmark;
$Bosch-Ic-bookmark-add: $bosch-ic-bookmark-add;
$Bosch-Ic-bookmark-check: $bosch-ic-bookmark-check;
$Bosch-Ic-bookmark-delete: $bosch-ic-bookmark-delete;
$Bosch-IC-books: $bosch-ic-books;
$Bosch-IC-bookshelf: $bosch-ic-bookshelf;
$Bosch-Ic-box: $bosch-ic-box;
$Bosch-IC-box-closed: $bosch-ic-box-closed;
$Bosch-IC-box-delivery: $bosch-ic-box-delivery;
$Bosch-IC-box-delivery-connected: $bosch-ic-box-delivery-connected;
$Bosch-IC-brake-disk: $bosch-ic-brake-disk;
$Bosch-IC-briefcase: $bosch-ic-briefcase;
$Bosch-IC-broom: $bosch-ic-broom;
$Bosch-IC-broom-cloud-dirt: $bosch-ic-broom-cloud-dirt;
$Bosch-Ic-brush: $bosch-ic-brush;
$Bosch-Ic-bug: $bosch-ic-bug;
$Bosch-IC-building: $bosch-ic-building;
$Bosch-IC-cactus: $bosch-ic-cactus;
$Bosch-IC-calculate: $bosch-ic-calculate;
$Bosch-Ic-calendar: $bosch-ic-calendar;
$Bosch-Ic-calendar-add: $bosch-ic-calendar-add;
$Bosch-IC-calendar-settings: $bosch-ic-calendar-settings;
$Bosch-Ic-call: $bosch-ic-call;
$Bosch-IC-call-add: $bosch-ic-call-add;
$Bosch-IC-call-remove: $bosch-ic-call-remove;
$Bosch-IC-call-sos: $bosch-ic-call-sos;
$Bosch-IC-call-team: $bosch-ic-call-team;
$Bosch-Ic-call-wifi: $bosch-ic-call-wifi;
$Bosch-Ic-camera: $bosch-ic-camera;
$Bosch-Ic-camera-switch: $bosch-ic-camera-switch;
$Bosch-IC-car: $bosch-ic-car;
$Bosch-IC-car-clock: $bosch-ic-car-clock;
$Bosch-IC-car-connected: $bosch-ic-car-connected;
$Bosch-IC-car-locator: $bosch-ic-car-locator;
$Bosch-IC-car-mechanic: $bosch-ic-car-mechanic;
$Bosch-IC-car-rental: $bosch-ic-car-rental;
$Bosch-IC-car-seat-connected: $bosch-ic-car-seat-connected;
$Bosch-IC-car-side: $bosch-ic-car-side;
$Bosch-IC-car-side-user: $bosch-ic-car-side-user;
$Bosch-Ic-milkrun-cart: $bosch-ic-cart;
$Bosch-Ic-certificate: $bosch-ic-certificate;
$Bosch-IC-certificate-pki: $bosch-ic-certificate-pki;
$Bosch-IC-change: $bosch-ic-change;
$Bosch-Ic-chart: $bosch-ic-chart-bar;
$Bosch-IC-chart-check: $bosch-ic-chart-check;
$Bosch-IC-chart-dummy: $bosch-ic-chart-dummy;
$Bosch-Ic-diagram: $bosch-ic-chart-line;
$Bosch-Ic-chat: $bosch-ic-chat;
$Bosch-IC-chat-add: $bosch-ic-chat-add;
$Bosch-IC-chat-question-answer: $bosch-ic-chat-question-answer;
$Bosch-IC-chat-remove: $bosch-ic-chat-remove;
$Bosch-Ic-checklist: $bosch-ic-checklist;
$Bosch-Ic-checkmark: $bosch-ic-checkmark;
$Bosch-Ic-chip: $bosch-ic-chip;
$Bosch-IC-circle-segment: $bosch-ic-circle-segment;
$Bosch-IC-clear-all: $bosch-ic-clear-all;
$Bosch-Ic-clipboard: $bosch-ic-clipboard;
$Bosch-Ic-clipboard-list: $bosch-ic-clipboard-list;
$Bosch-Ic-clock: $bosch-ic-clock;
$Bosch-Ic-abort: $bosch-ic-close;
$Bosch-IC-close-all: $bosch-ic-close-all;
$Bosch-Ic-cloud: $bosch-ic-cloud;
$Bosch-IC-cloud-co2: $bosch-ic-cloud-co2;
$Bosch-Ic-cloud-download: $bosch-ic-cloud-download;
$Bosch-IC-cloud-nox: $bosch-ic-cloud-nox;
$Bosch-Ic-cloud-upload: $bosch-ic-cloud-upload;
$Bosch-IC-club-arm-chair: $bosch-ic-club-arm-chair;
$Bosch-IC-coffee-break: $bosch-ic-coffee-break;
$Bosch-IC-coin-currency: $bosch-ic-coin-currency;
$Bosch-Ic-currency: $bosch-ic-coin-dollar;
$Bosch-Ic-color-picker: $bosch-ic-colorpicker;
$Bosch-IC-colors: $bosch-ic-colors;
$Bosch-Ic-command: $bosch-ic-command;
$Bosch-IC-communicator: $bosch-ic-communicator;
$Bosch-Ic-compare: $bosch-ic-compare;
$Bosch-Ic-components: $bosch-ic-components;
$Bosch-Ic-components-available: $bosch-ic-components-available;
$Bosch-Ic-components-reservation: $bosch-ic-components-reservation;
$Bosch-IC-concierge-bell: $bosch-ic-concierge-bell;
$Bosch-IC-configuration: $bosch-ic-configuration;
$Bosch-Ic-confirmation: $bosch-ic-confirmation;
$Bosch-Ic-unconnected: $bosch-ic-connection-off;
$Bosch-Ic-connected: $bosch-ic-connection-on;
$Bosch-IC-connectivity: $bosch-ic-connectivity;
$Bosch-Ic-builder: $bosch-ic-construction;
$Bosch-IC-consumer-goods: $bosch-ic-consumer-goods;
$Bosch-IC-contract: $bosch-ic-contract;
$Bosch-Ic-copy: $bosch-ic-copy;
$Bosch-IC-cube: $bosch-ic-cube;
$Bosch-IC-cube-arrows: $bosch-ic-cube-arrows;
$Bosch-Ic-StarterKit: $bosch-ic-cube-filled;
$Bosch-IC-cube-shock: $bosch-ic-cube-shock;
$Bosch-IC-cube-stacked: $bosch-ic-cube-stacked;
$Bosch-Ic-customerservice: $bosch-ic-customerservice;
$Bosch-IC-cutlery: $bosch-ic-cutlery;
$Bosch-IC-damper: $bosch-ic-damper;
$Bosch-IC-danger-flash: $bosch-ic-danger-flash;
$Bosch-IC-data-loss: $bosch-ic-data-loss;
$Bosch-Ic-dealer: $bosch-ic-dealer-details;
$Bosch-Ic-delete: $bosch-ic-delete;
$Bosch-Ic-delivery: $bosch-ic-delivery;
$Bosch-IC-denied: $bosch-ic-denied;
$Bosch-Ic-desktop: $bosch-ic-desktop;
$Bosch-IC-desktop-application-2d: $bosch-ic-desktop-application-2d;
$Bosch-IC-desktop-application-3d: $bosch-ic-desktop-application-3d;
$Bosch-IC-development: $bosch-ic-development;
$Bosch-Ic-devices: $bosch-ic-devices;
$Bosch-IC-devices-mobile: $bosch-ic-devices-mobile;
$Bosch-Ic-directory: $bosch-ic-directory;
$Bosch-Ic-dna: $bosch-ic-dna;
$Bosch-Ic-do-not-disturb: $bosch-ic-do-not-disturb;
$Bosch-Ic-document: $bosch-ic-document;
$Bosch-Ic-document-add: $bosch-ic-document-add;
$Bosch-IC-document-arrow-down: $bosch-ic-document-arrow-down;
$Bosch-IC-document-arrow-up: $bosch-ic-document-arrow-up;
$Bosch-Ic-document-audio: $bosch-ic-document-audio;
$Bosch-IC-document-check: $bosch-ic-document-check;
$Bosch-Ic-document-to-cloud: $bosch-ic-document-cloud;
$Bosch-Ic-document-copy: $bosch-ic-document-copy;
$Bosch-Ic-document-delete: $bosch-ic-document-delete;
$Bosch-Ic-document-to-doc: $bosch-ic-document-doc;
$Bosch-Ic-document-edit: $bosch-ic-document-edit;
$Bosch-Ic-document-error: $bosch-ic-document-error;
$Bosch-Ic-document-locked: $bosch-ic-document-locked;
$Bosch-Ic-log-file: $bosch-ic-document-log;
$Bosch-Ic-document-to-one: $bosch-ic-document-one;
$Bosch-Ic-document-to-pdf: $bosch-ic-document-pdf;
$Bosch-Ic-document-plain: $bosch-ic-document-plain;
$Bosch-Ic-document-plain-add: $bosch-ic-document-plain-add;
$Bosch-Ic-document-plain-delete: $bosch-ic-document-plain-delete;
$Bosch-Ic-document-to-ppt: $bosch-ic-document-ppt;
$Bosch-Ic-document-to-pub: $bosch-ic-document-pub;
$Bosch-Ic-document-to-rtf: $bosch-ic-document-rtf;
$Bosch-Ic-document-save-to: $bosch-ic-document-save-to;
$Bosch-Ic-search-document: $bosch-ic-document-search;
$Bosch-Ic-document-settings: $bosch-ic-document-settings;
$Bosch-Ic-document-test: $bosch-ic-document-test;
$Bosch-Ic-document-text: $bosch-ic-document-text;
$Bosch-Ic-document-to-txt: $bosch-ic-document-txt;
$Bosch-Ic-document-to-vsd: $bosch-ic-document-vsd;
$Bosch-Ic-document-to-xls: $bosch-ic-document-xls;
$Bosch-Ic-document-to-xml: $bosch-ic-document-xml;
$Bosch-Ic-document-to-zip: $bosch-ic-document-zip;
$Bosch-IC-dome: $bosch-ic-dome;
$Bosch-IC-door: $bosch-ic-door;
$Bosch-IC-door-sensor: $bosch-ic-door-sensor;
$Bosch-Ic-arrow-expand-down: $bosch-ic-down;
$Bosch-Ic-arrow-expand-double-down: $bosch-ic-down-double;
$Bosch-Ic-arrow-left-circle: $bosch-ic-arrow-left-frame;
$Bosch-Ic-arrow-right-circle: $bosch-ic-arrow-right-frame;
$Bosch-Ic-arrow-right-down-circle: $bosch-ic-arrow-right-down-frame;
$Bosch-Ic-arrow-right-up-circle: $bosch-ic-arrow-right-up-frame;
$Bosch-Ic-arrow-left-down-circle: $bosch-ic-arrow-left-down-frame;
$Bosch-Ic-arrow-left-up-circle: $bosch-ic-arrow-left-up-frame;
$Bosch-Ic-arrow-up-circle: $bosch-ic-arrow-up-frame;
$Bosch-Ic-download: $bosch-ic-download;
$Bosch-IC-drag-handle: $bosch-ic-drag-handle;
$Bosch-IC-dragdrop: $bosch-ic-dragdrop;
$Bosch-IC-drop: $bosch-ic-drop;
$Bosch-IC-e-Charging: $bosch-ic-e-charging;
$Bosch-IC-e-mobility: $bosch-ic-e-mobility;
$Bosch-IC-ear-off: $bosch-ic-ear-off-disabled-light;
$Bosch-IC-ear-on: $bosch-ic-ear-on;
$Bosch-IC-eco-system: $bosch-ic-eco-system;
$Bosch-Ic-edit: $bosch-ic-edit;
$Bosch-IC-education: $bosch-ic-education;
$Bosch-IC-efficiency: $bosch-ic-efficiency;
$Bosch-IC-elevator: $bosch-ic-elevator;
$Bosch-IC-elevator-alarm: $bosch-ic-elevator-alarm;
$Bosch-IC-elevator-cloud: $bosch-ic-elevator-cloud;
$Bosch-IC-elevator-headset: $bosch-ic-elevator-headset;
$Bosch-IC-elevator-service: $bosch-ic-elevator-service;
$Bosch-IC-emergency-exit: $bosch-ic-emergency-exit;
$Bosch-IC-emoji-happy: $bosch-ic-emoji-happy;
$Bosch-IC-emoji-neutral: $bosch-ic-emoji-neutral;
$Bosch-IC-emoji-sad: $bosch-ic-emoji-sad;
$Bosch-IC-emoji-super-happy: $bosch-ic-emoji-super-happy;
$Bosch-IC-emoji-very-sad: $bosch-ic-emoji-very-sad;
$Bosch-IC-EU-energy-label: $bosch-ic-eu-energy-label;
$Bosch-IC-excavator: $bosch-ic-excavator;
$Bosch-Ic-exit: $bosch-ic-exit;
$Bosch-IC-expansion-arrows: $bosch-ic-expansion-arrows;
$Bosch-IC-explosion: $bosch-ic-explosion;
$Bosch-Ic-export: $bosch-ic-export;
$Bosch-IC-externallink: $bosch-ic-externallink;
$Bosch-IC-fast-forward: $bosch-ic-fast-forward;
$Bosch-IC-faucet: $bosch-ic-faucet;
$Bosch-IC-favorites: $bosch-ic-favorites;
$Bosch-IC-fax: $bosch-ic-fax;
$Bosch-IC-female: $bosch-ic-female;
$Bosch-IC-film: $bosch-ic-film;
$Bosch-Ic-filter: $bosch-ic-filter;
$Bosch-IC-filter-success: $bosch-ic-filter-success;
$Bosch-IC-fingerprint: $bosch-ic-fingerprint;
$Bosch-IC-fire: $bosch-ic-fire;
$Bosch-IC-fire-emergency: $bosch-ic-fire-emergency;
$Bosch-IC-fireworks: $bosch-ic-fireworks;
$Bosch-Ic-first-aid: $bosch-ic-first-aid;
$Bosch-IC-first-aid-cross: $bosch-ic-first-aid-cross;
$Bosch-IC-fit-to-sceen: $bosch-ic-fit-to-sceen;
$Bosch-Ic-flag: $bosch-ic-flag;
$Bosch-Ic-danger-flash-outline: $bosch-ic-flash;
$Bosch-IC-flask: $bosch-ic-flask;
$Bosch-Ic-flexpress: $bosch-ic-flexpress;
$Bosch-Ic-twitter: $bosch-ic-twitter-frame;
$Bosch-Ic-folder: $bosch-ic-folder;
$Bosch-Ic-download-from-folder: $bosch-ic-folder-download;
$Bosch-Ic-download-frame: $bosch-ic-download-frame;
$Bosch-IC-folder-open: $bosch-ic-folder-open;
$Bosch-Ic-upload-to-folder: $bosch-ic-folder-upload;
$Bosch-Ic-fork-lift: $bosch-ic-fork-lift;
$Bosch-IC-fork-lift-locator: $bosch-ic-fork-lift-locator;
$Bosch-IC-forward-right-small: $bosch-ic-forward-right-small;
$Bosch-Ic-arrow-expand-right: $bosch-ic-forward-right;
$Bosch-Ic-arrow-expand-double-right: $bosch-ic-forward-right-double;
$Bosch-IC-full-empty: $bosch-ic-full-empty;
$Bosch-Ic-fullscreen: $bosch-ic-fullscreen;
$Bosch-IC-fullscreen-arrows: $bosch-ic-fullscreen-arrows;
$Bosch-Ic-fullscreen-exit: $bosch-ic-fullscreen-exit;
$Bosch-IC-g-arrow-down: $bosch-ic-g-arrow-down;
$Bosch-IC-g-arrow-up: $bosch-ic-g-arrow-up;
$Bosch-IC-generator: $bosch-ic-generator;
$Bosch-IC-gift: $bosch-ic-gift;
$Bosch-IC-glance: $bosch-ic-glance;
$Bosch-IC-glas-plate: $bosch-ic-glas-plate;
$Bosch-IC-globe: $bosch-ic-globe;
$Bosch-IC-globe-arrow: $bosch-ic-globe-arrow;
$Bosch-IC-glossary: $bosch-ic-glossary;
$Bosch-Ic-grid: $bosch-ic-grid-view;
$Bosch-IC-hammer: $bosch-ic-hammer;
$Bosch-Ic-hand: $bosch-ic-hand;
$Bosch-IC-hand-motion: $bosch-ic-hand-motion;
$Bosch-IC-hand-motion-off: $bosch-ic-hand-motion-off-disabled-light;
$Bosch-IC-handlewithcare: $bosch-ic-handlewithcare;
$Bosch-Ic-add-material-manually: $bosch-ic-handover-add;
$Bosch-Ic-handover-check: $bosch-ic-handover-check;
$Bosch-Ic-remove-from-handover: $bosch-ic-handover-remove;
$Bosch-IC-hanger: $bosch-ic-hanger;
$Bosch-Ic-health: $bosch-ic-health;
$Bosch-IC-heart: $bosch-ic-heart;
$Bosch-Ic-history: $bosch-ic-history;
$Bosch-Ic-home-outline: $bosch-ic-home;
$Bosch-IC-home-locator: $bosch-ic-home-locator;
$Bosch-Ic-sandclock: $bosch-ic-hourglass;
$Bosch-IC-hydrant: $bosch-ic-hydrant;
$Bosch-IC-ice: $bosch-ic-ice;
$Bosch-Ic-image: $bosch-ic-imagery;
$Bosch-IC-imagery-add: $bosch-ic-imagery-add;
$Bosch-IC-imagery-remove: $bosch-ic-imagery-remove;
$Bosch-Ic-import: $bosch-ic-import;
$Bosch-Ic-imprint: $bosch-ic-imprint;
$Bosch-IC-impulse: $bosch-ic-impulse;
$Bosch-Ic-fabric: $bosch-ic-industry;
$Bosch-IC-industry-clock: $bosch-ic-industry-clock;
$Bosch-IC-industry-connected: $bosch-ic-industry-connected;
$Bosch-Ic-information: $bosch-ic-info-i;
$Bosch-Ic-information-tooltip: $bosch-ic-info-i;
$Bosch-IC-interval: $bosch-ic-interval;
$Bosch-IC-it-device: $bosch-ic-it-device;
$Bosch-IC-jewel: $bosch-ic-jewel;
$Bosch-IC-keyboard: $bosch-ic-keyboard;
$Bosch-IC-label: $bosch-ic-label;
$Bosch-Ic-laptop-01: $bosch-ic-laptop;
$Bosch-IC-laser: $bosch-ic-laser;
$Bosch-IC-layout: $bosch-ic-layout;
$Bosch-IC-leaf: $bosch-ic-leaf;
$Bosch-IC-led: $bosch-ic-led;
$Bosch-Ic-world: $bosch-ic-world-frame;
$Bosch-Ic-facebook: $bosch-ic-facebook-frame;
$Bosch-Ic-checkmark-outline: $bosch-ic-checkmark-frame;
$Bosch-Ic-youtube: $bosch-ic-youtube-frame;
$Bosch-Ic-share: $bosch-ic-share;
$Bosch-Ic-user: $bosch-ic-user;
$Bosch-Ic-upload_outline: $bosch-ic-upload-frame;
$Bosch-Ic-download_outline: $bosch-ic-download-frame;
$Bosch-Ic-wishlist-add: $bosch-ic-wishlist-add;
$Bosch-Ic-youtube-frame: $bosch-ic-youtube-frame;
$Bosch-Ic-less-minimize: $bosch-ic-less-minimize;
$Bosch-IC-lightbulb: $bosch-ic-lightbulb;
$bosch-Ic-innovation: $bosch-ic-lightbulb-off;
$Bosch-IC-line-chart: $bosch-ic-line-chart;
$Bosch-Ic-link: $bosch-ic-link;
$Bosch-IC-link-broken: $bosch-ic-link-broken;
$Bosch-Ic-list-view: $bosch-ic-list-view;
$Bosch-Ic-menu: $bosch-ic-list-view-mobile;
$Bosch-IC-livechat: $bosch-ic-livechat;
$Bosch-Ic-location-pin: $bosch-ic-locator;
$Bosch-IC-locator-ip-connected: $bosch-ic-locator-ip-connected;
$Bosch-IC-locator-ip-disconnected: $bosch-ic-locator-ip-disconnected;
$Bosch-IC-locator-spot: $bosch-ic-locator-spot;
$Bosch-IC-locator-spot-check: $bosch-ic-locator-spot-check;
$Bosch-Ic-lock-closed: $bosch-ic-lock-closed;
$Bosch-Ic-lock-open: $bosch-ic-lock-open;
$Bosch-Ic-log-in: $bosch-ic-login;
$Bosch-Ic-logistics: $bosch-ic-logistics;
$Bosch-Ic-log-out: $bosch-ic-logout;
$Bosch-IC-machine: $bosch-ic-machine;
$Bosch-Ic-magnet: $bosch-ic-magnet;
$Bosch-Ic-mail: $bosch-ic-mail;
$Bosch-Ic-mail-forward: $bosch-ic-mail-forward;
$Bosch-Ic-mail-open: $bosch-ic-mail-open;
$Bosch-Ic-mail-out: $bosch-ic-mail-out;
$Bosch-Ic-mail-answer: $bosch-ic-mail-reply;
$Bosch-IC-male: $bosch-ic-male;
$Bosch-Ic-map: $bosch-ic-map;
$Bosch-Ic-add-material: $bosch-ic-material-add;
$Bosch-IC-material-check: $bosch-ic-material-check;
$Bosch-Ic-remove-material: $bosch-ic-material-remove;
$Bosch-IC-maximize: $bosch-ic-maximize;
$Bosch-IC-mechanic: $bosch-ic-mechanic;
$Bosch-IC-megaphone: $bosch-ic-megaphone;
$Bosch-Ic-message: $bosch-ic-message;
$Bosch-IC-mic: $bosch-ic-mic;
$Bosch-Ic-microphone: $bosch-ic-microphone;
$Bosch-IC-microphone-classic: $bosch-ic-microphone-classic;
$Bosch-IC-minimize: $bosch-ic-minimize;
$Bosch-IC-money-currency: $bosch-ic-money-currency;
$Bosch-IC-money-dollar: $bosch-ic-money-dollar;
$Bosch-IC-moneybag-currency: $bosch-ic-moneybag-currency;
$Bosch-IC-moneybag-dollar: $bosch-ic-moneybag-dollar;
$Bosch-IC-monument: $bosch-ic-monument;
$Bosch-IC-moon: $bosch-ic-moon;
$Bosch-IC-mouse: $bosch-ic-mouse;
$Bosch-IC-mouse-left: $bosch-ic-mouse-left;
$Bosch-IC-mouse-right: $bosch-ic-mouse-right;
$Bosch-IC-mouth: $bosch-ic-mouth;
$Bosch-Ic-my-product: $bosch-ic-my-product;
$Bosch-IC-new-way-work: $bosch-ic-new-way-work;
$Bosch-Ic-mail-sent: $bosch-ic-newsletter;
$Bosch-Ic-news: $bosch-ic-newspaper;
$Bosch-IC-nose: $bosch-ic-nose;
$Bosch-Ic-notes: $bosch-ic-notepad;
$Bosch-Ic-notes-edit: $bosch-ic-notepad-edit;
$Bosch-Ic-alarm: $bosch-ic-notification;
$Bosch-IC-notification-active: $bosch-ic-notification-active;
$Bosch-IC-notification-add: $bosch-ic-notification-add;
$Bosch-IC-notification-remove: $bosch-ic-notification-remove;
$Bosch-Ic-oil-trailer: $bosch-ic-oil-car;
$Bosch-IC-omnichannel: $bosch-ic-omnichannel;
$Bosch-Ic-show-more-horizontal: $bosch-ic-options;
$Bosch-IC-origami-boat: $bosch-ic-origami-boat;
$Bosch-Ic-palette-trailer: $bosch-ic-pallete-car;
$Bosch-IC-pallete-car-connected: $bosch-ic-pallete-car-connected;
$Bosch-IC-panel: $bosch-ic-panel;
$Bosch-Ic-paperclip: $bosch-ic-paperclip;
$Bosch-IC-paperplane: $bosch-ic-paperplane;
$Bosch-IC-parking: $bosch-ic-parking;
$Bosch-Ic-pause: $bosch-ic-pause;
$Bosch-Ic-creditcard: $bosch-ic-payment;
$Bosch-IC-people: $bosch-ic-people;
$Bosch-IC-petrol-station: $bosch-ic-petrol-station;
$Bosch-IC-piggybank: $bosch-ic-piggybank;
$Bosch-Ic-pin: $bosch-ic-pin-classic;
$Bosch-IC-pin-modern: $bosch-ic-pin-modern;
$Bosch-IC-plane-side: $bosch-ic-plane-side;
$Bosch-Ic-play: $bosch-ic-play;
$Bosch-IC-plug-12V: $bosch-ic-plug-12v;
$Bosch-IC-podium-speaker: $bosch-ic-podium-speaker;
$Bosch-IC-police: $bosch-ic-police;
$Bosch-IC-poop: $bosch-ic-poop;
$Bosch-IC-post-it: $bosch-ic-post-it;
$Bosch-IC-postal-code: $bosch-ic-postal-code;
$Bosch-IC-power: $bosch-ic-power;
$Bosch-Ic-print: $bosch-ic-print;
$Bosch-IC-prototyping: $bosch-ic-prototyping;
$Bosch-IC-puzzle: $bosch-ic-puzzle;
$Bosch-IC-quad: $bosch-ic-quad;
$Bosch-IC-question: $bosch-ic-question;
$Bosch-IC-radar: $bosch-ic-radar;
$Bosch-IC-radio: $bosch-ic-radio;
$Bosch-Ic-radio: $bosch-ic-radiotower;
$Bosch-IC-redirect: $bosch-ic-redirect;
$Bosch-Ic-redo: $bosch-ic-redo;
$Bosch-IC-reference: $bosch-ic-reference;
$Bosch-Ic-refresh: $bosch-ic-refresh;
$Bosch-IC-refresh-cloud: $bosch-ic-refresh-cloud;
$Bosch-Ic-registration: $bosch-ic-registration;
$Bosch-IC-remote: $bosch-ic-remote;
$Bosch-Ic-reset: $bosch-ic-reset;
$Bosch-IC-resolution: $bosch-ic-resolution;
$Bosch-Ic-robot-automation: $bosch-ic-robot;
$Bosch-IC-robot-connected: $bosch-ic-robot-connected;
$Bosch-IC-robothead: $bosch-ic-robothead;
$Bosch-IC-rocket: $bosch-ic-rocket;
$Bosch-Ic-rotation: $bosch-ic-rotation;
$Bosch-Ic-rotate-360-degrees: $bosch-ic-rotation-360;
$Bosch-Ic-rotate-clockwise: $bosch-ic-rotation-x-left;
$Bosch-Ic-rotate-counterclockwise: $bosch-ic-rotation-x-right;
$Bosch-IC-rotation-y-down: $bosch-ic-rotation-y-down;
$Bosch-IC-rotation-y-up: $bosch-ic-rotation-y-up;
$Bosch-Ic-navigation: $bosch-ic-route;
$Bosch-Ic-measure: $bosch-ic-ruler;
$Bosch-IC-ruler-pen: $bosch-ic-ruler-pen;
$Bosch-Ic-satelite: $bosch-ic-satellite;
$Bosch-Ic-save: $bosch-ic-save;
$Bosch-IC-scale: $bosch-ic-scale;
$Bosch-Ic-cut: $bosch-ic-scissors;
$Bosch-Ic-search: $bosch-ic-search;
$Bosch-IC-security: $bosch-ic-security;
$Bosch-IC-security-check: $bosch-ic-security-check;
$Bosch-IC-security-user: $bosch-ic-security-user;
$Bosch-Ic-sensor: $bosch-ic-sensor;
$Bosch-Ic-server: $bosch-ic-server;
$Bosch-IC-server-expansion: $bosch-ic-server-expansion;
$Bosch-IC-server-rate: $bosch-ic-server-rate;
$Bosch-IC-service-agent: $bosch-ic-service-agent;
$Bosch-IC-service-time: $bosch-ic-service-time;
$Bosch-Ic-settings: $bosch-ic-settings;
$Bosch-IC-settings-arrows: $bosch-ic-settings-arrows;
$Bosch-IC-settings-connected: $bosch-ic-settings-connected;
$Bosch-Ic-share-outline: $bosch-ic-share;
$Bosch-IC-shield-stop: $bosch-ic-shield-stop;
$Bosch-IC-ship: $bosch-ic-ship;
$Bosch-IC-ship-side: $bosch-ic-ship-side;
$Bosch-Ic-shoppingcart: $bosch-ic-shoppingcart;
$Bosch-IC-shoppingcart-add: $bosch-ic-shoppingcart-add;
$Bosch-IC-shoppingcart-switch: $bosch-ic-shoppingcart-switch;
$Bosch-IC-signal-full: $bosch-ic-signal-full;
$Bosch-IC-signal-half: $bosch-ic-signal-half;
$Bosch-IC-signal-lost: $bosch-ic-signal-lost;
$Bosch-IC-signpost: $bosch-ic-signpost;
$Bosch-IC-simcard: $bosch-ic-simcard;
$Bosch-IC-skyscraper: $bosch-ic-skyscraper;
$Bosch-IC-smartcity: $bosch-ic-smartcity;
$Bosch-IC-smarthome: $bosch-ic-smarthome;
$Bosch-Ic-phone: $bosch-ic-smartphone;
$Bosch-IC-smartphone-acoustic: $bosch-ic-smartphone-acoustic;
$Bosch-IC-smartphone-arrow-right: $bosch-ic-smartphone-arrow-right;
$Bosch-IC-smartphone-arrows-x-y-z: $bosch-ic-smartphone-arrows-x-y-z;
$Bosch-IC-smartphone-clock: $bosch-ic-smartphone-clock;
$Bosch-IC-smartphone-cloud: $bosch-ic-smartphone-cloud;
$Bosch-IC-smartphone-loading-wheel: $bosch-ic-smartphone-loading-wheel;
$Bosch-IC-smartphone-smashed: $bosch-ic-smartphone-smashed;
$Bosch-IC-smartphone-vibration: $bosch-ic-smartphone-vibration;
$Bosch-IC-smartphone-z: $bosch-ic-smartphone-z;
$Bosch-Ic-spareparts: $bosch-ic-spare-parts;
$Bosch-IC-spark-plug: $bosch-ic-spark-plug;
$Bosch-IC-speaker-acoustic: $bosch-ic-speaker-acoustic;
$Bosch-IC-speedometer: $bosch-ic-speedometer;
$Bosch-IC-stadium: $bosch-ic-stadium;
$Bosch-IC-stairs: $bosch-ic-stairs;
$Bosch-IC-stamp: $bosch-ic-stamp;
$Bosch-IC-standby: $bosch-ic-standby;
$Bosch-IC-steering-wheel: $bosch-ic-steering-wheel;
$Bosch-IC-steering-wheel-connected: $bosch-ic-steering-wheel-connected;
$Bosch-IC-steering-wheel-hands: $bosch-ic-steering-wheel-hands;
$Bosch-IC-steps: $bosch-ic-steps;
$Bosch-IC-stethoscope: $bosch-ic-stethoscope;
$Bosch-Ic-stop: $bosch-ic-stop;
$Bosch-IC-stopwatch: $bosch-ic-stopwatch;
$Bosch-IC-storage-local: $bosch-ic-storage-local;
$Bosch-IC-structure: $bosch-ic-structure;
$Bosch-IC-subtitles: $bosch-ic-subtitles;
$Bosch-Ic-summary: $bosch-ic-summary;
$Bosch-IC-sun: $bosch-ic-sun;
$Bosch-IC-sun-half-filled: $bosch-ic-sun-half-filled;
$Bosch-IC-sunshade-table: $bosch-ic-sunshade-table;
$Bosch-IC-surveillance: $bosch-ic-surveillance;
$Bosch-IC-table-chairs: $bosch-ic-table-chairs;
$Bosch-Ic-tablet: $bosch-ic-tablet;
$Bosch-IC-team: $bosch-ic-team;
$Bosch-IC-team-lightbulb: $bosch-ic-team-lightbulb;
$Bosch-IC-technical-service: $bosch-ic-technical-service;
$Bosch-Ic-temperature: $bosch-ic-temperature;
$Bosch-IC-temperature-high: $bosch-ic-temperature-high;
$Bosch-IC-temperature-low: $bosch-ic-temperature-low;
$Bosch-IC-theater: $bosch-ic-theater;
$Bosch-Ic-dislike: $bosch-ic-thumb-down;
$Bosch-Ic-like: $bosch-ic-thumb-up;
$Bosch-Ic-ticket: $bosch-ic-ticket;
$Bosch-IC-touch: $bosch-ic-touch;
$Bosch-IC-touch-sos: $bosch-ic-touch-sos;
$Bosch-IC-towing-truck: $bosch-ic-towing-truck;
$Bosch-IC-train: $bosch-ic-train;
$Bosch-IC-train-side: $bosch-ic-train-side;
$Bosch-Ic-transport-movements: $bosch-ic-transport-movements;
$Bosch-IC-transscript: $bosch-ic-transscript;
$Bosch-IC-transscript-off: $bosch-ic-transscript-off;
$Bosch-IC-transscript-on: $bosch-ic-transscript-on;
$Bosch-IC-tree: $bosch-ic-tree;
$Bosch-IC-tricycle: $bosch-ic-tricycle;
$Bosch-Ic-default-trailer: $bosch-ic-trolley-empty;
$Bosch-Ic-taxi-trailer: $bosch-ic-trolley-filled-box;
$Bosch-Ic-shelve-trailer: $bosch-ic-trolly-filled-files;
$Bosch-IC-trophy: $bosch-ic-trophy;
$Bosch-IC-truck: $bosch-ic-truck;
$Bosch-IC-tuktuk: $bosch-ic-tuktuk;
$Bosch-Ic-tune: $bosch-ic-tune;
$Bosch-IC-typography: $bosch-ic-typography;
$Bosch-IC-ui: $bosch-ic-ui;
$Bosch-IC-umbrella: $bosch-ic-umbrella;
$Bosch-Ic-undo: $bosch-ic-undo;
$Bosch-Ic-arrow-expand-up: $bosch-ic-up;
$Bosch-Ic-arrow-expand-double-up: $bosch-ic-up-double;
$Bosch-Ic-sort: $bosch-ic-up-down;
$Bosch-Ic-upload: $bosch-ic-upload;
$Bosch-IC-usb: $bosch-ic-usb;
$Bosch-Ic-user-outline: $bosch-ic-user;
$Bosch-IC-user-advanced: $bosch-ic-user-advanced;
$Bosch-IC-user-beginner: $bosch-ic-user-beginner;
$Bosch-IC-user-desktop: $bosch-ic-user-desktop;
$Bosch-IC-user-down: $bosch-ic-user-down;
$Bosch-IC-user-falling: $bosch-ic-user-falling;
$Bosch-IC-user-hand: $bosch-ic-user-hand;
$Bosch-IC-user-hand-stop: $bosch-ic-user-hand-stop;
$Bosch-IC-user-professional: $bosch-ic-user-professional;
$Bosch-IC-user-run: $bosch-ic-user-run;
$Bosch-IC-user-share: $bosch-ic-user-share;
$Bosch-IC-user-voice: $bosch-ic-user-voice;
$Bosch-IC-user-walk: $bosch-ic-user-walk;
$Bosch-Ic-videocam: $bosch-ic-video;
$Bosch-Ic-videocam-disabled: $bosch-ic-video-disabled-light;
$Bosch-IC-video-record: $bosch-ic-video-record;
$Bosch-IC-virtual-reality: $bosch-ic-virtual-reality;
$Bosch-Ic-volume-disabled: $bosch-ic-volume-disabled;
$Bosch-Ic-volume-high: $bosch-ic-volume-high;
$Bosch-Ic-volume-low: $bosch-ic-volume-low;
$Bosch-Ic-volume-off: $bosch-ic-volume-off-light;
$Bosch-Ic-magic-wand: $bosch-ic-wand;
$Bosch-Ic-hidden-project: $bosch-ic-watch-off-disabled-light;
$Bosch-Ic-show: $bosch-ic-watch-on;
$Bosch-IC-water-shower: $bosch-ic-water-shower;
$Bosch-IC-welcome: $bosch-ic-welcome;
$Bosch-IC-wellness: $bosch-ic-wellness;
$Bosch-IC-wheelchair: $bosch-ic-wheelchair;
$Bosch-Ic-alert: $bosch-ic-whistle;
$Bosch-IC-wide-angled-arrow: $bosch-ic-wide-angled-arrow;
$Bosch-Ic-wifi: $bosch-ic-wifi;
$Bosch-Ic-window-analysis: $bosch-ic-window-analysis;
$Bosch-Ic-window-browser: $bosch-ic-window-browser;
$Bosch-Ic-window-console: $bosch-ic-window-console;
$Bosch-Ic-gaussian-view: $bosch-ic-window-gaussian-view;
$Bosch-Ic-window-info: $bosch-ic-window-info;
$Bosch-IC-window-new: $bosch-ic-window-new;
$Bosch-IC-window-resize: $bosch-ic-window-resize;
$Bosch-Ic-general-scaling-view: $bosch-ic-window-scaling-view;
$Bosch-Ic-restore-window: $bosch-ic-window-shuffle;
$Bosch-Ic-window-statistic: $bosch-ic-window-statistic;
$Bosch-Ic-wishlist: $bosch-ic-wishlist;
$Bosch-Ic-work-orders: $bosch-ic-work-order;
$Bosch-IC-worldwideweb: $bosch-ic-worldwideweb;
$Bosch-Ic-wrench: $bosch-ic-wrench;
$Bosch-Ic-zoom-in: $bosch-ic-zoom-in;
$Bosch-Ic-zoom-out: $bosch-ic-zoom-out;
