/*!
  * Copyright (C) 2019 Robert Bosch GmbH Copyright (C) 2019 Robert Bosch Manufacturing Solutions GmbH, Germany. All rights reserved.
 */

@import '@bci-web-core/common-styles/sass/bci/bci-variables';

//styling progress bar used for loading screen
mat-progress-bar.loading-screen-progress-bar {
  height: $grid-size;

  .mdc-linear-progress__bar-inner {
    border-top-width: $grid-size;
    border-color: $boschBlue50;
    top: -2px;
  }

  .mdc-linear-progress__buffer {
    height: 8px;
  }

  .mdc-linear-progress__buffer-bar {
    height: 6px;
    border: $boschBlue50 1px solid;
    background-color: transparent;
  }

  // set determinate progress bar fill
  .mat-progress-bar-fill {
    // reset initial transition, when loading screen opens
    transform: scaleX(0);

    // set fill gradient
    &::after {
      background-color: $boschBlue50;
      background-size: cover;
      background-position: center center;
    }
  }

  // set indeterminate progress bar style
  &[mode='indeterminate'] {
    .mat-mdc-progress-bar-fill {
      transform: none;
    }

    .mdc-linear-progress__bar-inner {
      max-width: 32px;
      animation: none !important;
    }

    .mdc-linear-progress__primary-bar {
      animation: none !important;
    }

    // no animation
    .mat-mdc-progress-bar-primary {
      animation: none;
    }

    // no animation
    .mat-mdc-progress-bar-secondary {
      animation: none;
      left: 0;

      // set a gradient moving in a loop back and forth
      &.mat-mdc-progress-bar-fill::after {
        background: linear-gradient(to right, $boschBlue35, $boschBlue50, $boschBlue70, $boschBlue50, $boschBlue35);
        background-size: 200% 200%;

        animation: mat-mdc-progress-bar-secondary-indeterminate-loop 3s ease infinite;

        @-webkit-keyframes mat-mdc-progress-bar-secondary-indeterminate-loop {
          0% {
            background-position: 0% 50%;
          }
          50% {
            background-position: 100% 50%;
          }
          100% {
            background-position: 0% 50%;
          }
        }
        @-moz-keyframes mat-progress-bar-secondary-indeterminate-loop {
          0% {
            background-position: 0% 50%;
          }
          50% {
            background-position: 100% 50%;
          }
          100% {
            background-position: 0% 50%;
          }
        }
        @keyframes mat-progress-bar-secondary-indeterminate-loop {
          0% {
            background-position: 0% 50%;
          }
          50% {
            background-position: 100% 50%;
          }
          100% {
            background-position: 0% 50%;
          }
        }
      }
    }
  }
}
