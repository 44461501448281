/*!
  *  Copyright (C) 2017 Robert Bosch GmbH Copyright (C) 2017 Robert Bosch Manufacturing Solutions GmbH, Germany. All rights reserved.
 */

label {
  @include make-label-left;

  &.label-top {
    @include make-label-top;
  }

  &.label-top-disabled {
    @include make-label-top-disabled;
  }

  &.label-top-focused {
    @include make-label-top-focused;
  }
}

.dark-background {
  label {
    &.label-top {
      @include make-label-top-dark;
    }

    &.label-top-focused {
      @include make-label-top-focused-dark;
    }
  }
}
