@mixin bci-list {
  &:hover {
    background-color: $boschGray90;
  }

  &:active {
    background-color: $boschGray80;
  }

  &.active {
    background-color: $boschBlue50;
    color: $boschWhite;
  }

  &.active:hover {
    background-color: $boschBlue40;
    color: $boschWhite;
  }

  &.active:active {
    background-color: $boschBlue30;
    color: $boschWhite;
  }
}
