/*!
  *  Copyright (C) 2018 Robert Bosch GmbH Copyright (C) 2018 Robert Bosch Manufacturing Solutions GmbH, Germany. All rights reserved.
 */

.ngx-datatable {
  .datatable-header {
    font-weight: bold;
    border-bottom: 4px solid $boschGray80;

    .datatable-header-cell span.sort-btn {
      line-height: normal;
      &.datatable-icon-down:before {
        @include make-icon($Bosch-Ic-arrow-down, 28px);
        font-weight: normal;
      }

      &.datatable-icon-up:before {
        @include make-icon($Bosch-Ic-arrow-up, 28px);
        font-weight: normal;
      }
    }
  }

  .datatable-body-cell,
  .datatable-header-cell {
    padding: 0 12px;

    .datatable-checkbox {
      width: $checkbox-size;
      height: $checkbox-size;
      line-height: initial;
      margin-top: 12px;
      padding: 0;
      display: block;
      cursor: pointer;
      border: none;
      background-color: $checkbox-background-color;

      &.isSelected {
        background-color: $checkbox-background-color-checked;
        &::before {
          @include make-icon($bosch-ic-checkmark, 26px);
          position: absolute;
          left: 11px;
          top: 11px;
          line-height: 28px;
          color: $checkbox-stroke-color;
        }
      }

      input {
        opacity: 0;
        width: $checkbox-size;
        height: $checkbox-size;
        margin: 0;
        cursor: pointer;
      }
    }
  }

  .datatable-body-cell {
    &:nth-of-type(even) {
      background-color: $boschGray95;
    }

    .datatable-body-cell-label {
      line-height: 48px;
      height: 48px;
    }
  }

  .datatable-header {
    height: 48px !important;
    display: flex !important;
    align-items: center !important;
  }

  .datatable-header-cell {
    display: flex !important;
    align-items: center !important;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    > div {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      line-height: initial;
    }

    .resize-handle:hover {
      background-color: $boschGray85;
      transition: background-color 400ms ease;
    }
  }

  .datatable-row-wrapper {
    border-top: 1px solid $boschGray80;

    &:first-of-type {
      border-top: none;
    }
  }

  .Bosch-Ic,
  .bosch-ic {
    font-size: 24px;
    color: $boschBlack;
  }

  .datatable-row-right {
    color: white;
    background-color: white;
  }

  datatable-pager {
    .pager {
      @include make-pagination-medium;

      .active {
        @include make-pagination-element-selected($pagination-medium-size-base);
      }
    }

    .pager {
      > li {
        vertical-align: middle;

        &:first-child::after,
        &:hover:first-child::after,
        &:active:first-child::after,
        &:focus:first-child::after,
        &:last-child::after,
        &:hover:last-child::after,
        &:active:last-child::after,
        &:focus:last-child::after {
          display: none;
        }

        a {
          vertical-align: middle;
          font-size: 20px;
        }
      }

      i.datatable-icon-left:before {
        @include make-icon($Bosch-Ic-arrow-expand-left, 20px);
      }

      i.datatable-icon-right:before {
        @include make-icon($Bosch-Ic-arrow-expand-right, 20px);
      }

      i.datatable-icon-prev:after {
        @include make-icon($Bosch-Ic-arrow-end-left, 20px);
      }

      i.datatable-icon-skip:before {
        @include make-icon($Bosch-Ic-arrow-end-right, 20px);
      }

      .pages {
        > *:hover {
          @include make-pagination-element-hover;
        }

        > *:disabled,
        *.disabled {
          @include make-pagination-element-disabled;
        }

        &.active a {
          color: $boschWhite !important;
          @include make-pagination-element-selected(
            $pagination-medium-size-base
          );
        }

        a {
          @include make-pagination-element(
            $pagination-medium-size-base,
            $pagination-medium-font-size
          );
          margin: 0;

          &::after {
            display: none;
          }
        }
      }
    }
  }
}
