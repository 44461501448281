/*!
  *  Copyright (C) 2017 Robert Bosch GmbH Copyright (C) 2017 Robert Bosch Manufacturing Solutions GmbH, Germany. All rights reserved.
 */

@mixin make-button-group-button {
  position: relative;
  float: left;
  transition: $transition-time-quarter;
  font-size: $font-size-base;
  line-height: $grid-size * 6;
  padding: 0 $grid-size * 2;
  cursor: pointer;
  background-color: $boschGray95;
  border: $boschWhite;
  color: $boschBlack;
  // Bring the "active" button to the front
  &:hover {
    background-color: $boschGray90;
    color: $boschBlack;
  }
  &:focus,
  &:active,
  &.active {
    background-color: $boschBlue35;
    color: $boschWhite;
    outline: none;

    &:hover {
      background-color: $boschBlue80;
      color: $boschWhite;
    }
  }
  &:disabled,
  &:hover:disabled,
  &.disabled {
    @include make-button-group-button-disabled;
  }
}

@mixin make-button-group-base {
  position: relative;
  display: inline-block;
  vertical-align: middle; // match .button alignment given font-size hack above

  > * {
    @include make-button-group-button;
  }
}

@mixin make-button-group {
  @include make-button-group-base;
  * + * {
    margin-left: -1px;
  }
}
//--------------------------------------------------------------------------------------------------------------------- DISABLED

@mixin make-button-group-button-disabled {
  color: $boschWhite;
  background-color: $boschGray80;
  pointer-events: none;
}

//--------------------------------------------------------------------------------------------------------------------- VERTICAL
@mixin make-button-group-vertical-button {
  display: block;
  float: none;
  width: 100%;
  text-align: center;
  margin-bottom: -1px;
}

@mixin make-button-group-vertical {
  @include make-button-group-base;
  * + * {
    margin-top: -1px;
    margin-left: 0;
  }
  > * {
    @include make-button-group-vertical-button;
  }
}
