/*!
  *  Copyright (C) 2017 Robert Bosch GmbH Copyright (C) 2017 Robert Bosch Manufacturing Solutions GmbH, Germany. All rights reserved.
 */
@import "../bci-variables";

@mixin make-header-logo {
  margin-left: auto;
  height: $header-height;
  width: $header-logo-width;
  background: url("#{$path-to-assets}/img/bosch-logo-only.png");
  background-size: $header-logo-width auto;
  background-repeat: no-repeat;
  background-position-y: center;

  @media all and (max-width: $screen-xs-min) {
    height: $header-height-screen-xs;
    background-size: $header-logo-width-screen-xs auto;
    width: $header-logo-width-screen-xs;
  }
}

@mixin make-responsive-header-padding($top, $bottom) {
  @each $width, $padding in $content-padding {
    @media (min-width: $width) {
      padding: unquote($top) $padding unquote($bottom) $padding;
    }
  }
}

@mixin make-bci-logo-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: $header-height;
  align-items: center;
  box-sizing: content-box;
  @include make-border(0 0 1px 0, $boschGray90);
  @include make-responsive-header-padding("16px", "0px");

  @media all and (max-width: $screen-xs-min) {
    height: $header-height-screen-xs;
  }

  .header-content {
    margin-right: 16px;
    @include make-font-regular(28px);
  }
}

@mixin make-header-breadcrumb {
  @media all and (max-width: $screen-sm-min) {
    display: none;
  }
}

@mixin make-bci-toolbar-row {
  @include make-bci-logo-row;
  padding-top: 0;
}
