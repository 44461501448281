/*!
  *  Copyright (C) 2020 Robert Bosch GmbH Copyright (C) 2020 Robert Bosch Manufacturing Solutions GmbH, Germany. All rights reserved.
 */

@mixin make-badge {
  height: $grid-size * 2;
  min-width: $font-size-base;
  padding: 0px $font-size-base * 0.25;
  border-radius: $font-size-base * 0.5;
  font-size: $font-size-small;
  font-family: $font-family-base;
  line-height: $font-size-base;
  position: absolute;
  background: $boschBlue50;
  color: $boschWhite;
  text-align: center;
  font-weight: $font-weight-regular;
  margin-left: -$grid-size * 1.5;
  margin-top: -6px;
}

@mixin make-badge-hidden {
  display: none;
}

@mixin make-badge-warn {
  background: $boschSignalRed;
}

@mixin make-badge-accent {
  background: $boschSignalYellow;
}
