/*!
  *  Copyright (C) 2017 Robert Bosch GmbH Copyright (C) 2017 Robert Bosch Manufacturing Solutions GmbH, Germany. All rights reserved.
 */

@mixin make-expand-height($deduction: 0px) {
  height: calc(100% - #{$deduction});
}

@mixin make-expand-width($deduction: 0px) {
  width: calc(100% - #{$deduction});
}
