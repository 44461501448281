/*!
 *  Copyright (C) 2017 Robert Bosch GmbH Copyright (C) 2017 Robert Bosch Manufacturing Solutions GmbH, Germany. All rights reserved.
*/
// This is a GENERATED FILE! Do not touch.

.bosch-ic-3d-print:before {
    content: $bosch-ic-3d-print;
}
.bosch-ic-a-to-b:before {
    content: $bosch-ic-a-to-b;
}
.bosch-ic-add:before {
    content: $bosch-ic-add;
}
.bosch-ic-address-consumer-data:before {
    content: $bosch-ic-address-consumer-data;
}
.bosch-ic-adress-book:before {
    content: $bosch-ic-adress-book;
}
.bosch-ic-agility:before {
    content: $bosch-ic-agility;
}
.bosch-ic-alarm-clock:before {
    content: $bosch-ic-alarm-clock;
}
.bosch-ic-ambulance:before {
    content: $bosch-ic-ambulance;
}
.bosch-ic-analysis:before {
    content: $bosch-ic-analysis;
}
.bosch-ic-anchor:before {
    content: $bosch-ic-anchor;
}
.bosch-ic-arrow-down:before {
    content: $bosch-ic-arrow-down;
}
.bosch-ic-arrow-left:before {
    content: $bosch-ic-arrow-left;
}
.bosch-ic-arrow-left-down:before {
    content: $bosch-ic-arrow-left-down;
}
.bosch-ic-arrow-left-up:before {
    content: $bosch-ic-arrow-left-up;
}
.bosch-ic-arrow-right:before {
    content: $bosch-ic-arrow-right;
}
.bosch-ic-arrow-right-down:before {
    content: $bosch-ic-arrow-right-down;
}
.bosch-ic-arrow-right-up:before {
    content: $bosch-ic-arrow-right-up;
}
.bosch-ic-arrow-turn-left:before {
    content: $bosch-ic-arrow-turn-left;
}
.bosch-ic-arrow-turn-right:before {
    content: $bosch-ic-arrow-turn-right;
}
.bosch-ic-arrow-up:before {
    content: $bosch-ic-arrow-up;
}
.bosch-ic-arrows:before {
    content: $bosch-ic-arrows;
}
.bosch-ic-arrows-x-y-z:before {
    content: $bosch-ic-arrows-x-y-z;
}
.bosch-ic-artificial-intelligence:before {
    content: $bosch-ic-artificial-intelligence;
}
.bosch-ic-assembly-line:before {
    content: $bosch-ic-assembly-line;
}
.bosch-ic-atom:before {
    content: $bosch-ic-atom;
}
.bosch-ic-augmented-reality:before {
    content: $bosch-ic-augmented-reality;
}
.bosch-ic-autobod:before {
    content: $bosch-ic-autobod;
}
.bosch-ic-back-left:before {
    content: $bosch-ic-back-left;
}
.bosch-ic-back-left-double:before {
    content: $bosch-ic-back-left-double;
}
.bosch-ic-bar-chart:before {
    content: $bosch-ic-bar-chart;
}
.bosch-ic-bar-chart-double:before {
    content: $bosch-ic-bar-chart-double;
}
.bosch-ic-battery-0:before {
    content: $bosch-ic-battery-0;
}
.bosch-ic-battery-1:before {
    content: $bosch-ic-battery-1;
}
.bosch-ic-battery-2:before {
    content: $bosch-ic-battery-2;
}
.bosch-ic-battery-3:before {
    content: $bosch-ic-battery-3;
}
.bosch-ic-battery-4:before {
    content: $bosch-ic-battery-4;
}
.bosch-ic-battery-fail:before {
    content: $bosch-ic-battery-fail;
}
.bosch-ic-battery-li-ion:before {
    content: $bosch-ic-battery-li-ion;
}
.bosch-ic-battery-loading:before {
    content: $bosch-ic-battery-loading;
}
.bosch-ic-bed:before {
    content: $bosch-ic-bed;
}
.bosch-ic-bicycle:before {
    content: $bosch-ic-bicycle;
}
.bosch-ic-bicycle-e:before {
    content: $bosch-ic-bicycle-e;
}
.bosch-ic-blog:before {
    content: $bosch-ic-blog;
}
.bosch-ic-bluetooth:before {
    content: $bosch-ic-bluetooth;
}
.bosch-ic-bluetooth-le:before {
    content: $bosch-ic-bluetooth-le;
}
.bosch-ic-board-speaker:before {
    content: $bosch-ic-board-speaker;
}
.bosch-ic-boiler-connected:before {
    content: $bosch-ic-boiler-connected;
}
.bosch-ic-book:before {
    content: $bosch-ic-book;
}
.bosch-ic-bookmark:before {
    content: $bosch-ic-bookmark;
}
.bosch-ic-bookmark-add:before {
    content: $bosch-ic-bookmark-add;
}
.bosch-ic-bookmark-check:before {
    content: $bosch-ic-bookmark-check;
}
.bosch-ic-bookmark-delete:before {
    content: $bosch-ic-bookmark-delete;
}
.bosch-ic-books:before {
    content: $bosch-ic-books;
}
.bosch-ic-bookshelf:before {
    content: $bosch-ic-bookshelf;
}
.bosch-ic-box:before {
    content: $bosch-ic-box;
}
.bosch-ic-box-closed:before {
    content: $bosch-ic-box-closed;
}
.bosch-ic-box-delivery:before {
    content: $bosch-ic-box-delivery;
}
.bosch-ic-box-delivery-connected:before {
    content: $bosch-ic-box-delivery-connected;
}
.bosch-ic-brake-disk:before {
    content: $bosch-ic-brake-disk;
}
.bosch-ic-briefcase:before {
    content: $bosch-ic-briefcase;
}
.bosch-ic-broom:before {
    content: $bosch-ic-broom;
}
.bosch-ic-broom-cloud-dirt:before {
    content: $bosch-ic-broom-cloud-dirt;
}
.bosch-ic-brush:before {
    content: $bosch-ic-brush;
}
.bosch-ic-bug:before {
    content: $bosch-ic-bug;
}
.bosch-ic-building:before {
    content: $bosch-ic-building;
}
.bosch-ic-cactus:before {
    content: $bosch-ic-cactus;
}
.bosch-ic-calculate:before {
    content: $bosch-ic-calculate;
}
.bosch-ic-calendar:before {
    content: $bosch-ic-calendar;
}
.bosch-ic-calendar-add:before {
    content: $bosch-ic-calendar-add;
}
.bosch-ic-calendar-settings:before {
    content: $bosch-ic-calendar-settings;
}
.bosch-ic-call:before {
    content: $bosch-ic-call;
}
.bosch-ic-call-add:before {
    content: $bosch-ic-call-add;
}
.bosch-ic-call-remove:before {
    content: $bosch-ic-call-remove;
}
.bosch-ic-call-sos:before {
    content: $bosch-ic-call-sos;
}
.bosch-ic-call-team:before {
    content: $bosch-ic-call-team;
}
.bosch-ic-call-wifi:before {
    content: $bosch-ic-call-wifi;
}
.bosch-ic-camera:before {
    content: $bosch-ic-camera;
}
.bosch-ic-camera-switch:before {
    content: $bosch-ic-camera-switch;
}
.bosch-ic-car:before {
    content: $bosch-ic-car;
}
.bosch-ic-car-clock:before {
    content: $bosch-ic-car-clock;
}
.bosch-ic-car-connected:before {
    content: $bosch-ic-car-connected;
}
.bosch-ic-car-locator:before {
    content: $bosch-ic-car-locator;
}
.bosch-ic-car-mechanic:before {
    content: $bosch-ic-car-mechanic;
}
.bosch-ic-car-rental:before {
    content: $bosch-ic-car-rental;
}
.bosch-ic-car-seat-connected:before {
    content: $bosch-ic-car-seat-connected;
}
.bosch-ic-car-side:before {
    content: $bosch-ic-car-side;
}
.bosch-ic-car-side-user:before {
    content: $bosch-ic-car-side-user;
}
.bosch-ic-cart:before {
    content: $bosch-ic-cart;
}
.bosch-ic-certificate:before {
    content: $bosch-ic-certificate;
}
.bosch-ic-certificate-pki:before {
    content: $bosch-ic-certificate-pki;
}
.bosch-ic-change:before {
    content: $bosch-ic-change;
}
.bosch-ic-chart-bar:before {
    content: $bosch-ic-chart-bar;
}
.bosch-ic-chart-check:before {
    content: $bosch-ic-chart-check;
}
.bosch-ic-chart-dummy:before {
    content: $bosch-ic-chart-dummy;
}
.bosch-ic-chart-line:before {
    content: $bosch-ic-chart-line;
}
.bosch-ic-chat:before {
    content: $bosch-ic-chat;
}
.bosch-ic-chat-add:before {
    content: $bosch-ic-chat-add;
}
.bosch-ic-chat-question-answer:before {
    content: $bosch-ic-chat-question-answer;
}
.bosch-ic-chat-remove:before {
    content: $bosch-ic-chat-remove;
}
.bosch-ic-checklist:before {
    content: $bosch-ic-checklist;
}
.bosch-ic-checkmark:before {
    content: $bosch-ic-checkmark;
}
.bosch-ic-chip:before {
    content: $bosch-ic-chip;
}
.bosch-ic-circle-segment:before {
    content: $bosch-ic-circle-segment;
}
.bosch-ic-clear-all:before {
    content: $bosch-ic-clear-all;
}
.bosch-ic-clipboard:before {
    content: $bosch-ic-clipboard;
}
.bosch-ic-clipboard-list:before {
    content: $bosch-ic-clipboard-list;
}
.bosch-ic-clock:before {
    content: $bosch-ic-clock;
}
.bosch-ic-close:before {
    content: $bosch-ic-close;
}
.bosch-ic-close-all:before {
    content: $bosch-ic-close-all;
}
.bosch-ic-cloud:before {
    content: $bosch-ic-cloud;
}
.bosch-ic-cloud-co2:before {
    content: $bosch-ic-cloud-co2;
}
.bosch-ic-cloud-download:before {
    content: $bosch-ic-cloud-download;
}
.bosch-ic-cloud-nox:before {
    content: $bosch-ic-cloud-nox;
}
.bosch-ic-cloud-upload:before {
    content: $bosch-ic-cloud-upload;
}
.bosch-ic-club-arm-chair:before {
    content: $bosch-ic-club-arm-chair;
}
.bosch-ic-coffee-break:before {
    content: $bosch-ic-coffee-break;
}
.bosch-ic-coin-currency:before {
    content: $bosch-ic-coin-currency;
}
.bosch-ic-coin-dollar:before {
    content: $bosch-ic-coin-dollar;
}
.bosch-ic-colorpicker:before {
    content: $bosch-ic-colorpicker;
}
.bosch-ic-colors:before {
    content: $bosch-ic-colors;
}
.bosch-ic-command:before {
    content: $bosch-ic-command;
}
.bosch-ic-communicator:before {
    content: $bosch-ic-communicator;
}
.bosch-ic-compare:before {
    content: $bosch-ic-compare;
}
.bosch-ic-components:before {
    content: $bosch-ic-components;
}
.bosch-ic-components-available:before {
    content: $bosch-ic-components-available;
}
.bosch-ic-components-reservation:before {
    content: $bosch-ic-components-reservation;
}
.bosch-ic-concierge-bell:before {
    content: $bosch-ic-concierge-bell;
}
.bosch-ic-configuration:before {
    content: $bosch-ic-configuration;
}
.bosch-ic-confirmation:before {
    content: $bosch-ic-confirmation;
}
.bosch-ic-connection-off:before {
    content: $bosch-ic-connection-off;
}
.bosch-ic-connection-on:before {
    content: $bosch-ic-connection-on;
}
.bosch-ic-connectivity:before {
    content: $bosch-ic-connectivity;
}
.bosch-ic-construction:before {
    content: $bosch-ic-construction;
}
.bosch-ic-consumer-goods:before {
    content: $bosch-ic-consumer-goods;
}
.bosch-ic-contract:before {
    content: $bosch-ic-contract;
}
.bosch-ic-copy:before {
    content: $bosch-ic-copy;
}
.bosch-ic-cube:before {
    content: $bosch-ic-cube;
}
.bosch-ic-cube-arrows:before {
    content: $bosch-ic-cube-arrows;
}
.bosch-ic-cube-filled:before {
    content: $bosch-ic-cube-filled;
}
.bosch-ic-cube-shock:before {
    content: $bosch-ic-cube-shock;
}
.bosch-ic-cube-stacked:before {
    content: $bosch-ic-cube-stacked;
}
.bosch-ic-customerservice:before {
    content: $bosch-ic-customerservice;
}
.bosch-ic-cutlery:before {
    content: $bosch-ic-cutlery;
}
.bosch-ic-damper:before {
    content: $bosch-ic-damper;
}
.bosch-ic-danger-flash:before {
    content: $bosch-ic-danger-flash;
}
.bosch-ic-data-loss:before {
    content: $bosch-ic-data-loss;
}
.bosch-ic-dealer-details:before {
    content: $bosch-ic-dealer-details;
}
.bosch-ic-delete:before {
    content: $bosch-ic-delete;
}
.bosch-ic-delivery:before {
    content: $bosch-ic-delivery;
}
.bosch-ic-denied:before {
    content: $bosch-ic-denied;
}
.bosch-ic-desktop:before {
    content: $bosch-ic-desktop;
}
.bosch-ic-desktop-application-2d:before {
    content: $bosch-ic-desktop-application-2d;
}
.bosch-ic-desktop-application-3d:before {
    content: $bosch-ic-desktop-application-3d;
}
.bosch-ic-development:before {
    content: $bosch-ic-development;
}
.bosch-ic-devices:before {
    content: $bosch-ic-devices;
}
.bosch-ic-devices-mobile:before {
    content: $bosch-ic-devices-mobile;
}
.bosch-ic-directory:before {
    content: $bosch-ic-directory;
}
.bosch-ic-dna:before {
    content: $bosch-ic-dna;
}
.bosch-ic-do-not-disturb:before {
    content: $bosch-ic-do-not-disturb;
}
.bosch-ic-document:before {
    content: $bosch-ic-document;
}
.bosch-ic-document-add:before {
    content: $bosch-ic-document-add;
}
.bosch-ic-document-arrow-down:before {
    content: $bosch-ic-document-arrow-down;
}
.bosch-ic-document-arrow-up:before {
    content: $bosch-ic-document-arrow-up;
}
.bosch-ic-document-audio:before {
    content: $bosch-ic-document-audio;
}
.bosch-ic-document-check:before {
    content: $bosch-ic-document-check;
}
.bosch-ic-document-cloud:before {
    content: $bosch-ic-document-cloud;
}
.bosch-ic-document-copy:before {
    content: $bosch-ic-document-copy;
}
.bosch-ic-document-delete:before {
    content: $bosch-ic-document-delete;
}
.bosch-ic-document-doc:before {
    content: $bosch-ic-document-doc;
}
.bosch-ic-document-edit:before {
    content: $bosch-ic-document-edit;
}
.bosch-ic-document-error:before {
    content: $bosch-ic-document-error;
}
.bosch-ic-document-locked:before {
    content: $bosch-ic-document-locked;
}
.bosch-ic-document-log:before {
    content: $bosch-ic-document-log;
}
.bosch-ic-document-one:before {
    content: $bosch-ic-document-one;
}
.bosch-ic-document-pdf:before {
    content: $bosch-ic-document-pdf;
}
.bosch-ic-document-plain:before {
    content: $bosch-ic-document-plain;
}
.bosch-ic-document-plain-add:before {
    content: $bosch-ic-document-plain-add;
}
.bosch-ic-document-plain-delete:before {
    content: $bosch-ic-document-plain-delete;
}
.bosch-ic-document-ppt:before {
    content: $bosch-ic-document-ppt;
}
.bosch-ic-document-pub:before {
    content: $bosch-ic-document-pub;
}
.bosch-ic-document-rtf:before {
    content: $bosch-ic-document-rtf;
}
.bosch-ic-document-save-to:before {
    content: $bosch-ic-document-save-to;
}
.bosch-ic-document-search:before {
    content: $bosch-ic-document-search;
}
.bosch-ic-document-settings:before {
    content: $bosch-ic-document-settings;
}
.bosch-ic-document-test:before {
    content: $bosch-ic-document-test;
}
.bosch-ic-document-text:before {
    content: $bosch-ic-document-text;
}
.bosch-ic-document-txt:before {
    content: $bosch-ic-document-txt;
}
.bosch-ic-document-vsd:before {
    content: $bosch-ic-document-vsd;
}
.bosch-ic-document-xls:before {
    content: $bosch-ic-document-xls;
}
.bosch-ic-document-xml:before {
    content: $bosch-ic-document-xml;
}
.bosch-ic-document-zip:before {
    content: $bosch-ic-document-zip;
}
.bosch-ic-dome:before {
    content: $bosch-ic-dome;
}
.bosch-ic-door:before {
    content: $bosch-ic-door;
}
.bosch-ic-door-sensor:before {
    content: $bosch-ic-door-sensor;
}
.bosch-ic-down:before {
    content: $bosch-ic-down;
}
.bosch-ic-down-double:before {
    content: $bosch-ic-down-double;
}
.bosch-ic-download:before {
    content: $bosch-ic-download;
}
.bosch-ic-drag-handle:before {
    content: $bosch-ic-drag-handle;
}
.bosch-ic-dragdrop:before {
    content: $bosch-ic-dragdrop;
}
.bosch-ic-drop:before {
    content: $bosch-ic-drop;
}
.bosch-ic-e-charging:before {
    content: $bosch-ic-e-charging;
}
.bosch-ic-e-mobility:before {
    content: $bosch-ic-e-mobility;
}
.bosch-ic-ear-off-disabled-light:before {
    content: $bosch-ic-ear-off-disabled-light;
}
.bosch-ic-ear-on:before {
    content: $bosch-ic-ear-on;
}
.bosch-ic-eco-system:before {
    content: $bosch-ic-eco-system;
}
.bosch-ic-edit:before {
    content: $bosch-ic-edit;
}
.bosch-ic-education:before {
    content: $bosch-ic-education;
}
.bosch-ic-efficiency:before {
    content: $bosch-ic-efficiency;
}
.bosch-ic-elevator:before {
    content: $bosch-ic-elevator;
}
.bosch-ic-elevator-alarm:before {
    content: $bosch-ic-elevator-alarm;
}
.bosch-ic-elevator-cloud:before {
    content: $bosch-ic-elevator-cloud;
}
.bosch-ic-elevator-headset:before {
    content: $bosch-ic-elevator-headset;
}
.bosch-ic-elevator-service:before {
    content: $bosch-ic-elevator-service;
}
.bosch-ic-emergency-exit:before {
    content: $bosch-ic-emergency-exit;
}
.bosch-ic-emoji-happy:before {
    content: $bosch-ic-emoji-happy;
}
.bosch-ic-emoji-neutral:before {
    content: $bosch-ic-emoji-neutral;
}
.bosch-ic-emoji-sad:before {
    content: $bosch-ic-emoji-sad;
}
.bosch-ic-emoji-super-happy:before {
    content: $bosch-ic-emoji-super-happy;
}
.bosch-ic-emoji-very-sad:before {
    content: $bosch-ic-emoji-very-sad;
}
.bosch-ic-eu-energy-label:before {
    content: $bosch-ic-eu-energy-label;
}
.bosch-ic-excavator:before {
    content: $bosch-ic-excavator;
}
.bosch-ic-exit:before {
    content: $bosch-ic-exit;
}
.bosch-ic-expansion-arrows:before {
    content: $bosch-ic-expansion-arrows;
}
.bosch-ic-explosion:before {
    content: $bosch-ic-explosion;
}
.bosch-ic-export:before {
    content: $bosch-ic-export;
}
.bosch-ic-externallink:before {
    content: $bosch-ic-externallink;
}
.bosch-ic-fast-forward:before {
    content: $bosch-ic-fast-forward;
}
.bosch-ic-faucet:before {
    content: $bosch-ic-faucet;
}
.bosch-ic-favorites:before {
    content: $bosch-ic-favorites;
}
.bosch-ic-fax:before {
    content: $bosch-ic-fax;
}
.bosch-ic-female:before {
    content: $bosch-ic-female;
}
.bosch-ic-film:before {
    content: $bosch-ic-film;
}
.bosch-ic-filter:before {
    content: $bosch-ic-filter;
}
.bosch-ic-filter-success:before {
    content: $bosch-ic-filter-success;
}
.bosch-ic-fingerprint:before {
    content: $bosch-ic-fingerprint;
}
.bosch-ic-fire:before {
    content: $bosch-ic-fire;
}
.bosch-ic-fire-emergency:before {
    content: $bosch-ic-fire-emergency;
}
.bosch-ic-fireworks:before {
    content: $bosch-ic-fireworks;
}
.bosch-ic-first-aid:before {
    content: $bosch-ic-first-aid;
}
.bosch-ic-first-aid-cross:before {
    content: $bosch-ic-first-aid-cross;
}
.bosch-ic-fit-to-sceen:before {
    content: $bosch-ic-fit-to-sceen;
}
.bosch-ic-flag:before {
    content: $bosch-ic-flag;
}
.bosch-ic-flash:before {
    content: $bosch-ic-flash;
}
.bosch-ic-flask:before {
    content: $bosch-ic-flask;
}
.bosch-ic-flexpress:before {
    content: $bosch-ic-flexpress;
}
.bosch-ic-folder:before {
    content: $bosch-ic-folder;
}
.bosch-ic-folder-download:before {
    content: $bosch-ic-folder-download;
}
.bosch-ic-folder-open:before {
    content: $bosch-ic-folder-open;
}
.bosch-ic-folder-upload:before {
    content: $bosch-ic-folder-upload;
}
.bosch-ic-fork-lift:before {
    content: $bosch-ic-fork-lift;
}
.bosch-ic-fork-lift-locator:before {
    content: $bosch-ic-fork-lift-locator;
}
.bosch-ic-forward-right:before {
    content: $bosch-ic-forward-right;
}
.bosch-ic-forward-right-double:before {
    content: $bosch-ic-forward-right-double;
}
.bosch-ic-full-empty:before {
    content: $bosch-ic-full-empty;
}
.bosch-ic-fullscreen:before {
    content: $bosch-ic-fullscreen;
}
.bosch-ic-fullscreen-arrows:before {
    content: $bosch-ic-fullscreen-arrows;
}
.bosch-ic-fullscreen-exit:before {
    content: $bosch-ic-fullscreen-exit;
}
.bosch-ic-g-arrow-down:before {
    content: $bosch-ic-g-arrow-down;
}
.bosch-ic-g-arrow-up:before {
    content: $bosch-ic-g-arrow-up;
}
.bosch-ic-generator:before {
    content: $bosch-ic-generator;
}
.bosch-ic-gift:before {
    content: $bosch-ic-gift;
}
.bosch-ic-glance:before {
    content: $bosch-ic-glance;
}
.bosch-ic-glas-plate:before {
    content: $bosch-ic-glas-plate;
}
.bosch-ic-globe:before {
    content: $bosch-ic-globe;
}
.bosch-ic-globe-arrow:before {
    content: $bosch-ic-globe-arrow;
}
.bosch-ic-glossary:before {
    content: $bosch-ic-glossary;
}
.bosch-ic-grid-view:before {
    content: $bosch-ic-grid-view;
}
.bosch-ic-hammer:before {
    content: $bosch-ic-hammer;
}
.bosch-ic-hand:before {
    content: $bosch-ic-hand;
}
.bosch-ic-hand-motion:before {
    content: $bosch-ic-hand-motion;
}
.bosch-ic-hand-motion-off-disabled-light:before {
    content: $bosch-ic-hand-motion-off-disabled-light;
}
.bosch-ic-handlewithcare:before {
    content: $bosch-ic-handlewithcare;
}
.bosch-ic-handover-add:before {
    content: $bosch-ic-handover-add;
}
.bosch-ic-handover-check:before {
    content: $bosch-ic-handover-check;
}
.bosch-ic-handover-remove:before {
    content: $bosch-ic-handover-remove;
}
.bosch-ic-hanger:before {
    content: $bosch-ic-hanger;
}
.bosch-ic-health:before {
    content: $bosch-ic-health;
}
.bosch-ic-heart:before {
    content: $bosch-ic-heart;
}
.bosch-ic-history:before {
    content: $bosch-ic-history;
}
.bosch-ic-home:before {
    content: $bosch-ic-home;
}
.bosch-ic-home-locator:before {
    content: $bosch-ic-home-locator;
}
.bosch-ic-hourglass:before {
    content: $bosch-ic-hourglass;
}
.bosch-ic-hydrant:before {
    content: $bosch-ic-hydrant;
}
.bosch-ic-ice:before {
    content: $bosch-ic-ice;
}
.bosch-ic-imagery:before {
    content: $bosch-ic-imagery;
}
.bosch-ic-imagery-add:before {
    content: $bosch-ic-imagery-add;
}
.bosch-ic-imagery-remove:before {
    content: $bosch-ic-imagery-remove;
}
.bosch-ic-import:before {
    content: $bosch-ic-import;
}
.bosch-ic-imprint:before {
    content: $bosch-ic-imprint;
}
.bosch-ic-impulse:before {
    content: $bosch-ic-impulse;
}
.bosch-ic-industry:before {
    content: $bosch-ic-industry;
}
.bosch-ic-industry-clock:before {
    content: $bosch-ic-industry-clock;
}
.bosch-ic-industry-connected:before {
    content: $bosch-ic-industry-connected;
}
.bosch-ic-info:before {
    content: $bosch-ic-info;
}
.bosch-ic-info-i:before {
    content: $bosch-ic-info-i;
}
.bosch-ic-interval:before {
    content: $bosch-ic-interval;
}
.bosch-ic-it-device:before {
    content: $bosch-ic-it-device;
}
.bosch-ic-jewel:before {
    content: $bosch-ic-jewel;
}
.bosch-ic-keyboard:before {
    content: $bosch-ic-keyboard;
}
.bosch-ic-label:before {
    content: $bosch-ic-label;
}
.bosch-ic-laptop:before {
    content: $bosch-ic-laptop;
}
.bosch-ic-laser:before {
    content: $bosch-ic-laser;
}
.bosch-ic-layout:before {
    content: $bosch-ic-layout;
}
.bosch-ic-leaf:before {
    content: $bosch-ic-leaf;
}
.bosch-ic-led:before {
    content: $bosch-ic-led;
}
.bosch-ic-brick:before {
    content: $bosch-ic-brick;
}
.bosch-ic-brick-slanting-1:before {
    content: $bosch-ic-brick-slanting-1;
}
.bosch-ic-brick-slanting-2:before {
    content: $bosch-ic-brick-slanting-2;
}
.bosch-ic-less-minimize:before {
    content: $bosch-ic-less-minimize;
}
.bosch-ic-lightbulb:before {
    content: $bosch-ic-lightbulb;
}
.bosch-ic-lightbulb-off:before {
    content: $bosch-ic-lightbulb-off;
}
.bosch-ic-line-chart:before {
    content: $bosch-ic-line-chart;
}
.bosch-ic-link:before {
    content: $bosch-ic-link;
}
.bosch-ic-link-broken:before {
    content: $bosch-ic-link-broken;
}
.bosch-ic-list-view:before {
    content: $bosch-ic-list-view;
}
.bosch-ic-list-view-mobile:before {
    content: $bosch-ic-list-view-mobile;
}
.bosch-ic-livechat:before {
    content: $bosch-ic-livechat;
}
.bosch-ic-locator:before {
    content: $bosch-ic-locator;
}
.bosch-ic-locator-ip-connected:before {
    content: $bosch-ic-locator-ip-connected;
}
.bosch-ic-locator-ip-disconnected:before {
    content: $bosch-ic-locator-ip-disconnected;
}
.bosch-ic-locator-spot:before {
    content: $bosch-ic-locator-spot;
}
.bosch-ic-locator-spot-check:before {
    content: $bosch-ic-locator-spot-check;
}
.bosch-ic-lock-closed:before {
    content: $bosch-ic-lock-closed;
}
.bosch-ic-lock-open:before {
    content: $bosch-ic-lock-open;
}
.bosch-ic-login:before {
    content: $bosch-ic-login;
}
.bosch-ic-logistics:before {
    content: $bosch-ic-logistics;
}
.bosch-ic-logout:before {
    content: $bosch-ic-logout;
}
.bosch-ic-machine:before {
    content: $bosch-ic-machine;
}
.bosch-ic-magnet:before {
    content: $bosch-ic-magnet;
}
.bosch-ic-mail:before {
    content: $bosch-ic-mail;
}
.bosch-ic-mail-forward:before {
    content: $bosch-ic-mail-forward;
}
.bosch-ic-mail-open:before {
    content: $bosch-ic-mail-open;
}
.bosch-ic-mail-out:before {
    content: $bosch-ic-mail-out;
}
.bosch-ic-mail-reply:before {
    content: $bosch-ic-mail-reply;
}
.bosch-ic-male:before {
    content: $bosch-ic-male;
}
.bosch-ic-map:before {
    content: $bosch-ic-map;
}
.bosch-ic-material-add:before {
    content: $bosch-ic-material-add;
}
.bosch-ic-material-check:before {
    content: $bosch-ic-material-check;
}
.bosch-ic-material-remove:before {
    content: $bosch-ic-material-remove;
}
.bosch-ic-maximize:before {
    content: $bosch-ic-maximize;
}
.bosch-ic-mechanic:before {
    content: $bosch-ic-mechanic;
}
.bosch-ic-megaphone:before {
    content: $bosch-ic-megaphone;
}
.bosch-ic-message:before {
    content: $bosch-ic-message;
}
.bosch-ic-mic:before {
    content: $bosch-ic-mic;
}
.bosch-ic-microphone:before {
    content: $bosch-ic-microphone;
}
.bosch-ic-microphone-classic:before {
    content: $bosch-ic-microphone-classic;
}
.bosch-ic-minimize:before {
    content: $bosch-ic-minimize;
}
.bosch-ic-money-currency:before {
    content: $bosch-ic-money-currency;
}
.bosch-ic-money-dollar:before {
    content: $bosch-ic-money-dollar;
}
.bosch-ic-moneybag-currency:before {
    content: $bosch-ic-moneybag-currency;
}
.bosch-ic-moneybag-dollar:before {
    content: $bosch-ic-moneybag-dollar;
}
.bosch-ic-monument:before {
    content: $bosch-ic-monument;
}
.bosch-ic-moon:before {
    content: $bosch-ic-moon;
}
.bosch-ic-motorcycle:before {
    content: $bosch-ic-motorcycle;
}
.bosch-ic-motorcycle-side-car:before {
    content: $bosch-ic-motorcycle-side-car;
}
.bosch-ic-motorcycle-side:before {
    content: $bosch-ic-motorcycle-side;
}
.bosch-ic-mouse:before {
    content: $bosch-ic-mouse;
}
.bosch-ic-mouse-left:before {
    content: $bosch-ic-mouse-left;
}
.bosch-ic-mouse-right:before {
    content: $bosch-ic-mouse-right;
}
.bosch-ic-mouth:before {
    content: $bosch-ic-mouth;
}
.bosch-ic-my-product:before {
    content: $bosch-ic-my-product;
}
.bosch-ic-new-way-work:before {
    content: $bosch-ic-new-way-work;
}
.bosch-ic-newsletter:before {
    content: $bosch-ic-newsletter;
}
.bosch-ic-newspaper:before {
    content: $bosch-ic-newspaper;
}
.bosch-ic-nose:before {
    content: $bosch-ic-nose;
}
.bosch-ic-notepad:before {
    content: $bosch-ic-notepad;
}
.bosch-ic-notepad-edit:before {
    content: $bosch-ic-notepad-edit;
}
.bosch-ic-notification:before {
    content: $bosch-ic-notification;
}
.bosch-ic-notification-active:before {
    content: $bosch-ic-notification-active;
}
.bosch-ic-notification-add:before {
    content: $bosch-ic-notification-add;
}
.bosch-ic-notification-remove:before {
    content: $bosch-ic-notification-remove;
}
.bosch-ic-oil-car:before {
    content: $bosch-ic-oil-car;
}
.bosch-ic-omnichannel:before {
    content: $bosch-ic-omnichannel;
}
.bosch-ic-options:before {
    content: $bosch-ic-options;
}
.bosch-ic-origami-boat:before {
    content: $bosch-ic-origami-boat;
}
.bosch-ic-pallete-car:before {
    content: $bosch-ic-pallete-car;
}
.bosch-ic-pallete-car-connected:before {
    content: $bosch-ic-pallete-car-connected;
}
.bosch-ic-panel:before {
    content: $bosch-ic-panel;
}
.bosch-ic-paperclip:before {
    content: $bosch-ic-paperclip;
}
.bosch-ic-paperplane:before {
    content: $bosch-ic-paperplane;
}
.bosch-ic-parking:before {
    content: $bosch-ic-parking;
}
.bosch-ic-pause:before {
    content: $bosch-ic-pause;
}
.bosch-ic-payment:before {
    content: $bosch-ic-payment;
}
.bosch-ic-people:before {
    content: $bosch-ic-people;
}
.bosch-ic-petrol-station:before {
    content: $bosch-ic-petrol-station;
}
.bosch-ic-piggybank:before {
    content: $bosch-ic-piggybank;
}
.bosch-ic-pin-classic:before {
    content: $bosch-ic-pin-classic;
}
.bosch-ic-pin-modern:before {
    content: $bosch-ic-pin-modern;
}
.bosch-ic-plane-side:before {
    content: $bosch-ic-plane-side;
}
.bosch-ic-play:before {
    content: $bosch-ic-play;
}
.bosch-ic-plug-12v:before {
    content: $bosch-ic-plug-12v;
}
.bosch-ic-podium-speaker:before {
    content: $bosch-ic-podium-speaker;
}
.bosch-ic-police:before {
    content: $bosch-ic-police;
}
.bosch-ic-poop:before {
    content: $bosch-ic-poop;
}
.bosch-ic-post-it:before {
    content: $bosch-ic-post-it;
}
.bosch-ic-postal-code:before {
    content: $bosch-ic-postal-code;
}
.bosch-ic-power:before {
    content: $bosch-ic-power;
}
.bosch-ic-print:before {
    content: $bosch-ic-print;
}
.bosch-ic-prototyping:before {
    content: $bosch-ic-prototyping;
}
.bosch-ic-puzzle:before {
    content: $bosch-ic-puzzle;
}
.bosch-ic-quad:before {
    content: $bosch-ic-quad;
}
.bosch-ic-question:before {
    content: $bosch-ic-question;
}
.bosch-ic-radar:before {
    content: $bosch-ic-radar;
}
.bosch-ic-radio:before {
    content: $bosch-ic-radio;
}
.bosch-ic-radiotower:before {
    content: $bosch-ic-radiotower;
}
.bosch-ic-redirect:before {
    content: $bosch-ic-redirect;
}
.bosch-ic-redo:before {
    content: $bosch-ic-redo;
}
.bosch-ic-reference:before {
    content: $bosch-ic-reference;
}
.bosch-ic-refresh:before {
    content: $bosch-ic-refresh;
}
.bosch-ic-refresh-cloud:before {
    content: $bosch-ic-refresh-cloud;
}
.bosch-ic-registration:before {
    content: $bosch-ic-registration;
}
.bosch-ic-remote:before {
    content: $bosch-ic-remote;
}
.bosch-ic-reset:before {
    content: $bosch-ic-reset;
}
.bosch-ic-resolution:before {
    content: $bosch-ic-resolution;
}
.bosch-ic-robot:before {
    content: $bosch-ic-robot;
}
.bosch-ic-robot-connected:before {
    content: $bosch-ic-robot-connected;
}
.bosch-ic-robothead:before {
    content: $bosch-ic-robothead;
}
.bosch-ic-rocket:before {
    content: $bosch-ic-rocket;
}
.bosch-ic-rotation:before {
    content: $bosch-ic-rotation;
}
.bosch-ic-rotation-360:before {
    content: $bosch-ic-rotation-360;
}
.bosch-ic-rotation-x-left:before {
    content: $bosch-ic-rotation-x-left;
}
.bosch-ic-rotation-x-right:before {
    content: $bosch-ic-rotation-x-right;
}
.bosch-ic-rotation-y-down:before {
    content: $bosch-ic-rotation-y-down;
}
.bosch-ic-rotation-y-up:before {
    content: $bosch-ic-rotation-y-up;
}
.bosch-ic-route:before {
    content: $bosch-ic-route;
}
.bosch-ic-ruler:before {
    content: $bosch-ic-ruler;
}
.bosch-ic-ruler-pen:before {
    content: $bosch-ic-ruler-pen;
}
.bosch-ic-satellite:before {
    content: $bosch-ic-satellite;
}
.bosch-ic-save:before {
    content: $bosch-ic-save;
}
.bosch-ic-scale:before {
    content: $bosch-ic-scale;
}
.bosch-ic-scissors:before {
    content: $bosch-ic-scissors;
}
.bosch-ic-search:before {
    content: $bosch-ic-search;
}
.bosch-ic-security:before {
    content: $bosch-ic-security;
}
.bosch-ic-security-check:before {
    content: $bosch-ic-security-check;
}
.bosch-ic-security-user:before {
    content: $bosch-ic-security-user;
}
.bosch-ic-sensor:before {
    content: $bosch-ic-sensor;
}
.bosch-ic-server:before {
    content: $bosch-ic-server;
}
.bosch-ic-server-expansion:before {
    content: $bosch-ic-server-expansion;
}
.bosch-ic-server-rate:before {
    content: $bosch-ic-server-rate;
}
.bosch-ic-service-agent:before {
    content: $bosch-ic-service-agent;
}
.bosch-ic-service-time:before {
    content: $bosch-ic-service-time;
}
.bosch-ic-settings:before {
    content: $bosch-ic-settings;
}
.bosch-ic-settings-arrows:before {
    content: $bosch-ic-settings-arrows;
}
.bosch-ic-settings-connected:before {
    content: $bosch-ic-settings-connected;
}
.bosch-ic-share:before {
    content: $bosch-ic-share;
}
.bosch-ic-shield-stop:before {
    content: $bosch-ic-shield-stop;
}
.bosch-ic-ship:before {
    content: $bosch-ic-ship;
}
.bosch-ic-ship-side:before {
    content: $bosch-ic-ship-side;
}
.bosch-ic-shoppingcart:before {
    content: $bosch-ic-shoppingcart;
}
.bosch-ic-shoppingcart-add:before {
    content: $bosch-ic-shoppingcart-add;
}
.bosch-ic-shoppingcart-switch:before {
    content: $bosch-ic-shoppingcart-switch;
}
.bosch-ic-signal-full:before {
    content: $bosch-ic-signal-full;
}
.bosch-ic-signal-half:before {
    content: $bosch-ic-signal-half;
}
.bosch-ic-signal-lost:before {
    content: $bosch-ic-signal-lost;
}
.bosch-ic-signpost:before {
    content: $bosch-ic-signpost;
}
.bosch-ic-simcard:before {
    content: $bosch-ic-simcard;
}
.bosch-ic-skyscraper:before {
    content: $bosch-ic-skyscraper;
}
.bosch-ic-smartcity:before {
    content: $bosch-ic-smartcity;
}
.bosch-ic-smarthome:before {
    content: $bosch-ic-smarthome;
}
.bosch-ic-smartphone:before {
    content: $bosch-ic-smartphone;
}
.bosch-ic-smartphone-acoustic:before {
    content: $bosch-ic-smartphone-acoustic;
}
.bosch-ic-smartphone-arrow-right:before {
    content: $bosch-ic-smartphone-arrow-right;
}
.bosch-ic-smartphone-arrows-x-y-z:before {
    content: $bosch-ic-smartphone-arrows-x-y-z;
}
.bosch-ic-smartphone-clock:before {
    content: $bosch-ic-smartphone-clock;
}
.bosch-ic-smartphone-cloud:before {
    content: $bosch-ic-smartphone-cloud;
}
.bosch-ic-smartphone-loading-wheel:before {
    content: $bosch-ic-smartphone-loading-wheel;
}
.bosch-ic-smartphone-smashed:before {
    content: $bosch-ic-smartphone-smashed;
}
.bosch-ic-smartphone-vibration:before {
    content: $bosch-ic-smartphone-vibration;
}
.bosch-ic-smartphone-z:before {
    content: $bosch-ic-smartphone-z;
}
.bosch-ic-smartwatch-connected:before {
    content: $bosch-ic-smartwatch-connected;
}
.bosch-ic-spare-parts:before {
    content: $bosch-ic-spare-parts;
}
.bosch-ic-spark-plug:before {
    content: $bosch-ic-spark-plug;
}
.bosch-ic-speaker-acoustic:before {
    content: $bosch-ic-speaker-acoustic;
}
.bosch-ic-speedometer:before {
    content: $bosch-ic-speedometer;
}
.bosch-ic-stadium:before {
    content: $bosch-ic-stadium;
}
.bosch-ic-stairs:before {
    content: $bosch-ic-stairs;
}
.bosch-ic-stamp:before {
    content: $bosch-ic-stamp;
}
.bosch-ic-standby:before {
    content: $bosch-ic-standby;
}
.bosch-ic-steering-wheel:before {
    content: $bosch-ic-steering-wheel;
}
.bosch-ic-steering-wheel-connected:before {
    content: $bosch-ic-steering-wheel-connected;
}
.bosch-ic-steering-wheel-hands:before {
    content: $bosch-ic-steering-wheel-hands;
}
.bosch-ic-steps:before {
    content: $bosch-ic-steps;
}
.bosch-ic-stethoscope:before {
    content: $bosch-ic-stethoscope;
}
.bosch-ic-stop:before {
    content: $bosch-ic-stop;
}
.bosch-ic-stopwatch:before {
    content: $bosch-ic-stopwatch;
}
.bosch-ic-storage-local:before {
    content: $bosch-ic-storage-local;
}
.bosch-ic-structure:before {
    content: $bosch-ic-structure;
}
.bosch-ic-subtitles:before {
    content: $bosch-ic-subtitles;
}
.bosch-ic-subtitles-off-light:before {
    content: $bosch-ic-subtitles-off-light;
}
.bosch-ic-summary:before {
    content: $bosch-ic-summary;
}
.bosch-ic-sun:before {
    content: $bosch-ic-sun;
}
.bosch-ic-sun-half-filled:before {
    content: $bosch-ic-sun-half-filled;
}
.bosch-ic-sunshade-table:before {
    content: $bosch-ic-sunshade-table;
}
.bosch-ic-surveillance:before {
    content: $bosch-ic-surveillance;
}
.bosch-ic-table-chairs:before {
    content: $bosch-ic-table-chairs;
}
.bosch-ic-tablet:before {
    content: $bosch-ic-tablet;
}
.bosch-ic-team:before {
    content: $bosch-ic-team;
}
.bosch-ic-team-lightbulb:before {
    content: $bosch-ic-team-lightbulb;
}
.bosch-ic-technical-service:before {
    content: $bosch-ic-technical-service;
}
.bosch-ic-temperature:before {
    content: $bosch-ic-temperature;
}
.bosch-ic-temperature-high:before {
    content: $bosch-ic-temperature-high;
}
.bosch-ic-temperature-low:before {
    content: $bosch-ic-temperature-low;
}
.bosch-ic-theater:before {
    content: $bosch-ic-theater;
}
.bosch-ic-thumb-down:before {
    content: $bosch-ic-thumb-down;
}
.bosch-ic-thumb-up:before {
    content: $bosch-ic-thumb-up;
}
.bosch-ic-ticket:before {
    content: $bosch-ic-ticket;
}
.bosch-ic-touch:before {
    content: $bosch-ic-touch;
}
.bosch-ic-touch-sos:before {
    content: $bosch-ic-touch-sos;
}
.bosch-ic-towing-truck:before {
    content: $bosch-ic-towing-truck;
}
.bosch-ic-train:before {
    content: $bosch-ic-train;
}
.bosch-ic-train-side:before {
    content: $bosch-ic-train-side;
}
.bosch-ic-transport-movements:before {
    content: $bosch-ic-transport-movements;
}
.bosch-ic-transscript:before {
    content: $bosch-ic-transscript;
}
.bosch-ic-transscript-off:before {
    content: $bosch-ic-transscript-off;
}
.bosch-ic-transscript-on:before {
    content: $bosch-ic-transscript-on;
}
.bosch-ic-tree:before {
    content: $bosch-ic-tree;
}
.bosch-ic-tricycle:before {
    content: $bosch-ic-tricycle;
}
.bosch-ic-trolley-empty:before {
    content: $bosch-ic-trolley-empty;
}
.bosch-ic-trolley-filled-box:before {
    content: $bosch-ic-trolley-filled-box;
}
.bosch-ic-trolly-filled-files:before {
    content: $bosch-ic-trolly-filled-files;
}
.bosch-ic-trophy:before {
    content: $bosch-ic-trophy;
}
.bosch-ic-truck:before {
    content: $bosch-ic-truck;
}
.bosch-ic-tuktuk:before {
    content: $bosch-ic-tuktuk;
}
.bosch-ic-tune:before {
    content: $bosch-ic-tune;
}
.bosch-ic-typography:before {
    content: $bosch-ic-typography;
}
.bosch-ic-ui:before {
    content: $bosch-ic-ui;
}
.bosch-ic-umbrella:before {
    content: $bosch-ic-umbrella;
}
.bosch-ic-undo:before {
    content: $bosch-ic-undo;
}
.bosch-ic-up:before {
    content: $bosch-ic-up;
}
.bosch-ic-up-double:before {
    content: $bosch-ic-up-double;
}
.bosch-ic-up-down:before {
    content: $bosch-ic-up-down;
}
.bosch-ic-upload:before {
    content: $bosch-ic-upload;
}
.bosch-ic-usb:before {
    content: $bosch-ic-usb;
}
.bosch-ic-user:before {
    content: $bosch-ic-user;
}
.bosch-ic-user-advanced:before {
    content: $bosch-ic-user-advanced;
}
.bosch-ic-user-beginner:before {
    content: $bosch-ic-user-beginner;
}
.bosch-ic-user-desktop:before {
    content: $bosch-ic-user-desktop;
}
.bosch-ic-user-down:before {
    content: $bosch-ic-user-down;
}
.bosch-ic-user-falling:before {
    content: $bosch-ic-user-falling;
}
.bosch-ic-user-hand:before {
    content: $bosch-ic-user-hand;
}
.bosch-ic-user-hand-stop:before {
    content: $bosch-ic-user-hand-stop;
}
.bosch-ic-user-professional:before {
    content: $bosch-ic-user-professional;
}
.bosch-ic-user-run:before {
    content: $bosch-ic-user-run;
}
.bosch-ic-user-share:before {
    content: $bosch-ic-user-share;
}
.bosch-ic-user-voice:before {
    content: $bosch-ic-user-voice;
}
.bosch-ic-user-walk:before {
    content: $bosch-ic-user-walk;
}
.bosch-ic-video:before {
    content: $bosch-ic-video;
}
.bosch-ic-video-disabled-light:before {
    content: $bosch-ic-video-disabled-light;
}
.bosch-ic-video-record:before {
    content: $bosch-ic-video-record;
}
.bosch-ic-virtual-reality:before {
    content: $bosch-ic-virtual-reality;
}
.bosch-ic-volume-disabled:before {
    content: $bosch-ic-volume-disabled;
}
.bosch-ic-volume-high:before {
    content: $bosch-ic-volume-high;
}
.bosch-ic-volume-low:before {
    content: $bosch-ic-volume-low;
}
.bosch-ic-volume-off-light:before {
    content: $bosch-ic-volume-off-light;
}
.bosch-ic-wand:before {
    content: $bosch-ic-wand;
}
.bosch-ic-warranty:before {
    content: $bosch-ic-warranty;
}
.bosch-ic-watch-off-disabled-light:before {
    content: $bosch-ic-watch-off-disabled-light;
}
.bosch-ic-watch-on:before {
    content: $bosch-ic-watch-on;
}
.bosch-ic-water-shower:before {
    content: $bosch-ic-water-shower;
}
.bosch-ic-welcome:before {
    content: $bosch-ic-welcome;
}
.bosch-ic-wellness:before {
    content: $bosch-ic-wellness;
}
.bosch-ic-wheelchair:before {
    content: $bosch-ic-wheelchair;
}
.bosch-ic-whistle:before {
    content: $bosch-ic-whistle;
}
.bosch-ic-wide-angled-arrow:before {
    content: $bosch-ic-wide-angled-arrow;
}
.bosch-ic-wifi:before {
    content: $bosch-ic-wifi;
}
.bosch-ic-window-analysis:before {
    content: $bosch-ic-window-analysis;
}
.bosch-ic-window-browser:before {
    content: $bosch-ic-window-browser;
}
.bosch-ic-window-console:before {
    content: $bosch-ic-window-console;
}
.bosch-ic-window-gaussian-view:before {
    content: $bosch-ic-window-gaussian-view;
}
.bosch-ic-window-info:before {
    content: $bosch-ic-window-info;
}
.bosch-ic-window-new:before {
    content: $bosch-ic-window-new;
}
.bosch-ic-window-resize:before {
    content: $bosch-ic-window-resize;
}
.bosch-ic-window-scaling-view:before {
    content: $bosch-ic-window-scaling-view;
}
.bosch-ic-window-shuffle:before {
    content: $bosch-ic-window-shuffle;
}
.bosch-ic-window-statistic:before {
    content: $bosch-ic-window-statistic;
}
.bosch-ic-wishlist:before {
    content: $bosch-ic-wishlist;
}
.bosch-ic-work-order:before {
    content: $bosch-ic-work-order;
}
.bosch-ic-worldwideweb:before {
    content: $bosch-ic-worldwideweb;
}
.bosch-ic-wrench:before {
    content: $bosch-ic-wrench;
}
.bosch-ic-zoom-in:before {
    content: $bosch-ic-zoom-in;
}
.bosch-ic-zoom-out:before {
    content: $bosch-ic-zoom-out;
}
.bosch-ic-scooter:before {
    content: $bosch-ic-scooter;
}
.bosch-ic-cars-traffic:before {
    content: $bosch-ic-cars-traffic;
}
.bosch-ic-arm-chair:before {
    content: $bosch-ic-arm-chair;
}
.bosch-ic-car-phone:before {
    content: $bosch-ic-car-phone;
}
.bosch-ic-box-lightbulb:before {
    content: $bosch-ic-box-lightbulb;
}
.bosch-ic-chair-officedesk:before {
    content: $bosch-ic-chair-officedesk;
}
.bosch-ic-calendar-clock:before {
    content: $bosch-ic-calendar-clock;
}
.bosch-ic-document-cv:before {
    content: $bosch-ic-document-cv;
}
.bosch-ic-user-run-bag:before {
    content: $bosch-ic-user-run-bag;
}
.bosch-ic-brake-disk-spray:before {
    content: $bosch-ic-brake-disk-spray;
}
.bosch-ic-circle-abs:before {
    content: $bosch-ic-circle-abs;
}
.bosch-ic-bright-down:before {
    content: $bosch-ic-bright-down;
}
.bosch-ic-bright-up:before {
    content: $bosch-ic-bright-up;
}
.bosch-ic-fan:before {
    content: $bosch-ic-fan;
}
.bosch-ic-sun-moon:before {
    content: $bosch-ic-sun-moon;
}
.bosch-ic-lightbulb-attention:before {
    content: $bosch-ic-lightbulb-attention;
}
.bosch-ic-settings-attention:before {
    content: $bosch-ic-settings-attention;
}
.bosch-ic-danger-hazards:before {
    content: $bosch-ic-danger-hazards;
}
.bosch-ic-windshield-spray:before {
    content: $bosch-ic-windshield-spray;
}
.bosch-ic-windshield-heat:before {
    content: $bosch-ic-windshield-heat;
}
.bosch-ic-light-drop:before {
    content: $bosch-ic-light-drop;
}
.bosch-ic-starter:before {
    content: $bosch-ic-starter;
}
.bosch-ic-turbo:before {
    content: $bosch-ic-turbo;
}
.bosch-ic-settings-lock:before {
    content: $bosch-ic-settings-lock;
}
.bosch-ic-settings-n:before {
    content: $bosch-ic-settings-n;
}
.bosch-ic-light-beam-high:before {
    content: $bosch-ic-light-beam-high;
}
.bosch-ic-light-beam-low:before {
    content: $bosch-ic-light-beam-low;
}
.bosch-ic-glas:before {
    content: $bosch-ic-glas;
}
.bosch-ic-shirt-ice:before {
    content: $bosch-ic-shirt-ice;
}
.bosch-ic-helmet-fan:before {
    content: $bosch-ic-helmet-fan;
}
.bosch-ic-notification-arrow:before {
    content: $bosch-ic-notification-arrow;
}
.bosch-ic-steering-wheel-arrow:before {
    content: $bosch-ic-steering-wheel-arrow;
}
.bosch-ic-circle-arrow:before {
    content: $bosch-ic-circle-arrow;
}
.bosch-ic-tempomat-pit:before {
    content: $bosch-ic-tempomat-pit;
}
.bosch-ic-radiator-fan-spray:before {
    content: $bosch-ic-radiator-fan-spray;
}
.bosch-ic-boost-down:before {
    content: $bosch-ic-boost-down;
}
.bosch-ic-boost-up:before {
    content: $bosch-ic-boost-up;
}
.bosch-ic-circle-lock:before {
    content: $bosch-ic-circle-lock;
}
.bosch-ic-mp-down:before {
    content: $bosch-ic-mp-down;
}
.bosch-ic-mp-up:before {
    content: $bosch-ic-mp-up;
}
.bosch-ic-tc-down:before {
    content: $bosch-ic-tc-down;
}
.bosch-ic-tc-up:before {
    content: $bosch-ic-tc-up;
}
.bosch-ic-danger-arrow-attention:before {
    content: $bosch-ic-danger-arrow-attention;
}
.bosch-ic-user-remove:before {
    content: $bosch-ic-user-remove;
}
.bosch-ic-user-add:before {
    content: $bosch-ic-user-add;
}
.bosch-ic-post-it-collection:before {
    content: $bosch-ic-post-it-collection;
}
.bosch-ic-horn:before {
    content: $bosch-ic-horn;
}
.bosch-ic-desktop-notification:before {
    content: $bosch-ic-desktop-notification;
}
.bosch-ic-router:before {
    content: $bosch-ic-router;
}
.bosch-ic-berry:before {
    content: $bosch-ic-berry;
}
.bosch-ic-chat-language:before {
    content: $bosch-ic-chat-language;
}
.bosch-ic-game-controller:before {
    content: $bosch-ic-game-controller;
}
.bosch-ic-user-brain:before {
    content: $bosch-ic-user-brain;
}
.bosch-ic-mouth-finger:before {
    content: $bosch-ic-mouth-finger;
}
.bosch-ic-excavator-shovel:before {
    content: $bosch-ic-excavator-shovel;
}
.bosch-ic-ear-in:before {
    content: $bosch-ic-ear-in;
}
.bosch-ic-ear-out:before {
    content: $bosch-ic-ear-out;
}
.bosch-ic-lion:before {
    content: $bosch-ic-lion;
}
.bosch-ic-car-convertible:before {
    content: $bosch-ic-car-convertible;
}
.bosch-ic-car-sports:before {
    content: $bosch-ic-car-sports;
}
.bosch-ic-bear:before {
    content: $bosch-ic-bear;
}
.bosch-ic-pot:before {
    content: $bosch-ic-pot;
}
.bosch-ic-bed-double:before {
    content: $bosch-ic-bed-double;
}
.bosch-ic-air-hot:before {
    content: $bosch-ic-air-hot;
}
.bosch-ic-air-ice:before {
    content: $bosch-ic-air-ice;
}
.bosch-ic-air-leaf:before {
    content: $bosch-ic-air-leaf;
}
.bosch-ic-air-purifier-0:before {
    content: $bosch-ic-air-purifier-0;
}
.bosch-ic-air-purifier-1:before {
    content: $bosch-ic-air-purifier-1;
}
.bosch-ic-air-purifier-2:before {
    content: $bosch-ic-air-purifier-2;
}
.bosch-ic-air-purifier-3:before {
    content: $bosch-ic-air-purifier-3;
}
.bosch-ic-air:before {
    content: $bosch-ic-air;
}
.bosch-ic-fan-off-disabled-bold:before {
    content: $bosch-ic-fan-off-disabled-bold;
}
.bosch-ic-ice-off-disabled-bold:before {
    content: $bosch-ic-ice-off-disabled-bold;
}
.bosch-ic-faucet-boost:before {
    content: $bosch-ic-faucet-boost;
}
.bosch-ic-faucet-mode:before {
    content: $bosch-ic-faucet-mode;
}
.bosch-ic-faucet-off-disabled-bold:before {
    content: $bosch-ic-faucet-off-disabled-bold;
}
.bosch-ic-faucet-schedule:before {
    content: $bosch-ic-faucet-schedule;
}
.bosch-ic-fuel-cell:before {
    content: $bosch-ic-fuel-cell;
}
.bosch-ic-heating-floor-off-disabled-bold:before {
    content: $bosch-ic-heating-floor-off-disabled-bold;
}
.bosch-ic-heating-floor:before {
    content: $bosch-ic-heating-floor;
}
.bosch-ic-heating-radiator-boost:before {
    content: $bosch-ic-heating-radiator-boost;
}
.bosch-ic-heating-radiator-mode:before {
    content: $bosch-ic-heating-radiator-mode;
}
.bosch-ic-heating-radiator-off-disabled-bold:before {
    content: $bosch-ic-heating-radiator-off-disabled-bold;
}
.bosch-ic-heating-radiator-schedule:before {
    content: $bosch-ic-heating-radiator-schedule;
}
.bosch-ic-leaf-simple:before {
    content: $bosch-ic-leaf-simple;
}
.bosch-ic-oil-barrel:before {
    content: $bosch-ic-oil-barrel;
}
.bosch-ic-water-bathtub:before {
    content: $bosch-ic-water-bathtub;
}
.bosch-ic-water-dishwash:before {
    content: $bosch-ic-water-dishwash;
}
.bosch-ic-water-handwash:before {
    content: $bosch-ic-water-handwash;
}
.bosch-ic-water-hot:before {
    content: $bosch-ic-water-hot;
}
.bosch-ic-water-ice:before {
    content: $bosch-ic-water-ice;
}
.bosch-ic-water-leaf:before {
    content: $bosch-ic-water-leaf;
}
.bosch-ic-water-ladder:before {
    content: $bosch-ic-water-ladder;
}
.bosch-ic-water-purifier-0:before {
    content: $bosch-ic-water-purifier-0;
}
.bosch-ic-water-purifier-1:before {
    content: $bosch-ic-water-purifier-1;
}
.bosch-ic-water-purifier-2:before {
    content: $bosch-ic-water-purifier-2;
}
.bosch-ic-water-purifier-3:before {
    content: $bosch-ic-water-purifier-3;
}
.bosch-ic-water-shower-boost:before {
    content: $bosch-ic-water-shower-boost;
}
.bosch-ic-water-shower-mode:before {
    content: $bosch-ic-water-shower-mode;
}
.bosch-ic-water-shower-off-disabled-bold:before {
    content: $bosch-ic-water-shower-off-disabled-bold;
}
.bosch-ic-water-shower-schedule:before {
    content: $bosch-ic-water-shower-schedule;
}
.bosch-ic-water:before {
    content: $bosch-ic-water;
}
.bosch-ic-chart-bar-drop:before {
    content: $bosch-ic-chart-bar-drop;
}
.bosch-ic-chart-bar-fire:before {
    content: $bosch-ic-chart-bar-fire;
}
.bosch-ic-chart-bar-flash:before {
    content: $bosch-ic-chart-bar-flash;
}
.bosch-ic-chart-bar-oil:before {
    content: $bosch-ic-chart-bar-oil;
}
.bosch-ic-drop-minus:before {
    content: $bosch-ic-drop-minus;
}
.bosch-ic-drop-plus:before {
    content: $bosch-ic-drop-plus;
}
.bosch-ic-fan-minus:before {
    content: $bosch-ic-fan-minus;
}
.bosch-ic-fan-plus:before {
    content: $bosch-ic-fan-plus;
}
.bosch-ic-home-drops-0:before {
    content: $bosch-ic-home-drops-0;
}
.bosch-ic-home-drops-1:before {
    content: $bosch-ic-home-drops-1;
}
.bosch-ic-home-drops-2:before {
    content: $bosch-ic-home-drops-2;
}
.bosch-ic-home-drops-3:before {
    content: $bosch-ic-home-drops-3;
}
.bosch-ic-home-temperature-in:before {
    content: $bosch-ic-home-temperature-in;
}
.bosch-ic-home-temperature-out:before {
    content: $bosch-ic-home-temperature-out;
}
.bosch-ic-home-user-in:before {
    content: $bosch-ic-home-user-in;
}
.bosch-ic-ice-minus:before {
    content: $bosch-ic-ice-minus;
}
.bosch-ic-ice-plus:before {
    content: $bosch-ic-ice-plus;
}
.bosch-ic-chimney-sweeper:before {
    content: $bosch-ic-chimney-sweeper;
}
.bosch-ic-menu-up:before {
    content: $bosch-ic-menu-up;
}
.bosch-ic-security-ice:before {
    content: $bosch-ic-security-ice;
}
.bosch-ic-security-legionella:before {
    content: $bosch-ic-security-legionella;
}
.bosch-ic-trolley-case:before {
    content: $bosch-ic-trolley-case;
}
.bosch-ic-sun-minus:before {
    content: $bosch-ic-sun-minus;
}
.bosch-ic-sun-plug:before {
    content: $bosch-ic-sun-plug;
}
.bosch-ic-sun-plus:before {
    content: $bosch-ic-sun-plus;
}
.bosch-ic-touch-pit:before {
    content: $bosch-ic-touch-pit;
}
.bosch-ic-temperature-current:before {
    content: $bosch-ic-temperature-current;
}
.bosch-ic-temperature-set:before {
    content: $bosch-ic-temperature-set;
}
.bosch-ic-teddy-bear:before {
    content: $bosch-ic-teddy-bear;
}
.bosch-ic-document-svg:before {
    content: $bosch-ic-document-svg;
}
.bosch-ic-back-menu:before {
    content: $bosch-ic-back-menu;
}
.bosch-ic-car-arrows:before {
    content: $bosch-ic-car-arrows;
}
.bosch-ic-wrench-cloud:before {
    content: $bosch-ic-wrench-cloud;
}
.bosch-ic-weather-cloud-sun:before {
    content: $bosch-ic-weather-cloud-sun;
}
.bosch-ic-weather-cloudy:before {
    content: $bosch-ic-weather-cloudy;
}
.bosch-ic-weather-gale:before {
    content: $bosch-ic-weather-gale;
}
.bosch-ic-weather-mist:before {
    content: $bosch-ic-weather-mist;
}
.bosch-ic-weather-moon-cloud:before {
    content: $bosch-ic-weather-moon-cloud;
}
.bosch-ic-weather-rain-snow:before {
    content: $bosch-ic-weather-rain-snow;
}
.bosch-ic-weather-rain-thunder:before {
    content: $bosch-ic-weather-rain-thunder;
}
.bosch-ic-weather-rain:before {
    content: $bosch-ic-weather-rain;
}
.bosch-ic-weather-snow:before {
    content: $bosch-ic-weather-snow;
}
.bosch-ic-weather-thunder:before {
    content: $bosch-ic-weather-thunder;
}
.bosch-ic-weather-wind:before {
    content: $bosch-ic-weather-wind;
}
.bosch-ic-plane-start:before {
    content: $bosch-ic-plane-start;
}
.bosch-ic-plane-land:before {
    content: $bosch-ic-plane-land;
}
.bosch-ic-graph-power:before {
    content: $bosch-ic-graph-power;
}
.bosch-ic-floorplan:before {
    content: $bosch-ic-floorplan;
}
.bosch-ic-clock-pause:before {
    content: $bosch-ic-clock-pause;
}
.bosch-ic-clock-start:before {
    content: $bosch-ic-clock-start;
}
.bosch-ic-clock-stop:before {
    content: $bosch-ic-clock-stop;
}
.bosch-ic-computer-retro:before {
    content: $bosch-ic-computer-retro;
}
.bosch-ic-smartphone-retro:before {
    content: $bosch-ic-smartphone-retro;
}
.bosch-ic-wood:before {
    content: $bosch-ic-wood;
}
.bosch-ic-solid-fuel:before {
    content: $bosch-ic-solid-fuel;
}
.bosch-ic-appliance-fan:before {
    content: $bosch-ic-appliance-fan;
}
.bosch-ic-appliance-flame:before {
    content: $bosch-ic-appliance-flame;
}
.bosch-ic-appliance-ice:before {
    content: $bosch-ic-appliance-ice;
}
.bosch-ic-appliance-oil:before {
    content: $bosch-ic-appliance-oil;
}
.bosch-ic-appliance-solid-fuel:before {
    content: $bosch-ic-appliance-solid-fuel;
}
.bosch-ic-appliance-sun-ice:before {
    content: $bosch-ic-appliance-sun-ice;
}
.bosch-ic-appliance-sun:before {
    content: $bosch-ic-appliance-sun;
}
.bosch-ic-appliance-wood:before {
    content: $bosch-ic-appliance-wood;
}
.bosch-ic-appliance-air-leaf:before {
    content: $bosch-ic-appliance-air-leaf;
}
.bosch-ic-appliance-water-air:before {
    content: $bosch-ic-appliance-water-air;
}
.bosch-ic-appliance-water-leaf:before {
    content: $bosch-ic-appliance-water-leaf;
}
.bosch-ic-appliance-water-oil:before {
    content: $bosch-ic-appliance-water-oil;
}
.bosch-ic-appliance-water:before {
    content: $bosch-ic-appliance-water;
}
.bosch-ic-appliance-heat-pump-air:before {
    content: $bosch-ic-appliance-heat-pump-air;
}
.bosch-ic-appliance-heat-pump-ground:before {
    content: $bosch-ic-appliance-heat-pump-ground;
}
.bosch-ic-appliance-heat-pump-water:before {
    content: $bosch-ic-appliance-heat-pump-water;
}
.bosch-ic-appliance-plug-air:before {
    content: $bosch-ic-appliance-plug-air;
}
.bosch-ic-appliance-plug-fuel-cell:before {
    content: $bosch-ic-appliance-plug-fuel-cell;
}
.bosch-ic-appliance-plug-leaf:before {
    content: $bosch-ic-appliance-plug-leaf;
}
.bosch-ic-appliance-plug-water-air:before {
    content: $bosch-ic-appliance-plug-water-air;
}
.bosch-ic-thermostat-connected-c:before {
    content: $bosch-ic-thermostat-connected-c;
}
.bosch-ic-thermostat-connected-f:before {
    content: $bosch-ic-thermostat-connected-f;
}
.bosch-ic-image-gallery:before {
    content: $bosch-ic-image-gallery;
}
.bosch-ic-arrows-caution:before {
    content: $bosch-ic-arrows-caution;
}
.bosch-ic-arrows-diagonal-disabled-bold:before {
    content: $bosch-ic-arrows-diagonal-disabled-bold;
}
.bosch-ic-back-left-end:before {
    content: $bosch-ic-back-left-end;
}
.bosch-ic-back-right-end:before {
    content: $bosch-ic-back-right-end;
}
.bosch-ic-bar-chart-search:before {
    content: $bosch-ic-bar-chart-search;
}
.bosch-ic-batch:before {
    content: $bosch-ic-batch;
}
.bosch-ic-calendar-remove:before {
    content: $bosch-ic-calendar-remove;
}
.bosch-ic-call-bluetooth:before {
    content: $bosch-ic-call-bluetooth;
}
.bosch-ic-call-deny:before {
    content: $bosch-ic-call-deny;
}
.bosch-ic-call-settings:before {
    content: $bosch-ic-call-settings;
}
.bosch-ic-caution-sign-clock:before {
    content: $bosch-ic-caution-sign-clock;
}
.bosch-ic-caution-sign-list:before {
    content: $bosch-ic-caution-sign-list;
}
.bosch-ic-core-data:before {
    content: $bosch-ic-core-data;
}
.bosch-ic-cursor-connected:before {
    content: $bosch-ic-cursor-connected;
}
.bosch-ic-cursor:before {
    content: $bosch-ic-cursor;
}
.bosch-ic-dmc-code:before {
    content: $bosch-ic-dmc-code;
}
.bosch-ic-find-id:before {
    content: $bosch-ic-find-id;
}
.bosch-ic-globe-search:before {
    content: $bosch-ic-globe-search;
}
.bosch-ic-interface:before {
    content: $bosch-ic-interface;
}
.bosch-ic-material-add-manually:before {
    content: $bosch-ic-material-add-manually;
}
.bosch-ic-material-list:before {
    content: $bosch-ic-material-list;
}
.bosch-ic-material-remove-manually:before {
    content: $bosch-ic-material-remove-manually;
}
.bosch-ic-material:before {
    content: $bosch-ic-material;
}
.bosch-ic-menu-checkmark:before {
    content: $bosch-ic-menu-checkmark;
}
.bosch-ic-qr-code:before {
    content: $bosch-ic-qr-code;
}
.bosch-ic-sort-alphabetically:before {
    content: $bosch-ic-sort-alphabetically;
}
.bosch-ic-unpin-classic-disabled-bold:before {
    content: $bosch-ic-unpin-classic-disabled-bold;
}
.bosch-ic-unpin-modern-disabled-bold:before {
    content: $bosch-ic-unpin-modern-disabled-bold;
}
.bosch-ic-window-search:before {
    content: $bosch-ic-window-search;
}
.bosch-ic-cloud-clock:before {
    content: $bosch-ic-cloud-clock;
}
.bosch-ic-concierge:before {
    content: $bosch-ic-concierge;
}
.bosch-ic-heating-radiator:before {
    content: $bosch-ic-heating-radiator;
}
.bosch-ic-home-user-out:before {
    content: $bosch-ic-home-user-out;
}
.bosch-ic-hot:before {
    content: $bosch-ic-hot;
}
.bosch-ic-screen-service:before {
    content: $bosch-ic-screen-service;
}
.bosch-ic-sun-ice:before {
    content: $bosch-ic-sun-ice;
}
.bosch-ic-sun-off-disabled-bold:before {
    content: $bosch-ic-sun-off-disabled-bold;
}
.bosch-ic-tractor:before {
    content: $bosch-ic-tractor;
}
.bosch-ic-video-recorder-digital:before {
    content: $bosch-ic-video-recorder-digital;
}
.bosch-ic-windshield-wiper:before {
    content: $bosch-ic-windshield-wiper;
}
.bosch-ic-weather-snow-thunder:before {
    content: $bosch-ic-weather-snow-thunder;
}
.bosch-ic-electricity-pylon:before {
    content: $bosch-ic-electricity-pylon;
}
.bosch-ic-box-questionmark:before {
    content: $bosch-ic-box-questionmark;
}
.bosch-ic-rainbow:before {
    content: $bosch-ic-rainbow;
}
.bosch-ic-medal:before {
    content: $bosch-ic-medal;
}
.bosch-ic-wheel:before {
    content: $bosch-ic-wheel;
}
.bosch-ic-borescope-connected:before {
    content: $bosch-ic-borescope-connected;
}
.bosch-ic-filter-delete:before {
    content: $bosch-ic-filter-delete;
}
.bosch-ic-filter-add:before {
    content: $bosch-ic-filter-add;
}
.bosch-ic-man:before {
    content: $bosch-ic-man;
}
.bosch-ic-woman:before {
    content: $bosch-ic-woman;
}
.bosch-ic-fridge:before {
    content: $bosch-ic-fridge;
}
.bosch-ic-battery-car:before {
    content: $bosch-ic-battery-car;
}
.bosch-ic-bag-escalator:before {
    content: $bosch-ic-bag-escalator;
}
.bosch-ic-shopping-bags:before {
    content: $bosch-ic-shopping-bags;
}
.bosch-ic-car-steering-left:before {
    content: $bosch-ic-car-steering-left;
}
.bosch-ic-car-steering-right:before {
    content: $bosch-ic-car-steering-right;
}
.bosch-ic-thumb-up-down:before {
    content: $bosch-ic-thumb-up-down;
}
.bosch-ic-user-run-detection:before {
    content: $bosch-ic-user-run-detection;
}
.bosch-ic-bank:before {
    content: $bosch-ic-bank;
}
.bosch-ic-transportation:before {
    content: $bosch-ic-transportation;
}
.bosch-ic-government:before {
    content: $bosch-ic-government;
}
.bosch-ic-user-music:before {
    content: $bosch-ic-user-music;
}
.bosch-ic-bus:before {
    content: $bosch-ic-bus;
}
.bosch-ic-car-euro:before {
    content: $bosch-ic-car-euro;
}
.bosch-ic-bag:before {
    content: $bosch-ic-bag;
}
.bosch-ic-plate-tetrapack:before {
    content: $bosch-ic-plate-tetrapack;
}
.bosch-ic-hospital:before {
    content: $bosch-ic-hospital;
}
.bosch-ic-desktop-structure:before {
    content: $bosch-ic-desktop-structure;
}
.bosch-ic-frog-head:before {
    content: $bosch-ic-frog-head;
}
.bosch-ic-sportboat:before {
    content: $bosch-ic-sportboat;
}
.bosch-ic-yacht:before {
    content: $bosch-ic-yacht;
}
.bosch-ic-leaf-tilia:before {
    content: $bosch-ic-leaf-tilia;
}
.bosch-ic-music:before {
    content: $bosch-ic-music;
}
.bosch-ic-oil-lamp:before {
    content: $bosch-ic-oil-lamp;
}
.bosch-ic-windshield-wiper-rear:before {
    content: $bosch-ic-windshield-wiper-rear;
}
.bosch-ic-dongle-connected:before {
    content: $bosch-ic-dongle-connected;
}
.bosch-ic-slate:before {
    content: $bosch-ic-slate;
}
.bosch-ic-appliance-heat-pump-exhaust:before {
    content: $bosch-ic-appliance-heat-pump-exhaust;
}
.bosch-ic-alert-list:before {
    content: $bosch-ic-alert-list;
}
.bosch-ic-arrow-left-end-frame:before {
    content: $bosch-ic-arrow-left-end-frame;
}
.bosch-ic-arrow-right-end-frame:before {
    content: $bosch-ic-arrow-right-end-frame;
}
.bosch-ic-shape-ellipse:before {
    content: $bosch-ic-shape-ellipse;
}
.bosch-ic-shape-rectangle:before {
    content: $bosch-ic-shape-rectangle;
}
.bosch-ic-wishlist-add:before {
    content: $bosch-ic-wishlist-add;
}
.bosch-ic-smartphone-disabled-light:before {
    content: $bosch-ic-smartphone-disabled-light;
}
.bosch-ic-airbag:before {
    content: $bosch-ic-airbag;
}
.bosch-ic-compass:before {
    content: $bosch-ic-compass;
}
.bosch-ic-turbine-wind:before {
    content: $bosch-ic-turbine-wind;
}
.bosch-ic-arrows-diagonal-disabled-light:before {
    content: $bosch-ic-arrows-diagonal-disabled-light;
}
.bosch-ic-ear-off-disabled-bold:before {
    content: $bosch-ic-ear-off-disabled-bold;
}
.bosch-ic-fan-off-disabled-light:before {
    content: $bosch-ic-fan-off-disabled-light;
}
.bosch-ic-faucet-off-disabled-light:before {
    content: $bosch-ic-faucet-off-disabled-light;
}
.bosch-ic-hand-motion-off-disabled-bold:before {
    content: $bosch-ic-hand-motion-off-disabled-bold;
}
.bosch-ic-heating-floor-off-disabled-light:before {
    content: $bosch-ic-heating-floor-off-disabled-light;
}
.bosch-ic-heating-radiator-off-disabled-light:before {
    content: $bosch-ic-heating-radiator-off-disabled-light;
}
.bosch-ic-ice-off-disabled-light:before {
    content: $bosch-ic-ice-off-disabled-light;
}
.bosch-ic-smartphone-disabled-bold:before {
    content: $bosch-ic-smartphone-disabled-bold;
}
.bosch-ic-subtitles-off-bold:before {
    content: $bosch-ic-subtitles-off-bold;
}
.bosch-ic-sun-off-disabled-light:before {
    content: $bosch-ic-sun-off-disabled-light;
}
.bosch-ic-video-disabled-bold:before {
    content: $bosch-ic-video-disabled-bold;
}
.bosch-ic-volume-off-bold:before {
    content: $bosch-ic-volume-off-bold;
}
.bosch-ic-watch-off-disabled-bold:before {
    content: $bosch-ic-watch-off-disabled-bold;
}
.bosch-ic-water-shower-off-disabled-light:before {
    content: $bosch-ic-water-shower-off-disabled-light;
}
.bosch-ic-3d-frame-play:before {
    content: $bosch-ic-3d-frame-play;
}
.bosch-ic-alert-error-checkmark:before {
    content: $bosch-ic-alert-error-checkmark;
}
.bosch-ic-arrow-up-frame-check:before {
    content: $bosch-ic-arrow-up-frame-check;
}
.bosch-ic-arrow-up-frame-error:before {
    content: $bosch-ic-arrow-up-frame-error;
}
.bosch-ic-arrows-checkmark:before {
    content: $bosch-ic-arrows-checkmark;
}
.bosch-ic-assembly-line-settings:before {
    content: $bosch-ic-assembly-line-settings;
}
.bosch-ic-augmented-reality-player:before {
    content: $bosch-ic-augmented-reality-player;
}
.bosch-ic-badge-checkmark:before {
    content: $bosch-ic-badge-checkmark;
}
.bosch-ic-bar-chart-kappa:before {
    content: $bosch-ic-bar-chart-kappa;
}
.bosch-ic-bar-chart-search-detail:before {
    content: $bosch-ic-bar-chart-search-detail;
}
.bosch-ic-barcode-checkmark:before {
    content: $bosch-ic-barcode-checkmark;
}
.bosch-ic-barcode-scan-bulb:before {
    content: $bosch-ic-barcode-scan-bulb;
}
.bosch-ic-barcode:before {
    content: $bosch-ic-barcode;
}
.bosch-ic-binary:before {
    content: $bosch-ic-binary;
}
.bosch-ic-book-lightbulb:before {
    content: $bosch-ic-book-lightbulb;
}
.bosch-ic-box-arrow-down:before {
    content: $bosch-ic-box-arrow-down;
}
.bosch-ic-box-delivery-checkmark:before {
    content: $bosch-ic-box-delivery-checkmark;
}
.bosch-ic-globe-checkmark:before {
    content: $bosch-ic-globe-checkmark;
}
.bosch-ic-calendar-boxes:before {
    content: $bosch-ic-calendar-boxes;
}
.bosch-ic-calendar-service:before {
    content: $bosch-ic-calendar-service;
}
.bosch-ic-camera-processor:before {
    content: $bosch-ic-camera-processor;
}
.bosch-ic-camera-settings:before {
    content: $bosch-ic-camera-settings;
}
.bosch-ic-chart-line-checkmark:before {
    content: $bosch-ic-chart-line-checkmark;
}
.bosch-ic-checklist-stack:before {
    content: $bosch-ic-checklist-stack;
}
.bosch-ic-checkmark-frame-minimum:before {
    content: $bosch-ic-checkmark-frame-minimum;
}
.bosch-ic-checkmark-frame-tripple:before {
    content: $bosch-ic-checkmark-frame-tripple;
}
.bosch-ic-clipboard-checkmark:before {
    content: $bosch-ic-clipboard-checkmark;
}
.bosch-ic-clipboard-list-parts:before {
    content: $bosch-ic-clipboard-list-parts;
}
.bosch-ic-clipboard-settings:before {
    content: $bosch-ic-clipboard-settings;
}
.bosch-ic-computer-tower-box:before {
    content: $bosch-ic-computer-tower-box;
}
.bosch-ic-connectivity-lock:before {
    content: $bosch-ic-connectivity-lock;
}
.bosch-ic-counter-current:before {
    content: $bosch-ic-counter-current;
}
.bosch-ic-counter-target:before {
    content: $bosch-ic-counter-target;
}
.bosch-ic-cube-checkmark:before {
    content: $bosch-ic-cube-checkmark;
}
.bosch-ic-cube-play:before {
    content: $bosch-ic-cube-play;
}
.bosch-ic-timeline-settings:before {
    content: $bosch-ic-timeline-settings;
}
.bosch-ic-timeline-view:before {
    content: $bosch-ic-timeline-view;
}
.bosch-ic-timeline:before {
    content: $bosch-ic-timeline;
}
.bosch-ic-delivery-checkmark:before {
    content: $bosch-ic-delivery-checkmark;
}
.bosch-ic-desktop-application-3d-play:before {
    content: $bosch-ic-desktop-application-3d-play;
}
.bosch-ic-desktop-apps-download:before {
    content: $bosch-ic-desktop-apps-download;
}
.bosch-ic-desktop-dashboard:before {
    content: $bosch-ic-desktop-dashboard;
}
.bosch-ic-desktop-graph-checkmark:before {
    content: $bosch-ic-desktop-graph-checkmark;
}
.bosch-ic-desktop-graph-search:before {
    content: $bosch-ic-desktop-graph-search;
}
.bosch-ic-desktop-graph:before {
    content: $bosch-ic-desktop-graph;
}
.bosch-ic-development-kit:before {
    content: $bosch-ic-development-kit;
}
.bosch-ic-directory-cloud-settings:before {
    content: $bosch-ic-directory-cloud-settings;
}
.bosch-ic-directory-cloud:before {
    content: $bosch-ic-directory-cloud;
}
.bosch-ic-directory-settings:before {
    content: $bosch-ic-directory-settings;
}
.bosch-ic-document-clock-cycle:before {
    content: $bosch-ic-document-clock-cycle;
}
.bosch-ic-document-code-stack:before {
    content: $bosch-ic-document-code-stack;
}
.bosch-ic-document-dat-edit:before {
    content: $bosch-ic-document-dat-edit;
}
.bosch-ic-document-ohd-arrow-down:before {
    content: $bosch-ic-document-ohd-arrow-down;
}
.bosch-ic-dolphin:before {
    content: $bosch-ic-dolphin;
}
.bosch-ic-energy-management:before {
    content: $bosch-ic-energy-management;
}
.bosch-ic-eraser:before {
    content: $bosch-ic-eraser;
}
.bosch-ic-folder-broom:before {
    content: $bosch-ic-folder-broom;
}
.bosch-ic-folder-check-broom:before {
    content: $bosch-ic-folder-check-broom;
}
.bosch-ic-folder-checkmark:before {
    content: $bosch-ic-folder-checkmark;
}
.bosch-ic-folder-oes-check:before {
    content: $bosch-ic-folder-oes-check;
}
.bosch-ic-folder-open-service:before {
    content: $bosch-ic-folder-open-service;
}
.bosch-ic-folder-reload:before {
    content: $bosch-ic-folder-reload;
}
.bosch-ic-hand-command:before {
    content: $bosch-ic-hand-command;
}
.bosch-ic-heating-start:before {
    content: $bosch-ic-heating-start;
}
.bosch-ic-hierarchy-refresh:before {
    content: $bosch-ic-hierarchy-refresh;
}
.bosch-ic-hierarchy-search:before {
    content: $bosch-ic-hierarchy-search;
}
.bosch-ic-hierarchy-settings:before {
    content: $bosch-ic-hierarchy-settings;
}
.bosch-ic-hierarchy:before {
    content: $bosch-ic-hierarchy;
}
.bosch-ic-hmi-desktop-settings:before {
    content: $bosch-ic-hmi-desktop-settings;
}
.bosch-ic-human-machine-interface:before {
    content: $bosch-ic-human-machine-interface;
}
.bosch-ic-keys-user-access:before {
    content: $bosch-ic-keys-user-access;
}
.bosch-ic-keys:before {
    content: $bosch-ic-keys;
}
.bosch-ic-label-edit:before {
    content: $bosch-ic-label-edit;
}
.bosch-ic-line-arrow-checkmark:before {
    content: $bosch-ic-line-arrow-checkmark;
}
.bosch-ic-link-checkmark:before {
    content: $bosch-ic-link-checkmark;
}
.bosch-ic-lock-closed-checkmark:before {
    content: $bosch-ic-lock-closed-checkmark;
}
.bosch-ic-lock-open-checkmark:before {
    content: $bosch-ic-lock-open-checkmark;
}
.bosch-ic-material-search:before {
    content: $bosch-ic-material-search;
}
.bosch-ic-objects-search:before {
    content: $bosch-ic-objects-search;
}
.bosch-ic-objects:before {
    content: $bosch-ic-objects;
}
.bosch-ic-plan-a-to-b:before {
    content: $bosch-ic-plan-a-to-b;
}
.bosch-ic-plan-chart:before {
    content: $bosch-ic-plan-chart;
}
.bosch-ic-print-send:before {
    content: $bosch-ic-print-send;
}
.bosch-ic-refresh-analysis:before {
    content: $bosch-ic-refresh-analysis;
}
.bosch-ic-remote-desktop-add:before {
    content: $bosch-ic-remote-desktop-add;
}
.bosch-ic-rfid-checkmark:before {
    content: $bosch-ic-rfid-checkmark;
}
.bosch-ic-rfid-tag:before {
    content: $bosch-ic-rfid-tag;
}
.bosch-ic-sequence-checkmark:before {
    content: $bosch-ic-sequence-checkmark;
}
.bosch-ic-server-arrow-up:before {
    content: $bosch-ic-server-arrow-up;
}
.bosch-ic-server-arrows:before {
    content: $bosch-ic-server-arrows;
}
.bosch-ic-server-settings:before {
    content: $bosch-ic-server-settings;
}
.bosch-ic-server-share:before {
    content: $bosch-ic-server-share;
}
.bosch-ic-settings-editor:before {
    content: $bosch-ic-settings-editor;
}
.bosch-ic-survey-checkmark:before {
    content: $bosch-ic-survey-checkmark;
}
.bosch-ic-target-dart:before {
    content: $bosch-ic-target-dart;
}
.bosch-ic-target-view:before {
    content: $bosch-ic-target-view;
}
.bosch-ic-target:before {
    content: $bosch-ic-target;
}
.bosch-ic-thickness-selection:before {
    content: $bosch-ic-thickness-selection;
}
.bosch-ic-toolbox-checkmark:before {
    content: $bosch-ic-toolbox-checkmark;
}
.bosch-ic-toolbox:before {
    content: $bosch-ic-toolbox;
}
.bosch-ic-translate:before {
    content: $bosch-ic-translate;
}
.bosch-ic-tune-vertical-checkmark:before {
    content: $bosch-ic-tune-vertical-checkmark;
}
.bosch-ic-wand-user-add:before {
    content: $bosch-ic-wand-user-add;
}
.bosch-ic-wand-user-settings:before {
    content: $bosch-ic-wand-user-settings;
}
.bosch-ic-wand-user:before {
    content: $bosch-ic-wand-user;
}
.bosch-ic-window-globe:before {
    content: $bosch-ic-window-globe;
}
.bosch-ic-wrench-change:before {
    content: $bosch-ic-wrench-change;
}
.bosch-ic-clock-24-7:before {
    content: $bosch-ic-clock-24-7;
}
.bosch-ic-eagle:before {
    content: $bosch-ic-eagle;
}
.bosch-ic-fluorescent-light:before {
    content: $bosch-ic-fluorescent-light;
}
.bosch-ic-home-cloud-0:before {
    content: $bosch-ic-home-cloud-0;
}
.bosch-ic-home-cloud-1:before {
    content: $bosch-ic-home-cloud-1;
}
.bosch-ic-home-cloud-2:before {
    content: $bosch-ic-home-cloud-2;
}
.bosch-ic-home-cloud-3:before {
    content: $bosch-ic-home-cloud-3;
}
.bosch-ic-home-shadow:before {
    content: $bosch-ic-home-shadow;
}
.bosch-ic-motorcycle-sport:before {
    content: $bosch-ic-motorcycle-sport;
}
.bosch-ic-smartphone-connected:before {
    content: $bosch-ic-smartphone-connected;
}
.bosch-ic-usb-stick:before {
    content: $bosch-ic-usb-stick;
}
.bosch-ic-user-setting:before {
    content: $bosch-ic-user-setting;
}
.bosch-ic-timeline-clock:before {
    content: $bosch-ic-timeline-clock;
}
.bosch-ic-alarm-fire:before {
    content: $bosch-ic-alarm-fire;
}
.bosch-ic-emergency-front:before {
    content: $bosch-ic-emergency-front;
}
.bosch-ic-transporter-front:before {
    content: $bosch-ic-transporter-front;
}
.bosch-ic-truck-front:before {
    content: $bosch-ic-truck-front;
}
.bosch-ic-unpin-classic-disabled-light:before {
    content: $bosch-ic-unpin-classic-disabled-light;
}
.bosch-ic-unpin-modern-disabled-light:before {
    content: $bosch-ic-unpin-modern-disabled-light;
}
.bosch-ic-smartphone-ban:before {
    content: $bosch-ic-smartphone-ban;
}
.bosch-ic-helmet:before {
    content: $bosch-ic-helmet;
}
.bosch-ic-gate-closed:before {
    content: $bosch-ic-gate-closed;
}
.bosch-ic-fence:before {
    content: $bosch-ic-fence;
}
.bosch-ic-desktop-apps:before {
    content: $bosch-ic-desktop-apps;
}
.bosch-ic-candle:before {
    content: $bosch-ic-candle;
}
.bosch-ic-chart-oee:before {
    content: $bosch-ic-chart-oee;
}
.bosch-ic-cycletime-eighth:before {
    content: $bosch-ic-cycletime-eighth;
}
.bosch-ic-cycletime-quarter:before {
    content: $bosch-ic-cycletime-quarter;
}
.bosch-ic-gateway:before {
    content: $bosch-ic-gateway;
}
.bosch-ic-heatmap:before {
    content: $bosch-ic-heatmap;
}
.bosch-ic-laptop-checklist:before {
    content: $bosch-ic-laptop-checklist;
}
.bosch-ic-molecule:before {
    content: $bosch-ic-molecule;
}
.bosch-ic-robot-settings:before {
    content: $bosch-ic-robot-settings;
}
.bosch-ic-sensor-cable:before {
    content: $bosch-ic-sensor-cable;
}
.bosch-ic-tank-layers:before {
    content: $bosch-ic-tank-layers;
}
.bosch-ic-window-settings:before {
    content: $bosch-ic-window-settings;
}
.bosch-ic-heatmap-independent:before {
    content: $bosch-ic-heatmap-independent;
}
.bosch-ic-dumble:before {
    content: $bosch-ic-dumble;
}
.bosch-ic-truck-wash:before {
    content: $bosch-ic-truck-wash;
}
.bosch-ic-smart-sensor:before {
    content: $bosch-ic-smart-sensor;
}
.bosch-ic-audio-add:before {
    content: $bosch-ic-audio-add;
}
.bosch-ic-audio-checked:before {
    content: $bosch-ic-audio-checked;
}
.bosch-ic-audio-play:before {
    content: $bosch-ic-audio-play;
}
.bosch-ic-audio-remove:before {
    content: $bosch-ic-audio-remove;
}
.bosch-ic-image-gallery-checked:before {
    content: $bosch-ic-image-gallery-checked;
}
.bosch-ic-imagery-checked:before {
    content: $bosch-ic-imagery-checked;
}
.bosch-ic-image-gallery-remove:before {
    content: $bosch-ic-image-gallery-remove;
}
.bosch-ic-recording:before {
    content: $bosch-ic-recording;
}
.bosch-ic-stop-frame:before {
    content: $bosch-ic-stop-frame;
}
.bosch-ic-roof:before {
    content: $bosch-ic-roof;
}
.bosch-ic-projection-interactive:before {
    content: $bosch-ic-projection-interactive;
}
.bosch-ic-bridge:before {
    content: $bosch-ic-bridge;
}
.bosch-ic-box-arrows:before {
    content: $bosch-ic-box-arrows;
}
.bosch-ic-chart-line-fit:before {
    content: $bosch-ic-chart-line-fit;
}
.bosch-ic-chart-line-compress:before {
    content: $bosch-ic-chart-line-compress;
}
.bosch-ic-ciss:before {
    content: $bosch-ic-ciss;
}
.bosch-ic-scd:before {
    content: $bosch-ic-scd;
}
.bosch-ic-vci:before {
    content: $bosch-ic-vci;
}
.bosch-ic-access-controller:before {
    content: $bosch-ic-access-controller;
}
.bosch-ic-card-reader:before {
    content: $bosch-ic-card-reader;
}
.bosch-ic-table:before {
    content: $bosch-ic-table;
}
.bosch-ic-replace:before {
    content: $bosch-ic-replace;
}
.bosch-ic-chart-topflop-organized-horizontal:before {
    content: $bosch-ic-chart-topflop-organized-horizontal;
}
.bosch-ic-chart-topflop-random-horizontal:before {
    content: $bosch-ic-chart-topflop-random-horizontal;
}
.bosch-ic-chart-topflop-organized-vertical:before {
    content: $bosch-ic-chart-topflop-organized-vertical;
}
.bosch-ic-chart-topflop-random-vertical:before {
    content: $bosch-ic-chart-topflop-random-vertical;
}
.bosch-ic-ois:before {
    content: $bosch-ic-ois;
}
.bosch-ic-cloud-refresh-locked:before {
    content: $bosch-ic-cloud-refresh-locked;
}
.bosch-ic-car-bluetooth:before {
    content: $bosch-ic-car-bluetooth;
}
.bosch-ic-camera-trainer:before {
    content: $bosch-ic-camera-trainer;
}
.bosch-ic-magnifier-moving:before {
    content: $bosch-ic-magnifier-moving;
}
.bosch-ic-absolute-orientation:before {
    content: $bosch-ic-absolute-orientation;
}
.bosch-ic-accelerometer:before {
    content: $bosch-ic-accelerometer;
}
.bosch-ic-imu:before {
    content: $bosch-ic-imu;
}
.bosch-ic-gyroscope:before {
    content: $bosch-ic-gyroscope;
}
.bosch-ic-magneotmeter:before {
    content: $bosch-ic-magneotmeter;
}
.bosch-ic-battery-car-1:before {
    content: $bosch-ic-battery-car-1;
}
.bosch-ic-battery-car-2:before {
    content: $bosch-ic-battery-car-2;
}
.bosch-ic-battery-car-3:before {
    content: $bosch-ic-battery-car-3;
}
.bosch-ic-battery-car-charging:before {
    content: $bosch-ic-battery-car-charging;
}
.bosch-ic-battery-car-defective:before {
    content: $bosch-ic-battery-car-defective;
}
.bosch-ic-truck-crane:before {
    content: $bosch-ic-truck-crane;
}
.bosch-ic-train-diesel:before {
    content: $bosch-ic-train-diesel;
}
.bosch-ic-snowmobile:before {
    content: $bosch-ic-snowmobile;
}
.bosch-ic-riding-mower:before {
    content: $bosch-ic-riding-mower;
}
.bosch-ic-motorcycle-small:before {
    content: $bosch-ic-motorcycle-small;
}
.bosch-ic-mining-truck:before {
    content: $bosch-ic-mining-truck;
}
.bosch-ic-lawnmower:before {
    content: $bosch-ic-lawnmower;
}
.bosch-ic-jetski:before {
    content: $bosch-ic-jetski;
}
.bosch-ic-golf-caddy:before {
    content: $bosch-ic-golf-caddy;
}
.bosch-ic-generator-electric:before {
    content: $bosch-ic-generator-electric;
}
.bosch-ic-garbage-truck:before {
    content: $bosch-ic-garbage-truck;
}
.bosch-ic-fork-lift-big:before {
    content: $bosch-ic-fork-lift-big;
}
.bosch-ic-engine-small:before {
    content: $bosch-ic-engine-small;
}
.bosch-ic-engine-big:before {
    content: $bosch-ic-engine-big;
}
.bosch-ic-coach:before {
    content: $bosch-ic-coach;
}
.bosch-ic-chainsaw:before {
    content: $bosch-ic-chainsaw;
}
.bosch-ic-car-van:before {
    content: $bosch-ic-car-van;
}
.bosch-ic-car-suv:before {
    content: $bosch-ic-car-suv;
}
.bosch-ic-car-race:before {
    content: $bosch-ic-car-race;
}
.bosch-ic-car-old:before {
    content: $bosch-ic-car-old;
}
.bosch-ic-car-camping-van:before {
    content: $bosch-ic-car-camping-van;
}
.bosch-ic-car-camper:before {
    content: $bosch-ic-car-camper;
}
.bosch-ic-bus-side:before {
    content: $bosch-ic-bus-side;
}
.bosch-ic-book-info:before {
    content: $bosch-ic-book-info;
}
.bosch-ic-gears:before {
    content: $bosch-ic-gears;
}
.bosch-ic-shark:before {
    content: $bosch-ic-shark;
}
.bosch-ic-mountaintop:before {
    content: $bosch-ic-mountaintop;
}
.bosch-ic-baby:before {
    content: $bosch-ic-baby;
}
.bosch-ic-coffee-bean:before {
    content: $bosch-ic-coffee-bean;
}
.bosch-ic-coffee-beans:before {
    content: $bosch-ic-coffee-beans;
}
.bosch-ic-skull:before {
    content: $bosch-ic-skull;
}
.bosch-ic-stroller:before {
    content: $bosch-ic-stroller;
}
.bosch-ic-glasses:before {
    content: $bosch-ic-glasses;
}
.bosch-ic-detergent:before {
    content: $bosch-ic-detergent;
}
.bosch-ic-washing-machine:before {
    content: $bosch-ic-washing-machine;
}
.bosch-ic-sdcard:before {
    content: $bosch-ic-sdcard;
}
.bosch-ic-water-connectivity:before {
    content: $bosch-ic-water-connectivity;
}
.bosch-ic-server-connectivity:before {
    content: $bosch-ic-server-connectivity;
}
.bosch-ic-presentation-speaker:before {
    content: $bosch-ic-presentation-speaker;
}
.bosch-ic-chart-bar-arrow:before {
    content: $bosch-ic-chart-bar-arrow;
}
.bosch-ic-book-connectivity:before {
    content: $bosch-ic-book-connectivity;
}
.bosch-ic-route-focus:before {
    content: $bosch-ic-route-focus;
}
.bosch-ic-bicycle-helmet:before {
    content: $bosch-ic-bicycle-helmet;
}
.bosch-ic-cookie:before {
    content: $bosch-ic-cookie;
}
.bosch-ic-glass-wine:before {
    content: $bosch-ic-glass-wine;
}
.bosch-ic-cup-small:before {
    content: $bosch-ic-cup-small;
}
.bosch-ic-cup-large:before {
    content: $bosch-ic-cup-large;
}
.bosch-ic-bowl:before {
    content: $bosch-ic-bowl;
}
.bosch-ic-glass-small:before {
    content: $bosch-ic-glass-small;
}
.bosch-ic-ice-bottle:before {
    content: $bosch-ic-ice-bottle;
}
.bosch-ic-cup-plate-drop:before {
    content: $bosch-ic-cup-plate-drop;
}
.bosch-ic-plate:before {
    content: $bosch-ic-plate;
}
.bosch-ic-cup-plate:before {
    content: $bosch-ic-cup-plate;
}
.bosch-ic-bubbles:before {
    content: $bosch-ic-bubbles;
}
.bosch-ic-button-start-stop:before {
    content: $bosch-ic-button-start-stop;
}
.bosch-ic-elevator-freight-max:before {
    content: $bosch-ic-elevator-freight-max;
}
.bosch-ic-elevator-freight:before {
    content: $bosch-ic-elevator-freight;
}
.bosch-ic-elevator-persons:before {
    content: $bosch-ic-elevator-persons;
}
.bosch-ic-embed:before {
    content: $bosch-ic-embed;
}
.bosch-ic-female-male:before {
    content: $bosch-ic-female-male;
}
.bosch-ic-fork-knife-spoon:before {
    content: $bosch-ic-fork-knife-spoon;
}
.bosch-ic-global-entrepreneur:before {
    content: $bosch-ic-global-entrepreneur;
}
.bosch-ic-arrows-left-right-checkmark:before {
    content: $bosch-ic-arrows-left-right-checkmark;
}
.bosch-ic-automatic-mode:before {
    content: $bosch-ic-automatic-mode;
}
.bosch-ic-babybottle:before {
    content: $bosch-ic-babybottle;
}
.bosch-ic-barcode-scan:before {
    content: $bosch-ic-barcode-scan;
}
.bosch-ic-barcode-scanner:before {
    content: $bosch-ic-barcode-scanner;
}
.bosch-ic-cash-frame-rupee:before {
    content: $bosch-ic-cash-frame-rupee;
}
.bosch-ic-chart-heat-curve:before {
    content: $bosch-ic-chart-heat-curve;
}
.bosch-ic-compressor:before {
    content: $bosch-ic-compressor;
}
.bosch-ic-cubes:before {
    content: $bosch-ic-cubes;
}
.bosch-ic-highway:before {
    content: $bosch-ic-highway;
}
.bosch-ic-industry-workers:before {
    content: $bosch-ic-industry-workers;
}
.bosch-ic-industry-workers-connected:before {
    content: $bosch-ic-industry-workers-connected;
}
.bosch-ic-machine-station-control:before {
    content: $bosch-ic-machine-station-control;
}
.bosch-ic-map-city:before {
    content: $bosch-ic-map-city;
}
.bosch-ic-ordernumber:before {
    content: $bosch-ic-ordernumber;
}
.bosch-ic-shirt-business:before {
    content: $bosch-ic-shirt-business;
}
.bosch-ic-shirt-casual:before {
    content: $bosch-ic-shirt-casual;
}
.bosch-ic-suitcase:before {
    content: $bosch-ic-suitcase;
}
.bosch-ic-weather-cloud-sun-sensor:before {
    content: $bosch-ic-weather-cloud-sun-sensor;
}
.bosch-ic-window-code:before {
    content: $bosch-ic-window-code;
}
.bosch-ic-wrench-warning:before {
    content: $bosch-ic-wrench-warning;
}
.bosch-ic-arrow-circle-a:before {
    content: $bosch-ic-arrow-circle-a;
}
.bosch-ic-arrows-agility:before {
    content: $bosch-ic-arrows-agility;
}
.bosch-ic-arrows-collapse-object:before {
    content: $bosch-ic-arrows-collapse-object;
}
.bosch-ic-arrows-expand-object:before {
    content: $bosch-ic-arrows-expand-object;
}
.bosch-ic-arrows-left-right:before {
    content: $bosch-ic-arrows-left-right;
}
.bosch-ic-fan-heat:before {
    content: $bosch-ic-fan-heat;
}
.bosch-ic-battery-car-arrows-circle:before {
    content: $bosch-ic-battery-car-arrows-circle;
}
.bosch-ic-battery-car-clock:before {
    content: $bosch-ic-battery-car-clock;
}
.bosch-ic-battery-vibration:before {
    content: $bosch-ic-battery-vibration;
}
.bosch-ic-belt-elastic:before {
    content: $bosch-ic-belt-elastic;
}
.bosch-ic-bicycle-e-arrow-away:before {
    content: $bosch-ic-bicycle-e-arrow-away;
}
.bosch-ic-bicycle-e-arrow-towards:before {
    content: $bosch-ic-bicycle-e-arrow-towards;
}
.bosch-ic-brake-decoupled-power:before {
    content: $bosch-ic-brake-decoupled-power;
}
.bosch-ic-brake-i-booster:before {
    content: $bosch-ic-brake-i-booster;
}
.bosch-ic-brake-system-esp:before {
    content: $bosch-ic-brake-system-esp;
}
.bosch-ic-brake-system-integrated:before {
    content: $bosch-ic-brake-system-integrated;
}
.bosch-ic-brake-vacuum-booster:before {
    content: $bosch-ic-brake-vacuum-booster;
}
.bosch-ic-capacity:before {
    content: $bosch-ic-capacity;
}
.bosch-ic-car-beam-length:before {
    content: $bosch-ic-car-beam-length;
}
.bosch-ic-car-smartphone-locator:before {
    content: $bosch-ic-car-smartphone-locator;
}
.bosch-ic-chart-pie:before {
    content: $bosch-ic-chart-pie;
}
.bosch-ic-chess-jumper:before {
    content: $bosch-ic-chess-jumper;
}
.bosch-ic-clock-time-off:before {
    content: $bosch-ic-clock-time-off;
}
.bosch-ic-clock-time-on:before {
    content: $bosch-ic-clock-time-on;
}
.bosch-ic-coin:before {
    content: $bosch-ic-coin;
}
.bosch-ic-connection-off-settings:before {
    content: $bosch-ic-connection-off-settings;
}
.bosch-ic-connection-on-settings:before {
    content: $bosch-ic-connection-on-settings;
}
.bosch-ic-control-unit:before {
    content: $bosch-ic-control-unit;
}
.bosch-ic-customer:before {
    content: $bosch-ic-customer;
}
.bosch-ic-engine:before {
    content: $bosch-ic-engine;
}
.bosch-ic-filter-air:before {
    content: $bosch-ic-filter-air;
}
.bosch-ic-filter-cabin-carbon:before {
    content: $bosch-ic-filter-cabin-carbon;
}
.bosch-ic-filter-cabin:before {
    content: $bosch-ic-filter-cabin;
}
.bosch-ic-filter-cooling-water:before {
    content: $bosch-ic-filter-cooling-water;
}
.bosch-ic-filter-denox-cover:before {
    content: $bosch-ic-filter-denox-cover;
}
.bosch-ic-filter-denox:before {
    content: $bosch-ic-filter-denox;
}
.bosch-ic-filter-element-compensation-sealing:before {
    content: $bosch-ic-filter-element-compensation-sealing;
}
.bosch-ic-filter-element-compensation:before {
    content: $bosch-ic-filter-element-compensation;
}
.bosch-ic-filter-element:before {
    content: $bosch-ic-filter-element;
}
.bosch-ic-filter-oil:before {
    content: $bosch-ic-filter-oil;
}
.bosch-ic-filter-petrol:before {
    content: $bosch-ic-filter-petrol;
}
.bosch-ic-filter-prefilter:before {
    content: $bosch-ic-filter-prefilter;
}
.bosch-ic-fireplace:before {
    content: $bosch-ic-fireplace;
}
.bosch-ic-flash-plus:before {
    content: $bosch-ic-flash-plus;
}
.bosch-ic-globe-worldwideweb:before {
    content: $bosch-ic-globe-worldwideweb;
}
.bosch-ic-glow-control-unit:before {
    content: $bosch-ic-glow-control-unit;
}
.bosch-ic-glow-plug:before {
    content: $bosch-ic-glow-plug;
}
.bosch-ic-hand-cloth:before {
    content: $bosch-ic-hand-cloth;
}
.bosch-ic-leaf-plus:before {
    content: $bosch-ic-leaf-plus;
}
.bosch-ic-machine-warning:before {
    content: $bosch-ic-machine-warning;
}
.bosch-ic-measuring-jug:before {
    content: $bosch-ic-measuring-jug;
}
.bosch-ic-plug-play:before {
    content: $bosch-ic-plug-play;
}
.bosch-ic-refresh-checkmark:before {
    content: $bosch-ic-refresh-checkmark;
}
.bosch-ic-replacement-filament:before {
    content: $bosch-ic-replacement-filament;
}
.bosch-ic-screw:before {
    content: $bosch-ic-screw;
}
.bosch-ic-seat-belt:before {
    content: $bosch-ic-seat-belt;
}
.bosch-ic-steering-wheel-hands-automated:before {
    content: $bosch-ic-steering-wheel-hands-automated;
}
.bosch-ic-sun-ice-parking:before {
    content: $bosch-ic-sun-ice-parking;
}
.bosch-ic-syringe-change-needle:before {
    content: $bosch-ic-syringe-change-needle;
}
.bosch-ic-syringe-change:before {
    content: $bosch-ic-syringe-change;
}
.bosch-ic-syringe:before {
    content: $bosch-ic-syringe;
}
.bosch-ic-thread-diameter:before {
    content: $bosch-ic-thread-diameter;
}
.bosch-ic-tool-extraction:before {
    content: $bosch-ic-tool-extraction;
}
.bosch-ic-transporter-car-front:before {
    content: $bosch-ic-transporter-car-front;
}
.bosch-ic-truck-car-motorcycle:before {
    content: $bosch-ic-truck-car-motorcycle;
}
.bosch-ic-truck-car:before {
    content: $bosch-ic-truck-car;
}
.bosch-ic-truck-logistics-box:before {
    content: $bosch-ic-truck-logistics-box;
}
.bosch-ic-truck-transporter:before {
    content: $bosch-ic-truck-transporter;
}
.bosch-ic-watch-on-warning:before {
    content: $bosch-ic-watch-on-warning;
}
.bosch-ic-wrench-calibrate:before {
    content: $bosch-ic-wrench-calibrate;
}
.bosch-ic-wrench-dummy:before {
    content: $bosch-ic-wrench-dummy;
}
.bosch-ic-wrench-tightening-torque:before {
    content: $bosch-ic-wrench-tightening-torque;
}
.bosch-ic-wrench-width-across-flats:before {
    content: $bosch-ic-wrench-width-across-flats;
}
.bosch-ic-user-arm-bandage:before {
    content: $bosch-ic-user-arm-bandage;
}
.bosch-ic-user-eye-blind:before {
    content: $bosch-ic-user-eye-blind;
}
.bosch-ic-user-ear-deaf:before {
    content: $bosch-ic-user-ear-deaf;
}
.bosch-ic-user-car-distracted:before {
    content: $bosch-ic-user-car-distracted;
}
.bosch-ic-user-mouth-mute:before {
    content: $bosch-ic-user-mouth-mute;
}
.bosch-ic-user-ear-blocked:before {
    content: $bosch-ic-user-ear-blocked;
}
.bosch-ic-user-bavarian:before {
    content: $bosch-ic-user-bavarian;
}
.bosch-ic-user-ear-loud:before {
    content: $bosch-ic-user-ear-loud;
}
.bosch-ic-user-walk-crutch:before {
    content: $bosch-ic-user-walk-crutch;
}
.bosch-ic-user-mouth-speechless:before {
    content: $bosch-ic-user-mouth-speechless;
}
.bosch-ic-user-arm-baby:before {
    content: $bosch-ic-user-arm-baby;
}
.bosch-ic-user-arm-missing:before {
    content: $bosch-ic-user-arm-missing;
}
.bosch-ic-user-eye-eyepatch:before {
    content: $bosch-ic-user-eye-eyepatch;
}
.bosch-ic-user-strapped:before {
    content: $bosch-ic-user-strapped;
}
.bosch-ic-cleaning-agent:before {
    content: $bosch-ic-cleaning-agent;
}
.bosch-ic-cup-plate-dirty:before {
    content: $bosch-ic-cup-plate-dirty;
}
.bosch-ic-cup-plate-private:before {
    content: $bosch-ic-cup-plate-private;
}
.bosch-ic-rubbish-apple:before {
    content: $bosch-ic-rubbish-apple;
}
.bosch-ic-rubbish-fish:before {
    content: $bosch-ic-rubbish-fish;
}
.bosch-ic-rubbish-organic-bin:before {
    content: $bosch-ic-rubbish-organic-bin;
}
.bosch-ic-rubbish-paper:before {
    content: $bosch-ic-rubbish-paper;
}
.bosch-ic-rubbish-paper-bin:before {
    content: $bosch-ic-rubbish-paper-bin;
}
.bosch-ic-rubbish-residual-bin:before {
    content: $bosch-ic-rubbish-residual-bin;
}
.bosch-ic-shower:before {
    content: $bosch-ic-shower;
}
.bosch-ic-shower-female:before {
    content: $bosch-ic-shower-female;
}
.bosch-ic-shower-male:before {
    content: $bosch-ic-shower-male;
}
.bosch-ic-wardrobe-female:before {
    content: $bosch-ic-wardrobe-female;
}
.bosch-ic-wardrobe-male:before {
    content: $bosch-ic-wardrobe-male;
}
.bosch-ic-car-top:before {
    content: $bosch-ic-car-top;
}
.bosch-ic-label-info:before {
    content: $bosch-ic-label-info;
}
.bosch-ic-perspective-view:before {
    content: $bosch-ic-perspective-view;
}
.bosch-ic-road:before {
    content: $bosch-ic-road;
}
.bosch-ic-screen-split-horizontal:before {
    content: $bosch-ic-screen-split-horizontal;
}
.bosch-ic-screen-split-vertical:before {
    content: $bosch-ic-screen-split-vertical;
}
.bosch-ic-screenshot-frame:before {
    content: $bosch-ic-screenshot-frame;
}
.bosch-ic-sensor-lidar:before {
    content: $bosch-ic-sensor-lidar;
}
.bosch-ic-tram:before {
    content: $bosch-ic-tram;
}
.bosch-ic-video-360-degrees:before {
    content: $bosch-ic-video-360-degrees;
}
.bosch-ic-belt-groove-drives:before {
    content: $bosch-ic-belt-groove-drives;
}
.bosch-ic-bottle-champagne:before {
    content: $bosch-ic-bottle-champagne;
}
.bosch-ic-box-falling:before {
    content: $bosch-ic-box-falling;
}
.bosch-ic-box-falling-off-disabled-bold:before {
    content: $bosch-ic-box-falling-off-disabled-bold;
}
.bosch-ic-box-falling-off-disabled-light:before {
    content: $bosch-ic-box-falling-off-disabled-light;
}
.bosch-ic-brake-pedal:before {
    content: $bosch-ic-brake-pedal;
}
.bosch-ic-car-front-light-inside:before {
    content: $bosch-ic-car-front-light-inside;
}
.bosch-ic-car-licence-plate-light:before {
    content: $bosch-ic-car-licence-plate-light;
}
.bosch-ic-car-side-light-back:before {
    content: $bosch-ic-car-side-light-back;
}
.bosch-ic-car-side-light-front:before {
    content: $bosch-ic-car-side-light-front;
}
.bosch-ic-light-fog-warning:before {
    content: $bosch-ic-light-fog-warning;
}
.bosch-ic-prism:before {
    content: $bosch-ic-prism;
}
.bosch-ic-turnstile:before {
    content: $bosch-ic-turnstile;
}
.bosch-ic-turnstile-open-in-and-outbound:before {
    content: $bosch-ic-turnstile-open-in-and-outbound;
}
.bosch-ic-turnstile-open-inbound:before {
    content: $bosch-ic-turnstile-open-inbound;
}
.bosch-ic-turnstile-open-outbound:before {
    content: $bosch-ic-turnstile-open-outbound;
}
.bosch-ic-uwb:before {
    content: $bosch-ic-uwb;
}
.bosch-ic-heat-reduce-active:before {
    content: $bosch-ic-heat-reduce-active;
}
.bosch-ic-heat-reduce-passive:before {
    content: $bosch-ic-heat-reduce-passive;
}
.bosch-ic-hourglass-add:before {
    content: $bosch-ic-hourglass-add;
}
.bosch-ic-light-fog:before {
    content: $bosch-ic-light-fog;
}
.bosch-ic-car-turn-signal:before {
    content: $bosch-ic-car-turn-signal;
}
.bosch-ic-car-turn-signal-left:before {
    content: $bosch-ic-car-turn-signal-left;
}
.bosch-ic-car-turn-signal-right:before {
    content: $bosch-ic-car-turn-signal-right;
}
.bosch-ic-light-rear:before {
    content: $bosch-ic-light-rear;
}
.bosch-ic-car-top-turn-signal-front:before {
    content: $bosch-ic-car-top-turn-signal-front;
}
.bosch-ic-car-top-turn-signal-back:before {
    content: $bosch-ic-car-top-turn-signal-back;
}
.bosch-ic-ccu-cm:before {
    content: $bosch-ic-ccu-cm;
}
.bosch-ic-lung:before {
    content: $bosch-ic-lung;
}
.bosch-ic-head-speaking-voice:before {
    content: $bosch-ic-head-speaking-voice;
}
.bosch-ic-car-motorcycle:before {
    content: $bosch-ic-car-motorcycle;
}
.bosch-ic-car-turn-signal-side:before {
    content: $bosch-ic-car-turn-signal-side;
}
.bosch-ic-car-light-parking:before {
    content: $bosch-ic-car-light-parking;
}
.bosch-ic-phoenix:before {
    content: $bosch-ic-phoenix;
}
.bosch-ic-sensor-air-quality:before {
    content: $bosch-ic-sensor-air-quality;
}
.bosch-ic-weather-station:before {
    content: $bosch-ic-weather-station;
}
.bosch-ic-watering-can:before {
    content: $bosch-ic-watering-can;
}
.bosch-ic-propeller:before {
    content: $bosch-ic-propeller;
}
.bosch-ic-temperature-fluctuating:before {
    content: $bosch-ic-temperature-fluctuating;
}
.bosch-ic-stove-top:before {
    content: $bosch-ic-stove-top;
}
.bosch-ic-socket:before {
    content: $bosch-ic-socket;
}
.bosch-ic-siren:before {
    content: $bosch-ic-siren;
}
.bosch-ic-sensor-water:before {
    content: $bosch-ic-sensor-water;
}
.bosch-ic-user-run-sensor-motion:before {
    content: $bosch-ic-user-run-sensor-motion;
}
.bosch-ic-robot-lawnmower:before {
    content: $bosch-ic-robot-lawnmower;
}
.bosch-ic-robot-cleaning:before {
    content: $bosch-ic-robot-cleaning;
}
.bosch-ic-oven:before {
    content: $bosch-ic-oven;
}
.bosch-ic-loudspeaker:before {
    content: $bosch-ic-loudspeaker;
}
.bosch-ic-keypad:before {
    content: $bosch-ic-keypad;
}
.bosch-ic-shape-circle-square:before {
    content: $bosch-ic-shape-circle-square;
}
.bosch-ic-garage:before {
    content: $bosch-ic-garage;
}
.bosch-ic-fume-hood:before {
    content: $bosch-ic-fume-hood;
}
.bosch-ic-freezer:before {
    content: $bosch-ic-freezer;
}
.bosch-ic-food-processor:before {
    content: $bosch-ic-food-processor;
}
.bosch-ic-floorplan-rooms:before {
    content: $bosch-ic-floorplan-rooms;
}
.bosch-ic-dryer-tumble:before {
    content: $bosch-ic-dryer-tumble;
}
.bosch-ic-dryer:before {
    content: $bosch-ic-dryer;
}
.bosch-ic-door-window:before {
    content: $bosch-ic-door-window;
}
.bosch-ic-door-lock:before {
    content: $bosch-ic-door-lock;
}
.bosch-ic-door-bell:before {
    content: $bosch-ic-door-bell;
}
.bosch-ic-dishwasher:before {
    content: $bosch-ic-dishwasher;
}
.bosch-ic-dimmer:before {
    content: $bosch-ic-dimmer;
}
.bosch-ic-detector-smoke:before {
    content: $bosch-ic-detector-smoke;
}
.bosch-ic-detector-presence:before {
    content: $bosch-ic-detector-presence;
}
.bosch-ic-cooking:before {
    content: $bosch-ic-cooking;
}
.bosch-ic-coffee-maschine:before {
    content: $bosch-ic-coffee-maschine;
}
.bosch-ic-camera-indoor:before {
    content: $bosch-ic-camera-indoor;
}
.bosch-ic-broom-clean:before {
    content: $bosch-ic-broom-clean;
}
.bosch-ic-blinds:before {
    content: $bosch-ic-blinds;
}
.bosch-ic-bicycle-e-flash:before {
    content: $bosch-ic-bicycle-e-flash;
}
.bosch-ic-finger-domino-trigger:before {
    content: $bosch-ic-finger-domino-trigger;
}
.bosch-ic-automation-start:before {
    content: $bosch-ic-automation-start;
}
.bosch-ic-automation:before {
    content: $bosch-ic-automation;
}
.bosch-ic-sensor-multifunctional:before {
    content: $bosch-ic-sensor-multifunctional;
}
.bosch-ic-plane-top:before {
    content: $bosch-ic-plane-top;
}
.bosch-ic-shape-square-overlapping:before {
    content: $bosch-ic-shape-square-overlapping;
}
.bosch-ic-square-overlapping:before {
    content: $bosch-ic-square-overlapping;
}
.bosch-ic-battery-car-14v:before {
    content: $bosch-ic-battery-car-14v;
}
.bosch-ic-calibration:before {
    content: $bosch-ic-calibration;
}
.bosch-ic-structure-can-bus:before {
    content: $bosch-ic-structure-can-bus;
}
.bosch-ic-gears-direction-arrows:before {
    content: $bosch-ic-gears-direction-arrows;
}
.bosch-ic-car-limousine:before {
    content: $bosch-ic-car-limousine;
}
.bosch-ic-molecule-gas:before {
    content: $bosch-ic-molecule-gas;
}
.bosch-ic-money-euro:before {
    content: $bosch-ic-money-euro;
}
.bosch-ic-revolution-rpm:before {
    content: $bosch-ic-revolution-rpm;
}
.bosch-ic-rikscha-e:before {
    content: $bosch-ic-rikscha-e;
}
.bosch-ic-sensor-circle:before {
    content: $bosch-ic-sensor-circle;
}
.bosch-ic-speedometer-high:before {
    content: $bosch-ic-speedometer-high;
}
.bosch-ic-steering-wheel-arrows:before {
    content: $bosch-ic-steering-wheel-arrows;
}
.bosch-ic-tablet-checklist:before {
    content: $bosch-ic-tablet-checklist;
}
.bosch-ic-petrol-charging-station-hybrid:before {
    content: $bosch-ic-petrol-charging-station-hybrid;
}
.bosch-ic-weather-cloud-sun-rain-snow:before {
    content: $bosch-ic-weather-cloud-sun-rain-snow;
}
.bosch-ic-light-half:before {
    content: $bosch-ic-light-half;
}
.bosch-ic-video-sensor:before {
    content: $bosch-ic-video-sensor;
}
.bosch-ic-binary-desktop:before {
    content: $bosch-ic-binary-desktop;
}
.bosch-ic-truck-delivery:before {
    content: $bosch-ic-truck-delivery;
}
.bosch-ic-van-delivery:before {
    content: $bosch-ic-van-delivery;
}
.bosch-ic-fullscreen-user:before {
    content: $bosch-ic-fullscreen-user;
}
.bosch-ic-user-helmet-motorcycle:before {
    content: $bosch-ic-user-helmet-motorcycle;
}
.bosch-ic-user-hand-disabled-light:before {
    content: $bosch-ic-user-hand-disabled-light;
}
.bosch-ic-user-hand-disabled-bold:before {
    content: $bosch-ic-user-hand-disabled-bold;
}
.bosch-ic-link-connected:before {
    content: $bosch-ic-link-connected;
}
.bosch-ic-gate-open:before {
    content: $bosch-ic-gate-open;
}
.bosch-ic-box-checkmark:before {
    content: $bosch-ic-box-checkmark;
}
.bosch-ic-box-place:before {
    content: $bosch-ic-box-place;
}
.bosch-ic-logistics-dock:before {
    content: $bosch-ic-logistics-dock;
}
.bosch-ic-barcode-scan-logistics:before {
    content: $bosch-ic-barcode-scan-logistics;
}
.bosch-ic-locator-tracking:before {
    content: $bosch-ic-locator-tracking;
}
.bosch-ic-car-light-trunk:before {
    content: $bosch-ic-car-light-trunk;
}
.bosch-ic-car-light-glove-compartment:before {
    content: $bosch-ic-car-light-glove-compartment;
}
.bosch-ic-car-light-dashboard:before {
    content: $bosch-ic-car-light-dashboard;
}
.bosch-ic-fire-leaf:before {
    content: $bosch-ic-fire-leaf;
}
.bosch-ic-laptop-hacker:before {
    content: $bosch-ic-laptop-hacker;
}
.bosch-ic-hands-drops:before {
    content: $bosch-ic-hands-drops;
}
.bosch-ic-team-disabled-bold:before {
    content: $bosch-ic-team-disabled-bold;
}
.bosch-ic-team-disabled-light:before {
    content: $bosch-ic-team-disabled-light;
}
.bosch-ic-user-head-arm:before {
    content: $bosch-ic-user-head-arm;
}
.bosch-ic-user-head-hand-disabled-bold:before {
    content: $bosch-ic-user-head-hand-disabled-bold;
}
.bosch-ic-user-head-hand-disabled-light:before {
    content: $bosch-ic-user-head-hand-disabled-light;
}
.bosch-ic-welcome-disabled-bold:before {
    content: $bosch-ic-welcome-disabled-bold;
}
.bosch-ic-welcome-disabled-light:before {
    content: $bosch-ic-welcome-disabled-light;
}
.bosch-ic-female-male-arrows:before {
    content: $bosch-ic-female-male-arrows;
}
.bosch-ic-broccoli:before {
    content: $bosch-ic-broccoli;
}
.bosch-ic-chicken:before {
    content: $bosch-ic-chicken;
}
.bosch-ic-cow:before {
    content: $bosch-ic-cow;
}
.bosch-ic-deer:before {
    content: $bosch-ic-deer;
}
.bosch-ic-fish:before {
    content: $bosch-ic-fish;
}
.bosch-ic-garlic:before {
    content: $bosch-ic-garlic;
}
.bosch-ic-hotpepper:before {
    content: $bosch-ic-hotpepper;
}
.bosch-ic-pig:before {
    content: $bosch-ic-pig;
}
.bosch-ic-rabbit:before {
    content: $bosch-ic-rabbit;
}
.bosch-ic-sheep:before {
    content: $bosch-ic-sheep;
}
.bosch-ic-shell:before {
    content: $bosch-ic-shell;
}
.bosch-ic-shrimp:before {
    content: $bosch-ic-shrimp;
}
.bosch-ic-car-seat:before {
    content: $bosch-ic-car-seat;
}
.bosch-ic-car-seat-steering-wheel:before {
    content: $bosch-ic-car-seat-steering-wheel;
}
.bosch-ic-car-side-arrow-back:before {
    content: $bosch-ic-car-side-arrow-back;
}
.bosch-ic-car-side-arrow-front:before {
    content: $bosch-ic-car-side-arrow-front;
}
.bosch-ic-car-steering-left-disabled-bold:before {
    content: $bosch-ic-car-steering-left-disabled-bold;
}
.bosch-ic-car-steering-left-disabled-light:before {
    content: $bosch-ic-car-steering-left-disabled-light;
}
.bosch-ic-car-steering-left-lle:before {
    content: $bosch-ic-car-steering-left-lle;
}
.bosch-ic-car-steering-left-lle-disabled-bold:before {
    content: $bosch-ic-car-steering-left-lle-disabled-bold;
}
.bosch-ic-car-steering-left-lle-disabled-light:before {
    content: $bosch-ic-car-steering-left-lle-disabled-light;
}
.bosch-ic-car-steering-right-disabled-bold:before {
    content: $bosch-ic-car-steering-right-disabled-bold;
}
.bosch-ic-car-steering-right-disabled-light:before {
    content: $bosch-ic-car-steering-right-disabled-light;
}
.bosch-ic-car-steering-right-rle:before {
    content: $bosch-ic-car-steering-right-rle;
}
.bosch-ic-car-steering-right-rle-disabled-bold:before {
    content: $bosch-ic-car-steering-right-rle-disabled-bold;
}
.bosch-ic-car-steering-right-rle-disabled-light:before {
    content: $bosch-ic-car-steering-right-rle-disabled-light;
}
.bosch-ic-cars-traffic-left-right:before {
    content: $bosch-ic-cars-traffic-left-right;
}
.bosch-ic-configuration-wrench:before {
    content: $bosch-ic-configuration-wrench;
}
.bosch-ic-hook:before {
    content: $bosch-ic-hook;
}
.bosch-ic-hook-arrows:before {
    content: $bosch-ic-hook-arrows;
}
.bosch-ic-hook-arrows-wiperblade:before {
    content: $bosch-ic-hook-arrows-wiperblade;
}
.bosch-ic-hook-disabled-bold:before {
    content: $bosch-ic-hook-disabled-bold;
}
.bosch-ic-hook-disabled-light:before {
    content: $bosch-ic-hook-disabled-light;
}
.bosch-ic-info-i-frame-add:before {
    content: $bosch-ic-info-i-frame-add;
}
.bosch-ic-search-number:before {
    content: $bosch-ic-search-number;
}
.bosch-ic-search-number-add:before {
    content: $bosch-ic-search-number-add;
}
.bosch-ic-set:before {
    content: $bosch-ic-set;
}
.bosch-ic-speedometer-speed-high:before {
    content: $bosch-ic-speedometer-speed-high;
}
.bosch-ic-windshield-wiper-clean:before {
    content: $bosch-ic-windshield-wiper-clean;
}
.bosch-ic-windshield-wiper-double:before {
    content: $bosch-ic-windshield-wiper-double;
}
.bosch-ic-wiper-adapter:before {
    content: $bosch-ic-wiper-adapter;
}
.bosch-ic-wiper-blade:before {
    content: $bosch-ic-wiper-blade;
}
.bosch-ic-wiper-blade-arrow:before {
    content: $bosch-ic-wiper-blade-arrow;
}
.bosch-ic-wiper-blade-eco:before {
    content: $bosch-ic-wiper-blade-eco;
}
.bosch-ic-wiper-blade-mm:before {
    content: $bosch-ic-wiper-blade-mm;
}
.bosch-ic-wiper-blade-mm-arrows:before {
    content: $bosch-ic-wiper-blade-mm-arrows;
}
.bosch-ic-wiper-blade-spoiler:before {
    content: $bosch-ic-wiper-blade-spoiler;
}
.bosch-ic-wiper-blade-twin:before {
    content: $bosch-ic-wiper-blade-twin;
}
.bosch-ic-wiper-flatblade:before {
    content: $bosch-ic-wiper-flatblade;
}
.bosch-ic-wiper-flatblade-aeroeco:before {
    content: $bosch-ic-wiper-flatblade-aeroeco;
}
.bosch-ic-wiper-flatblade-aerofit:before {
    content: $bosch-ic-wiper-flatblade-aerofit;
}
.bosch-ic-wiper-flatblade-aerotwin:before {
    content: $bosch-ic-wiper-flatblade-aerotwin;
}
.bosch-ic-wiper-flatblade-arrow:before {
    content: $bosch-ic-wiper-flatblade-arrow;
}
.bosch-ic-wiper-flatblade-mm:before {
    content: $bosch-ic-wiper-flatblade-mm;
}
.bosch-ic-wiper-flatblade-mm-arrows:before {
    content: $bosch-ic-wiper-flatblade-mm-arrows;
}
.bosch-ic-wiper-rubber:before {
    content: $bosch-ic-wiper-rubber;
}
.bosch-ic-address-control-system:before {
    content: $bosch-ic-address-control-system;
}
.bosch-ic-balcony:before {
    content: $bosch-ic-balcony;
}
.bosch-ic-battery-car-45-degree-arrow:before {
    content: $bosch-ic-battery-car-45-degree-arrow;
}
.bosch-ic-boiler-warning:before {
    content: $bosch-ic-boiler-warning;
}
.bosch-ic-book-wrench:before {
    content: $bosch-ic-book-wrench;
}
.bosch-ic-car-driver-passenger-arrows:before {
    content: $bosch-ic-car-driver-passenger-arrows;
}
.bosch-ic-car-licence-plate:before {
    content: $bosch-ic-car-licence-plate;
}
.bosch-ic-car-side-arrow-back-tires:before {
    content: $bosch-ic-car-side-arrow-back-tires;
}
.bosch-ic-car-side-arrow-front-tires:before {
    content: $bosch-ic-car-side-arrow-front-tires;
}
.bosch-ic-car-side-half-arrow-back:before {
    content: $bosch-ic-car-side-half-arrow-back;
}
.bosch-ic-car-steering-left-arrow:before {
    content: $bosch-ic-car-steering-left-arrow;
}
.bosch-ic-car-steering-left-passenger-arrow:before {
    content: $bosch-ic-car-steering-left-passenger-arrow;
}
.bosch-ic-car-steering-right-arrow:before {
    content: $bosch-ic-car-steering-right-arrow;
}
.bosch-ic-car-steering-right-passenger-arrow:before {
    content: $bosch-ic-car-steering-right-passenger-arrow;
}
.bosch-ic-chess-board-transparency:before {
    content: $bosch-ic-chess-board-transparency;
}
.bosch-ic-cloud-locked:before {
    content: $bosch-ic-cloud-locked;
}
.bosch-ic-conference-system:before {
    content: $bosch-ic-conference-system;
}
.bosch-ic-cycletime-quarter-arrows-interval:before {
    content: $bosch-ic-cycletime-quarter-arrows-interval;
}
.bosch-ic-desktop-connectivity:before {
    content: $bosch-ic-desktop-connectivity;
}
.bosch-ic-hierarchy-checkmark:before {
    content: $bosch-ic-hierarchy-checkmark;
}
.bosch-ic-home-office:before {
    content: $bosch-ic-home-office;
}
.bosch-ic-home-semi-detached-house:before {
    content: $bosch-ic-home-semi-detached-house;
}
.bosch-ic-kitchen:before {
    content: $bosch-ic-kitchen;
}
.bosch-ic-lambda-arrows-length:before {
    content: $bosch-ic-lambda-arrows-length;
}
.bosch-ic-lambda-oxygen:before {
    content: $bosch-ic-lambda-oxygen;
}
.bosch-ic-lambda-sensor-cores:before {
    content: $bosch-ic-lambda-sensor-cores;
}
.bosch-ic-panel-control:before {
    content: $bosch-ic-panel-control;
}
.bosch-ic-prison:before {
    content: $bosch-ic-prison;
}
.bosch-ic-prison-cell:before {
    content: $bosch-ic-prison-cell;
}
.bosch-ic-radio-broadcasting:before {
    content: $bosch-ic-radio-broadcasting;
}
.bosch-ic-robot-body:before {
    content: $bosch-ic-robot-body;
}
.bosch-ic-robot-body-arrow:before {
    content: $bosch-ic-robot-body-arrow;
}
.bosch-ic-skyscraper-danger:before {
    content: $bosch-ic-skyscraper-danger;
}
.bosch-ic-skyscraper-flat:before {
    content: $bosch-ic-skyscraper-flat;
}
.bosch-ic-skyscraper-flat-floor-low:before {
    content: $bosch-ic-skyscraper-flat-floor-low;
}
.bosch-ic-skyscraper-flat-floor-middle:before {
    content: $bosch-ic-skyscraper-flat-floor-middle;
}
.bosch-ic-skyscraper-flat-floor-top:before {
    content: $bosch-ic-skyscraper-flat-floor-top;
}
.bosch-ic-skyscraper-flat-semi-detached:before {
    content: $bosch-ic-skyscraper-flat-semi-detached;
}
.bosch-ic-skyscraper-locked:before {
    content: $bosch-ic-skyscraper-locked;
}
.bosch-ic-skyscraper-settings:before {
    content: $bosch-ic-skyscraper-settings;
}
.bosch-ic-team-signpost:before {
    content: $bosch-ic-team-signpost;
}
.bosch-ic-toaster-hand-warning:before {
    content: $bosch-ic-toaster-hand-warning;
}
.bosch-ic-user-arrows-heart:before {
    content: $bosch-ic-user-arrows-heart;
}
.bosch-ic-user-graduate-chat:before {
    content: $bosch-ic-user-graduate-chat;
}
.bosch-ic-user-head-mask:before {
    content: $bosch-ic-user-head-mask;
}
.bosch-ic-warehouse:before {
    content: $bosch-ic-warehouse;
}
.bosch-ic-world-paperplane:before {
    content: $bosch-ic-world-paperplane;
}
.bosch-ic-aperture:before {
    content: $bosch-ic-aperture;
}
.bosch-ic-hook-wiperblade-arrows:before {
    content: $bosch-ic-hook-wiperblade-arrows;
}
.bosch-ic-image-remove:before {
    content: $bosch-ic-image-remove;
}
.bosch-ic-image-checked:before {
    content: $bosch-ic-image-checked;
}
.bosch-ic-apartment-in-house-terraced:before {
    content: $bosch-ic-apartment-in-house-terraced;
}
.bosch-ic-apartment-in-house:before {
    content: $bosch-ic-apartment-in-house;
}
.bosch-ic-appliance-heat-pump-leaf-add:before {
    content: $bosch-ic-appliance-heat-pump-leaf-add;
}
.bosch-ic-appliance-heat-pump-leaf-reload:before {
    content: $bosch-ic-appliance-heat-pump-leaf-reload;
}
.bosch-ic-appliance-heat-pump-oil-refresh:before {
    content: $bosch-ic-appliance-heat-pump-oil-refresh;
}
.bosch-ic-appliance-water-drop:before {
    content: $bosch-ic-appliance-water-drop;
}
.bosch-ic-appliance-water-sun:before {
    content: $bosch-ic-appliance-water-sun;
}
.bosch-ic-brake-pedal-thumb-up:before {
    content: $bosch-ic-brake-pedal-thumb-up;
}
.bosch-ic-call-plus:before {
    content: $bosch-ic-call-plus;
}
.bosch-ic-battery-car-3-plug:before {
    content: $bosch-ic-battery-car-3-plug;
}
.bosch-ic-cloud-share:before {
    content: $bosch-ic-cloud-share;
}
.bosch-ic-crankshaft:before {
    content: $bosch-ic-crankshaft;
}
.bosch-ic-detector-motion:before {
    content: $bosch-ic-detector-motion;
}
.bosch-ic-device-analog:before {
    content: $bosch-ic-device-analog;
}
.bosch-ic-device-digital:before {
    content: $bosch-ic-device-digital;
}
.bosch-ic-externallink-landscape:before {
    content: $bosch-ic-externallink-landscape;
}
.bosch-ic-gears-interlocked-arrow:before {
    content: $bosch-ic-gears-interlocked-arrow;
}
.bosch-ic-gearshift-reverse-gear:before {
    content: $bosch-ic-gearshift-reverse-gear;
}
.bosch-ic-glove:before {
    content: $bosch-ic-glove;
}
.bosch-ic-hand-warranty:before {
    content: $bosch-ic-hand-warranty;
}
.bosch-ic-heating-floor-radiator:before {
    content: $bosch-ic-heating-floor-radiator;
}
.bosch-ic-home-appliance-heat-pump-air-in:before {
    content: $bosch-ic-home-appliance-heat-pump-air-in;
}
.bosch-ic-home-appliance-heat-pump-air-out:before {
    content: $bosch-ic-home-appliance-heat-pump-air-out;
}
.bosch-ic-lightbulb-gear:before {
    content: $bosch-ic-lightbulb-gear;
}
.bosch-ic-line-open-closed:before {
    content: $bosch-ic-line-open-closed;
}
.bosch-ic-road-disabled-bold:before {
    content: $bosch-ic-road-disabled-bold;
}
.bosch-ic-road-disabled-light:before {
    content: $bosch-ic-road-disabled-light;
}
.bosch-ic-shower-drop-1:before {
    content: $bosch-ic-shower-drop-1;
}
.bosch-ic-shower-drop-2:before {
    content: $bosch-ic-shower-drop-2;
}
.bosch-ic-shower-drop-3:before {
    content: $bosch-ic-shower-drop-3;
}
.bosch-ic-shower-drop-4:before {
    content: $bosch-ic-shower-drop-4;
}
.bosch-ic-sinus-curve:before {
    content: $bosch-ic-sinus-curve;
}
.bosch-ic-team-3:before {
    content: $bosch-ic-team-3;
}
.bosch-ic-team-4:before {
    content: $bosch-ic-team-4;
}
.bosch-ic-team-5:before {
    content: $bosch-ic-team-5;
}
.bosch-ic-test-tube:before {
    content: $bosch-ic-test-tube;
}
.bosch-ic-truck-car-motorcycle-arrows:before {
    content: $bosch-ic-truck-car-motorcycle-arrows;
}
.bosch-ic-weather-cloud-dust:before {
    content: $bosch-ic-weather-cloud-dust;
}
.bosch-ic-windshield-wiper-double-parallel-left:before {
    content: $bosch-ic-windshield-wiper-double-parallel-left;
}
.bosch-ic-windshield-wiper-double-parallel-right:before {
    content: $bosch-ic-windshield-wiper-double-parallel-right;
}
.bosch-ic-world-pin:before {
    content: $bosch-ic-world-pin;
}
.bosch-ic-sign-warning-slope:before {
    content: $bosch-ic-sign-warning-slope;
}
.bosch-ic-bond-laser:before {
    content: $bosch-ic-bond-laser;
}
.bosch-ic-bond-ribbon-thick:before {
    content: $bosch-ic-bond-ribbon-thick;
}
.bosch-ic-bond-ribbon-thin:before {
    content: $bosch-ic-bond-ribbon-thin;
}
.bosch-ic-bond-wire-thick:before {
    content: $bosch-ic-bond-wire-thick;
}
.bosch-ic-bond-wire-thin:before {
    content: $bosch-ic-bond-wire-thin;
}
.bosch-ic-clamp-connection-insulation:before {
    content: $bosch-ic-clamp-connection-insulation;
}
.bosch-ic-clamp-connection-stamping-grid:before {
    content: $bosch-ic-clamp-connection-stamping-grid;
}
.bosch-ic-clinching:before {
    content: $bosch-ic-clinching;
}
.bosch-ic-connector:before {
    content: $bosch-ic-connector;
}
.bosch-ic-connector-potting:before {
    content: $bosch-ic-connector-potting;
}
.bosch-ic-element-damping:before {
    content: $bosch-ic-element-damping;
}
.bosch-ic-element-pressure-compensation:before {
    content: $bosch-ic-element-pressure-compensation;
}
.bosch-ic-flanging:before {
    content: $bosch-ic-flanging;
}
.bosch-ic-gap-pad:before {
    content: $bosch-ic-gap-pad;
}
.bosch-ic-heat-sink:before {
    content: $bosch-ic-heat-sink;
}
.bosch-ic-hook-clipsing:before {
    content: $bosch-ic-hook-clipsing;
}
.bosch-ic-interconnection-board-to-board:before {
    content: $bosch-ic-interconnection-board-to-board;
}
.bosch-ic-labyrinth-geometry:before {
    content: $bosch-ic-labyrinth-geometry;
}
.bosch-ic-lacquering-pcb:before {
    content: $bosch-ic-lacquering-pcb;
}
.bosch-ic-magnetic-rotor:before {
    content: $bosch-ic-magnetic-rotor;
}
.bosch-ic-magnetic-switch:before {
    content: $bosch-ic-magnetic-switch;
}
.bosch-ic-pin-pressfit:before {
    content: $bosch-ic-pin-pressfit;
}
.bosch-ic-power-contact:before {
    content: $bosch-ic-power-contact;
}
.bosch-ic-rivet:before {
    content: $bosch-ic-rivet;
}
.bosch-ic-seal-ring:before {
    content: $bosch-ic-seal-ring;
}
.bosch-ic-sealing-cipg:before {
    content: $bosch-ic-sealing-cipg;
}
.bosch-ic-sealing-solid:before {
    content: $bosch-ic-sealing-solid;
}
.bosch-ic-sealing-tongue-groove:before {
    content: $bosch-ic-sealing-tongue-groove;
}
.bosch-ic-shrinking-arrows-circle:before {
    content: $bosch-ic-shrinking-arrows-circle;
}
.bosch-ic-solder-selective:before {
    content: $bosch-ic-solder-selective;
}
.bosch-ic-spring-mechanical:before {
    content: $bosch-ic-spring-mechanical;
}
.bosch-ic-staking-hot-air:before {
    content: $bosch-ic-staking-hot-air;
}
.bosch-ic-stitching:before {
    content: $bosch-ic-stitching;
}
.bosch-ic-thermal-inlay:before {
    content: $bosch-ic-thermal-inlay;
}
.bosch-ic-thermal-via:before {
    content: $bosch-ic-thermal-via;
}
.bosch-ic-tube:before {
    content: $bosch-ic-tube;
}
.bosch-ic-tube-gapfiller:before {
    content: $bosch-ic-tube-gapfiller;
}
.bosch-ic-tube-gelling:before {
    content: $bosch-ic-tube-gelling;
}
.bosch-ic-tube-gluing:before {
    content: $bosch-ic-tube-gluing;
}
.bosch-ic-tube-thermal-adhesive:before {
    content: $bosch-ic-tube-thermal-adhesive;
}
.bosch-ic-tube-underfill:before {
    content: $bosch-ic-tube-underfill;
}
.bosch-ic-welding-laser:before {
    content: $bosch-ic-welding-laser;
}
.bosch-ic-welding-resistance:before {
    content: $bosch-ic-welding-resistance;
}
.bosch-ic-welding-ultrasonic:before {
    content: $bosch-ic-welding-ultrasonic;
}
.bosch-ic-air-condition-cassette:before {
    content: $bosch-ic-air-condition-cassette;
}
.bosch-ic-air-condition-ceiling:before {
    content: $bosch-ic-air-condition-ceiling;
}
.bosch-ic-air-condition-convertible:before {
    content: $bosch-ic-air-condition-convertible;
}
.bosch-ic-air-condition-duct:before {
    content: $bosch-ic-air-condition-duct;
}
.bosch-ic-air-condition-floor:before {
    content: $bosch-ic-air-condition-floor;
}
.bosch-ic-air-condition-outdoor:before {
    content: $bosch-ic-air-condition-outdoor;
}
.bosch-ic-air-condition-outdoor-hp:before {
    content: $bosch-ic-air-condition-outdoor-hp;
}
.bosch-ic-air-condition-outdoor-hr:before {
    content: $bosch-ic-air-condition-outdoor-hr;
}
.bosch-ic-air-condition-spot-vertical-airflow-swing:before {
    content: $bosch-ic-air-condition-spot-vertical-airflow-swing;
}
.bosch-ic-air-condition-horizontal-swing:before {
    content: $bosch-ic-air-condition-horizontal-swing;
}
.bosch-ic-air-condition-wall:before {
    content: $bosch-ic-air-condition-wall;
}
.bosch-ic-angle-curvature-motorcycle:before {
    content: $bosch-ic-angle-curvature-motorcycle;
}
.bosch-ic-appliance-hot:before {
    content: $bosch-ic-appliance-hot;
}
.bosch-ic-appliance-water-hot:before {
    content: $bosch-ic-appliance-water-hot;
}
.bosch-ic-appliances-hot:before {
    content: $bosch-ic-appliances-hot;
}
.bosch-ic-arrows-left-right-a:before {
    content: $bosch-ic-arrows-left-right-a;
}
.bosch-ic-arrows-left-right-exchange:before {
    content: $bosch-ic-arrows-left-right-exchange;
}
.bosch-ic-arrows-left-right-exchange-unit:before {
    content: $bosch-ic-arrows-left-right-exchange-unit;
}
.bosch-ic-arrows-left-right-ice:before {
    content: $bosch-ic-arrows-left-right-ice;
}
.bosch-ic-assembly-line-robot:before {
    content: $bosch-ic-assembly-line-robot;
}
.bosch-ic-back-left-small:before {
    content: $bosch-ic-back-left-small;
}
.bosch-ic-bacteria:before {
    content: $bosch-ic-bacteria;
}
.bosch-ic-battery-car-drop-leakage-proof:before {
    content: $bosch-ic-battery-car-drop-leakage-proof;
}
.bosch-ic-battery-car-hourglass:before {
    content: $bosch-ic-battery-car-hourglass;
}
.bosch-ic-brake-disk-arrow:before {
    content: $bosch-ic-brake-disk-arrow;
}
.bosch-ic-brake-disk-compound:before {
    content: $bosch-ic-brake-disk-compound;
}
.bosch-ic-building-religious-church:before {
    content: $bosch-ic-building-religious-church;
}
.bosch-ic-calendar-sheet:before {
    content: $bosch-ic-calendar-sheet;
}
.bosch-ic-caliper:before {
    content: $bosch-ic-caliper;
}
.bosch-ic-car-middle-arrow:before {
    content: $bosch-ic-car-middle-arrow;
}
.bosch-ic-checkmark-bold:before {
    content: $bosch-ic-checkmark-bold;
}
.bosch-ic-close-small:before {
    content: $bosch-ic-close-small;
}
.bosch-ic-data-center:before {
    content: $bosch-ic-data-center;
}
.bosch-ic-dot:before {
    content: $bosch-ic-dot;
}
.bosch-ic-down-small:before {
    content: $bosch-ic-down-small;
}
.bosch-ic-e-call:before {
    content: $bosch-ic-e-call;
}
.bosch-ic-fire-extinguisher:before {
    content: $bosch-ic-fire-extinguisher;
}
.bosch-ic-fire-off-bold:before {
    content: $bosch-ic-fire-off-bold;
}
.bosch-ic-fire-off-light:before {
    content: $bosch-ic-fire-off-light;
}
.bosch-ic-forward-right-small:before {
    content: $bosch-ic-forward-right-small;
}
.bosch-ic-health-warning:before {
    content: $bosch-ic-health-warning;
}
.bosch-ic-ignition-coil-pencil:before {
    content: $bosch-ic-ignition-coil-pencil;
}
.bosch-ic-ignition-condensor:before {
    content: $bosch-ic-ignition-condensor;
}
.bosch-ic-ignition-contact-set:before {
    content: $bosch-ic-ignition-contact-set;
}
.bosch-ic-ignition-distributor-cap:before {
    content: $bosch-ic-ignition-distributor-cap;
}
.bosch-ic-ignition-distributor-rotor:before {
    content: $bosch-ic-ignition-distributor-rotor;
}
.bosch-ic-ignition-module:before {
    content: $bosch-ic-ignition-module;
}
.bosch-ic-less-minimize-bold:before {
    content: $bosch-ic-less-minimize-bold;
}
.bosch-ic-leaf-plus-boost:before {
    content: $bosch-ic-leaf-plus-boost;
}
.bosch-ic-sensor-dynamic:before {
    content: $bosch-ic-sensor-dynamic;
}
.bosch-ic-sensor-radar:before {
    content: $bosch-ic-sensor-radar;
}
.bosch-ic-up-small:before {
    content: $bosch-ic-up-small;
}
.bosch-ic-water-ladder-off-bold:before {
    content: $bosch-ic-water-ladder-off-bold;
}
.bosch-ic-water-ladder-off-light:before {
    content: $bosch-ic-water-ladder-off-light;
}
.bosch-ic-nose-desodorize:before {
    content: $bosch-ic-nose-desodorize;
}
.bosch-ic-nose-fine-dust:before {
    content: $bosch-ic-nose-fine-dust;
}
.bosch-ic-smartcity-camera:before {
    content: $bosch-ic-smartcity-camera;
}
.bosch-ic-heating-underfloor:before {
    content: $bosch-ic-heating-underfloor;
}
.bosch-ic-drill-bit:before {
    content: $bosch-ic-drill-bit;
}
.bosch-ic-parameter-process:before {
    content: $bosch-ic-parameter-process;
}
.bosch-ic-car-side-plug-flash:before {
    content: $bosch-ic-car-side-plug-flash;
}
.bosch-ic-car-side-plug-engine-flash:before {
    content: $bosch-ic-car-side-plug-engine-flash;
}
.bosch-ic-car-side-engine-flash:before {
    content: $bosch-ic-car-side-engine-flash;
}
.bosch-ic-car-side-hydrogen-flash:before {
    content: $bosch-ic-car-side-hydrogen-flash;
}
.bosch-ic-car-side-engine:before {
    content: $bosch-ic-car-side-engine;
}
.bosch-ic-tunnel:before {
    content: $bosch-ic-tunnel;
}
.bosch-ic-stadium-sport:before {
    content: $bosch-ic-stadium-sport;
}
.bosch-ic-refinery-oil:before {
    content: $bosch-ic-refinery-oil;
}
.bosch-ic-power-plants:before {
    content: $bosch-ic-power-plants;
}
.bosch-ic-hammer-chisel:before {
    content: $bosch-ic-hammer-chisel;
}
.bosch-ic-glass-cocktail:before {
    content: $bosch-ic-glass-cocktail;
}
.bosch-ic-traffic-light:before {
    content: $bosch-ic-traffic-light;
}
.bosch-ic-vehicle-off-road:before {
    content: $bosch-ic-vehicle-off-road;
}
.bosch-ic-tablet-finger:before {
    content: $bosch-ic-tablet-finger;
}
.bosch-ic-scooter-kick:before {
    content: $bosch-ic-scooter-kick;
}
.bosch-ic-handlebar-motorcycle:before {
    content: $bosch-ic-handlebar-motorcycle;
}
.bosch-ic-display-motorcycle:before {
    content: $bosch-ic-display-motorcycle;
}
.bosch-ic-case-label-product:before {
    content: $bosch-ic-case-label-product;
}
.bosch-ic-case-label:before {
    content: $bosch-ic-case-label;
}
.bosch-ic-product-falling:before {
    content: $bosch-ic-product-falling;
}
.bosch-ic-product-checkmark:before {
    content: $bosch-ic-product-checkmark;
}
.bosch-ic-product-broken:before {
    content: $bosch-ic-product-broken;
}
.bosch-ic-lightbulb-halogen-rays-bright:before {
    content: $bosch-ic-lightbulb-halogen-rays-bright;
}
.bosch-ic-lightbulb-halogen-rays:before {
    content: $bosch-ic-lightbulb-halogen-rays;
}
.bosch-ic-lamp-gas-discharge-rays:before {
    content: $bosch-ic-lamp-gas-discharge-rays;
}
.bosch-ic-home-lightbulb-off-disabled-bold:before {
    content: $bosch-ic-home-lightbulb-off-disabled-bold;
}
.bosch-ic-home-lightbulb-off-disabled-light:before {
    content: $bosch-ic-home-lightbulb-off-disabled-light;
}
.bosch-ic-fork-lift-boxes:before {
    content: $bosch-ic-fork-lift-boxes;
}
.bosch-ic-harbour-ship-cargo:before {
    content: $bosch-ic-harbour-ship-cargo;
}
.bosch-ic-rollercoaster:before {
    content: $bosch-ic-rollercoaster;
}
.bosch-ic-sound-off-light:before {
    content: $bosch-ic-sound-off-light;
}
.bosch-ic-sound-off-bold:before {
    content: $bosch-ic-sound-off-bold;
}
.bosch-ic-car-door:before {
    content: $bosch-ic-car-door;
}
.bosch-ic-car-door-tailgate:before {
    content: $bosch-ic-car-door-tailgate;
}
.bosch-ic-car-door-split-rear:before {
    content: $bosch-ic-car-door-split-rear;
}
.bosch-ic-car-door-rear:before {
    content: $bosch-ic-car-door-rear;
}
.bosch-ic-drill-driver-cordless:before {
    content: $bosch-ic-drill-driver-cordless;
}
.bosch-ic-virus:before {
    content: $bosch-ic-virus;
}
.bosch-ic-ice-temperature:before {
    content: $bosch-ic-ice-temperature;
}
.bosch-ic-sensor-ultrasonic:before {
    content: $bosch-ic-sensor-ultrasonic;
}
.bosch-ic-user-artificial-intelligence:before {
    content: $bosch-ic-user-artificial-intelligence;
}
.bosch-ic-detector-leak:before {
    content: $bosch-ic-detector-leak;
}
.bosch-ic-car-seat-add:before {
    content: $bosch-ic-car-seat-add;
}
.bosch-ic-car-shuttle-robo:before {
    content: $bosch-ic-car-shuttle-robo;
}
.bosch-ic-caravan:before {
    content: $bosch-ic-caravan;
}
.bosch-ic-user-water-swimming:before {
    content: $bosch-ic-user-water-swimming;
}
.bosch-ic-square-add:before {
    content: $bosch-ic-square-add;
}
.bosch-ic-map-zoom-in:before {
    content: $bosch-ic-map-zoom-in;
}
.bosch-ic-map-arrow-pop-out:before {
    content: $bosch-ic-map-arrow-pop-out;
}
.bosch-ic-home-user-in-error:before {
    content: $bosch-ic-home-user-in-error;
}
.bosch-ic-home-arrow-right-in-denied:before {
    content: $bosch-ic-home-arrow-right-in-denied;
}
.bosch-ic-home-arrow-right-in-check:before {
    content: $bosch-ic-home-arrow-right-in-check;
}
.bosch-ic-home-arrow-right-in:before {
    content: $bosch-ic-home-arrow-right-in;
}
.bosch-ic-home-arrow-left-out:before {
    content: $bosch-ic-home-arrow-left-out;
}
.bosch-ic-file-error:before {
    content: $bosch-ic-file-error;
}
.bosch-ic-dealer-details-missing:before {
    content: $bosch-ic-dealer-details-missing;
}
.bosch-ic-dealer-details-check:before {
    content: $bosch-ic-dealer-details-check;
}
.bosch-ic-components-add:before {
    content: $bosch-ic-components-add;
}
.bosch-ic-machine-cell:before {
    content: $bosch-ic-machine-cell;
}
.bosch-ic-wheel-rim-clean:before {
    content: $bosch-ic-wheel-rim-clean;
}
.bosch-ic-gas-can:before {
    content: $bosch-ic-gas-can;
}
.bosch-ic-steps-locator:before {
    content: $bosch-ic-steps-locator;
}
.bosch-ic-panel-control-manual:before {
    content: $bosch-ic-panel-control-manual;
}
.bosch-ic-socket-europe:before {
    content: $bosch-ic-socket-europe;
}
.bosch-ic-tripod:before {
    content: $bosch-ic-tripod;
}
.bosch-ic-document-dxf:before {
    content: $bosch-ic-document-dxf;
}
.bosch-ic-document-dwg:before {
    content: $bosch-ic-document-dwg;
}
.bosch-ic-hook-wiperblade:before {
    content: $bosch-ic-hook-wiperblade;
}
.bosch-ic-traffic-cone:before {
    content: $bosch-ic-traffic-cone;
}
.bosch-ic-folder-locked:before {
    content: $bosch-ic-folder-locked;
}
.bosch-ic-package-self-service:before {
    content: $bosch-ic-package-self-service;
}
.bosch-ic-drop-ice:before {
    content: $bosch-ic-drop-ice;
}
.bosch-ic-lamp-gas-discharge:before {
    content: $bosch-ic-lamp-gas-discharge;
}
.bosch-ic-home-basement:before {
    content: $bosch-ic-home-basement;
}
.bosch-ic-link-edit:before {
    content: $bosch-ic-link-edit;
}
.bosch-ic-table-header-add:before {
    content: $bosch-ic-table-header-add;
}
.bosch-ic-table-column-add-after:before {
    content: $bosch-ic-table-column-add-after;
}
.bosch-ic-table-row-add-above:before {
    content: $bosch-ic-table-row-add-above;
}
.bosch-ic-table-column-add-before:before {
    content: $bosch-ic-table-column-add-before;
}
.bosch-ic-table-row-add-below:before {
    content: $bosch-ic-table-row-add-below;
}
.bosch-ic-table-cells-split:before {
    content: $bosch-ic-table-cells-split;
}
.bosch-ic-table-cells-merge:before {
    content: $bosch-ic-table-cells-merge;
}
.bosch-ic-table-row-delete:before {
    content: $bosch-ic-table-row-delete;
}
.bosch-ic-text-indent-arrow:before {
    content: $bosch-ic-text-indent-arrow;
}
.bosch-ic-text-outdent-arrow:before {
    content: $bosch-ic-text-outdent-arrow;
}
.bosch-ic-car-side-flash:before {
    content: $bosch-ic-car-side-flash;
}
.bosch-ic-battery-plug-flash:before {
    content: $bosch-ic-battery-plug-flash;
}
.bosch-ic-car-chip:before {
    content: $bosch-ic-car-chip;
}
.bosch-ic-car-cloud-upload:before {
    content: $bosch-ic-car-cloud-upload;
}
.bosch-ic-circle-parking:before {
    content: $bosch-ic-circle-parking;
}
.bosch-ic-engine-battery-flash:before {
    content: $bosch-ic-engine-battery-flash;
}
.bosch-ic-engine-battery-flash-plug:before {
    content: $bosch-ic-engine-battery-flash-plug;
}
.bosch-ic-engine-combustion:before {
    content: $bosch-ic-engine-combustion;
}
.bosch-ic-user-head-scan:before {
    content: $bosch-ic-user-head-scan;
}
.bosch-ic-bluetooth-off-disabled-bold:before {
    content: $bosch-ic-bluetooth-off-disabled-bold;
}
.bosch-ic-bluetooth-off-disabled-light:before {
    content: $bosch-ic-bluetooth-off-disabled-light;
}
.bosch-ic-communicator-off-disabled-bold:before {
    content: $bosch-ic-communicator-off-disabled-bold;
}
.bosch-ic-communicator-off-disabled-light:before {
    content: $bosch-ic-communicator-off-disabled-light;
}
.bosch-ic-connectivity-off-disabled-bold:before {
    content: $bosch-ic-connectivity-off-disabled-bold;
}
.bosch-ic-connectivity-off-disabled-light:before {
    content: $bosch-ic-connectivity-off-disabled-light;
}
.bosch-ic-globe-off-disabled-bold:before {
    content: $bosch-ic-globe-off-disabled-bold;
}
.bosch-ic-globe-off-disabled-light:before {
    content: $bosch-ic-globe-off-disabled-light;
}
.bosch-ic-lamp-led:before {
    content: $bosch-ic-lamp-led;
}
.bosch-ic-wifi-off-disabled-bold:before {
    content: $bosch-ic-wifi-off-disabled-bold;
}
.bosch-ic-wifi-off-disabled-light:before {
    content: $bosch-ic-wifi-off-disabled-light;
}
.bosch-ic-wheel-vehicle:before {
    content: $bosch-ic-wheel-vehicle;
}
.bosch-ic-ignition-cable:before {
    content: $bosch-ic-ignition-cable;
}
.bosch-ic-ignition-coil:before {
    content: $bosch-ic-ignition-coil;
}
.bosch-ic-ignition-distributor:before {
    content: $bosch-ic-ignition-distributor;
}
.bosch-ic-microphone-classic-off-disabled-light:before {
    content: $bosch-ic-microphone-classic-off-disabled-light;
}
.bosch-ic-microphone-classic-off-disabled-bold:before {
    content: $bosch-ic-microphone-classic-off-disabled-bold;
}
.bosch-ic-cloud-refresh:before {
    content: $bosch-ic-cloud-refresh;
}
.bosch-ic-ignition-coil-module:before {
    content: $bosch-ic-ignition-coil-module;
}
.bosch-ic-abort-frame:before {
    content: $bosch-ic-abort-frame;
}
.bosch-ic-arrow-down-frame:before {
    content: $bosch-ic-arrow-down-frame;
}
.bosch-ic-arrow-left-down-frame:before {
    content: $bosch-ic-arrow-left-down-frame;
}
.bosch-ic-arrow-left-frame:before {
    content: $bosch-ic-arrow-left-frame;
}
.bosch-ic-arrow-left-up-frame:before {
    content: $bosch-ic-arrow-left-up-frame;
}
.bosch-ic-arrow-right-down-frame:before {
    content: $bosch-ic-arrow-right-down-frame;
}
.bosch-ic-arrow-right-frame:before {
    content: $bosch-ic-arrow-right-frame;
}
.bosch-ic-arrow-right-up-frame:before {
    content: $bosch-ic-arrow-right-up-frame;
}
.bosch-ic-arrow-up-frame:before {
    content: $bosch-ic-arrow-up-frame;
}
.bosch-ic-asiapacific-frame:before {
    content: $bosch-ic-asiapacific-frame;
}
.bosch-ic-cash-frame:before {
    content: $bosch-ic-cash-frame;
}
.bosch-ic-checkmark-frame:before {
    content: $bosch-ic-checkmark-frame;
}
.bosch-ic-copyright-frame:before {
    content: $bosch-ic-copyright-frame;
}
.bosch-ic-download-frame:before {
    content: $bosch-ic-download-frame;
}
.bosch-ic-europe-frame:before {
    content: $bosch-ic-europe-frame;
}
.bosch-ic-lock-closed-frame:before {
    content: $bosch-ic-lock-closed-frame;
}
.bosch-ic-lock-open-frame:before {
    content: $bosch-ic-lock-open-frame;
}
.bosch-ic-my-brand-frame:before {
    content: $bosch-ic-my-brand-frame;
}
.bosch-ic-northamerica-frame:before {
    content: $bosch-ic-northamerica-frame;
}
.bosch-ic-problem-frame:before {
    content: $bosch-ic-problem-frame;
}
.bosch-ic-promotion-frame:before {
    content: $bosch-ic-promotion-frame;
}
.bosch-ic-question-frame:before {
    content: $bosch-ic-question-frame;
}
.bosch-ic-share-frame:before {
    content: $bosch-ic-share-frame;
}
.bosch-ic-southamerica-frame:before {
    content: $bosch-ic-southamerica-frame;
}
.bosch-ic-start-play-frame:before {
    content: $bosch-ic-start-play-frame;
}
.bosch-ic-upload-frame:before {
    content: $bosch-ic-upload-frame;
}
.bosch-ic-world-frame:before {
    content: $bosch-ic-world-frame;
}
.bosch-ic-add-frame:before {
    content: $bosch-ic-add-frame;
}
.bosch-ic-call-deny-frame:before {
    content: $bosch-ic-call-deny-frame;
}
.bosch-ic-call-frame:before {
    content: $bosch-ic-call-frame;
}
.bosch-ic-fast-backward-frame:before {
    content: $bosch-ic-fast-backward-frame;
}
.bosch-ic-fast-forward-frame:before {
    content: $bosch-ic-fast-forward-frame;
}
.bosch-ic-skip-backward-frame:before {
    content: $bosch-ic-skip-backward-frame;
}
.bosch-ic-skip-fast-backward-frame:before {
    content: $bosch-ic-skip-fast-backward-frame;
}
.bosch-ic-skip-fast-forward-frame:before {
    content: $bosch-ic-skip-fast-forward-frame;
}
.bosch-ic-skip-forward-frame:before {
    content: $bosch-ic-skip-forward-frame;
}
.bosch-ic-subtract-frame:before {
    content: $bosch-ic-subtract-frame;
}
.bosch-ic-info-i-frame:before {
    content: $bosch-ic-info-i-frame;
}
.bosch-ic-stop-frame1:before {
    content: $bosch-ic-stop-frame1;
}
.bosch-ic-arrow-up-frame-error1:before {
    content: $bosch-ic-arrow-up-frame-error1;
}
.bosch-ic-arrow-up-frame-check1:before {
    content: $bosch-ic-arrow-up-frame-check1;
}
.bosch-ic-arrow-left-end-frame1:before {
    content: $bosch-ic-arrow-left-end-frame1;
}
.bosch-ic-arrow-right-end-frame1:before {
    content: $bosch-ic-arrow-right-end-frame1;
}
.bosch-ic-bluetooth-frame:before {
    content: $bosch-ic-bluetooth-frame;
}
.bosch-ic-bluetooth-frame-error:before {
    content: $bosch-ic-bluetooth-frame-error;
}
.bosch-ic-ece-ccc-frame:before {
    content: $bosch-ic-ece-ccc-frame;
}
.bosch-ic-recycle-arrows-pap20-frame:before {
    content: $bosch-ic-recycle-arrows-pap20-frame;
}
.bosch-ic-umbrella-drops-frame:before {
    content: $bosch-ic-umbrella-drops-frame;
}
.bosch-ic-arrows-this-side-up-frame:before {
    content: $bosch-ic-arrows-this-side-up-frame;
}
.bosch-ic-glass-wine-frame:before {
    content: $bosch-ic-glass-wine-frame;
}
.bosch-ic-pb-frame:before {
    content: $bosch-ic-pb-frame;
}
.bosch-ic-pb-frame-disabled-bold:before {
    content: $bosch-ic-pb-frame-disabled-bold;
}
.bosch-ic-pb-frame-disabled-light:before {
    content: $bosch-ic-pb-frame-disabled-light;
}
.bosch-ic-mode-frame:before {
    content: $bosch-ic-mode-frame;
}
.bosch-ic-uv-frame-disabled-bold:before {
    content: $bosch-ic-uv-frame-disabled-bold;
}
.bosch-ic-uv-frame-disabled-light:before {
    content: $bosch-ic-uv-frame-disabled-light;
}
.bosch-ic-fan-frame:before {
    content: $bosch-ic-fan-frame;
}
.bosch-ic-flash-frame:before {
    content: $bosch-ic-flash-frame;
}
.bosch-ic-facebook-frame:before {
    content: $bosch-ic-facebook-frame;
}
.bosch-ic-googleplus-frame:before {
    content: $bosch-ic-googleplus-frame;
}
.bosch-ic-instagram-frame:before {
    content: $bosch-ic-instagram-frame;
}
.bosch-ic-lineapp-frame:before {
    content: $bosch-ic-lineapp-frame;
}
.bosch-ic-linkedin-frame:before {
    content: $bosch-ic-linkedin-frame;
}
.bosch-ic-pinterest-frame:before {
    content: $bosch-ic-pinterest-frame;
}
.bosch-ic-snapchat-frame:before {
    content: $bosch-ic-snapchat-frame;
}
.bosch-ic-tumblr-frame:before {
    content: $bosch-ic-tumblr-frame;
}
.bosch-ic-twitter-frame:before {
    content: $bosch-ic-twitter-frame;
}
.bosch-ic-vimeo-frame:before {
    content: $bosch-ic-vimeo-frame;
}
.bosch-ic-wechat-frame:before {
    content: $bosch-ic-wechat-frame;
}
.bosch-ic-weibo-frame:before {
    content: $bosch-ic-weibo-frame;
}
.bosch-ic-whatsapp-frame:before {
    content: $bosch-ic-whatsapp-frame;
}
.bosch-ic-xing-frame:before {
    content: $bosch-ic-xing-frame;
}
.bosch-ic-youku-frame:before {
    content: $bosch-ic-youku-frame;
}
.bosch-ic-youtube-frame:before {
    content: $bosch-ic-youtube-frame;
}
.bosch-ic-vk-frame:before {
    content: $bosch-ic-vk-frame;
}
.bosch-ic-skype-frame:before {
    content: $bosch-ic-skype-frame;
}
.bosch-ic-facebook:before {
    content: $bosch-ic-facebook;
}
.bosch-ic-google-frame:before {
    content: $bosch-ic-google-frame;
}
.bosch-ic-google:before {
    content: $bosch-ic-google;
}
.bosch-ic-googleplus:before {
    content: $bosch-ic-googleplus;
}
.bosch-ic-instagram:before {
    content: $bosch-ic-instagram;
}
.bosch-ic-lineapp:before {
    content: $bosch-ic-lineapp;
}
.bosch-ic-linkedin:before {
    content: $bosch-ic-linkedin;
}
.bosch-ic-pinterest:before {
    content: $bosch-ic-pinterest;
}
.bosch-ic-skype:before {
    content: $bosch-ic-skype;
}
.bosch-ic-snapchat:before {
    content: $bosch-ic-snapchat;
}
.bosch-ic-tumblr:before {
    content: $bosch-ic-tumblr;
}
.bosch-ic-twitter:before {
    content: $bosch-ic-twitter;
}
.bosch-ic-vimeo:before {
    content: $bosch-ic-vimeo;
}
.bosch-ic-vk:before {
    content: $bosch-ic-vk;
}
.bosch-ic-wechat:before {
    content: $bosch-ic-wechat;
}
.bosch-ic-weibo:before {
    content: $bosch-ic-weibo;
}
.bosch-ic-whatsapp:before {
    content: $bosch-ic-whatsapp;
}
.bosch-ic-xing:before {
    content: $bosch-ic-xing;
}
.bosch-ic-youku:before {
    content: $bosch-ic-youku;
}
.bosch-ic-youtube:before {
    content: $bosch-ic-youtube;
}
.bosch-ic-ms-teams:before {
    content: $bosch-ic-ms-teams;
}
.bosch-ic-ms-teams-frame:before {
    content: $bosch-ic-ms-teams-frame;
}
.bosch-ic-kakao:before {
    content: $bosch-ic-kakao;
}
.bosch-ic-kakao-frame:before {
    content: $bosch-ic-kakao-frame;
}
.bosch-ic-naver:before {
    content: $bosch-ic-naver;
}
.bosch-ic-naver-frame:before {
    content: $bosch-ic-naver-frame;
}
.bosch-ic-tencent:before {
    content: $bosch-ic-tencent;
}
.bosch-ic-tencent-frame:before {
    content: $bosch-ic-tencent-frame;
}
.bosch-ic-wordpress:before {
    content: $bosch-ic-wordpress;
}
.bosch-ic-wordpress-frame:before {
    content: $bosch-ic-wordpress-frame;
}
.bosch-ic-mini-program-frame:before {
    content: $bosch-ic-mini-program-frame;
}
.bosch-ic-mini-program:before {
    content: $bosch-ic-mini-program;
}
.bosch-ic-tiktok:before {
    content: $bosch-ic-tiktok;
}
.bosch-ic-tiktok-frame:before {
    content: $bosch-ic-tiktok-frame;
}
.bosch-ic-glassdoor:before {
    content: $bosch-ic-glassdoor;
}
.bosch-ic-glassdoor-frame:before {
    content: $bosch-ic-glassdoor-frame;
}
.bosch-ic-git-hub:before {
    content: $bosch-ic-git-hub;
}
.bosch-ic-git-hub-frame:before {
    content: $bosch-ic-git-hub-frame;
}
.bosch-ic-auracast-marketing:before {
    content: $bosch-ic-auracast-marketing;
}
.bosch-ic-auracast-product:before {
    content: $bosch-ic-auracast-product;
}
.bosch-ic-nfc-charging:before {
    content: $bosch-ic-nfc-charging;
}
.bosch-ic-nfc-charging-frame:before {
    content: $bosch-ic-nfc-charging-frame;
}
.bosch-ic-nfc-directional:before {
    content: $bosch-ic-nfc-directional;
}
.bosch-ic-nfc-instructional-directional:before {
    content: $bosch-ic-nfc-instructional-directional;
}
.bosch-ic-nfc-instructional-simplified:before {
    content: $bosch-ic-nfc-instructional-simplified;
}
.bosch-ic-nfc-simplified:before {
    content: $bosch-ic-nfc-simplified;
}
.bosch-ic-nfc-simplified-frame:before {
    content: $bosch-ic-nfc-simplified-frame;
}
.bosch-ic-plug-and-charge:before {
    content: $bosch-ic-plug-and-charge;
}
.bosch-ic-plug-and-charge-frame:before {
    content: $bosch-ic-plug-and-charge-frame;
}
.bosch-ic-plug-and-charge-symbol:before {
    content: $bosch-ic-plug-and-charge-symbol;
}
.bosch-ic-plug-and-charge-symbol-frame:before {
    content: $bosch-ic-plug-and-charge-symbol-frame;
}
.bosch-ic-twitter-x:before {
    content: $bosch-ic-twitter-x;
}
.bosch-ic-twitter-x-frame:before {
    content: $bosch-ic-twitter-x-frame;
}
.bosch-ic-alert-error-filled:before {
    content: $bosch-ic-alert-error-filled;
}
.bosch-ic-alert-error:before {
    content: $bosch-ic-alert-error;
}
.bosch-ic-alert-success-filled:before {
    content: $bosch-ic-alert-success-filled;
}
.bosch-ic-alert-success:before {
    content: $bosch-ic-alert-success;
}
.bosch-ic-alert-warning-filled:before {
    content: $bosch-ic-alert-warning-filled;
}
.bosch-ic-alert-warning:before {
    content: $bosch-ic-alert-warning;
}
.bosch-ic-alert-info-filled:before {
    content: $bosch-ic-alert-info-filled;
}
.bosch-ic-alert-info:before {
    content: $bosch-ic-alert-info;
}
.bosch-ic-mold:before {
    content: $bosch-ic-mold;
}
.bosch-ic-battery-car-cranking-power-high:before {
    content: $bosch-ic-battery-car-cranking-power-high;
}
.bosch-ic-brake-disk-spray-can-coated:before {
    content: $bosch-ic-brake-disk-spray-can-coated;
}
.bosch-ic-car-top-arrows-left-front-right-back:before {
    content: $bosch-ic-car-top-arrows-left-front-right-back;
}
.bosch-ic-car-top-arrows-left-back-right-front:before {
    content: $bosch-ic-car-top-arrows-left-back-right-front;
}
.bosch-ic-car-top-arrows-front:before {
    content: $bosch-ic-car-top-arrows-front;
}
.bosch-ic-car-top-arrows-back-front-left-right:before {
    content: $bosch-ic-car-top-arrows-back-front-left-right;
}
.bosch-ic-car-top-arrows-back:before {
    content: $bosch-ic-car-top-arrows-back;
}
.bosch-ic-car-top-arrow-right:before {
    content: $bosch-ic-car-top-arrow-right;
}
.bosch-ic-car-top-arrow-left:before {
    content: $bosch-ic-car-top-arrow-left;
}
.bosch-ic-car-top-arrow-front-right:before {
    content: $bosch-ic-car-top-arrow-front-right;
}
.bosch-ic-car-top-arrow-front-left:before {
    content: $bosch-ic-car-top-arrow-front-left;
}
.bosch-ic-car-top-arrow-back-right:before {
    content: $bosch-ic-car-top-arrow-back-right;
}
.bosch-ic-car-top-arrow-back-left:before {
    content: $bosch-ic-car-top-arrow-back-left;
}
.bosch-ic-watermelon:before {
    content: $bosch-ic-watermelon;
}
.bosch-ic-wind-vane-moderate:before {
    content: $bosch-ic-wind-vane-moderate;
}
.bosch-ic-radioactive:before {
    content: $bosch-ic-radioactive;
}
.bosch-ic-heating-underfloor-off-bold:before {
    content: $bosch-ic-heating-underfloor-off-bold;
}
.bosch-ic-heating-underfloor-off-light:before {
    content: $bosch-ic-heating-underfloor-off-light;
}
.bosch-ic-goat:before {
    content: $bosch-ic-goat;
}
.bosch-ic-fire-hose:before {
    content: $bosch-ic-fire-hose;
}
.bosch-ic-user-run-arrow:before {
    content: $bosch-ic-user-run-arrow;
}
.bosch-ic-store:before {
    content: $bosch-ic-store;
}
.bosch-ic-user-work-dig:before {
    content: $bosch-ic-user-work-dig;
}
.bosch-ic-car-top-arrow-angle-right:before {
    content: $bosch-ic-car-top-arrow-angle-right;
}
.bosch-ic-control-unit-ecu:before {
    content: $bosch-ic-control-unit-ecu;
}
.bosch-ic-cigarette:before {
    content: $bosch-ic-cigarette;
}
.bosch-ic-structure-line:before {
    content: $bosch-ic-structure-line;
}
.bosch-ic-product-database-table:before {
    content: $bosch-ic-product-database-table;
}
.bosch-ic-bicycle-e-remote-led:before {
    content: $bosch-ic-bicycle-e-remote-led;
}
.bosch-ic-bicycle-e-drive-unit:before {
    content: $bosch-ic-bicycle-e-drive-unit;
}
.bosch-ic-bicycle-e-drive-unit-individual-support:before {
    content: $bosch-ic-bicycle-e-drive-unit-individual-support;
}
.bosch-ic-nm-arrow-circle:before {
    content: $bosch-ic-nm-arrow-circle;
}
.bosch-ic-tree-path:before {
    content: $bosch-ic-tree-path;
}
.bosch-ic-bicycle-e-wheel-arrows:before {
    content: $bosch-ic-bicycle-e-wheel-arrows;
}
.bosch-ic-tune-vertical:before {
    content: $bosch-ic-tune-vertical;
}
.bosch-ic-sound-professional:before {
    content: $bosch-ic-sound-professional;
}
.bosch-ic-plane-top-headset:before {
    content: $bosch-ic-plane-top-headset;
}
.bosch-ic-desktop-user-headset:before {
    content: $bosch-ic-desktop-user-headset;
}
.bosch-ic-user-service:before {
    content: $bosch-ic-user-service;
}
.bosch-ic-audio-commercial:before {
    content: $bosch-ic-audio-commercial;
}
.bosch-ic-loudspeaker-portable-note:before {
    content: $bosch-ic-loudspeaker-portable-note;
}
.bosch-ic-audio-loudspeaker-public:before {
    content: $bosch-ic-audio-loudspeaker-public;
}
.bosch-ic-items-lost-questionmark:before {
    content: $bosch-ic-items-lost-questionmark;
}
.bosch-ic-hand-pull:before {
    content: $bosch-ic-hand-pull;
}
.bosch-ic-steering-wheel-hand-horn:before {
    content: $bosch-ic-steering-wheel-hand-horn;
}
.bosch-ic-car-chassis:before {
    content: $bosch-ic-car-chassis;
}
.bosch-ic-steering-system:before {
    content: $bosch-ic-steering-system;
}
.bosch-ic-wheel-suspension:before {
    content: $bosch-ic-wheel-suspension;
}
.bosch-ic-car-body-side:before {
    content: $bosch-ic-car-body-side;
}
.bosch-ic-address-consumer-data-upright:before {
    content: $bosch-ic-address-consumer-data-upright;
}
.bosch-ic-weather-wind-rain:before {
    content: $bosch-ic-weather-wind-rain;
}
.bosch-ic-weather-rain-warning:before {
    content: $bosch-ic-weather-rain-warning;
}
.bosch-ic-user-check:before {
    content: $bosch-ic-user-check;
}
.bosch-ic-user-warning:before {
    content: $bosch-ic-user-warning;
}
.bosch-ic-black-white-stars-circle:before {
    content: $bosch-ic-black-white-stars-circle;
}
.bosch-ic-team-warning:before {
    content: $bosch-ic-team-warning;
}
.bosch-ic-black-white-circle:before {
    content: $bosch-ic-black-white-circle;
}
.bosch-ic-suitcase-small:before {
    content: $bosch-ic-suitcase-small;
}
.bosch-ic-suitcase-small-remove:before {
    content: $bosch-ic-suitcase-small-remove;
}
.bosch-ic-home-arrow-out:before {
    content: $bosch-ic-home-arrow-out;
}
.bosch-ic-home-arrow-in:before {
    content: $bosch-ic-home-arrow-in;
}
.bosch-ic-document-copy-check:before {
    content: $bosch-ic-document-copy-check;
}
.bosch-ic-ring-infrared:before {
    content: $bosch-ic-ring-infrared;
}
.bosch-ic-spirit-level:before {
    content: $bosch-ic-spirit-level;
}
.bosch-ic-drill-driver-check:before {
    content: $bosch-ic-drill-driver-check;
}
.bosch-ic-desktop-led:before {
    content: $bosch-ic-desktop-led;
}
.bosch-ic-desktop-black-white:before {
    content: $bosch-ic-desktop-black-white;
}
.bosch-ic-desktop-warning:before {
    content: $bosch-ic-desktop-warning;
}
.bosch-ic-fire-wall:before {
    content: $bosch-ic-fire-wall;
}
.bosch-ic-onroad-car-top:before {
    content: $bosch-ic-onroad-car-top;
}
.bosch-ic-onroad-suv-top:before {
    content: $bosch-ic-onroad-suv-top;
}
.bosch-ic-onroad-truck-top:before {
    content: $bosch-ic-onroad-truck-top;
}
.bosch-ic-weather-rain-ip44:before {
    content: $bosch-ic-weather-rain-ip44;
}
.bosch-ic-weather-rain-ip65:before {
    content: $bosch-ic-weather-rain-ip65;
}
.bosch-ic-weather-rain-ip66:before {
    content: $bosch-ic-weather-rain-ip66;
}
.bosch-ic-weather-rain-ip68:before {
    content: $bosch-ic-weather-rain-ip68;
}
.bosch-ic-weather-rain-ip67:before {
    content: $bosch-ic-weather-rain-ip67;
}
.bosch-ic-user-masking:before {
    content: $bosch-ic-user-masking;
}
.bosch-ic-address-consumer-data-dual:before {
    content: $bosch-ic-address-consumer-data-dual;
}
.bosch-ic-user-framed:before {
    content: $bosch-ic-user-framed;
}
.bosch-ic-user-counting-1:before {
    content: $bosch-ic-user-counting-1;
}
.bosch-ic-square-kx-uhd:before {
    content: $bosch-ic-square-kx-uhd;
}
.bosch-ic-square-qvga:before {
    content: $bosch-ic-square-qvga;
}
.bosch-ic-square-vga:before {
    content: $bosch-ic-square-vga;
}
.bosch-ic-square-xmp:before {
    content: $bosch-ic-square-xmp;
}
.bosch-ic-square-xxxp:before {
    content: $bosch-ic-square-xxxp;
}
.bosch-ic-angle-arrows-across:before {
    content: $bosch-ic-angle-arrows-across;
}
.bosch-ic-angle:before {
    content: $bosch-ic-angle;
}
.bosch-ic-angle-arrow-up:before {
    content: $bosch-ic-angle-arrow-up;
}
.bosch-ic-angle-view-wide:before {
    content: $bosch-ic-angle-view-wide;
}
.bosch-ic-chat-language-selection:before {
    content: $bosch-ic-chat-language-selection;
}
.bosch-ic-smartphone-app:before {
    content: $bosch-ic-smartphone-app;
}
.bosch-ic-commissioning-remote:before {
    content: $bosch-ic-commissioning-remote;
}
.bosch-ic-user-run-detection-pir:before {
    content: $bosch-ic-user-run-detection-pir;
}
.bosch-ic-field-in-motion:before {
    content: $bosch-ic-field-in-motion;
}
.bosch-ic-building-integration-system:before {
    content: $bosch-ic-building-integration-system;
}
.bosch-ic-desktop-mamangement:before {
    content: $bosch-ic-desktop-mamangement;
}
.bosch-ic-desktop-management-camera:before {
    content: $bosch-ic-desktop-management-camera;
}
.bosch-ic-screwdriver-check:before {
    content: $bosch-ic-screwdriver-check;
}
.bosch-ic-screwdriver:before {
    content: $bosch-ic-screwdriver;
}
.bosch-ic-desktop-hand-touch:before {
    content: $bosch-ic-desktop-hand-touch;
}
.bosch-ic-user-robot-head:before {
    content: $bosch-ic-user-robot-head;
}
.bosch-ic-gear-circles-elements:before {
    content: $bosch-ic-gear-circles-elements;
}
.bosch-ic-gears-3:before {
    content: $bosch-ic-gears-3;
}
.bosch-ic-door-address-consumer-data:before {
    content: $bosch-ic-door-address-consumer-data;
}
.bosch-ic-lock-closed-128bit:before {
    content: $bosch-ic-lock-closed-128bit;
}
.bosch-ic-lock-closed-connected-wps:before {
    content: $bosch-ic-lock-closed-connected-wps;
}
.bosch-ic-battery-arrow-right:before {
    content: $bosch-ic-battery-arrow-right;
}
.bosch-ic-connector-bnc:before {
    content: $bosch-ic-connector-bnc;
}
.bosch-ic-tower-connection-cdma:before {
    content: $bosch-ic-tower-connection-cdma;
}
.bosch-ic-tower-connection-gprs:before {
    content: $bosch-ic-tower-connection-gprs;
}
.bosch-ic-tritech:before {
    content: $bosch-ic-tritech;
}
.bosch-ic-quad-sensor-two:before {
    content: $bosch-ic-quad-sensor-two;
}
.bosch-ic-dynamic-range-hdr:before {
    content: $bosch-ic-dynamic-range-hdr;
}
.bosch-ic-dynamic-range-wdr:before {
    content: $bosch-ic-dynamic-range-wdr;
}
.bosch-ic-battery-thumb-up-arrow-right:before {
    content: $bosch-ic-battery-thumb-up-arrow-right;
}
.bosch-ic-screwdriver-thumb-up:before {
    content: $bosch-ic-screwdriver-thumb-up;
}
.bosch-ic-thumb-up-arrow-up:before {
    content: $bosch-ic-thumb-up-arrow-up;
}
.bosch-ic-core-data-sql:before {
    content: $bosch-ic-core-data-sql;
}
.bosch-ic-frequency-869:before {
    content: $bosch-ic-frequency-869;
}
.bosch-ic-coins:before {
    content: $bosch-ic-coins;
}
.bosch-ic-frames-60:before {
    content: $bosch-ic-frames-60;
}
.bosch-ic-hand-card:before {
    content: $bosch-ic-hand-card;
}
.bosch-ic-microphone-plugabble:before {
    content: $bosch-ic-microphone-plugabble;
}
.bosch-ic-video-h264:before {
    content: $bosch-ic-video-h264;
}
.bosch-ic-video-hd:before {
    content: $bosch-ic-video-hd;
}
.bosch-ic-video-mpeg:before {
    content: $bosch-ic-video-mpeg;
}
.bosch-ic-video-hd-4k:before {
    content: $bosch-ic-video-hd-4k;
}
.bosch-ic-video-hd-720:before {
    content: $bosch-ic-video-hd-720;
}
.bosch-ic-lasso:before {
    content: $bosch-ic-lasso;
}
.bosch-ic-lasso-remove:before {
    content: $bosch-ic-lasso-remove;
}
.bosch-ic-battery-car-3-clock:before {
    content: $bosch-ic-battery-car-3-clock;
}
.bosch-ic-battery-car-agm:before {
    content: $bosch-ic-battery-car-agm;
}
.bosch-ic-battery-car-flash:before {
    content: $bosch-ic-battery-car-flash;
}
.bosch-ic-filter-binary:before {
    content: $bosch-ic-filter-binary;
}
.bosch-ic-speedometer-bitrate-reduce:before {
    content: $bosch-ic-speedometer-bitrate-reduce;
}
.bosch-ic-voting:before {
    content: $bosch-ic-voting;
}
.bosch-ic-voting-dual:before {
    content: $bosch-ic-voting-dual;
}
.bosch-ic-sound-pressure-level-90:before {
    content: $bosch-ic-sound-pressure-level-90;
}
.bosch-ic-sound-pressure-level-88:before {
    content: $bosch-ic-sound-pressure-level-88;
}
.bosch-ic-sound-pressure-level-80:before {
    content: $bosch-ic-sound-pressure-level-80;
}
.bosch-ic-power-rated-20:before {
    content: $bosch-ic-power-rated-20;
}
.bosch-ic-labyrinth-geometry-integration:before {
    content: $bosch-ic-labyrinth-geometry-integration;
}
.bosch-ic-head-speaking-voice-2:before {
    content: $bosch-ic-head-speaking-voice-2;
}
.bosch-ic-volume-microphone:before {
    content: $bosch-ic-volume-microphone;
}
.bosch-ic-user-walk-route:before {
    content: $bosch-ic-user-walk-route;
}
.bosch-ic-user-walk-line-crossing:before {
    content: $bosch-ic-user-walk-line-crossing;
}
.bosch-ic-user-walk-line-count:before {
    content: $bosch-ic-user-walk-line-count;
}
.bosch-ic-fullscreen-arrows-circle:before {
    content: $bosch-ic-fullscreen-arrows-circle;
}
.bosch-ic-field-arrows-counter-flow:before {
    content: $bosch-ic-field-arrows-counter-flow;
}
.bosch-ic-field-arrows-directed-flow:before {
    content: $bosch-ic-field-arrows-directed-flow;
}
.bosch-ic-user-birds-eye-count-12:before {
    content: $bosch-ic-user-birds-eye-count-12;
}
.bosch-ic-field-user-count-12:before {
    content: $bosch-ic-field-user-count-12;
}
.bosch-ic-field-user-falling:before {
    content: $bosch-ic-field-user-falling;
}
.bosch-ic-field-user-loitering-arrow:before {
    content: $bosch-ic-field-user-loitering-arrow;
}
.bosch-ic-field-user-similarity:before {
    content: $bosch-ic-field-user-similarity;
}
.bosch-ic-field-user-walk:before {
    content: $bosch-ic-field-user-walk;
}
.bosch-ic-field-user-walk-arrow-behind:before {
    content: $bosch-ic-field-user-walk-arrow-behind;
}
.bosch-ic-field-user-walk-arrow-in-front:before {
    content: $bosch-ic-field-user-walk-arrow-in-front;
}
.bosch-ic-field-users:before {
    content: $bosch-ic-field-users;
}
.bosch-ic-shock-resistant:before {
    content: $bosch-ic-shock-resistant;
}
.bosch-ic-bus-sdi:before {
    content: $bosch-ic-bus-sdi;
}
.bosch-ic-bus-sdi2:before {
    content: $bosch-ic-bus-sdi2;
}
.bosch-ic-folder-ip-net:before {
    content: $bosch-ic-folder-ip-net;
}
.bosch-ic-globe-iscsi:before {
    content: $bosch-ic-globe-iscsi;
}
.bosch-ic-offline-www:before {
    content: $bosch-ic-offline-www;
}
.bosch-ic-opc:before {
    content: $bosch-ic-opc;
}
.bosch-ic-osdp:before {
    content: $bosch-ic-osdp;
}
.bosch-ic-settings-connected-ptrz:before {
    content: $bosch-ic-settings-connected-ptrz;
}
.bosch-ic-raid:before {
    content: $bosch-ic-raid;
}
.bosch-ic-lsn:before {
    content: $bosch-ic-lsn;
}
.bosch-ic-pointbus:before {
    content: $bosch-ic-pointbus;
}
.bosch-ic-male-count-12:before {
    content: $bosch-ic-male-count-12;
}
.bosch-ic-circle-point-record:before {
    content: $bosch-ic-circle-point-record;
}
.bosch-ic-plug-poe:before {
    content: $bosch-ic-plug-poe;
}
.bosch-ic-ir-distribution:before {
    content: $bosch-ic-ir-distribution;
}
.bosch-ic-ir-range:before {
    content: $bosch-ic-ir-range;
}
.bosch-ic-connectivity-it:before {
    content: $bosch-ic-connectivity-it;
}
.bosch-ic-music-note-plus:before {
    content: $bosch-ic-music-note-plus;
}
.bosch-ic-music-note-plus-two:before {
    content: $bosch-ic-music-note-plus-two;
}
.bosch-ic-chat-language-chn:before {
    content: $bosch-ic-chat-language-chn;
}
.bosch-ic-microphone-high-directive:before {
    content: $bosch-ic-microphone-high-directive;
}
.bosch-ic-document-copy-rg-gf:before {
    content: $bosch-ic-document-copy-rg-gf;
}
.bosch-ic-document-copy-cdq:before {
    content: $bosch-ic-document-copy-cdq;
}
.bosch-ic-document-copy-cgp:before {
    content: $bosch-ic-document-copy-cgp;
}
.bosch-ic-folder-add:before {
    content: $bosch-ic-folder-add;
}
.bosch-ic-user-tie:before {
    content: $bosch-ic-user-tie;
}
.bosch-ic-pill:before {
    content: $bosch-ic-pill;
}
.bosch-ic-sonotrode:before {
    content: $bosch-ic-sonotrode;
}
.bosch-ic-user-thumb-up:before {
    content: $bosch-ic-user-thumb-up;
}
.bosch-ic-architecture:before {
    content: $bosch-ic-architecture;
}
.bosch-ic-clamping-area-arrows:before {
    content: $bosch-ic-clamping-area-arrows;
}
.bosch-ic-clamping-rib:before {
    content: $bosch-ic-clamping-rib;
}
.bosch-ic-connection-plug-in:before {
    content: $bosch-ic-connection-plug-in;
}
.bosch-ic-connection-plug-in-electrical:before {
    content: $bosch-ic-connection-plug-in-electrical;
}
.bosch-ic-connection-plug-in-socket:before {
    content: $bosch-ic-connection-plug-in-socket;
}
.bosch-ic-connector-shield-emc:before {
    content: $bosch-ic-connector-shield-emc;
}
.bosch-ic-foil-flex:before {
    content: $bosch-ic-foil-flex;
}
.bosch-ic-grid-fan:before {
    content: $bosch-ic-grid-fan;
}
.bosch-ic-screw-nut:before {
    content: $bosch-ic-screw-nut;
}
.bosch-ic-sealing-groove:before {
    content: $bosch-ic-sealing-groove;
}
.bosch-ic-sealing-tongue-groove-flash:before {
    content: $bosch-ic-sealing-tongue-groove-flash;
}
.bosch-ic-solder:before {
    content: $bosch-ic-solder;
}
.bosch-ic-solder-selective-ssl:before {
    content: $bosch-ic-solder-selective-ssl;
}
.bosch-ic-solder-selective-thrs:before {
    content: $bosch-ic-solder-selective-thrs;
}
.bosch-ic-tuner-box:before {
    content: $bosch-ic-tuner-box;
}
.bosch-ic-water-cooling-arrow-up:before {
    content: $bosch-ic-water-cooling-arrow-up;
}
.bosch-ic-generic-device-connected:before {
    content: $bosch-ic-generic-device-connected;
}
.bosch-ic-home-sun-flash:before {
    content: $bosch-ic-home-sun-flash;
}
.bosch-ic-home-sun-wrench:before {
    content: $bosch-ic-home-sun-wrench;
}
.bosch-ic-temperature-lock:before {
    content: $bosch-ic-temperature-lock;
}
.bosch-ic-solder-method-smd:before {
    content: $bosch-ic-solder-method-smd;
}
.bosch-ic-pins:before {
    content: $bosch-ic-pins;
}
.bosch-ic-connector-housing:before {
    content: $bosch-ic-connector-housing;
}
.bosch-ic-antenna-cover-arrow-up:before {
    content: $bosch-ic-antenna-cover-arrow-up;
}
.bosch-ic-angle-open-horizontal-130:before {
    content: $bosch-ic-angle-open-horizontal-130;
}
.bosch-ic-angle-open-vertical-130:before {
    content: $bosch-ic-angle-open-vertical-130;
}
.bosch-ic-angle-open-vertical-90:before {
    content: $bosch-ic-angle-open-vertical-90;
}
.bosch-ic-angle-open-horizontal-145:before {
    content: $bosch-ic-angle-open-horizontal-145;
}
.bosch-ic-steering-system-steer-by-wire:before {
    content: $bosch-ic-steering-system-steer-by-wire;
}
.bosch-ic-tune-vertical-music-note:before {
    content: $bosch-ic-tune-vertical-music-note;
}
.bosch-ic-hammer-movement:before {
    content: $bosch-ic-hammer-movement;
}
.bosch-ic-drill-drive-percent-35:before {
    content: $bosch-ic-drill-drive-percent-35;
}
.bosch-ic-country-switzerland:before {
    content: $bosch-ic-country-switzerland;
}
.bosch-ic-speedlimit-revoking:before {
    content: $bosch-ic-speedlimit-revoking;
}
.bosch-ic-speedlimit-80-revoking:before {
    content: $bosch-ic-speedlimit-80-revoking;
}
.bosch-ic-speedlimit-80-dirty:before {
    content: $bosch-ic-speedlimit-80-dirty;
}
.bosch-ic-battery-car-mf:before {
    content: $bosch-ic-battery-car-mf;
}
.bosch-ic-access-controller-2:before {
    content: $bosch-ic-access-controller-2;
}
.bosch-ic-access-controller-arrow-up:before {
    content: $bosch-ic-access-controller-arrow-up;
}
.bosch-ic-access-controller-points-set:before {
    content: $bosch-ic-access-controller-points-set;
}
.bosch-ic-analog-value:before {
    content: $bosch-ic-analog-value;
}
.bosch-ic-analog-value-arrow-in:before {
    content: $bosch-ic-analog-value-arrow-in;
}
.bosch-ic-analog-value-arrow-out:before {
    content: $bosch-ic-analog-value-arrow-out;
}
.bosch-ic-binary-value:before {
    content: $bosch-ic-binary-value;
}
.bosch-ic-binary-value-arrow-in:before {
    content: $bosch-ic-binary-value-arrow-in;
}
.bosch-ic-binary-value-arrow-out:before {
    content: $bosch-ic-binary-value-arrow-out;
}
.bosch-ic-calendar-date-single:before {
    content: $bosch-ic-calendar-date-single;
}
.bosch-ic-calendar-date-weekend:before {
    content: $bosch-ic-calendar-date-weekend;
}
.bosch-ic-calendar-view:before {
    content: $bosch-ic-calendar-view;
}
.bosch-ic-chart-average:before {
    content: $bosch-ic-chart-average;
}
.bosch-ic-chart-line-arrows-up:before {
    content: $bosch-ic-chart-line-arrows-up;
}
.bosch-ic-configuration-points-set:before {
    content: $bosch-ic-configuration-points-set;
}
.bosch-ic-data-points-set:before {
    content: $bosch-ic-data-points-set;
}
.bosch-ic-document-points-set:before {
    content: $bosch-ic-document-points-set;
}
.bosch-ic-multistate-value:before {
    content: $bosch-ic-multistate-value;
}
.bosch-ic-multistate-value-arrow-in:before {
    content: $bosch-ic-multistate-value-arrow-in;
}
.bosch-ic-multistate-value-arrow-out:before {
    content: $bosch-ic-multistate-value-arrow-out;
}
.bosch-ic-structure-bacnet:before {
    content: $bosch-ic-structure-bacnet;
}
.bosch-ic-structure-sbus:before {
    content: $bosch-ic-structure-sbus;
}
.bosch-ic-team-arrows-in:before {
    content: $bosch-ic-team-arrows-in;
}
.bosch-ic-import-points-set:before {
    content: $bosch-ic-import-points-set;
}
.bosch-ic-image-points-set:before {
    content: $bosch-ic-image-points-set;
}
.bosch-ic-circuit-hydraulic:before {
    content: $bosch-ic-circuit-hydraulic;
}
.bosch-ic-structure-area:before {
    content: $bosch-ic-structure-area;
}
.bosch-ic-structure-calculate:before {
    content: $bosch-ic-structure-calculate;
}
.bosch-ic-structure-clipboard:before {
    content: $bosch-ic-structure-clipboard;
}
.bosch-ic-structure-collection:before {
    content: $bosch-ic-structure-collection;
}
.bosch-ic-structure-components:before {
    content: $bosch-ic-structure-components;
}
.bosch-ic-structure-device:before {
    content: $bosch-ic-structure-device;
}
.bosch-ic-structure-dragdrop:before {
    content: $bosch-ic-structure-dragdrop;
}
.bosch-ic-structure-floor:before {
    content: $bosch-ic-structure-floor;
}
.bosch-ic-structure-organization:before {
    content: $bosch-ic-structure-organization;
}
.bosch-ic-structure-questionmark:before {
    content: $bosch-ic-structure-questionmark;
}
.bosch-ic-structure-settings:before {
    content: $bosch-ic-structure-settings;
}
.bosch-ic-structure-skyscraper:before {
    content: $bosch-ic-structure-skyscraper;
}
.bosch-ic-structure-window-console:before {
    content: $bosch-ic-structure-window-console;
}
.bosch-ic-structure-wrench:before {
    content: $bosch-ic-structure-wrench;
}
.bosch-ic-car-pickup:before {
    content: $bosch-ic-car-pickup;
}
.bosch-ic-desktop-team:before {
    content: $bosch-ic-desktop-team;
}
.bosch-ic-keyboard-arrow-enter-return:before {
    content: $bosch-ic-keyboard-arrow-enter-return;
}
.bosch-ic-keyboard-arrow-left:before {
    content: $bosch-ic-keyboard-arrow-left;
}
.bosch-ic-keyboard-arrow-right-tab:before {
    content: $bosch-ic-keyboard-arrow-right-tab;
}
.bosch-ic-keyboard-capslock:before {
    content: $bosch-ic-keyboard-capslock;
}
.bosch-ic-keyboard-shift:before {
    content: $bosch-ic-keyboard-shift;
}
.bosch-ic-keyboard-space:before {
    content: $bosch-ic-keyboard-space;
}
.bosch-ic-bluetooth-le-off-disabled-light:before {
    content: $bosch-ic-bluetooth-le-off-disabled-light;
}
.bosch-ic-structure-label-edit:before {
    content: $bosch-ic-structure-label-edit;
}
.bosch-ic-structure-server:before {
    content: $bosch-ic-structure-server;
}
.bosch-ic-truck-box:before {
    content: $bosch-ic-truck-box;
}
.bosch-ic-imagery-off-disabled-light:before {
    content: $bosch-ic-imagery-off-disabled-light;
}
.bosch-ic-imagery-off-disabled-bold:before {
    content: $bosch-ic-imagery-off-disabled-bold;
}
.bosch-ic-plan-floorplan-edit:before {
    content: $bosch-ic-plan-floorplan-edit;
}
.bosch-ic-desktop-building-ruler-pen:before {
    content: $bosch-ic-desktop-building-ruler-pen;
}
.bosch-ic-conference-system-connected:before {
    content: $bosch-ic-conference-system-connected;
}
.bosch-ic-receiver-pocket-headphones:before {
    content: $bosch-ic-receiver-pocket-headphones;
}
.bosch-ic-conference-system-microphone:before {
    content: $bosch-ic-conference-system-microphone;
}
.bosch-ic-user-carry-box:before {
    content: $bosch-ic-user-carry-box;
}
.bosch-ic-user-handcart:before {
    content: $bosch-ic-user-handcart;
}
.bosch-ic-user-carry-box-2:before {
    content: $bosch-ic-user-carry-box-2;
}
.bosch-ic-locator-error:before {
    content: $bosch-ic-locator-error;
}
.bosch-ic-locator-offline:before {
    content: $bosch-ic-locator-offline;
}
.bosch-ic-locator-refresh:before {
    content: $bosch-ic-locator-refresh;
}
.bosch-ic-chart-bar-plug-arrow-up:before {
    content: $bosch-ic-chart-bar-plug-arrow-up;
}
.bosch-ic-clipboard-search-leaf:before {
    content: $bosch-ic-clipboard-search-leaf;
}
.bosch-ic-handover-leaf:before {
    content: $bosch-ic-handover-leaf;
}
.bosch-ic-home-sun-plug:before {
    content: $bosch-ic-home-sun-plug;
}
.bosch-ic-lightbulb-plug-leaf:before {
    content: $bosch-ic-lightbulb-plug-leaf;
}
.bosch-ic-wheel-water-plug:before {
    content: $bosch-ic-wheel-water-plug;
}
.bosch-ic-windmill-plug:before {
    content: $bosch-ic-windmill-plug;
}
.bosch-ic-windmill-plug-sun-water:before {
    content: $bosch-ic-windmill-plug-sun-water;
}
.bosch-ic-scale-co2:before {
    content: $bosch-ic-scale-co2;
}
.bosch-ic-certificat-co2:before {
    content: $bosch-ic-certificat-co2;
}
.bosch-ic-car-crash:before {
    content: $bosch-ic-car-crash;
}
.bosch-ic-headphones:before {
    content: $bosch-ic-headphones;
}
.bosch-ic-music-note:before {
    content: $bosch-ic-music-note;
}
.bosch-ic-clef-treble:before {
    content: $bosch-ic-clef-treble;
}
.bosch-ic-clef-bass:before {
    content: $bosch-ic-clef-bass;
}
.bosch-ic-volume:before {
    content: $bosch-ic-volume;
}
.bosch-ic-volume-increase:before {
    content: $bosch-ic-volume-increase;
}
.bosch-ic-head-speach-speaking:before {
    content: $bosch-ic-head-speach-speaking;
}
.bosch-ic-head-speaking-request:before {
    content: $bosch-ic-head-speaking-request;
}
.bosch-ic-tetragon-priority:before {
    content: $bosch-ic-tetragon-priority;
}
.bosch-ic-head-respond-speaking:before {
    content: $bosch-ic-head-respond-speaking;
}
.bosch-ic-head-respond-request:before {
    content: $bosch-ic-head-respond-request;
}
.bosch-ic-hand-stop:before {
    content: $bosch-ic-hand-stop;
}
.bosch-ic-layers-stack:before {
    content: $bosch-ic-layers-stack;
}
.bosch-ic-puzzle-binary:before {
    content: $bosch-ic-puzzle-binary;
}
.bosch-ic-sdcard-micro:before {
    content: $bosch-ic-sdcard-micro;
}
.bosch-ic-reader-card:before {
    content: $bosch-ic-reader-card;
}
.bosch-ic-reader-user-recognition-face:before {
    content: $bosch-ic-reader-user-recognition-face;
}
.bosch-ic-detector-point-type:before {
    content: $bosch-ic-detector-point-type;
}
.bosch-ic-manual-call-point:before {
    content: $bosch-ic-manual-call-point;
}
.bosch-ic-gun-magnifier:before {
    content: $bosch-ic-gun-magnifier;
}
.bosch-ic-interface-module:before {
    content: $bosch-ic-interface-module;
}
.bosch-ic-fire-detector-automatic:before {
    content: $bosch-ic-fire-detector-automatic;
}
.bosch-ic-fire-brigade-emergency:before {
    content: $bosch-ic-fire-brigade-emergency;
}
.bosch-ic-volume-siren-device:before {
    content: $bosch-ic-volume-siren-device;
}
.bosch-ic-user-reception:before {
    content: $bosch-ic-user-reception;
}
.bosch-ic-shredder-machine:before {
    content: $bosch-ic-shredder-machine;
}
.bosch-ic-badge-clean:before {
    content: $bosch-ic-badge-clean;
}
.bosch-ic-parking-assistance:before {
    content: $bosch-ic-parking-assistance;
}
.bosch-ic-laptop-questionmark:before {
    content: $bosch-ic-laptop-questionmark;
}
.bosch-ic-security-lock:before {
    content: $bosch-ic-security-lock;
}
.bosch-ic-battery-car-12v:before {
    content: $bosch-ic-battery-car-12v;
}
.bosch-ic-car-far-propagation:before {
    content: $bosch-ic-car-far-propagation;
}
.bosch-ic-car-wide-propagation:before {
    content: $bosch-ic-car-wide-propagation;
}
.bosch-ic-ear-sound:before {
    content: $bosch-ic-ear-sound;
}
.bosch-ic-umbrella-drops:before {
    content: $bosch-ic-umbrella-drops;
}
.bosch-ic-horn-arrows-in:before {
    content: $bosch-ic-horn-arrows-in;
}
.bosch-ic-video-bell:before {
    content: $bosch-ic-video-bell;
}
.bosch-ic-steering-wheel-arrows-up:before {
    content: $bosch-ic-steering-wheel-arrows-up;
}
.bosch-ic-circle-full:before {
    content: $bosch-ic-circle-full;
}
.bosch-ic-circle-half:before {
    content: $bosch-ic-circle-half;
}
.bosch-ic-circle-quarter:before {
    content: $bosch-ic-circle-quarter;
}
.bosch-ic-circle-quarter-three:before {
    content: $bosch-ic-circle-quarter-three;
}
.bosch-ic-appliance-flame-arrow:before {
    content: $bosch-ic-appliance-flame-arrow;
}
.bosch-ic-appliance-flame-arrow-bold:before {
    content: $bosch-ic-appliance-flame-arrow-bold;
}
.bosch-ic-appliance-flame-disabled-bold:before {
    content: $bosch-ic-appliance-flame-disabled-bold;
}
.bosch-ic-appliance-flame-disabled-light:before {
    content: $bosch-ic-appliance-flame-disabled-light;
}
.bosch-ic-appliance-flame-plus:before {
    content: $bosch-ic-appliance-flame-plus;
}
.bosch-ic-appliance-flame-plus-bold:before {
    content: $bosch-ic-appliance-flame-plus-bold;
}
.bosch-ic-flash-minus-bold:before {
    content: $bosch-ic-flash-minus-bold;
}
.bosch-ic-flash-minus:before {
    content: $bosch-ic-flash-minus;
}
.bosch-ic-flash-off:before {
    content: $bosch-ic-flash-off;
}
.bosch-ic-flash-plus-bold:before {
    content: $bosch-ic-flash-plus-bold;
}
.bosch-ic-flash-smart-grid:before {
    content: $bosch-ic-flash-smart-grid;
}
.bosch-ic-signal-high:before {
    content: $bosch-ic-signal-high;
}
.bosch-ic-signal-low:before {
    content: $bosch-ic-signal-low;
}
.bosch-ic-signal-very-low:before {
    content: $bosch-ic-signal-very-low;
}
.bosch-ic-5g:before {
    content: $bosch-ic-5g;
}
.bosch-ic-sun-photovoltaic:before {
    content: $bosch-ic-sun-photovoltaic;
}
.bosch-ic-inverter:before {
    content: $bosch-ic-inverter;
}
.bosch-ic-car-arrow-down:before {
    content: $bosch-ic-car-arrow-down;
}
.bosch-ic-car-distance:before {
    content: $bosch-ic-car-distance;
}
.bosch-ic-car-sensor:before {
    content: $bosch-ic-car-sensor;
}
.bosch-ic-car-sensor-environment:before {
    content: $bosch-ic-car-sensor-environment;
}
.bosch-ic-car-sensor-front:before {
    content: $bosch-ic-car-sensor-front;
}
.bosch-ic-streets-locator:before {
    content: $bosch-ic-streets-locator;
}
.bosch-ic-truck-distance:before {
    content: $bosch-ic-truck-distance;
}
.bosch-ic-warning-user-walk:before {
    content: $bosch-ic-warning-user-walk;
}
.bosch-ic-parking-check:before {
    content: $bosch-ic-parking-check;
}
.bosch-ic-dremel:before {
    content: $bosch-ic-dremel;
}
.bosch-ic-allergens:before {
    content: $bosch-ic-allergens;
}
.bosch-ic-money-currency-coins:before {
    content: $bosch-ic-money-currency-coins;
}
.bosch-ic-hydrogen-small:before {
    content: $bosch-ic-hydrogen-small;
}
.bosch-ic-hydrogen-large:before {
    content: $bosch-ic-hydrogen-large;
}
.bosch-ic-shoppingcart-remove:before {
    content: $bosch-ic-shoppingcart-remove;
}
.bosch-ic-chimney-sweeper-disabled-light:before {
    content: $bosch-ic-chimney-sweeper-disabled-light;
}
.bosch-ic-chimney-sweeper-disabled-bold:before {
    content: $bosch-ic-chimney-sweeper-disabled-bold;
}
.bosch-ic-car-waves:before {
    content: $bosch-ic-car-waves;
}
.bosch-ic-360-degrees-arrow:before {
    content: $bosch-ic-360-degrees-arrow;
}
.bosch-ic-clock-info:before {
    content: $bosch-ic-clock-info;
}
.bosch-ic-truck-front-disabled-bold:before {
    content: $bosch-ic-truck-front-disabled-bold;
}
.bosch-ic-truck-front-disabled-light:before {
    content: $bosch-ic-truck-front-disabled-light;
}
.bosch-ic-box-closed-list:before {
    content: $bosch-ic-box-closed-list;
}
.bosch-ic-battery-car-exchange:before {
    content: $bosch-ic-battery-car-exchange;
}
.bosch-ic-guitar:before {
    content: $bosch-ic-guitar;
}
.bosch-ic-actuator-by-wire:before {
    content: $bosch-ic-actuator-by-wire;
}
.bosch-ic-guitar-electric:before {
    content: $bosch-ic-guitar-electric;
}
.bosch-ic-format-align-center:before {
    content: $bosch-ic-format-align-center;
}
.bosch-ic-format-align-justify:before {
    content: $bosch-ic-format-align-justify;
}
.bosch-ic-format-align-right:before {
    content: $bosch-ic-format-align-right;
}
.bosch-ic-format-checklist:before {
    content: $bosch-ic-format-checklist;
}
.bosch-ic-format-italic:before {
    content: $bosch-ic-format-italic;
}
.bosch-ic-format-ordered-list:before {
    content: $bosch-ic-format-ordered-list;
}
.bosch-ic-format-strike:before {
    content: $bosch-ic-format-strike;
}
.bosch-ic-format-underline:before {
    content: $bosch-ic-format-underline;
}
.bosch-ic-format-align-left:before {
    content: $bosch-ic-format-align-left;
}
.bosch-ic-format-fill-drop:before {
    content: $bosch-ic-format-fill-drop;
}
.bosch-ic-format-color-a:before {
    content: $bosch-ic-format-color-a;
}
.bosch-ic-format-bold-b:before {
    content: $bosch-ic-format-bold-b;
}
.bosch-ic-fly:before {
    content: $bosch-ic-fly;
}
.bosch-ic-paw:before {
    content: $bosch-ic-paw;
}
.bosch-ic-desktop-video-response-fast:before {
    content: $bosch-ic-desktop-video-response-fast;
}
.bosch-ic-user-walk-weather-rain:before {
    content: $bosch-ic-user-walk-weather-rain;
}
.bosch-ic-brake-pedal-plug:before {
    content: $bosch-ic-brake-pedal-plug;
}
.bosch-ic-detector-motion-range-down-view:before {
    content: $bosch-ic-detector-motion-range-down-view;
}
.bosch-ic-detector-motion-range-long:before {
    content: $bosch-ic-detector-motion-range-long;
}
.bosch-ic-monitor-tiled:before {
    content: $bosch-ic-monitor-tiled;
}
.bosch-ic-radar-doppler:before {
    content: $bosch-ic-radar-doppler;
}
.bosch-ic-radioactive-disabled-light:before {
    content: $bosch-ic-radioactive-disabled-light;
}
.bosch-ic-radioactive-disabled-bold:before {
    content: $bosch-ic-radioactive-disabled-bold;
}
.bosch-ic-bug-disabled-light:before {
    content: $bosch-ic-bug-disabled-light;
}
.bosch-ic-bug-disabled-bold:before {
    content: $bosch-ic-bug-disabled-bold;
}
.bosch-ic-fly-disabled-light:before {
    content: $bosch-ic-fly-disabled-light;
}
.bosch-ic-fly-disabled-bold:before {
    content: $bosch-ic-fly-disabled-bold;
}
.bosch-ic-puzzle-2:before {
    content: $bosch-ic-puzzle-2;
}
.bosch-ic-camera-lenses-multi-images:before {
    content: $bosch-ic-camera-lenses-multi-images;
}
.bosch-ic-detector-spray:before {
    content: $bosch-ic-detector-spray;
}
.bosch-ic-weather-rain-ip56:before {
    content: $bosch-ic-weather-rain-ip56;
}
.bosch-ic-document-log-start:before {
    content: $bosch-ic-document-log-start;
}
.bosch-ic-document-log-stop:before {
    content: $bosch-ic-document-log-stop;
}
.bosch-ic-document-3d-pdf:before {
    content: $bosch-ic-document-3d-pdf;
}
.bosch-ic-document-ifc:before {
    content: $bosch-ic-document-ifc;
}
.bosch-ic-document-rfa:before {
    content: $bosch-ic-document-rfa;
}
.bosch-ic-document-stp:before {
    content: $bosch-ic-document-stp;
}
.bosch-ic-online-www:before {
    content: $bosch-ic-online-www;
}
.bosch-ic-bluetooth-connected:before {
    content: $bosch-ic-bluetooth-connected;
}
.bosch-ic-connected-tools:before {
    content: $bosch-ic-connected-tools;
}
.bosch-ic-badge-arrow-kickback:before {
    content: $bosch-ic-badge-arrow-kickback;
}
.bosch-ic-copy-paste:before {
    content: $bosch-ic-copy-paste;
}
.bosch-ic-volleyball:before {
    content: $bosch-ic-volleyball;
}
.bosch-ic-car-top-trailer:before {
    content: $bosch-ic-car-top-trailer;
}
.bosch-ic-mountain:before {
    content: $bosch-ic-mountain;
}
.bosch-ic-battery-car-oil-lamp:before {
    content: $bosch-ic-battery-car-oil-lamp;
}
.bosch-ic-battery-car-efb:before {
    content: $bosch-ic-battery-car-efb;
}
.bosch-ic-battery-car-ams:before {
    content: $bosch-ic-battery-car-ams;
}
.bosch-ic-battery-car-ag:before {
    content: $bosch-ic-battery-car-ag;
}
.bosch-ic-display-fuel-saving:before {
    content: $bosch-ic-display-fuel-saving;
}
.bosch-ic-circle-parking-arrows:before {
    content: $bosch-ic-circle-parking-arrows;
}
.bosch-ic-unicorn-head:before {
    content: $bosch-ic-unicorn-head;
}
.bosch-ic-car-parking-automated:before {
    content: $bosch-ic-car-parking-automated;
}
.bosch-ic-lunchboard:before {
    content: $bosch-ic-lunchboard;
}
.bosch-ic-tetrapack:before {
    content: $bosch-ic-tetrapack;
}
.bosch-ic-recycle-arrows:before {
    content: $bosch-ic-recycle-arrows;
}
.bosch-ic-stairs-arrow-up:before {
    content: $bosch-ic-stairs-arrow-up;
}
.bosch-ic-stairs-arrow-down:before {
    content: $bosch-ic-stairs-arrow-down;
}
.bosch-ic-industry-car-arrow:before {
    content: $bosch-ic-industry-car-arrow;
}
.bosch-ic-sensor-throttle-position:before {
    content: $bosch-ic-sensor-throttle-position;
}
.bosch-ic-sensor-knock:before {
    content: $bosch-ic-sensor-knock;
}
.bosch-ic-sensor-air-mass-meter:before {
    content: $bosch-ic-sensor-air-mass-meter;
}
.bosch-ic-sensor-pressure:before {
    content: $bosch-ic-sensor-pressure;
}
.bosch-ic-sensor-temperature:before {
    content: $bosch-ic-sensor-temperature;
}
.bosch-ic-sensor-speed-rpm:before {
    content: $bosch-ic-sensor-speed-rpm;
}
.bosch-ic-desktop-user-software-clock:before {
    content: $bosch-ic-desktop-user-software-clock;
}
.bosch-ic-book-service-wrench-add:before {
    content: $bosch-ic-book-service-wrench-add;
}
.bosch-ic-book-service-wrench:before {
    content: $bosch-ic-book-service-wrench;
}
.bosch-ic-bicycle-e-drive-unit-individual-support-off-light:before {
    content: $bosch-ic-bicycle-e-drive-unit-individual-support-off-light;
}
.bosch-ic-bicycle-e-drive-unit-individual-support-off-bold:before {
    content: $bosch-ic-bicycle-e-drive-unit-individual-support-off-bold;
}
.bosch-ic-circle-parking-disabled-bold:before {
    content: $bosch-ic-circle-parking-disabled-bold;
}
.bosch-ic-circle-parking-disabled-light:before {
    content: $bosch-ic-circle-parking-disabled-light;
}
.bosch-ic-interval-30sec:before {
    content: $bosch-ic-interval-30sec;
}
.bosch-ic-truck-front-light-inside:before {
    content: $bosch-ic-truck-front-light-inside;
}
.bosch-ic-software-tex:before {
    content: $bosch-ic-software-tex;
}
.bosch-ic-fire-arrow-circle:before {
    content: $bosch-ic-fire-arrow-circle;
}
.bosch-ic-heating-underfloor-circle-global-off:before {
    content: $bosch-ic-heating-underfloor-circle-global-off;
}
.bosch-ic-heating-radiator-circle-global-off:before {
    content: $bosch-ic-heating-radiator-circle-global-off;
}
.bosch-ic-faucet-circle-global-off:before {
    content: $bosch-ic-faucet-circle-global-off;
}
.bosch-ic-moon-stars-starlight:before {
    content: $bosch-ic-moon-stars-starlight;
}
.bosch-ic-tube-thumb-up:before {
    content: $bosch-ic-tube-thumb-up;
}
.bosch-ic-detector-microwave:before {
    content: $bosch-ic-detector-microwave;
}
.bosch-ic-user-headset-thumb-up:before {
    content: $bosch-ic-user-headset-thumb-up;
}
.bosch-ic-cloud-battery:before {
    content: $bosch-ic-cloud-battery;
}
.bosch-ic-temperature-arrow-up-increase:before {
    content: $bosch-ic-temperature-arrow-up-increase;
}
.bosch-ic-temperature-arrow-down-decrease:before {
    content: $bosch-ic-temperature-arrow-down-decrease;
}
.bosch-ic-moneybag-dollar-chart-double:before {
    content: $bosch-ic-moneybag-dollar-chart-double;
}
.bosch-ic-locator-remove:before {
    content: $bosch-ic-locator-remove;
}
.bosch-ic-square-remove:before {
    content: $bosch-ic-square-remove;
}
.bosch-ic-sun-hdr:before {
    content: $bosch-ic-sun-hdr;
}
.bosch-ic-moon-stars-circle:before {
    content: $bosch-ic-moon-stars-circle;
}
.bosch-ic-temperature-user-detection:before {
    content: $bosch-ic-temperature-user-detection;
}
.bosch-ic-wrench-thumb-up:before {
    content: $bosch-ic-wrench-thumb-up;
}
.bosch-ic-ring-infrared-ir:before {
    content: $bosch-ic-ring-infrared-ir;
}
.bosch-ic-play-back-arrow:before {
    content: $bosch-ic-play-back-arrow;
}
.bosch-ic-ignition-off:before {
    content: $bosch-ic-ignition-off;
}
.bosch-ic-arrow-up-down:before {
    content: $bosch-ic-arrow-up-down;
}
.bosch-ic-arrow-left-right:before {
    content: $bosch-ic-arrow-left-right;
}
.bosch-ic-number-0-bold:before {
    content: $bosch-ic-number-0-bold;
}
.bosch-ic-number-1-bold:before {
    content: $bosch-ic-number-1-bold;
}
.bosch-ic-number-2-bold:before {
    content: $bosch-ic-number-2-bold;
}
.bosch-ic-number-3-bold:before {
    content: $bosch-ic-number-3-bold;
}
.bosch-ic-number-4-bold:before {
    content: $bosch-ic-number-4-bold;
}
.bosch-ic-number-5-bold:before {
    content: $bosch-ic-number-5-bold;
}
.bosch-ic-number-6-bold:before {
    content: $bosch-ic-number-6-bold;
}
.bosch-ic-number-7-bold:before {
    content: $bosch-ic-number-7-bold;
}
.bosch-ic-number-8-bold:before {
    content: $bosch-ic-number-8-bold;
}
.bosch-ic-number-9-bold:before {
    content: $bosch-ic-number-9-bold;
}
.bosch-ic-e-charging-plug-dc:before {
    content: $bosch-ic-e-charging-plug-dc;
}
.bosch-ic-e-charging-plug-ac-dc:before {
    content: $bosch-ic-e-charging-plug-ac-dc;
}
.bosch-ic-angle-view-wide-monitor:before {
    content: $bosch-ic-angle-view-wide-monitor;
}
.bosch-ic-settings-range-ptrz:before {
    content: $bosch-ic-settings-range-ptrz;
}
.bosch-ic-puzzle-video-integration:before {
    content: $bosch-ic-puzzle-video-integration;
}
.bosch-ic-number-0-regular:before {
    content: $bosch-ic-number-0-regular;
}
.bosch-ic-number-1-regular:before {
    content: $bosch-ic-number-1-regular;
}
.bosch-ic-number-2-regular:before {
    content: $bosch-ic-number-2-regular;
}
.bosch-ic-number-3-regular:before {
    content: $bosch-ic-number-3-regular;
}
.bosch-ic-number-4-regular:before {
    content: $bosch-ic-number-4-regular;
}
.bosch-ic-number-5-regular:before {
    content: $bosch-ic-number-5-regular;
}
.bosch-ic-number-6-regular:before {
    content: $bosch-ic-number-6-regular;
}
.bosch-ic-number-7-regular:before {
    content: $bosch-ic-number-7-regular;
}
.bosch-ic-number-8-regular:before {
    content: $bosch-ic-number-8-regular;
}
.bosch-ic-number-9-regular:before {
    content: $bosch-ic-number-9-regular;
}
.bosch-ic-import-beep:before {
    content: $bosch-ic-import-beep;
}
.bosch-ic-file-questionmark:before {
    content: $bosch-ic-file-questionmark;
}
.bosch-ic-file-dollar:before {
    content: $bosch-ic-file-dollar;
}
.bosch-ic-document-reporting:before {
    content: $bosch-ic-document-reporting;
}
.bosch-ic-box-questionmark1:before {
    content: $bosch-ic-box-questionmark1;
}
.bosch-ic-chat-faq:before {
    content: $bosch-ic-chat-faq;
}
.bosch-ic-wishlist-filled:before {
    content: $bosch-ic-wishlist-filled;
}
.bosch-ic-wishlist-half-filled:before {
    content: $bosch-ic-wishlist-half-filled;
}
.bosch-ic-glove-work:before {
    content: $bosch-ic-glove-work;
}
.bosch-ic-lightbulb-halogen-hand-disabled-bold:before {
    content: $bosch-ic-lightbulb-halogen-hand-disabled-bold;
}
.bosch-ic-lightbulb-halogen-hand-disabled-light:before {
    content: $bosch-ic-lightbulb-halogen-hand-disabled-light;
}
.bosch-ic-lightbulb-halogen-cracked-disabled-bold:before {
    content: $bosch-ic-lightbulb-halogen-cracked-disabled-bold;
}
.bosch-ic-lightbulb-halogen-cracked-disabled-light:before {
    content: $bosch-ic-lightbulb-halogen-cracked-disabled-light;
}
.bosch-ic-lightbulb-halogen-cracked:before {
    content: $bosch-ic-lightbulb-halogen-cracked;
}
.bosch-ic-drops:before {
    content: $bosch-ic-drops;
}
.bosch-ic-drops-disabled-bold:before {
    content: $bosch-ic-drops-disabled-bold;
}
.bosch-ic-drops-disabled-light:before {
    content: $bosch-ic-drops-disabled-light;
}
.bosch-ic-temperature-heat:before {
    content: $bosch-ic-temperature-heat;
}
.bosch-ic-allen-key:before {
    content: $bosch-ic-allen-key;
}
.bosch-ic-aperture-closed:before {
    content: $bosch-ic-aperture-closed;
}
.bosch-ic-appliance-heat-pump-leaf-connected:before {
    content: $bosch-ic-appliance-heat-pump-leaf-connected;
}
.bosch-ic-badge-flash:before {
    content: $bosch-ic-badge-flash;
}
.bosch-ic-battery-car-1-plug-warning:before {
    content: $bosch-ic-battery-car-1-plug-warning;
}
.bosch-ic-battery-car-3-automatic-mode:before {
    content: $bosch-ic-battery-car-3-automatic-mode;
}
.bosch-ic-battery-car-3-ice:before {
    content: $bosch-ic-battery-car-3-ice;
}
.bosch-ic-battery-car-memory-function:before {
    content: $bosch-ic-battery-car-memory-function;
}
.bosch-ic-battery-polarity-reverse-warning:before {
    content: $bosch-ic-battery-polarity-reverse-warning;
}
.bosch-ic-book-questionmark:before {
    content: $bosch-ic-book-questionmark;
}
.bosch-ic-book-service-wrench-refresh:before {
    content: $bosch-ic-book-service-wrench-refresh;
}
.bosch-ic-box-arrow-up:before {
    content: $bosch-ic-box-arrow-up;
}
.bosch-ic-box-arrows-up-down:before {
    content: $bosch-ic-box-arrows-up-down;
}
.bosch-ic-camera-optic-sensor:before {
    content: $bosch-ic-camera-optic-sensor;
}
.bosch-ic-car-cloud-software:before {
    content: $bosch-ic-car-cloud-software;
}
.bosch-ic-car-gear-technology:before {
    content: $bosch-ic-car-gear-technology;
}
.bosch-ic-car-street:before {
    content: $bosch-ic-car-street;
}
.bosch-ic-chip-sensor:before {
    content: $bosch-ic-chip-sensor;
}
.bosch-ic-circle-blank:before {
    content: $bosch-ic-circle-blank;
}
.bosch-ic-detector-point-type-replace:before {
    content: $bosch-ic-detector-point-type-replace;
}
.bosch-ic-document-x:before {
    content: $bosch-ic-document-x;
}
.bosch-ic-drag-handle-arrows-up-down:before {
    content: $bosch-ic-drag-handle-arrows-up-down;
}
.bosch-ic-electrolyzer:before {
    content: $bosch-ic-electrolyzer;
}
.bosch-ic-exhaust-pipe:before {
    content: $bosch-ic-exhaust-pipe;
}
.bosch-ic-flower-focus-near:before {
    content: $bosch-ic-flower-focus-near;
}
.bosch-ic-fuel-pump-electric:before {
    content: $bosch-ic-fuel-pump-electric;
}
.bosch-ic-gear-dollar:before {
    content: $bosch-ic-gear-dollar;
}
.bosch-ic-gloves-work:before {
    content: $bosch-ic-gloves-work;
}
.bosch-ic-handlewithcare-globe:before {
    content: $bosch-ic-handlewithcare-globe;
}
.bosch-ic-handlewithcare-user:before {
    content: $bosch-ic-handlewithcare-user;
}
.bosch-ic-hard-disk:before {
    content: $bosch-ic-hard-disk;
}
.bosch-ic-hierarchy-child:before {
    content: $bosch-ic-hierarchy-child;
}
.bosch-ic-hierarchy-parent:before {
    content: $bosch-ic-hierarchy-parent;
}
.bosch-ic-hydraulic-connection-leaking-drops:before {
    content: $bosch-ic-hydraulic-connection-leaking-drops;
}
.bosch-ic-hydraulic-connection-off:before {
    content: $bosch-ic-hydraulic-connection-off;
}
.bosch-ic-hydraulic-connection-on:before {
    content: $bosch-ic-hydraulic-connection-on;
}
.bosch-ic-light-beam:before {
    content: $bosch-ic-light-beam;
}
.bosch-ic-map5000:before {
    content: $bosch-ic-map5000;
}
.bosch-ic-module-anti-blocking-system:before {
    content: $bosch-ic-module-anti-blocking-system;
}
.bosch-ic-module-ecg:before {
    content: $bosch-ic-module-ecg;
}
.bosch-ic-module-ecg-suction:before {
    content: $bosch-ic-module-ecg-suction;
}
.bosch-ic-module-navigation:before {
    content: $bosch-ic-module-navigation;
}
.bosch-ic-power-supply-unit-plug:before {
    content: $bosch-ic-power-supply-unit-plug;
}
.bosch-ic-security-street:before {
    content: $bosch-ic-security-street;
}
.bosch-ic-server-single:before {
    content: $bosch-ic-server-single;
}
.bosch-ic-skip-backward:before {
    content: $bosch-ic-skip-backward;
}
.bosch-ic-skip-forward:before {
    content: $bosch-ic-skip-forward;
}
.bosch-ic-speedometer-instrument-cluster:before {
    content: $bosch-ic-speedometer-instrument-cluster;
}
.bosch-ic-system-radar-connected:before {
    content: $bosch-ic-system-radar-connected;
}
.bosch-ic-truck-dump:before {
    content: $bosch-ic-truck-dump;
}
.bosch-ic-truck-loader:before {
    content: $bosch-ic-truck-loader;
}
.bosch-ic-user-head-twin-digital:before {
    content: $bosch-ic-user-head-twin-digital;
}
.bosch-ic-wind-vane-strong:before {
    content: $bosch-ic-wind-vane-strong;
}
.bosch-ic-wind-vane-weak:before {
    content: $bosch-ic-wind-vane-weak;
}
.bosch-ic-windshield-crack:before {
    content: $bosch-ic-windshield-crack;
}
.bosch-ic-common-rail:before {
    content: $bosch-ic-common-rail;
}
.bosch-ic-common-rail-injector-cri1-cri2:before {
    content: $bosch-ic-common-rail-injector-cri1-cri2;
}
.bosch-ic-common-rail-injector-cril3:before {
    content: $bosch-ic-common-rail-injector-cril3;
}
.bosch-ic-common-rail-injector-crin4-2:before {
    content: $bosch-ic-common-rail-injector-crin4-2;
}
.bosch-ic-common-rail-injector-crin123:before {
    content: $bosch-ic-common-rail-injector-crin123;
}
.bosch-ic-conventional-pump-a:before {
    content: $bosch-ic-conventional-pump-a;
}
.bosch-ic-conventional-pump-b:before {
    content: $bosch-ic-conventional-pump-b;
}
.bosch-ic-edc-inline-injection-pump-pes6p:before {
    content: $bosch-ic-edc-inline-injection-pump-pes6p;
}
.bosch-ic-edc-inline-injection-pump-pes6h:before {
    content: $bosch-ic-edc-inline-injection-pump-pes6h;
}
.bosch-ic-nozzle-holder:before {
    content: $bosch-ic-nozzle-holder;
}
.bosch-ic-unit-injector:before {
    content: $bosch-ic-unit-injector;
}
.bosch-ic-qr-code-scan:before {
    content: $bosch-ic-qr-code-scan;
}
.bosch-ic-car-mechanic-plug:before {
    content: $bosch-ic-car-mechanic-plug;
}
.bosch-ic-copy-machine:before {
    content: $bosch-ic-copy-machine;
}
.bosch-ic-dispenser-paper-towel:before {
    content: $bosch-ic-dispenser-paper-towel;
}
.bosch-ic-hand-drops-soap:before {
    content: $bosch-ic-hand-drops-soap;
}
.bosch-ic-door-automatic:before {
    content: $bosch-ic-door-automatic;
}
.bosch-ic-door-automatic-slide:before {
    content: $bosch-ic-door-automatic-slide;
}
.bosch-ic-door-pull:before {
    content: $bosch-ic-door-pull;
}
.bosch-ic-door-push:before {
    content: $bosch-ic-door-push;
}
.bosch-ic-floor-load-max:before {
    content: $bosch-ic-floor-load-max;
}
.bosch-ic-lockers:before {
    content: $bosch-ic-lockers;
}
.bosch-ic-rubbish-trash-bin:before {
    content: $bosch-ic-rubbish-trash-bin;
}
.bosch-ic-rubbish-waste-bin:before {
    content: $bosch-ic-rubbish-waste-bin;
}
.bosch-ic-rubbish-waste-container:before {
    content: $bosch-ic-rubbish-waste-container;
}
.bosch-ic-sugar:before {
    content: $bosch-ic-sugar;
}
.bosch-ic-rubbish-glass-bin:before {
    content: $bosch-ic-rubbish-glass-bin;
}
.bosch-ic-car-seat-steering-wheel-stowable:before {
    content: $bosch-ic-car-seat-steering-wheel-stowable;
}
.bosch-ic-car-seat-steering-wheel-standard:before {
    content: $bosch-ic-car-seat-steering-wheel-standard;
}
.bosch-ic-car-seat-steering-wheel-extended:before {
    content: $bosch-ic-car-seat-steering-wheel-extended;
}
.bosch-ic-device-bridge:before {
    content: $bosch-ic-device-bridge;
}
.bosch-ic-screw-cross-slot:before {
    content: $bosch-ic-screw-cross-slot;
}
.bosch-ic-screw-hexagon-head:before {
    content: $bosch-ic-screw-hexagon-head;
}
.bosch-ic-screw-hexagon-internal:before {
    content: $bosch-ic-screw-hexagon-internal;
}
.bosch-ic-screw-slotted:before {
    content: $bosch-ic-screw-slotted;
}
.bosch-ic-screw-torx:before {
    content: $bosch-ic-screw-torx;
}
.bosch-ic-tape:before {
    content: $bosch-ic-tape;
}
.bosch-ic-tape-teflon:before {
    content: $bosch-ic-tape-teflon;
}
.bosch-ic-security-fire:before {
    content: $bosch-ic-security-fire;
}
.bosch-ic-led-fault:before {
    content: $bosch-ic-led-fault;
}
.bosch-ic-led-alarm:before {
    content: $bosch-ic-led-alarm;
}
.bosch-ic-firepanel:before {
    content: $bosch-ic-firepanel;
}
.bosch-ic-fire-output-disabled:before {
    content: $bosch-ic-fire-output-disabled;
}
.bosch-ic-user-fire-alarm:before {
    content: $bosch-ic-user-fire-alarm;
}
.bosch-ic-car-desktop-graph:before {
    content: $bosch-ic-car-desktop-graph;
}
.bosch-ic-desktop-management-statistics:before {
    content: $bosch-ic-desktop-management-statistics;
}
.bosch-ic-document-key:before {
    content: $bosch-ic-document-key;
}
.bosch-ic-options-vertical:before {
    content: $bosch-ic-options-vertical;
}
.bosch-ic-euro-arrows:before {
    content: $bosch-ic-euro-arrows;
}
.bosch-ic-monkey:before {
    content: $bosch-ic-monkey;
}
.bosch-ic-appliance-resistance:before {
    content: $bosch-ic-appliance-resistance;
}
.bosch-ic-pipe-cutter:before {
    content: $bosch-ic-pipe-cutter;
}
.bosch-ic-flash-arrow:before {
    content: $bosch-ic-flash-arrow;
}
.bosch-ic-flash-arrow-disabled-light:before {
    content: $bosch-ic-flash-arrow-disabled-light;
}
.bosch-ic-heating-ceiling:before {
    content: $bosch-ic-heating-ceiling;
}
.bosch-ic-heating-ceiling-off-light:before {
    content: $bosch-ic-heating-ceiling-off-light;
}
.bosch-ic-heating-ceiling-off-bold:before {
    content: $bosch-ic-heating-ceiling-off-bold;
}
.bosch-ic-battery-analysis:before {
    content: $bosch-ic-battery-analysis;
}
.bosch-ic-battery-ice:before {
    content: $bosch-ic-battery-ice;
}
.bosch-ic-battery-refresh:before {
    content: $bosch-ic-battery-refresh;
}
.bosch-ic-device-ids:before {
    content: $bosch-ic-device-ids;
}
.bosch-ic-device-thermostat-bcc50-72:before {
    content: $bosch-ic-device-thermostat-bcc50-72;
}
.bosch-ic-device-thermostat-bcc100-72:before {
    content: $bosch-ic-device-thermostat-bcc100-72;
}
.bosch-ic-silos-gas-natural:before {
    content: $bosch-ic-silos-gas-natural;
}
.bosch-ic-silos-gas-leaf-biogas:before {
    content: $bosch-ic-silos-gas-leaf-biogas;
}
.bosch-ic-circle-decentralized:before {
    content: $bosch-ic-circle-decentralized;
}
.bosch-ic-molecules-h2:before {
    content: $bosch-ic-molecules-h2;
}
.bosch-ic-megawatt-range-arrow-scalable:before {
    content: $bosch-ic-megawatt-range-arrow-scalable;
}
.bosch-ic-fan-arrows:before {
    content: $bosch-ic-fan-arrows;
}
.bosch-ic-recycle-arrows-pvc3:before {
    content: $bosch-ic-recycle-arrows-pvc3;
}
.bosch-ic-recycle-arrows-ps6:before {
    content: $bosch-ic-recycle-arrows-ps6;
}
.bosch-ic-recycle-arrows-pp5:before {
    content: $bosch-ic-recycle-arrows-pp5;
}
.bosch-ic-recycle-arrows-pet1:before {
    content: $bosch-ic-recycle-arrows-pet1;
}
.bosch-ic-recycle-arrows-pap22:before {
    content: $bosch-ic-recycle-arrows-pap22;
}
.bosch-ic-recycle-arrows-pap21:before {
    content: $bosch-ic-recycle-arrows-pap21;
}
.bosch-ic-recycle-arrows-pap20:before {
    content: $bosch-ic-recycle-arrows-pap20;
}
.bosch-ic-recycle-arrows-oth7:before {
    content: $bosch-ic-recycle-arrows-oth7;
}
.bosch-ic-recycle-arrows-ldpe4:before {
    content: $bosch-ic-recycle-arrows-ldpe4;
}
.bosch-ic-recycle-arrows-hdpe2:before {
    content: $bosch-ic-recycle-arrows-hdpe2;
}
.bosch-ic-car-petrol-plug:before {
    content: $bosch-ic-car-petrol-plug;
}
.bosch-ic-user-diverse:before {
    content: $bosch-ic-user-diverse;
}
.bosch-ic-fire-emergency-run-map:before {
    content: $bosch-ic-fire-emergency-run-map;
}
.bosch-ic-automatic-mode-fan:before {
    content: $bosch-ic-automatic-mode-fan;
}
.bosch-ic-air-condition-swing-pac:before {
    content: $bosch-ic-air-condition-swing-pac;
}
.bosch-ic-user-fire-alarm-check:before {
    content: $bosch-ic-user-fire-alarm-check;
}
.bosch-ic-handlewithcare-team:before {
    content: $bosch-ic-handlewithcare-team;
}
.bosch-ic-settings-user-dollar:before {
    content: $bosch-ic-settings-user-dollar;
}
.bosch-ic-skyscraper-connected:before {
    content: $bosch-ic-skyscraper-connected;
}
.bosch-ic-security-ac:before {
    content: $bosch-ic-security-ac;
}
.bosch-ic-windshield-glare-sun:before {
    content: $bosch-ic-windshield-glare-sun;
}
.bosch-ic-user-flag:before {
    content: $bosch-ic-user-flag;
}
.bosch-ic-hammer-legal:before {
    content: $bosch-ic-hammer-legal;
}
.bosch-ic-handover-star:before {
    content: $bosch-ic-handover-star;
}
.bosch-ic-laptop-wrench:before {
    content: $bosch-ic-laptop-wrench;
}
.bosch-ic-fan-speed-1-display:before {
    content: $bosch-ic-fan-speed-1-display;
}
.bosch-ic-fan-speed-2-display:before {
    content: $bosch-ic-fan-speed-2-display;
}
.bosch-ic-fan-speed-3-display:before {
    content: $bosch-ic-fan-speed-3-display;
}
.bosch-ic-puzzle-binary-filled:before {
    content: $bosch-ic-puzzle-binary-filled;
}
.bosch-ic-fan-moving:before {
    content: $bosch-ic-fan-moving;
}
.bosch-ic-dog-head-side:before {
    content: $bosch-ic-dog-head-side;
}
.bosch-ic-imu-binary:before {
    content: $bosch-ic-imu-binary;
}
.bosch-ic-box-recycling:before {
    content: $bosch-ic-box-recycling;
}
.bosch-ic-video-perception:before {
    content: $bosch-ic-video-perception;
}
.bosch-ic-fan-speed-0-display:before {
    content: $bosch-ic-fan-speed-0-display;
}
.bosch-ic-air-soft-wind:before {
    content: $bosch-ic-air-soft-wind;
}
.bosch-ic-clean:before {
    content: $bosch-ic-clean;
}
.bosch-ic-air-condition-ion-negative:before {
    content: $bosch-ic-air-condition-ion-negative;
}
.bosch-ic-glasses-safety:before {
    content: $bosch-ic-glasses-safety;
}
.bosch-ic-bottle-squeeze:before {
    content: $bosch-ic-bottle-squeeze;
}
.bosch-ic-bottle-squeeze-water:before {
    content: $bosch-ic-bottle-squeeze-water;
}
.bosch-ic-knife:before {
    content: $bosch-ic-knife;
}
.bosch-ic-car-side-engine-h2:before {
    content: $bosch-ic-car-side-engine-h2;
}
.bosch-ic-nitrogen:before {
    content: $bosch-ic-nitrogen;
}
.bosch-ic-spring-tension:before {
    content: $bosch-ic-spring-tension;
}
.bosch-ic-manometer:before {
    content: $bosch-ic-manometer;
}
.bosch-ic-badge-50:before {
    content: $bosch-ic-badge-50;
}
.bosch-ic-badge-20:before {
    content: $bosch-ic-badge-20;
}
.bosch-ic-badge-10:before {
    content: $bosch-ic-badge-10;
}
.bosch-ic-garage-car-side-flash:before {
    content: $bosch-ic-garage-car-side-flash;
}
.bosch-ic-home-district:before {
    content: $bosch-ic-home-district;
}
.bosch-ic-scissors-ribbon-cut:before {
    content: $bosch-ic-scissors-ribbon-cut;
}
.bosch-ic-sign-warning-slope-high:before {
    content: $bosch-ic-sign-warning-slope-high;
}
.bosch-ic-air-condition-horizontal-airflow-auto:before {
    content: $bosch-ic-air-condition-horizontal-airflow-auto;
}
.bosch-ic-air-condition-horizontal-airflow-right:before {
    content: $bosch-ic-air-condition-horizontal-airflow-right;
}
.bosch-ic-air-condition-horizontal-airflow-center:before {
    content: $bosch-ic-air-condition-horizontal-airflow-center;
}
.bosch-ic-air-condition-horizontal-airflow-left:before {
    content: $bosch-ic-air-condition-horizontal-airflow-left;
}
.bosch-ic-air-condition-horizontal-airflow-off:before {
    content: $bosch-ic-air-condition-horizontal-airflow-off;
}
.bosch-ic-air-condition-horizontal-airflow-wide:before {
    content: $bosch-ic-air-condition-horizontal-airflow-wide;
}
.bosch-ic-air-condition-horizontal-airflow-ultra-wide:before {
    content: $bosch-ic-air-condition-horizontal-airflow-ultra-wide;
}
.bosch-ic-air-condition-horizontal-airflow-swing:before {
    content: $bosch-ic-air-condition-horizontal-airflow-swing;
}
.bosch-ic-air-condition-horizontal-airflow-swing-off:before {
    content: $bosch-ic-air-condition-horizontal-airflow-swing-off;
}
.bosch-ic-air-condition-spot-air-top-right:before {
    content: $bosch-ic-air-condition-spot-air-top-right;
}
.bosch-ic-air-condition-spot-air-top-left:before {
    content: $bosch-ic-air-condition-spot-air-top-left;
}
.bosch-ic-air-condition-spot-air-top-center:before {
    content: $bosch-ic-air-condition-spot-air-top-center;
}
.bosch-ic-air-condition-spot-air-off:before {
    content: $bosch-ic-air-condition-spot-air-off;
}
.bosch-ic-air-condition-spot-air-full:before {
    content: $bosch-ic-air-condition-spot-air-full;
}
.bosch-ic-air-condition-spot-air-bottom-right:before {
    content: $bosch-ic-air-condition-spot-air-bottom-right;
}
.bosch-ic-air-condition-spot-air-bottom-left:before {
    content: $bosch-ic-air-condition-spot-air-bottom-left;
}
.bosch-ic-air-condition-spot-air-bottom-center:before {
    content: $bosch-ic-air-condition-spot-air-bottom-center;
}
.bosch-ic-air-condition-spot-vertical-airflow-angle-5:before {
    content: $bosch-ic-air-condition-spot-vertical-airflow-angle-5;
}
.bosch-ic-air-condition-spot-vertical-airflow-angle-4:before {
    content: $bosch-ic-air-condition-spot-vertical-airflow-angle-4;
}
.bosch-ic-air-condition-spot-vertical-airflow-angle-3:before {
    content: $bosch-ic-air-condition-spot-vertical-airflow-angle-3;
}
.bosch-ic-air-condition-spot-vertical-airflow-angle-2:before {
    content: $bosch-ic-air-condition-spot-vertical-airflow-angle-2;
}
.bosch-ic-air-condition-spot-vertical-airflow-angle-6:before {
    content: $bosch-ic-air-condition-spot-vertical-airflow-angle-6;
}
.bosch-ic-air-condition-spot-vertical-airflow-swing1:before {
    content: $bosch-ic-air-condition-spot-vertical-airflow-swing1;
}
.bosch-ic-air-condition-spot-vertical-airflow-swing-off:before {
    content: $bosch-ic-air-condition-spot-vertical-airflow-swing-off;
}
.bosch-ic-air-condition-spot-vertical-airflow-angle-1:before {
    content: $bosch-ic-air-condition-spot-vertical-airflow-angle-1;
}
.bosch-ic-air-condition-spot-vertical-airflow-auto:before {
    content: $bosch-ic-air-condition-spot-vertical-airflow-auto;
}
.bosch-car-refresh-graph:before {
    content: $bosch-car-refresh-graph;
}
.bosch-ic-angle-grinder:before {
    content: $bosch-ic-angle-grinder;
}
.bosch-ic-screwdriver-battery-powered:before {
    content: $bosch-ic-screwdriver-battery-powered;
}
.bosch-ic-hammer-drill-battery-powered:before {
    content: $bosch-ic-hammer-drill-battery-powered;
}
.bosch-ic-laser-rangefinder:before {
    content: $bosch-ic-laser-rangefinder;
}
.bosch-ic-braking-system-decentralized:before {
    content: $bosch-ic-braking-system-decentralized;
}
.bosch-ic-braking-system-centralized:before {
    content: $bosch-ic-braking-system-centralized;
}
.bosch-ic-coil:before {
    content: $bosch-ic-coil;
}
.bosch-ic-circle-arrows-n-s:before {
    content: $bosch-ic-circle-arrows-n-s;
}
.bosch-ic-winter-check:before {
    content: $bosch-ic-winter-check;
}
.bosch-ic-spring-check:before {
    content: $bosch-ic-spring-check;
}
.bosch-ic-car-check-multi-point:before {
    content: $bosch-ic-car-check-multi-point;
}
.bosch-ic-holiday-check:before {
    content: $bosch-ic-holiday-check;
}
.bosch-ic-brake-disk-caliper-actuator-hydraulic:before {
    content: $bosch-ic-brake-disk-caliper-actuator-hydraulic;
}
.bosch-ic-brake-disk-electromechanical:before {
    content: $bosch-ic-brake-disk-electromechanical;
}
.bosch-ic-settings-drop-check:before {
    content: $bosch-ic-settings-drop-check;
}
.bosch-ic-drops-hygroscopic:before {
    content: $bosch-ic-drops-hygroscopic;
}
.bosch-ic-roof-solo:before {
    content: $bosch-ic-roof-solo;
}
.bosch-ic-badge-cleaned:before {
    content: $bosch-ic-badge-cleaned;
}
.bosch-ic-10-point-check:before {
    content: $bosch-ic-10-point-check;
}
.bosch-ic-car-check:before {
    content: $bosch-ic-car-check;
}
.bosch-ic-mot-check:before {
    content: $bosch-ic-mot-check;
}
.bosch-ic-vehicle-diagnostic-check:before {
    content: $bosch-ic-vehicle-diagnostic-check;
}
.bosch-ic-eletronic-diagnosis:before {
    content: $bosch-ic-eletronic-diagnosis;
}
.bosch-ic-battery-services:before {
    content: $bosch-ic-battery-services;
}
.bosch-ic-engine-diagnosis:before {
    content: $bosch-ic-engine-diagnosis;
}
.bosch-ic-engine-service:before {
    content: $bosch-ic-engine-service;
}
.bosch-ic-oil-filter-change:before {
    content: $bosch-ic-oil-filter-change;
}
.bosch-ic-brake-repair:before {
    content: $bosch-ic-brake-repair;
}
.bosch-ic-car-auxiliary-heating:before {
    content: $bosch-ic-car-auxiliary-heating;
}
.bosch-ic-wheel-alignment:before {
    content: $bosch-ic-wheel-alignment;
}
.bosch-ic-tire-service:before {
    content: $bosch-ic-tire-service;
}
.bosch-ic-bosch-talks:before {
    content: $bosch-ic-bosch-talks;
}
.bosch-ic-brake-fluid-change:before {
    content: $bosch-ic-brake-fluid-change;
}
.bosch-ic-fault-diagnosis:before {
    content: $bosch-ic-fault-diagnosis;
}
.bosch-ic-oil-bottle:before {
    content: $bosch-ic-oil-bottle;
}
.bosch-ic-oil-change:before {
    content: $bosch-ic-oil-change;
}
.bosch-ic-repair-appointment:before {
    content: $bosch-ic-repair-appointment;
}
.bosch-ic-aa-warehouse-karlsruhe:before {
    content: $bosch-ic-aa-warehouse-karlsruhe;
}
.bosch-ic-wholesaler:before {
    content: $bosch-ic-wholesaler;
}
.bosch-ic-notification-off-light:before {
    content: $bosch-ic-notification-off-light;
}
.bosch-ic-notification-off-bold:before {
    content: $bosch-ic-notification-off-bold;
}
.bosch-ic-laptop-checklist-education:before {
    content: $bosch-ic-laptop-checklist-education;
}
.bosch-ic-truck-small:before {
    content: $bosch-ic-truck-small;
}
.bosch-ic-fork-lift-loading:before {
    content: $bosch-ic-fork-lift-loading;
}
.bosch-ic-truck-large:before {
    content: $bosch-ic-truck-large;
}
.bosch-ic-fork-lift-big1:before {
    content: $bosch-ic-fork-lift-big1;
}
.bosch-ic-delivery-man:before {
    content: $bosch-ic-delivery-man;
}
.bosch-ic-hand-rocking:before {
    content: $bosch-ic-hand-rocking;
}
.bosch-ic-brush-wall:before {
    content: $bosch-ic-brush-wall;
}
.bosch-ic-drywall:before {
    content: $bosch-ic-drywall;
}
.bosch-ic-l-boxx-large-options:before {
    content: $bosch-ic-l-boxx-large-options;
}
.bosch-ic-l-boxx-large-roof:before {
    content: $bosch-ic-l-boxx-large-roof;
}
.bosch-ic-skyscraper-wrench:before {
    content: $bosch-ic-skyscraper-wrench;
}
.bosch-ic-industry-angle-grinder:before {
    content: $bosch-ic-industry-angle-grinder;
}
.bosch-ic-user-angle-grinder:before {
    content: $bosch-ic-user-angle-grinder;
}
.bosch-ic-workbench-saw-blade:before {
    content: $bosch-ic-workbench-saw-blade;
}
.bosch-ic-pipe-water:before {
    content: $bosch-ic-pipe-water;
}
.bosch-ic-crane-roof-truss:before {
    content: $bosch-ic-crane-roof-truss;
}
.bosch-ic-helmet-construction:before {
    content: $bosch-ic-helmet-construction;
}
.bosch-ic-vehicle-diagnostic-kts:before {
    content: $bosch-ic-vehicle-diagnostic-kts;
}
.bosch-ic-skyscraper-emoji-happy:before {
    content: $bosch-ic-skyscraper-emoji-happy;
}
.bosch-ic-vehicle-parts-delivery:before {
    content: $bosch-ic-vehicle-parts-delivery;
}
.bosch-ic-fork-lift-stacking:before {
    content: $bosch-ic-fork-lift-stacking;
}
.bosch-ic-box-refresh:before {
    content: $bosch-ic-box-refresh;
}
.bosch-ic-excavator-arm-stones:before {
    content: $bosch-ic-excavator-arm-stones;
}
.bosch-ic-helmet-construction-plan:before {
    content: $bosch-ic-helmet-construction-plan;
}
.bosch-ic-metal:before {
    content: $bosch-ic-metal;
}
.bosch-ic-document-bi:before {
    content: $bosch-ic-document-bi;
}
.bosch-ic-plasmacluster:before {
    content: $bosch-ic-plasmacluster;
}
.bosch-ic-coanda-effect-arrows:before {
    content: $bosch-ic-coanda-effect-arrows;
}
.bosch-ic-attention-drop:before {
    content: $bosch-ic-attention-drop;
}
.bosch-ic-appliance-temperature-56-degrees:before {
    content: $bosch-ic-appliance-temperature-56-degrees;
}
.bosch-ic-follow-me-remote:before {
    content: $bosch-ic-follow-me-remote;
}
.bosch-ic-first-aid-cross-check:before {
    content: $bosch-ic-first-aid-cross-check;
}
.bosch-ic-drop-check:before {
    content: $bosch-ic-drop-check;
}
.bosch-ic-outdoor-ear-off:before {
    content: $bosch-ic-outdoor-ear-off;
}
.bosch-ic-multi-space-mode:before {
    content: $bosch-ic-multi-space-mode;
}
.bosch-ic-ultra-wide-air-flow:before {
    content: $bosch-ic-ultra-wide-air-flow;
}
.bosch-ic-ionizer:before {
    content: $bosch-ic-ionizer;
}
.bosch-ic-mode-wind-avoid-user:before {
    content: $bosch-ic-mode-wind-avoid-user;
}
.bosch-ic-mode-intelligent-eye:before {
    content: $bosch-ic-mode-intelligent-eye;
}
.bosch-ic-flash-restart:before {
    content: $bosch-ic-flash-restart;
}
.bosch-ic-mode-dry:before {
    content: $bosch-ic-mode-dry;
}
.bosch-ic-mode-spot-air:before {
    content: $bosch-ic-mode-spot-air;
}
.bosch-ic-user-sensor-movment-30-min:before {
    content: $bosch-ic-user-sensor-movment-30-min;
}
.bosch-ic-user-sensor-movment-60-min:before {
    content: $bosch-ic-user-sensor-movment-60-min;
}
.bosch-ic-ambient-cooling-low-ice:before {
    content: $bosch-ic-ambient-cooling-low-ice;
}
.bosch-ic-appliance-thermostat-10-degrees:before {
    content: $bosch-ic-appliance-thermostat-10-degrees;
}
.bosch-ic-appliance-thermostat-8-degrees:before {
    content: $bosch-ic-appliance-thermostat-8-degrees;
}
.bosch-ic-screwdriver-voltage-tester-flash:before {
    content: $bosch-ic-screwdriver-voltage-tester-flash;
}
.bosch-ic-user-mechanic:before {
    content: $bosch-ic-user-mechanic;
}
.bosch-ic-business-training:before {
    content: $bosch-ic-business-training;
}
.bosch-ic-category-management:before {
    content: $bosch-ic-category-management;
}
.bosch-ic-cooperation-partners:before {
    content: $bosch-ic-cooperation-partners;
}
.bosch-ic-e-learning:before {
    content: $bosch-ic-e-learning;
}
.bosch-ic-desktop-online-booking:before {
    content: $bosch-ic-desktop-online-booking;
}
.bosch-ic-workshop-leads:before {
    content: $bosch-ic-workshop-leads;
}
.bosch-ic-fleet-managment:before {
    content: $bosch-ic-fleet-managment;
}
.bosch-ic-anti-corrosion:before {
    content: $bosch-ic-anti-corrosion;
}
.bosch-ic-workshop-car:before {
    content: $bosch-ic-workshop-car;
}
.bosch-ic-workshop-consultant:before {
    content: $bosch-ic-workshop-consultant;
}
.bosch-ic-technical-training-at-vehicle:before {
    content: $bosch-ic-technical-training-at-vehicle;
}
.bosch-ic-cloud-digital-ecosystem:before {
    content: $bosch-ic-cloud-digital-ecosystem;
}
.bosch-ic-smartphone-vehicle-reception-app:before {
    content: $bosch-ic-smartphone-vehicle-reception-app;
}
.bosch-ic-air-gland-nozzle:before {
    content: $bosch-ic-air-gland-nozzle;
}
.bosch-ic-pump-technical-symbol:before {
    content: $bosch-ic-pump-technical-symbol;
}
.bosch-ic-valve-technical-symbol:before {
    content: $bosch-ic-valve-technical-symbol;
}
.bosch-ic-mode-gear-changer:before {
    content: $bosch-ic-mode-gear-changer;
}
.bosch-ic-box-error:before {
    content: $bosch-ic-box-error;
}
.bosch-ic-proving-ground-location-boxberg:before {
    content: $bosch-ic-proving-ground-location-boxberg;
}
.bosch-ic-usb-c:before {
    content: $bosch-ic-usb-c;
}
.bosch-ic-desktop-share-cancel-cross:before {
    content: $bosch-ic-desktop-share-cancel-cross;
}
.bosch-ic-desktop-share-start-arrow:before {
    content: $bosch-ic-desktop-share-start-arrow;
}
.bosch-ic-knife-cutting:before {
    content: $bosch-ic-knife-cutting;
}
.bosch-ic-globe-cross:before {
    content: $bosch-ic-globe-cross;
}
.bosch-ic-box-archive:before {
    content: $bosch-ic-box-archive;
}
.bosch-ic-air-gentle-wind:before {
    content: $bosch-ic-air-gentle-wind;
}
.bosch-ic-air-condition-radar:before {
    content: $bosch-ic-air-condition-radar;
}
.bosch-ic-autobod-box:before {
    content: $bosch-ic-autobod-box;
}
.bosch-ic-autobod-fork-lift:before {
    content: $bosch-ic-autobod-fork-lift;
}
.bosch-ic-autobod-dolly:before {
    content: $bosch-ic-autobod-dolly;
}
.bosch-ic-autobod-tugger:before {
    content: $bosch-ic-autobod-tugger;
}
.bosch-ic-autobod-underrider:before {
    content: $bosch-ic-autobod-underrider;
}
.bosch-ic-dbc1:before {
    content: $bosch-ic-dbc1;
}
.bosch-ic-pcb:before {
    content: $bosch-ic-pcb;
}
.bosch-ic-ltcc:before {
    content: $bosch-ic-ltcc;
}
.bosch-ic-electric-motor-uvw:before {
    content: $bosch-ic-electric-motor-uvw;
}
.bosch-ic-coil-schemes:before {
    content: $bosch-ic-coil-schemes;
}
.bosch-ic-electric-motor-flash:before {
    content: $bosch-ic-electric-motor-flash;
}
.bosch-ic-obd2-port:before {
    content: $bosch-ic-obd2-port;
}
.bosch-ic-adas:before {
    content: $bosch-ic-adas;
}
.bosch-ic-module-safety:before {
    content: $bosch-ic-module-safety;
}
.bosch-ic-acs:before {
    content: $bosch-ic-acs;
}
.bosch-ic-cloud-certificate-checkmark:before {
    content: $bosch-ic-cloud-certificate-checkmark;
}
.bosch-ic-belt-timing-belt:before {
    content: $bosch-ic-belt-timing-belt;
}
.bosch-ic-exhaust-gas-treatment-diesel-engine:before {
    content: $bosch-ic-exhaust-gas-treatment-diesel-engine;
}
.bosch-ic-document-service-schedule:before {
    content: $bosch-ic-document-service-schedule;
}
.bosch-ic-laptop-user-remote:before {
    content: $bosch-ic-laptop-user-remote;
}
.bosch-ic-structure-add:before {
    content: $bosch-ic-structure-add;
}
.bosch-ic-firepanel-wrench:before {
    content: $bosch-ic-firepanel-wrench;
}
.bosch-ic-belt-auxiliary-drive:before {
    content: $bosch-ic-belt-auxiliary-drive;
}
.bosch-ic-search-questionmark:before {
    content: $bosch-ic-search-questionmark;
}
.bosch-ic-inspection-magnifier:before {
    content: $bosch-ic-inspection-magnifier;
}
.bosch-ic-clipboard-health-connected:before {
    content: $bosch-ic-clipboard-health-connected;
}
.bosch-ic-autobod-dolly-arrow-down:before {
    content: $bosch-ic-autobod-dolly-arrow-down;
}
.bosch-ic-autobod-dolly-arrow-up:before {
    content: $bosch-ic-autobod-dolly-arrow-up;
}
.bosch-ic-autobod-dolly-arrow-up-down:before {
    content: $bosch-ic-autobod-dolly-arrow-up-down;
}
.bosch-ic-autobod-underrider-arrow-down:before {
    content: $bosch-ic-autobod-underrider-arrow-down;
}
.bosch-ic-autobod-underrider-arrow-up:before {
    content: $bosch-ic-autobod-underrider-arrow-up;
}
.bosch-ic-autobod-underrider-arrow-up-down:before {
    content: $bosch-ic-autobod-underrider-arrow-up-down;
}
.bosch-ic-keyboard-delete-large:before {
    content: $bosch-ic-keyboard-delete-large;
}
.bosch-ic-keyboard-eject-large:before {
    content: $bosch-ic-keyboard-eject-large;
}
.bosch-ic-keyboard-shift-large:before {
    content: $bosch-ic-keyboard-shift-large;
}
.bosch-ic-diagonals:before {
    content: $bosch-ic-diagonals;
}
.bosch-ic-blade-putty:before {
    content: $bosch-ic-blade-putty;
}
.bosch-ic-bbm-domain-adas:before {
    content: $bosch-ic-bbm-domain-adas;
}
.bosch-ic-bbm-domain-application-software:before {
    content: $bosch-ic-bbm-domain-application-software;
}
.bosch-ic-bbm-domain-compute:before {
    content: $bosch-ic-bbm-domain-compute;
}
.bosch-ic-bbm-domain-energy:before {
    content: $bosch-ic-bbm-domain-energy;
}
.bosch-ic-bbm-domain-motion:before {
    content: $bosch-ic-bbm-domain-motion;
}
.bosch-ic-bbm-domain-service-software:before {
    content: $bosch-ic-bbm-domain-service-software;
}
.bosch-ic-desktop-spark-cursor-wom:before {
    content: $bosch-ic-desktop-spark-cursor-wom;
}
.bosch-ic-cloud-car-wrench-wsms:before {
    content: $bosch-ic-cloud-car-wrench-wsms;
}
.bosch-ic-globe-language:before {
    content: $bosch-ic-globe-language;
}
.bosch-ic-device-smart-service-key:before {
    content: $bosch-ic-device-smart-service-key;
}
.bosch-ic-car-seat-baby:before {
    content: $bosch-ic-car-seat-baby;
}
.bosch-ic-car-seat-baby-connected:before {
    content: $bosch-ic-car-seat-baby-connected;
}
.bosch-ic-car-seat-baby-front:before {
    content: $bosch-ic-car-seat-baby-front;
}
.bosch-ic-car-seat-baby-front-connected:before {
    content: $bosch-ic-car-seat-baby-front-connected;
}
.bosch-ic-square-bend-45-degree-arrow:before {
    content: $bosch-ic-square-bend-45-degree-arrow;
}
.bosch-ic-hacksaw:before {
    content: $bosch-ic-hacksaw;
}
.bosch-ic-object-room:before {
    content: $bosch-ic-object-room;
}
.bosch-ic-object-wall:before {
    content: $bosch-ic-object-wall;
}
.bosch-ic-objects-generic:before {
    content: $bosch-ic-objects-generic;
}
.bosch-ic-tape-lock:before {
    content: $bosch-ic-tape-lock;
}
.bosch-ic-connection-mslot:before {
    content: $bosch-ic-connection-mslot;
}
.bosch-ic-solder-heat:before {
    content: $bosch-ic-solder-heat;
}
.bosch-ic-bracket-holder:before {
    content: $bosch-ic-bracket-holder;
}
.bosch-ic-welding-laser-transmission:before {
    content: $bosch-ic-welding-laser-transmission;
}
.bosch-ic-magnetic-interface:before {
    content: $bosch-ic-magnetic-interface;
}
.bosch-ic-components-lug-down:before {
    content: $bosch-ic-components-lug-down;
}
.bosch-ic-components-lug-up:before {
    content: $bosch-ic-components-lug-up;
}
.bosch-ic-speed-nut:before {
    content: $bosch-ic-speed-nut;
}
.bosch-ic-desktop-tablet-smartphone:before {
    content: $bosch-ic-desktop-tablet-smartphone;
}
.bosch-ic-tablet-orientation-rotate:before {
    content: $bosch-ic-tablet-orientation-rotate;
}
.bosch-ic-touch-gesture-move:before {
    content: $bosch-ic-touch-gesture-move;
}
.bosch-ic-touch-gesture-pinch-in:before {
    content: $bosch-ic-touch-gesture-pinch-in;
}
.bosch-ic-touch-gesture-pinch-out:before {
    content: $bosch-ic-touch-gesture-pinch-out;
}
.bosch-ic-touch-gesture-rotate:before {
    content: $bosch-ic-touch-gesture-rotate;
}
.bosch-ic-touch-gesture-scroll-horizontal:before {
    content: $bosch-ic-touch-gesture-scroll-horizontal;
}
.bosch-ic-touch-gesture-scroll-vertical:before {
    content: $bosch-ic-touch-gesture-scroll-vertical;
}
.bosch-ic-touch-gesture-swipe-down:before {
    content: $bosch-ic-touch-gesture-swipe-down;
}
.bosch-ic-touch-gesture-swipe-left:before {
    content: $bosch-ic-touch-gesture-swipe-left;
}
.bosch-ic-touch-gesture-swipe-right:before {
    content: $bosch-ic-touch-gesture-swipe-right;
}
.bosch-ic-touch-gesture-swipe-up:before {
    content: $bosch-ic-touch-gesture-swipe-up;
}
.bosch-ic-touch-gesture-tap-double:before {
    content: $bosch-ic-touch-gesture-tap-double;
}
.bosch-ic-touch-gesture-tap-hold:before {
    content: $bosch-ic-touch-gesture-tap-hold;
}
.bosch-ic-touch-gesture-tap-single:before {
    content: $bosch-ic-touch-gesture-tap-single;
}
.bosch-ic-reset-lock:before {
    content: $bosch-ic-reset-lock;
}
.bosch-ic-truck-large-arrow-turn-left:before {
    content: $bosch-ic-truck-large-arrow-turn-left;
}
.bosch-ic-truck-large-distance-arrows:before {
    content: $bosch-ic-truck-large-distance-arrows;
}
.bosch-ic-petrol-station-diesel:before {
    content: $bosch-ic-petrol-station-diesel;
}
.bosch-ic-lng:before {
    content: $bosch-ic-lng;
}
.bosch-ic-adr:before {
    content: $bosch-ic-adr;
}
.bosch-ic-rhombus-turn-arrow-right:before {
    content: $bosch-ic-rhombus-turn-arrow-right;
}
.bosch-ic-document-fwr:before {
    content: $bosch-ic-document-fwr;
}
.bosch-ic-document-view:before {
    content: $bosch-ic-document-view;
}
.bosch-ic-refresh-lock:before {
    content: $bosch-ic-refresh-lock;
}
.bosch-ic-e-charging-station-ice:before {
    content: $bosch-ic-e-charging-station-ice;
}
.bosch-ic-language-en:before {
    content: $bosch-ic-language-en;
}
.bosch-ic-temperature-checkmark:before {
    content: $bosch-ic-temperature-checkmark;
}
.bosch-ic-list-view-add:before {
    content: $bosch-ic-list-view-add;
}
.bosch-ic-opening-generic:before {
    content: $bosch-ic-opening-generic;
}
.bosch-ic-passage:before {
    content: $bosch-ic-passage;
}
.bosch-ic-molecule-propan:before {
    content: $bosch-ic-molecule-propan;
}
.bosch-ic-locator-multiple:before {
    content: $bosch-ic-locator-multiple;
}
.bosch-ic-bucket-paint:before {
    content: $bosch-ic-bucket-paint;
}
.bosch-ic-rat-mouse:before {
    content: $bosch-ic-rat-mouse;
}
.bosch-ic-power-point:before {
    content: $bosch-ic-power-point;
}
.bosch-ic-power-point-slide-set:before {
    content: $bosch-ic-power-point-slide-set;
}
.bosch-ic-binoculars:before {
    content: $bosch-ic-binoculars;
}
.bosch-ic-tube-hp-tim:before {
    content: $bosch-ic-tube-hp-tim;
}
.bosch-ic-system-in-package:before {
    content: $bosch-ic-system-in-package;
}
.bosch-ic-nupsis:before {
    content: $bosch-ic-nupsis;
}
.bosch-ic-connection-rf-sideview:before {
    content: $bosch-ic-connection-rf-sideview;
}
.bosch-ic-connection-mslot-sideview:before {
    content: $bosch-ic-connection-mslot-sideview;
}
.bosch-ic-power-point-slide:before {
    content: $bosch-ic-power-point-slide;
}
.bosch-ic-lung-simple:before {
    content: $bosch-ic-lung-simple;
}
.bosch-ic-battery-car-48v:before {
    content: $bosch-ic-battery-car-48v;
}
.bosch-ic-bbm-domain-infotainment:before {
    content: $bosch-ic-bbm-domain-infotainment;
}
.bosch-ic-bbm-domain-body-and-comfort:before {
    content: $bosch-ic-bbm-domain-body-and-comfort;
}
.bosch-ic-reset-gear:before {
    content: $bosch-ic-reset-gear;
}
.bosch-ic-drop-leaf:before {
    content: $bosch-ic-drop-leaf;
}
.bosch-ic-petrol-station-leaf:before {
    content: $bosch-ic-petrol-station-leaf;
}
.bosch-ic-steps-co2:before {
    content: $bosch-ic-steps-co2;
}
.bosch-ic-car-map-locator-cloud:before {
    content: $bosch-ic-car-map-locator-cloud;
}
.bosch-ic-battery-tool:before {
    content: $bosch-ic-battery-tool;
}
.bosch-ic-bluetooth-search:before {
    content: $bosch-ic-bluetooth-search;
}
.bosch-ic-drill-driver-cordless-check:before {
    content: $bosch-ic-drill-driver-cordless-check;
}
.bosch-ic-drill-driver-cordless-reset:before {
    content: $bosch-ic-drill-driver-cordless-reset;
}
.bosch-ic-scan-drill-driver-cordless:before {
    content: $bosch-ic-scan-drill-driver-cordless;
}
.bosch-ic-scan-suitcase:before {
    content: $bosch-ic-scan-suitcase;
}
.bosch-ic-id-label-qr-code:before {
    content: $bosch-ic-id-label-qr-code;
}
.bosch-ic-id-label-check:before {
    content: $bosch-ic-id-label-check;
}
.bosch-ic-charger-plug-flash:before {
    content: $bosch-ic-charger-plug-flash;
}
.bosch-ic-storage-tank-charging-system:before {
    content: $bosch-ic-storage-tank-charging-system;
}
.bosch-ic-storage-tank-monovalent:before {
    content: $bosch-ic-storage-tank-monovalent;
}
.bosch-ic-storage-tank-series-connection:before {
    content: $bosch-ic-storage-tank-series-connection;
}
.bosch-ic-buffer-tank-fresh-water-station:before {
    content: $bosch-ic-buffer-tank-fresh-water-station;
}
.bosch-ic-storage-tank-bivalent:before {
    content: $bosch-ic-storage-tank-bivalent;
}
.bosch-ic-search-graph:before {
    content: $bosch-ic-search-graph;
}
.bosch-ic-desktop-consumer-data:before {
    content: $bosch-ic-desktop-consumer-data;
}
.bosch-ic-door-smartphone:before {
    content: $bosch-ic-door-smartphone;
}
.bosch-ic-desktop-dashboard-rps:before {
    content: $bosch-ic-desktop-dashboard-rps;
}
.bosch-ic-camera-fingerprint-check:before {
    content: $bosch-ic-camera-fingerprint-check;
}
.bosch-ic-desktop-chart-line-arrow:before {
    content: $bosch-ic-desktop-chart-line-arrow;
}
.bosch-ic-chart-tcfc:before {
    content: $bosch-ic-chart-tcfc;
}
.bosch-ic-gas-valve-off:before {
    content: $bosch-ic-gas-valve-off;
}
.bosch-ic-gas-valve-on:before {
    content: $bosch-ic-gas-valve-on;
}
.bosch-ic-light-on:before {
    content: $bosch-ic-light-on;
}
.bosch-ic-light-off:before {
    content: $bosch-ic-light-off;
}
.bosch-ic-light-medium:before {
    content: $bosch-ic-light-medium;
}
.bosch-ic-throttle-decelerate:before {
    content: $bosch-ic-throttle-decelerate;
}
.bosch-ic-throttle-accelerate:before {
    content: $bosch-ic-throttle-accelerate;
}
.bosch-ic-speedometer-arrow:before {
    content: $bosch-ic-speedometer-arrow;
}
.bosch-ic-scooter-moving-user-disabled-bold:before {
    content: $bosch-ic-scooter-moving-user-disabled-bold;
}
.bosch-ic-scooter-moving-user-disabled-light:before {
    content: $bosch-ic-scooter-moving-user-disabled-light;
}
.bosch-ic-motorcycle-sport-rear-wheel-arrow:before {
    content: $bosch-ic-motorcycle-sport-rear-wheel-arrow;
}
.bosch-ic-motorcycle-sport-hill-brake-disk:before {
    content: $bosch-ic-motorcycle-sport-hill-brake-disk;
}
.bosch-ic-mountains-abs:before {
    content: $bosch-ic-mountains-abs;
}
.bosch-ic-brake-lever-clutch-level-hand:before {
    content: $bosch-ic-brake-lever-clutch-level-hand;
}
.bosch-ic-scooter-moving-flash:before {
    content: $bosch-ic-scooter-moving-flash;
}
.bosch-ic-throttle-hand:before {
    content: $bosch-ic-throttle-hand;
}
.bosch-ic-motorcycle-forward-collision-warning:before {
    content: $bosch-ic-motorcycle-forward-collision-warning;
}
.bosch-ic-motorcycle-electric-traction-control:before {
    content: $bosch-ic-motorcycle-electric-traction-control;
}
.bosch-ic-motorcycle-cornering-drag-torque-control:before {
    content: $bosch-ic-motorcycle-cornering-drag-torque-control;
}
.bosch-ic-motorcycle-blind-spot:before {
    content: $bosch-ic-motorcycle-blind-spot;
}
.bosch-ic-motorcycle-cornering-traction-control:before {
    content: $bosch-ic-motorcycle-cornering-traction-control;
}
.bosch-ic-motorcycle-sport-ecbs-brake-distribution:before {
    content: $bosch-ic-motorcycle-sport-ecbs-brake-distribution;
}
.bosch-ic-lock-torque-closed:before {
    content: $bosch-ic-lock-torque-closed;
}
.bosch-ic-smartphone-pin-off:before {
    content: $bosch-ic-smartphone-pin-off;
}
.bosch-ic-hand-vibration:before {
    content: $bosch-ic-hand-vibration;
}
.bosch-ic-feather:before {
    content: $bosch-ic-feather;
}
.bosch-ic-smartphone-pin-check:before {
    content: $bosch-ic-smartphone-pin-check;
}
.bosch-ic-bicycle-e-2-displays:before {
    content: $bosch-ic-bicycle-e-2-displays;
}
.bosch-ic-bicycle-e-4a-charger:before {
    content: $bosch-ic-bicycle-e-4a-charger;
}
.bosch-ic-bicycle-e-600-watt:before {
    content: $bosch-ic-bicycle-e-600-watt;
}
.bosch-ic-bicycle-e-alarm:before {
    content: $bosch-ic-bicycle-e-alarm;
}
.bosch-ic-bicycle-e-auto-downshift:before {
    content: $bosch-ic-bicycle-e-auto-downshift;
}
.bosch-ic-bicycle-e-auto-mode:before {
    content: $bosch-ic-bicycle-e-auto-mode;
}
.bosch-ic-bicycle-e-backpedal:before {
    content: $bosch-ic-bicycle-e-backpedal;
}
.bosch-ic-bicycle-e-battery:before {
    content: $bosch-ic-bicycle-e-battery;
}
.bosch-ic-bicycle-e-cargo-mode:before {
    content: $bosch-ic-bicycle-e-cargo-mode;
}
.bosch-ic-bicycle-e-cruise-25kmh:before {
    content: $bosch-ic-bicycle-e-cruise-25kmh;
}
.bosch-ic-bicycle-e-display:before {
    content: $bosch-ic-bicycle-e-display;
}
.bosch-ic-bicycle-e-displaysize:before {
    content: $bosch-ic-bicycle-e-displaysize;
}
.bosch-ic-bicycle-e-eco-mode:before {
    content: $bosch-ic-bicycle-e-eco-mode;
}
.bosch-ic-bicycle-e-emtb-mode:before {
    content: $bosch-ic-bicycle-e-emtb-mode;
}
.bosch-ic-bicycle-e-extended-boost:before {
    content: $bosch-ic-bicycle-e-extended-boost;
}
.bosch-ic-bicycle-e-gearshift:before {
    content: $bosch-ic-bicycle-e-gearshift;
}
.bosch-ic-bicycle-e-kiox300:before {
    content: $bosch-ic-bicycle-e-kiox300;
}
.bosch-ic-bicycle-e-wireless-speed-sensor:before {
    content: $bosch-ic-bicycle-e-wireless-speed-sensor;
}
.bosch-ic-bicycle-e-weight-61lbs:before {
    content: $bosch-ic-bicycle-e-weight-61lbs;
}
.bosch-ic-bicycle-e-weight-45lbs:before {
    content: $bosch-ic-bicycle-e-weight-45lbs;
}
.bosch-ic-bicycle-e-weight-2kg:before {
    content: $bosch-ic-bicycle-e-weight-2kg;
}
.bosch-ic-bicycle-e-weight-275kg:before {
    content: $bosch-ic-bicycle-e-weight-275kg;
}
.bosch-ic-bicycle-e-weight-29kg:before {
    content: $bosch-ic-bicycle-e-weight-29kg;
}
.bosch-ic-bicycle-e-turbo-mode:before {
    content: $bosch-ic-bicycle-e-turbo-mode;
}
.bosch-ic-bicycle-e-tour-mode:before {
    content: $bosch-ic-bicycle-e-tour-mode;
}
.bosch-ic-bicycle-e-torque-85nm:before {
    content: $bosch-ic-bicycle-e-torque-85nm;
}
.bosch-ic-bicycle-e-torque-75nm:before {
    content: $bosch-ic-bicycle-e-torque-75nm;
}
.bosch-ic-bicycle-e-torque-65nm:before {
    content: $bosch-ic-bicycle-e-torque-65nm;
}
.bosch-ic-bicycle-e-torque-55nm:before {
    content: $bosch-ic-bicycle-e-torque-55nm;
}
.bosch-ic-bicycle-e-torque-50nm:before {
    content: $bosch-ic-bicycle-e-torque-50nm;
}
.bosch-ic-bicycle-e-torque-40nm:before {
    content: $bosch-ic-bicycle-e-torque-40nm;
}
.bosch-ic-bicycle-e-support-340-percent:before {
    content: $bosch-ic-bicycle-e-support-340-percent;
}
.bosch-ic-bicycle-e-sprint-mode:before {
    content: $bosch-ic-bicycle-e-sprint-mode;
}
.bosch-ic-bicycle-e-sport-mode:before {
    content: $bosch-ic-bicycle-e-sport-mode;
}
.bosch-ic-bicycle-e-speed-45kmh:before {
    content: $bosch-ic-bicycle-e-speed-45kmh;
}
.bosch-ic-bicycle-e-speed-28mph:before {
    content: $bosch-ic-bicycle-e-speed-28mph;
}
.bosch-ic-bicycle-e-smartphone-key:before {
    content: $bosch-ic-bicycle-e-smartphone-key;
}
.bosch-ic-bicycle-e-smartphone-display:before {
    content: $bosch-ic-bicycle-e-smartphone-display;
}
.bosch-ic-bicycle-e-sharing-riding-data:before {
    content: $bosch-ic-bicycle-e-sharing-riding-data;
}
.bosch-ic-bicycle-e-safety-handling:before {
    content: $bosch-ic-bicycle-e-safety-handling;
}
.bosch-ic-bicycle-e-protect:before {
    content: $bosch-ic-bicycle-e-protect;
}
.bosch-ic-bicycle-e-power-to-weight-ratio:before {
    content: $bosch-ic-bicycle-e-power-to-weight-ratio;
}
.bosch-ic-bicycle-e-navigation-personal-route-planning:before {
    content: $bosch-ic-bicycle-e-navigation-personal-route-planning;
}
.bosch-ic-bicycle-e-navigation-app-route-planning:before {
    content: $bosch-ic-bicycle-e-navigation-app-route-planning;
}
.bosch-ic-bicycle-e-emtb:before {
    content: $bosch-ic-bicycle-e-emtb;
}
.bosch-ic-bicycle-e-easy-handling:before {
    content: $bosch-ic-bicycle-e-easy-handling;
}
.bosch-ic-bicycle-e-ambitious-assistants:before {
    content: $bosch-ic-bicycle-e-ambitious-assistants;
}
.bosch-ic-bicycle-e-connectmodule:before {
    content: $bosch-ic-bicycle-e-connectmodule;
}
.bosch-ic-bicycle-e-display-key:before {
    content: $bosch-ic-bicycle-e-display-key;
}
.bosch-ic-bicycle-e-abs-control-unit:before {
    content: $bosch-ic-bicycle-e-abs-control-unit;
}
.bosch-ic-temperature-stable:before {
    content: $bosch-ic-temperature-stable;
}
.bosch-ic-smartphone-graph-analysis:before {
    content: $bosch-ic-smartphone-graph-analysis;
}
.bosch-ic-bicycle-e-tour-plus-mode:before {
    content: $bosch-ic-bicycle-e-tour-plus-mode;
}
.bosch-ic-handover-leaf-industry:before {
    content: $bosch-ic-handover-leaf-industry;
}
.bosch-ic-map5000-2:before {
    content: $bosch-ic-map5000-2;
}
.bosch-ic-bicycle-e-design:before {
    content: $bosch-ic-bicycle-e-design;
}
.bosch-ic-bicycle-e-sharing-personal-data:before {
    content: $bosch-ic-bicycle-e-sharing-personal-data;
}
.bosch-ic-text-bar-arrow-down:before {
    content: $bosch-ic-text-bar-arrow-down;
}
.bosch-ic-text-area:before {
    content: $bosch-ic-text-area;
}
.bosch-ic-battery-4-check:before {
    content: $bosch-ic-battery-4-check;
}
.bosch-ic-battery-2-check:before {
    content: $bosch-ic-battery-2-check;
}
.bosch-ic-hourglass-expiry-date-cartridge:before {
    content: $bosch-ic-hourglass-expiry-date-cartridge;
}
.bosch-ic-search-user:before {
    content: $bosch-ic-search-user;
}
.bosch-ic-calendar-reservation:before {
    content: $bosch-ic-calendar-reservation;
}
.bosch-ic-heart-flash:before {
    content: $bosch-ic-heart-flash;
}
.bosch-ic-livechat-questionmark:before {
    content: $bosch-ic-livechat-questionmark;
}
.bosch-ic-building-gear-warning:before {
    content: $bosch-ic-building-gear-warning;
}
.bosch-ic-home-roof-flat-arrow:before {
    content: $bosch-ic-home-roof-flat-arrow;
}
.bosch-ic-home-roof-flat:before {
    content: $bosch-ic-home-roof-flat;
}
.bosch-ic-fire-leaf-light-half:before {
    content: $bosch-ic-fire-leaf-light-half;
}
.bosch-ic-back-menu-disabled-off-light:before {
    content: $bosch-ic-back-menu-disabled-off-light;
}
.bosch-ic-book-add:before {
    content: $bosch-ic-book-add;
}
.bosch-ic-box-archive-add:before {
    content: $bosch-ic-box-archive-add;
}
.bosch-ic-camera-add:before {
    content: $bosch-ic-camera-add;
}
.bosch-ic-clock-add:before {
    content: $bosch-ic-clock-add;
}
.bosch-ic-crown:before {
    content: $bosch-ic-crown;
}
.bosch-ic-three-dots-circle-add:before {
    content: $bosch-ic-three-dots-circle-add;
}
.bosch-ic-three-dots-circle:before {
    content: $bosch-ic-three-dots-circle;
}
.bosch-ic-text-collection:before {
    content: $bosch-ic-text-collection;
}
.bosch-ic-text-add:before {
    content: $bosch-ic-text-add;
}
.bosch-ic-receipt:before {
    content: $bosch-ic-receipt;
}
.bosch-ic-four-dots-circle:before {
    content: $bosch-ic-four-dots-circle;
}
.bosch-ic-format-paragraph:before {
    content: $bosch-ic-format-paragraph;
}
.bosch-ic-user-worker:before {
    content: $bosch-ic-user-worker;
}
.bosch-ic-heatpump-add:before {
    content: $bosch-ic-heatpump-add;
}
.bosch-ic-heatpump-boiler-add:before {
    content: $bosch-ic-heatpump-boiler-add;
}
.bosch-ic-car-steering-wheel:before {
    content: $bosch-ic-car-steering-wheel;
}
.bosch-ic-commercial-vehicle-steering-wheel:before {
    content: $bosch-ic-commercial-vehicle-steering-wheel;
}
.bosch-ic-brake-disk-steering-wheel:before {
    content: $bosch-ic-brake-disk-steering-wheel;
}
.bosch-ic-speedlimit-60:before {
    content: $bosch-ic-speedlimit-60;
}
.bosch-ic-sign-warning-gradient-10-percent:before {
    content: $bosch-ic-sign-warning-gradient-10-percent;
}
.bosch-ic-sign-curve-s:before {
    content: $bosch-ic-sign-curve-s;
}
.bosch-ic-sign-cars-traffic-jam:before {
    content: $bosch-ic-sign-cars-traffic-jam;
}
.bosch-ic-bicycle-e-powertube:before {
    content: $bosch-ic-bicycle-e-powertube;
}
.bosch-ic-bicycle-e-race-mode:before {
    content: $bosch-ic-bicycle-e-race-mode;
}
.bosch-ic-bicycle-e-off-mode:before {
    content: $bosch-ic-bicycle-e-off-mode;
}
.bosch-ic-bicycle-e-streetsign:before {
    content: $bosch-ic-bicycle-e-streetsign;
}
.bosch-ic-bicycle-e-limit-mode:before {
    content: $bosch-ic-bicycle-e-limit-mode;
}
.bosch-ic-fire-pre-alarm:before {
    content: $bosch-ic-fire-pre-alarm;
}
.bosch-ic-book-text-page:before {
    content: $bosch-ic-book-text-page;
}
.bosch-ic-battery-car-sli:before {
    content: $bosch-ic-battery-car-sli;
}
.bosch-ic-battery-car-gel:before {
    content: $bosch-ic-battery-car-gel;
}
.bosch-ic-battery-car-open:before {
    content: $bosch-ic-battery-car-open;
}
.bosch-ic-battery-car-li-ion-2:before {
    content: $bosch-ic-battery-car-li-ion-2;
}
.bosch-ic-wooden-pallet-box:before {
    content: $bosch-ic-wooden-pallet-box;
}
.bosch-ic-wooden-pallet-staked:before {
    content: $bosch-ic-wooden-pallet-staked;
}
.bosch-ic-wooden-pallet:before {
    content: $bosch-ic-wooden-pallet;
}
.bosch-ic-wooden-pallet-packed-full:before {
    content: $bosch-ic-wooden-pallet-packed-full;
}
.bosch-ic-bicycle-e-eco-plus-mode:before {
    content: $bosch-ic-bicycle-e-eco-plus-mode;
}
.bosch-ic-bicycle-e-support-400-percent:before {
    content: $bosch-ic-bicycle-e-support-400-percent;
}
.bosch-ic-bicycle-e-weight-1-6kg:before {
    content: $bosch-ic-bicycle-e-weight-1-6kg;
}
.bosch-ic-bicycle-e-weight-3-65kg:before {
    content: $bosch-ic-bicycle-e-weight-3-65kg;
}
.bosch-ic-bicycle-e-weight-35lbs:before {
    content: $bosch-ic-bicycle-e-weight-35lbs;
}
.bosch-ic-bicycle-e-weight-3kg:before {
    content: $bosch-ic-bicycle-e-weight-3kg;
}
.bosch-ic-bicycle-e-weight-4-3kg:before {
    content: $bosch-ic-bicycle-e-weight-4-3kg;
}
.bosch-ic-bicycle-e-weight-66lbs:before {
    content: $bosch-ic-bicycle-e-weight-66lbs;
}
.bosch-ic-bicycle-e-weight-8lbs:before {
    content: $bosch-ic-bicycle-e-weight-8lbs;
}
.bosch-ic-bicycle-e-weight-95lbs:before {
    content: $bosch-ic-bicycle-e-weight-95lbs;
}
.bosch-ic-compass-needle:before {
    content: $bosch-ic-compass-needle;
}
.bosch-ic-map-pitch:before {
    content: $bosch-ic-map-pitch;
}
.bosch-ic-podium-winner-trophy:before {
    content: $bosch-ic-podium-winner-trophy;
}
.bosch-ic-chart-competition-user:before {
    content: $bosch-ic-chart-competition-user;
}
.bosch-ic-clipboard-add:before {
    content: $bosch-ic-clipboard-add;
}
.bosch-ic-user-workpackage:before {
    content: $bosch-ic-user-workpackage;
}
.bosch-ic-arrow-ignore-plan-data:before {
    content: $bosch-ic-arrow-ignore-plan-data;
}
.bosch-ic-number-1-regular-square:before {
    content: $bosch-ic-number-1-regular-square;
}
.bosch-ic-number-2-regular-square:before {
    content: $bosch-ic-number-2-regular-square;
}
.bosch-ic-number-0-regular-circle-black:before {
    content: $bosch-ic-number-0-regular-circle-black;
}
.bosch-ic-manage-project-overview:before {
    content: $bosch-ic-manage-project-overview;
}
.bosch-ic-manage-project:before {
    content: $bosch-ic-manage-project;
}
.bosch-ic-milestone:before {
    content: $bosch-ic-milestone;
}
.bosch-ic-ms-project-plan:before {
    content: $bosch-ic-ms-project-plan;
}
.bosch-ic-plan-outline-checkout:before {
    content: $bosch-ic-plan-outline-checkout;
}
.bosch-ic-plan-outline:before {
    content: $bosch-ic-plan-outline;
}
.bosch-ic-reporting:before {
    content: $bosch-ic-reporting;
}
.bosch-ic-user-fields-tracking-key-figure:before {
    content: $bosch-ic-user-fields-tracking-key-figure;
}
.bosch-ic-ms-project-plan-checkout:before {
    content: $bosch-ic-ms-project-plan-checkout;
}
.bosch-ic-user-workpackage-group:before {
    content: $bosch-ic-user-workpackage-group;
}
.bosch-ic-car-dollar:before {
    content: $bosch-ic-car-dollar;
}
.bosch-ic-badge-3-year-warranty-superhappy:before {
    content: $bosch-ic-badge-3-year-warranty-superhappy;
}
.bosch-ic-badge-5-year-warranty-superhappy:before {
    content: $bosch-ic-badge-5-year-warranty-superhappy;
}
.bosch-ic-key:before {
    content: $bosch-ic-key;
}
.bosch-ic-data-fabric:before {
    content: $bosch-ic-data-fabric;
}
.bosch-ic-ontologies:before {
    content: $bosch-ic-ontologies;
}
.bosch-ic-battery-car-3-badge-flash:before {
    content: $bosch-ic-battery-car-3-badge-flash;
}
.bosch-ic-diode:before {
    content: $bosch-ic-diode;
}
.bosch-ic-parachute-user:before {
    content: $bosch-ic-parachute-user;
}
.bosch-ic-emergency-exit-user-run:before {
    content: $bosch-ic-emergency-exit-user-run;
}
.bosch-ic-user-standing-laptop:before {
    content: $bosch-ic-user-standing-laptop;
}
.bosch-ic-user-sitting-laptop:before {
    content: $bosch-ic-user-sitting-laptop;
}
.bosch-ic-speachbubble-user:before {
    content: $bosch-ic-speachbubble-user;
}
.bosch-ic-smartphone-ar-diorama:before {
    content: $bosch-ic-smartphone-ar-diorama;
}
.bosch-ic-bicycle-e-hillstart:before {
    content: $bosch-ic-bicycle-e-hillstart;
}
.bosch-ic-heatpump-horizontal:before {
    content: $bosch-ic-heatpump-horizontal;
}
.bosch-ic-heatpump-vertical:before {
    content: $bosch-ic-heatpump-vertical;
}
.bosch-ic-skyscraper-camera:before {
    content: $bosch-ic-skyscraper-camera;
}
.bosch-ic-skyscraper-flame:before {
    content: $bosch-ic-skyscraper-flame;
}
.bosch-ic-fire-detector-check:before {
    content: $bosch-ic-fire-detector-check;
}
.bosch-ic-fire-detector-add:before {
    content: $bosch-ic-fire-detector-add;
}
.bosch-ic-remote-desktop-fire-panel:before {
    content: $bosch-ic-remote-desktop-fire-panel;
}
.bosch-ic-gear-check:before {
    content: $bosch-ic-gear-check;
}
.bosch-ic-remote-desktop-fire-panel-add:before {
    content: $bosch-ic-remote-desktop-fire-panel-add;
}
.bosch-ic-skyscraper-building-gpt:before {
    content: $bosch-ic-skyscraper-building-gpt;
}
.bosch-ic-checkmark-frame-info:before {
    content: $bosch-ic-checkmark-frame-info;
}
.bosch-ic-plug-socket:before {
    content: $bosch-ic-plug-socket;
}
.bosch-ic-chair-user-sitting-check:before {
    content: $bosch-ic-chair-user-sitting-check;
}
.bosch-ic-tent-event:before {
    content: $bosch-ic-tent-event;
}
.bosch-ic-hall-event-user:before {
    content: $bosch-ic-hall-event-user;
}
.bosch-ic-ear-noises:before {
    content: $bosch-ic-ear-noises;
}
.bosch-ic-warning-brake-judder:before {
    content: $bosch-ic-warning-brake-judder;
}
.bosch-ic-flower-bloom:before {
    content: $bosch-ic-flower-bloom;
}
.bosch-ic-chair-user-running:before {
    content: $bosch-ic-chair-user-running;
}
.bosch-ic-fuse:before {
    content: $bosch-ic-fuse;
}
.bosch-ic-vc0:before {
    content: $bosch-ic-vc0;
}
.bosch-ic-chart-line-coin-euro:before {
    content: $bosch-ic-chart-line-coin-euro;
}
.bosch-ic-chart-line-coin-dollar:before {
    content: $bosch-ic-chart-line-coin-dollar;
}
.bosch-ic-clock-warning:before {
    content: $bosch-ic-clock-warning;
}
.bosch-ic-spark-plug-change:before {
    content: $bosch-ic-spark-plug-change;
}
.bosch-ic-cup-filled:before {
    content: $bosch-ic-cup-filled;
}
.bosch-ic-volume-noise-increase-arrow-up:before {
    content: $bosch-ic-volume-noise-increase-arrow-up;
}
.bosch-ic-volume-reduction-arrow-down:before {
    content: $bosch-ic-volume-reduction-arrow-down;
}
.bosch-ic-keyboard-caps-lock-large:before {
    content: $bosch-ic-keyboard-caps-lock-large;
}
.bosch-ic-low-loss-header:before {
    content: $bosch-ic-low-loss-header;
}
.bosch-ic-bicycle-e-mounting-position-purion-400:before {
    content: $bosch-ic-bicycle-e-mounting-position-purion-400;
}
.bosch-ic-skyscraper-digital-twin:before {
    content: $bosch-ic-skyscraper-digital-twin;
}
.bosch-ic-robot-onboarding-copilot-body:before {
    content: $bosch-ic-robot-onboarding-copilot-body;
}
.bosch-ic-robot-onboarding-copilot:before {
    content: $bosch-ic-robot-onboarding-copilot;
}
.bosch-ic-plug-ethernet:before {
    content: $bosch-ic-plug-ethernet;
}
.bosch-ic-battery-car-internal-resistance:before {
    content: $bosch-ic-battery-car-internal-resistance;
}
.bosch-ic-multimeter:before {
    content: $bosch-ic-multimeter;
}
.bosch-ic-spark-plug-recycle:before {
    content: $bosch-ic-spark-plug-recycle;
}
.bosch-ic-battery-car-truck:before {
    content: $bosch-ic-battery-car-truck;
}
.bosch-ic-battery-car-new:before {
    content: $bosch-ic-battery-car-new;
}
.bosch-ic-voltage-current-va:before {
    content: $bosch-ic-voltage-current-va;
}
.bosch-ic-rasp:before {
    content: $bosch-ic-rasp;
}
.bosch-ic-car-top-sensor-inside:before {
    content: $bosch-ic-car-top-sensor-inside;
}
.bosch-ic-car-sensor-inside:before {
    content: $bosch-ic-car-sensor-inside;
}
.bosch-ic-car-rollover:before {
    content: $bosch-ic-car-rollover;
}
.bosch-ic-engine-misfires:before {
    content: $bosch-ic-engine-misfires;
}
.bosch-ic-car-starting-difficulties:before {
    content: $bosch-ic-car-starting-difficulties;
}
.bosch-ic-document-arrow-side-down:before {
    content: $bosch-ic-document-arrow-side-down;
}
.bosch-ic-wiper-flatblade-reuse:before {
    content: $bosch-ic-wiper-flatblade-reuse;
}
