/*!
  *  Copyright (C) 2017 Robert Bosch GmbH Copyright (C) 2017 Robert Bosch Manufacturing Solutions GmbH, Germany. All rights reserved.
 */

body {
  margin: 0;
  display: flex;
  flex-direction: column;
}

main,
* {
  font-family: $font-family-base;
}

::selection,
::-moz-selection {
  background-color: $boschBlue50;
}

a::-moz-selection {
  color: $boschWhite;
}

.lead {
  margin-bottom: $line-height-computed;
  font-size: floor(($font-size-base * 1.15));
  font-weight: 300;
  line-height: 1.4;

  @media (min-width: $screen-sm-min) {
    font-size: ($font-size-base * 1.5);
  }
}
