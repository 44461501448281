/*!
  *  Copyright (C) 2017 Robert Bosch GmbH Copyright (C) 2017 Robert Bosch Manufacturing Solutions GmbH, Germany. All rights reserved.
 */

//------------------------------------------------------------------------------------------------------ BUTTON
@mixin make-dropdown {
  @include make-legacy-dropdown;
  position: relative;
  display: inline-block;
}

@mixin make-dropdown-label {
}

@mixin make-dropdown-disabled {
  @include make-legacy-dropdown-disabled-state;
  @include make-form-control-disabled-state;
}

@mixin make-dropdown-dark {
  @include make-form-input-dark;
}

//------------------------------------------------------------------------------------------------------ MENU ELEMENT
@mixin make-dropdown-element {
  @include make-form-input-padding-without-label;
  cursor: pointer;
  background-color: $dropdown-bg-color;
  opacity: 0;

  * {
    color: $dropdown-menu-color;
    cursor: pointer;
    white-space: nowrap;
  }

  &:hover,
  &:hover.selected {
    background: $dropdown-menu-hover-bg-color;

    * {
      color: $dropdown-menu-hover-color;
    }
  }

  &:active,
  &.active {
    background: $dropdown-menu-active-bg-color;

    * {
      color: $dropdown-menu-active-color;
    }
  }

  &.selected {
    background: $dropdown-menu-selected-bg-color;

    * {
      color: $dropdown-menu-selected-color;
    }
  }

  &.disabled,
  &:hover.disabled {
    background: $dropdown-bg-color;

    * {
      color: $dropdown-menu-disabled-color;
    }
  }
}

@mixin make-dropdown-element-visible {
  opacity: 1;
}

//------------------------------------------------------------------------------------------------------ MENU
@mixin make-dropdown-menu {
  width: calc(100% - 2px);
  overflow: hidden;
  z-index: $dropdown-z-index;
  background: $dropdown-bg-color;

  position: absolute;
  left: 0;
  top: calc(100% + 1px);
  margin: 0;
  padding: 0;

  @include make-border(0px 1px 1px 1px, $boschGray80);

  transform: scaleY(0);
  transform-origin: top;

  > * {
    @include make-dropdown-element;
  }
}

@mixin make-dropdown-menu-dark {
  background: $dropdown-dark-bg-color;
}

@mixin make-dropdown-menu-visible {
  transform: scaleY(1);
  transform-origin: top;

  > * {
    @include make-dropdown-element-visible;
  }
}

//------------------------------------------------------------------------------------------------------ LEGACY
@mixin make-legacy-dropdown {
  @include make-form-input;
  @include make-legacy-dropdown-states;

  background-image: url("data:image/svg+xml;charset=UTF-8,<svg xmlns='http://www.w3.org/2000/svg' width='192' height='192' viewBox='0 0 192 192'><polygon points='29.2 72.6 33.2 67 96 115.8 158.8 67 162.8 72.6 96 125 29.2 72.6'/></svg>");
  background-position: calc(100% - #{$grid-size * 1.5}) $grid-size * 1.5;
  background-repeat: no-repeat;
  background-size: $grid-size * 3;
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

@mixin make-legacy-dropdown-focus-state {
  background-image: url("data:image/svg+xml;charset=UTF-8,<svg xmlns='http://www.w3.org/2000/svg' width='192' height='192' transform='rotate(180)' viewBox='0 0 192 192'><polygon points='29.2 72.6 33.2 67 96 115.8 158.8 67 162.8 72.6 96 125 29.2 72.6'/></svg>");
}

@mixin make-legacy-dropdown-disabled-state {
  background-image: url("data:image/svg+xml;charset=UTF-8,<svg xmlns='http://www.w3.org/2000/svg' width='192' height='192' viewBox='0 0 192 192'><polygon style='stroke:black;stroke-width:1;fill:white' points='29.2 72.6 33.2 67 96 115.8 158.8 67 162.8 72.6 96 125 29.2 72.6'/></svg>");
  cursor: not-allowed;
}

@mixin make-legacy-dropdown-states {
  &:disabled {
    @include make-legacy-dropdown-disabled-state;
  }

  &::-ms-expand {
    /* for IE 11 */
    display: none;
  }

  &:focus {
    @include make-legacy-dropdown-focus-state;
  }
}

@mixin make-legacy-dropdown-dark {
  @include make-form-input-dark;
}
