/*!
  *  Copyright (C) 2017 Robert Bosch GmbH Copyright (C) 2017 Robert Bosch Manufacturing Solutions GmbH, Germany. All rights reserved.
 */

@mixin make-sub-header {
  @include make-border(0 0 1px 0, $boschGray90);

  display: flex;
  justify-content: flex-end;
  align-items: center;

  width: 100%;
  height: 64px;
  top: 81px;

  z-index: 3;

  > *:last-child {
    margin-right: 24px;
  }

  input {
    width: 300px;
    margin-bottom: 0;
  }
}

@mixin make-sub-header-element {
  color: $sub-header-icon-color;
  margin: 0 16px;
  cursor: pointer;
}
