/*!
  * Copyright (C) 2017 Robert Bosch GmbH Copyright (C) 2017 Robert Bosch Manufacturing Solutions GmbH, Germany. All rights reserved.
 */

@import '@bci-web-core/common-styles/sass/bci-theme';

// Color palettes from Bosch design spec.
// See https://www.google.com/design/spec/style/color.html
// Contrast colors are hard-coded because it is too difficult (probably impossible) to
// calculate them. These contrast colors are pulled from the public Material Design spec swatches.
// While the contrast colors in the spec are not prescriptive, we use them for convenience.

$black-87-opacity: rgba(black, 0.87);
$white-87-opacity: rgba(white, 0.87);
$black-12-opacity: rgba(black, 0.12);
$white-12-opacity: rgba(white, 0.12);
$black-6-opacity: rgba(black, 0.06);
$white-6-opacity: rgba(white, 0.06);

$mat-bci: (
  50: $boschBlue75,
  100: $boschBlue75,
  200: $boschBlue75,
  300: $boschBlue75,
  400: $boschBlue75,
  500: $boschBlue35,
  600: $boschBlue35,
  700: $boschBlue35,
  800: $boschBlue35,
  900: $boschBlue35,
  A100: $boschBlue75,
  A200: $boschBlue35,
  A400: $boschBlue35,
  A700: $boschBlue35,
  contrast: (
    50: $black-87-opacity,
    100: $black-87-opacity,
    200: $black-87-opacity,
    300: white,
    400: white,
    500: $white-87-opacity,
    600: $white-87-opacity,
    700: $white-87-opacity,
    800: $white-87-opacity,
    900: $white-87-opacity,
    A100: $black-87-opacity,
    A200: white,
    A400: white,
    A700: $white-87-opacity,
  ),
);

$mat-bci-light-blue: (
  50: $boschBlue80,
  100: $boschBlue80,
  200: $boschBlue80,
  300: $boschBlue80,
  400: $boschBlue80,
  500: $boschBlue50,
  600: $boschBlue50,
  700: $boschBlue50,
  800: $boschBlue50,
  900: $boschBlue50,
  A100: $boschBlue80,
  A200: $boschBlue50,
  A400: $boschBlue50,
  A700: $boschBlue50,
  contrast: (
    50: $black-87-opacity,
    100: $black-87-opacity,
    200: $black-87-opacity,
    300: $black-87-opacity,
    400: $black-87-opacity,
    500: white,
    600: white,
    700: white,
    800: white,
    900: $white-87-opacity,
    A100: $black-87-opacity,
    A200: $black-87-opacity,
    A400: $black-87-opacity,
    A700: white,
  ),
);

$mat-grey: (
  0: $boschWhite,
  50: $boschGray95,
  100: $boschGray95,
  200: $boschGray95,
  300: $boschGray95,
  400: $boschGray95,
  500: $boschGray95,
  600: $boschGray95,
  700: $boschGray95,
  800: $boschGray95,
  900: $boschGray95,
  1000: $boschGray95,
  A100: $boschWhite,
  A200: $boschGray95,
  A400: $boschGray95,
  A700: $boschGray80,
  contrast: (
    0: $black-87-opacity,
    50: $black-87-opacity,
    100: $black-87-opacity,
    200: $black-87-opacity,
    300: $black-87-opacity,
    400: $black-87-opacity,
    500: $black-87-opacity,
    600: $white-87-opacity,
    700: $white-87-opacity,
    800: $white-87-opacity,
    900: $white-87-opacity,
    1000: $white-87-opacity,
    A100: $black-87-opacity,
    A200: $black-87-opacity,
    A400: $black-87-opacity,
    A700: $white-87-opacity,
  ),
);

// Background palette for light themes.
$mat-bci-theme-background: (
  status-bar: map_get($mat-grey, 300),
  app-bar: map_get($mat-grey, 100),
  background: map_get($mat-grey, 50),
  hover: rgba(black, 0.04),
  // TODO(kara): check style with Material Design UX
  card: $boschWhite,
  dialog: $boschWhite,
  disabled-button: $boschGray80,
  raised-button: $boschWhite,
  focused-button: $boschBlue50,
  selected-button: map_get($mat-grey, 300),
  selected-disabled-button: map_get($mat-grey, 400),
  disabled-button-toggle: map_get($mat-grey, 200),
  unselected-chip: map_get($mat-grey, 300),
);

// Foreground palette for light themes.
$mat-bci-theme-foreground: (
  base: $boschBlack,
  divider: $black-12-opacity,
  dividers: $black-12-opacity,
  disabled: rgba(black, 0.38),
  disabled-button: rgba(black, 0.38),
  disabled-text: rgba(black, 0.38),
  hint-text: rgba(black, 0.38),
  secondary-text: rgba(black, 0.54),
  icon: rgba(black, 0.54),
  icons: rgba(black, 0.54),
  text: rgba(black, 0.87),
  slider-off: rgba(black, 0.26),
  slider-off-active: rgba(black, 0.38),
);
