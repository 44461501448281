/*!
  * Copyright (C) 2017 Robert Bosch GmbH Copyright (C) 2017 Robert Bosch Manufacturing Solutions GmbH, Germany. All rights reserved.
 */

@import '@bci-web-core/common-styles/sass/bci/mixins/typography';

.mat-mdc-autocomplete-panel,
.mdc-menu-surface.mat-mdc-autocomplete-panel,
.mat-mdc-autocomplete-panel:not([class*='mat-elevation-z']) {
  box-shadow: 0px 0px $grid-size rgba(0, 0, 0, 0.25);
  border-radius: 0 !important;
  padding: 0px !important;
  background-color: $boschWhite;
  color: $boschBlack;

  .mat-mdc-option {
    @include make-font-regular($font-size-base);

    &:hover {
      background-color: $boschGray90;
    }
  }
}
