/*!
  * Copyright (C) 2019 Robert Bosch GmbH Copyright (C) 2019 Robert Bosch Manufacturing Solutions GmbH, Germany. All rights reserved.
 */

@import '@bci-web-core/common-styles/sass/bci/bci-variables';

mat-toolbar button.mat-mdc-menu-trigger {
  &:hover {
    color: $boschBlue50;
  }

  &:active {
    color: $boschBlue40;
  }
}

.mat-mdc-menu-panel {
  border-radius: 0 !important;
}

.mat-mdc-menu-panel.mat-elevation-z8 {
  overflow: inherit;

  &.mdc-menu-surface--open {
    display: inline-table;
  }

  .mat-mdc-menu-content {
    height: 100%;
    overflow-y: auto;
  }

  // style the menu position indicator
  .mat-mdc-menu-content:after {
    content: '';
    position: fixed;
    display: block;
    height: 17px;
    width: 17px;
    transform-origin: center;
    background: $boschWhite;
    box-shadow: -2px 2px 3px rgb(0 0 0 / 12%) !important;
    z-index: 1;
    margin-left: -4px;
  }

  // positioning of the menu indicator
  &.mat-menu-below .mat-mdc-menu-content:after {
    top: -9px;
    transform: rotate(135deg);
  }

  &.mat-menu-above .mat-mdc-menu-content:after {
    bottom: -9px;
    transform: rotate(-45deg);
  }

  &.mat-menu-after .mat-mdc-menu-content:after {
    left: 20px;
  }

  &.mat-menu-before .mat-mdc-menu-content:after {
    right: 15px;
  }
}

.mat-mdc-menu-panel,
.mat-mdc-menu-panel.mat-mdc-elevation-specific {
  box-shadow: 0px 0px $grid-size rgb(0 0 0 / 25%) !important;
  min-width: 160px !important;
  background: $boschWhite;

  .mat-mdc-menu-content {
    // remove paddings
    &:not(:empty) {
      padding-top: 0;
      padding-bottom: 0;
    }

    // style menu item
    .mat-mdc-menu-item {
      padding: 12px $grid-size * 2;
      height: $grid-size * 6;
      line-height: $grid-size * 3;
      font-size: $grid-size * 2;
      font-weight: $font-weight-regular;
      color: $boschBlack;
      z-index: 12;
      display: flex;
      justify-content: space-between;
      min-height: unset;

      .mdc-list-item__primary-text {
        font-weight: $font-weight-regular;
      }

      &.labeled-divider,
      &.labeled-divider:hover,
      &.labeled-divider:active {
        background-color: $boschWhite !important;
        height: 26px;
        padding: $grid-size * 0.5 $grid-size * 2;
        line-height: 1;
        cursor: default;
        margin-top: $grid-size;

        .mat-mdc-menu-ripple {
          display: none;
        }

        > span {
          height: 18px;
          @include make-font-bold($font-size-small);
        }
      }

      & > span {
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .mat-icon {
        color: $boschBlack;
        min-width: 24px;
      }

      // style hover & focused menu item
      &:hover:not(.labeled-divider):not([disabled]),
      &.cdk-program-focused:not([disabled]),
      &.cdk-keyboard-focused:not([disabled]),
      &.mat-mdc-menu-item-highlighted:not([disabled]) {
        background: $boschGray90;
        color: $boschBlack;
        z-index: 12;

        // set menu item icon color
        .mat-icon {
          color: $boschBlack;
        }
      }

      &:active:not(.labeled-divider):not([disabled]) {
        background: $boschGray80;
        color: $boschBlack;
        z-index: 12;

        // set menu item icon color
        .mat-icon {
          color: $boschBlack;
        }
      }

      // sets icon color when disabled
      &[disabled],
      &[disabled] .mat-icon {
        color: $boschGray80;
      }

      // style sub menu trigger
      &.mat-mdc-menu-item-submenu-trigger {
        // fix position due to different menu item padding
        &::after {
          right: 12px;
        }

        // set white color on highlighted triggered menu item icon
        &.mat-mdc-menu-item-highlighted::after {
          color: $boschBlack;
        }
      }

      // set menu item icon margins
      mat-icon:first-child {
        margin: 0 $grid-size 0 0;
        line-height: 1;
        position: absolute;

        + span {
          padding-left: 32px;
        }
      }

      mat-icon:not(:first-child) {
        margin: 0 0 0 $grid-size;
        line-height: 1;
      }
    }

    a.mat-mdc-menu-item {
      height: $grid-size * 6;
    }
  }
}

.mat-mdc-menu-submenu-icon {
  display: none;
}

.mat-mdc-menu-trigger.mat-mdc-menu-item.mat-mdc-menu-item-submenu-trigger::after {
  @include make-icon($Bosch-IC-forward-right-small);
  position: absolute;
  right: 6px;
}
