/*!
  * Copyright (C) 2020 Robert Bosch GmbH Copyright (C) 2020 Robert Bosch Manufacturing Solutions GmbH, Germany. All rights reserved.
 */

@import '@bci-web-core/common-styles/sass/bci/bci-variables';
@import '@bci-web-core/common-styles/sass/bci/mixins/badges';

.mat-badge > span.mat-badge-content {
  @include make-badge();
  --mat-badge-container-overlap-offset: 0 0 (-$grid-size) (-$grid-size);
  --mat-badge-text-size: $font-size-small;
  text-overflow: unset;
  width: unset;
  height: unset;
}

.mat-badge.mat-badge-overlap.mat-badge-after > span.mat-badge-content {
  top: unset;
  right: unset;
}
