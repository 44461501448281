/*!
  *  Copyright (C) 2017 Robert Bosch GmbH Copyright (C) 2017 Robert Bosch Manufacturing Solutions GmbH, Germany. All rights reserved.
 */

.dropdown {
  @include make-dropdown;

  &.dropdown-disabled {
    @include make-dropdown-disabled;
  }

  .dropdown-label {
    @include make-dropdown-label;
  }

  .dropdown-menu {
    @include make-dropdown-menu;
  }
}

.dropdown:hover:not(.dropdown-disabled) .dropdown-menu,
.dropdown:focus:not(.dropdown-disabled) .dropdown-menu {
  @include make-dropdown-menu-visible;
}

select {
  @include make-legacy-dropdown;
}

.dark-background {
  .dropdown {
    @include make-dropdown-dark;
  
    .dropdown-menu {
      @include make-dropdown-menu-dark;
    }
  }

  select {
    @include make-legacy-dropdown-dark;
  }
}
