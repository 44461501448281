/*!
  *  Copyright (C) 2020 Robert Bosch GmbH Copyright (C) 2020 Robert Bosch Manufacturing Solutions GmbH, Germany. All rights reserved.
 */

.status-indicator-info {
  @include make-color-circle($boschBlue50, $grid-size * 2, 0 $grid-size 0 0);
}

.status-indicator-neutral {
  @include make-color-circle($boschGray55, $grid-size * 2, 0 $grid-size 0 0);
}

.status-indicator-success {
  @include make-color-circle($boschSignalGreen, $grid-size * 2, 0 $grid-size 0 0);
}

.status-indicator-warning {
  @include make-color-circle($boschSignalYellow, $grid-size * 2, 0 $grid-size 0 0);
}

.status-indicator-error {
  @include make-color-circle($boschSignalRed, $grid-size * 2, 0 $grid-size 0 0);
}
