/*!
  *  Copyright (C) 2017 Robert Bosch GmbH Copyright (C) 2017 Robert Bosch Manufacturing Solutions GmbH, Germany. All rights reserved.
 */

blockquote, .blockquote {
  @include make-blockquote;

  &.blockquote-reverse {
    @include make-blockquote-reverse;
  }
}
