/*!
  *  Copyright (C) 2017 Robert Bosch GmbH Copyright (C) 2017 Robert Bosch Manufacturing Solutions GmbH, Germany. All rights reserved.
 */

@mixin make-clickable-element {
  transition: $transition-time-half;
  cursor: pointer;

  &:active,
  &.active {
    color: $boschBlue50;
  }

  &:active:hover,
  &:hover &:focus {
    color: $boschBlue80;
  }

  &:disabled,
  &.disabled {
    color: $boschGray70;

    &:active,
    &.active,
    &:active:hover,
    &:hover {
      color: $boschGray70;
    }
  }
}

@mixin make-button-base($line-height, $font-size, $padding) {
  font-family: $font-family-base;
  font-weight: $font-weight-regular;
  font-size: $font-size;
  height: $grid-size * 6;
  padding: $padding;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  width: auto;
}

/** Bosch primary button mixins */
@mixin make-primary-button {
  color: $boschWhite;

  @include make-button-base(24px, $font-size-base, 0px 16px);
  @include make-primary-button-background;
  @include make-border(0, transparent);

  &:hover,
  &:focus-visible {
    @include make-primary-button-hover;
  }

  &:active {
    @include make-primary-button-active;
  }

  &:disabled,
  &.disabled {
    @include make-primary-button-disabled;
  }
}

@mixin make-primary-button-background {
  background-color: $boschBlue50;
  background-size: 100% 200%;
}

@mixin make-primary-button-active {
  background-color: $boschBlue30;
  color: $boschWhite;
  outline: 0;
}

@mixin make-primary-button-hover {
  background-color: $boschBlue40;
  color: $boschWhite;
  outline: 0;
}

@mixin make-primary-button-disabled {
  background-color: $boschGray80;
  color: $boschGray45;
  background-image: none;
  cursor: default;
}

/** Bosch secondary button mixins */
@mixin make-secondary-button {
  color: $boschBlue50;
  background-color: transparent;

  // using 0px 15px padding because border adds 1px respectivly => 0px 16px
  @include make-button-base(24px, $font-size-base, 0px 15px);
  @include make-border(1px, $boschBlue50);

  &:hover,
  &:focus-visible {
    @include make-secondary-button-hover;
  }

  &:active {
    @include make-secondary-button-active;
  }

  &:disabled,
  &.disabled {
    @include make-secondary-button-disabled;
  }
}

@mixin make-secondary-button-active {
  border-color: $boschBlue30;
  color: $boschBlue30;
  outline: 0;
  background-color: $boschBlue80;
  @include make-border(1px, $boschBlue30);
}

@mixin make-secondary-button-hover {
  border-color: $boschBlue30;
  outline: 0;
  color: $boschBlue30;
  background-color: $boschBlue90;
  @include make-border(1px, $boschBlue30);
}

@mixin make-secondary-button-disabled {
  color: $boschGray65;
  border-color: $boschGray65;
  background-color: transparent;
  cursor: default;
}

/** Bosch tertiary button mixins */
@mixin make-tertiary-button {
  color: $boschBlue50;
  background-color: transparent;

  @include make-button-base(24px, $font-size-base, 0px 16px);

  border-style: none;

  &:hover,
  &:focus-visible {
    @include make-tertiary-button-hover;
  }

  &:active {
    @include make-tertiary-button-active;
  }

  &:disabled,
  &.disabled {
    @include make-tertiary-button-disabled;
  }
}

@mixin make-tertiary-button-active {
  color: $boschBlue30;
  background-color: $boschBlue80;
  outline: 0;
}

@mixin make-tertiary-button-hover {
  outline: 0;
  color: $boschBlue40;
  background-color: $boschBlue90;
}

@mixin make-tertiary-button-disabled {
  color: $boschGray65;
  background-color: transparent;
  cursor: default;
}

/** Bosch integrated button mixins */
@mixin make-integrated-button {
  color: $boschBlack;
  background-color: transparent;

  @include make-button-base(24px, $font-size-base, 0px 16px);
  border-style: none;

  &:hover,
  &:focus-visible {
    @include make-integrated-button-hover;
  }

  &:active {
    @include make-integrated-button-active;
  }

  &:disabled,
  &.disabled {
    @include make-integrated-button-disabled;
  }
}

@mixin make-integrated-button-active {
  color: $boschBlue40;
  outline: 0;
}

@mixin make-integrated-button-hover {
  color: $boschBlue50;
  outline: 0;
}

@mixin make-integrated-button-disabled {
  color: $boschGray65 !important;
  background-color: transparent;
  cursor: default;
}

/** Bosch value modificator button mixins */
@mixin make-value-modificator-button {
  color: $boschBlack;
  background-color: $boschGray90;

  @include make-button-base(
    $button-size-base - 24px,
    $font-size-base,
    0 0 0 0px
  );

  width: 48px;
  height: 47px;
  border-style: none;

  &:hover {
    @include make-value-modificator-button-hover;
  }

  &:active {
    @include make-value-modificator-button-active;
  }

  &:disabled,
  &:hover:disabled,
  &.disabled {
    @include make-value-modificator-button-disabled;
  }

  & .mat-icon {
    margin-right: 0px !important;
  }
}

@mixin make-value-modificator-button-active {
  background-color: $boschGray70;
  color: $boschBlack;
  outline: 0;
}

@mixin make-value-modificator-button-hover {
  outline: 0;
  background-color: $boschGray80;
  color: $boschBlack;
}

@mixin make-value-modificator-button-disabled {
  background-color: $boschGray90;
  color: $boschGray70;
  cursor: default;
}

/** Bosch link button */
@mixin make-link-button {
  color: $boschBlue50;
  background-color: transparent;
  border: none;
  @include box-shadow(none);
  @include make-button-base(
    $button-size-base,
    $font-size-base,
    0 2 * $grid-size 0 2 * $grid-size
  );

  &::after {
    position: absolute;
    @include make-icon($Bosch-Ic-arrow-expand-right, 18px);
    // @include transition(all 0.4s ease 0s);
  }

  &:hover,
  &:focus-visible {
    @include make-link-button-hover;
  }

  &:active {
    @include make-link-button-active;
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] {
    @include make-link-button-disabled;
  }
}

@mixin make-link-button-hover {
  color: $boschBlue40;
  text-decoration: underline;
  outline: 0;
}

@mixin make-link-button-active {
  color: $boschBlue30;
  text-decoration: underline;
}

@mixin make-link-button-disabled {
  color: $boschGray80;
  text-decoration: none;

  @include transition(none);

  &::after {
    @include translate(0, 0);
  }
}

@mixin make-link-button-no-arrow {
  &::after {
    content: "";
  }
}

/** Block Buttons */
@mixin make-block-button {
  display: block;
  width: 100%;
}

@mixin button-padding-for-icon-with-label {
  padding: 0px 16px 0px 14px;
}
