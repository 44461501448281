/*!
  *  Copyright (C) 2017 Robert Bosch GmbH Copyright (C) 2017 Robert Bosch Manufacturing Solutions GmbH, Germany. All rights reserved.
 */

// Labels

@mixin make-label-top {
  @include make-font-regular(12px);
  color: $font-color;
  background-color: $form-field-default-theme-background-color;
  display: block;

  &:last-of-type {
    position: absolute;
    left: 1px;
    top: 1px;
    z-index: 1;
    padding: $grid-size * 0.5 0 0 $grid-size * 2-1;
    margin: 0;
    width: calc(100% - #{$grid-size * 6});
    max-width: calc(100% - #{$grid-size * 6});
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@mixin make-label-top-disabled {
  color: $form-field-disabled-color;
  cursor: not-allowed;
}

@mixin make-label-top-focused {
  background-color: $form-field-default-theme-focused-background-color;
}

@mixin make-label-top-hovered {
  background-color: $form-field-default-theme-hovered-background-color;
}

@mixin make-label-top-pressed {
  background-color: $form-field-default-theme-pressed-background-color;
}

@mixin make-label-top-dark {
  background-color: $form-field-dark-theme-background-color;
}

@mixin make-label-top-focused-dark {
  background-color: $form-field-dark-theme-focused-background-color;
}

@mixin make-label-left {
  @include make-font-regular($font-size-small);
  color: $boschBlack;
  display: inline;
  line-height: 18px;
  padding: 0 $grid-size * 2 0 0;
}

@mixin label-variant($color) {
  border: 1px solid $color;
  border-top-width: 2px;
  background-color: inherit;
  &[href] {
    &:hover,
    &:focus {
      background-color: $color;
    }
  }
}
